angular.module("kno2.settings").controller("SettingsFaxEditModalCtrl", [
    "$timeout",
    "$filter",
    "$q",
    "$uibModalInstance",
    "$uibModal",
    "$confirm",
    "data",
    "DocumentSourcesFaxService",
    "NotificationService",
    "UsersService",
    "CommonData",
    "_",
    "SessionService",
    "SettingsService",
    function (
        $timeout,
        $filter,
        $q,
        $uibModalInstance,
        $uibModal,
        $confirm,
        data,
        DocumentSourcesFaxService,
        NotificationService,
        UsersService,
        CommonData,
        _,
        SessionService,
        SettingsService
    ) {
        const ctrl = this;
        ctrl.userProfile = data.userProfile;
        ctrl.$onInit = init;
        ctrl.isEFax = isEFax;
        ctrl.setForm = function (form) {
            ctrl.form = form;
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        ctrl.saveChanges = function () {
            if (ctrl.documentSource.isAssociatedWithAllActiveOrganizationUsers && ctrl.documentSource.users.length > 0) {
                $confirm
                    .open({
                        title: "Confirmation",
                        bodyText:
                            "Are you sure you want to switch to global permissions? This action will remove the existing list of users. Please confirm to proceed.",
                        okText: "Continue"
                    })
                    .result.then(() => ctrl.confirmSaveChanges());
            } else {
                ctrl.confirmSaveChanges();
            }
        };

        ctrl.confirmSaveChanges = function () {
            ctrl.formSubmitted = true;

            var users = [];
            if (!ctrl.documentSource.isAssociatedWithAllActiveOrganizationUsers) {
                _.each(ctrl.documentSource.users, function (user) {
                    users.push(user);
                });
            }
            ctrl.documentSource.users = users;

            // Save changes if form valid
            if (ctrl.form.$valid) {
                const functionName = ctrl.documentSource.id ? "putDocumentSource" : "postDocumentSource";
                DocumentSourcesFaxService[functionName](ctrl.documentSource).then(
                    function () {
                        NotificationService.success("The fax was saved successfully.");
                        $uibModalInstance.close(ctrl.documentSource);
                    },
                    function (response) {
                        if (!!response.data.message) {
                            NotificationService.serverError({}, response.data.message);
                        } else {
                            NotificationService.serverError(response.data.modelState, "There was an error saving the fax.");
                        }
                    }
                );
                if (isEFax(ctrl.documentSource.documentSourceProviderName)) {
                    SettingsService.upsert({
                        entityId: ctrl.documentSource.id,
                        entityType: "DocumentSource",
                        settings: {
                            DocumentSourceFaxCallerId: ctrl.callerId
                        }
                    }).then((res) => {});
                }
            } else {
                _(ctrl.form.$error.required).forEach(function (value) {
                    // Triggers the red exlamation point (Focus input, swich to another input, switch back).
                    $timeout(
                        function () {
                            $("input[name*='" + value.$name + "']")
                                .focus()
                                .select();
                        },
                        250,
                        false
                    );
                    $timeout(
                        function () {
                            $("input[name*='address']").focus().select();
                        },
                        250,
                        false
                    );
                    $timeout(
                        function () {
                            $("input[name*='" + value.$name + "']")
                                .focus()
                                .select();
                        },
                        250,
                        false
                    );
                });
            }
        };

        function isEFax(documentSourceProviderName) {
            return documentSourceProviderName === "eFax";
        }

        function init() {
            ctrl.restrictSendPermissions = SessionService.hasUIResourceAction("directAddressesSendPermissionsInput", "disable");

            DocumentSourcesFaxService.getDocumentSources("asc", 1, 100).then((data) => {
                this.callerIdOptions = data.documentSources;
            });
            UsersService.getOrgUsers(false).then(function (response) {
                ctrl.organizationUsers = response;

                // Get the Document Sources
                if (data.id) {
                    DocumentSourcesFaxService.getDocumentSource(data.id).then(function (response) {
                        ctrl.documentSource = response;
                        const users = ctrl.documentSource.users;

                        SettingsService.getAll(ctrl.documentSource.id, "DocumentSource").then((res) => {
                            ctrl.callerId = res.data.data.documentSourceFaxCallerId;
                        });
                        ctrl.documentSource.users = [];
                        if (users && !ctrl.documentSource.isAssociatedWithAllActiveOrganizationUsers) {
                            _.each(users, function (x) {
                                const u = _.find(ctrl.organizationUsers, { id: x.id });
                                if (u) {
                                    ctrl.documentSource.users.push(u);
                                }
                            });
                        }
                        if (ctrl.restrictSendPermissions) {
                            ctrl.organizationUsers = [];
                        }
                    });
                } else {
                    ctrl.documentSource = {
                        directAddressType: "fax",
                        type: "Fax",
                        isAssociatedWithAllActiveOrganizationUsers: false,
                        users: []
                    };
                }
                $timeout(
                    function () {
                        $("input[name*='address']").focus();
                    },
                    500,
                    false
                );
            });
        }
    }
]);
