declare const angular;
export class AdminCertificatesService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    postCertificate(certificateFile) {
        const formDataObj = new FormData();
        formDataObj.append("name", certificateFile.name);
        formDataObj.append("file", certificateFile.file);
        formDataObj.append("password", certificateFile.password);
        formDataObj.append("useExistingName", certificateFile.useExistingName);

        return this.$http({
            method: "POST",
            url: `/api/configuration/certificates`,
            data: formDataObj,
            transformRequest: angular.identity,
            headers: {
                "Content-Type": undefined
            }
        });
    }

    getCertificateByName(name) {
        return this.$http.get(`/api/configuration/certificates/${name}`).then((response) => response.data);
    }

    getCertificates() {
        return this.$http.get(`/api/configuration/certificates`).then((response) => response.data);
    }

    deleteCertificate(name) {
        return this.$http.delete(`/api/configuration/certificates`).then((response) => response.data);
    }

    enableCertificate(cert: any) {
        return this.$http.put(`/api/configuration/certificates/${cert.name}/enable`, { enabled: !cert.enabled }).then((response) => response.data);
    }
}
