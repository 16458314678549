let ctrl;
class AdminDocuSignDeleteModalController {
    public static $inject: Array<string> = ["AdminDocuSignService", "NotificationService"];

    public resolve;
    public close;
    public dismiss;
    public accountId: string;

    public saving: boolean;

    constructor(
        private adminDocuSignService,
        private notificationService
    ) {}

    public $onInit(): void {
        this.accountId = this.resolve.accountId;
    }

    public deleteDocuSignAccount(): void {
        this.adminDocuSignService.deleteOrganizationAccount(this.accountId).then(
            () => {
                this.notificationService.success("The DocuSign Organization account has been removed");
                this.close();
            },
            function (res) {
                this.notificationService.error({
                    messages: [res.data.message]
                });
            }
        );
    }
}

export class AdminDocuSignDeleteModalComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.docusign.delete-modal.component.html");
        this.controller = AdminDocuSignDeleteModalController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}
