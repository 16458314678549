import { Routes } from "@angular/router";

export const routes: Routes = [
    {
        path: "account/profile/email",
        loadComponent: () => import("./profile/profile.component").then((c) => c.ProfileComponent),
        data: { currentTab: "emailNotifications" }
    },
    {
        path: "account/profile",
        pathMatch: "full",
        loadComponent: () => import("./profile/profile.component").then((c) => c.ProfileComponent)
    }
];
