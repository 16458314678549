IdentityQuestionsModalCtrl.$inject = [
    "$scope",
    "$uibModalInstance",
    "$timeout",
    "IdProofingService",
    "NotificationService",
    "ValidationService",
    "SessionService",
    "quiz"
];
export function IdentityQuestionsModalCtrl(
    $scope,
    $uibModalInstance,
    $timeout,
    idProofingService,
    notificationService,
    validationService,
    sessionService,
    quiz
) {
    $scope.modal = {};
    $scope.title = "Answer Questions: Step 2 of 2";
    $scope.userProfile = sessionService.getProfile();
    $scope.quiz = quiz;
    $scope.answersSubmitInProgress = false;
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.submitAnswers = function () {
        $scope.answersSubmitInProgress = true;
        var quizAnswers = {
            quizId: $scope.quiz.quizId,
            transactionKey: $scope.quiz.transactionKey,
            userIdentity: $scope.quiz.userIdentity,
            answers: []
        };

        var selectedAnswers = _.map($scope.quiz.questions, function (question) {
            var selected = _.find(question.answerChoice, { selected: "true" });
            if (!selected) return;
            return {
                questionId: question.questionId,
                answerId: selected.answerId
            };
        });

        quizAnswers.answers = selectedAnswers;

        if ($scope.form.$valid) {
            idProofingService
                .submitAnswers(quizAnswers)
                .then(function (response) {
                    $uibModalInstance.close(response.data);
                })
                .finally(function () {
                    $scope.answersSubmitInProgress = true;
                });
        }
    };
}
