import manageCachedAddressesModalTemplate from "../../common/cached-addresses/manage-cached-addresses-modal.html";
import intakeMessageConfirmAttachmentsModalTemplate from "../../intake/message/intake.message.confirm-attachments-modal.html";
import releaseMessageConfirmDraftSaveModalTemplate from "./release.message.confirm-draft-save-modal.html";
import releaseMessageReviewAndSendModalTemplate from "./release.message.review-and-send-modal.html";
import releaseTagsInputAutocompleteTemplate from "./release.tags-input-autocomplete.html";
import releaseTagsInputTemplate from "./release.tags-input.html";

import { compareObjects } from "../../common/utils/objectCompare";

angular.module("kno2.release").controller("ReleaseNewDraftCtrl", ReleaseNewDraftCtrl);
ReleaseNewDraftCtrl.$inject = [
    "$scope",
    "$rootScope",
    "$stateParams",
    "$q",
    "$timeout",
    "$location",
    "$uibModal",
    "$window",
    "DocumentSourceService",
    "FeatureService",
    "ReleaseService",
    "AttachmentService",
    "ContactsService",
    "SessionService",
    "SubscriptionService",
    "NotificationService",
    "CommonData",
    "MessageStateFactory",
    "ReleaseTypesService",
    "AddressesCacheFactory",
    "$confirm",
    "moment",
    "_",
    "DirectoryService",
    "appConfig"
];
function ReleaseNewDraftCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $q,
    $timeout,
    $location,
    $uibModal,
    $window,
    DocumentSourceService,
    FeatureService,
    ReleaseService,
    AttachmentService,
    ContactsService,
    SessionService,
    SubscriptionService,
    NotificationService,
    CommonData,
    MessageStateFactory,
    ReleaseTypesService,
    AddressesCacheFactory,
    $confirm,
    moment,
    _,
    DirectoryService,
    appConfig
) {
    var onRouteChangeEvent, onAddAttachmentSuccess, updatePatientConfirmInstance;
    let onSave = () => {};

    $scope.location = $location;
    $scope.profile = SessionService.getProfile();
    $scope.draftId = null;
    $scope.showDocumentViewer = false;
    $scope.onRouteChangeEventDisabled = false;
    $scope.sourceTypes = CommonData.sourceTypes.asArray();
    $scope.patient = {};
    $scope.attachments = [];
    $scope.destinationAddress = null;
    $scope.destinationAddressPlaceholder;
    $scope.originAddress = null;
    $scope.isQuickRelease = false;
    $scope.selectedReleaseType = null;
    $scope.includeAttachmentsConfirmation = includeAttachmentsConfirmation;
    $scope.releaseTypeChanged = releaseTypeChanged;
    $scope.manageCachedAddresses = manageCachedAddresses;
    $scope.autoCompleteAddresses = autoCompleteAddresses;
    $scope.reviewSendMessage = reviewSendMessage;
    $scope.setInvalidAddressesFromResponse = setInvalidAddressesFromResponse;
    $scope.onPatientAttachmentsInit = onPatientAttachmentsInit;
    $scope.validateReleaseModel = validateReleaseModel;
    $scope.openSendAndReviewModal = openSendAndReviewModal;
    $scope.searchDirectory = searchDirectory;
    $scope.onFileSelect = onFileSelect;
    $scope.updatePatient = updatePatient;
    $scope.start = start;
    $scope.isAttachmentProcessing = isAttachmentProcessing;
    $scope.removeAttachmentInProcess = removeAttachmentInProcess;
    $scope.populateFromAddress = populateFromAddress;
    $scope.resetRelease = resetRelease;
    $scope.canRemoveTag = canRemoveTag;
    $scope.setViewer = setViewer;
    $scope.title = "Message Body";
    $scope.viewerOptions = {
        rotate: "enabled",
        remove: "enabled",
        print: "hidden",
        download: "hidden",
        zoom: "auto",
        // Old options
        supportPageRotation: true,
        supportPageRemoval: true
    };
    $scope.releaseTagsInputTemplateUrl = releaseTagsInputTemplate;
    $scope.releaseTagsInputAutocompleteTemplateUrl = releaseTagsInputAutocompleteTemplate;

    activate();

    function activate() {
        $scope.message = {
            // this should always be the Long representation of the id
            id: null,
            organizationName: $scope.profile.organizationName,
            origin: appConfig.brand,
            fromAddress: null,
            toAddresses: []
        };

        FeatureService.load().then(function () {
            $scope.destinationAddressPlaceholder = FeatureService.isEnabled("FaxDocumentSource") ? "Add an address or fax number" : "Add an address";
        });

        ReleaseTypesService.getReleaseTypes().then(function (response) {
            $scope.sourceType = _.find($scope.sourceTypes, { id: "dm" });
            $scope.releaseTypes = response.releaseTypes;

            // mimicking the behavior of org-release-types.filter.js and system-release-types.filter.js
            let orgReleaseTypes = _.filter($scope.releaseTypes, function (r) {
                return !!r.organizationId;
            });
            let systemReleaseTypes = _.filter($scope.releaseTypes, function (r) {
                return !r.organizationId;
            });
            if (orgReleaseTypes.length && systemReleaseTypes.length) {
                orgReleaseTypes = orgReleaseTypes.concat({ disabled: true, name: "-", id: "-1" });
            }
            $scope.releaseTypeOptions = orgReleaseTypes.concat(systemReleaseTypes);

            var releaseTypeId = parseInt($location.search().releaseType || -1, 10);
            $scope.releaseType = _.find($scope.releaseTypes, { id: releaseTypeId });
            $scope.message.releaseType = $scope.releaseType ? $scope.releaseType.name : "";
            $scope.message.sourceType = $scope.sourceType ? $scope.sourceType.name : "";
        });

        $scope.$watchCollection("message.toAddresses", function () {
            $scope.invalidAddressWarningGiven = false;
        });

        onRouteChangeEvent = $rootScope.$on("$locationChangeStart", function (event, next) {
            if ($scope.onRouteChangeEventDisabled) return;
            if (!$scope.messageStrategy || $scope.messageStrategy.getName() === "forward" || $scope.messageStrategy.getName() === "reply") return;

            if (!$scope.message.fromAddress) {
                if (draftInContext()) {
                    deleteDraft($scope.draftId);
                }
                return;
            }

            event.preventDefault();
            var modalInstance = $uibModal.open({
                templateUrl: releaseMessageConfirmDraftSaveModalTemplate,
                controller: "ConfirmDraftSaveModalCtrl"
            });
            modalInstance.result.then(
                function (action) {
                    if (action === "save") {
                        $q.all([
                            ReleaseService.saveDraft($scope.draftId, $scope.message, $scope.attachments, $scope.patient),
                            AttachmentService.transformAttachments($scope.draftId, $scope.attachments, false)
                        ])
                            .then(function () {
                                redirectFromReleaseMessage(next);
                            })
                            .catch(function (res) {
                                if (res && res.data)
                                    NotificationService.errors(res.data.exceptionMessage.split("\r\n"), "Please fix the following errors before sending.");
                            });
                    } else if (action === "discard") {
                        if (draftInContext()) {
                            deleteDraft($scope.draftId).then(
                                function () {
                                    redirectFromReleaseMessage(next);
                                },
                                function () {
                                    NotificationService.error("The message was not discarded successfully.");
                                }
                            );
                        } else {
                            redirectFromReleaseMessage(next);
                        }
                    }
                },
                () => {}
            );
        });

        $scope.$on("$destroy", onRouteChangeEvent);

        onAddAttachmentSuccess = $rootScope.$on("onAddAttachmentSuccess", function (event, data) {
            var addAttachmentCompleted = function () {
                updatePatient(data.attachment.attachmentMeta.patient, $scope.patient);

                $scope.attachments.push(data.attachment);

                $timeout(function () {
                    $rootScope.$broadcast("onAddAttachmentCompleted", data.attachment);
                    $scope.removeAttachmentInProcess(data.uploadProcessId);
                });
            };

            // DONT CHANGE THE CB ORDER HERE... THAR BE DRAGONS
            AttachmentService.getAttachment($scope.draftId, data.attachment.id).then(
                function (response) {
                    if (response.headers("Retry-After")) {
                        $timeout(function () {
                            $rootScope.$emit("onAddAttachmentSuccess", data);
                        }, 500);
                    } else {
                        data.attachment = response.data;
                        addAttachmentCompleted(); // 2014-10-01 IKW-1229 PR: Code moved to addAttachmentCompleted and must be called if error too.
                    }
                },
                function (err) {
                    addAttachmentCompleted();
                }
            );
        });

        $scope.$on("$destroy", onAddAttachmentSuccess);

        $rootScope.$on("onAddAttachmentError", function (event, data) {
            $scope.removeAttachmentInProcess(data.uploadProcessId);
            NotificationService.error(`An error occurred while uploading '${data.fileName}'`);
        });

        $rootScope.$on("onAddAttachmentExtensionError", function (event, data) {
            $scope.removeAttachmentInProcess(data.uploadProcessId);
            NotificationService.error(`An error occurred while uploading '${data.fileName}'. Unsupported file type`);
        });

        $rootScope.$on("onAddAttachmentProcessStarted", function (event, data) {
            $scope.attachmentsInProcess.push({
                uploadProcessId: data.uploadProcessId,
                fileName: data.fileName
            });
        });

        DocumentSourceService.getReleaseDocumentSourcesByUser().then(function (data) {
            $scope.availableFromAddresses = _.map(data, "fullAddress");
            $scope.documentSources = data;
            if ($location.$$path.indexOf("/draft") > -1) {
                $scope.id = $stateParams.id;
                $scope.messageStrategy = MessageStateFactory.createStrategy("draft");
            } else if ($location.$$path.indexOf("release/forward") > -1) {
                $scope.id = $stateParams.id;
                $scope.messageStrategy = MessageStateFactory.createStrategy("forward");
            } else if ($location.$$path.indexOf("release/reply") > -1) {
                $scope.id = $stateParams.id;
                $scope.messageStrategy = MessageStateFactory.createStrategy("reply");
            } else {
                $scope.messageStrategy = MessageStateFactory.createStrategy("new");
            }

            $scope.upload = [];
            $scope.attachmentsInProcess = [];

            $scope.messageStrategy.init($scope);
        });
    }

    function setViewer(showIt) {
        $scope.showDocumentViewer = showIt;
        $scope.title = showIt ? "Attachment Preview" : "Message Body";
    }

    function draftInContext() {
        return $scope.draftId !== undefined && $scope.draftId !== null;
    }

    function updatePatient(meta, patient) {
        if (!meta || (!meta.firstName && !meta.lastName)) return;

        meta = _.pick(
            meta,
            "patientId",
            "patientIds",
            "firstName",
            "lastName",
            "middleName",
            "birthDate",
            "gender",
            "streetAddress1",
            "streetAddress2",
            "city",
            "state",
            "postalCode",
            "country",
            "telephone"
        );

        if (meta.telephone) {
            meta.telephone = meta.telephone.replace(/tel:/, "");
            meta.telephone = meta.telephone.replace(/\+/, "");
        }

        if (!patient.firstName && !patient.lastName) {
            angular.extend(patient, meta);
        } else {
            let comparisonResults = compareObjects(patient, meta);
            if (comparisonResults.length > 0) {
                let message = `Would you like to overwrite the the patient details for "${patient.firstName} ${patient.lastName}" with the following: \n\n`;
                _.forEach(comparisonResults, function (result) {
                    message =
                        message +
                        `<strong>${upperCaseFirst(result.property)}</strong>: ${
                            result.property === "patientIds" ? parsePatientIds(result.new) : parseEmpty(result.new)
                        }\n`;
                });
                message = message + '\n\n(Choose "Cancel" to leave the patient details unchanged.)';

                updatePatientConfirmInstance = $confirm.open({
                    title: "Multiple Patients",
                    bodyText: message
                });

                updatePatientConfirmInstance.result.then(function (ok) {
                    if (ok) angular.extend(patient, meta);
                });
            }
        }

        function upperCaseFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).replace(/([A-Z])/g, " $1");
        }

        function parseEmpty(string) {
            return string ? string : "(empty)";
        }

        function parsePatientIds(pids) {
            return _.map(pids, function (pid) {
                return pid.id;
            }).join(", ");
        }
    }

    function includeAttachmentsConfirmation(messageStrategy) {
        var clearAttachments = function () {
            $scope.attachments = [];
        };

        if ($scope.attachments.length > 0) {
            if (messageStrategy === "reply") {
                clearAttachments();
                $scope.populateFromAddress();
            } else {
                var modalInstance = $uibModal.open({
                    templateUrl: intakeMessageConfirmAttachmentsModalTemplate,
                    controller: "ConfirmAttachmentsReplyForwardModalCtrl",
                    backdrop: "static",
                    resolve: {
                        data: function () {
                            return {
                                messageStrategy: messageStrategy
                            };
                        }
                    }
                });
                modalInstance.result.then(function (action) {
                    if (action == "remove") {
                        clearAttachments();
                        $scope.populateFromAddress();
                    } else {
                        // On forward with and keep attachment, we must create a draft automatically.
                        if (!draftInContext()) {
                            ReleaseService.saveDraftWithAttachments($scope.draftId, $scope.message, true)
                                .then(function (res) {
                                    $scope.onRouteChangeEventDisabled = true;
                                    res.subject = $scope.message.subject;
                                    ReleaseService.setCurrentDraft(res);
                                    $location.path("/release/forward/draft/" + res.id);
                                })
                                .catch(function (res) {
                                    if (res && res.data) {
                                        NotificationService.errors(res.data.exceptionMessage.split("\r\n"), "Please fix the following errors before sending.");
                                    }
                                });
                        }
                    }
                });
            }
        }
    }

    function resetRelease() {
        $scope.message.releaseType = "";
        releaseTypeChanged();
    }

    function canRemoveTag(tag) {
        if (tag && tag.text.includes("|")) {
            tag.text = null;
        }
        if (!$scope.selectedReleaseType) return true;
        if (tag && tag.text === $scope.selectedReleaseType.destinationAddress) {
            $scope.message.releaseType = "";
            releaseTypeChanged();
        }

        return true;
    }

    function releaseTypeChanged(addOnly) {
        $scope.selectedReleaseType = _.find($scope.releaseTypes, { name: $scope.message.releaseType.trim() }) || {};

        var prevDestinationAddress = $scope.destinationAddress;
        var prevOriginAddress = $scope.originAddress;

        if ($scope.selectedReleaseType.sendType) $scope.message.conversion = $scope.selectedReleaseType.sendType;
        $scope.hasReleaseOrigin = !!$scope.selectedReleaseType.originAddressId;
        $scope.hasReleaseDestination = !!$scope.selectedReleaseType.destinationAddress;
        $scope.isQuickRelease = $scope.hasReleaseOrigin && $scope.hasReleaseDestination;
        $scope.destinationAddress = AddressesCacheFactory.formatAddress($scope.selectedReleaseType.destinationAddress);

        if ($scope.hasReleaseOrigin) {
            var documentSource = _.find($scope.documentSources, { id: $scope.selectedReleaseType.originAddressId });
            $scope.message.fromAddress = documentSource ? documentSource.fullAddress : null;
            $scope.originAddress = AddressesCacheFactory.formatAddress($scope.message.fromAddress);
        }

        // Add the destination address
        if ($scope.hasReleaseDestination) {
            if ($scope.isQuickRelease) $scope.message.toAddresses = [];
            if ($scope.message.toAddresses.length === 0 || !_.find($scope.message.toAddresses, { text: $scope.destinationAddress })) {
                $scope.message.toAddresses.push({ text: $scope.destinationAddress });
            }
        }

        $scope.message.classification = null;
        if ($scope.selectedReleaseType.classificationCode) $scope.message.classification = { code: $scope.selectedReleaseType.classificationCode };

        var fromField = angular.element("#messageFromAddress");
        if ($scope.hasReleaseOrigin) {
            fromField.attr("disabled", true).trigger("chosen:updated");
        } else {
            fromField.attr("disabled", false).trigger("chosen:updated");
        }

        switch ($scope.messageStrategy.getName()) {
            case "forward":
                $scope.message.subject = "FW: " + $scope.message.releaseType;
                $scope.message.releaseTypeId = $scope.selectedReleaseType.id;
                break;
            case "reply":
                // Do nothing. Reply has no releaseType and the subject is already set.
                break;
            default:
                $scope.message.subject = $scope.message.releaseType;
                $scope.message.releaseTypeId = $scope.selectedReleaseType.id;
        }

        if (addOnly) return;

        // If the destination comes from Release Types, remove it when the Release Types change.
        if (prevDestinationAddress && prevDestinationAddress !== $scope.destinationAddress) {
            $scope.message.toAddresses = _.reject($scope.message.toAddresses, function (value) {
                return value.text === prevDestinationAddress;
            });
        }

        if (prevOriginAddress && !$scope.hasReleaseOrigin) {
            $scope.message.fromAddress = null;
            $scope.originAddress = $scope.message.fromAddress;
        }
    }

    function manageCachedAddresses() {
        var modalInstance = $uibModal.open({
            templateUrl: manageCachedAddressesModalTemplate,
            controller: "ManageCachedAddressesModalCtrl"
        });
        modalInstance.result.then(
            function (action) {
                if (action == "save") {
                    NotificationService.success("Direct Address Cache has been saved successfully.");
                }
            },
            () => {}
        );
    }

    async function autoCompleteAddresses(query) {
        query = query.toLowerCase();
        var addresses = [];

        const contacts = await searchContacts(query);

        // Get Addresses from storage cache
        var storageAddresses = AddressesCacheFactory.get();
        if (storageAddresses) {
            addresses = storageAddresses;
        }

        // Get Addresses from Document Sources
        _.each($scope.availableFromAddresses, function (item) {
            if (!_.includes(addresses, item)) {
                addresses.push(item);
            }
        });

        // Filter list with query value
        var filteredAddresses = [...contacts];
        var tempArr = [];
        addresses = addresses.sort();
        _.each(addresses, function (item) {
            item = item.toLowerCase();
            if (item.indexOf(query) > -1) {
                var addr = AddressesCacheFactory.formatAddress(item);
                const hasContactForAddress = contacts.map(({ text }) => AddressesCacheFactory.formatAddress(text.split("|")[0])).includes(addr);
                if (!_.includes(tempArr, addr) && !hasContactForAddress) {
                    tempArr.push(addr);
                    filteredAddresses.push({ text: addr });
                }
            }
        });

        var deferred = $q.defer();
        deferred.resolve(filteredAddresses);
        return deferred.promise;
    }

    function isFaxNumber(faxNumber) {
        return faxNumber.indexOf("@") === -1;
    }

    async function searchContacts(search) {
        const results = await ContactsService.searchContacts(search);
        const contacts = results.items;

        return contacts.map(({ name, company, value: address }) => {
            const text = [address, name, company].join("|");

            return { text };
        });
    }

    async function reviewSendMessage() {
        try {
            let hasLookupError = false;
            $scope.validationInProgress = true;
            onSave();
            await $scope.validateReleaseModel();
            $scope.message.originalObjectId = $scope.originalObjectId;

            await AttachmentService.transformAttachments($scope.draftId, $scope.attachments, false);
            const draftResult = await ReleaseService.saveDraft($scope.draftId, $scope.message, $scope.attachments, $scope.patient);
            $scope.draftId = draftResult.id;
            $scope.openSendAndReviewModal(hasLookupError);
        } catch (err) {
            if (err && err.data) {
                if (err.data.exceptionMessage)
                    NotificationService.errors(err.data.exceptionMessage.split("\r\n"), "Please fix the following errors before sending.");
                else if (err.data.message) {
                    if (err.data.modelState && err.data.modelState.toAddress) {
                        NotificationService.error(err.data.modelState.toAddress[0]);
                    } else NotificationService.error(err.data.message);
                }
            }
        } finally {
            $scope.validationInProgress = false;
        }
    }

    function deleteDraft(draftId) {
        return ReleaseService.deleteDraft(draftId);
    }

    function redirectFromReleaseMessage(url) {
        onRouteChangeEvent();
        $window.location.href = url;
    }

    function setInvalidAddressesFromResponse(response) {
        $scope.invalidAddresses = [];
        for (var address in response) {
            if (!response[address]) {
                $scope.invalidAddresses.push(address);
            }
        }
    }

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function phoneNumber(i) {
        var phone = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
        if (i.match(phone)) {
            return true;
        } else {
            return false;
        }
    }

    function containsKno2Address(addresses) {
        var kno2fyAddress = true;
        _.each(addresses, function (address) {
            if (!(address.toLowerCase().indexOf("kno2fy") > -1 || isNumeric(address) || phoneNumber(address))) {
                kno2fyAddress = false;
            }
        });
        return kno2fyAddress;
    }

    function onFreePlan() {
        return SessionService.hasUIResourceAction("releaseSendButton", "planUpgradePrompt");
    }

    function onPatientAttachmentsInit(ctrl) {
        $scope.attachmentsForm = ctrl.attachmentsForm;
        onSave = ctrl.onSave;
    }

    function validateReleaseModel() {
        if (!$scope.isQuickRelease) $scope.releaseTypeChanged(true); // Force adding the destination address if removed

        var deferred = $q.defer();
        var addresses = _.map($scope.message.toAddresses, "text");

        if (onFreePlan()) {
            if (!containsKno2Address(addresses)) {
                SubscriptionService.checkAndShowPrompt("releaseSendButton");
                deferred.reject();
                return deferred.promise;
            }
        }

        var validate = function () {
            var errors = [];

            if ($scope.attachmentsForm.$invalid) errors.push("An attachment's information is invalid.");

            if (_.isEmpty($scope.message.releaseType) && $scope.messageStrategy.getName() !== "reply") errors.push('"Release Type" is required.');

            if (_.isEmpty($scope.message.toAddresses)) errors.push('"To" address is required.');

            if (_.isEmpty($scope.message.fromAddress)) errors.push('"From" address is required.');

            $scope.messageStrategy.validateMessageModel($scope, errors);

            if (!_.isEmpty(errors)) {
                $window.scrollTo(0, 0);
                NotificationService.error({
                    messageTitle: "Please fix the following errors before sending.",
                    messages: errors
                });
                deferred.reject();
            } else {
                NotificationService.hideErrors();
                deferred.resolve();
            }
        };

        $timeout(validate);

        return deferred.promise;
    }

    function openSendAndReviewModal(hasLookupError) {
        var modalInstance = $uibModal.open({
            windowClass: "intake-review-send-message-modal",
            templateUrl: releaseMessageReviewAndSendModalTemplate,
            controller: "IntakeReviewSendMessageModalCtrl",
            resolve: {
                data: function () {
                    return {
                        messageStrategy: $scope.messageStrategy,
                        message: $scope.message,
                        patient: $scope.patient,
                        attachments: $scope.attachments,
                        draftId: $scope.draftId,
                        releaseTypes: $scope.releaseTypes,
                        selectedReleaseType: $scope.selectedReleaseType,
                        hasLookupError
                    };
                }
            }
        });
        modalInstance.result.then(function (result) {
            var redirectPath = $location.search().returnUrl || "/release";
            if (result === "success") {
                onRouteChangeEvent();
                $location.path(redirectPath).search({});
            }
        });
    }

    function searchDirectory() {
        DirectoryService.searchDirectory(true, $scope.message.toAddresses, function (newAddresses) {
            $scope.message.toAddresses = newAddresses;
        });
    }

    function onFileSelect($files) {
        var uploadFiles = function () {
            $scope.selectedFiles = $files;
            for (var i = 0; i < $files.length; i++) {
                $scope.start(i);
            }
        };
        if (!draftInContext()) {
            ReleaseService.saveDraft($scope.draftId, $scope.message, $scope.attachments, $scope.patient).then(function (res) {
                $scope.draftId = res.id;
                $scope.message.isDraft = res.isDraft;
                uploadFiles();
            });
        } else {
            uploadFiles();
        }
    }

    function start(index) {
        if ($scope.selectedFiles[index].size > 20971520) {
            NotificationService.error("Your attachment exceeds the maximum (20MB) allowed size.");
            return;
        }
        var uploadProcessId = _.uniqueId("upload_attachment_process_"),
            now = moment().utc().toDate(),
            meta = {
                documentType: null,
                confidentiality: 0,
                documentDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
                documentDescription: ""
            };

        $scope.uploading = 1;
        $scope.upload[uploadProcessId] = AttachmentService.uploadAttachment($scope.draftId, $scope.selectedFiles[index], meta)
            .then(function (response) {
                // trigger onAttachmentUploaded
                $rootScope.$emit("onAddAttachmentSuccess", {
                    uploadProcessId: uploadProcessId,
                    attachment: response.data
                });
            })
            .catch(function (response) {
                if (response.data.message == "FileType is not supported for upload") {
                    $rootScope.$emit("onAddAttachmentExtensionError", {
                        uploadProcessId: uploadProcessId,
                        fileName: $scope.selectedFiles[index].name
                    });
                } else {
                    $rootScope.$emit("onAddAttachmentError", {
                        uploadProcessId: uploadProcessId,
                        fileName: $scope.selectedFiles[index].name
                    });
                }
            });

        $rootScope.$emit("onAddAttachmentProcessStarted", {
            uploadProcessId: uploadProcessId,
            fileName: $scope.selectedFiles[index].name
        });
    }

    function isAttachmentProcessing() {
        if (typeof $scope.attachmentsInProcess == "undefined") return false;
        return !!$scope.attachmentsInProcess.length;
    }

    function removeAttachmentInProcess(uploadProcessId) {
        $scope.attachmentsInProcess = _.reject($scope.attachmentsInProcess, function (attachment) {
            return attachment.uploadProcessId === uploadProcessId;
        });
        if ($scope.upload) {
            delete $scope.upload[uploadProcessId];
        }
    }

    function populateFromAddress() {
        if ($scope.availableFromAddresses.length == 1 && _.isEmpty($scope.message.fromAddress)) {
            $timeout(function () {
                $scope.message.fromAddress = $scope.availableFromAddresses[0];
            }, 500);
        }
    }
}
