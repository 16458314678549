<kno2-header />
<div id="container">
    <div class="row">
        <kno2-sidebar
            id="sidebar-left"
            class="col-lg-2 col-sm-1"
            [class.minified]="sidebarCollapsed()" />
        <div
            id="content"
            style="height: 100%"
            class="col-lg-10 col-sm-11 container"
            [class.sidebar-minified]="sidebarCollapsed()">
            <div
                class="container"
                style="margin-top: 10px">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<kno2-footer />
