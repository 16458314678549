(function () {
    "use strict";

    angular.module("kno2.records").controller("FacilitySearch", facilitySearch);

    facilitySearch.$inject = ["$scope", "$uibModalInstance", "_", "CommonData", "FacilitiesService"];

    function facilitySearch($scope, $uibModalInstance, _, commonData, facilitiesService) {
        $scope.search = search;
        $scope.select = select;
        $scope.isSelected = isSelected;
        $scope.okDisabled = okDisabled;
        $scope.ok = ok;
        $scope.close = close;

        activate();

        function activate() {
            $scope.isSearching = false;
            $scope.selected = { facility: null };
            $scope.states = commonData.states.asArray();

            _.mixin({
                compact: function (o) {
                    var clone = _.clone(o);
                    _.each(clone, function (v, k) {
                        if (!v) {
                            delete clone[k];
                        }
                    });
                    return clone;
                }
            });
        }

        function search(query) {
            // if the query isn't null and all of the properties aren't falsy
            if (query && _.keys(_.compact(query)).length) {
                $scope.isSearching = true;
                facilitiesService.getFacilitiesByQuery(query).then(function (data) {
                    $scope.facilities = data;
                    $scope.isSearching = false;
                });
            }
        }

        function select(facility) {
            if (isSelected(facility)) $scope.selected.facility = null;
            else $scope.selected.facility = facility;
        }

        function isSelected(facility) {
            return facility === $scope.selected.facility;
        }

        function okDisabled() {
            return !$scope.selected.facility;
        }

        function ok() {
            $uibModalInstance.close($scope.selected.facility);
        }

        function close() {
            $uibModalInstance.dismiss("cancel");
        }
    }
})();
