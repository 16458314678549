<div class="modal-header">
    <button
        (click)="activeModal.close()"
        type="button"
        class="close"
        aria-hidden="true">
        &times;
    </button>
    <div class="modal-title">
        <h2>Configure Directory Entry - {{ directoryEntry().directoryEntryType | titlecase }}</h2>
        <h5>Kno2 Organization ID: {{ directoryEntry().kno2OrganizationId || "N/A" }}</h5>
    </div>
</div>
<div class="modal-body px-4">
    <form
        id="directoryEntryForm"
        #directoryEntryForm="ngForm"
        class="row"
        name="editDirectoryEntryForm"
        role="form">
        <div class="col-11">
            <div class="form-group row">
                <label
                    for="DirectoryOid"
                    class="col-3 control-label text-right col-form-label px-0">
                    Directory OID
                </label>
                <div class="col-8">
                    <div class="input-group">
                        <input
                            [(ngModel)]="directoryEntry().id"
                            type="text"
                            class="form-control"
                            name="DirectoryOid"
                            placeholder="Directory OID"
                            [readonly]="!allowEditOid()"
                            (ngModelChange)="onOidChange()"
                            required />
                        @if (this.isNewEntry()) {
                            <div class="input-group-append">
                                <a
                                    kno2NoOpLink
                                    title="Edit"
                                    class="btn"
                                    (click)="allowEditOid.set(true)">
                                    <i class="fa fa-pencil"></i>
                                </a>
                            </div>
                            <div class="invalid-feedback">Directory OID is required.</div>
                        }
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="HomeCommunityId"
                    class="col-3 control-label text-right col-form-label px-0">
                    HomeCommunityId
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntry().homeCommunityId"
                        type="text"
                        class="form-control"
                        name="HomeCommunityId"
                        placeholder="HomeCommunityId" />
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="DisplayName"
                    class="col-3 control-label text-right col-form-label px-0">
                    Display Name
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntry().name"
                        type="text"
                        class="form-control"
                        name="DisplayName"
                        placeholder="Display Name"
                        required />
                    <div class="invalid-feedback">Display name is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="LegalName"
                    class="col-3 control-label text-right col-form-label px-0">
                    Legal Name
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntry().name"
                        type="text"
                        class="form-control"
                        name="LegalName"
                        placeholder="Legal Name" />
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="OtherName"
                    class="col-3 control-label text-right col-form-label px-0">
                    Other Name
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntry().name"
                        type="text"
                        class="form-control"
                        name="OtherName"
                        placeholder="Other Name" />
                </div>
            </div>
            @if (directoryEntry().directoryEntryType !== DirectoryEntryType.Participant) {
                <div class="form-group row">
                    <label
                        for="ParentDirectoryOid"
                        class="col-3 control-label text-right col-form-label px-0">
                        Parent Directory OID
                    </label>
                    <div class="col-8">
                        <input
                            [(ngModel)]="directoryEntry().parentId"
                            #parentDirectoryOidFormControl="ngModel"
                            type="text"
                            class="form-control"
                            name="ParentDirectoryOid"
                            placeholder="Parent Directory OID" />
                        <div class="invalid-feedback">A parent OID is required for child entries.</div>
                    </div>
                </div>
            }
            <div class="form-group row">
                <label
                    for="Address"
                    class="col-3 control-label text-right col-form-label px-0">
                    Street
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().street1"
                        type="text"
                        class="form-control"
                        name="Address"
                        placeholder="Street"
                        required />
                    <div class="invalid-feedback">Street is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="City"
                    class="col-3 control-label text-right col-form-label px-0">
                    City
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().city"
                        type="text"
                        class="form-control"
                        name="City"
                        placeholder="City"
                        required />
                    <div class="invalid-feedback">City is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="State"
                    class="col-3 control-label text-right col-form-label px-0">
                    State
                </label>
                <div class="col-8">
                    <select
                        [(ngModel)]="directoryEntryOrganizationAddress().state"
                        class="form-control"
                        name="State"
                        required>
                        @for (state of commonData.states.asArray(); track $index) {
                            <option [value]="state.abbr">{{ state.name }}</option>
                        }
                    </select>
                    <div class="invalid-feedback">State is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="Zip"
                    class="col-3 control-label text-right col-form-label px-0">
                    Zip
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().zipCode"
                        type="text"
                        class="form-control"
                        name="Zip"
                        placeholder="Zip"
                        required />
                    <div class="invalid-feedback">Zip is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="Country"
                    class="col-3 control-label text-right col-form-label px-0">
                    Country
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().country"
                        type="text"
                        class="form-control"
                        name="Country"
                        placeholder="Country"
                        required />
                    <div class="invalid-feedback">Country is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="InboundMessageAddress"
                    class="col-3 control-label text-right col-form-label px-0 mt-n2">
                    Inbound Message Kno2 Address
                </label>
                <div class="col-8">
                    <select
                        [(ngModel)]="directoryEntry().inboundMessageAddress"
                        class="form-control"
                        name="InboundMessageAddress">
                        @if (!directoryEntry().inboundMessageAddress) {
                            <option hidden>Select an inbound address</option>
                        }
                        @for (documentSource of documentSources(); track $index) {
                            <option [selected]="documentSource === directoryEntry().inboundMessageAddress">{{ documentSource }}</option>
                        }
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="purposeOfUseCode"
                    class="col-3 control-label text-right col-form-label px-0">
                    Purpose of Use Codes
                </label>
                <div class="col-8">
                    <select
                        [(ngModel)]="directoryEntry().purposeOfUseCodes"
                        size="8"
                        multiple
                        required
                        class="form-control"
                        name="purposeOfUseCodes">
                        @for (purposeOfUse of purposesOfUse(); track $index) {
                            <option [value]="purposeOfUse.code">{{ purposeOfUse.description }}</option>
                        }
                    </select>
                    <div class="invalid-feedback">At least one purpose of use code is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="InitiatorOnly"
                    class="col-3 text-right px-0">
                    Initiator Only
                </label>
                <div class="col-8">
                    <input
                        [(ngModel)]="allowSelectInitiatorOnlyType"
                        type="checkbox"
                        name="InitiatorOnly" />
                </div>
            </div>
            <div class="form-group row mt-n2">
                <label
                    for="InitiatorOnlyType"
                    class="col-3 control-label text-right col-form-label px-0">
                    Initiator Type
                </label>
                <div class="col-8">
                    <select
                        [(ngModel)]="directoryEntry().initiatorOnlyType"
                        [class.disabled]="!allowSelectInitiatorOnlyType()"
                        [disabled]="!allowSelectInitiatorOnlyType()"
                        class="form-control"
                        name="InitiatorOnlyType"
                        [required]="allowSelectInitiatorOnlyType()">
                        <option
                            disabled
                            [value]="null">
                            {{ allowSelectInitiatorOnlyType() ? "Select an initiator type" : "" }}
                        </option>
                        @for (initiatorOnlyType of InitiatorOnlyType | keyvalue; track $index) {
                            <option [value]="initiatorOnlyType.value">{{ initiatorOnlyType.key | uppercase }}</option>
                        }
                    </select>
                    <div class="invalid-feedback">Initiator type is required if set to initiator only.</div>
                </div>
            </div>
            @if (this.hasQhinQueryEnabled() && this.directoryEntry().kno2OrganizationId) {
                <div class="form-group row">
                    <label
                        for="ResponderFilter"
                        class="col-3 control-label text-right col-form-label px-0">
                        Responder Filter
                    </label>
                    <div class="col-8">
                        <select
                            class="form-control"
                            [class.disabled]="!canDisplayResponderFilters()"
                            [(ngModel)]="directoryEntry().responderFilter"
                            [disabled]="!canDisplayResponderFilters()"
                            name="ResponderFilters"
                            [required]="canDisplayResponderFilters()">
                            <option
                                disabled
                                selected
                                [value]="null">
                                {{ allowSelectInitiatorOnlyType() ? "" : "Select a responder filter" }}
                            </option>
                            @for (filter of ResponderFilters | keyvalue; track $index) {
                                <option [value]="filter.value">
                                    {{ filter.value | titlecase }}
                                </option>
                            }
                        </select>
                    </div>
                    <i
                        data-toggle="tooltip"
                        title="HRR (default): Inbound queries will be filtered to those where the patient has an address in the same Hospital Referral Region as the responding organization's address. None: responder receives all inbound XCPD queries."
                        data-placement="left"
                        class="fa fa-info-circle box-info"></i>
                </div>
            }
            <div class="form-group row">
                <label
                    for="IsActive"
                    class="col-3 text-right px-0">
                    Is Active
                </label>
                <div class="col-8">
                    <input
                        disabled
                        [checked]="directoryEntry().isActive"
                        type="checkbox"
                        name="IsActive" />
                </div>
            </div>
            <!-- <div class="form-group row">
                <label
                    for="ActivationDate"
                    class="col-3 control-label text-right col-form-label px-0">
                    Registry Activation Date
                </label>
                <div class="col-8">
                    <input
                        readonly
                        [value]="directoryEntry().created | date: 'MMM d, y'"
                        type="text"
                        class="form-control"
                        name="ActivationDate" />
                </div>
            </div> -->
        </div>
    </form>
</div>
<div class="col-12 modal-footer">
    <button
        (click)="activeModal.close()"
        type="button"
        class="btn btn-default">
        Cancel
    </button>
    <button
        form="directoryEntryForm"
        (click)="directoryEntryForm.control.markAllAsTouched() || (directoryEntryForm.valid && saveDirectoryEntry())"
        type="submit"
        class="btn btn-primary">
        <span>Save</span>
    </button>
</div>
