export function AdminAuditsService($http) {
    return {
        get
    };

    function get(query) {
        return $http.post("/api/admin/audits", query).then((res) => res.data);
    }
}

AdminAuditsService.$inject = ["$http"];
