declare const angular;
declare const _;

let ctrl;
class DocuSignConnectFormController {
    public static $inject: Array<string> = ["DocumentSourcesService", "IntegrationsService", "SessionService", "WorkflowsProcessingService"];

    public documentSourceOptions: Array<any>;
    public connectedIntegrationTypes: Array<any>;
    public envelopeCustomFields: Array<any>;
    public integration: any;
    public saveChanges: () => any;
    public processingWorkflow: any;
    public matchingCriteriaIsEmpty: boolean;

    constructor(
        private DocumentSourcesService,
        private IntegrationsService,
        private SessionService,
        private WorkflowsProcessingService
    ) {}

    public $onInit(): void {
        this.getDocumentSources();
        this.getConnectedIntegrationTypes();
        this.getPatientKeys();
        this.getProcessingWorkflow();
        this.matchingCriteriaIsEmpty = this.isMatchingCriteriaEmpty();
    }

    private getDocumentSources(): void {
        this.DocumentSourcesService.getDocumentSources("asc", 1, 100).then((data) => {
            this.documentSourceOptions = data.documentSources.map((x) => x.address);
        });
    }

    private getProcessingWorkflow(): void {
        const profile = this.SessionService.getProfile();

        if (!!this.integration.values.ProcessingWorkflowId) {
            this.WorkflowsProcessingService.getById(profile.organizationId, this.integration.values.ProcessingWorkflowId).then((data) => {
                this.processingWorkflow = data.data;
            });
        }
    }

    private getConnectedIntegrationTypes(): void {
        this.IntegrationsService.listConnectedTypes().then((data) => {
            this.connectedIntegrationTypes = data.map((x) => {
                return { name: x.name, value: x.type };
            });
        });
    }

    private getPatientKeys(): void {
        this.IntegrationsService.getPatientKeys("DocusignConnect.DocusignEnvelopeCustomField").then((data) => {
            this.envelopeCustomFields = data;
        });
    }

    private isMatchingCriteriaEmpty(): boolean {
        return (
            this.integration.values.DocusignMatchingCriteriaFields == null || Object.keys(this.integration.values.DocusignMatchingCriteriaFields).length === 0
        );
    }

    private isForwardViaDirect(): boolean {
        return this.integration.values.ConnectedIntegration.indexOf("ForwardViaDirectIntegration") > -1;
    }

    private shouldCreateWorkflow(): boolean {
        return !this.processingWorkflow && this.isForwardViaDirect();
    }

    private shouldDeleteWorkflow(): boolean {
        return !!this.processingWorkflow && !this.isForwardViaDirect();
    }

    private shouldUpdateWorkflow(): boolean {
        return !!this.processingWorkflow;
    }

    public getExistingCriteriaValue(): boolean {
        return this.integration.id && !this.matchingCriteriaIsEmpty;
    }

    public showMatchingCriteria(): boolean {
        const pccIntegration = "Inofile.Kno2.Integration.Integrations.PointClickCare.PointClickCareIntegration";
        return this.integration.values.ConnectedIntegration === pccIntegration && this.integration.values.AutoProcessDocuments;
    }

    public saveDocusignIntegration(): void {
        this.saveChanges();
    }
}

export class DocuSignConnectFormComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./docusign-connect-form.component.html");
        this.controller = DocuSignConnectFormController;
        this.bindings = {
            dismissModal: "&",
            saveChanges: "&",
            selectedIntegration: "=",
            savingIntegration: "=",
            integration: "="
        };
    }
}
