signaturesRoutes.$inject = ["$stateProvider"];

export function signaturesRoutes($stateProvider) {
    $stateProvider
        .state("signatures", {
            url: "/signatures",
            parent: "home",
            component: "signaturesComponent"
        })
        .state("signatureRequestNew", {
            url: "/signatures/new",
            parent: "home",
            component: "signaturesNewDraftComponent"
        })
        .state("signatureRequestDraft", {
            url: "/signatures/draft/:id",
            parent: "home",
            component: "signaturesNewDraftComponent",
            reloadOnSearch: false
        });
}
