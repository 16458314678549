angular.module("kno2.factories").factory("SubscriptionsFactory", [
    "$http",
    "CacheService",
    ($http, CacheService) => {
        return {
            get: get,
            update: update,
            cancel: cancel,
            reactivate: reactivate,
            fastspring: fastspring
        };

        function get(organizationId) {
            return $http.get("/api/subscriptionplans/subscriptions" + (organizationId ? "?organizationId=" + organizationId : ""), {
                cache: CacheService.cacheL2()
            });
        }

        function update(organizationId, planId) {
            CacheService.clear("/subscriptionplans");
            var params = {
                organizationId: organizationId ? organizationId : null,
                planId: planId
            };
            return $http.post("/api/subscriptionplans/subscriptions", null, { params: params }).then(function (response) {
                return response.data;
            });
        }

        function cancel(reason) {
            var request = {
                cancelReason: reason
            };
            return $http.post("/api/subscriptionplans/cancel", request);
        }

        function reactivate(planId) {
            var params = {
                planId: planId
            };
            return $http.post("/api/subscriptionplans/reactivate/", params);
        }

        function fastspring(organizationId) {
            return $http.get("/api/subscriptionplans/fastspring/" + organizationId);
        }
    }
]);
