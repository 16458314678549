import { HttpClient } from "@angular/common/http";
import { Injectable, Signal, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { filterNonEmptyProperties } from "@kno2/common/utils";
import { Observable, firstValueFrom, shareReplay } from "rxjs";

export interface DownloadableFile {
    id: number;
    brandId: string;
    displayName: string;
    downloadTag: string;
    description: string;
    platform: { os: string; version: string; supportedVersions: string[] };
    isDownloadable: boolean;
    isVisible: boolean;
    downloadOnFree: boolean;
}

@Injectable({
    providedIn: "root"
})
export class DownloadsService {
    private cache: Record<string, Observable<DownloadableFile[]>> = {};
    private httpClient = inject(HttpClient);

    public getPages = (params: {
        sortDir?: "asc" | "desc";
        pageNumber?: number;
        pageSize?: number;
        search?: string;
    }): Signal<{ downloadableFiles: DownloadableFile[]; total: number }> => toSignal(this.getPages$(params));
    public getAll = (): Signal<DownloadableFile[]> => toSignal(this.getAll$());
    public getById = (id: number): Signal<DownloadableFile> => toSignal(this.getById$(id));
    public upload = (download: DownloadableFile, file): Promise<void> => firstValueFrom(this.upload$(download, file));
    public update = (download: DownloadableFile): Promise<void> => firstValueFrom(this.update$(download));
    public delete = (id: number): Promise<void> => firstValueFrom(this.delete$(id));
    public activate = (id: number): Promise<void> => firstValueFrom(this.activate$(id));
    public deactivate = (id: number): Promise<void> => firstValueFrom(this.deactivate$(id));

    private getPages$(
        params: {
            sortDir?: "asc" | "desc";
            pageNumber?: number;
            pageSize?: number;
            search?: string;
        } = {}
    ): Observable<{ downloadableFiles: DownloadableFile[]; total: number }> {
        return this.httpClient.get<{ downloadableFiles: DownloadableFile[]; total: number }>("/api/downloads/getpaged", {
            params: filterNonEmptyProperties(params)
        });
    }

    private getAll$(): Observable<DownloadableFile[]> {
        const url = "/api/downloads";
        if (!this.cache[url]) {
            this.cache[url] = this.httpClient.get<DownloadableFile[]>("/api/downloads").pipe(shareReplay(1));
        }

        return this.cache[url];
    }

    private getById$(id: number): Observable<DownloadableFile> {
        return this.httpClient.get<DownloadableFile>(`/api/downloads/${id}`);
    }

    private upload$(downloadableFileResource: DownloadableFile, file: File): Observable<void> {
        const formData = new FormData();

        formData.append("downloadableFileResource", JSON.stringify(downloadableFileResource));
        formData.append("file", file);

        return this.httpClient.post<void>("/api/downloads/upload", formData);
    }

    private update$(downloadableFileResource: DownloadableFile): Observable<void> {
        return this.httpClient.put<void>(`/api/downloads/${downloadableFileResource.id}/update`, downloadableFileResource);
    }

    private delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(`/api/downloads/${id}`);
    }

    private activate$(id: number): Observable<void> {
        return this.httpClient.post<void>(`/api/downloads/${id}/activate`, null);
    }

    private deactivate$(id: number): Observable<void> {
        return this.httpClient.post<void>(`/api/downloads/${id}/deactivate`, null);
    }
}
