import { Directive, TemplateRef, ViewContainerRef, inject, input } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";

@Directive({
    selector: "[kno2FeatureToggleHide]",
    standalone: true
})
export class FeatureToggleHideDirective {
    private readonly upgrade = inject(UpgradeModule);
    private readonly featureService = this.upgrade.$injector.get("FeatureService");
    private readonly templateRef = inject(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);

    protected requiredFeatures = input.required<string[]>({ alias: "kno2FeatureToggleHide" });

    public ngOnInit(): void {
        if (!this.featureService.isEnabled(this.requiredFeatures())) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
