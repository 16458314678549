import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, Input, OnInit, computed, inject, signal } from "@angular/core";
import { NoOpLink } from "@kno2/common/directives";
import { DirectoryClient } from "@kno2/data-access/directory";
import {
    AddressType,
    ContactType,
    DirectoryEntryQueryResult,
    DirectoryEntrySummaryResult,
    DirectoryEntryType,
    Governance,
    QhinMetadata
} from "@kno2/data-access/directory/directory-api.service";
import { OrganizationFeaturesService } from "@kno2/data-access/organization-features";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";
import { cloneDeep } from "lodash";
import { firstValueFrom } from "rxjs";
import { AdminOrganizationsQhinDetailsModalComponent } from "./admin-organizations-qhin-details-modal.component";
import AdminOrganizationsQhinDoaModalComponent from "./admin-organizations-qhin-doa-modal.component";

@Component({
    selector: "kno2-admin-organizations-qhin",
    standalone: true,
    imports: [DatePipe, TitleCasePipe, NoOpLink],
    templateUrl: "./admin-organizations-qhin.component.html"
})
export class AdminOrganizationsQhinComponent implements OnInit {
    DirectoryEntryType = DirectoryEntryType;
    @Input() public organization: any;

    private readonly organizationFeatureService = inject(OrganizationFeaturesService);
    private readonly ngbModalService = inject(NgbModal);
    private readonly directoryClient = inject(DirectoryClient);

    private isQhinQueryEnabled: boolean;

    public qhinDirectoryEntries = signal<QhinMetadata[]>([]);
    public directoryEntry = signal<DirectoryEntryQueryResult>(null);
    public childDirectoryEntries = signal<DirectoryEntrySummaryResult[]>([]);
    public allDirectoryEntries = computed(() => [this.directoryEntry(), ...this.childDirectoryEntries()].filter(Boolean));

    async ngOnInit(): Promise<void> {
        await this.refreshDirectoryEntries();
        this.isQhinQueryEnabled = await this.enabledFeaturesContainsQhinQuery(this.organization.enabledFeatures);
        this.qhinDirectoryEntries.set(await firstValueFrom(this.directoryClient.qhinMetadata(Governance.Tefca)));
    }

    public isDirectoryEntryEditable(directoryEntry: DirectoryEntrySummaryResult) {
        return !directoryEntry.kno2OrganizationId || directoryEntry.kno2OrganizationId === this.organization.organizationId;
    }

    public async showQhinDetailsModal(directoryEntry: Partial<DirectoryEntryQueryResult>) {
        const parentDirectoryEntry =
            directoryEntry.directoryEntryType === DirectoryEntryType.Participant ? this.getKno2DirectoryEntry() : this.directoryEntry();
        const editableDirectoryEntry = cloneDeep(
            directoryEntry.id
                ? await firstValueFrom(this.directoryClient.directoryEntriesGET(directoryEntry.id))
                : {
                      id: null,
                      kno2OrganizationId: this.directoryEntry() ? null : this.organization.organizationId,
                      name: this.organization.name,
                      homeCommunityId: null,
                      endpoints: this.organization.endpoints,
                      parentId: parentDirectoryEntry?.id,
                      governance: Governance.Tefca,
                      directoryEntryType: directoryEntry.directoryEntryType,
                      responderFilter: directoryEntry.responderFilter,
                      purposeOfUseCodes: this.organization.purposeOfUseCodes,
                      source: this.organization.source,
                      contacts: [
                          {
                              contactType: ContactType.Organization,
                              addresses: [
                                  {
                                      addressType: AddressType.OrganizationLocation,
                                      street1: this.organization.address.street,
                                      city: this.organization.address.city,
                                      zipCode: this.organization.address.zip,
                                      state: this.organization.address.state,
                                      country: this.organization.address.country
                                  }
                              ]
                          }
                      ]
                  }
        );

        const { componentInstance, result } = this.ngbModalService.open(AdminOrganizationsQhinDetailsModalComponent, {
            windowClass: "modal-1000",
            backdrop: false
        }) as { componentInstance: AdminOrganizationsQhinDetailsModalComponent; result: Promise<any> };

        componentInstance.directoryEntry.set(editableDirectoryEntry);
        componentInstance.organizationId.set(this.organization.organizationId);
        componentInstance.isNewEntry.set(!directoryEntry?.id);
        componentInstance.hasQhinQueryEnabled.set(this.isQhinQueryEnabled);

        await result.catch(noop);
        await this.refreshDirectoryEntries();
    }

    public async showQhinDoaModal(directoryEntry: DirectoryEntryQueryResult) {
        const { componentInstance, result } = this.ngbModalService.open(AdminOrganizationsQhinDoaModalComponent, {
            windowClass: "modal-1000",
            backdrop: false
        }) as { componentInstance: AdminOrganizationsQhinDoaModalComponent; result: Promise<any> };

        componentInstance.directoryEntry.set(directoryEntry);
        componentInstance.qhinDirectoryEntries.set(this.qhinDirectoryEntries());

        await result.catch(noop);
        await this.refreshDirectoryEntries();
    }

    private async enabledFeaturesContainsQhinQuery(ids: string[]): Promise<boolean> {
        const features = await this.organizationFeatureService.getFeatures();
        return features.some((feature) => feature.name === "QhinQuery" && ids.includes(feature.id));
    }

    private getKno2DirectoryEntry(): QhinMetadata {
        const kno2DirectoryEntry = this.qhinDirectoryEntries().find((f) => f.name == "Kno2");

        if (!kno2DirectoryEntry) throw new Error(`Directory API returned unexpected results when querying for Kno2 entry`);

        return kno2DirectoryEntry;
    }

    private async refreshDirectoryEntries(): Promise<void> {
        try {
            this.directoryEntry.set(
                await firstValueFrom(this.directoryClient.directoryEntriesGET2(this.organization.organizationId, undefined, undefined, false))
            );

            this.childDirectoryEntries.set(await firstValueFrom(this.directoryClient.childrenAll(this.directoryEntry().id, undefined, false)));
        } catch (e) {}
    }
}
