import angular from "angular";
import identityProviderRoutes from "./identity-provider.routes";
import { networkIdentityProviderComponent } from "./network-identity-provider.component";
import { manageNetworkIdentityProviderComponent } from "./manage-network-identity-provider.component";
import { networkIdentityProviderUserProvisioningComponent } from "./network-identity-provider-user-provisioning.component";
import { networkIdentityProviderUserGroupsComponent } from "./network-identity-provider-user-groups";
import { NetworkIdentityProviderService } from "../../common/services/network-identity-provider.service";
import { NetworkUserGroupsService } from "../../common/services/network-user-groups.service";
import { networkIdentityProviderUserGroupDetailModalComponent } from "./network-identity-provider-user-group-detail-modal";

export const ManageNetworkIdentityProviderModule = angular
    .module("manageNetworkIdentityProvider", ["kno2.services"])
    .config(identityProviderRoutes)
    .service("NetworkIdentityProviderService", NetworkIdentityProviderService)
    .service("NetworkUserGroupsService", NetworkUserGroupsService)
    .component("manageNetworkIdentityProvider", manageNetworkIdentityProviderComponent)
    .component("networkIdentityProvider", networkIdentityProviderComponent)
    .component("networkIdentityProviderUserProvisioning", networkIdentityProviderUserProvisioningComponent)
    .component("networkIdentityProviderUserGroups", networkIdentityProviderUserGroupsComponent)
    .component("networkIdentityProviderUserGroupDetailModal", networkIdentityProviderUserGroupDetailModalComponent).name;

