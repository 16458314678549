import templateUrl from "./admin-documentsource-status.modal.component.html";

export const AdminDocumentSourcesStatusModal = {
    templateUrl: templateUrl,
    controller: DocumentSourceStatusController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

DocumentSourceStatusController.$inject = ["AdminDocumentSourcesService", "NotificationService", "$timeout"];

export function DocumentSourceStatusController(AdminDocumentSourcesService, NotificationService, $timeout) {
    const ctrl = this;
    ctrl.$onInit = onInit;

    function onInit() {
        ctrl.documentSource = ctrl.resolve.documentSource;
        ctrl.currentStatus = ctrl.documentSource.statusName.replace(/\s/g, "");
        ctrl.updateSureScripts = true;
        AdminDocumentSourcesService.getDocumentSourceStatuses().then(function (response) {
            ctrl.statuses = response;
        });
    }

    ctrl.cancel = function () {
        ctrl.dismiss();
    };

    ctrl.save = function () {
        AdminDocumentSourcesService.setDocumentSourceStatus(ctrl.documentSource.id, ctrl.currentStatus, ctrl.updateSureScripts).then(function (results) {
            NotificationService.success("Status updated");
            ctrl.close();
        });
    };
}
