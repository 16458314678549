(function () {
    "use strict";

    angular.module("kno2.settings").controller("RuleDeleteCtrl", RuleDeleteCtrl);

    RuleDeleteCtrl.$inject = ["$uibModalInstance", "items", "RulesService"];

    function RuleDeleteCtrl($uibModalInstance, ruleId, RulesService) {
        const ctrl = this;

        ctrl.ok = ok;
        ctrl.cancel = cancel;

        ctrl.$onInit = activate;

        function activate() {
            RulesService.getRule(ruleId).then(function (result) {
                ctrl.deleteRule = result;
            });
        }

        function ok() {
            RulesService.deleteRule(ruleId).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss("cancel");
        }
    }
})();
