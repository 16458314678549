export class MessagingHealthService {
    constructor($http) {
        this.$http = $http;
    }

    getIncomingMessageCount() {
        return this.$http.get("/api/diagnostics/messaging/messageCounts").then(function (response) {
            return response.data;
        });
    }

    getSystemAlerts() {
        return this.$http.get("/api/diagnostics/messaging/systemalerts").then(function (response) {
            return response.data;
        });
    }

    getWorkflows(queryParams, pageNumber, pageSize) {
        return this.$http.get(`/api/diagnostics/messaging/workflows`, {
            params: {
                ...queryParams,
                pageNumber: pageNumber,
                pageSize: pageSize
            }
        });
    }
}

MessagingHealthService.$inject = ["$http"];
