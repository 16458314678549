import { SettingsService } from "./settings.service";

export class BrandSettingsService {
    public static $inject: Array<string> = ["SettingsService", "SessionStorageFactory", "SessionService"];
    public static readonly ENTITY_TYPE = "Brand";
    public static readonly BRAND_SETTINGS_KEY = "brandSettings";

    constructor(
        private settingsService: SettingsService,
        private sessionStorageFactory,
        private sessionService
    ) {}

    public async load(): Promise<any> {
        const brandId = this.sessionService.getProfile().brandId;
        const response = await this.settingsService.getAll(brandId, BrandSettingsService.ENTITY_TYPE);
        const result = response.data.data;

        this.setBrandSettings(result);
    }

    public getBrandSettings(): any {
        return this.sessionStorageFactory.get(BrandSettingsService.BRAND_SETTINGS_KEY);
    }

    public setBrandSettings(settings: any): void {
        this.sessionStorageFactory.set(BrandSettingsService.BRAND_SETTINGS_KEY, settings);
    }

    public isEnabled(settingName: string): boolean {
        const settings = this.getBrandSettings();

        if (!settings[settingName]) return false;
        return settings[settingName].toLowerCase() === "true";
    }
}
