import templateUrl from "./multiple-service-account.component.html";

export const multipleServiceAccountComponent = {
    templateUrl: templateUrl,
    controller: MultipleServiceAccountController,
    bindings: {
        type: "<",
        group: "<"
    }
};

MultipleServiceAccountController.$inject = ["$http", "$uibModal", "ServiceAccountServiceFactory", "NotificationService", "SessionService"];

export function MultipleServiceAccountController($http, $uibModal, ServiceAccountServiceFactory, NotificationService, SessionService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.wizardInit = wizardInit;
    ctrl.getCredentials = getCredentials;
    ctrl.showClipboardCapture = showClipboardCapture;
    ctrl.toggleEnableStatus = toggleEnableStatus;
    ctrl.saveIpAddresses = saveIpAddresses;
    ctrl.confirmGenerate = confirmGenerate;
    ctrl.confirmDelete = confirmDelete;
    ctrl.showAccountDetails = showAccountDetails;
    ctrl.toggleCollapse = toggleCollapse;
    ctrl.toggleCollapseAll = toggleCollapseAll;
    ctrl.confirmGenerateAdditionalServiceAccount = confirmGenerateAdditionalServiceAccount;
    ctrl.hasServiceAccounts = hasServiceAccounts;
    const profile = SessionService.getProfile();
    ctrl.externalServiceAccountHasManagementAccess = profile.externalServiceAccountSettings.hasManagingExternalServiceAccount;
    ctrl.externalServiceAccountName = profile.externalServiceAccountSettings.externalServiceAccountName;
    ctrl.serviceAccounts = [];

    function $onInit() {
        ctrl.serviceAccountService = ServiceAccountServiceFactory.ofType(ctrl.type);
        ctrl.clientSecretClipboard = false;
        ctrl.group = ctrl.group || {};
    }

    function wizardInit(wizard) {
        ctrl.wizard = wizard;
        getClientApiSettings();
    }

    function getClientApiSettings() {
        if (!ctrl.group.id) {
            updateClientCredentialState();
            return;
        }

        ctrl.serviceAccountService.getServiceAccountLimit(ctrl.group.id).then(function (data) {
            ctrl.serviceAccountLimit = data.data;
        });

        return ctrl.serviceAccountService.getServiceAccounts(ctrl.group.id).then(
            function (clientSettings) {
                ctrl.hasClientCredentials = true;
                // Set inital client credential info
                // No Client Id then we are brand new
                if (clientSettings && clientSettings.filter((cs) => cs.clientId).length > 0) {
                    clientSettings.forEach((sa) => {
                        setExistingClientCredentialState(
                            sa.id,
                            sa.clientId,
                            null,
                            null,
                            sa.clientIpAddresses,
                            sa.serviceAccountEnabled,
                            sa.lastLoginDate,
                            sa.lastLoginIp,
                            sa.allowedAddressCount
                        );
                        ctrl.hasClientCredentials = true;
                        ctrl.clientEnabled = sa.serviceAccountEnabled;
                        ctrl.sourceIpAddress = sa.sourceIpAddress;
                        //
                        if (sa.clientIpAddresses.length > 0) {
                            ctrl.wizard.step3();
                            ctrl.wizard.show = false;
                            ctrl.wizard.complete = true;
                        } else {
                            ctrl.wizard.step2();
                        }
                    });
                } else {
                    updateClientCredentialState();
                    return;
                }
            },
            function () {
                updateClientCredentialState();
            }
        );
    }

    function setClientCredentialInfo(
        accountId,
        clientId,
        clientSecret,
        clientMessage,
        clientGenerateMessage,
        clientConfirmTitle,
        clientConfirmWarning,
        clientConfirmBody,
        ipAddresses,
        enabled,
        lastLoginDate,
        lastLoginIp,
        allowedAddressCount
    ) {
        var account = {
            id: accountId,
            clientId: clientId,
            clientSecret: clientSecret,
            clientEnabled: enabled,
            showIpAddressAddButton: true,
            clientIpAddresses: ipAddresses,
            expand: true,
            lastLoginDate: lastLoginDate,
            lastLoginIp: lastLoginIp,
            allowedAddressCount: allowedAddressCount
        };

        (ctrl.clientMessage = clientMessage),
            (ctrl.clientGenerateMessage = clientGenerateMessage),
            (ctrl.clientConfirmTitle = clientConfirmTitle),
            (ctrl.clientConfirmWarning = clientConfirmWarning),
            (ctrl.clientConfirmBody = clientConfirmBody);

        //If the empty base template exists and we are overriding it with actual credentials, delete it
        if (accountId) {
            ctrl.serviceAccounts = ctrl.serviceAccounts.filter((x) => x.id);
        }

        //If account with id already exists update the account
        if (ctrl.serviceAccounts.filter((x) => x.id === accountId).length > 0) {
            var accountIndex = ctrl.serviceAccounts.findIndex((x) => x.id === accountId);
            ctrl.serviceAccounts[accountIndex] = account;
        } else {
            ctrl.serviceAccounts.push(account);
        }
    }

    function updateClientCredentialState(accountId) {
        ctrl.serviceAccounts = ctrl.serviceAccounts.filter((x) => x.id != accountId);

        //If there are still other service account credentials do not reset to no credential state
        if (ctrl.serviceAccounts.length != 0) return;

        setClientCredentialInfo(
            null,
            null,
            null,
            null,
            "Create Client Credentials",
            "Generate New Client Key and Secret?",
            "Generating a client key and secret <u>will enable</u> access to your service account API.",
            "Are you sure you want to generate those credentials now?",
            [],
            true,
            null,
            null
        );

        ctrl.clientSecretClipboard = false;
        ctrl.hasClientCredentials = false;
        ctrl.wizard.complete = false;
        ctrl.wizard.step0();
    }

    function setExistingClientCredentialState(
        accountId,
        clientId,
        clientSecret,
        warningMessage,
        ipAddresses,
        enabled,
        lastLoginDate,
        lastLoginIp,
        allowedAddressCount
    ) {
        if (!clientSecret) {
            clientSecret = "**********************";
        }
        setClientCredentialInfo(
            accountId,
            clientId,
            clientSecret,
            warningMessage,
            "Regenerate",
            "Regenerate Existing Client Key and Secret?",
            "Regenerating your client key and secret <u>will deauthorize</u> current clients.",
            "Are you sure you want to regenerate now?",
            ipAddresses,
            enabled,
            lastLoginDate,
            lastLoginIp,
            allowedAddressCount
        );
    }

    function getCredentials(account) {
        return "Key: " + account.clientId + "\r\nSecret: " + account.clientSecret + "\r\n";
    }

    function showClipboardCapture() {
        if (ctrl.clientIpAddresses == undefined || ctrl.clientIpAddresses.length === 0) {
            ctrl.wizard.step2();
        }
        NotificationService.success("Client key and secret copied to your clipboard");
    }

    function toggleEnableStatus(account) {
        var state = account.clientEnabled ? "disable" : "enable";
        ctrl.serviceAccountService
            .toggleStatus(ctrl.group.id, account.id, state)
            .then(function (data) {
                account.clientEnabled = data.enabled;
                NotificationService.success("Client has been " + (account.clientEnabled ? "enabled" : "disabled"));
            })
            .catch(function () {
                NotificationService.error("An error occurred when updating client settings state");
            });
    }

    function saveIpAddresses(addresses, accountId) {
        if (addresses.length === 0) {
            NotificationService.error("Please add IP Addresses to save.", true);
            return;
        }

        ctrl.serviceAccountService.updateIpAddresses(ctrl.group.id, accountId, addresses).then(
            function (data) {
                NotificationService.success("IP Address Settings Updated");
                ctrl.wizard.step3();
                ctrl.clientIpAddresses = _.uniq(data.clientIpAddresses);
            },
            function (error) {
                var message = error.data.message ? error.data.message : "Failed to Update IP Addresses";
                NotificationService.error(message, true);
            }
        );
    }

    function atAccountLimit() {
        return ctrl.serviceAccounts && ctrl.serviceAccounts.length >= ctrl.serviceAccountLimit;
    }

    function confirmGenerate(accountId = "") {
        var modalInstance = $uibModal.open({
            component: "k2ClientCredentialsGenerateModal",
            resolve: {
                title: function () {
                    return ctrl.clientConfirmTitle;
                },
                warning: function () {
                    return ctrl.clientConfirmWarning;
                },
                body: function () {
                    return ctrl.clientConfirmBody;
                },
                button: function () {
                    return ctrl.clientGenerateMessage;
                },
                hasClientCredentials: function () {
                    return ctrl.hasClientCredentials;
                },
                group: function () {
                    return ctrl.group;
                },
                type: function () {
                    return ctrl.type;
                },
                accountId: function () {
                    return accountId;
                }
            }
        });

        modalInstance.result.then(
            function (result) {
                setExistingClientCredentialState(
                    result.id,
                    result.clientId,
                    result.clientSecret,
                    "Save your secret — this is the <u>last time</u> you will have access to it",
                    result.clientIpAddresses,
                    accountId,
                    result.lastLoginDate,
                    result.lastLoginIp,
                    result.allowedAddressCount
                );

                ctrl.clientSecretClipboard = true;
                NotificationService.success("API Settings Updated");

                if (!hasCompletedClientCredentials()) ctrl.wizard.step1();

                ctrl.hasClientCredentials = true;
            },
            function () {
                // cancelled
            }
        );
    }

    function confirmGenerateAdditionalServiceAccount() {
        if (atAccountLimit()) {
            NotificationService.errorToaster(`Service Account Limit of ${ctrl.serviceAccountLimit} Reached`);
            return;
        }

        var modalInstance = $uibModal.open({
            component: "k2ClientCredentialsGenerateModal",
            resolve: {
                title: function () {
                    return "Generate New Client Key and Secret?";
                },
                warning: function () {
                    return "Generating a client key and secret <u>will enable</u> access to your service account API.";
                },
                body: function () {
                    return "Are you sure you want to generate those credentials now?";
                },
                button: function () {
                    return "Create Client Credentials";
                },
                hasClientCredentials: function () {
                    return ctrl.hasClientCredentials;
                },
                group: function () {
                    return ctrl.group;
                },
                type: function () {
                    return ctrl.type;
                },
                accountId: function () {
                    return null;
                }
            }
        });

        modalInstance.result.then(
            function (result) {
                setExistingClientCredentialState(
                    result.id,
                    result.clientId,
                    result.clientSecret,
                    "Save your secret — this is the <u>last time</u> you will have access to it",
                    result.clientIpAddresses,
                    result.serviceAccountEnabled,
                    result.lastLoginDate,
                    result.lastLoginIp,
                    result.allowedAddressCount
                );

                ctrl.clientSecretClipboard = true;
                NotificationService.success("API Settings Updated");
            },
            function () {
                //oh noes
            }
        );
    }

    function confirmDelete(accountId) {
        var modalInstance = $uibModal.open({
            component: "k2ClientCredentialsDeleteModal",
            resolve: {
                group: function () {
                    return ctrl.group;
                },
                type: function () {
                    return ctrl.type;
                },
                accountId: function () {
                    return accountId;
                }
            }
        });

        modalInstance.result.then(
            function () {
                updateClientCredentialState(accountId);
            },
            function () {
                //oh noes
            }
        );
    }

    function hasServiceAccounts() {
        return ctrl.serviceAccounts && ctrl.serviceAccounts.filter((sa) => sa.clientId).length > 0;
    }

    function hasCompletedClientCredentials() {
        return (
            ctrl.serviceAccounts &&
            ctrl.serviceAccounts.filter((sa) => sa.clientId && sa.clientSecret && sa.ipAddresses && sa.ipAddresses.length > 0).length > 0
        );
    }

    function showAccountDetails(index) {
        return ctrl.serviceAccounts && ctrl.serviceAccounts.at(index).expand;
    }

    function toggleCollapse(index) {
        ctrl.serviceAccounts.at(index).expand = !ctrl.serviceAccounts.at(index).expand;
    }

    function toggleCollapseAll(expand) {
        ctrl.serviceAccounts.forEach((x) => (x.expand = expand));
    }
}
