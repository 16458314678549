import { Component, inject, OnInit } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { LocalStorageService } from "@kno2/core/storage";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "kno2-org-activation-delay",
    standalone: true,
    imports: [],
    templateUrl: "./org-activation-delay-modal.component.html"
})
export class OrgActivationDelayModalComponent implements OnInit {
    private readonly activeModal = inject(NgbActiveModal);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly sessionStorageFactory = this.upgrade.$injector.get("SessionStorageFactory");

    protected hidePrompt = false;

    public ngOnInit(): void {
        this.sessionStorageFactory.set("hideOrgActivationDelayModal", true);
    }

    protected cancel(): void {
        this.activeModal.dismiss();
    }

    protected ok(): void {
        if (this.hidePrompt) {
            this.localStorageService.set("hideOrgActivationDelayModal", this.hidePrompt);
        }

        this.activeModal.dismiss();
    }
}
