angular.module("kno2.factories").factory("usersFactory", [
    "$http",
    "CacheService",
    ($http, CacheService) => {
        return {
            list: list,
            userfororg: userfororg,
            user: user,
            create: create,
            save: save,
            delete: deleteUser,
            find: find,
            getJobFunctions: getJobFunctions,
            resetPassword: resetPassword,
            resetMfa,
            resendInvitation: resendInvitation,
            sendInvitation: sendInvitation,
            enable: enable,
            enableTFA: enableTFA,
            sendCode: sendCode,
            verifyCode: verifyCode,
            getQrCode: getQrCode,
            enableTokenAuth: enableTokenAuth,
            postOrgAssignment: postOrgAssignment,
            deleteOrgAssignment: deleteOrgAssignment,
            removeApplication: removeApplication
        };

        function list(sort, page, size, deleted, search, includeNetwork) {
            var params = {
                sort: JSON.stringify(sort),
                page: page || 1,
                size: size || 10,
                deleted: deleted || false,
                includeNetwork: includeNetwork
            };
            if (search) {
                params.search = search;
            }
            return $http.get("/api/users", { params: params }).then(function (response) {
                return response.data;
            });
        }

        function userfororg(id) {
            return $http.get("/api/admin/users/" + id + "/organization");
        }

        function user(id) {
            return $http.get("/api/users/" + id);
        }

        function create(user) {
            CacheService.clear("/users", "/subscriptionplans");
            return $http.post("/api/users", user);
        }

        function save(user) {
            CacheService.clear("/users", "/subscriptionplans");
            return $http.put("/api/users/" + user.id, user);
        }

        function deleteUser(id) {
            CacheService.clear("/users", "/subscriptionplans");
            return $http.delete("/api/users/" + id);
        }

        function removeApplication(id, appId) {
            return $http.delete("/api/users/" + id + "/applications/" + appId);
        }

        function find(username) {
            var params = {
                username: username,
                s: true
            };
            return $http.post("/api/users/find", null, { params: params }).then(function (response) {
                return response.data;
            });
        }

        function getJobFunctions() {
            return $http.get("/api/users/jobs");
        }

        function resetPassword(user) {
            return $http.post("/api/users/resetpassword", user);
        }

        function resetMfa(userId) {
            return $http.delete(`/api/users/${userId}/authenticators`, user);
        }

        function resendInvitation(user) {
            return $http.post("/api/users/resendinvitation", user);
        }

        function sendInvitation(invitation) {
            return $http.post("/api/users/sendinvitation", invitation);
        }

        function enable(user, enable) {
            CacheService.clear("/users", "/subscriptionplans");
            var params = {
                enable: enable ? "true" : "false"
            };
            return $http.post("/api/users/" + user.id + "/enable", null, { params: params }).then(function (response) {
                return response.data;
            });
        }

        function enableTFA(code) {
            return $http.get("/api/users/enabletfa?code=" + code);
        }

        function sendCode(method) {
            return $http.get("/api/users/sendcode?method=" + method);
        }

        function verifyCode(userId, verifyResource) {
            return $http.post(`/api/users/${userId}/verifycode`, verifyResource);
        }

        function getQrCode() {
            return $http.get("/api/users/enabletokenauth");
        }

        function enableTokenAuth(code) {
            return $http.post("/api/users/enabletokenauth", { code: code });
        }

        function postOrgAssignment(assignment) {
            CacheService.clear("/users", "/subscriptionplans");
            return $http.post("/api/admin/users/organizations/", assignment);
        }

        function deleteOrgAssignment(assignment) {
            CacheService.clear("/users", "/subscriptionplans");
            return $http.put("/api/admin/users/organizations/", assignment);
        }
    }
]);
