(function () {
    "use strict";

    angular.module("kno2.components").directive("k2FilterGroup", function () {
        return {
            controller: FilterGroupController,
            bindToController: true,
            scope: {
                singleFilter: "@",
                onFilterChange: "&"
            }
        };
    });

    FilterGroupController.$inject = [];

    function FilterGroupController() {
        var ctrl = this;

        ctrl.filters = [];
        ctrl.$onInit = $onInit;
        ctrl.addFilter = addFilter;
        ctrl.filterChanged = filterChanged;

        function $onInit() {
            ctrl.isSingleFilter = ctrl.singleFilter !== undefined;
        }

        function addFilter(filter) {
            ctrl.filters.push(filter);
        }

        function filterChanged(filter) {
            if (ctrl.isSingleFilter) resetFilters({ except: filter });
            else filter = getFilters();

            ctrl.onFilterChange({ $filter: filter });
        }

        function getFilters() {
            return ctrl.filters.map(mapFilter);
        }

        function mapFilter(ctrl) {
            return {
                prop: ctrl.prop,
                descending: ctrl.descending,
                search: ctrl.search
            };
        }

        function resetFilters(options) {
            ctrl.filters
                .filter(function (f) {
                    return f.prop !== options.except.prop;
                })
                .forEach(function (f) {
                    f.onReset();
                });
        }
    }
})();
