import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Router, provideRouter } from "@angular/router";
import { setUpLocationSync } from "@angular/router/upgrade";
import { UpgradeModule, downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { AuthClientConfig, provideAuth0 } from "@auth0/auth0-angular";
import { HttpUtilitiesService } from "@kno2/common/services";
import { AuthService, authInterceptor } from "@kno2/core/auth";
import { apiInterceptor, loadingInterceptor, tokenInterceptor } from "@kno2/core/http";
import { ApplicationInsightsService, LoadingIndicatorService } from "@kno2/core/services";
import { SessionService } from "@kno2/core/session";
import { LocalStorageService, provideLocalStorage } from "@kno2/core/storage";
import { DIRECTORY_API_BASE_URL } from "@kno2/data-access/directory";
import { EulasService } from "@kno2/data-access/eulas";
import { OrganizationFeaturesService } from "@kno2/data-access/organization-features";
import { SupportService } from "@kno2/data-access/support";
import { SocketService } from "@kno2/data-access/websockets";
import { AdminOrganizationsQhinComponent } from "@kno2/features/admin/organizations";
import { PatientAddModalComponent } from "@kno2/features/intake/message";
import { DeliverySummarySendToIntakeComponent } from "@kno2/features/release/delivery-summary-send-to-intake";
import { ContactsComponent } from "@kno2/features/settings/contacts";
import { AppConfigToken, CommonDataToken } from "@kno2/interop";
import { FooterComponent, HeaderComponent, SidebarComponent, SidebarService } from "@kno2/layout";
import { AdminModule } from "@kno2/ng1/admin/admin.module";
import { AdminDocumentSourcesService } from "@kno2/ng1/admin/documentsources/admin-documentsources.service";
import { AppConfig, AppConfigService } from "@kno2/ng1/app-config";
import { ConfirmationDialogService } from "@kno2/shared/confirmation-dialog";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as angular from "angular";
import { routes } from "./app.routes";

export const appConfig: ApplicationConfig = {
    providers: [
        provideAuth0(),
        provideRouter(routes),
        provideHttpClient(withInterceptors([loadingInterceptor, tokenInterceptor, apiInterceptor, authInterceptor])),
        provideLocalStorage({ keyPrefix: "kno2" }),
        importProvidersFrom([BrowserModule, UpgradeModule]),
        provideAnimations(),
        {
            provide: AppConfigToken,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("appConfig"),
            deps: [UpgradeModule]
        },
        {
            provide: AppConfigService,
            useFactory: () => {
                const injector = angular.injector(["ng", "app.config"]);
                const appConfigService: AppConfigService = injector.get("AppConfigService");
                return appConfigService;
            }
        },
        {
            provide: CommonDataToken,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("CommonData"),
            deps: [UpgradeModule]
        },
        {
            provide: DIRECTORY_API_BASE_URL,
            useFactory: (appConfig: AppConfig) => `${appConfig.baseApiUrl}/api/v2/directory`,
            deps: [AppConfigToken]
        },
        {
            provide: AdminDocumentSourcesService,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("AdminDocumentSourcesService"),
            deps: [UpgradeModule]
        },
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        },
        SocketService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfigService: AppConfigService, upgrade: UpgradeModule, authClientConfig: AuthClientConfig) => async () => {
                const appConfig = await appConfigService.load();
                authClientConfig.set({
                    domain: appConfig.auth0.domain,
                    clientId: appConfig.auth0.clientId,
                    errorPath: "/auth-error",
                    authorizationParams: {
                        redirect_uri: appConfig.auth0.redirectUri,
                        audience: appConfig.auth0.audience,
                        responseType: "token id_token",
                        scope: "openid offline_access"
                    }
                });

                angular.module("app.config").constant("appConfig", appConfig);

                const kno2ClientEl = document.getElementById("kno2-client");
                const kno2AdminEl = document.getElementById("kno2-admin");

                if (kno2ClientEl) {
                    getDefaultModules(angular.module("kno2.client"))
                        .service("HttpUtilitiesService", downgradeInjectable(HttpUtilitiesService))
                        .directive("kno2Contacts", downgradeComponent({ component: ContactsComponent }))
                        .directive("kno2PatientAddModal", downgradeComponent({ component: PatientAddModalComponent }))
                        .directive("kno2DeliverySummarySendToIntake", downgradeComponent({ component: DeliverySummarySendToIntakeComponent }));
                } else if (kno2AdminEl) {
                    getDefaultModules(angular.module(AdminModule.name)).directive(
                        "kno2AdminOrganizationsQhin",
                        downgradeComponent({ component: AdminOrganizationsQhinComponent })
                    );
                }

                function getDefaultModules(module) {
                    return module
                        .service("loadingIndicatorService", downgradeInjectable(LoadingIndicatorService))
                        .service("EulasService", downgradeInjectable(EulasService))
                        .service("ngxRouter", downgradeInjectable(Router))
                        .service("ngbModalService", downgradeInjectable(NgbModal))
                        .service("sidebarService", downgradeInjectable(SidebarService))
                        .service("Auth0Service", downgradeInjectable(AuthService))
                        .service("localStorageService", downgradeInjectable(LocalStorageService))
                        .service("ConfirmationDialogService", downgradeInjectable(ConfirmationDialogService))
                        .service("SupportService", downgradeInjectable(SupportService))
                        .service("SessionService", downgradeInjectable(SessionService))
                        .service("OrganizationFeaturesService", downgradeInjectable(OrganizationFeaturesService))
                        .directive("kno2Header", downgradeComponent({ component: HeaderComponent }))
                        .directive("kno2Sidebar", downgradeComponent({ component: SidebarComponent }))
                        .directive("kno2Footer", downgradeComponent({ component: FooterComponent }));
                }

                return new Promise((resolve) => {
                    if (kno2ClientEl) upgrade.bootstrap(kno2ClientEl, ["kno2.client"]);
                    if (kno2AdminEl) upgrade.bootstrap(kno2AdminEl, [AdminModule.name]);
                    setUpLocationSync(upgrade);
                    resolve(null);
                });
            },
            deps: [AppConfigService, UpgradeModule, AuthClientConfig],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfigService: AppConfigService, appInsightsService: ApplicationInsightsService, thing) => async (): Promise<void> => {
                const { appInsightInstrumentationKey: appInsightsInstrumentationKey } = (await appConfigService.load()) as any;

                appInsightsService.load(appInsightsInstrumentationKey);
                return;
            },
            deps: [AppConfigService, ApplicationInsightsService],
            multi: true
        }
    ]
};
