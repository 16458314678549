<footer class="d-flex justify-content-between">
    <div>
        <p class="copyright links">
            Copyright &copy; {{ year }}
            <span>|</span>
            <a
                routerLink="legalinformation">
                Legal Information
            </a>
            <span>|</span>
            <a
                routerLink="/policies/privacy"
                target="_blank">
                Privacy Policy
            </a>
            <span
                class="sys-admin-link"
                *kno2ShowForRoleIn="['Administrator', 'User']">
                <span>|</span>
                <a
                    routerLink="/supportcenter/contact">
                    Contact
                </a>
            </span>
            @if (aboutUsUrl) {
                <span>|</span>
                <a
                    [href]="aboutUsUrl"
                    target="_blank"
                    rel="noreferrer noopener">
                    About Us
                </a>
            }
        </p>
    </div>
    <div class="links">
        <span
            *kno2ShowForRoleIn="['SysAdmin']"
            class="light">
            {{ hostName }} Core (Build: {{ buildNumber }})
        </span>
        <img
            src="/content/images/ConnectedByKno2_small.png"
            alt="Connected By Kno2" />
    </div>
</footer>
