import { Component, inject, input, output } from "@angular/core";
import { RouterLink } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { NoOpLink } from "@kno2/common/directives";
import { AuthService } from "@kno2/core/auth";
import { ProfileModalComponent } from "@kno2/features/account/profile";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";

@Component({
    selector: "kno2-profile-menu",
    standalone: true,
    imports: [RouterLink, NoOpLink],
    templateUrl: "./profile-menu.component.html"
})
export class ProfileMenuComponent {
    private readonly modalService = inject(NgbModal);
    private readonly upgrade = inject(UpgradeModule);
    private readonly authService = inject(AuthService);
    private readonly cacheService = this.upgrade.$injector.get("CacheService");

    protected currentUser = input.required<{ fullName: string; userId: string }>();
    protected changingPassword = input<boolean>(false);

    public onProfileUpdated = output<void>();

    public editProfile({ changingPassword }: { changingPassword: boolean } = { changingPassword: false }): void {
        const profileModal = this.modalService.open(ProfileModalComponent, {
            windowClass: "modal-800"
        });

        profileModal.componentInstance.changingPassword = changingPassword;

        profileModal.result.then(() => this.onProfileUpdated.emit(), noop);
    }

    public async logout(): Promise<void> {
        this.cacheService.clear();
        await this.authService.logout();
    }
}
