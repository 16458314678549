angular.module("kno2.services").service("AdminDocumentSourcesFaxService", [
    "$http",
    "CacheService",
    function ($http, CacheService) {
        this.getDocumentSources = function (sortDir, pageNumber, pageSize, searchTerm) {
            if (!pageNumber) pageNumber = 1;
            const searchKeyValue = searchTerm == undefined || searchTerm === "" ? "" : "&search=" + searchTerm;
            const promise = $http
                .get(
                    "/api/admin/documentsources?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&type=fax" + searchKeyValue
                )
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };

        this.getDocumentSource = function (id) {
            const promise = $http.get("/api/admin/documentsources/" + id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        // Ajax request to delete
        this.deleteDocumentSource = function (id) {
            return $http.delete("/api/admin/documentsources/" + id);
        };

        this.activateDocumentSource = function (id) {
            return $http.post("/api/admin/documentsources/activate/" + id);
        };

        this.putDocumentSource = function (documentSource) {
            documentSource.directAddressType = "fax";
            return $http.put("/api/admin/documentsources/" + documentSource.id, documentSource);
        };

        this.updateFaxActiveStatus = function (documentSource) {
            documentSource.directAddressType = "fax";
            return $http.put("/api/admin/documentsources/" + documentSource.id + "/faxstatus", documentSource);
        };

        this.postDocumentSource = function (documentSource) {
            documentSource.directAddressType = "fax";
            return $http.post("/api/admin/documentsources", documentSource);
        };

        this.exists = function (id, val) {
            const url = "/api/admin/documentsources/exists?val=" + val;
            return $http.get(url);
        };

        this.getFaxProviders = function () {
            return $http.get("/api/admin/documentsources/documentsourceproviders?pageNumber=1&pageSize=20&type=Fax");
        };
    }
]);
