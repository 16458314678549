import templateUrl from "./inline-box.component.html";

InlineBoxController.$inject = ["$attrs"];

export function InlineBoxController($attrs) {
    var ctrl = this;

    ctrl.$onInit = $onInit;

    function $onInit() {
        if (!$attrs.onClick) ctrl.onClick = defaultOnClick;
    }

    function defaultOnClick() {
        ctrl.expanded = !ctrl.expanded;
    }
}

angular.module("kno2.components").component("k2InlineBox", {
    templateUrl,
    controller: InlineBoxController,
    transclude: true,
    bindings: {
        title: "@",
        tooltip: "@",
        expanded: "<",
        onClick: "&"
    }
});
