import { Routes } from "@angular/router";

export const routes: Routes = [
    {
        path: "documenttypes",
        loadComponent: () => import("../../features/settings/document-types/document-types.component").then((c) => c.DocumentTypesComponent),
        data: {
            roles: ["SysAdmin"]
        }
    },
    {
        path: "releasetypes",
        loadComponent: () => import("../../features/settings/release-types/release-types.component").then((c) => c.ReleaseTypesComponent),
        data: {
            roles: ["SysAdmin"]
        }
    }
];
