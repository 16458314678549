import templateUrl from "./admin-organizations-clear-cancel.modal.component.html";

export const adminOrganizationsClearCancelModalComponent = {
    templateUrl,
    controller: AdminOrganizationsClearCancelModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AdminOrganizationsClearCancelModalController.$inject = ["AdminOrganizationsService", "NotificationService"];

export function AdminOrganizationsClearCancelModalController(AdminOrganizationsService, NotificationService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.ok = ok;
    ctrl.cancel = cancel;

    function $onInit() {
        ctrl.organizationId = ctrl.resolve.orgId;

        getOrganizationDto(ctrl.organizationId);
    }

    function ok() {
        AdminOrganizationsService.clearOrganizationCancellation(ctrl.organization.id)
            .then(function (result) {
                NotificationService.success("The cancellation was cleared successfully.");
                ctrl.close();
            })
            .catch(function (response) {
                NotificationService.serverError(response.data.message, "There was an error clearing the cancellation for this organization.");
            });
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function getOrganizationDto(id) {
        AdminOrganizationsService.getOrganizationDto(id).then((x) => (ctrl.organization = x));
    }
}
