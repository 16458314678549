import { Pipe, PipeTransform } from "@angular/core";

type FileSizeUnit = "B" | "k" | "MB";

@Pipe({
    name: "fileSize",
    standalone: true
})
export class FileSizePipe implements PipeTransform {
    private readonly units = ["B", "k", "MB"];
    public transform(bytes: number, targetUnit: FileSizeUnit = "MB"): string {
        const index = this.units.indexOf(targetUnit);
        const convertedValue = bytes / Math.pow(1024, index);

        return `${parseFloat(convertedValue.toFixed(4))} ${targetUnit}`;
    }
}
