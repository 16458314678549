AdminOrganizationsService.$inject = ["$http", "$q", "CsvService"];

export function AdminOrganizationsService($http, $q, csvService) {
    this.getPagedItemsSelectValue = function () {
        return $("#pagedItemsSelect").val();
    };

    this.getOrgs = function (name) {
        return $http.get("/api/organizations/", { params: { search: name } }).then(function (response) {
            return response.data.items;
        });
    };

    // Ajax request to get all organizations data
    this.getOrganizationDtos = function (sortDir, pageNumber, pageSize, searchTerm, cancelling) {
        return $http
            .get(`/api/organizations?cancelling=${cancelling || false}`, {
                params: {
                    search: searchTerm || undefined,
                    sortDir: sortDir,
                    pageNumber: pageNumber,
                    pageSize: pageSize
                }
            })
            .then(function (response) {
                return response.data;
            });
    };

    this.getOrganizationTypes = function () {
        return $http.get("/api/organizations/types", { cache: true }).then((x) => x.data);
    };

    this.getOrganizationSpecialties = function () {
        return $http.get("/api/organizations/specialties", { cache: true }).then((x) => x.data);
    };

    this.createOrganizationAlternateOids = function (oid) {
        return $http.post(`/api/admin/organizations/${oid.organizationId}/oids`, oid).then((x) => x.data);
    };

    this.getOrganizationAlternateOids = function (orgId) {
        return $http.get(`/api/admin/organizations/${orgId}/oids`).then((x) => x.data);
    };

    this.updateOrganizationAlternateOids = function (oid) {
        return $http.put(`/api/admin/organizations/${oid.organizationId}/oids`, oid).then((x) => x.data);
    };

    this.deleteOrganizationAlternateOid = function (oid) {
        return $http.delete(`/api/admin/organizations/${oid.organizationId}/oids/${oid.id}`).then((x) => x.data);
    };

    this.upsertOrganizationDirectoryEntry = (orgId, entry) => {
        return $http.put(`/api/admin/organizations/${orgId}/directoryentries`, entry).then((x) => x.data);
    };

    this.getOrganizationDirectoryEntries = (orgId) => {
        return $http.get(`/api/admin/organizations/${orgId}/directoryentries`).then((x) => x.data);
    };

    this.syncOrganizationDirectoryEntries = (orgId, resource) => {
        return $http.post(`/api/admin/organizations/${orgId}/directoryentries/sync`, resource).then((x) => x.data);
    };

    this.getOrganizationAdministrators = function (organizationId) {
        return $http.get(`/api/admin/organizations/${organizationId}/admins`).then((x) => x.data);
    };

    this.getOrganizationIntegrations = function (id) {
        return $http.get(`/api/organizations/${id}/integrations`).then((x) => x.data);
    };

    this.getOrganizationDevices = function (id) {
        return $http.get(`/api/admin/organizations/${id}/devices`).then((x) => x.data);
    };

    this.getOrganizationLicenses = function (id) {
        return $http.get(`/api/admin/organizations/${id}/licenses`).then((x) => x.data);
    };

    this.getOrganizationAdmins = function (params) {
        return $http
            .get(`/api/admin/networks/${params.networkId}/admins`, {
                params: {
                    search: params.search
                }
            })
            .then((x) => x.data);
    };

    // Ajax request to get one organizations data
    this.getOrganizationDto = function (organizationId) {
        return $http.get(`/api/organizations/${organizationId}`).then((x) => x.data);
    };

    this.getServiceAccounts = function (organizationId) {
        return $http.get(`/api/organizations/${organizationId}/serviceaccount`).then((x) => x.data);
    };

    this.updateServiceAccountAddressCount = function (organizationId, accountId, count) {
        return $http
            .put(`/api/organizations/${organizationId}/serviceaccounts/${accountId}/ipaddresses/limit`, {
                count: count
            })
            .then((x) => x.data);
    };

    // Check if Subdomain already exists.
    this.isSubDomainExists = function (id, subdomain) {
        return $http.get("/api/organizations/subdomain/exists", {
            params: {
                id: id,
                subdomain: subdomain
            }
        });
    };

    this.isSubdomainValid = function (id, subdomain) {
        if (!id && !subdomain) {
            var deferred = $q.defer();
            deferred.resolve({ data: "false" });
            return deferred.promise;
        }

        return $http.get("/api/organizations/subdomain/isvalid", {
            params: {
                organizationId: id || undefined,
                subdomain: subdomain || undefined
            }
        });
    };

    // Ajax request to save this organization
    this.putOrganization = function (organization) {
        return $http.put("/api/organizations/" + organization.id, organization);
    };

    // Ajax request to save this organization
    this.postOrganization = function (organization) {
        return $http.post("/api/organizations/", organization);
    };

    // Ajax request to delete this organization
    this.deleteOrganization = function (organizationId) {
        return $http.delete("/api/organizations/" + organizationId);
    };

    this.clearOrganizationCancellation = function (organizationId) {
        return $http.post(`/api/organizations/${organizationId}/clearcancel`);
    };

    this.activateOrg = function (activationRequest) {
        return $http.put("/api/organizations/{id}/activate".replace("{id}", activationRequest.id), activationRequest);
    };

    this.enableDisableOrg = function (organizationId) {
        return $http.put("/api/organizations/{id}/enable/".replace("{id}", organizationId));
    };

    this.exportDirectAddresses = function () {
        csvService.getCsvFile("/api/admin/reports/directaddresses", "DirectAddresses");
    };

    this.exportOrganizations = function () {
        csvService.getCsvFile("/api/admin/reports/organizations", "Organizations");
    };

    this.exportContactsStatistics = function () {
        csvService.getCsvFile("/api/admin/reports/contacts-usage-statistics", "ContactsStatistics");
    };

    this.syncOrganization = function (organizationId) {
        return $http.post("/api/organizations/{id}/sync".replace("{id}", organizationId));
    };

    this.toggleApiCapture = function (organizationId) {
        return $http.put("/api/organizations/{id}/apicapture/".replace("{id}", organizationId));
    };

    this.getDirectProviders = function () {
        return $http.get("/api/admin/documentsources/documentsourceproviders?pageNumber=1&pageSize=20&type=DirectMessaging");
    };

    this.searchNpiByNumber = function (npiNumber) {
        return $http.get(`/api/admin/organizations/npi?npiNumber=${npiNumber}`);
    };

    this.updateServiceAccountLimit = function (organizationId, count) {
        return $http.put(`/api/organizations/${organizationId}/serviceaccount/limit`, { count: count });
    };

    this.getServiceAccountLimit = function (organizationId) {
        return $http.get(`/api/organizations/${organizationId}/serviceaccount/limit`);
    };
}

