import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";

export interface ClassificationCodeResource {
    code: string;
    name: string;
    scheme: string;
}

@Injectable({
    providedIn: "root"
})
export class MessagesService {
    public deliverySummarySendToIntake = (id: string): Promise<void> => firstValueFrom(this.deliverySummarySendToIntake$(id));
    public getClassifications = (): Promise<ClassificationCodeResource[]> => firstValueFrom(this.getClassifications$());

    constructor(private httpClient: HttpClient) {}

    private deliverySummarySendToIntake$ = (id: string): Observable<void> =>
        this.httpClient.post<void>(`/api/messages/${id}/deliverysummary/sendtointake`, null);

    private getClassifications$ = (): Observable<ClassificationCodeResource[]> => this.httpClient.get<any>(`/api/messages/classifications`);
}
