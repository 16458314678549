import { IHttpService } from "angular";

export interface NetworkOrganizationListItemResponse {
    id: number;
    objectId: string;
    name: string;
}

export class NetworksService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http: IHttpService) {}

    public getNetwork(networkId) {
        return this.$http.get("/api/networks/" + networkId).then(function (response) {
            return response.data;
        });
    }

    public getNetworkFeatureStatuses(networkId) {
        return this.$http.get(`/api/networks/${networkId}/features`, { cache: true }).then(function (response) {
            return response.data;
        });
    }

    public getNetworkOrganizations(networkId) {
        return this.$http.get<NetworkOrganizationListItemResponse[]>(`/api/admin/networks/${networkId}/organizations`).then((response) => response.data);
    }
}
