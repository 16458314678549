import templateUrl from "./integration-whitelist-edit.modal.component.html";

export class IntegrationWhitelistEditModalController {
    constructor(AdminIntegrationsService, NotificationModalService) {
        this.AdminIntegrationsService = AdminIntegrationsService;
        this.NotificationModalService = NotificationModalService;
    }

    $onInit() {
        this.whitelist = this.resolve.whitelist;
    }

    save() {
        const promise = this.whitelist.id
            ? this.AdminIntegrationsService.updateIntegrationWhitelistEntry(this.whitelist.id, this.whitelist)
            : this.AdminIntegrationsService.addIntegrationWhitelistEntry(this.whitelist);

        return promise
            .then(() => {
                this.NotificationModalService.success("Whitelist entry saved");
                this.close();
            })
            .catch((response) => {
                if (response.data && response.data.modelState)
                    this.NotificationModalService.serverError(response.data.modelState, "An error occurred while saving this whitelist entry.");
                else if (response.status === 409) this.NotificationModalService.error("An entry with this value already exists.");
                else this.NotificationModalService.error("An unknown error occurred while saving this whitelist entry.");
            });
    }
}

IntegrationWhitelistEditModalController.$inject = ["AdminIntegrationsService", "NotificationModalService"];

export const integrationWhitelistEditModalComponent = {
    templateUrl,
    controller: IntegrationWhitelistEditModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
