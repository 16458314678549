routeAuthConfig.$inject = ["$transitions", "$location", "SessionService", "StateRoutingService", "appConfig"];

export function routeAuthConfig($transitions, $location, SessionService, StateRoutingService, appConfig) {
    $transitions.onBefore({}, function (transition) {
        const hasBrandGuard = transition.to().data && transition.to().data.brands;
        if (hasBrandGuard) {
            const targetState = StateRoutingService.getState();
            const isAccessible = transition.to().data.brands.indexOf(appConfig.brand) !== -1;
            const isSysAdmin = SessionService.userInRole("SysAdmin");

            if (!isAccessible && isSysAdmin) return StateRoutingService.goTo(targetState);
            if (!isAccessible && !isSysAdmin) return transition.router.stateService.target(targetState);
        }
    });
}
