import { AfterViewInit, Component, computed, effect, HostListener, inject, OnInit, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { NoOpLink, ShowForRoleInDirective } from "@kno2/common/directives";
import { SessionService } from "@kno2/core/session";
import { MenuItem } from "./menu-item";
import { SYSADMIN_MENU_ITEMS, SYSADMIN_REPORTING_MENU_ITEMS, USER_MENU_ITEMS } from "./sidebar-items";
import { SidebarService } from "./sidebar.service";

@Component({
    selector: "kno2-sidebar",
    standalone: true,
    imports: [RouterLink, ShowForRoleInDirective, NoOpLink],
    templateUrl: "./sidebar.component.html",
    styles: `#sidebar-left {
        max-width: 14%;
    }

    #sidebar-left.minified {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 60px;
        max-width: 60px;
    }

    a.main-menu-min i {
        margin-left: 0;
    }

    .sidebar-nav > ul {
        margin: -9px -25px;
        border: none;
        padding-bottom: 1px;
        font-size: 18px;
    }

    @media (max-width: 991px) {
        .main-menu-min,
        .debug-footer {
            visibility: hidden !important;
        }
    }`
})
export class SidebarComponent implements OnInit, AfterViewInit {
    private readonly router = inject(Router);
    private readonly sidebarService = inject(SidebarService);
    private readonly sessionService = inject(SessionService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly directoryService = this.upgrade.$injector.get("DirectoryService");
    private readonly routerEvent = toSignal(this.router.events);

    protected readonly activeRoute: Signal<string> = computed(() => (this.routerEvent() as NavigationEnd)?.url || this.router.url);
    protected readonly sidebarCollapsed: Signal<boolean> = this.sidebarService.collapsed;
    protected menuItems: MenuItem[] = [];

    public constructor() {
        effect(() => this.expandActiveMenuGroup());
    }

    public ngOnInit(): void {
        this.setMenuItems();
    }

    public ngAfterViewInit(): void {
        this.onResize();
    }

    private setMenuItems(): void {
        let role: "User" | "Administrator" | "SysAdminReporting" | "SysAdmin";
        if (this.sessionService.userInRole("User")) role = "User";
        if (this.sessionService.userInRole("Administrator")) role = "Administrator";
        if (this.sessionService.userInRole("SysAdminReporting")) role = "SysAdminReporting";
        if (this.sessionService.userInRole("SysAdmin")) role = "SysAdmin";

        switch (role) {
            case "User":
            case "Administrator":
                const userProfile = this.sessionService.getProfile();
                this.menuItems = USER_MENU_ITEMS.map((i) => ({
                    ...i,
                    disabled: !userProfile.orgIsActivated,
                    visible: this.sessionService.userInRole(i.data?.roles)
                }));
                this.menuItems.find((i) => i.id === "find-provider").command = this.directoryService.searchDirectory;
                this.menuItems.find((i) => i.id === "get-signatures").visible = userProfile.signaturesProfileSettings?.isGetSignaturesEnabled;
                break;
            case "SysAdminReporting":
                this.menuItems = SYSADMIN_REPORTING_MENU_ITEMS.map((i) => ({ ...i, visible: true }));
                break;
            case "SysAdmin":
                this.menuItems = SYSADMIN_MENU_ITEMS.map((i) => ({ ...i, visible: true }));

                break;
            default:
                console.error("Error setting up the side bar. Unknown role: ", this.sessionService.getProfile().roles);
        }
    }

    public expandActiveMenuGroup(): void {
        const menuItem = this.menuItems.find(({ items }) => items?.some(({ route }) => route === this.activeRoute())) || {};
        menuItem.expanded = true;
    }

    public toggleCollapse(): void {
        this.sidebarService.toggleSidebar();
    }

    @HostListener("window:resize")
    public onResize(): void {
        if (window.innerWidth < 1200) {
            this.sidebarService.collapseSidebar();
        } else if (window.innerWidth >= 1200) {
            this.sidebarService.restoreState();
        }
    }
}
