export class ConfigurationService {
    constructor($http) {
        this.$http = $http;
    }

    getConfiguration() {
        return this.$http.get("/api/apicaptureconfig", { cache: true }).then((x) => x.data);
    }
}

ConfigurationService.$inject = ["$http"];
