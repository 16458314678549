export class CodeGrantsService {
    constructor($http) {
        this.$http = $http;
    }

    getCodeGrants(integrationType) {
        return this.$http.get(`/api/codegrants`, { params: { integrationType: integrationType } }).then((x) => x.data);
    }
}

CodeGrantsService.$inject = ["$http"];

angular.module("kno2.services").service("CodeGrantsService", CodeGrantsService);
