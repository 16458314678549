angular
    .module("kno2.services")
    .service("layoutService", function () {
        var service = {};

        return service;
    })

    .service("notify", [
        function () {
            (this.notify = function (type, message) {
                var opts = {
                    text: message,
                    layout: "topCenter",
                    type: type,
                    timeout: 5000,
                    //timeout: false,
                    closeWith: ["click"]
                };
                noty(opts);
            }),
                (this.success = function (message) {
                    this.notify("success", message);
                });

            this.error = function (message) {
                this.notify("error", message);
            };

            this.warning = function (message) {
                this.notify("warning", message);
            };

            this.info = function (message) {
                this.notify("information", message);
            };
        }
    ])

    .service("SpinnerService", [
        "$timeout",
        "$rootScope",
        "usSpinnerService",
        function ($timeout, $rootScope, usSpinnerService) {
            var checkForDocLoaded = function () {
                $timeout(function () {
                    var pageCount = $("#viewer").contents().find(".page").length || 0;

                    // The "page" class will load for each page. If page is greater than 0, then see what
                    // the count is, then check the count in 500 ms to see if that count is the same. If it
                    // is, assume the document is fully loaded, otherwise, we are still loading.

                    if (pageCount && pageCount == prevPageCount) {
                        stopSpin();
                        $rootScope.$emit("stopSpinSuccess");
                    } else {
                        prevPageCount = pageCount;
                        checkForDocLoaded();
                    }
                }, 1000);
            };

            var startSpin = function () {
                prevPageCount = 0;
                $("div.viewer-container div.spinner-container").show();
                usSpinnerService.spin("spinner-1");
            };

            var stopSpin = function () {
                usSpinnerService.stop("spinner-1");
                $("div.viewer-container div.spinner-container").hide();
            };

            var prevPageCount = 0;

            this.start = function () {
                startSpin();
                checkForDocLoaded();
            };

            this.stop = function () {
                stopSpin();
            };
        }
    ]);
