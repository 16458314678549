// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/content/images/quick-tasks/horn.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"modal-header\"> <button type=\"button\" class=\"close\" aria-hidden=\"true\" ng-click=\"dismissInviteModal()\"> &times; </button> <h2 class=\"modal-title\">New features and updates</h2> </div> <div class=\"qt-section\"> <notification-modal-alert></notification-modal-alert> <div class=\"modal-area new-features\"> <div class=\"qt-icon-container\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"/> </div> </div> <div class=\"qt-body\"> <div ng-repeat=\"feature in features\"> <h2 ng-show=\"feature.link\"> <a class=\"qt-link\" target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{feature.link}}\"> {{feature.title}} </a> - {{feature.modifiedDate | formatAlertDate}} </h2> <h2 ng-hide=\"feature.link\">{{feature.title}} - {{feature.modifiedDate | formatAlertDate}}</h2> <p>{{feature.description}}</p> </div> </div> <div class=\"modal-footer\"> <button type=\"submit\" class=\"btn btn-primary ladda-button\" ng-click=\"dismissInviteModal()\"> <span class=\"ladda-label\">Ok</span> </button> </div> </div> ";
// Exports
var _module_exports = code;;
var path = 'App/src/account/dashboard/new-features/account.dashboard.new-features-modal.html';
window.angular.module('kno2.templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;