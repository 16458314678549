(function () {
    "use strict";

    angular.module("kno2.directives").directive("form", form);

    form.$inject = [];

    function form() {
        var directive = {
            restrict: "E",
            require: "^form",
            link: link
        };
        return directive;

        function link(scope, element, attrs, FormCtrl) {
            var name = FormCtrl.$name;
            scope.$parent[name] = scope[name] = FormCtrl;
            FormCtrl.$submitted = false;

            element.bind("submit", function (e) {
                scope.$apply(function () {
                    FormCtrl.$submitted = true;
                });
            });
        }
    }
})();
