import { Component, input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PopoverDirective } from "@kno2/common/directives";

@Component({
    selector: "kno2-profile-email-notifications",
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, PopoverDirective],
    templateUrl: "./profile-email-notifications.component.html",
    styles: ``
})
export class ProfileEmailNotificationsComponent {
    public preferencesForm = input.required<FormGroup>();
    public isAdministrator = input.required<boolean>();

    protected emailNotificationsTooltip = `Select this option to be notified via email when there are important notifications for your review.`;
    protected emailNewMessagesTooltip = `Select this option to be notified via email any time a new message is received that you are designated to view/process.`;
    protected emailUnassignedMessagesTooltip = `Select this option to be notified via email any time a new message is received and there are no rules designated for the message.`;
    protected emailUnprocessedMessagesTooltip = `Select this option to be notified via email when there are unprocessed messages for longer than 24 hours in your organization.`;
    protected emailFailedMessagesTooltip = `Select this option to be notified via email when there are failed messages to be acknowledged.`;
}
