export function compareObjects(a, b) {
    let result = [];
    let keys = Object.keys(a);

    _.forEach(keys, function (key) {
        if (lower(a[key]) !== lower(b[key])) result.push({ property: key, original: a[key], new: b[key] });
    });

    return result;
    function lower(str) {
        return str ? String.prototype.toLowerCase.apply(str) : str;
    }
}
