import templateUrl from "./message-utils.component.html";

export const messageUtilsComponent = {
    templateUrl: templateUrl,
    controller: messageUtilitiesController
};

messageUtilitiesController.$inject = ["UtilsService", "NotificationService"];

function messageUtilitiesController(UtilsService, NotificationService) {
    const ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.search = search;
    ctrl.showClipboardCapture = showClipboardCapture;
    ctrl.searchObjectIds = searchObjectIds;
    ctrl.decodeObjectIds = decodeObjectIds;

    function onInit() {
        //nothing to do
    }

    function search() {
        if (!ctrl.messageId && !ctrl.workflowId) return;
        if (ctrl.messageId) {
            UtilsService.get(ctrl.messageId).then(
                function (response) {
                    ctrl.workflowId = response.workflowId;
                },
                function (error) {
                    if (error.status === 404) NotificationService.errorToaster("Not Found");
                    else NotificationService.errorToaster(error.data.message);
                }
            );
        } else {
            UtilsService.getFromWorkflow(ctrl.workflowId).then(
                function (response) {
                    ctrl.messageId = response.messageId;
                },
                function (error) {
                    if (error.status === 404) NotificationService.errorToaster("Not Found");
                    else NotificationService.errorToaster(error.data.message);
                }
            );
        }
    }

    function searchObjectIds() {
        if (!ctrl.objectIds) return;
        ctrl.objectIds = ctrl.objectIds.replace(/(\r\n|\n|\r)/gm, "");
        ctrl.objectIds = ctrl.objectIds.split(",");
        const objectIds = ctrl.objectIds.map((oid) => {
            return oid.trim();
        });
        const request = {
            objectIds: objectIds
        };
        UtilsService.mailMessageObjectIdsSearch(request).then(
            function (response) {
                const stringArray = response.map((r) => {
                    return `${r.objectId}, ${r.mailMessageId}, ${r.workflowId}`;
                });
                ctrl.objectIds = stringArray.join("\r\n");
            },
            function (error) {
                if (error.status === 404) NotificationService.errorToaster("Not Found");
                else NotificationService.errorToaster(error.data.message);
            }
        );
    }

    function decodeObjectIds() {
        if (!ctrl.decodedObjectIds) return;
        ctrl.decodedObjectIds = ctrl.decodedObjectIds.replace(/(\r\n|\n|\r)/gm, "");
        ctrl.decodedObjectIds = ctrl.decodedObjectIds.split(",");
        const objectIds = ctrl.decodedObjectIds.map((objectId) => objectId.trim());
        UtilsService.decodeObjectIds(objectIds).then(
            function (response) {
                const stringArray = response.map((r) => (r && `${r.objectId}, ${r.dbEntityId}, ${r.uuid}`) || "Invalid");
                ctrl.decodedObjectIds = stringArray.join("\r\n");
            },
            function (error) {
                NotificationService.errorToaster(error.data.message);
            }
        );
    }

    function showClipboardCapture(copyValue) {
        NotificationService.success(`${copyValue} copied to your clipboard`);
    }
}

