import messageBodySummaryTemplate from "./message/release.message-body-summary.html";
import newMessageBodySummaryTemplate from "./message/release.new-message-body-summary.html";

angular
    .module("kno2.release")
    .factory("MessageStateFactory", [
        "$injector",
        "$timeout",
        function ($injector, $timeout) {
            var createStrategy = function (name) {
                if (name == "new") return $injector.get("NewMessageStrategy");
                else if (name == "draft") return $injector.get("DraftMessageStrategy");
                else if (name == "forward") return $injector.get("ForwardMessageStrategy");
                else if (name == "reply") return $injector.get("ReplyMessageStrategy");
            };

            var initForwardReplyCommon = function ($scope) {
                // Header title controls
                //make sure the parent Id gets passed
                var parent = $scope.message.originalObjectId;
                $scope.message = {
                    id: $scope.id,
                    releaseType: $scope.releaseType ? $scope.releaseType.name : "",
                    sourceType: $scope.sourceType ? $scope.sourceType.name : "",
                    fromAddress: $scope.doc.fromAddress || "",
                    toAddresses: [],
                    subject: $scope.doc.subject || "New Message Subject",
                    organizationName: $scope.profile.organizationName,
                    originalObjectId: parent,
                    isDraft: $scope.doc.isDraft
                };

                $scope.message.id = $scope.message.id || "";
                $scope.message.systemReceivedDate = $scope.doc.messageDate || "";
                $scope.message.body = $scope.doc.body || "";
                $scope.message.comments = $scope.doc.comments || "";
                var titlePrefix = $scope.messageStrategy.getName() == "reply" ? "RE: " : "FW: ";

                if (/[Ff][Ww]\:?/.test($scope.doc.subject)) {
                    //prevents double FW:
                    $scope.doc.subject = $scope.doc.subject.substring(4);
                }
                $scope.message.subject = titlePrefix + ($scope.doc.subject || $scope.message.releaseType);

                // Patient Details data
                $scope.patient = $scope.doc.patient || {};

                // set default from address\
                var addresses;
                if ($scope.doc.toAddress.indexOf(";") > 0) {
                    addresses = ($scope.doc.toAddress || []).split(";");
                } else {
                    addresses = [$scope.doc.toAddress];
                }

                var joined = _.filter($scope.availableFromAddresses, function (addr) {
                    return _.includes(addresses, addr);
                });
                $scope.message.originalFromAddress = $scope.message.fromAddress;
                $scope.message.fromAddress = _.first(joined);

                var replyDateHeader =
                    moment.utc($scope.doc.messageDate).local().format("On ddd, MMM D, YYYY [at] h:mm A zz, ") + $scope.message.originalFromAddress + " wrote:";

                $scope.message.body = "\r\n\r\n----------\r\n" + replyDateHeader + "\r\n" + $scope.message.body;
            };

            return {
                createStrategy: createStrategy,
                initForwardReplyCommon: initForwardReplyCommon
            };
        }
    ])

    .factory("NewMessageStrategy", [
        "DocumentTypesService",
        "MessageFactory",
        function (DocumentTypesService, MessageFactory) {
            return {
                init: function ($scope) {
                    MessageFactory.init($scope, "new");
                    MessageFactory.initializeDateTypeSelections($scope.attachments);
                    $scope.populateFromAddress();
                },
                initForModal: function ($scope) {
                    MessageFactory.populateMessageBodyFromScope($scope);
                },
                validateReleaseModel: function ($scope, errors) {
                    if (_.isEmpty($scope.message.releaseType)) {
                        errors.push('"Release type is required');
                    }
                },
                validateMessageModel: function ($scope, errors) {
                    MessageFactory.validate($scope, errors, "new");
                },
                getName: function () {
                    return "new";
                },
                getModalTemplate: function () {
                    return newMessageBodySummaryTemplate;
                }
            };
        }
    ])
    .factory("ReplyMessageStrategy", [
        "DocumentTypesService",
        "MessageService",
        "$sce",
        "MessageStateFactory",
        "MessageFactory",
        function (DocumentTypesService, MessageService, $sce, MessageStateFactory, MessageFactory) {
            function cleanPotentialFaxNumber(address) {
                if (address.indexOf("@") === -1) {
                    address = address.replace(/-/g, "");
                    if (address.startsWith("1")) {
                        address = address.substr(1);
                    }
                }
                return address;
            }

            var initPageControls = function ($scope) {
                MessageStateFactory.initForwardReplyCommon($scope);

                // Attachments data
                $scope.attachments = $scope.doc.attachments || [];

                if ($scope.attachments && $scope.attachments.length) {
                    // Load up the first document to view, as this is the first time the page is loading
                    $scope.selectedAttachment = $scope.attachments[0];
                }

                // In reply, we are replying to the original from address. There can
                // only be one from address, but the toAddresses field can support multiple.
                // Set the toAddresses to be the fromAddress.
                var toAddresses = ($scope.doc.fromAddress || []).split(";");
                $scope.message.toAddresses = _.map(toAddresses, function (item) {
                    return { text: item };
                });
                $scope.message.fromAddress = cleanPotentialFaxNumber($scope.doc.toAddress);
                MessageFactory.init($scope, "reply");
                $scope.includeAttachmentsConfirmation("reply");
            };

            return {
                init: function ($scope) {
                    MessageService.getMessage($scope.id).then(function (data) {
                        $scope.doc = data;
                        $scope.id = data.id;
                        initPageControls($scope);
                    });
                },
                initForModal: function ($scope) {},
                validateReleaseModel: function ($scope, errors) {
                    // Purposely left empty, as it is part of the strategy interface.
                },
                validateMessageModel: function ($scope, errors) {
                    MessageFactory.validate($scope, errors, "reply");
                },
                getName: function () {
                    return "reply";
                },
                getModalTemplate: function () {
                    return messageBodySummaryTemplate;
                }
            };
        }
    ])
    .factory("ForwardMessageStrategy", [
        "DocumentTypesService",
        "MessageService",
        "ReleaseService",
        "$sce",
        "MessageStateFactory",
        "MessageFactory",
        function (DocumentTypesService, MessageService, ReleaseService, $sce, MessageStateFactory, MessageFactory) {
            var initPageControls = function ($scope) {
                MessageStateFactory.initForwardReplyCommon($scope);

                // Attachments data
                $scope.attachments = $scope.doc.attachments || [];

                if ($scope.attachments && $scope.attachments.length) {
                    // Load up the first document to view, as this is the first time the page is loading
                    $scope.selectedAttachment = $scope.attachments[0];
                }

                MessageFactory.init($scope, "forward");
                MessageFactory.initializeDateTypeSelections($scope.attachments);
                // $scope.includeAttachmentsConfirmation('forward');
            };

            return {
                init: function ($scope) {
                    $scope.message.id = $scope.id;
                    $scope.originalObjectId = $scope.id;
                    ReleaseService.saveDraftWithAttachments($scope.id, $scope.message, true)
                        .then(function (res) {
                            $scope.onRouteChangeEventDisabled = true;
                            $scope.doc = res;
                            $scope.doc.originalObjectId = $scope.originalObjectId;
                            $scope.id = res.id;
                            $scope.draftId = res.id;
                            initPageControls($scope);
                            ReleaseService.setCurrentDraft(res);
                            var draft = ReleaseService.getCurrentDraft();
                            if (draft.subject) {
                                $scope.doc.subject = draft.subject;
                            }
                        })
                        .catch(function (res) {});
                },
                initForModal: function ($scope) {},
                validateReleaseModel: function ($scope, errors) {
                    if (_.isEmpty($scope.message.releaseType)) {
                        errors.push('"Release type is required');
                    }
                },
                validateMessageModel: function ($scope, errors) {
                    MessageFactory.validate($scope, errors, "forward");
                },
                getName: function () {
                    return "forward";
                },
                getModalTemplate: function () {
                    return messageBodySummaryTemplate;
                }
            };
        }
    ])
    .factory("DraftMessageStrategy", [
        "DocumentTypesService",
        "MessageFactory",
        "MessageService",
        "ReleaseService",
        function (DocumentTypesService, MessageFactory, MessageService, ReleaseService) {
            const initPageControls = function ($scope) {
                // Attachments data
                $scope.attachments = $scope.doc.attachments || [];
            };

            const getMessageSubject = function ($scope) {
                const draft = ReleaseService.getCurrentDraft();
                let subject = $scope.doc.subject || $scope.message.releaseType;
                if (draft && draft.subject) {
                    subject = draft.subject;
                }
                return subject;
            };

            const cleanAddress = function (address) {
                if (address && address.indexOf("@") === -1) return address.split("-").join("");
                return address;
            };

            const initDocumentData = function ($scope) {
                $scope.message = {
                    id: $scope.id,
                    releaseType: $scope.doc.subject || "",
                    sourceType: $scope.sourceType.name || "",
                    fromAddress: cleanAddress($scope.doc.fromAddress) || "",
                    toAddresses: [],
                    subject: $scope.doc.subject || "",
                    organizationName: $scope.profile.organizationName,
                    origin: $scope.doc.origin,
                    isDraft: $scope.doc.isDraft
                };

                const trimmedToAddress = $.trim($scope.doc.toAddress);
                if (trimmedToAddress !== "") {
                    const addresses = $.trim(trimmedToAddress).split(";");
                    $scope.message.toAddresses = addresses;
                }

                $scope.message.id = $scope.message.id || "";
                $scope.message.systemReceivedDate = $scope.doc.messageDate || "";
                $scope.message.body = $scope.doc.body || "";
                $scope.message.comments = $scope.doc.comments || "";
                $scope.message.subject = getMessageSubject($scope);

                const replyDateHeader =
                    moment.utc($scope.doc.messageDate).local().format("On ddd, MMM D, YYYY [at] h:mm A zz, ") + $scope.message.fromAddress + " wrote:";

                $scope.message.body = "\r\n\r\n----------\r\n" + replyDateHeader + "\r\n" + $scope.message.body;

                // Patient Details data
                $scope.patient = $scope.doc.patient || {};
            };

            return {
                init: function ($scope) {
                    MessageService.getDraft($scope.id).then(function (data) {
                        $scope.doc = data;
                        $scope.draftId = data.id;
                        initPageControls($scope);
                        initDocumentData($scope);
                        $scope.populateFromAddress();
                    });

                    MessageFactory.init($scope, "new");
                },
                initForModal: function ($scope) {
                    MessageFactory.populateMessageBodyFromScope($scope);
                },
                validateReleaseModel: function ($scope, errors) {
                    if (_.isEmpty($scope.message.releaseType)) {
                        errors.push('"Release type is required');
                    }
                },
                validateMessageModel: function ($scope, errors) {
                    MessageFactory.validate($scope, errors, "new");
                },
                getName: function () {
                    return "new";
                },
                getModalTemplate: function () {
                    return newMessageBodySummaryTemplate;
                }
            };
        }
    ]);
