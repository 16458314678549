let ctrl;
class PatientTemplatesAddController {
    public static $inject: Array<string> = ["IntegrationsService", "NotificationModalService"];

    public apiTemplates: Array<any>;
    public templateIds: Array<any>;
    public selectedTemplate: any;
    public template: any;

    public resolve: any;
    public close: any;
    public dismiss: any;

    public isSubmitting: boolean;

    constructor(
        private IntegrationsService,
        private NotificationModalService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.templateIds = this.resolve.templateIds;
        ctrl.getTemplates();
    }

    public submitForm(): any {
        this.isSubmitting = true;
        const isValid = this.validateForm();
        if (isValid) {
            ctrl.close({ $value: ctrl.selectedTemplate });
        } else {
            this.NotificationModalService.error("Please select a template");
        }
        this.isSubmitting = false;
    }

    public getRoles(template: any): string {
        const roles = template.signers.map((x) => x.roleName);
        return roles.join(", ");
    }

    private getTemplates(): void {
        return ctrl.IntegrationsService.getTemplates().then((templates) => {
            const trimEmptyNames = templates.filter((x) => !!x.name);
            const trimDuplicateTemplates = trimEmptyNames.filter((x) => !this.templateIds.includes(x.templateId));
            ctrl.apiTemplates = trimDuplicateTemplates;
        });
    }

    private validateForm(): boolean {
        return ctrl.selectedTemplate != null;
    }

    private isValidDate(date: any): boolean {
        return Date.parse(date) >= 0;
    }
}

class PatientTemplatesAddComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./patient-templates.add.component.html");
        this.controller = PatientTemplatesAddController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const patientTemplatesAddComponent = new PatientTemplatesAddComponent();
