export class AdminEmergenciesService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    set(isEnabled: boolean): Promise<any> {
        return this.$http.post("/api/admin/emergencies", isEnabled);
    }

    upsert(emergency: object): Promise<any> {
        return this.$http.put("/api/admin/emergencies", emergency);
    }

    get(): Promise<any> {
        return this.$http.get("/api/admin/emergencies");
    }

    getById(emergencyId: string): Promise<any> {
        return this.$http.get(`/api/admin/emergencies/${emergencyId}`);
    }

    enable(emergencyId: string): Promise<any> {
        return this.$http.put(`/api/admin/emergencies/${emergencyId}/enable`);
    }

    disable(emergencyId: string): Promise<any> {
        return this.$http.put(`/api/admin/emergencies/${emergencyId}/disable`);
    }
}
