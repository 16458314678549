export class IdentityProvidersService {
    constructor($http) {
        this.$http = $http;
    }

    get() {
        return this.$http.get("/api/identityproviders");
    }

    getTypes() {
        return this.$http.get("/api/identityproviders/types");
    }

    upsert(identityProvider) {
        if (identityProvider.id) return this.update(identityProvider.id, identityProvider);
        else return this.add(identityProvider);
    }

    add(identityProvider) {
        return this.$http.post(`/api/identityproviders`, identityProvider);
    }

    update(id, identityProvider) {
        return this.$http.put(`/api/identityproviders/${id}`, identityProvider);
    }

    delete(id) {
        return this.$http.delete(`/api/identityproviders/${id}`);
    }

    setCache(organizationId, username) {
        return this.$http.post(`/api/identityproviders/setupcache/${organizationId}`, { username });
    }
}

IdentityProvidersService.$inject = ["$http"];
