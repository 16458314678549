import templateUrl from "./fax-accounts.component.html";

export const settingsFaxAccountComponent = {
    templateUrl: templateUrl,
    controller: FaxAccountController
};

FaxAccountController.$inject = ["$uibModal", "NotificationService", "FaxAccountService"];

export function FaxAccountController($uibModal, NotificationService, FaxAccountService) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.account = {
        canType: "OpenText"
    };
    ctrl.setForm = function (form) {
        ctrl.form = form;
    };

    function onInit() {
        FaxAccountService.get().then(function (data) {
            ctrl.account = data;
        });
    }

    ctrl.save = function () {
        if (!ctrl.form.$valid) return;
        FaxAccountService.save(ctrl.account).then(
            function (data) {
                ctrl.account = data;
                NotificationService.success("Account saved");
            },
            function (error) {
                NotificationService.errorToaster(error.data.message);
            }
        );
    };

    ctrl.delete = function () {
        if (!ctrl.account) return;
        FaxAccountService.delete(ctrl.account).then(
            function (data) {
                ctrl.account = null;
                NotificationService.success("Account deleted");
            },
            function (error) {
                NotificationService.errorToaster(error.data.message);
            }
        );
    };
}
