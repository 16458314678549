angular
    .module("kno2.services")
    .service("OrganizationService", [
        "$http",
        function ($http) {
            // Ajax request to get one organizations data
            this.getOrganizationDto = function (organizationId) {
                var promise = $http.get("/api/organizations/" + organizationId).then(function (response) {
                    return response.data;
                });
                return promise;
            };
            this.getOrganizations = function (val) {
                return $http.get("/api/organizations/", {
                    params: {
                        val: val
                    }
                });
            };
            this.getOrganizationType = function (id) {
                return $http.get("/api/organizations/types/" + id, { cache: true });
            };
            this.getOrganizationSpecialty = function (id) {
                return $http.get("/api/organizations/specialties/" + id, { cache: true });
            };
            this.getSubscription = function () {
                var promise = $http.get("/api/subscriptionplans/subscriptions").then(function (response) {
                    return response.data;
                });
                return promise;
            };
            this.getOrganizationSettings = function () {
                return $http.get(`/api/settings`).then((x) => x.data);
            };
            this.updateRequireWhitelistedIp = function (isRequired) {
                return $http.put(`/api/settings/requirewhitelistedip`, {
                    requireWhitelistedIp: isRequired
                });
            };
            this.updateIgnoredAttachmentExtensions = (ignoredExtensions) => {
                return $http.put(`/api/settings/ignoredattachmentextensions`, {
                    ignoredExtensions
                });
            };
            this.allowLegacyCompatibilityMode = (isEnabled) => {
                return $http.put(`/api/settings/allowcompatibility`, {
                    allowOrganizationLegacyLoginCompatibility: isEnabled
                });
            };
            this.updateCarePlanActions = (actions) => {
                return $http.put(`/api/settings/careplanactions`, {
                    automatedCarePlanActions: actions
                });
            };
        }
    ])

    .service("NetworkService", [
        "$http",
        function ($http) {
            this.getOrganizations = function (networkId, orgSearch) {
                return $http.get("/api/admin/networks/{networkId}/organizations".replace("{networkId}", networkId), {
                    params: {
                        orgSearch: orgSearch
                    }
                });
            };
        }
    ])

    .service("DocumentSourceService", [
        "$http",
        "CacheService",
        "SessionService",
        function ($http, cacheService, sessionService) {
            var orgId = sessionService.getProfile().organizationId;
            this.getOrgDocumentSources = function (cache) {
                if (cache === false) cacheService.clear("/api/organizations/{id}/documentsources".replace("{id}", orgId));

                var promise = $http
                    .get("/api/organizations/{id}/documentsources".replace("{id}", orgId), {
                        cache: cacheService.cacheL2()
                    })
                    .then(function (res) {
                        return res.data;
                    });
                return promise;
            };

            this.isValidFaxAddress = function (address) {
                var promise = $http.get("/api/documentsources/" + address + "/validateFaxAddress").then(function (res) {
                    return res.data;
                });
                return promise;
            };

            this.getMyDocumentSources = function () {
                const userId = sessionService.getProfile().userId;
                return this.getDocumentSourcesByUser(userId);
            };

            this.getMyDownloadprofileDocumentSources = function () {
                const userId = sessionService.getProfile().userId;
                return $http.get(`/api/users/${userId}/downloadprofilesources`).then(function (res) {
                    return res.data;
                });
            };

            this.getDocumentSourcesByUser = function (userId) {
                return $http.get("/api/users/{id}/documentsources".replace("{id}", userId)).then(function (res) {
                    return res.data;
                });
            };

            this.getReleaseDocumentSourcesByUser = function () {
                return $http.get(`/api/documentsources?status=activated`).then(function (res) {
                    return res.data;
                });
            };

            this.getDocumentSourcesByTenantAndUserRole = function (userId) {
                return $http.get("/api/users/{id}/releasetypedocumentsources".replace("{id}", userId)).then(function (res) {
                    return res.data;
                });
            };

            this.setDocumentSourcesForUser = function (userId, documentSources) {
                return $http.put("/api/users/{id}/documentsources".replace("{id}", userId), documentSources);
            };

            this.addDocumentSourceToUser = function (userId, documentSource) {
                return $http.post("/api/users/{id}/documentsources".replace("{id}", userId), documentSource).then(function () {});
            };
        }
    ])

    .service("DocumentSourcesService", [
        "$http",
        "CacheService",
        function ($http, CacheService) {
            this.getDocumentSources = function (sortDir, pageNumber, pageSize, searchTerm) {
                CacheService.clear("/documentsources");
                var searchKeyValue = searchTerm == undefined || searchTerm == "" ? "" : "&search=" + searchTerm;
                var promise = $http
                    .get("/api/documentsources?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + searchKeyValue, {
                        cache: CacheService.cacheL2()
                    })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.getAllDocumentSources = function () {
                CacheService.clear("/documentsources");
                var promise = $http
                    .get("/api/documentsources?sortDir=asc&pageNumber=1&pageSize=100&type=All", {
                        cache: CacheService.cacheL2()
                    })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.getAddressTypes = function () {
                return $http.get("/api/documentsources/addressTypes").then(function (response) {
                    return response.data;
                });
            };

            // Check if Address already exists.
            this.isAddressExists = function (id, address) {
                return $http.get("/api/documentsources/exists", {
                    params: {
                        id: id,
                        address: address
                    }
                });
            };

            this.getDocumentSource = function (id) {
                var promise = $http.get("/api/documentsources/" + id).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            // Ajax request to delete
            this.deleteDocumentSource = function (id) {
                CacheService.clear("/documentsources");
                return $http.delete("/api/documentsources/" + id);
            };

            this.getDocumentSourcesChanges = function (sortDir, pageNumber, pageSize, searchTerm) {
                var searchKeyValue = searchTerm == undefined ? "" : "&search=" + searchTerm;
                var promise = $http
                    .get("/api/documentsources/changes?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + searchKeyValue)
                    .then(function (response) {
                        angular.forEach(response.data.documentSources, function (value, key) {
                            value.pendingDateTime = new Date(value.pendingDateTime + "Z");
                        });
                        return response.data;
                    });
                return promise;
            };

            // Ajax request to save
            this.putDocumentSource = function (documentSource) {
                CacheService.clear("/documentsources");
                return $http.put("/api/documentsources/" + documentSource.id, documentSource);
            };

            // Ajax request to save
            this.postDocumentSource = function (documentSource) {
                CacheService.clear("/documentsources");
                return $http.post("/api/documentsources/", documentSource);
            };
        }
    ])

    .service("DocumentSourcesFaxService", [
        "$http",
        "CacheService",
        function ($http, CacheService) {
            this.getDocumentSources = function (sortDir, pageNumber, pageSize, searchTerm) {
                CacheService.clear("/documentsources");
                var searchKeyValue = searchTerm == undefined || searchTerm == "" ? "" : "&search=" + searchTerm;
                var promise = $http
                    .get("/api/documentsources?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&type=fax", searchKeyValue, {
                        cache: CacheService.cacheL2()
                    })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            // Check if Address already exists.
            this.isAddressExists = function (id, address) {
                return $http.get("/api/documentsources/exists", {
                    params: {
                        id: id,
                        address: address
                    }
                });
            };

            this.getDocumentSource = function (id) {
                var promise = $http.get("/api/documentsources/" + id).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            // Ajax request to delete
            this.deleteDocumentSource = function (id) {
                CacheService.clear("/documentsources");
                return $http.delete("/api/documentsources/" + id);
            };

            this.getDocumentSourcesChanges = function (sortDir, pageNumber, pageSize, searchTerm) {
                var searchKeyValue = searchTerm == undefined ? "" : "&search=" + searchTerm;
                var promise = $http
                    .get("/api/documentsources/changes?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + searchKeyValue)
                    .then(function (response) {
                        angular.forEach(response.data.documentSources, function (value, key) {
                            value.pendingDateTime = new Date(value.pendingDateTime + "Z");
                        });
                        return response.data;
                    });
                return promise;
            };

            // Ajax request to save
            this.putDocumentSource = function (documentSource) {
                CacheService.clear("/documentsources");
                documentSource.directAddressType = "fax";
                return $http.put("/api/documentsources/" + documentSource.id, documentSource);
            };

            // Ajax request to save
            this.postDocumentSource = function (documentSource) {
                CacheService.clear("/documentsources");
                documentSource.directAddressType = "fax";
                return $http.post("/api/documentsources/", documentSource);
            };
        }
    ])

    .service("DocumentTypesService", [
        "$http",
        "CacheService",
        function ($http, CacheService) {
            this.getDocumentTypes = function (sortDir, pageNumber, pageSize, searchTerm) {
                // When called with no arguments, we get all document types.
                if (arguments.length == 0) {
                    var promise = $http.get("/api/documenttypes", { cache: CacheService.cacheL2() }).then(function (response) {
                        return response.data;
                    });
                } else {
                    var searchKeyValue = searchTerm == undefined ? "" : "&search=" + searchTerm;
                    var promise = $http
                        .get("/api/documenttypes?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + searchKeyValue, {
                            cache: CacheService.cacheL2()
                        })
                        .then(function (response) {
                            return response.data;
                        });
                }
                return promise;
            };

            this.getSystemDocumentTypes = function (sortDir, pageNumber, pageSize, searchTerm) {
                var searchKeyValue = searchTerm == undefined ? "" : "&search=" + searchTerm;
                var promise = $http
                    .get("/api/documenttypes/system?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + searchKeyValue, {
                        cache: CacheService.cacheL2()
                    })
                    .then(function (response) {
                        return response.data;
                    });

                return promise;
            };

            // Check if Address already exists.
            this.isDocumentTypeExists = function (id, name) {
                return $http.get("/api/documenttypes/exists", {
                    params: {
                        id: id,
                        name: name
                    }
                });
            };

            this.getDocumentType = function (id) {
                var promise = $http.get("/api/documenttypes/" + id).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            // Ajax request to delete
            this.deleteDocumentType = function (id) {
                CacheService.clear("/documenttypes");
                return $http.delete("/api/documenttypes/" + id);
            };

            // Ajax request to save
            this.putDocumentType = function (documentType) {
                CacheService.clear("/documenttypes");
                return $http.put("/api/documenttypes/" + documentType.id, documentType);
            };

            // Ajax request to save
            this.postDocumentType = function (documentType) {
                CacheService.clear("/documenttypes");
                return $http.post("/api/documenttypes/", documentType);
            };
        }
    ])

    .service("ReleaseTypesService", [
        "$http",
        "CacheService",
        "_",
        function ($http, CacheService, _) {
            this.getReleaseTypes = function (sortDir, pageNumber, pageSize, searchTerm) {
                // When called with no arguments, we get all document types.
                if (arguments.length == 0) {
                    var promise = $http.get("/api/releasetypes?pageSize=5000", { cache: CacheService.cacheL2() }).then(function (response) {
                        response.data.itemsCount = response.data.releaseTypesCount; // Satisfy ModalFactory
                        return response.data;
                    });
                } else {
                    var searchKeyValue = !searchTerm ? "" : "&search=" + searchTerm;
                    var promise = $http
                        .get("/api/releasetypes?" + "sortDir=" + sortDir + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + searchKeyValue, {
                            cache: CacheService.cacheL2()
                        })
                        .then(function (response) {
                            response.data.itemsCount = response.data.releaseTypesCount; // Satisfy ModalFactory
                            return response.data;
                        });
                }
                return promise;
            };

            // Check if Address already exists.
            this.isReleaseTypeExists = function (id, name) {
                var promise = $http.get("/api/releasetypes/?search=" + name).then(function (response) {
                    if (response.data.length) {
                        return { data: "true" };
                    } else {
                        return { data: "false" };
                    }
                });
                return promise;
            };

            this.getReleaseType = function (id) {
                var promise = $http.get("/api/releasetypes/" + id).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            // Ajax request to delete
            this.deleteReleaseType = function (id) {
                CacheService.clear("/releasetypes");
                return $http.delete("/api/releasetypes/" + id);
            };

            // Ajax request to save
            this.putReleaseType = function (releaseType) {
                CacheService.clear("/releasetypes");
                return $http.put("/api/releasetypes/" + releaseType.id, releaseType);
            };

            // Ajax request to save
            this.postReleaseType = function (releaseType) {
                CacheService.clear("/releasetypes");
                return $http.post("/api/releasetypes/", releaseType);
            };
        }
    ])

    .service("InteroperabilityService", [
        "$http",
        "CacheService",
        function ($http, CacheService) {
            // Ajax request to save
            (this.put = function (interoperabilitySettings) {
                CacheService.clear("/interoperability");
                return $http.put("/api/settings/interoperability/", interoperabilitySettings);
            }),
                (this.get = function () {
                    return $http.get("/api/settings/interoperability/", { cache: CacheService.cacheL2() });
                });
        }
    ])

    .service("SureScriptsService", [
        "$http",
        "$q",
        function ($http, $q) {
            this.searchDirectory = function (directoryQuery) {
                var promise = $http.post("/api/directory/search", directoryQuery).then(function (res) {
                    return res.data;
                });
                return promise;
            };

            // This getAddresses is using static data with a promise to simulate
            // how we would request data from the SureScripts API. Once we have
            // SureScripts access, replace the static data below with dynamic.
            this.getAddresses = function (startsWith) {
                var deferred = $q.defer();
                var addreses = new Array();

                if (startsWith) {
                    var regex = new RegExp("^" + startsWith);
                    deferred.resolve(
                        _.sortBy(
                            _.filter(addreses, function (x) {
                                return regex.test(x);
                            }),
                            function (x) {
                                return x;
                            }
                        )
                    );
                } else {
                    deferred.resolve(
                        _.sortBy(addreses, function (x) {
                            return x;
                        })
                    );
                }

                return deferred.promise;
            };
        }
    ]);
