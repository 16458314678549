<li
    id="profile-menu"
    class="dropdown"
    ng-controller="ProfileCtrl"
    style="margin-right: -12px"
    data-toggle="tooltip"
    title="Account">
    <a
        class="btn account dropdown-toggle"
        title="Account"
        data-toggle="dropdown">
        <div class="user-name mr-1">{{ currentUser().fullName }}</div>
        <span class="caret more-dropdown"></span>
    </a>
    <ul class="dropdown-menu dropdown-menu-right">
        <li>
            <a
                kno2NoOpLink
                (click)="editProfile()">
                <i class="fa fa-user"></i>
                &nbsp; My Profile
            </a>
        </li>
        <li>
            <a
                kno2NoOpLink
                (click)="editProfile({ changingPassword: true })">
                <i class="fa fa-lock"></i>
                &nbsp; Change Password
            </a>
        </li>
        <li>
            <a
                kno2NoOpLink
                (click)="logout()">
                <i class="fa fa-sign-out"></i>
                &nbsp; Sign Out
            </a>
        </li>
    </ul>
</li>
