declare const angular;

let ctrl;
class AdminDocuSignController {
    public static $inject: Array<string> = ["AdminDocuSignService", "$uibModal"];
    public account: any;

    constructor(
        private AdminDocuSignService,
        private $uibModal
    ) {}

    public $onInit(): void {
        this.AdminDocuSignService.getOrganizationAccounts().then((data) => {
            this.account = data.data.find((x) => x.type === "Kno2DocuSignAccount");
        });
    }

    public login(): void {
        this.$uibModal
            .open({
                component: "k2AdminDocuSignLoginComponent"
            })
            .result.then(() => "", angular.noop);
    }

    public delete(accountId: string): void {
        this.$uibModal
            .open({
                component: "k2AdminDocuSignDeleteModalComponent",
                resolve: {
                    accountId: () => accountId
                }
            })
            .result.then(() => "", angular.noop);
    }
}

export class AdminDocuSignComponent {
    public templateUrl: any;
    public controller: any;

    constructor() {
        this.templateUrl = require("./admin.docusign.component.html");
        this.controller = AdminDocuSignController;
    }
}
