import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LoadingIndicatorService {
    private loadingRoutesSubject = new BehaviorSubject<string[]>([]);
    public isLoading$ = this.loadingRoutesSubject.pipe(map((routes) => routes.length > 0));

    public addRoute(route: string): void {
        this.loadingRoutesSubject.next([...this.loadingRoutesSubject.value, route]);
    }

    public removeRoute(route: string): void {
        this.loadingRoutesSubject.next(this.loadingRoutesSubject.value.filter((r) => r !== route));
    }
}
