import multiValueSnippetTemplate from "./multi-value-snippet.directive.html";

angular.module("kno2.directives").directive("multiValueSnippet", [
    "_",
    function (_) {
        return {
            restrict: "A",
            templateUrl: multiValueSnippetTemplate,
            scope: {
                values: "=",
                showCount: "@",
                delimiter: "@"
            },
            link: function (scope) {
                scope.showCount = scope.showCount || 3;
                scope.delimiter = scope.delimiter || ";";

                scope.$watchCollection("values", function () {
                    var visibleValues = _.take(scope.values, scope.showCount),
                        moreCount = !scope.values ? 0 : scope.values.length - visibleValues.length;

                    scope.model = {
                        allValues: scope.values,
                        visibleValues: visibleValues,
                        delimiter: scope.delimiter,
                        moreCount: moreCount,
                        showMoreValues: !!moreCount
                    };
                });
            }
        };
    }
]);
