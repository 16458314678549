angular.module("kno2.directives").directive("tristate", function () {
    return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, el, attrs, ctrl) {
            ctrl.$formatters = [];
            ctrl.$parsers = [];
            ctrl.$render = function () {
                var checked = ctrl.$viewValue;
                el.data("checked", checked);
                switch (checked) {
                    case true:
                        el.prop("indeterminate", false);
                        el.prop("checked", true);
                        break;
                    case false:
                        el.prop("indeterminate", false);
                        el.prop("checked", false);
                        break;
                    default:
                        el.prop("indeterminate", true);
                        break;
                }
            };

            el.bind("click", function () {
                var checked;
                switch (el.data("checked")) {
                    case false:
                        checked = true;
                        break;
                    case true:
                        checked = false;
                        break;
                    default:
                        checked = false;
                        break;
                }
                scope.tristate = checked;
                ctrl.$setViewValue(checked);
                scope.$apply(ctrl.$render);
            });
        }
    };
});
