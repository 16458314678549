let ctrl;
declare const _;

class SignaturesNewDraftController {
    public static $inject: Array<string> = [
        "NotificationService",
        "$uibModal",
        "ReleaseService",
        "$state",
        "SessionService",
        "DocumentSourceService",
        "appConfig",
        "$window",
        "MessageService"
    ];

    public readonly types: Array<string> = ["Patient Signature Request"];
    public readonly profile: any = this.SessionService.getProfile();
    public readonly isSmsEnabled: boolean = this.profile.signaturesProfileSettings.isSMSNotificationsEnabled;
    public patient: any;
    public templates: Array<any>;
    public message: any;
    public recipients: Array<any>;
    public allowedSenders: Array<any>;
    public loadingDraft: boolean;
    public sending: boolean;
    public draftId: string;
    public templatesInProcess: Array<any>;
    public showInPersonSigner: boolean;
    public emailRegex: any =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public phoneRegex: any = /^(?:\+{0,1}?)(\d{0,1})(?:[-.\s]?)(?:\(?)(\d{3})(?:\)?)(?:[-.\s]?)(\d{3})(?:[-\.\s]?)(\d{4})$/;

    constructor(
        private NotificationService,
        private $uibModal,
        private ReleaseService,
        private $state,
        private SessionService,
        private DocumentSourceService,
        private appConfig,
        private $window,
        private MessageService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.templates = [];
        this.allowedSenders = [];
        this.templatesInProcess = [];
        this.patient = {};
        this.message = {
            organizationName: this.profile.organizationName,
            origin: this.appConfig.brand,
            fromAddress: null,
            toAddresses: [],
            signers: []
        };
        this.loadingDraft = false;
        this.recipients = [];
        this.draftId = null;

        ctrl.message.releaseType = "Patient Signature Request";

        this.loadAllowedSenders();

        if (this.$window.location.pathname.indexOf("/draft") > -1) {
            this.buildPageFromDraft();
        }
    }

    public draftInContext(): boolean {
        return this.draftId !== undefined && this.draftId !== null;
    }

    private setSignerIds(signers: Array<any>): void {
        const finalRecipients = this.getValidRecipients();
        finalRecipients.forEach((recipient) => {
            const signerResponse = signers.find((x) => x.role === recipient.role);
            recipient.id = signerResponse.id;
            if (this.hasSmsPhoneNumber(recipient.additionalNotifications)) {
                recipient.additionalNotifications[0].id = signerResponse.additionalNotifications[0].id;
            }
        });
    }

    public async send(): Promise<any> {
        this.NotificationService.hideErrors();
        this.sending = true;
        try {
            if (this.isValid()) {
                this.updateMessage();
                await this.ReleaseService.saveDraft(this.draftId, this.message, [], this.patient);
                const signers = await this.MessageService.getSigners(this.draftId);
                this.setSignerIds(signers);
                this.updateMessage();

                const message = this.message;
                const patient = this.patient;
                const draftId = this.draftId;
                const templates = this.templates;

                const modalInstance = this.$uibModal.open({
                    windowClass: "intake-review-send-message-modal",
                    component: "SignatureRequestConfirmSendComponent",
                    resolve: {
                        message: () => message,
                        patient: () => patient,
                        draftId: () => draftId,
                        templates: () => templates
                    }
                });
                modalInstance.result.then((result) => {
                    if (result === "success") {
                        this.$state.go("signatures");
                    }
                });
            }
        } catch (err) {
            this.NotificationService.error("Failed to update message.");
            throw err;
        } finally {
            this.sending = false;
        }
    }

    public signerTypeDisplay(signerType: string): string {
        if (signerType) {
            return signerType.replace(/([A-Z])/g, " $1").trim();
        }

        return "Signer Type";
    }

    public isSignerFilledOut(recipient: any): boolean {
        return (recipient.email && recipient.name) || (recipient.hostEmail && recipient.hostName && recipient.name);
    }

    private atLeastOneRoleIsCompleted(): boolean {
        return this.getValidRecipients().length != 0;
    }

    private async buildPageFromDraft(): Promise<any> {
        this.loadingDraft = true;

        try {
            const messageId = ctrl.$stateParams.id;

            const message = await this.MessageService.getDraft(messageId);
            ctrl.message.fromAddress = message.fromAddress;
            ctrl.message.subject = message.subject;
            ctrl.message.releaseType = "Patient Signature Request";

            ctrl.draftId = messageId;
            ctrl.patient = message.patient || {};

            await this.setRolesFromTemplates(message);

            const signers = await this.MessageService.getSigners(messageId);
            this.message.signers = signers;
            if (signers.length > 0) {
                signers.forEach((signer) => {
                    const recipient = this.recipients.find((x) => x.role == signer.role);
                    if (recipient) {
                        recipient.id = signer.id;
                        recipient.email = signer.email;
                        recipient.name = signer.name;
                        recipient.hostEmail = signer.hostEmail;
                        recipient.hostName = signer.hostName;
                        recipient.signerType = signer.signerType;
                        recipient.additionalNotifications = signer.additionalNotifications;
                    }
                });
            }
        } catch (err) {
            this.NotificationService.error("Failed to load draft.");
        } finally {
            this.showInPersonSigner = this.rolesContainInPersonSigner();
            this.loadingDraft = false;
        }
    }

    private isValid(): boolean {
        let errors = [];

        if (!this.message.releaseType) {
            errors.push(`Request Type is required.`);
        }
        if (!this.message.fromAddress) {
            errors.push(`From Address is required.`);
        }
        if (_.isEmpty(this.patient)) {
            errors.push(`Patient Details are required.`);
        } else {
            if (!this.patient.firstName) {
                errors.push(`Patient First Name is required.`);
            }
            if (!this.patient.lastName) {
                errors.push(`Patient Last Name is required.`);
            }
            if (!this.patient.gender) {
                errors.push(`Patient Gender is required.`);
            }
            if (!this.patient.birthDate) {
                errors.push(`Patient Birth Date is required.`);
            }
        }

        if (this.templates.length <= 0) {
            errors.push("At least one Template must be selected.");
        } else {
            if (!this.recipients) {
                errors.push(`Signers are required.`);
            } else {
                if (!this.atLeastOneRoleIsCompleted()) {
                    errors.push(`At least one Signer must have all fields completed.`);
                }
                errors = this.validateRecipientErrors(errors);
            }
        }

        if (errors.length > 0) {
            this.NotificationService.errors(errors, "Please fix the following errors before sending.");
            return false;
        }

        return true;
    }

    private getValidRecipients(): Array<any> {
        return this.recipients.filter((x) => (x.email && x.name) || (x.hostEmail && x.hostName && x.name));
    }

    private validateRecipientErrors(errors: Array<string>): Array<string> {
        const recipients = this.getValidRecipients();

        recipients.forEach((x) => {
            if (x.signerType !== "InPersonSigner" && !this.emailRegex.test(x.email)) errors.push(`Invalid email provided for Role: ${x.role}`);
            if (x.signerType === "InPersonSigner" && !this.emailRegex.test(x.hostEmail)) errors.push(`Invalid host email provided for Role: ${x.role}`);
            const hasPhoneNumber = this.hasSmsPhoneNumber(x.additionalNotifications);
            if (hasPhoneNumber && !this.phoneRegex.test(x.additionalNotifications[0].value)) errors.push(`Invalid phone number provided for Role: ${x.role}`);
        });
        return errors;
    }

    private loadAllowedSenders(): void {
        this.DocumentSourceService.getReleaseDocumentSourcesByUser().then((data) => {
            const directMessageSenders = data.filter((x) => x.type === "DirectMessage");
            ctrl.allowedSenders = _.map(directMessageSenders, "fullAddress");

            if (ctrl.allowedSenders.length == 1 && _.isEmpty(ctrl.message.fromAddress)) {
                ctrl.message.fromAddress = ctrl.allowedSenders[0];
            }
        });
    }

    private rolesContainInPersonSigner(): boolean {
        const inPersonSigner = this.recipients.find((x) => x.signerType == "In-Person Signer");
        return inPersonSigner != undefined;
    }

    private async setRolesFromTemplates(message: any): Promise<void> {
        try {
            const templateDtos = message.attachments.map((x) => {
                return {
                    templateId: x.attachmentMeta.documentDescription,
                    attachmentId: x.id
                };
            });

            const dataTemplates = await ctrl.IntegrationsService.getTemplates();
            const templates = dataTemplates.filter((x) => templateDtos.map((x) => x.templateId).includes(x.templateId));

            templates.forEach((template) => {
                template.attachmentId = templateDtos.find((x) => x.templateId == template.templateId).attachmentId;
                this.templates.push(template);
                template.signers.forEach((signer) => {
                    const duplicateRole = this.recipients.find((x) => x.role == signer.roleName);
                    if (duplicateRole) {
                        duplicateRole.templateIds.push(template.templateId);
                    } else {
                        this.recipients.push({
                            id: 0,
                            name: "",
                            role: signer.roleName,
                            email: "",
                            templateIds: [template.templateId],
                            hostName: "",
                            hostEmail: "",
                            signerType: signer.signerType
                        });
                    }
                });
            });
        } catch (err) {
            this.NotificationService.error("Failed to load draft.");
            this.loadingDraft = false;
        }
    }

    private updateMessage(): void {
        this.message.subject = this.templates[0].emailSubject;
        const finalRecipients = this.getValidRecipients();
        finalRecipients.forEach((x) => {
            const hasPhoneNumber = this.hasSmsPhoneNumber(x.additionalNotifications);
            if (!hasPhoneNumber) x.additionalNotifications = [];
            else x.additionalNotifications[0].notificationType = "SMS";
        });

        this.message.signers = finalRecipients;
    }

    private hasSmsPhoneNumber(notifications: Array<any>): boolean {
        const phoneNumber = _.get({ notifications }, "notifications[0].value", null);
        return phoneNumber !== null && phoneNumber.length;
    }
}

class SignaturesNewDraftComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./signatures.new-draft.component.html");
        this.controller = SignaturesNewDraftController;
        this.bindings = {
            templates: "="
        };
    }
}

export const signaturesNewDraftComponent = new SignaturesNewDraftComponent();
