import templateUrl from "./service-account.component.html";

export const serviceAccountComponent = {
    templateUrl: templateUrl,
    controller: ServiceAccountController,
    bindings: {
        type: "<",
        group: "<"
    }
};

ServiceAccountController.$inject = ["$http", "$uibModal", "ServiceAccountServiceFactory", "NotificationService"];

export function ServiceAccountController($http, $uibModal, ServiceAccountServiceFactory, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.wizardInit = wizardInit;
    ctrl.getCredentials = getCredentials;
    ctrl.showClipboardCapture = showClipboardCapture;
    ctrl.toggleEnableStatus = toggleEnableStatus;
    ctrl.saveIpAddresses = saveIpAddresses;
    ctrl.confirmGenerate = confirmGenerate;
    ctrl.confirmDelete = confirmDelete;

    function $onInit() {
        ctrl.serviceAccountService = ServiceAccountServiceFactory.ofType(ctrl.type);
        ctrl.clientSecretClipboard = false;
        ctrl.group = ctrl.group || {};
    }

    function wizardInit(wizard) {
        ctrl.wizard = wizard;
        getClientApiSettings();
    }

    function getClientApiSettings() {
        if (!ctrl.group.id) {
            setNoClientCredentialState();
            ctrl.wizard.step0();
            return;
        }

        return ctrl.serviceAccountService.getServiceAccount(ctrl.group.id).then(
            function (clientSettings) {
                //Set inital client credential info
                // No Client Id then we are brand new
                if (clientSettings.clientId) {
                    setExistingClientCredentialState(
                        clientSettings.clientId,
                        null,
                        null,
                        clientSettings.clientIpAddresses,
                        clientSettings.serviceAccountEnabled
                    );
                    ctrl.lastLoginDate = clientSettings.lastLoginDate;
                    ctrl.lastLoginIp = clientSettings.lastLoginIp;
                    ctrl.hasClientCredentials = !!ctrl.clientId;
                    ctrl.clientEnabled = clientSettings.serviceAccountEnabled;
                    ctrl.sourceIpAddress = clientSettings.sourceIpAddress;
                    if (clientSettings.clientIpAddresses.length > 0) {
                        ctrl.wizard.step3();
                        ctrl.wizard.show = false;
                        ctrl.wizard.complete = true;
                    } else {
                        ctrl.wizard.step2();
                    }
                }
            },
            function () {
                setNoClientCredentialState();
                ctrl.wizard.step0();
            }
        );
    }

    function setClientCredentialInfo(
        clientId,
        clientSecret,
        clientMessage,
        clientGenerateMessage,
        clientConfirmTitle,
        clientConfirmWarning,
        clientConfirmBody,
        ipAddresses,
        enabled
    ) {
        ctrl.clientId = clientId;
        ctrl.clientSecret = clientSecret;
        ctrl.clientMessage = clientMessage;
        ctrl.clientGenerateMessage = clientGenerateMessage;
        ctrl.clientConfirmTitle = clientConfirmTitle;
        ctrl.clientConfirmWarning = clientConfirmWarning;
        ctrl.clientConfirmBody = clientConfirmBody;
        ctrl.clientIpAddresses = [];
        ctrl.clientEnabled = enabled;
        ctrl.showIpAddressAddButton = true;
        ctrl.clientIpAddresses = ipAddresses;
    }

    function setNoClientCredentialState() {
        setClientCredentialInfo(
            null,
            null,
            null,
            "Create Client Credentials",
            "Generate New Client Key and Secret?",
            "Generating a client key and secret <u>will enable</u> access to your service account API.",
            "Are you sure you want to generate those credentials now?",
            []
        );
    }

    function setExistingClientCredentialState(clientId, clientSecret, warningMessage, ipAddresses, enabled) {
        if (!clientSecret) {
            clientSecret = "**********************";
        }
        setClientCredentialInfo(
            clientId,
            clientSecret,
            warningMessage,
            "Regenerate",
            "Regenerate Existing Client Key and Secret?",
            "Regenerating your client key and secret <u>will deauthorize</u> current clients.",
            "Are you sure you want to regenerate now?",
            ipAddresses,
            enabled
        );
    }

    function getCredentials() {
        return "Key: " + ctrl.clientId + "\r\nSecret: " + ctrl.clientSecret + "\r\n";
    }

    function showClipboardCapture() {
        if (ctrl.clientIpAddresses == undefined || ctrl.clientIpAddresses.length === 0) {
            ctrl.wizard.step2();
        }
        NotificationService.success("Client key and secret copied to your clipboard");
    }

    function toggleEnableStatus() {
        var state = ctrl.clientEnabled ? "disable" : "enable";

        ctrl.serviceAccountService
            .toggleStatus(ctrl.group.id, state)
            .then(function (data) {
                ctrl.clientEnabled = data.enabled;
                NotificationService.success("Client has been " + (ctrl.clientEnabled ? "enabled" : "disabled"));
            })
            .catch(function () {
                NotificationService.error("An error occurred when updating client settings state");
            });
    }

    function saveIpAddresses(addresses) {
        if (addresses.length === 0) {
            NotificationService.error("Please add IP Addresses to save.", true);
            return;
        }

        ctrl.serviceAccountService.updateIpAddresses(ctrl.group.id, addresses).then(
            function (data) {
                NotificationService.success("IP Address Settings Updated");
                ctrl.wizard.step3();
                ctrl.clientIpAddresses = _.uniq(data.clientIpAddresses);
            },
            function (error) {
                NotificationService.error(error.data.message, true);
            }
        );
    }

    function confirmGenerate() {
        var modalInstance = $uibModal.open({
            component: "k2ClientCredentialsGenerateModal",
            resolve: {
                title: function () {
                    return ctrl.clientConfirmTitle;
                },
                warning: function () {
                    return ctrl.clientConfirmWarning;
                },
                body: function () {
                    return ctrl.clientConfirmBody;
                },
                button: function () {
                    return ctrl.clientGenerateMessage;
                },
                hasClientCredentials: function () {
                    return ctrl.hasClientCredentials;
                },
                group: function () {
                    return ctrl.group;
                },
                type: function () {
                    return ctrl.type;
                }
            }
        });

        modalInstance.result.then(
            function (result) {
                setExistingClientCredentialState(
                    result.clientId,
                    result.clientSecret,
                    "Save your secret — this is the <u>last time</u> you will have access to it",
                    result.clientIpAddresses
                );
                ctrl.clientSecretClipboard = true;
                NotificationService.success("API Settings Updated");
                ctrl.wizard.step1();
                ctrl.hasClientCredentials = !!ctrl.clientId;
            },
            function () {
                // oh noes
            }
        );
    }

    function confirmDelete() {
        var modalInstance = $uibModal.open({
            component: "k2ClientCredentialsDeleteModal",
            resolve: {
                group: function () {
                    return ctrl.group;
                },
                type: function () {
                    return ctrl.type;
                }
            }
        });

        modalInstance.result.then(
            function () {
                setNoClientCredentialState();
                ctrl.clientSecretClipboard = false;
                NotificationService.success("API Settings Deleted");
                ctrl.hasClientCredentials = false;
                ctrl.wizard.complete = false;
                ctrl.wizard.step0();
            },
            function () {
                // oh noes
            }
        );
    }
}
