import templateUrl from "./admin-organization-types-edit.modal.component.html";

export const adminOrganizationTypesEditModalComponent = {
    templateUrl,
    controller: AdminOrganizationTypesEditModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AdminOrganizationTypesEditModalController.$inject = ["NotificationService", "OrganizationTypesService", "OrganizationSpecialtiesService"];

export function AdminOrganizationTypesEditModalController(NotificationService, OrganizationTypesService, OrganizationSpecialtiesService) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.save = save;
    ctrl.cancel = cancel;

    function onInit() {
        ctrl.organizationTypeId = ctrl.resolve.organizationTypeId;

        OrganizationSpecialtiesService.getAll().then(function (response) {
            ctrl.specialties = response;
        });
        if (ctrl.organizationTypeId) {
            OrganizationTypesService.getOrganizationType(ctrl.organizationTypeId).then(function (response) {
                ctrl.organizationType = response;
            });
        }
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function save() {
        const promise = ctrl.organizationType.id
            ? OrganizationTypesService.update(ctrl.organizationType)
            : OrganizationTypesService.create(ctrl.organizationType);

        promise.then(
            function (response) {
                ctrl.close();
            },
            function (error) {
                NotificationService.error(error.message);
            }
        );
    }
}
