import decisionModalTemplate from "./account.id-proofing.identity-decision-modal.html";
import questionsModalTemplate from "./account.id-proofing.identity-questions-modal.html";
import tOSModalTemplate from "./account.id-proofing.identity-terms-and-conditions-modal.html";
import identityVerificationModalTemplate from "./account.id-proofing.identity-verification-modal.html";
import welcomeModalTemplate from "./account.id-proofing.identity-welcome-modal.html";
import submitIdentityModalTemplate from "./account.id-proofing.submit-identity-modal.html";

import { OrgActivationDelayModalComponent } from "../../../../AppNgx/app/features/account/org-activation-delay-modal/org-activation-delay-modal.component";

IdProofingCtrl.$inject = [
    "$scope",
    "$uibModal",
    "ngbModalService",
    "$timeout",
    "usersFactory",
    "SessionService",
    "NotificationService",
    "IdProofingService",
    "localStorageService",
    "SessionStorageFactory"
];
export function IdProofingCtrl(
    $scope,
    $uibModal,
    ngbModalService,
    $timeout,
    usersFactory,
    sessionService,
    notificationService,
    idProofingService,
    localStorageService,
    sessionStorageFactory
) {
    $scope.userProfile = sessionService.getProfile();

    $scope.checkDuplicateAddress = function () {
        idProofingService.checkDuplicateAddress($scope.identity.currentAddress.addressLine);
    };

    $scope.openIdentityModal = function () {
        var identityModal = $uibModal.open({
            templateUrl: submitIdentityModalTemplate,
            windowClass: "id-proofing-modal",
            controller: "SubmitIdentityModalCtrl",
            keyboard: false,
            backdrop: "static",
            resolve: {
                identity: function () {
                    return $scope.identity;
                }
            }
        });
        identityModal.result.then(function (identityResponse) {
            if (identityResponse.quizId) {
                // Got a quiz
                $scope.openQuizModal(identityResponse);
            } else {
                // Got a decision
                $scope.openDecisionModal(identityResponse);
            }
        });
    };

    $scope.openQuizModal = function (quiz) {
        var questionsModal = $uibModal.open({
            templateUrl: questionsModalTemplate,
            controller: "IdentityQuestionsModalCtrl",
            windowClass: "identity-questions-modal",
            backdrop: "static",
            keyboard: false,
            resolve: {
                quiz: function () {
                    return quiz;
                }
            }
        });
        questionsModal.result.then(function (answersResponse) {
            if (answersResponse.quizId) {
                // Got a quiz
                $scope.openQuizModal(answersResponse);
            } else {
                // Got a decision
                $scope.openDecisionModal(answersResponse);
            }
        });
    };

    $scope.openDecisionModal = function (completion) {
        var decisionModal = $uibModal.open({
            templateUrl: decisionModalTemplate,
            controller: "IdentityDecisionModal",
            backdrop: "static",
            keyboard: false,
            resolve: {
                completion: function () {
                    return completion;
                }
            }
        });
        decisionModal.result.then(
            function () {
                sessionService.refreshProfile();
            },
            function (error) {}
        );
    };

    $scope.openTermsAndConditionsModal = function () {
        var termsAndConditionsModal = $uibModal.open({
            windowClass: "identity-terms-and-conditions-modal",
            templateUrl: tOSModalTemplate,
            controller: "IdentityTermsAndConditionsModal",
            backdrop: "static",
            keyboard: false
        });
        termsAndConditionsModal.result.then(() => {
            if (!$scope.userProfile.orgIsIdProofed) {
                $scope.openWelcomeKno2Modal();
            } else if ($scope.shouldDisplayOrgActivationDelayModal()) {
                $scope.openOrgActivationDelayModal();
            }
        });
    };

    $scope.openResellerTosModal = function () {
        $uibModal
            .open({
                component: "k2ResellerTosModal",
                backdrop: "static",
                keyboard: false
            })
            .result.then(() => {
                if (!$scope.userProfile.orgIsIdProofed) {
                    $scope.openWelcomeKno2Modal();
                } else if ($scope.shouldDisplayOrgActivationDelayModal()) {
                    $scope.openOrgActivationDelayModal();
                }
            });
    };

    $scope.openWelcomeKno2Modal = function () {
        var welcomeKno2Modal = $uibModal.open({
            windowClass: "identity-welcome-kno2-modal",
            templateUrl: welcomeModalTemplate,
            controller: "IdentityWelcomeKno2Modal",
            backdrop: "static",
            keyboard: false
        });
        welcomeKno2Modal.result.then(function (response) {
            if (!$scope.userProfile.orgIsIdProofed) {
                $scope.openIdentityVerificationModal();
            } else if ($scope.shouldDisplayOrgActivationDelayModal()) {
                $scope.openOrgActivationDelayModal();
            }
        });
    };

    $scope.openOrgActivationDelayModal = function () {
        ngbModalService.open(OrgActivationDelayModalComponent);
    };

    $scope.openIdentityVerificationModal = function () {
        var welcomeKno2Modal = $uibModal.open({
            windowClass: "identity-verification-modal",
            templateUrl: identityVerificationModalTemplate,
            controller: "IdentityVerificationModal",
            backdrop: "static",
            keyboard: false
        });
        welcomeKno2Modal.result.then(function (response) {
            if (!$scope.userProfile.orgIsIdProofed) {
                $scope.openIdentityModal();
            } else if ($scope.shouldDisplayOrgActivationDelayModal()) {
                $scope.openOrgActivationDelayModal();
            }
        });
    };

    $scope.shouldDisplayOrgActivationDelayModal = function () {
        return (
            !$scope.userProfile.orgIsActivated &&
            localStorageService.get("hideOrgActivationDelayModal") !== true &&
            sessionStorageFactory.get("hideOrgActivationDelayModal") !== "true"
        );
    };

    $scope.submitIdentity = function () {
        $scope.openIdentityModal();
    };

    // init
    (function () {
        // User is an admin and the org has no admins that have been ID Proofed
        idProofingService.getUserIdentity().then(function (res) {
            $scope.identity = res.data;
        });

        var action;
        if ($scope.userProfile.isAdmin) {
            if (!$scope.userProfile.orgIsAcceptedEULA) {
                action = $scope.userProfile.resellerBypassTos ? $scope.openResellerTosModal : $scope.openTermsAndConditionsModal;
            } else if (!$scope.userProfile.orgIsIdProofed) {
                if ($scope.userProfile.idProofedAttemptDate) {
                    action = function () {
                        return $scope.openDecisionModal({
                            decision: "N",
                            transactionKey: "",
                            overallScore: 0,
                            userIdentity: {},
                            reasons: []
                        });
                    };
                } else {
                    action = $scope.openWelcomeKno2Modal;
                }
            } else {
                if ($scope.shouldDisplayOrgActivationDelayModal()) {
                    action = $scope.openOrgActivationDelayModal;
                }
            }
        }

        if (action) $timeout(action, 1000, false);
    })();
}
