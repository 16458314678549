<div class="col-sm-12">
    <h2>Integrations cache</h2>
    <ul class="list-group">
        <li class="list-group-item">
            <strong>Clear integration cached data</strong>
            <button
                type="button"
                class="btn btn-danger pull-right"
                (click)="clearCachedData()">
                <i class="fa fa-trash"></i>
            </button>
        </li>
    </ul>
    @if (externalLoginTokens().length) {
        <div style="margin-top: 15px">
            <h2>You have the following active external login tokens</h2>
            <ul class="list-group">
                @for (token of externalLoginTokens(); track $index) {
                    <li class="list-group-item">
                        <strong>{{ token.name }}</strong>
                        <button
                            type="button"
                            class="btn btn-danger pull-right"
                            (click)="revokeToken(token)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </li>
                }
            </ul>
        </div>
    }
</div>
