import { Directive, TemplateRef, ViewContainerRef, inject, input } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { filter, firstValueFrom } from "rxjs";

@Directive({
    selector: "[kno2FeatureToggle]",
    standalone: true
})
export class FeatureToggleDirective {
    private readonly upgrade = inject(UpgradeModule);
    private readonly featureService = this.upgrade.$injector.get("FeatureService");
    private readonly templateRef = inject(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);

    private isReady = firstValueFrom(this.featureService.isLoaded$.pipe(filter(Boolean)));

    protected requiredFeatures = input.required<string[]>({ alias: "kno2FeatureToggle" });

    public async ngOnInit(): Promise<void> {
        await this.isReady;
        if (this.featureService.isEnabled(this.requiredFeatures())) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
