declare const angular;

class AuthorizationCodeCallbackController {
    public static $inject: Array<string> = ["$stateParams", "$location", "$http"];
    private integrationMap: any;

    constructor(
        private $stateParams,
        private $location,
        private $http
    ) {
        this.integrationMap = {
            docusign: "DocusignApi.DocusignApiIntegration"
        };
    }

    public $onInit(): void {
        const type: string = this.$stateParams.type.toLowerCase();
        const queryStringParams = this.$location.search();
        const code = queryStringParams.code;

        if (type === "kno2docusign") {
            this.$http.post("/api/connectors/ds/authcode", { authorizationCode: code }).then(() => {
                window.close();
            });
        } else {
            this.$http
                .post("/api/integrations/authcode", {
                    authorizationCode: code,
                    integrationType: this.integrationMap[type]
                })
                .then(() => {
                    window.close();
                });
        }
    }
}

export class AuthorizationCodeCallbackComponent {
    public controller: any;

    constructor() {
        this.controller = AuthorizationCodeCallbackController;
    }
}
