(function () {
    "use strict";

    angular.module("kno2.records").service("FacilitiesService", facilities);

    facilities.$inject = ["$http", "SessionService"];

    function facilities($http, sessionService) {
        var service = {
            getMyFacilities: getMyFacilities,
            getMyFacilitiesByName: getMyFacilitiesByName,
            getMyFacilitiesByQuery: getMyFacilitiesByQuery,
            getFacilitiesByQuery: getFacilitiesByQuery
        };

        return service;

        function getMyFacilities() {
            return getMyFacilitiesByQuery();
        }

        function getMyFacilitiesByName(name) {
            return getMyFacilitiesByQuery({ facilityName: name });
        }

        function getMyFacilitiesByQuery(query) {
            var orgId = sessionService.getProfile().organizationId;
            return $http.get("/api/organizations/{id}/facilities".replace("{id}", orgId), { params: query }).then(function (response) {
                return response.data;
            });
        }

        function getFacilitiesByQuery(query) {
            return $http.get("/api/facilities/search/", { params: query }).then(function (response) {
                return response.data;
            });
        }
    }
})();
