import { Injectable } from "@angular/core";
import { IHttpResponse } from "angular";

@Injectable({
    providedIn: "root"
})
export class HttpUtilitiesService {
    public getFileName(response: IHttpResponse<void>, defaultName: string): string {
        var contentDisposition = response.headers()["content-disposition"];
        if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, "");
            }
        }

        return defaultName;
    }
}
