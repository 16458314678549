let ctrl;
declare const _;
class PatientTemplatesController {
    public static $inject: Array<string> = [
        "CommonData",
        "NotificationService",
        "$uibModal",
        "$confirm",
        "ReleaseService",
        "AttachmentService",
        "IntegrationsService",
        "ExternalLoginService",
        "SessionService"
    ];

    public states: Array<any>;
    public templates: Array<any>;
    public patient: any;
    public recipients: Array<any>;
    public draftId: string;
    public message: any;
    public loadingDraft: boolean;
    public readonly attachmentMetadata: any;
    public readonly standardTemplate: string = "Standard";
    public readonly dateOptions: any = { maxDate: new Date() };

    public templatesInProcess: Array<any>;

    public searchableFields: Set<string>;
    public patientLookupAvailable: boolean;
    public externalLogin: boolean;
    public integrationDisplayName: string;
    public hasFacilityLookup: boolean;
    public patientLookupTemplate: any;
    public patientDetailsLookupAvailable: boolean;
    public visitLookupAvailable: boolean;
    public facilities: Array<any>;
    public templateSource: string;

    constructor(
        private CommonData,
        private NotificationService,
        private $uibModal,
        private $confirm,
        private ReleaseService,
        private AttachmentService,
        private IntegrationsService,
        private ExternalLoginService,
        private SessionService
    ) {}

    public $onInit(): void {
        ctrl = this;
        ctrl.states = ctrl.CommonData.states.asArray();

        ctrl.facilities = [];
        ctrl.patientLookupAvailable = false;
        ctrl.searchableFields = new Set();
        ctrl.externalLogin = false;
        ctrl.integrationDisplayName = "";
        ctrl.hasFacilityLookup = false;
        ctrl.patientDetailsLookupAvailable = false;
        ctrl.visitLookupAvailable = false;
        ctrl.patientLookupTemplate = ctrl.standardTemplate;

        let profile = ctrl.SessionService.getProfile();
        ctrl.templateSource = profile.signaturesProfileSettings.templateSource;
        ctrl.attachmentMetadata = { documentType: profile.signaturesProfileSettings.documentType, convert: false };

        ctrl.IntegrationsService.getCapabilities().then((integrations) => {
            integrations.capabilities.forEach((x) => (ctrl[`${x.display}Available`] = true));

            ctrl.searchableFields = new Set(integrations.capabilities.find((x) => x.display === "patientLookup")?.properties.map((p) => p.name));
            ctrl.externalLogin =
                integrations.externalLogin ||
                !!_.find(integrations.capabilities, {
                    display: ctrl.IntegrationsService.capabilities.HaveCodeGrantLogin
                });
            ctrl.integrationDisplayName = integrations.displayName;
            ctrl.hasFacilityLookup = _.some(integrations.capabilities, (x) => x.display === "facilityLookup");
            if (ctrl.hasFacilityLookup) {
                ctrl.IntegrationsService.getFacilities(true).then((facs) => {
                    ctrl.facilities = facs;
                });
            }

            if (ctrl.patientLookupAvailable && integrations.customPatientLookupTemplate) {
                const customTemplates = {
                    "hcs-patient-lookup-modal": "Hcs"
                };
                ctrl.patientLookupTemplate = customTemplates[integrations.customPatientLookupTemplate];
            }
        });
    }

    public beginPatientSearch(): void {
        if (!ctrl.externalLogin) {
            ctrl.openPatientLookupModal();
        } else {
            ctrl.ExternalLoginService.externalLogin({ name: ctrl.integrationDisplayName }, ctrl.openPatientLookupModal);
        }
    }

    private openPatientLookupModal(): void {
        const modal = ctrl.$uibModal.open({
            windowClass: "modal-draggable modal-800",
            component: "PatientSearchModalComponent",
            resolve: {
                patient: () => ctrl.patient,
                patientLookupTemplate: () => ctrl.patientLookupTemplate,
                visitEnabled: () => ctrl.visitLookupAvailable,
                searchableFields: () => ctrl.searchableFields,
                hasFacilityLookup: () => ctrl.hasFacilityLookup,
                facilities: () => ctrl.facilities || [],
                integrationDisplayName: () => ctrl.integrationDisplayName
            }
        });

        modal.result.then(
            function (result) {
                if (!result) return;

                if (result.patient) {
                    if (ctrl.patientDetailsLookupAvailable) {
                        ctrl.IntegrationsService.getPatientById(result.patient.patientResourceId).then((x) => {
                            ctrl.mapPatient(ctrl.patient, x);
                        });
                    } else {
                        ctrl.mapPatient(ctrl.patient, result.patient);
                    }
                }

                if (result.visit) {
                    ctrl.visit = result.visit;
                    ctrl.mapVisit(ctrl.patient, result.visit);
                }
            },
            () => {
                /* handle dismiss */
            }
        );
    }

    public mapPatient(p1: any, p2: any): void {
        p1.integrationMeta = p1.integrationMeta || {};
        p1.integrationMeta.patientId = p2.patientResourceId;
        p1.patientId = p2.patientId;
        p1.firstName = p2.firstName;
        p1.middleName = p2.middleName;
        p1.lastName = p2.lastName;
        p1.birthDate = p2.birthdate;
        p1.gender = ctrl.mapGender(p2.gender);
        if (p2.medicalRecordNumber) p1.integrationMeta.medicalRecordNumber = p2.medicalRecordNumber;
        else delete p1.integrationMeta.medicalRecordNumber;

        p1.streetAddress1 = p2.streetAddress1;
        p1.streetAddress2 = p2.streetAddress2;
        p1.city = p2.city;
        if (p2.facilityId) p1.integrationMeta.facilityId = p2.facilityId.toString();
        else delete p1.integrationMeta.facilityId;

        if (p2.visitDate) p1.visitDate = p2.visitDate;

        const state = ctrl.mapState(p2.state);
        p1.state = state ? state.abbr : null;

        p1.postalCode = p2.postalCode;
        p1.telephone = p2.telephone;
    }

    public mapGender(gender: any): any {
        if (!gender) return null;
        switch (gender.toLowerCase()) {
            case "m":
            case "male":
                return "M";
            case "f":
            case "female":
                return "F";
            case "un":
            case "undifferentiated":
                return "UN";
            default:
                return null;
        }
    }

    public mapState(state: any): any {
        if (!state) return null;
        const lowerState = state.toLowerCase();
        const foundState = _.find(ctrl.states, function (s) {
            if (lowerState.length === 2) return s.abbr.toLowerCase() === lowerState;
            return s.name.toLowerCase() === lowerState;
        });
        return foundState;
    }

    public mapVisit(v1: any, v2: any): void {
        v1.integrationMeta = v1.integrationMeta || {};
        v1.integrationMeta.visitId = v2.id;
        v1.visitId = v2.id;
        v1.visitDate = v2.date;
    }

    public clearPatient(): void {
        ctrl.mapPatient(ctrl.patient, {});
        ctrl.clearVisit();
    }

    public clearVisit() {
        ctrl.visit = null;
        ctrl.mapVisit(ctrl.patient, {});
        ctrl.clearOrders();
    }

    public clearOrders() {
        _.each(ctrl.attachments, function (a) {
            if (a.integrationMeta) a.integrationMeta.orderIds = [];
        });
    }

    public addTemplate(): void {
        ctrl.NotificationService.hideErrors();
        ctrl.openAddTemplateModal();
    }

    public deleteTemplate(index: number): void {
        const template = ctrl.templates[index];

        if (!ctrl.draftId || !template.attachmentId) {
            ctrl.NotificationService.error("Cannot remove specified template from Message.");
            return;
        }

        const modal = ctrl.$confirm.open({
            title: "Remove Template",
            bodyText: `You are about to remove template "${template.name}".\n\nAre you sure?`
        });

        modal.result.then((ok) => {
            if (ok) {
                ctrl.AttachmentService.deleteAttachment(ctrl.draftId, template.attachmentId)
                    .then(() => {
                        ctrl.templates.splice(index, 1);
                        ctrl.NotificationService.success("Template has been removed.");
                        ctrl.deleteRoles(template);
                    })
                    .catch((err) => {
                        ctrl.NotificationService.error("Failed to remove template from message.");
                    });
            }
        });
    }

    public openAddTemplateModal(): void {
        const templateIds = ctrl.templates.map((x) => x.templateId);
        const modalInstance = ctrl.$uibModal.open({
            component: "patientTemplatesAddComponent",
            resolve: {
                templateIds: function () {
                    return templateIds;
                }
            }
        });
        modalInstance.result.then((newTemplate) => {
            ctrl.addNewTemplate(newTemplate);
        });
    }

    private addNewTemplate(newTemplate: any): void {
        if (newTemplate) {
            if (ctrl.templates.find((x) => x.templateId == newTemplate.templateId)) {
                ctrl.NotificationService.error("Cannot add duplicate templates.");
            } else {
                ctrl.templatesInProcess.push(newTemplate);
                const templateFile = ctrl.createFileFromTemplate(newTemplate);
                const meta = { ...ctrl.attachmentMetadata, documentDescription: newTemplate.templateId };
                if (!ctrl.draftId) {
                    ctrl.ReleaseService.saveDraft(ctrl.draftId, ctrl.message, [], ctrl.patient).then((message) => {
                        ctrl.draftId = message.id;
                        ctrl.AttachmentService.uploadAttachment(message.id, templateFile, meta).then((attachment) => {
                            newTemplate.attachmentId = attachment.data.id;
                            ctrl.templates.push(newTemplate);
                            ctrl.addRoles(newTemplate);
                            ctrl.templatesInProcess = ctrl.templatesInProcess.filter((x) => x !== newTemplate);
                        });
                    });
                } else {
                    ctrl.AttachmentService.uploadAttachment(ctrl.draftId, templateFile, meta).then((attachment) => {
                        newTemplate.attachmentId = attachment.data.id;
                        ctrl.templates.push(newTemplate);
                        ctrl.addRoles(newTemplate);
                        ctrl.templatesInProcess = ctrl.templatesInProcess.filter((x) => x !== newTemplate);
                    });
                }
            }
        }
    }

    private addRoles(template: any): void {
        template.signers.forEach((signer) => {
            const duplicateRole = ctrl.recipients.find((x) => x.role == signer.roleName);
            if (duplicateRole) {
                duplicateRole.templateIds.push(template.templateId);
            } else {
                ctrl.recipients.push({
                    name: signer.name,
                    role: signer.roleName,
                    email: signer.email,
                    templateIds: [template.templateId],
                    hostEmail: signer.hostEmail,
                    hostName: signer.hostName,
                    signerType: signer.signerType,
                    additionalNotifications: signer.additionalNotifications || [
                        {
                            notificationType: "SMS",
                            value: null
                        }
                    ]
                });
            }
        });
    }

    private deleteRoles(template: any): void {
        template.signers.forEach((signer) => {
            const role = ctrl.recipients.find((x) => x.role == signer.roleName);
            if (role.templateIds.length <= 1) {
                ctrl.recipients = ctrl.recipients.filter((x) => x != role);
            } else {
                role.templateIds = role.templateIds.filter((x) => x != template.templateId);
            }
        });
    }

    private createFileFromTemplate(template: any): any {
        const json = JSON.stringify({
            templateSource: this.templateSource,
            templateId: template.templateId
        });
        const blob = new Blob([json], { type: "application/json" });
        return new File([blob], "template.json");
    }
}

class PatientTemplatesComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./patient-templates.component.html");
        this.controller = PatientTemplatesController;
        this.bindings = {
            draftId: "=",
            patient: "<",
            templates: "=",
            message: "=",
            recipients: "=",
            templatesInProcess: "=",
            loadingDraft: "="
        };
    }
}

export const patientTemplatesComponent = new PatientTemplatesComponent();
