angular.module("kno2.directives").directive("npiValid", [
    function () {
        return {
            require: "ngModel",
            link: function ($scope, element, attrs, ngModel) {
                element.on("blur change", function () {
                    var isNpi = function (npi) {
                        var tmp;
                        var sum;
                        var i;
                        var j;
                        i = npi.length;
                        if (i == 15 && npi.indexOf("80840", 0, 5) == 0) sum = 0;
                        else if (i == 10) sum = 24;
                        else return false;
                        j = 0;
                        while (i != 0) {
                            tmp = npi.charCodeAt(i - 1) - "0".charCodeAt(0);
                            if (j++ % 2 != 0) {
                                if ((tmp <<= 1) > 9) {
                                    tmp -= 10;
                                    tmp++;
                                }
                            }
                            sum += tmp;
                            i--;
                        }
                        if (sum % 10 == 0) return true;
                        else return false;
                    };
                    var npi = ngModel.$modelValue || "";
                    if (npi) {
                        ngModel.$setValidity("npi", isNpi(npi));
                    } else {
                        ngModel.$setValidity("npi", true);
                    }
                });
            }
        };
    }
]);
