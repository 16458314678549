import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatAuthError",
    standalone: true
})
export class FormatAuthErrorPipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): unknown {
        return (
            value
                ?.split("_")
                .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                .join(" ") || ""
        );
    }
}
