import templateUrl from "./intake.process-multiple-messages-modal.component.html";

export class ProcessMultipleMessagesModalController {
    constructor(MessageService, $q) {
        this.messageService = MessageService;
        this.$q = $q;
        this.numberToLimit = 5;
    }

    $onInit() {
        this.messages = this.resolve.messages;
    }

    cancel() {
        this.dismiss();
    }

    ok() {
        const ctrl = this;
        const requests = [];
        ctrl.messages.forEach((x) => {
            requests.push(ctrl.messageService.saveProcessedMessage(x.id, true, "", "ForceProcess"));
        });
        ctrl.$q
            .all(requests)
            .then((x) => {
                ctrl.close();
            })
            .catch((errs) => {
                ctrl.close(errs);
            });
    }
}

ProcessMultipleMessagesModalController.$inject = ["MessageService", "$q"];

export const processMultipleMessagesModalComponent = {
    templateUrl,
    controller: ProcessMultipleMessagesModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
