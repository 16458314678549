import deleteMessageModalTemplate from "../common/delete-message/delete-message-modal.html";
import intakeAuditMessageModalTemplate from "./intake.audit-message-modal.html";
import intakeTriageUploadModalTemplate from "./intake.triage-upload-modal.html";

IntakeCtrl.$inject = [
    "$scope",
    "$stateParams",
    "$uibModal",
    "$location",
    "$rootScope",
    "$timeout",
    "$injector",
    "MessageService",
    "SessionService",
    "IntakeService",
    "PagingFactory",
    "SessionStorageFactory",
    "SyncService",
    "$q",
    "EventService",
    "TriageHttpService",
    "SubscriptionService",
    "isFirstRequestForView",
    "DocumentSourceService",
    "$state",
    "NotificationService",
    "_"
];

export function IntakeCtrl(
    $scope,
    $stateParams,
    $uibModal,
    $location,
    $rootScope,
    $timeout,
    $injector,
    MessageService,
    SessionService,
    IntakeService,
    PagingFactory,
    SessionStorageFactory,
    SyncService,
    $q,
    EventService,
    TriageHttpService,
    SubscriptionService,
    isFirstRequestForView,
    DocumentSourceService,
    $state,
    NotificationService,
    _
) {
    $scope.rules = [];
    $scope.statuses = [];
    $scope.checked = [];
    $scope.locked = [];
    $scope.processedTypes = [];
    $scope.sources = [];
    $scope.selectedMessageIds = [];
    $scope.documentSources;
    $scope.isLeftNav = $stateParams.isLeftNav;
    $scope.waitingForMessages = false;

    const ngxRouter = $injector.get("ngxRouter");

    IntakeService.onRuleChanged = function (view, friendlyName, rules, statuses, sourceTypes, isAssignedToSelf, persistsCurrentPage, sources) {
        if (view === "byRules") {
            $scope.viewName = "Intake for " + $scope.fullName;
        } else {
            $scope.viewName = friendlyName;
        }
        updateSortDetails();
        $scope.filteredView = view;
        $scope.rules = rules;
        $scope.statuses = statuses;
        $scope.sourceTypes = sourceTypes;
        $scope.isAssignedToSelf = isAssignedToSelf;
        $scope.sources = sources;
        $scope.currentPage = persistsCurrentPage ? $scope.currentPage : 1;

        getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
    };

    IntakeService.onStatusChanged = function (statuses, sourceTypes) {
        // the selected items are:
        $scope.statuses = statuses;
        getMessagesFromService(1, $scope.pagedItemRange.value);
    };

    IntakeService.onProcessedTypeChanged = function (processedTypes, statuses, sourceTypes) {
        // the selected items are:
        $scope.statuses = statuses;
        $scope.sourceTypes = sourceTypes;
        $scope.processedTypes = processedTypes;
        getMessagesFromService(1, $scope.pagedItemRange.value);
    };

    $scope.calculateNumberSelected = function () {
        $scope.numberSelected = _.filter($scope.messages, (x) => x.isSelected).length;
        $scope.isAllSelected = $scope.messages.every((x) => x.isSelected);
        return $scope.numberSelected;
    };

    $scope.messageSelected = function () {
        $scope.selectedMessageIds = _.filter($scope.messages, (x) => x.isSelected).map((x) => x.id);
        $scope.calculateNumberSelected();
    };

    $scope.selectAllMessages = function () {
        _.forEach($scope.messages, (x) => {
            x.isSelected = $scope.isAllSelected;
        });

        $scope.calculateNumberSelected();
    };

    // intake document row click event handler
    $scope.rowClick = function (document) {
        IntakeService.redirectToMessageView(document);
    };

    // Paging - event handler for clicking on First, Prev, 1, 2... Next, Last
    $scope.gotoPage = function (page) {
        if (page < 1) page = 1;
        else if (page > $scope.maxPage) page = $scope.maxPage;
        $scope.currentPage = page;
        IntakeService.setCurrentPage(page);
        $scope.loadingPage = true;
        getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value, true).then(
            () => ($scope.loadingPage = false),
            () => ($scope.loadingPage = false)
        );
    };

    $scope.deleteClick = function (d, ev) {
        ev.stopPropagation();
        const modal = $uibModal.open({
            templateUrl: deleteMessageModalTemplate,
            controller: "DeleteMessageModalCtrl",
            controllerAs: "$ctrl",
            resolve: {
                message: function () {
                    return d;
                }
            }
        });

        modal.result.then(
            function (o) {
                getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
                $scope.toggleMenu(ev.currentTarget.closest("ul.show"));
            },
            () => {}
        );
    };

    $scope.deleteAllSelected = function () {
        multiSelectAction("k2DeleteMultipleMessagesModalComponent");
    };

    $scope.processAllSelected = function () {
        multiSelectAction("k2ProcessMultipleMessagesModalComponent");
    };

    function multiSelectAction(component) {
        const modal = $uibModal.open({
            component,
            resolve: {
                messages: () => {
                    return _.filter($scope.messages, (x) => x.isSelected);
                }
            }
        });

        modal.result
            .then((errs) => {
                if (errs) {
                    let errorMessage = "";
                    errs.forEach((x) => {
                        errorMessage += x + "\n";
                    });
                    NotificationService.error(errorMessage);
                }
                getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
            })
            .catch(() => {});
    }

    $scope.showActivity = function (d, ev) {
        ev.stopPropagation();
        $uibModal
            .open({
                windowClass: "audit-message-modal",
                templateUrl: intakeAuditMessageModalTemplate,
                controller: "AuditMessageModalCtrl",
                controllerAs: "$ctrl",
                resolve: {
                    message: function () {
                        return d;
                    }
                }
            })
            .result.then(
                () => {},
                () => $scope.toggleMenu(ev.currentTarget.closest("ul.show"))
            );
    };

    $scope.filterDate = function (startDate, endDate) {
        $scope.currentPage = 1;
        IntakeService.setCurrentPage($scope.currentPage);
        $scope.startDate = startDate;
        $scope.endDate = endDate;
        IntakeService.setDateRange(startDate, endDate);
        getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
    };

    $scope.filterIntake = function () {
        $scope.currentPage = 1;
        IntakeService.setAddressFilter($scope.filter);
        getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
    };

    $scope.clearFilters = function () {
        $scope.startDate = null;
        $scope.endDate = null;
        IntakeService.clearFilters();
        getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
    };

    $scope.isFaxMessage = function (message) {
        return message.properties && message.properties.faxPageCount;
    };

    $scope.forwardClick = function (d, ev) {
        ev.stopPropagation();
        if (!SubscriptionService.checkAndShowPrompt("replyAndForwardButton")) $location.path("/release/forward/" + d.id).search({ returnUrl: "/intake" });
    };

    $scope.replyClick = function (d, ev) {
        ev.stopPropagation();
        if (!SubscriptionService.checkAndShowPrompt("replyAndForwardButton")) $location.path("/release/reply/" + d.id).search({ returnUrl: "/intake" });
    };

    $scope.checkClick = function (d, ev) {
        ev.stopPropagation();
        const elem = angular.element(ev.target);
        if (elem.hasClass("fa fa-square-o")) {
            elem.removeClass("fa fa-square-o").addClass("fa fa-check-square-o");
        } else {
            elem.removeClass("fa fa-check-square-o").addClass("fa fa-square-o");
        }
    };

    $scope.lockClick = function (d, ev) {
        ev.stopPropagation();
        const elem = angular.element(ev.target);
        if (elem.hasClass("fa fa-unlock-alt")) {
            elem.removeClass("fa fa-unlock-alt").addClass("fa fa-lock");
        } else {
            elem.removeClass("fa fa-lock").addClass("fa fa-unlock-alt");
        }
    };

    $scope.toggleMenu = function (el) {
        el.classList.toggle("show");
    };

    $scope.triageClick = function (message) {
        if (!SubscriptionService.checkAndShowPrompt("triageRoute")) TriageHttpService.triageAndShow(message);
    };

    $scope.createDownloadProfile = function () {
        let page = $scope.currentPage;
        if ($scope.isFirstRequestForView) {
            $scope.isFirstRequestForView = false;
            page = getLastPageForFilteredView($scope.filteredView);
        }

        const normalizedRules = $scope.rules.map((rule) => {
            return {
                ...rule,
                fromAddress: normalizeAddress(rule.fromAddress)
            };
        });

        const params = {
            sortDir: $scope.isSortDesc ? "desc" : "asc",
            pageNumber: page,
            pageSize: $scope.pagedItemRange.value,
            orderBy: $scope.sortInfo.id,
            rules: normalizedRules,
            statuses: $scope.statuses,
            isAll: $scope.filteredView === "all",
            isUnassigned: $scope.filteredView === "unassigned",
            sourceTypes: $scope.sourceTypes,
            isAssignedToSelf: $scope.isAssignedToSelf,
            processedTypes: $scope.processedTypes,
            sources: $scope.sources,
            filter: $scope.filter,
            dontStringify: false
        };
        const request = MessageService.getRequest(params);

        ngxRouter.navigate(["/settings/downloadprofiles"], { state: { data: { request } } });
    };

    $scope.addRule = function () {
        $scope.filteredView = "byRules";
        const last = _.last($scope.rules);
        if (!last || last.toAddress || last.fromAddress) $scope.rules.push({ toAddress: null, fromAddress: null });
    };

    $scope.removeRule = function (index) {
        $scope.rules.splice(index, 1);
        $scope.filteredView = $scope.rules.length === 0 ? "all" : "byRules";
        $scope.preview();
    };

    var getMessagesFromService = _.debounce(function (page, pageSize, triggeredByPageChange, useOptimizedIntake) {
        // Request the page index for the filteredView for the first time
        if ($scope.isFirstRequestForView || $scope.isLeftNav) {
            $scope.isLeftNav = $scope.isFirstRequestForView = false;

            $scope.startDate = moment().utc().subtract(30, "days").startOf("day").toDate();
            $scope.filter = "";
            page = 1;
        }

        // Set the page index for the current view in local storage
        setLastPageForFilteredView($scope.filteredView, page);

        $scope.currentPage = page;
        // TODO: Do we need "processed" here?

        const params = {
            sortDir: $scope.isSortDesc ? "desc" : "asc",
            pageNumber: page,
            pageSize: pageSize,
            orderBy: $scope.sortInfo.id,
            startDate: $scope.startDate || null,
            endDate: $scope.endDate || null,
            rules: $scope.rules,
            statuses: $scope.statuses,
            isAll: $scope.filteredView === "all",
            isUnassigned: $scope.filteredView === "unassigned",
            sourceTypes: $scope.sourceTypes,
            isAssignedToSelf: $scope.isAssignedToSelf,
            processedTypes: $scope.processedTypes,
            sources: $scope.sources,
            filter: $scope.filter
        };

        return MessageService.getMessages(params, useOptimizedIntake)
            .then(function (response) {
                if (!!$scope.messages && !triggeredByPageChange) flagNewMessages($scope.messages, response.data.items);

                $scope.messages = response.data.items;

                $scope.selectedMessageIds.forEach((id) => {
                    var m = _.find($scope.messages, (x) => x.id === id);
                    if (m) {
                        m.isSelected = true;
                    }
                });

                $scope.calculateNumberSelected();

                $scope.totalDocumentCount = response.data.totalCount;
                $scope.maxPage = Math.ceil(response.data.totalCount / $scope.pagedItemRange.value);

                $scope.pages = _.range(1, $scope.maxPage + 1);

                // Update the range to be displayed in the title row
                $scope.rangeStart = $scope.currentPage * $scope.pagedItemRange.value - ($scope.pagedItemRange.value - 1);
                $scope.rangeEnd = $scope.currentPage * $scope.pagedItemRange.value;
                if ($scope.rangeEnd > $scope.totalDocumentCount) $scope.rangeEnd = $scope.totalDocumentCount;
                if ($scope.rangeStart > $scope.rangeEnd) $scope.rangeStart = $scope.rangeEnd;

                $scope.waitingForMessages = false;
                return "getMessagesFromService API call success";
            })
            .catch(function (err) {
                $scope.waitingForMessages = false;
                var errorMessage = err.statusText;
                if (err.data && err.data.message) {
                    errorMessage += ". " + err.data.message;
                }
                NotificationService.error(errorMessage);
                return "getMessagesFromService API call failed";
            });
    }, 1000);

    $scope.showTriage = function (message) {
        return _.some(message.attachments, { isRestorable: false }) || _.some(message.attachments, { isPreviewAvailable: true });
    };

    $scope.preview = function () {
        _.remove($scope.rules, function (rule) {
            rule.fromAddress = normalizeAddress(rule.fromAddress);

            return !rule.toAddress && !rule.fromAddress;
        });
        getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value, false, false);
    };

    function normalizeAddress(address) {
        if (address && !_.includes(address, "@")) {
            address = address.replace(/\D/g, "").trim();
        }

        return address;
    }

    function flagNewMessages(oldMessages, newMessages) {
        const older = _(oldMessages).map(function (m) {
            return m.id;
        });
        const newer = _(newMessages).map(function (m) {
            return m.id;
        });
        const diff = _.difference(newer, older);

        if (diff.length) {
            const recent = _(newMessages).filter(function (m) {
                return _(diff).includes(m.id);
            });

            _.each(recent, function (m) {
                m.recent = true;
            });

            $timeout(function () {
                _.each(recent, function (m) {
                    m.recent = false;
                });
            }, 1500);

            $timeout(function () {
                _.each(recent, function (m) {
                    delete m.recent;
                });
            }, 3000);
        }
    }

    const getLastPageForFilteredView = function (view) {
        let key = "intakePageForView-byRules";
        switch (view) {
            case "unassigned":
                key = "intakePageForView-unassigned";
                break;
            case "all":
                key = "intakePageForView-all";
                break;
            default:
                key = "intakePageForView-byRules";
                break;
        }
        key = $scope.userName + "." + key;
        const page = SessionStorageFactory.get(key);
        return Number(page) || 1;
    };

    const setLastPageForFilteredView = function (view, page) {
        if (view === "byRules") {
            return SessionStorageFactory.set($scope.userName + ".intakePageForView-byRules", page);
        } else if (view === "unassigned") {
            return SessionStorageFactory.set($scope.userName + ".intakePageForView-unassigned", page);
        } else if (view === "all") {
            return SessionStorageFactory.set($scope.userName + ".intakePageForView-all", page);
        }
    };

    const getLastSortDirection = function () {
        const data = SessionStorageFactory.get($scope.userName + ".intakeSortDir");
        if (data && data === "false") return false;
        return true;
    };

    const setLastSortDirection = function (dir) {
        $scope.isSortDesc = dir;
        return SessionStorageFactory.set($scope.userName + ".intakeSortDir", dir);
    };

    const getLastSortInfo = function () {
        if (SessionStorageFactory.get($scope.userName + ".intakeSortInfo")) {
            return SessionStorageFactory.get($scope.userName + ".intakeSortInfo");
        } else {
            setLastSortInfo("receiveDate");
            return getLastSortInfo();
        }
    };

    const setLastSortInfo = function (sortByField) {
        const sortInfo = _.find($scope.sortItems.data, function (x) {
            return x.id === sortByField;
        });
        SessionStorageFactory.set($scope.userName + ".intakeSortInfo", sortInfo);
    };

    $scope.sort = function (sortByField) {
        if ($scope.sortInfo.id !== sortByField) {
            setLastSortInfo(sortByField);
            updateSortDetails();
            getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
        }
    };

    $scope.orderBy = function (isDesc) {
        if ($scope.isSortDesc !== isDesc) {
            setLastSortDirection(isDesc);
            getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
        }
    };

    const updateSortDetails = function () {
        $scope.sortInfo = getLastSortInfo();
        $scope.isSortDesc = getLastSortDirection();
        $scope.sortOrder = $scope.sortInfo.order;
    };

    SyncService.registerForBroadcast(function () {
        if (!$scope.waitingForMessages) {
            $scope.waitingForMessages = true;
            getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
        }
    }, $scope);

    $scope.showUploadBatchDialog = function () {
        if (!SubscriptionService.checkAndShowPrompt("triageRoute")) {
            $uibModal
                .open({
                    templateUrl: intakeTriageUploadModalTemplate,
                    controller: "TriageUploadModalCtrl as vm",
                    windowClass: "modal-500",
                    resolve: {
                        rules: [
                            "RulesService",
                            function (RulesService) {
                                return RulesService.getAllTriageRules();
                            }
                        ],
                        whitelist: [
                            "TriageHttpService",
                            function (TriageHttpService) {
                                return TriageHttpService.getExtensions();
                            }
                        ]
                    }
                })
                .result.then(function () {
                    getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value);
                })
                .catch(angular.noop);
        }
    };

    $scope.toggleAdvanced = function () {
        $scope.showAdvanced = !$scope.showAdvanced;
        DocumentSourceService.getMyDownloadprofileDocumentSources().then(function (data) {
            $scope.documentSources = data;
        });
    };

    function init() {
        const profile = SessionService.getProfile();
        $scope.isFirstRequestForView = isFirstRequestForView;

        if (isFirstRequestForView) IntakeService.clearFilters();

        $scope.userName = profile.userName;
        $scope.fullName = profile.fullName;
        $scope.currentPage = IntakeService.getCurrentPage() || 1;
        $scope.startDate = IntakeService.getDateRange().startDate;
        $scope.endDate = IntakeService.getDateRange().endDate;
        $scope.sortItems = MessageService.getDocumentSortItems();
        $scope.filteredView = "";
        $scope.filter = IntakeService.getAddressFilter();
        $scope.filterActive = !isFirstRequestForView && $scope.filter;
        $scope.isAdministrator = SessionService.userInRole("Administrator");
        $scope.showAdvanced = false;
        $scope.isFilteredDate = !isFirstRequestForView && $scope.startDate && $scope.endDate;

        $scope.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        $scope.pagedItemRange = $scope.pagedItemRanges[0];
        updateSortDetails();
    }

    init();
}
