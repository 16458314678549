import templateUrl from "./admin-organizations-carequality-directory.component.html";

export class AdminOrganizationsCarequalityDirectoryController {
    constructor($uibModal, NotificationService, AdminOrganizationsService) {
        this.$uibModal = $uibModal;
        this.NotificationService = NotificationService;
        this.AdminOrganizationsService = AdminOrganizationsService;
    }

    $onInit() {
        this.orgId = this.organization.id;
        this.getDirectoryEntries(this.orgId);
    }

    create() {
        const ctrl = this;
        const modalInstance = this.$uibModal.open({
            windowClass: "modal-1000",
            component: "k2AdminOrganizationsCarequalityDirectoryEditModalComponent",
            resolve: {
                organization: () => ctrl.organization,
                orgId: () => ctrl.orgId,
                parentOrg: () => ctrl.directoryEntries.find((x) => x.isParent)
            }
        });

        modalInstance.result.then(
            () => {
                ctrl.NotificationService.success(`The Directory Entry was added successfully.`);
                ctrl.getDirectoryEntries(ctrl.orgId);
            },
            () => {}
        );
    }

    edit(entry) {
        const ctrl = this;
        const modalInstance = ctrl.$uibModal.open({
            windowClass: "modal-1000",
            component: "k2AdminOrganizationsCarequalityDirectoryEditModalComponent",
            resolve: {
                orgId: () => ctrl.orgId,
                entry: () => entry
            }
        });

        modalInstance.result.then(
            () => {
                ctrl.NotificationService.success(`The Directory Entry was edited successfully.`);
                ctrl.getDirectoryEntries(ctrl.orgId);
            },
            () => {}
        );
    }

    toggleActive(entry) {
        const ctrl = this;
        const modalInstance = ctrl.$uibModal.open({
            component: "k2AdminOrganizationsCarequalityDirectoryActiveToggleModalComponent",
            resolve: {
                entry: () => entry,
                orgId: () => ctrl.orgId
            }
        });

        modalInstance.result.then(
            () => {
                ctrl.getDirectoryEntries(ctrl.orgId);
            },
            () => {}
        );
    }

    openSync() {
        const ctrl = this;
        const modalInstance = ctrl.$uibModal.open({
            component: "k2AdminOrganizationsCarequalityDirectorySyncModalComponent",
            resolve: {
                orgId: () => ctrl.orgId
            }
        });

        modalInstance.result.then(
            () => {
                ctrl.getDirectoryEntries(ctrl.orgId);
            },
            () => {}
        );
    }

    getDirectoryEntries(orgId) {
        if (orgId) {
            this.AdminOrganizationsService.getOrganizationDirectoryEntries(orgId)
                .then((data) => {
                    this.directoryEntries = data;
                })
                .catch((err) => {
                    this.directoryEntries = [];
                    this.NotificationService.error(err.data.message);
                });
        }
    }
}

AdminOrganizationsCarequalityDirectoryController.$inject = ["$uibModal", "NotificationService", "AdminOrganizationsService"];

export const adminOrganizationsCarequalityDirectoryComponent = {
    templateUrl,
    controller: AdminOrganizationsCarequalityDirectoryController,
    bindings: {
        organization: "<"
    }
};
