angular.module("kno2.settings").controller("DirectAddressesEditModalCtrl", [
    "$timeout",
    "$filter",
    "$q",
    "$uibModalInstance",
    "$uibModal",
    "$confirm",
    "data",
    "DocumentSourcesService",
    "NotificationService",
    "UsersService",
    "_",
    "SessionService",
    "appConfig",
    function (
        $timeout,
        $filter,
        $q,
        $uibModalInstance,
        $uibModal,
        $confirm,
        data,
        DocumentSourcesService,
        NotificationService,
        UsersService,
        _,
        SessionService,
        appConfig
    ) {
        const ctrl = this;

        ctrl.brandDisplayName = appConfig.brandDisplayName;
        ctrl.userProfile = data.userProfile;
        ctrl.setForm = function (form) {
            ctrl.form = form;
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
        ctrl.nameRegex = "^[A-Za-z]+['-]*[A-Za-z]+$";

        ctrl.$onInit = init;

        ctrl.directAddressTypeChanged = function () {
            // Clear provider specific fields if not provider type
            if (ctrl.documentSource && ctrl.documentSource.directAddressType !== "provider") {
                ctrl.documentSource.npi = null;
                ctrl.documentSource.providerFirstName = null;
                ctrl.documentSource.providerLastName = null;
            } else {
                $timeout(
                    function () {
                        $("input[name*='NPI']").focus().select();
                    },
                    500,
                    false
                );
            }
        };

        ctrl.saveChanges = function () {
            if (ctrl.documentSource.isAssociatedWithAllActiveOrganizationUsers && ctrl.documentSource.users.length > 0) {
                $confirm
                    .open({
                        title: "Confirmation",
                        bodyText:
                            "Are you sure you want to switch to global permissions? This action will remove the existing list of users. Please confirm to proceed.",
                        okText: "Continue"
                    })
                    .result.then(() => ctrl.confirmSaveChanges());
            } else {
                ctrl.confirmSaveChanges();
            }
        };

        ctrl.confirmSaveChanges = function () {
            ctrl.formSubmitted = true;

            var users = [];
            if (!ctrl.documentSource.isAssociatedWithAllActiveOrganizationUsers) {
                _.each(ctrl.documentSource.users, function (user) {
                    users.push(user);
                });
            }
            ctrl.documentSource.users = users;

            // Save changes if form valid
            if (ctrl.form.$valid) {
                var functionName = ctrl.documentSource.id ? "putDocumentSource" : "postDocumentSource";
                DocumentSourcesService[functionName](ctrl.documentSource).then(
                    function () {
                        NotificationService.success("The direct address was saved successfully.");
                        $uibModalInstance.close(ctrl.documentSource);
                    },
                    function (response) {
                        NotificationService.serverError(response.data.modelState, "There was an error saving the direct address.");
                    }
                );
            } else {
                _(ctrl.form.$error.required).forEach(function (value) {
                    // Triggers the red exlamation point (Focus input, swich to another input, switch back).
                    $timeout(
                        function () {
                            $("input[name*='" + value.$name + "']")
                                .focus()
                                .select();
                        },
                        250,
                        false
                    );
                    $timeout(
                        function () {
                            $("input[name*='address']").focus().select();
                        },
                        250,
                        false
                    );
                    $timeout(
                        function () {
                            $("input[name*='" + value.$name + "']")
                                .focus()
                                .select();
                        },
                        250,
                        false
                    );
                });
            }
        };

        // This provides the necessary call information for the k2-unique directive.
        ctrl.documentSourceExists = function () {
            return {
                serviceFunction: DocumentSourcesService.isAddressExists,
                errorMessage: "This direct address already exists, please enter a different one."
            };
        };

        function init() {
            ctrl.restrictSendPermissions = SessionService.hasUIResourceAction("directAddressesSendPermissionsInput", "disable");

            DocumentSourcesService.getAddressTypes().then(function (response) {
                ctrl.directAddressTypes = response;
            });
            UsersService.getOrgUsers(false).then(function (response) {
                ctrl.organizationUsers = response;

                // Get the Document Sources
                if (data.id) {
                    DocumentSourcesService.getDocumentSource(data.id).then(function (response) {
                        ctrl.documentSource = response;
                        ctrl.documentSource.directAddressType = ctrl.documentSource.npi ? "provider" : "organization";

                        // Set selected Users
                        var users = ctrl.documentSource.users;
                        ctrl.documentSource.users = [];
                        if (users && !ctrl.documentSource.isAssociatedWithAllActiveOrganizationUsers) {
                            _.each(users, function (x) {
                                var u = _.find(ctrl.organizationUsers, { id: x.id });
                                if (u) {
                                    ctrl.documentSource.users.push(u);
                                }
                            });
                        }

                        if (ctrl.restrictSendPermissions) {
                            ctrl.organizationUsers = [];
                        }
                    });
                } else {
                    ctrl.documentSource = {
                        directAddressType: "organization",
                        type: "DirectMessage",
                        isAssociatedWithAllActiveOrganizationUsers: false,
                        users: []
                    };
                }
                $timeout(
                    function () {
                        $("input[name*='address']").focus().select();
                    },
                    500,
                    false
                );
            });
        }
    }
]);
