OrganizationSpecialtiesService.$inject = ["$http", "CacheService"];

export function OrganizationSpecialtiesService($http, CacheService) {
    return {
        get: get,
        getAll: getAll,
        create: create,
        update: update,
        getSpecialty: getSpecialty,
        delete: deleteSpecialty
    };

    function getAll() {
        return $http({
            method: "GET",
            url: "/api/admin/organizationspecialties?pageSize=5000",
            cache: CacheService.cacheL1()
        }).then(function (response) {
            return response.data.items;
        });
    }

    function get(pageStart, pageSize, search) {
        const args = {
            pageStart: search ? 1 : pageStart,
            pageSize: pageSize,
            search: search
        };
        return $http({
            method: "GET",
            params: args,
            url: "/api/admin/organizationspecialties"
        }).then(function (response) {
            return response.data;
        });
    }

    function create(organizationSpecialty) {
        return $http.post("/api/admin/organizationspecialties", organizationSpecialty).then(function (response) {
            return response.data;
        });
    }

    function update(organizationSpecialty) {
        return $http.put("/api/admin/organizationspecialties", organizationSpecialty).then(function (response) {
            return response.data;
        });
    }

    function getSpecialty(id) {
        return $http.get(`/api/admin/organizationspecialties/${id}`).then(function (response) {
            return response.data;
        });
    }

    function deleteSpecialty(id) {
        return $http.delete(`/api/admin/organizationspecialties/${id}`).then(function (response) {
            return response.data;
        });
    }
}
