import { AdminExternalServiceAccountsService } from "../../../../common/services/admin.external-service-accounts.service";

let ctrl;

class AddBrandServiceAccountModalController {
    public static $inject: Array<string> = ["NotificationService", "AdminExternalServiceAccountsService"];

    public serviceAccountName: string;
    public serviceAccountDescription: string;
    public brandId: string;

    public resolve: any;
    public close: any;
    public dismiss: any;

    constructor(
        private NotificationService,
        private AdminExternalServiceAccountsService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.brandId = ctrl.resolve.brandId;
    }

    public assignApi(clientId): any {
        const request = {
            client_id: clientId,
            scope: []
        };

        try {
            this.AdminExternalServiceAccountsService.createGrantTypes(request).then((data) => {
                this.NotificationService.success(`The Client Grant for ${data.data.client_id} was Successfully created in auth0`);
            });
        } catch (error) {
            this.NotificationService.failure(error);
        }
    }

    public submitForm(): any {
        const isValid = this.validateForm();

        if (isValid) {
            const request = {
                name: this.serviceAccountName,
                description: this.serviceAccountDescription,
                entityId: this.brandId,
                entityType: "Brand"
            };

            try {
                this.AdminExternalServiceAccountsService.createClient(request).then(async (data) => {
                    const apiAssigned = await this.assignApi(data.data.client_id);
                    this.NotificationService.success("The Client was Successfully created in Auth0.");
                    this.close();
                });
            } catch (error) {
                this.NotificationService.failure(error);
            }
        }
    }

    public validateForm(): boolean {
        let isValid = true;

        if (!this.serviceAccountName || !this.serviceAccountDescription) isValid = false;

        return isValid;
    }
}

class AddBrandServiceAccountModalComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./add-brand-service-account-modal.component.html");
        this.controller = AddBrandServiceAccountModalController;
        this.bindings = {
            brandId: "<",
            createClient: "&",
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const addBrandServiceAccountModalComponent = new AddBrandServiceAccountModalComponent();
