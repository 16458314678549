let ctrl;
declare const _;
declare const moment;

class PatientSearchModalController {
    public static $inject: Array<string> = ["$timeout", "IntegrationsService", "NotificationModalService"];

    public resolve: any;
    public close: any;
    public dismiss: any;

    public patient: any;
    public patientLookupTemplate: any;
    public visitEnabled: boolean;
    public searchableFields: Set<string>;
    public hasFacilityLookup: boolean;
    public integrationDisplayName: string;
    public facilities: Array<any>;

    public hideBack: boolean;
    public capabilities: any;
    public showInactivePatientsControl: boolean;
    public facilityRequired: boolean;

    public tempCachedPatients: any;

    constructor(
        private $timeout,
        private IntegrationsService,
        private NotificationModalService
    ) {}

    public $onInit(): void {
        ctrl = this;
        ctrl.tempCachedPatients = {};

        ctrl.patient = ctrl.resolve.patient;
        ctrl.patientLookupTemplate = ctrl.resolve.patientLookupTemplate;
        ctrl.visitEnabled = ctrl.resolve.visitEnabled;
        ctrl.searchableFields = ctrl.resolve.searchableFields;
        ctrl.hasFacilityLookup = ctrl.resolve.hasFacilityLookup;
        ctrl.integrationDisplayName = ctrl.resolve.integrationDisplayName;
        ctrl.facilities = ctrl.resolve.facilities;

        ctrl.hideBack = !!ctrl.patient.patientResourceId;
        ctrl.capabilities = null;
        ctrl.showInactivePatientsControl = false;
        ctrl.facilityRequired = ctrl.hasFacilityLookup && ctrl.facilities.length > 10;
    }

    public search() {
        ctrl.$timeout(() => {
            if (!ctrl.visitsVisible && ctrl.patientLookupForm.$valid) {
                ctrl.patientsLoading = true;
                ctrl.IntegrationsService.getPatients(ctrl.patient).then(
                    (patients) => {
                        ctrl.patients = patients;

                        if (patients.length === 1) ctrl.selectPatient(patients[0]);

                        ctrl.patientsLoading = false;
                    },
                    (err) => {
                        ctrl.patientsLoading = false;
                        if (err.data && err.data.message) {
                            ctrl.NotificationModalService.error(`Patient Search - ${err.data.message}`);
                        }
                    }
                );
            }
        });
    }

    public selectPatient(patient) {
        ctrl.tempCachedPatients[patient.patientResourceId] = patient;
        if (ctrl.selectedPatient === patient) patient = null;
        ctrl.selectedPatient = patient;
    }

    public showVisits(patient) {
        ctrl.tempCachedPatients[patient.patientResourceId] = patient;
        ctrl.selectedPatient = patient;
        ctrl.visitsLoading = true;
        ctrl.visitsVisible = true;
        ctrl.IntegrationsService.getVisits(patient.patientResourceId).then((visits) => {
            ctrl.visits = visits;
            ctrl.visitsLoading = false;
        });
    }

    public hideVisits() {
        ctrl.visits = null;
        ctrl.selectedVisit = null;
        ctrl.visitsVisible = false;
    }

    public selectVisit(visit) {
        if (ctrl.selectedVisit === visit) visit = null;
        ctrl.selectedVisit = visit;
    }

    public confirm(): void {
        ctrl.close({
            $value: {
                patient: ctrl.selectedPatient,
                visit: ctrl.selectedVisit
            }
        });
    }

    public dismissModal(): void {
        ctrl.dismiss();
    }

    public clearSearch() {
        ctrl.patient = ctrl.mapPatient({});
        ctrl.patients = null;
    }

    public mapPatient(p): any {
        if (p) {
            const { patientId, firstName, lastName, birthDate, includeInactive, medicalRecordNumber } = p;
            const birthdate = birthDate ? moment(birthDate)?.format("YYYY-MM-DD") : null;
            let name = undefined;
            if (ctrl.patientLookupTemplate === "Hcs") {
                if (p.firstName || p.lastName) {
                    name = `${p.lastName || ""}, ${p.firstName || ""}`;
                }
            }

            const shouldUsePatientIdAsMrn = this.usePatientIdAsMrn();
            return {
                patientId: shouldUsePatientIdAsMrn ? null : patientId,
                firstName,
                lastName,
                birthdate,
                name,
                includeInactive,
                medicalRecordNumber: shouldUsePatientIdAsMrn ? patientId : medicalRecordNumber
            };
        }
        return {};
    }

    private usePatientIdAsMrn(): boolean {
        return ["PointClickCare", "Axxess"].includes(ctrl.integrationDisplayName);
    }
}

class PatientSearchModalComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./patient-search.modal.component.html");
        this.controller = PatientSearchModalController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const patientSearchModalComponent = new PatientSearchModalComponent();
