import templateUrl from "./messaging-health-dashboard.component.html";

export const messagingHealthDashboardComponent = {
    templateUrl: templateUrl,
    controller: MessagingHealthDashboardController
};

MessagingHealthDashboardController.$inject = ["MessagingHealthService", "$interval"];

export function MessagingHealthDashboardController(MessagingHealthService, $interval) {
    var ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.$onDestroy = clearInterval;

    ctrl.colors = ["blue", "orange", "red", "pink"];

    function onInit() {
        ctrl.model = {};

        getMessageData();
        getSystemAlerts();
    }

    function getMessageData() {
        getMessageCounts().then(() => {
            ctrl.model.inboundMessages.forEach(function (c) {
                c.chartData = getChannelSeries(c.dailyStats);

                switch (c.state) {
                    case "Error":
                        return (c.color = "red");
                    case "Warn":
                        return (c.color = "orange");
                    default:
                        return (c.color = "blue");
                }
            });
            ctrl.model.outboundMessages.forEach(function (c) {
                c.chartData = getChannelSeries(c.dailyStats);
                switch (c.state) {
                    case "Error":
                        return (c.color = "red");
                    case "Warn":
                        return (c.color = "orange");
                    default:
                        return (c.color = "blue");
                }
            });

            initCharts();
        });
    }

    let handle = $interval(function () {
        getMessageData();
        getSystemAlerts();
    }, 10000);

    function clearInterval() {
        if (handle) $interval.cancel(handle);
    }

    function retina() {
        retinaMode = window.devicePixelRatio > 1;
        return retinaMode;
    }

    let retinaMode = 1;

    function initCharts() {
        let now = new Date(),
            time = now.getTime(),
            options = {
                series: {
                    curvedLines: { active: true },
                    lines: {
                        show: true,
                        lineWidth: 1,
                        fill: false,
                        fillColor: { colors: [{ opacity: 0.1 }, { opacity: 0.1 }] }
                    },
                    points: {
                        show: true,
                        lineWidth: 2
                    },
                    shadowSize: 0
                },
                grid: {
                    hoverable: true,
                    clickable: true,
                    labelMargin: 15,
                    borderColor: "transparent",
                    border: 2
                },
                legend: {
                    show: true,
                    backgroundOpacity: 0.5,
                    noColumns: 0
                },
                colors: ["#bdea74", "#2FABE9", "#02547D", "#02BEC4", "#0284A8"],
                xaxis: {
                    mode: "time",
                    tickSize: [10, "minute"],
                    tickFormatter: function (val, axis) {
                        return format.minute(val);
                    },
                    aggregate: "sum",
                    max: now,
                    min: new Date(time - 2 * 60 * 60 * 1000).getTime()
                },
                yaxis: { ticks: 5, tickDecimals: 0, tickColor: "#e9ebec", min: 0 }
            };

        var plot = $.plot(
            $("#chart-24h"),
            _.map(ctrl.model.inboundMessages, function (c) {
                return {
                    data: c.chartData,
                    label: c.name
                };
            }),
            options
        );

        var outPlot = $.plot(
            $("#outchart-24h"),
            _.map(ctrl.model.outboundMessages, function (c) {
                return {
                    data: c.chartData,
                    label: c.name
                };
            }),
            options
        );

        function showTooltip(x, y, contents) {
            $('<div id="chtooltip">' + contents + "</div>")
                .css({
                    position: "absolute",
                    display: "none",
                    top: y + 5,
                    left: x + 5,
                    border: "1px solid #fdd",
                    padding: "2px",
                    "background-color": "#dfeffc",
                    opacity: 0.8,
                    "z-index": 899
                })
                .appendTo("body")
                .fadeIn(200);
        }

        var previousPoint = null;
        $("#chart-24h").bind("plothover", function (event, pos, item) {
            $("#x").text(pos.x.toFixed(2));
            $("#y").text(pos.y.toFixed(2));

            if (item) {
                if (previousPoint != item.dataIndex) {
                    previousPoint = item.dataIndex;

                    $("#chtooltip").remove();
                    var x = item.datapoint[0].toFixed(2),
                        y = item.datapoint[1].toFixed(2);

                    showTooltip(item.pageX, item.pageY, item.series.label + " " + y);
                }
            } else {
                $("#chtooltip").remove();
                previousPoint = null;
            }
        });
        var opreviousPoint = null;
        $("#outchart-24h").bind("plothover", function (event, pos, item) {
            $("#x").text(pos.x.toFixed(2));
            $("#y").text(pos.y.toFixed(2));

            if (item) {
                if (opreviousPoint != item.dataIndex) {
                    opreviousPoint = item.dataIndex;

                    $("#chtooltip").remove();
                    var x = item.datapoint[0].toFixed(2),
                        y = item.datapoint[1].toFixed(2);

                    showTooltip(item.pageX, item.pageY, item.series.label + " " + y);
                }
            } else {
                $("#chtooltip").remove();
                opreviousPoint = null;
            }
        });
    }
    var format = {
        minute: function (arg) {
            //return kendo.toString(kendo.parseDate(arg), 'MM/dd/yyyy hh:mm:ss tt');
            var date = window.moment.utc(arg).toDate();
            return window.moment(date).local().format("h:mm a");
        }
    };

    function getChannelSeries(data) {
        var messages = [];
        for (var i = 0; i < data.length; i++) {
            var msg = data[i];
            messages.push(convertToPoint(msg.date, msg.value));
        }
        return messages;
    }

    function roundToMinute(date) {
        var coeff = 1000 * 60 * 1;
        var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
        return date;
    }

    function convertToPoint(date, value) {
        var res = roundToMinute(moment.utc(date).toDate());
        return [res.getTime(), value];
    }

    function getMessageCounts() {
        return MessagingHealthService.getIncomingMessageCount().then(function (data) {
            ctrl.model.inboundMessages = data.inboundMessages;
            ctrl.model.rawResults = data.results;
            ctrl.model.outboundMessages = data.outboundMessages;
        });
    }

    function getSystemAlerts() {
        return MessagingHealthService.getSystemAlerts().then(function (data) {
            data.forEach(function (c) {
                c.date = new Date(c.createdAt).toLocaleString();
                switch (c.status) {
                    case "Triggered":
                        return (c.alertType = "danger");
                    case "Warn":
                        return (c.alertType = "warning");
                    default:
                        return (c.alertType = "success");
                }
            });

            ctrl.model.alerts = data;
        });
    }
}
