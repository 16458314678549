AuditMessageModalCtrl.$inject = ["$uibModalInstance", "$timeout", "message", "IAuditService"];

export function AuditMessageModalCtrl($uibModalInstance, $timeout, message, AuditService) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    const end = moment().utc().toJSON();
    const start = moment().utc().subtract(14, "days").toJSON();

    ctrl.filterKey = "all";
    ctrl.filters = {
        all: { display: "All", events: null },
        viewed: { display: "Viewed", events: ["IntakeMessageView"] },
        processed: {
            display: "Processed",
            events: [
                "IntakeMessageProcessed",
                "IntakeMessageExported",
                "IntakeMessagePrinted",
                "IntakeMessageForwardedToEMR",
                "IntakeMessageAwaitingEMRExport",
                "IntakeMessageEMRExported"
            ]
        },
        replied: { display: "Replied", events: ["ReleaseMessageReplied"] },
        forwarded: { display: "Forwarded", events: ["ReleaseMessageForwarded"] },
        deleted: { display: "Deleted", events: ["IntakeMessageDeleted"] }
    };

    ctrl.pageSize = 10;
    ctrl.currentPage = 1;

    ctrl.auditEntries = [];
    ctrl.startDate = start;
    ctrl.endDate = end;

    ctrl.dateOptions = {
        maxDate: new Date()
    };

    ctrl.auditFilter = {
        startDate: start,
        endDate: end,
        eventTypes: null
    };

    function loadAuditEntriesInternal() {
        const startDate = moment(ctrl.startDate);
        const endDate = moment(ctrl.endDate);
        AuditService.getAuditEntriesByTypes(
            ctrl.message.id,
            ctrl.auditFilter.eventTypes,
            new Date(startDate.year(), startDate.month(), startDate.date(), 0, 0, 0).toJSON(),
            new Date(endDate.year(), endDate.month(), endDate.date(), 23, 59, 59).toJSON()
        ).then(function (res) {
            ctrl.auditEntries = res;
            ctrl.entriesLoaded = true;
            spliceResults();
        });
    }

    function spliceResults() {
        const pageStart = (ctrl.currentPage - 1) * ctrl.pageSize;
        let pageEnd = ctrl.currentPage * ctrl.pageSize;

        // check to see if pageEnd is greater than total count
        if (ctrl.auditEntries.length < pageEnd) {
            pageEnd = ctrl.auditEntries.length;
        }

        ctrl.dataPage = ctrl.auditEntries.slice(pageStart, pageEnd);
    }

    ctrl.filterChanged = function (key) {
        ctrl.filterKey = key;
        const filter = ctrl.filters[key];
        if (filter) ctrl.auditFilter.eventTypes = filter.events;
        ctrl.startDate = moment(ctrl.auditFilter.startDate).toJSON();
        ctrl.endDate = moment(ctrl.auditFilter.endDate).toJSON();
        loadAuditEntriesInternal();
        spliceResults();
    };

    ctrl.pageChanged = function () {
        spliceResults();
    };

    ctrl.setSelectedType = function (selectedType) {
        ctrl.auditFilter.eventType = selectedType;
    };

    ctrl.closeModal = function () {
        $uibModalInstance.dismiss("cancel");
    };

    function onInit() {
        ctrl.message = message;
        loadAuditEntriesInternal();
    }
}
