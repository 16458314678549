import { HttpContextToken, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { from, lastValueFrom } from "rxjs";
import { AuthService } from "../auth";

export const SKIP_TOKEN_INJECTION = new HttpContextToken<boolean>(() => false);

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);

    if (req.context?.get(SKIP_TOKEN_INJECTION)) {
        return next(req);
    }

    return from(
        (async () => {
            const isExpiredToken = authService.isExpired();
            if (isExpiredToken) await authService.renewTokens();

            return lastValueFrom(next(req));
        })()
    );
};
