export function integrationCapabilityFilter() {
    return (value) => {
        if (!value || !angular.isString(value)) return value;
        return (
            value.charAt(0).toUpperCase() +
            value
                .replace(/([A-Z]+)/g, " $1")
                .replace(/([A-Z][a-z])/g, " $1")
                .slice(1)
        );
    };
}
