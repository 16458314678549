import { noop, IComponentOptions, IFormController, IPromise, IQService, IRootScopeService } from "angular";
import { IAppConfig } from "App/src/app-config";
import {
    NetworkIdentityProviderService,
    NetworkIdentityProvider,
    NetworkIdentityProviderConfiguration,
    NetworkIdentityProviderConfigurationValue,
    NetworkIdentityProviderExpectedConfigurationValue,
    NetworkIdentityProviderType
} from "App/src/common/services/network-identity-provider.service";

class NetworkIdentityProviderController {
    public static $inject: Array<string> = ["$confirm", "NotificationService", "NetworkIdentityProviderService", "appConfig", "$q", "$rootScope", "appConfig"];

    public identityProviderTypes: NetworkIdentityProviderType[] = [];
    public identityProviderTypeLookup: Record<string, NetworkIdentityProviderType>;
    public isLoading: boolean = false;
    public identityProvider: NetworkIdentityProvider;
    public showIdentityProviderConfiguration: boolean = false;
    public showProvidedConfigurationValues: boolean = false;
    public showRequiredConfigurationValues: boolean = false;
    public providedConfigurationValues: NetworkIdentityProviderConfigurationValue[];
    public requiredConfigurationValues: NetworkIdentityProviderExpectedConfigurationValue[];
    public allowConfigurationUpdates: boolean = true;
    public allowConfigurationDeletion: boolean = false;
    public formRequiredConfigurationErrors: Record<string, string[]> = {};
    public singleSignOnUrl: string = null;
    public brandDisplayName: string = null;

    constructor(
        private $confirm: any,
        private notificationService: any,
        private networkIdentityProviderService: NetworkIdentityProviderService,
        private appConfig: IAppConfig,
        private $q: IQService,
        private $rootScope: IRootScopeService,
        private $appConfig: IAppConfig
    ) {}

    public $onInit(): void {
        this.isLoading = true;
        this.brandDisplayName = this.$appConfig.brandDisplayName;
        this.networkIdentityProviderService
            .getSupportedTypes()
            .then(this.setIdentityProviderTypes.bind(this))
            .then(this.networkIdentityProviderService.getNetworkIdentityProvider.bind(this.networkIdentityProviderService))
            .then(this.setIdentityProvider.bind(this))
            .catch((error) => {
                this.notificationService.errorToaster(error);
                throw error;
            })
            .finally(() => {
                this.notifyIdentityProviderExistenceChanged();
                this.isLoading = false;
            });
    }

    private setIdentityProviderTypes(identityProviderTypes: NetworkIdentityProviderType[]): IPromise<void> {
        this.identityProviderTypes = identityProviderTypes;
        this.identityProviderTypeLookup = identityProviderTypes.reduce(
            (accumulator, value) => {
                accumulator[value.name] = value;
                return accumulator;
            },
            {} as { [key: string]: NetworkIdentityProviderType }
        );
        return this.$q.resolve();
    }

    private notifyIdentityProviderExistenceChanged(): void {
        this.$rootScope.$emit("identityProviderExistenceChanged", !!this.identityProvider);
    }

    private setIdentityProviderConfiguration(config: NetworkIdentityProviderConfiguration): IPromise<void> {
        this.providedConfigurationValues = config.providedConfigurationValues;
        this.showProvidedConfigurationValues = !!this.providedConfigurationValues && !!Object.keys(this.providedConfigurationValues).length;
        this.requiredConfigurationValues = config.requiredConfigurationValues;
        this.showRequiredConfigurationValues = !!this.requiredConfigurationValues.length;
        if (!!this.identityProvider) {
            this.identityProvider.configuration = config.requiredConfigurationValues.reduce(
                (accumulator, value) => {
                    accumulator[value.propertyName] =
                        !!this.identityProvider && !!this.identityProvider.configuration ? this.identityProvider.configuration[value.propertyName] : null;
                    return accumulator;
                },
                {} as Record<string, string>
            );
        }
        return this.$q.resolve();
    }

    private setIdentityProvider(identityProvider: NetworkIdentityProvider): IPromise<void> {
        this.identityProvider = identityProvider;
        const registrationComplete = !!this.identityProvider;
        this.allowConfigurationUpdates = !registrationComplete;
        this.allowConfigurationDeletion = registrationComplete;
        this.showIdentityProviderConfiguration = registrationComplete;
        this.singleSignOnUrl = registrationComplete ? new URL(`${this.appConfig.appUrl}/account/login/${identityProvider.connectionName}`).toString() : null;
        if (!registrationComplete) {
            this.setIdentityProviderConfiguration({ providedConfigurationValues: [], requiredConfigurationValues: [] });
        }
        return registrationComplete
            ? this.networkIdentityProviderService
                  .getConfigurationForIdentityProviderType(this.identityProvider.identityProviderTypeName)
                  .then(this.setIdentityProviderConfiguration.bind(this))
            : this.$q.resolve();
    }

    public onIdentityProviderTypeChange(): void {
        this.showIdentityProviderConfiguration = !!this.identityProvider.identityProviderTypeName;
        if (this.showIdentityProviderConfiguration) {
            this.networkIdentityProviderService
                .getConfigurationForIdentityProviderType(this.identityProvider.identityProviderTypeName)
                .then(this.setIdentityProviderConfiguration.bind(this))
                .catch((error) => {
                    this.notificationService.errorToaster(error);
                    throw error;
                });
        }
    }

    public showCopyToClipboardSuccess(valueName: string): void {
        this.notificationService.success(`${valueName} copied to clipboard`);
    }

    public save(form: IFormController): void {
        this.isLoading = true;
        this.formRequiredConfigurationErrors = {};
        this.networkIdentityProviderService
            .saveNetworkIdentityProvider(this.identityProvider)
            .then(this.setIdentityProvider.bind(this))
            .then(() => {
                this.notificationService.success("Identity Provider saved successfully");
                this.formRequiredConfigurationErrors = {};
                form.$setPristine();
            })
            .catch((error) => {
                if (error && error.data && error.data.Detail && error.data.Detail.ModelState) {
                    Object.keys(error.data.Detail.ModelState).forEach((key) => {
                        form[key].$setValidity("server", false);
                        this.formRequiredConfigurationErrors[key] = error.data.Detail.ModelState[key];
                    });
                } else if (error && error.data && error.data.Detail && error.data.Detail.Message) {
                    this.notificationService.errorToaster(error.data.Detail.Message);
                    throw error;
                } else {
                    this.notificationService.errorToaster("Failed to save identity provider.");
                    throw error;
                }
            })
            .finally(() => {
                this.notifyIdentityProviderExistenceChanged();
                this.isLoading = false;
            });
    }

    public delete(form: IFormController): void {
        this.isLoading = true;
        this.$confirm
            .open({
                bodyText: "Are you sure you want to delete this Identity Provider association?",
                okText: "Delete"
            })
            .result.then(
                () =>
                    this.networkIdentityProviderService
                        .deleteNetworkIdentityProvider()
                        .then(this.setIdentityProvider.bind(this))
                        .then(() => {
                            this.notificationService.success("Identity Provider deleted successfully");
                            this.formRequiredConfigurationErrors = {};
                            form.$setPristine();
                        })
                        .catch((error) => {
                            this.notificationService.errorToaster(error);
                            throw error;
                        }),
                noop
            )
            .finally(() => {
                this.notifyIdentityProviderExistenceChanged();
                this.isLoading = false;
            });
    }
}

export const networkIdentityProviderComponent: IComponentOptions = {
    templateUrl: require("./network-identity-provider.component.html"),
    controller: NetworkIdentityProviderController
};
