import { Routes } from "@angular/router";
import { featureGuard } from "@kno2/core/routing";
import { roleGuard } from "@kno2/core/session";

export const routes: Routes = [
    {
        path: "settings",
        data: {
            roles: ["Administrator"]
        },
        canActivateChild: [roleGuard],
        children: [
            {
                path: "contacts",
                canActivate: [featureGuard],
                data: {
                    features: ["ContactsManagement"]
                },
                loadComponent: () => import("./contacts").then((m) => m.ContactsComponent)
            },
            {
                path: "documenttypes",
                loadComponent: () => import("./document-types").then((m) => m.DocumentTypesComponent)
            },
            {
                path: "downloadprofiles",
                loadComponent: () => import("./download-profiles").then((m) => m.DownloadProfilesComponent)
            },
            {
                path: "releasetypes",
                loadComponent: () => import("./release-types").then((m) => m.ReleaseTypesComponent)
            }
        ]
    }
];
