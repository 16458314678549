import { IHttpPromise, IHttpService, IPromise, IQService, IWindowService } from "angular";
import { IAppConfig } from "./app.config";

declare const APP_CONFIG_OVERRIDE: IAppConfig;
declare const BASE_API_URL: string;

const devConfigDataStore: IAppConfig = APP_CONFIG_OVERRIDE;

interface BrandWebConfiguration extends Omit<IAppConfig, "auth0"> {
    auth0ClientId: string;
    auth0Domain: string;
    auth0Audience: string;
    auth0RedirectUri: string;
    images: BrandWebConfigurationImage[];
    themes: BrandWebConfigurationTheme[];
}

type BrandWebConfigurationImageName = "logo" | "logo-2x" | "logo-dark" | "favicon" | "top-nav-background";
interface BrandWebConfigurationImage {
    name: BrandWebConfigurationImageName;
    url: string;
}

interface BrandWebConfigurationTheme {
    cssPropertyName: string;
    cssPropertyValue: string;
}

export class AppConfigService {
    private document: Document;
    public static $inject = ["$http", "$window", "$q"];
    private currentHost: string;
    private appConfig: IAppConfig;
    private webConfig: Partial<BrandWebConfiguration> = { images: [], themes: [] };

    constructor(
        private $http: IHttpService,
        private $window: IWindowService,
        private $q: IQService
    ) {
        this.document = this.$window.document;
        this.currentHost = this.$window.location.host;
    }

    public load(): IPromise<IAppConfig> {
        if (this.appConfig) {
            return this.$q.resolve(this.appConfig);
        }

        return this.getBrandWebConfiguration()
            .then((response) => {
                const { data } = response;
                const { images, themes, ...config } = data;

                this.appConfig = {
                    ...config,
                    baseApiUrl: BASE_API_URL,
                    auth0: {
                        clientId: this.isLocalDev() ? devConfigDataStore.auth0.clientId : config.auth0ClientId,
                        domain: config.auth0Domain,
                        redirectUri: config.auth0RedirectUri,
                        audience: config.auth0Audience
                    }
                };

                this.webConfig = { images, themes };
                this.setCssVariables();
                this.setWindowTitleAndFavicon();

                return this.appConfig;
            })
            .catch((err) => {
                console.error(err);
                throw new Error(err);
            });
    }

    public getImageByName(imageName: BrandWebConfigurationImageName): BrandWebConfigurationImage {
        return this.webConfig.images.find((image) => image.name === imageName);
    }

    public getBrandWebConfiguration(): IHttpPromise<BrandWebConfiguration> {
        return this.$http.get<BrandWebConfiguration>(`${BASE_API_URL}/api/brands/webconfiguration`, {
            params: {
                appUrl: this.currentHost
            },
            cache: true
        });
    }

    private isLocalDev(): boolean {
        return this.currentHost.startsWith("localhost") || this.currentHost.includes(".local");
    }

    private setCssVariables(): void {
        const { themes } = this.webConfig;
        themes.forEach(({ cssPropertyName, cssPropertyValue }) => {
            this.document.documentElement.style.setProperty(`--${cssPropertyName}`, cssPropertyValue);
        });
    }

    private setWindowTitleAndFavicon(): void {
        this.document.title = this.appConfig.brandDisplayName;
        let faviconLinkEl = this.document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!faviconLinkEl) {
            faviconLinkEl = this.document.createElement("link");
            faviconLinkEl.rel = "shortcut icon";
            this.document.getElementsByTagName("head")[0].appendChild(faviconLinkEl);
        }
        faviconLinkEl.href = this.getImageByName("favicon").url;
    }
}
