declare const angular;
declare const _;

let ctrl;
class AdminEmergenciesController {
    public static $inject: Array<string> = ["$state", "$uibModal"];
    public selectedTab = "emergencies";
    public emergencyAdded: boolean = false;
    public organizationAdded: boolean = false;

    constructor(
        private $state,
        private $uibModal
    ) {}

    private addEmergency(): void {
        this.$uibModal
            .open({
                component: "k2AdminEmergenciesEditComponent"
            })
            .result.then(() => (this.emergencyAdded = true), angular.noop);
    }

    private addOrganization(): void {
        this.$uibModal
            .open({
                component: "k2AdminEmergenciesOrganizationsEditComponent"
            })
            .result.then(() => (this.organizationAdded = true), angular.noop);
    }

    public add(): void {
        const stateName = this.$state.current.name.split(".")[1];

        switch (this.selectedTab) {
            case "organizations":
                this.addOrganization();
                break;
            case "emergencies":
            default:
                this.addEmergency();
                break;
        }
    }

    public resetItems(): void {
        this.emergencyAdded = false;
        this.organizationAdded = false;
    }
}

export class AdminEmergenciesComponent {
    public templateUrl: any;
    public controller: any;

    constructor() {
        this.templateUrl = require("./admin.emergencies.component.html");
        this.controller = AdminEmergenciesController;
    }
}
