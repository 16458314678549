import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { SKIP_TOKEN_INJECTION } from "@kno2/core/http";
import { Observable, firstValueFrom } from "rxjs";

export interface ChangePasswordRequest {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}

export interface AccountActivateRequest {
    token: string;
    password: string;
    confirmPassword: string;
}

@Injectable({
    providedIn: "root"
})
export class AccountService {
    private httpClient = inject(HttpClient);

    public changeOrganization = (orgId: string): Promise<string | null> => firstValueFrom(this.changeOrganization$(orgId));
    public changePassword = (password: { oldPassword: string; password: string; confirmPassword: string }): Promise<void> =>
        firstValueFrom(this.changePassword$(password));

    public activate = (request: AccountActivateRequest): Promise<void> => firstValueFrom(this.activate$(request));
    public validateToken = (token: string): Promise<void> => firstValueFrom(this.validateToken$(token));
    public resendInvitation = (token: string): Promise<string> => firstValueFrom(this.resendInvitation$(token));

    private changeOrganization$(orgId: string): Observable<string | null> {
        return this.httpClient.post<string | null>("/api/account/change", { orgId });
    }

    private changePassword$(request: ChangePasswordRequest): Observable<void> {
        return this.httpClient.put<void>("/api/account/password", request);
    }

    private activate$(request: AccountActivateRequest): Observable<void> {
        return this.httpClient.post<void>("/api/account/activate", request, {
            context: new HttpContext().set(SKIP_TOKEN_INJECTION, true)
        });
    }

    private validateToken$(token: string): Observable<void> {
        return this.httpClient.get<void>(`/api/account/validatetoken/${token}`, {
            context: new HttpContext().set(SKIP_TOKEN_INJECTION, true)
        });
    }

    private resendInvitation$(token: string): Observable<string> {
        return this.httpClient.post<string>(`/api/account/resend/invitation/${token}`, null, {
            context: new HttpContext().set(SKIP_TOKEN_INJECTION, true)
        });
    }
}
