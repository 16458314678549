import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formErrors",
    standalone: true
})
export class FormErrorsPipe implements PipeTransform {
    transform(errorObj: Record<string, string>): string[] {
        if (!errorObj) return [];
        return Object.values(errorObj);
    }
}
