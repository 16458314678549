import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ContactUploadLatestData } from "@kno2/data-access/contacts";

@Component({
    selector: "kno2-contacts-upload-failure-card",
    imports: [DatePipe],
    templateUrl: "./contacts-upload-failure-card.component.html",
    standalone: true
})
export class ContactsUploadFailureCardComponent {
    @Input() public uploadData: ContactUploadLatestData;
    @Input() public isLoading: boolean;
    @Output() public onDownloadClick = new EventEmitter<void>();

    public downloadErrorFile(): void {
        this.onDownloadClick.emit();
    }
}
