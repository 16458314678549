import { AdminEmergenciesOrganizationsService } from "./admin.emergencies-organizations.service";

declare const angular;
declare const _;

let ctrl;
class AdminEmergenciesOrganizationsController {
    public static $inject: Array<string> = ["$confirm", "$uibModal", "AdminEmergenciesOrganizationsService", "NotificationService"];
    public organizations: Array<any>;

    constructor(
        private $confirm,
        private $uibModal,
        private adminEmergenciesOrganizationsService: AdminEmergenciesOrganizationsService,
        private notificationService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.organizations = [];
        this.loadOrganizations();
    }

    public $onChanges(changes): void {
        if (changes.organizationAdded && changes.organizationAdded.currentValue) {
            this.loadOrganizations();
        }
    }

    private loadOrganizations(): void {
        ctrl.adminEmergenciesOrganizationsService
            .getEmergencyOrganizations()
            .then((res) => {
                ctrl.organizations = res.data.value;
                ctrl.resetItems();
            })
            .catch((err) => {
                ctrl.notificationService.error("Error loading organizations.");
                ctrl.resetItems();
            });
    }

    public upsertOrganization(org: any): void {
        this.$uibModal
            .open({
                component: "k2AdminEmergenciesOrganizationsEditComponent",
                resolve: { data: () => org }
            })
            .result.then(this.loadOrganizations, angular.noop);
    }

    public deleteOrganization(org: any): void {
        this.$confirm
            .open({
                bodyText: `Are you sure you want to remove <strong>${org.name}</strong> from the list of known PUBLICHEALTH emergencies?`,
                okText: "Remove"
            })
            .result.then(() => {
                this.adminEmergenciesOrganizationsService.deleteEmergencyOrganization(org.oid).then(() => {
                    this.notificationService.success("Organization removed.");
                    this.loadOrganizations();
                });
            }, angular.noop);
    }
}

export class AdminEmergenciesOrganizationsComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./admin.emergencies-organizations.component.html");
        this.controller = AdminEmergenciesOrganizationsController;
        this.bindings = {
            organizationAdded: "<",
            resetItems: "&"
        };
    }
}
