export const SUPPORT_TICKET_AREAS: (brandDisplayName: string) => string[] = (brandDisplayName: string) => [
    "Add On",
    "Address Configuration",
    "APIs",
    "Document Type Setup",
    "Document Viewing",
    "Fax Number Configuration",
    "Intake",
    "Intake Process",
    "Intake Download",
    "Intake Rules",
    "Fax",
    "My Profile",
    "Notifications",
    "Porting Fax Number",
    "Release",
    "Releases Setup",
    "Request Connection/Invite",
    "User Assignment",
    `${brandDisplayName} Desktop`,
    "Other"
];

export const SUPPORT_TICKET_SEVERITY: (brandDisplayName: string) => string[] = (brandDisplayName: string) => [
    `1 - ${brandDisplayName} is not operational`,
    "2 - Feature not working as expected",
    "3 - Enhancement request",
    `4 - Question about ${brandDisplayName}`
];

export const SUPPORT_TICKET_ISSUE_TEXT_PLACEHOLDER =
    "DISCLAIMER - This system is provided for support and general customer communication only. Do not send or transmit through this system any information, including protected health information, that is protected under the Health Insurance Portability and Accountability Act of 1996, as amended (HIPAA), or any other applicable law. If you have uploaded any such information, you must remove it immediately.";
