angular.module("kno2.filters").filter("telephone", function () {
    var numberRegex = /^[0-9]{10}$/;

    var formatTelphoneNumber = function (telephone) {
        var areaCode = telephone.slice(0, 3);
        var number = telephone.slice(3, 6) + "-" + telephone.slice(6);
        return "(" + areaCode + ") " + number;
    };

    return function (value) {
        if (!value) return "";
        var telephone = $.trim(value).replace(/[^\d]*/g, "");
        return numberRegex.test(telephone) ? formatTelphoneNumber(telephone) : "";
    };
});
