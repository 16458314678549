export class ContactsService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    searchContacts(search: string, pageStart = 1, pageSize = 15) {
        const params = { search, pageStart, pageSize };
        return this.$http.get("/api/contacts", { params }).then((x) => x.data);
    }
}

ContactsService.$inject = ["$http"];
