(function () {
    "use strict";

    angular.module("kno2.directives").directive("select", select);

    select.$inject = [];

    /**
     * A directive that will intercept any model values that are '' and format
     * them as null so angular doesn't insert an empty <option value="?"> for unknowns.
     * See IKW-3814, KPI-28869.
     */
    function select() {
        return {
            restrict: "E",
            require: "?ngModel",
            link: function (scope, element, attrs, ngModelController) {
                if (!ngModelController) return;

                ngModelController.$formatters.unshift(function removeBlankStrings(value) {
                    if (value === "") return null;
                    return value;
                });
            }
        };
    }
})();
