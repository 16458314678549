import accountDashboardUserInvitationModalTemplate from "../common/user-invitation/account.dashboard.user-invitation-modal.html";

InviteCtrl.$inject = ["$window", "$location", "$timeout", "$scope", "$uibModal", "InviteService", "usersFactory", "NotificationService"];
export function InviteCtrl($window, $location, $timeout, $scope, $uibModal, InviteService, usersFactory, NotificationService) {
    $scope.invitations = {};

    // Paging

    $scope.updateInvitations = function (page) {
        InviteService.getInvitations(page, $scope.pageSize).then(function (response) {
            $scope.invitations = response;
        });
    };

    $scope.openInvitationModal = function () {
        var inviteModal = $uibModal.open({
            templateUrl: accountDashboardUserInvitationModalTemplate,
            controller: "UserInvitationModalCtrl"
        });
        inviteModal.result.then(
            function () {
                InviteService.getInvitations($scope.currentPage, $scope.pageSize).then(function (response) {
                    $scope.invitations = response;
                });
            },
            () => {}
        );
    };

    $scope.sendInvitation = function (email, fullName, directAddress) {
        if (!$scope.processing) {
            $scope.processing = true;
            usersFactory
                .sendInvitation({
                    emailAddress: email,
                    fullName: fullName,
                    directAddress: directAddress
                })
                .then(function () {
                    NotificationService.success("Invitation has been sent");
                    InviteService.getInvitations($scope.currentPage, $scope.pageSize).then(function (response) {
                        $scope.invitations = response;
                    });
                })
                .finally(function () {
                    $scope.processing = false;
                });
        }
    };

    // Init
    (function () {
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        InviteService.getInvitations($scope.currentPage, $scope.pageSize).then(function (response) {
            $scope.invitations = response;
            $scope.maxPage = Math.ceil($scope.invitations.totalCount / $scope.pageSize);
        });

        //  $scope.invitations.currentPage = 0;
    })();
}
