import templateUrl from "./query.organizations.component.html";

angular.module("kno2.records").component("k2QueryOrganizations", {
    controller: QueryOrganizationsController,
    templateUrl,
    require: {
        wizard: "^^k2QueryWizard"
    }
});

QueryOrganizationsController.$inject = ["$state", "NotificationService"];

function QueryOrganizationsController($state, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.toggleAll = toggleAll;
    ctrl.refreshAllChecked = refreshAllChecked;

    function $onInit() {
        ctrl.form = ctrl.wizard.form;
        var organizations = ctrl.wizard.organizations;
        ctrl.organizations = organizations;

        var organizationStateArray = _.times(organizations.length, _.constant(false));
        var orgIds = _.map(organizations, function (org) {
            return org.orgId;
        });

        var selectedOrgs = _.zipObject(orgIds, organizationStateArray);

        ctrl.selectedOrganizations = selectedOrgs;
        ctrl.wizard.submit = next;
        ctrl.allChecked = false;
    }

    function toggleAll() {
        var selectAll = ctrl.allChecked === true;

        for (var org in ctrl.selectedOrganizations) {
            if (ctrl.selectedOrganizations.hasOwnProperty(org)) {
                ctrl.selectedOrganizations[org] = selectAll;
            }
        }
    }

    function refreshAllChecked() {
        ctrl.allChecked = allOrgsChecked();
    }

    function allOrgsChecked() {
        var orgsSelected = _.keys(
            _.pickBy(ctrl.selectedOrganizations, function (v) {
                return v === true;
            })
        );

        if (orgsSelected.length === ctrl.organizations.length) {
            return true;
        }

        var result = orgsSelected.length > 0 ? null : false;

        return result;
    }

    function next() {
        var selectedOrgIds = _.keys(
            _.pickBy(ctrl.selectedOrganizations, function (v) {
                return v === true;
            })
        );
        var selectedOrgs = _.filter(ctrl.organizations, function (org) {
            return _.includes(selectedOrgIds, org.orgId);
        });

        if (!selectedOrgs.length) return NotificationService.error("Please select at least 1 organization.");

        ctrl.wizard.selectedOrganizations = selectedOrgs;

        $state.go("records.query.wizard.patient");
    }
}
