angular.module("kno2.services").service("UsersService", [
    "$http",
    "CacheService",
    function ($http, CacheService) {
        // Ajax request to get all users in the logged in user's org
        this.getOrgUsers = function (includeNetwork) {
            const params = {
                includeNetwork: includeNetwork
            };
            var promise = $http.get("/api/users", { params: params, cache: CacheService.cacheL2() }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.getCurrent = function () {
            return $http.get("/api/users/current").then((response) => response.data);
        };

        this.getClientIp = function () {
            return $http.get("/api/users/ipaddress").then((response) => response.data);
        };
    }
]);
