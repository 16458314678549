import {
    NetworkIdentityProviderService,
    NetworkIdentityProviderProvisioning,
    NetworkIdentityProviderProvisioningConfiguration
} from "App/src/common/services/network-identity-provider.service";
import { IComponentOptions, IPromise, IQService } from "angular";

class NetworkIdentityProviderProvisioningController {
    public static $inject: Array<string> = ["$confirm", "NotificationService", "NetworkIdentityProviderService", "$q"];

    public isReady: boolean = false;
    public isLoading: boolean = false;
    public identityProviderProvisioning: NetworkIdentityProviderProvisioning;
    public identityProviderDisplayName: string;
    public endpointFieldLabel: string;
    public tokenFieldLabel: string;

    constructor(
        private $confirm: any,
        private notificationService: any,
        private networkIdentityProviderService: NetworkIdentityProviderService,
        private $q: IQService
    ) {}

    public $onInit(): void {
        this.isLoading = true;
        this.networkIdentityProviderService
            .getProvisioningInfo()
            .then((provisioningInfo) => (this.identityProviderProvisioning = provisioningInfo))
            .then((provisioningInfo) =>
                this.networkIdentityProviderService.getProvisioningConfigurationForIdentityProviderType(provisioningInfo.identityProviderTypeName)
            )
            .then((provisioningInfo) => this.setIdentityProviderProvisioningConfiguration(provisioningInfo))
            .catch((error) => {
                this.notificationService.errorToaster(error);
                throw error;
            })
            .finally(() => {
                this.isReady = true;
                this.isLoading = false;
            });
    }

    public generateProvisioningToken(): void {
        this.isLoading = true;

        this.networkIdentityProviderService
            .generateProvisioningToken()
            .then((tokenResponse) => (this.identityProviderProvisioning.token = tokenResponse.token))
            .catch((error) => {
                this.notificationService.errorToaster(error);
                throw error;
            })
            .finally(() => (this.isLoading = false));
    }

    public deleteProvisioningToken(): void {
        this.$confirm
            .open({
                bodyText: "When revoking the provisioning token, the existing token will no longer work. Are you sure about this?",
                okText: "Revoke"
            })
            .result.then(() => {
                this.isLoading = true;

                this.networkIdentityProviderService
                    .deleteProvisioningToken()
                    .then(() => (this.identityProviderProvisioning.token = ""))
                    .catch((error) => {
                        this.notificationService.errorToaster(error);
                        throw error;
                    })
                    .finally(() => (this.isLoading = false));
            });
    }

    public showCopyToClipboardSuccess(valueName: string): void {
        this.notificationService.success(`${valueName} copied to clipboard.`);
    }

    private setIdentityProviderProvisioningConfiguration(
        identityProviderProvisioningConfiguration: NetworkIdentityProviderProvisioningConfiguration[]
    ): IPromise<void> {
        this.identityProviderDisplayName = identityProviderProvisioningConfiguration.find((v) => v.propertyName === "identityProviderDisplayName").displayName;
        this.endpointFieldLabel = identityProviderProvisioningConfiguration.find((v) => v.propertyName === "endpoint").displayName;
        this.tokenFieldLabel = identityProviderProvisioningConfiguration.find((v) => v.propertyName === "token").displayName;

        return this.$q.resolve();
    }
}

export const networkIdentityProviderUserProvisioningComponent: IComponentOptions = {
    templateUrl: require("./network-identity-provider-user-provisioning.component.html"),
    controller: NetworkIdentityProviderProvisioningController
};
