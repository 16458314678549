import templateUrl from "./reseller-tos.modal.component.html";

export const resellerTosModalComponent = {
    templateUrl,
    controller: ResellerTosModalController,
    bindings: {
        resolve: "<",
        close: "&"
    }
};

ResellerTosModalController.$inject = ["ResellerService", "SessionService", "NotificationModalService"];

export function ResellerTosModalController(ResellerService, SessionService, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.accept = accept;

    function $onInit() {
        ctrl.profile = SessionService.getProfile();
    }

    function accept() {
        ResellerService.acceptEula(ctrl.profile.resellerId)
            .then(() => {
                SessionService.refreshProfile();
                ctrl.close({ $value: null });
            })
            .catch(() => NotificationModalService.error("An error occurred. Please try again."));
    }
}
