angular.module("kno2.settings").controller("ResendInvitationModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "user",
    "usersFactory",
    "NotificationService",
    "appConfig",
    function ($scope, $uibModalInstance, user, usersFactory, NotificationService, appConfig) {
        $scope.user = user;
        $scope.brandDisplayName = appConfig.brandDisplayName;
        $scope.resendInvitation = function () {
            usersFactory.resendInvitation($scope.user).then(
                function (res) {
                    NotificationService.success("Invitation has been sent");
                    $uibModalInstance.close($scope.user);
                },
                function (res) {
                    NotificationService.serverError(res.data.modelState, "There was an error resending the invitation.");
                }
            );
        };

        $scope.closeModal = function () {
            $uibModalInstance.dismiss($scope.user);
        };
    }
]);
