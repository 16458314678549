import templateUrl from "./admin-organization-types-delete.modal.component.html";

export const adminOrganizationTypesDeleteModalComponent = {
    templateUrl,
    controller: AdminOrganizationTypesDeleteModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AdminOrganizationTypesDeleteModalController.$inject = ["NotificationService", "OrganizationTypesService"];

export function AdminOrganizationTypesDeleteModalController(NotificationService, OrganizationTypesService) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.confirm = confirm;
    ctrl.cancel = cancel;

    function onInit() {
        ctrl.organizationTypeId = ctrl.resolve.organizationTypeId;

        OrganizationTypesService.getOrganizationType(ctrl.organizationTypeId).then(function (response) {
            ctrl.organizationType = response;
        });
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function confirm() {
        OrganizationTypesService.delete(ctrl.organizationType.id).then(
            function (response) {
                ctrl.close();
            },
            function (error) {
                NotificationService.error(error.message);
            }
        );
    }
}
