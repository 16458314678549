angular
    .module("kno2.records")
    .filter("recentActivityEventDate", function () {
        return function (date) {
            var eventMoment = moment.utc(date).local();
            if (eventMoment.isValid()) {
                var isToday = eventMoment.isSame(moment(), "day");
                if (isToday) {
                    return eventMoment.format("h:mm A");
                } else {
                    return eventMoment.format("MMM DD");
                }
            }
            return undefined;
        };
    })
    .filter("releasePopOverEventDateFilter", function () {
        return function (date) {
            var eventMoment = moment.utc(date).local();
            if (eventMoment.isValid()) {
                return eventMoment.format("MM/DD/YY hh:mm A");
            }
            return undefined;
        };
    });
