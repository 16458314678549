import { AdminCertificatesService } from "./admin.certificates.service";

let ctrl;
class AdminCertificatesEditController {
    public static $inject: Array<string> = ["AdminCertificatesService", "NotificationService", "$timeout"];

    public resolve;
    public close;
    public dismiss;

    public cert: any;
    public saving: boolean;
    public hasFile: boolean;
    public certificate: any;

    constructor(
        private adminCertificatesService: AdminCertificatesService,
        private notificationService,
        private $timeout
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.cert = this.resolve.data || {};
        this.saving = false;
        if (this.cert.name) {
            this.adminCertificatesService
                .getCertificateByName(this.cert.name)
                .then((response) => {
                    this.certificate = {
                        ...response.data,
                        name: this.cert.name
                    };
                })
                .catch(() => this.notificationService.error("Error retrieving certificate"));
        } else {
            this.certificate = {
                organizations: []
            };
        }
    }

    public save(): void {
        this.certificate.useExistingName = !!this.cert.name;

        this.saving = true;
        this.adminCertificatesService
            .postCertificate(this.certificate)
            .then((response) => {
                this.notificationService.success("Certicate saved.");
                this.$timeout(ctrl.close(), 2000, false);
                this.saving = false;
            })
            .catch((error) => {
                let message = "There was an error uploading the certificate";
                if (error.data && error.data.title) message += ` ( Error: \"${error.data.title}\" )`;
                this.notificationService.error(message);
                this.saving = false;
            });
    }

    public onFileSelect(files): void {
        this.certificate.file = files[0];
        this.hasFile = true;
    }
}

export class AdminCertificatesEditComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.certificates.edit-modal.component.html");
        this.controller = AdminCertificatesEditController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}
