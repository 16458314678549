angular.module("kno2.services").service("IAuditService", [
    "$http",
    "_",
    function ($http, _) {
        this.getEntriesByCorrelationId = function (correlationId, eventType, startDate, endDate) {
            const params = {
                startDate: startDate || moment().utc(),
                endDate: endDate || moment().utc().subtract(14, "days")
            };
            return $http.get(`/api/audit/${eventType}/${correlationId}`, { params }).then((x) => x.data);
        };

        this.getAuditEntriesByTypes = function (correlationId, eventTypes, startDate, endDate) {
            const params = {
                eventTypes: eventTypes ? eventTypes.join(",") : undefined,
                startDate: startDate || moment().utc(),
                endDate: endDate || moment().utc().subtract(14, "days")
            };
            return $http.get(`/api/audit/${correlationId}/events`, { params }).then((x) => x.data);
        };

        this.getEntry = function (entryId) {
            return $http.get("/api/audit/" + entryId).then((x) => x.data);
        };

        this.getEntriesByType = function (eventType, startDate, endDate) {
            const params = {
                startDate: startDate || moment().utc().subtract(14, "days").toJSON(),
                endDate: endDate || moment().utc().toJSON()
            };
            return $http.get(`/api/audit/entries/${eventType}`, { params }).then((x) => x.data);
        };

        this.getEntriesByUser = function (username, eventType, startDate, endDate) {
            const encodedUserName = encodeURIComponent(username);
            const params = {
                username: encodedUserName,
                eventType: eventType,
                startDate: startDate || moment().utc().subtract(14, "days"),
                endDate: endDate || moment().utc()
            };
            return $http.get("/api/audit/user", { params }).then((x) => x.data);
        };

        this.getEntryTypes = function () {
            return $http.get("/api/audit/types").then((x) => x.data);
        };
    }
]);
