(function () {
    "use strict";

    angular.module("kno2.directives").directive("input", inputTelDirective);

    inputTelDirective.$inject = [];

    function inputTelDirective() {
        var directive = {
            restrict: "E",
            require: "?ngModel",
            link: link
        };
        return directive;

        function link(scope, element, attrs, ngModelController) {
            if (!ngModelController || attrs.type !== "tel") return;

            ngModelController.$validators.tel = function (modelValue, viewValue) {
                var pattern = /^(\d{0,1})(?:[-.\s]?)(?:\(?)(\d{3})(?:\)?)(?:[-.\s]?)(\d{3})(?:[-\.\s]?)(\d{4})$/;
                var value = modelValue || viewValue;
                if (!value) return true;
                return pattern.test(value);
            };
        }
    }
})();
