import templateUrl from "./messaging-health-workflows.component.html";

export class MessagingHealthWorkflowsController {
    constructor(MessagingHealthService, NotificationService, $stateParams, Auth0Service, $http, appConfig) {
        this.MessagingHealthService = MessagingHealthService;
        this.NotificationService = NotificationService;
        this.$stateParams = $stateParams;

        this.onPageSizeChange = _.debounce(
            function () {
                this.getWorkflows();
            }.bind(this),
            300,
            true
        );

        const token = Auth0Service.accessToken;
        const header = "Bearer " + token;

        let filterDef = [];
        Object.keys($stateParams).forEach((key) => {
            if (!!$stateParams[key]) {
                filterDef.push({
                    field: key,
                    operator: "eq",
                    value: $stateParams[key]
                });
            }
        });

        this.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: {
                        url: `${appConfig.baseApiUrl}/api/diagnostics/messaging/workflows/_search`,
                        type: "POST",
                        contentType: "application/json",
                        dataType: "json",
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", header);
                        }
                    },
                    parameterMap: function (data, type) {
                        return kendo.stringify(data);
                    }
                },
                schema: {
                    data: function (data) {
                        return data["messageHealthDataValues"];
                    },
                    total: function (data) {
                        return data["messageHealthDataValuesCount"];
                    }
                },
                pageSize: 20,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                filter: filterDef
            }),
            sortable: true,
            filterable: true,
            pageable: {
                pageSizes: [10, 20, 50, 100],
                refresh: true
            },
            detailTemplate: kendo.template($("#template").html()),
            columns: [
                {
                    title: "Id",
                    field: "mailMessage.mailMessageId",
                    template: (dataItem) => {
                        return !dataItem.mailMessage ? "" : dataItem.mailMessage.mailMessageId;
                    },
                    filterable: false,
                    width: "60px"
                },
                {
                    title: "Tenant",
                    field: "mailMessage.tenantId",
                    template: (dataItem) => {
                        return !dataItem.mailMessage ? "" : dataItem.mailMessage.tenantId;
                    },
                    width: "60px"
                },
                {
                    title: "Sender",
                    field: "mailMessage.sender",
                    template: (dataItem) => {
                        return !dataItem.mailMessage ? "" : dataItem.mailMessage.sender;
                    },
                    width: "140px"
                },
                {
                    field: "mailMessage.mailTo",
                    title: "Mail To",
                    template: (dataItem) => {
                        return !dataItem.mailMessage ? "" : dataItem.mailMessage.mailTo;
                    },
                    width: "140px"
                },
                {
                    title: "Origin",
                    field: "mailMessage.origin",
                    template: (dataItem) => {
                        return !dataItem.mailMessage ? "" : dataItem.mailMessage.origin;
                    },
                    width: "60px"
                },
                {
                    field: "channel",
                    title: "Channel",
                    width: "50px"
                },
                {
                    field: "status",
                    width: "35px"
                },
                {
                    field: "id",
                    title: "Workflow Id",
                    width: "60px"
                },
                {
                    field: "createdTime",
                    title: "Created Date",
                    filterable: false,
                    template: "#: format.datePrecision(createdTime) #",
                    width: "75px"
                }
            ]
        };

        $("#workflows-grid").kendoTooltip({
            filter: ".k-grid-filter, .k-pager-refresh"
        });

        this.workflowGridOptions = function (dataItem) {
            const ctrl = this;

            let type = "MessageSendWorkflow";
            if (dataItem.type === "intake") type = "MessageReceiveWorkflow";

            for (let i = 0; i < dataItem.workflow.steps.length; i++) {
                dataItem.workflow.steps[i].workflowId = dataItem.id;
                dataItem.workflow.steps[i].command = dataItem.command;
                dataItem.workflow.steps[i].workflowType = type;
            }

            return {
                dataSource: new kendo.data.DataSource({
                    data: dataItem.workflow.steps
                }),
                scrollable: true,
                sortable: false,
                pageable: false,
                detailTemplate: kendo.template(`
                <div class="row">
                    <h6><b>Meta</b></h6>
                    <pre>{{ dataItem.meta | json }}</pre>
                </div>
                `),
                columns: [
                    { field: "stepName", title: "Name" },
                    {
                        field: "status",
                        template: kendo.template(`
                        <div class='status #: status #'>
                            <span>#: status #</span>
                        </div>
                        <button type="button" ng-show="ctrl.allowRetry(dataItem)" class="btn btn-xs btn-default" ng-click="ctrl.retry(dataItem)">
                            <i class="glyphicon glyphicon-repeat" style="font-size: .8em; margin-top: 4px;"></i>
                        </button>`),
                        title: "Status"
                    },
                    { field: "startTime", template: "#: format.datePrecision(startTime) #", title: "Start" },
                    { field: "endTime", template: "#: format.datePrecision(endTime) #", title: "End" },
                    { field: "elapsed", title: "Run Time" }
                ]
            };
        };

        this.detailGridOptions = function (dataItem) {
            if (!dataItem.mailMessage || dataItem.mailMessage === null) return {};

            return {
                dataSource: new kendo.data.DataSource({
                    data: dataItem.mailMessage.attachmentEvents
                }),
                scrollable: false,
                sortable: false,
                pageable: false,
                columns: [
                    { field: "mailAttachmentId", title: "Id" },
                    { field: "mimeType" },
                    { field: "size" },
                    { field: "previewStatus", title: "Preview" },
                    { field: "hasPdfContent", title: "PDF Content" },
                    { field: "hasCDAContent", title: "CDA Content" },
                    { field: "hasHl7Content", title: "HL7 Content" }
                ]
            };
        };

        this.eventsGridOptions = function (dataItem) {
            if (!dataItem.mailMessage || dataItem.mailMessage === null) return {};
            return {
                dataSource: {
                    type: "odata",
                    transport: {
                        read: {
                            url: `${appConfig.baseApiUrl}/odata/eventmessages`,
                            dataType: "json",
                            beforeSend: function (req) {
                                req.setRequestHeader("Authorization", header);
                            }
                        }
                    },
                    serverPaging: true,
                    serverSorting: true,
                    serverFiltering: true,
                    pageSize: 5,
                    schema: {
                        data: function (data) {
                            return data["value"];
                        },
                        total: function (data) {
                            return data["odata.count"];
                        },
                        model: {
                            fields: {
                                MailMessageId: {
                                    type: "number"
                                }
                            }
                        }
                    },
                    filter: { field: "MailMessageId", operator: "eq", value: dataItem.mailMessage.mailMessageId }
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                columns: [
                    { field: "Origin", template: "#: format.splitUppercase(Origin) #" },
                    { field: "EventType", title: "Event", template: "#: format.splitUppercase(EventType) #" },
                    { field: "CreatedDate", title: "Receive Date", template: "#: format.date(CreatedDate) #" },
                    {
                        field: "SystemReceivedDate",
                        title: "Sent Date",
                        template: "#: format.date(SystemReceivedDate) #"
                    }
                ]
            };
        };

        this.allowRetry = function (dataItem) {
            if (dataItem.status !== "Completed") return true;
        };

        this.retry = function (dataItem) {
            const data = {
                type: dataItem.workflowType,
                retryStep: dataItem.stepName,
                id: dataItem.workflowId || dataItem.command.workflowId
            };

            dataItem.Status = "Retry";

            $http
                .put("/api/workflows/" + data.id, data)
                .then(function () {})
                .catch(function () {});
        };
    }

    $onInit() {
        this.pageSizeOptions = [10, 25, 50, 100];
        this.pageSize = this.pageSizeOptions[1];
        this.currentPage = 1;
    }
}

MessagingHealthWorkflowsController.$inject = ["MessagingHealthService", "NotificationService", "$stateParams", "Auth0Service", "$http", "appConfig"];

export const messagingHealthWorkflowsComponent = {
    templateUrl: templateUrl,
    controller: MessagingHealthWorkflowsController
};

window.format = {
    getSender: function (mailMessage) {
        if (mailMessage === null) return "";
        return mailMessage.sender;
    },
    datePrecision: function (arg) {
        var date = window.moment.utc(arg).toDate();
        return window.moment(date).local().format("MM/DD/YY hh:mm:ss.SSS a");
    }
};
