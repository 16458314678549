import templateUrl from "./message-row-repeater.html";

class MessageRowRepeaterCtrl {
    constructor($uibModal) {
        this.$uibModal = $uibModal;
    }

    preview($event, message, attachment) {
        $event.stopPropagation();

        this.$uibModal
            .open({
                windowClass: "attachment-preview-modal",
                component: "k2AttachmentPreviewModalComponent",
                resolve: {
                    message: function () {
                        return message;
                    },
                    attachment: function () {
                        return attachment;
                    }
                }
            })
            .result.then(
                () => {},
                () => {}
            );
    }
}
MessageRowRepeaterCtrl.$inject = ["$uibModal"];
export const messageRowRepeater = {
    controller: MessageRowRepeaterCtrl,
    templateUrl: templateUrl,
    bindings: {
        messages: "=",
        rowClick: "&",
        messageSelected: "&",
        deleteClick: "&",
        replyClick: "&",
        forwardClick: "&",
        triageClick: "&",
        showTriage: "&",
        showActivity: "&",
        isFaxMessage: "&"
    }
};
