import { Routes } from "@angular/router";
import { authGuard } from "@kno2/core/auth";
import { rootGuard, runSeriallyGuard } from "@kno2/core/routing";
import { profileGuard } from "@kno2/core/session";
import { accountRoutes } from "@kno2/features/account";
import { adminRoutes } from "@kno2/features/admin";
import { routes as authRoutes } from "@kno2/features/auth";
import { routes as settingsRoutes } from "@kno2/features/settings";
import { routes as supportRoutes } from "@kno2/features/support";
import { HomeComponent } from "@kno2/layout";

export const routes: Routes = [
    ...authRoutes,
    {
        path: "",
        component: HomeComponent,
        canActivate: [runSeriallyGuard([authGuard, profileGuard, rootGuard])],
        children: [
            ...accountRoutes,
            ...adminRoutes,
            ...settingsRoutes,
            ...supportRoutes,
            {
                path: "legalinformation",
                loadComponent: () => import("./features/eula/legal-information/legal-information.component").then((c) => c.LegalInformationComponent)
            },
            {
                path: "unauthorized",
                loadComponent: () => import("./features/auth/unauthorized.component").then((c) => c.UnauthorizedComponent)
            }
        ]
    },
    {
        path: "account/register",
        loadComponent: () => import("./features/account/account-register/account-register.component").then((c) => c.AccountRegisterComponent)
    },
    {
        path: "policies/privacy",
        loadComponent: () => import("./features/eula/policy/policy.component").then((c) => c.PolicyComponent),
        data: { policyType: "Privacy" }
    },
    {
        path: "policies/termsofuse",
        loadComponent: () => import("./features/eula/policy/policy.component").then((c) => c.PolicyComponent),
        data: { policyType: "EULA" }
    },
    {
        path: "unsupported",
        loadComponent: () => import("./features/error/unsupported-browser.component").then((c) => c.UnsupportedBrowserComponent),
        data: { policyType: "EULA" }
    },
    {
        path: "**",
        canActivate: [runSeriallyGuard([authGuard])],
        loadComponent: () => import("./interop/components/empty/empty.component").then((c) => c.EmptyComponent)
    }
];
