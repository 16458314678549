import templateUrl from "./client-credentials-generate.modal.component.html";

export const clientCredentialsGenerateModalComponent = {
    templateUrl: templateUrl,
    controller: ClientCredentialsGenerateModalController,
    bindings: {
        resolve: "<",
        modalInstance: "<"
    }
};

ClientCredentialsGenerateModalController.$inject = ["ServiceAccountServiceFactory", "NotificationModalService"];

export function ClientCredentialsGenerateModalController(ServiceAccountServiceFactory, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.generate = generate;
    ctrl.cancel = cancel;

    function $onInit() {
        ctrl.title = ctrl.resolve.title;
        ctrl.warning = ctrl.resolve.warning;
        ctrl.body = ctrl.resolve.body;
        ctrl.hasClientCredentials = ctrl.resolve.hasClientCredentials;
        ctrl.button = ctrl.resolve.button;
        ctrl.group = ctrl.resolve.group;
        ctrl.isEdit = !!ctrl.group.id;
        ctrl.type = ctrl.resolve.type;
        ctrl.accountId = ctrl.resolve.accountId;
    }

    function generate() {
        if (ctrl.form.$invalid) return NotificationModalService.error("Client Id is required.");

        if (ctrl.accountId) {
            return ServiceAccountServiceFactory.ofType(ctrl.type)
                .addOrUpdateServiceAccount(ctrl.group.id, ctrl.accountId)
                .then(function (data) {
                    ctrl.modalInstance.close(data);
                })
                .catch(function () {
                    NotificationModalService.error("An error occurred when generating new api settings");
                });
        }

        return ServiceAccountServiceFactory.ofType(ctrl.type)
            .addOrUpdateServiceAccount(ctrl.group.id)
            .then(function (data) {
                ctrl.modalInstance.close(data);
            })
            .catch(function () {
                NotificationModalService.error("An error occurred when generating new api settings");
            });
    }

    function cancel() {
        ctrl.group.id = null;
        ctrl.modalInstance.dismiss();
    }
}
