import { Component, inject, OnInit } from "@angular/core";
import { AuthenticationError } from "@auth0/auth0-angular";
import { AuthService } from "@kno2/core/auth";
import { FormatAuthErrorPipe } from "./format-auth-error.pipe";

@Component({
    selector: "kno2-auth-error",
    imports: [FormatAuthErrorPipe],
    standalone: true,
    template: `
        <div style="height: 100vh; background-color: #fff; padding-top: 100px">
            <h1 class="text-center">ERROR: {{ error?.error | formatAuthError }}</h1>
            <h2 class="text-center">
                <p>{{ error?.error_description }}</p>
                @if (hasAccessDeniedError) {
                    <p>You will be redirected to the login screen in {{ seconds }} seconds.</p>
                }
            </h2>
        </div>
    `
})
export class AuthErrorComponent implements OnInit {
    private readonly authService = inject(AuthService);
    protected error: AuthenticationError;
    protected seconds = 6;
    protected hasAccessDeniedError = false;

    public async ngOnInit(): Promise<void> {
        this.error = await this.authService.getError();
        this.hasAccessDeniedError = this.error.error === "access_denied";

        if (this.hasAccessDeniedError) this.countdown();
    }

    public countdown(): void {
        setInterval(() => {
            this.seconds--;
            if (this.seconds === 0) this.authService.logout();
        }, 1000);
    }
}
