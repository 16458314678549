import accountDashboardUserInvitationModalTemplate from "../common/user-invitation/account.dashboard.user-invitation-modal.html";

import templateUrl from "./directory-search-modal.component.html";

export const directorySearchModalComponent = {
    controller: DirectorySearchModalController,
    templateUrl: templateUrl,
    bindings: {
        modalInstance: "<",
        resolve: "<"
    }
};

DirectorySearchModalController.$inject = ["_", "SureScriptsService", "CommonData", "$uibModal", "appConfig"];

export function DirectorySearchModalController(_, SureScriptsService, CommonData, $uibModal, appConfig) {
    const ctrl = this;

    ctrl.brandDisplayName = appConfig.brandDisplayName;
    ctrl.$onInit = $onInit;
    ctrl.closeModal = closeModal;
    ctrl.hasPhoneNumber = hasPhoneNumber;
    ctrl.searchSubmit = searchSubmit;
    ctrl.search = search;
    ctrl.selectAddresses = selectAddresses;
    ctrl.disableAddressSelection = disableAddressSelection;
    ctrl.openInvitationModal = openInvitationModal;

    function $onInit() {
        ctrl.allowSelection = ctrl.resolve.allowSelection;
        ctrl.providers = [];
        ctrl.organizations = [];

        ctrl.totalProvidersCount = 0;
        ctrl.totalOrganizationsCount = 0;

        ctrl.currentProviderPage = 1;
        ctrl.currentOrganizationPage = 1;

        ctrl.states = CommonData.states.asArray();
        ctrl.query = {
            firstName: "",
            lastName: "",
            city: "",
            state: "",
            zip: "",
            providerNetworkAddress: "",
            organizationNetworkAddress: "",
            organizationName: "",
            providerPageStart: 1,
            organizationPageStart: 1,
            pageSize: 10
        };
    }

    function closeModal() {
        ctrl.modalInstance.dismiss("cancel");
    }

    function hasPhoneNumber(item) {
        const hasNumber = _.some(["phonePrimary", "fax"], function (n) {
            return !!_.trim(item[n]);
        });
        return hasNumber;
    }

    function searchSubmit() {
        ctrl.currentProviderPage = 1;
        ctrl.currentOrganizationPage = 1;
        ctrl.providers = [];
        ctrl.organizations = [];
        search();
    }

    function search(tab) {
        if (ctrl.query.zip && !(ctrl.query.zip.substr(-1) === "*")) ctrl.query.zip += "*";
        if (!ctrl.searchInProgress) {
            ctrl.searchInProgress = true;
            ctrl.query.providerPageStart = ctrl.currentProviderPage;
            ctrl.query.organizationPageStart = ctrl.currentOrganizationPage;

            SureScriptsService.searchDirectory(ctrl.query)
                .then((data) => {
                    if (tab === "provider" || !tab) {
                        ctrl.providers = ctrl.providers.concat(data.providers || []);
                    }
                    if (tab === "organization" || !tab) {
                        ctrl.organizations = ctrl.organizations.concat(data.organizations || []);
                    }
                    ctrl.totalProvidersCount = data.totalProvidersCount || 0;
                    ctrl.totalOrganizationsCount = data.totalOrganizationsCount || 0;
                })
                .finally(() => {
                    if (tab === "provider" || !tab) {
                        ctrl.currentProviderPage++;
                    }
                    if (tab === "organization" || !tab) {
                        ctrl.currentOrganizationPage++;
                    }
                    ctrl.searchInProgress = false;
                });
        }
    }

    function selectAddresses() {
        const selectedProviders = _.filter(ctrl.providers, { selected: true });
        const selectedOrganizations = _.filter(ctrl.organizations, { selected: true });
        const selectedResults = selectedProviders.concat(selectedOrganizations);
        const selectedAddresses = _.map(selectedResults, "directAddress");

        ctrl.modalInstance.close(selectedAddresses);
    }

    function disableAddressSelection() {
        return ctrl.searchInProgress || noAddressSelected();
    }

    function noAddressSelected() {
        const selectedProvider = _.find(ctrl.providers, { selected: true });
        const selectedOrganization = _.find(ctrl.organizations, { selected: true });
        return !selectedProvider && !selectedOrganization;
    }

    function openInvitationModal() {
        closeModal();
        $uibModal
            .open({
                templateUrl: accountDashboardUserInvitationModalTemplate,
                controller: "UserInvitationModalCtrl"
            })
            .result.then(
                () => {},
                () => {}
            );
    }
}
