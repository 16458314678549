(function () {
    "use strict";

    angular.module("kno2.directives").directive("k2NetworkFeatureToggle", featureToggle);

    featureToggle.$inject = ["$animate", "$compile", "NetworksService", "SessionService"];

    function featureToggle($animate, $compile, NetworksService, SessionService) {
        return {
            restrict: "A",
            $$tlb: true,
            link: async function ($scope, $element, $attr) {
                $element.hide();
                const requiredFeatures = $attr.k2NetworkFeatureToggle.split(",");
                const { administerOfNetworkObjectId, isNetworkAdmin } = SessionService.getProfile();

                if (!isNetworkAdmin) return;

                const features = await NetworksService.getNetworkFeatureStatuses(administerOfNetworkObjectId);
                const enabledFeatures = features
                    .flatMap((f) => [f, ...(f.childNetworkFeatures || [])])
                    .filter((f) => f.isEnabled)
                    .map((f) => f.name);

                const hasFeatures = requiredFeatures.every((f) => enabledFeatures.includes(f));
                if (hasFeatures) $element.show();
            }
        };
    }
})();
