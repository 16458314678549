import { Directive, ElementRef, HostListener, input } from "@angular/core";

type DateFormatDelimiter = "/" | "-" | ".";

@Directive({
    selector: "[kno2DateFormat]",
    standalone: true
})
export class DateFormatDirective {
    private readonly validDelimitersRegex = /[\/\-\.]/;
    public dateFormatDelimiter = input<DateFormatDelimiter>("/");

    constructor(private el: ElementRef) {}

    @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent) {
        if (!this.validDelimitersRegex.test(event.key)) return;

        const initialValue = this.el.nativeElement.value;

        let [month, day, year] = initialValue.split(this.validDelimitersRegex);
        if (month) month = `${month?.padStart(2, "0")}${this.dateFormatDelimiter()}`;
        if (day) day = `${day?.padStart(2, "0")}${this.dateFormatDelimiter()}`;

        this.el.nativeElement.value = [month, day, year].join("");

        if (initialValue !== this.el.nativeElement.value) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
}
