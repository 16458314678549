IdentityTermsAndConditionsModal.$inject = [
    "$scope",
    "$uibModalInstance",
    "EulasService",
    "NotificationService",
    "SessionService",
    "$window",
    "$state",
    "appConfig"
];

export function IdentityTermsAndConditionsModal($scope, $uibModalInstance, eulasService, notificationService, sessionService, $window, $state, appConfig) {
    $scope.brandDisplayName = appConfig.brandDisplayName;
    $scope.hasBrandEula = appConfig.hasBrandEula;
    $scope.title = "License Agreement";
    $scope.userProfile = sessionService.getProfile();
    $scope.acceptedTermsAndConditions = false;

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.printTermsAndConditions = function () {
        if ($scope.form.$valid) {
            var url = `policies/termsofuse?print=true`;
            $window.open(url, "_blank");
        }
    };

    $scope.acceptTermsAndConditions = function () {
        eulasService
            .accept($scope.eula.id)
            .then(function () {
                sessionService.refreshProfile();
                $uibModalInstance.close();
            })
            .catch(function () {
                notificationService.error("An error occurred when accepting License Agreement.");
            });
    };

    eulasService.getActive().then(function (response) {
        $scope.eula = response;
    });

    eulasService.getViewActive().then(function (response) {
        $scope.eulaHtml = response;
    });
}
