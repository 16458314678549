// Controllers that are shared across modules (admin, client, etc.)
angular
    .module("kno2.controllers")

    // TODO: This NotificationCtrl is the same as NotificationModalCtrl. It is intentional.
    // TODO: See the commments for NotificationModalCtrl to understand why.
    .controller("NotificationCtrl", [
        "$scope",
        "$rootScope",
        "NotificationService",
        function ($scope, $rootScope, NotificationService) {
            // Sticky Alerts: These are embedded into the form
            // Parameters:
            //  options: This can be a string or an object. If it is an object,
            //  then provide object.messageTitle and an array of object.messages;
            // Usage:
            //  NotificationService.error("this is an error message");
            //  NotificationService.error(errorOptions);
            NotificationService.showAlertOnPage = function (options) {
                // options can be either a String or an Object.
                // If it is an Object, it will have a messageTitle and an array of messages.
                // If the array of messages only contains 1 item, then use it as the messageTitle
                // and forgo the array of separate alerts.
                var msgTitle;

                if (options.messageTitle) {
                    msgTitle = options.messageTitle;
                }

                if (!msgTitle && options.messages && options.messages.length === 1) {
                    msgTitle = options.messages[0];
                    options.messages = undefined; // null these so they do not show up
                }

                $scope.messageTitle = msgTitle ? msgTitle : options;

                $scope.titleCaption = options.caption || "Oh no!";
                $scope.level = options.level || "danger";
                $scope.showClose = true;

                $scope.messages = options.messages;

                hideThrobber();
                showAlert();
            };

            NotificationService.hideAlertOnPage = function () {
                hideAlert();
            };

            $scope.close = function () {
                hideAlert();
            };

            var showAlert = function () {
                $(".notification-alert").removeClass("ng-hide");
                $(".notification-alert").fadeIn(200);
                $rootScope.$emit("notificationAlertVisible");
            };

            var hideAlert = function () {
                $scope.$emit("editInPlaceOnSuccess", "hideAlert");

                $(".notification-alert").fadeOut(200);
                $rootScope.$emit("notificationAlertHidden");
            };

            var hideThrobber = function () {
                // Hide the THROBBER from plainsite.
                // The throbber will appear when loading, and during an error
                // nothing removes it, so hide it.
                $("div.loading").hide();
            };

            // Non-sticky Alerts: These appear at the top center of the page.
            // These are not embedded, but overlay the page header.
            NotificationService.notifySuccess = function (message) {
                notify("success", message);
            };

            NotificationService.notifyInfo = function (message) {
                notify("information", message);
            };

            NotificationService.notifyWarning = function (message) {
                notify("warning", message);
            };

            NotificationService.notifyError = function (message) {
                notify("error", message);
            };

            var notify = function (type, message) {
                hideThrobber();

                var opts = {
                    text: message,
                    layout: "topCenter",
                    type: type,
                    timeout: 5000,
                    //timeout: false,
                    maxVisible: 1,
                    killer: true,
                    closeWith: ["click"],
                    animation: {
                        open: { height: "toggle" },
                        close: { height: "toggle" },
                        easing: "swing",
                        speed: 200 // opening & closing animation speed
                    }
                };

                if (angular.element(".notification-alert").css("display") != "none") {
                    // hide the alert window because we are showing a lesser severity notificatino
                    hideAlert();
                }
                noty(opts);
            };
        }
    ])

    // TODO: This NotificationModalCtrl is a duplicate of NotificationCtrl. It is a duplicate
    // TODO: because there was a bug hours before the HIMSS 2014 conference and this was the fastes solution.
    // TODO: The problem was in this instance of the bug, we needed to have a NotificationCtrl for the page
    // TODO: and a NoficationCtrl of the modal window in the page. These two instances conflicted and caused
    // TODO: each other to fail. There should never be a third instance, on page and modal. This was our $2 solution.
    // TODO: The page with the problem was release.message.attachments.
    .controller("NotificationModalCtrl", [
        "$scope",
        "NotificationModalService",
        function ($scope, NotificationModalService) {
            // Sticky Alerts: These are embedded into the form
            // Parameters:
            //  options: This can be a string or an object. If it is an object,
            //  then provide object.messageTitle and an array of object.messages;
            // Usage:
            //  NotificationService.error("this is an error message");
            //  NotificationService.error(errorOptions);
            NotificationModalService.showAlertOnPage = function (options) {
                // options can be either a String or an Object.
                // If it is an Object, it will have a messageTitle and an array of messages.
                // If the array of messages only contains 1 item, then use it as the messageTitle
                // and forgo the array of separate alerts.
                var msgTitle = null;

                if (options.messageTitle) {
                    msgTitle = options.messageTitle;
                }

                if (!msgTitle && options.messages && options.messages.length === 1) {
                    msgTitle = options.messages[0];
                    options.messages = undefined; // null these so they do not show up
                }

                $scope.messageTitle = msgTitle || options;

                $scope.titleCaption = options.caption || "Oh no!";
                $scope.level = options.level || "danger";
                $scope.showClose = false;

                $scope.messages = options.messages;

                hideThrobber();
                showAlert();
            };

            NotificationModalService.hideAlertOnPage = function () {
                hideAlert();
            };

            $scope.close = function () {
                hideAlert();
            };

            var showAlert = function () {
                if ($(".notification-modal-alert").hasClass("ng-hide")) {
                    $(".notification-modal-alert").removeClass("ng-hide");
                }
                $(".notification-modal-alert").fadeIn(200);
            };

            var hideAlert = function () {
                $scope.$emit("editInPlaceOnSuccess", "hideAlert");

                $(".notification-modal-alert").fadeOut(200);
            };

            var hideThrobber = function () {
                // Hide the THROBBER from plainsite.
                // The throbber will appear when loading, and during an error
                // nothing removes it, so hide it.
                $("div.loading").hide();
            };

            // Non-sticky Alerts: These appear at the top center of the page.
            // These are not embedded, but overlay the page header.
            NotificationModalService.notifySuccess = function (message) {
                notify("success", message);
            };

            NotificationModalService.notifyInfo = function (message) {
                notify("information", message);
            };

            NotificationModalService.notifyWarning = function (message) {
                notify("warning", message);
            };

            var notify = function (type, message) {
                hideThrobber();

                var opts = {
                    text: message,
                    layout: "topCenter",
                    type: type,
                    timeout: 5000,
                    //timeout: false,
                    maxVisible: 1,
                    killer: true,
                    closeWith: ["click"],
                    animation: {
                        open: { height: "toggle" },
                        close: { height: "toggle" },
                        easing: "swing",
                        speed: 200 // opening & closing animation speed
                    }
                };

                if (angular.element(".notification-modal-alert").css("display") != "none") {
                    // hide the alert window because we are showing a lesser severity notificatino
                    hideAlert();
                }
                noty(opts);
            };
        }
    ]);
