import { NetworkUserGroupListItemResponse, NetworkUserGroupsService } from "App/src/common/services/network-user-groups.service";
import { NetworkOrganizationListItemResponse, NetworksService } from "App/src/common/services/networks.service";
import { IComponentOptions, IFormController, IRootScopeService } from "angular";

interface OrganizationUserGroupTableData {
    organizationName: string;
    organizaitonId: number;
    userGroupName: string;
    userGroupId: number;
    userGroupRole: string;
    numberOfUsers: number;
    assignmentCreatedAt: string;
}

interface NetworkAdminUserGroupData {
    userGroupId: number;
    userGroupName: string;
    numberOfUsers: number;
}

class NetworkIdentityProviderUserGroupsController {
    public static $inject: Array<string> = [
        "$rootScope",
        "$confirm",
        "$uibModal",
        "NotificationService",
        "SessionService",
        "NetworkUserGroupsService",
        "NetworksService"
    ];

    public isLoading: boolean = false;
    public organizations: NetworkOrganizationListItemResponse[] = [];

    public userGroups: NetworkUserGroupListItemResponse[] = [];
    public candidateNetworkAdminGroups: NetworkAdminUserGroupData[] = [];
    private candidateOrganizationGroups: NetworkUserGroupListItemResponse[] = [];

    public networkAdminGroup: NetworkAdminUserGroupData | null;
    public organizationGroupMapSearch: string | null = "";
    public organizationGroupMap = {
        userGroup: null as NetworkUserGroupListItemResponse | null,
        role: "User",
        organization: null as NetworkOrganizationListItemResponse | null,
        selectAllOrganizations: false as boolean
    };

    public availableOrganizationRoles: string[] = ["Administrator", "User"];

    public networkAdminUserGroupTableData: NetworkAdminUserGroupData[] = [];
    public organizationUserGroupTableData: OrganizationUserGroupTableData[] = [];

    constructor(
        private $rootScope: IRootScopeService,
        private $confirm: any,
        private $uibModal: any,
        private notificationService: any,
        private sessionService: any,
        private networkUserGroupsService: NetworkUserGroupsService,
        private networkService: NetworksService
    ) {}

    public $onInit() {
        let networkId = this.sessionService.getProfile().administerOfNetworkObjectId;
        this.networkService
            .getNetworkOrganizations(networkId)
            .then((orgs) => (this.organizations = orgs))
            .then(() => this.refreshUserGroups());
    }

    public addNetworkAdminGroup(form: IFormController) {
        this.isLoading = true;

        this.networkUserGroupsService
            .updateUserGroup(this.networkAdminGroup.userGroupId, { role: "NetworkAdmin", organizationIds: [] })
            .then((updated) => this.updateUserGroups(updated))
            .then(() => {
                this.networkAdminGroup = null;
                this.notificationService.success("The association was created successfully.");
                form.$setPristine();
            })
            .catch((error) => {
                this.notificationService.errorToaster(error);
                throw error;
            })
            .finally(() => (this.isLoading = false));
    }

    public removeNetworkAdminGroup(row: NetworkAdminUserGroupData) {
        this.$confirm
            .open({
                bodyText: `You are about to remove the <b>${row.userGroupName}</b> as a network admin group. Are you sure?`,
                okText: "Yes"
            })
            .result.then(() => {
                this.isLoading = true;

                this.networkUserGroupsService
                    .updateUserGroup(row.userGroupId, { role: "User", organizationIds: [] })
                    .then((updated) => this.updateUserGroups(updated))
                    .then(() => this.notificationService.success("The association was removed successfully."))
                    .catch((error) => {
                        this.notificationService.errorToaster(error);
                        throw error;
                    })
                    .finally(() => (this.isLoading = false));
            });
    }

    public getAvailableOrganizationsForUserGroup(userGroup: NetworkUserGroupListItemResponse) {
        return this.organizations.filter((o) => !userGroup.organizations.some((ugo) => ugo.organizationId === o.id));
    }

    public groupChange(userGroup: NetworkUserGroupListItemResponse): void {
        if (this.isAssociatedToAnyOrganization(userGroup)) {
            this.organizationGroupMap.role = userGroup.role;
        }

        if (
            !this.organizationGroupMap.organization ||
            this.organizationGroupMap.userGroup.organizations.some((o) => o.organizationId === this.organizationGroupMap.organization.id)
        ) {
            this.organizationGroupMap.organization = null;
        }
    }

    public isOrganizationRoleSelectionDisabledForUserGroup(userGroup: NetworkUserGroupListItemResponse): boolean {
        return this.isAssociatedToAnyOrganization(userGroup);
    }

    public mapGroupToOrganization(form: IFormController) {
        const groupExistingOrganizationIds = this.userGroups
            .find((g) => g.id === this.organizationGroupMap.userGroup.id)
            .organizations.map((o) => o.organizationId);

        const groupOrganizationToBeAddedIds = this.organizationGroupMap.selectAllOrganizations
            ? this.getAvailableOrganizationsForUserGroup(this.organizationGroupMap.userGroup).map((o) => o.id)
            : [this.organizationGroupMap.organization.id];

        const groupFinalListOfOrganizationIds = groupOrganizationToBeAddedIds.concat(groupExistingOrganizationIds);

        if (this.shouldDisplayGroupOrganizationConfirmationMessage(groupOrganizationToBeAddedIds.length)) {
            this.$confirm
                .open({
                    bodyText: `Given the number of organizations being mapped, this action may take longer than usual to save. Would you like to continue?`,
                    okText: "Yes"
                })
                .result.then(
                    () => this.confirmMapGroupToOrganization(form, groupFinalListOfOrganizationIds),
                    () => this.$rootScope.$broadcast("loading-complete")
                );
        } else {
            this.confirmMapGroupToOrganization(form, groupFinalListOfOrganizationIds);
        }
    }

    private confirmMapGroupToOrganization(form: IFormController, groupFinalListOfOrganizationIds: number[]) {
        this.isLoading = true;

        this.networkUserGroupsService
            .updateUserGroup(this.organizationGroupMap.userGroup.id, {
                organizationIds: groupFinalListOfOrganizationIds,
                role: this.organizationGroupMap.role
            })
            .then((updated) => this.updateUserGroups(updated))
            .then(() => {
                this.organizationGroupMap = { userGroup: null, role: "User", organization: null, selectAllOrganizations: false };
                this.notificationService.success("The association was created successfully.");
                form.$setPristine();
            })
            .catch((error) => {
                this.notificationService.errorToaster(error);
                throw error;
            })
            .finally(() => (this.isLoading = false));
    }

    public removeOrganizationFromUserGroup(row: OrganizationUserGroupTableData) {
        this.$confirm
            .open({
                bodyText: `You are about to remove the group mapping for <b>${row.organizationName}</b>. Are you sure?`,
                okText: "Yes"
            })
            .result.then(() => {
                this.isLoading = true;

                let newOrganizations = this.userGroups
                    .find((f) => f.id === row.userGroupId)
                    .organizations.filter((f) => f.organizationId !== row.organizaitonId)
                    .map((m) => m.organizationId);

                this.networkUserGroupsService
                    .updateUserGroup(row.userGroupId, { organizationIds: newOrganizations, role: row.userGroupRole })
                    .then((updated) => this.updateUserGroups(updated))
                    .then(() => this.notificationService.success("The association was removed successfully."))
                    .catch((error) => {
                        this.notificationService.errorToaster(error);
                        throw error;
                    })
                    .finally(() => (this.isLoading = false));
            });
    }

    public showUserGroupDetailsModal(row: OrganizationUserGroupTableData) {
        this.$uibModal
            .open({
                component: "networkIdentityProviderUserGroupDetailModal",
                resolve: {
                    userGroupId: function () {
                        return row.userGroupId;
                    }
                }
            })
            .result.then(
                (data) => {},
                (err) => {}
            );
    }

    public changeAllorganizationsSelection(): void {
        this.organizationGroupMap.organization = null;
    }

    private refreshUserGroups() {
        this.isLoading = true;

        this.networkUserGroupsService
            .getUserGroups()
            .then((userGroups) => this.distributeGroups(userGroups))
            .finally(() => (this.isLoading = false));
    }

    private updateUserGroups(toBeUpdated: NetworkUserGroupListItemResponse): void {
        this.distributeGroups(this.userGroups.map((ug) => (ug.id === toBeUpdated.id ? toBeUpdated : ug)));
    }

    private distributeGroups(userGroups: NetworkUserGroupListItemResponse[]): void {
        this.userGroups = userGroups;

        this.clearGroupLists();

        this.userGroups.forEach((ug) => {
            this.addToCandidateNetworkAdminGroupsIfApplies(ug);
            this.addToCandidateOrganizationGroupsIfApplies(ug);
            this.addToNetworkAdminGroupTableDataIfApplies(ug);
            this.addToOrganizationGroupTableData(ug);
        });
    }

    private clearGroupLists(): void {
        this.candidateNetworkAdminGroups = [];
        this.candidateOrganizationGroups = [];
        this.networkAdminUserGroupTableData = [];
        this.organizationUserGroupTableData = [];
    }

    private addToCandidateNetworkAdminGroupsIfApplies(userGroup: NetworkUserGroupListItemResponse): void {
        if (!this.isNetworkAdminGroup(userGroup) && !this.isAssociatedToAnyOrganization(userGroup)) {
            this.candidateNetworkAdminGroups.push({
                userGroupId: userGroup.id,
                userGroupName: userGroup.name,
                numberOfUsers: userGroup.numberOfUsers
            });
        }
    }

    private addToCandidateOrganizationGroupsIfApplies(userGroup: NetworkUserGroupListItemResponse): void {
        if (!this.isNetworkAdminGroup(userGroup) && this.areThereOrganizationsAvailableForGroup(userGroup)) {
            this.candidateOrganizationGroups.push(userGroup);
        }
    }

    private addToNetworkAdminGroupTableDataIfApplies(userGroup: NetworkUserGroupListItemResponse): void {
        if (this.isNetworkAdminGroup(userGroup)) {
            this.networkAdminUserGroupTableData.push({
                userGroupId: userGroup.id,
                userGroupName: userGroup.name,
                numberOfUsers: userGroup.numberOfUsers
            });
        }
    }

    private addToOrganizationGroupTableData(userGroup: NetworkUserGroupListItemResponse): void {
        userGroup.organizations.forEach((organization) => {
            this.organizationUserGroupTableData.push({
                organizaitonId: organization.organizationId,
                organizationName: organization.organizationName,
                userGroupId: userGroup.id,
                userGroupName: userGroup.name,
                userGroupRole: userGroup.role,
                numberOfUsers: userGroup.numberOfUsers,
                assignmentCreatedAt: organization.assignmentCreatedAt
            });
        });

        this.organizationUserGroupTableData = this.organizationUserGroupTableData.sort(
            (a, b) => new Date(b.assignmentCreatedAt).getTime() - new Date(a.assignmentCreatedAt).getTime()
        );
    }

    private shouldDisplayGroupOrganizationConfirmationMessage = (organizationsToAddToGroup: number): boolean => organizationsToAddToGroup > 50;

    private areThereOrganizationsAvailableForGroup = (userGroup: NetworkUserGroupListItemResponse) =>
        userGroup?.organizations.length < this.organizations.length;

    private isNetworkAdminGroup = (userGroup: NetworkUserGroupListItemResponse): boolean => userGroup?.role === "NetworkAdmin";

    private isAssociatedToAnyOrganization = (userGroup: NetworkUserGroupListItemResponse): boolean => userGroup?.organizations.length > 0;
}

export const networkIdentityProviderUserGroupsComponent: IComponentOptions = {
    templateUrl: require("./network-identity-provider-user-groups.html"),
    controller: NetworkIdentityProviderUserGroupsController
};
