import { IWindowService } from "angular";
import { IAppConfig } from "../app-config";

interface IntercomFunction extends Function {
    (command: string, ...args: any[]): void;
    q: any[];
    c: (...args: any[]) => void;
}

export class IntercomService {
    public static $inject = ["$window", "SessionService", "appConfig"];

    constructor(
        private $window: IWindowService,
        private SessionService: any,
        private appConfig: IAppConfig
    ) {}

    public boot() {
        const profile = this.SessionService.getProfile();
        const intercomAppId = profile.intercomAppId || this.appConfig.intercomAppId;
        if (!this.$window.Intercom && !!intercomAppId) {
            this.initializeWidget(intercomAppId);
        }

        if (!!profile.intercomAppId) {
            this.$window.Intercom("boot", {
                app_id: intercomAppId,
                user_id: profile.userId
            });
        }
    }

    private initializeWidget(appId: string): void {
        if (!appId) {
            console.debug(`${this.constructor.name}: No appId provided, service is disabled.`);
            return;
        } else {
            console.debug(`${this.constructor.name}: Initializing Intercom widget with appId:`, appId);
        }

        if (typeof this.$window.Intercom === "function") {
            this.$window.Intercom("reattach_activator");
            this.$window.Intercom("update", this.$window.intercomSettings);
        } else {
            const intercomStub = ((...args: any[]): void => {
                intercomStub.c(args);
            }) as IntercomFunction;
            intercomStub.q = [];
            intercomStub.c = (args: any[]): void => {
                intercomStub.q.push(args);
            };
            this.$window.Intercom = intercomStub;

            const loadIntercomScript = () => {
                const scriptElement = this.$window.document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.async = true;
                scriptElement.src = `https://widget.intercom.io/widget/${appId}`;
                const firstScript = this.$window.document.getElementsByTagName("script")[0];
                firstScript.parentNode.insertBefore(scriptElement, firstScript);
            };

            if (this.$window.document.readyState === "complete") {
                loadIntercomScript();
            } else {
                this.$window.addEventListener("load", loadIntercomScript, false);
            }
        }
    }
}
