angular.module("kno2.factories").factory("MessageFactory", [
    "InteroperabilityService",
    "$timeout",
    (InteroperabilityService, $timeout) => {
        var pageState = {};
        return {
            initializeDateTypeSelections: initializeDateTypeSelections,
            documentDateTypeChanged: documentDateTypeChanged,
            init: init,
            populateMessageBodyFromScope: populateMessageBodyFromScope,
            isAttachmentReadOnlyMode: isAttachmentReadOnlyMode,
            isStructuredDocument: isStructuredDocument,
            validate: validate
        };

        function isStructuredDocument(_attachment) {
            var fileName = _attachment.fileName;
            var ext = fileName.substring(fileName.lastIndexOf(".") + 1).toLocaleLowerCase();
            // Structured documentS can't have its meta data updated (IKW-429)
            if (ext == "hl7" || ext === "ccd" || ext === "cda" || ext === "xml") {
                return true;
            }
            return false;
        }

        function isAttachmentReadOnlyMode(attachment, pageState) {
            if (pageState) {
                switch (pageState.messageStrategy) {
                    case "new":
                    case "forward":
                        return false;
                        break;

                    case "release":
                        return true;
                        break;

                    case "intake":
                        if (!pageState.attachments.enabled) return true;
                        if (attachment.isSelected) return false;
                        return true;
                        break;
                }
            }

            if (attachment && attachment.fileName && isStructuredDocument(attachment)) {
                return true;
            }

            return false;
        }

        function populateMessageBodyFromScope($scope) {
            var patientIdNotPopulated = typeof $scope.patient.patientId == "undefined" || $scope.patient.patientId == null;
            var patientId = patientIdNotPopulated ? "" : $scope.patient.patientId;
            var birthDate = $scope.patient.birthDate ? moment($scope.patient.birthDate).format("MM/DD/YYYY") : "";
            var addressLines = [];
            var lastAddressLine = [];

            if ($scope.patient.streetAddress1) addressLines.push($scope.patient.streetAddress1);
            if ($scope.patient.streetAddress2) addressLines.push($scope.patient.streetAddress2);
            if ($scope.patient.city) lastAddressLine.push($scope.patient.city + ($scope.patient.state ? "," : ""));
            if ($scope.patient.state) lastAddressLine.push($scope.patient.state);
            if ($scope.patient.postalCode) lastAddressLine.push($scope.patient.postalCode);
            if (lastAddressLine.length) addressLines.push(lastAddressLine.join(" "));

            var lines = [
                "Patient ID: " + patientId,
                "Patient Name: " + $scope.patient.fullName,
                "Gender: " + $scope.patient.gender,
                "DOB: " + birthDate,
                "Address: " + addressLines.join("\n"),
                "Phone: " + `${$scope.patient.telephone || ""}`
            ];

            var messageBody = lines.join("\n");

            $scope.message.body = messageBody;
        }

        function initializeDateTypeSelections(attachments) {
            _.each(attachments, function (item) {
                if (!item.attachmentMeta) {
                    item.attachmentMeta = {};
                }
                item.attachmentMeta.documentDateType = "";
                if (!item.attachmentMeta.documentDate) {
                    item.attachmentMeta.documentDate = "";
                }
            });
        }

        function documentDateTypeChanged(index, attachments, documentDate) {
            if (index >= 0) {
                var attachmentId = attachments[index].id;
                var documentDateType = attachments[index].attachmentMeta.documentDateType;
                // Initialized attachmentMeta object if null
                if (!attachments[index].attachmentMeta) {
                    attachments[index].attachmentMeta = {};
                }
                switch (documentDateType) {
                    case "today":
                        var now = moment().utc().toDate();
                        attachments[index].attachmentMeta.documentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
                        break;
                    case "message":
                        attachments[index].attachmentMeta.documentDate = documentDate;
                        break;
                    case "selected":
                        attachments[index].attachmentMeta.documentDate = null;
                        $timeout(function () {
                            $("#document-date-" + attachmentId)
                                .focus()
                                .click();
                        });
                        break;
                }
            }
        }

        function init($scope, messageStrategy) {
            // Read Interoperability Settings for validations (just for Intake MVP)
            pageState.messageStrategy = messageStrategy;
            $scope.messageStrategyMode = messageStrategy;

            // patient: Enable all - Require Patient ID, First Name, last Name, DOB, Gender
            pageState.patient = { enabled: true };
            pageState.patient.patientId = { enabled: true };
            pageState.patient.firstName = { enabled: true };
            pageState.patient.middleName = { enabled: true };
            pageState.patient.lastName = { enabled: true };
            pageState.patient.birthDate = { enabled: true };
            pageState.patient.gender = { enabled: true };
            pageState.patient.streetAddress1 = { enabled: true };
            pageState.patient.streetAddress2 = { enabled: true };
            pageState.patient.city = { enabled: true };
            pageState.patient.state = { enabled: true };
            pageState.patient.postalCode = { enabled: true };
            pageState.patient.telephone = { enabled: true };

            // Attachments enabled
            // Attachments: Require attachment information of Doc Type and Doc Date for attachments that are “checked”
            // (or designated for export). Document comments are never required. Enable attachments, as the view will
            // call a method to check if all controls in the attachment are enabled.
            pageState.attachments = { enabled: true };
            pageState.attachments.upload = { enabled: true };

            switch (messageStrategy) {
                case "intake":
                    InteroperabilityService.get().then(function (response) {
                        $scope.interoperabilitySettings = response.data;

                        // hideVisitDetails
                        $scope.isVisitDetailsReadonly =
                            $scope.interoperabilitySettings.exportDirectoryType === "single_directory" ||
                            $scope.interoperabilitySettings.exportMode === "print" ||
                            $scope.interoperabilitySettings.exportOption === "xds-sd_cda" ||
                            ($scope.interoperabilitySettings.exportMode === "save_as" &&
                                $scope.interoperabilitySettings.exportOption === "standard" &&
                                $scope.interoperabilitySettings.exportDirectoryType === "patient_folder" &&
                                !$scope.interoperabilitySettings.exportVisitDirectory);

                        // Visit details - disable by default
                        pageState.patient.visitId = { enabled: false };
                        pageState.patient.visitDate = { enabled: false };
                        $scope.interoperabilitySettings.isPrintExportMode = $scope.interoperabilitySettings.exportMode === "print";
                        if ($scope.interoperabilitySettings.isPrintExportMode) {
                            // patient: patient details, visit details are disabled. These fields are still there, just greyed out.
                            pageState.patient.patientId = { enabled: false };
                            pageState.patient.firstName = { enabled: false };
                            pageState.patient.middleName = { enabled: false };
                            pageState.patient.lastName = { enabled: false };
                            pageState.patient.birthDate = { enabled: false };
                            pageState.patient.gender = { enabled: false };

                            // Attachments, we will grey out Doc type, date, and notes, and confidentiality.
                            // They can still select the checkbox so they can select to print.
                            pageState.attachments = { enabled: false };
                        } else if (
                            $scope.interoperabilitySettings.exportMode == "save_as" &&
                            $scope.interoperabilitySettings.exportOption == "standard" &&
                            $scope.interoperabilitySettings.exportDirectoryType == "patient_folder" &&
                            $scope.interoperabilitySettings.exportVisitDirectory
                        ) {
                            if (!$scope.isVisitDetailsReadonly) {
                                pageState.patient.visitId = { enabled: true };
                                pageState.patient.visitDate = { enabled: true };
                            }
                        }

                        pageState.attachments.upload = { enabled: false };
                    });
                    $scope.pageState = pageState;

                    break;

                //case 'new':
                case "forward":
                case "reply":
                    pageState.patient.visitId = { enabled: true };
                    pageState.patient.visitDate = { enabled: true };
                    $scope.pageState = pageState;

                    break;

                case "release":
                    InteroperabilityService.get().then(function (response) {
                        $scope.interoperabilitySettings = response.data;
                    });
                    pageState.patient = { enabled: false };
                    pageState.attachments.upload = { enabled: false };
                    $scope.pageState = pageState;

                    break;

                default:
                    $scope.pageState = pageState;

                    break;
            }
        }

        /* validate:
                messageStrategy
                    intake = Initated from Intake
                    new = Initiated from Release (New)
                    forward = Initiated from Release (Forward)
                    reply = Initiated from Release (Reply)
        */
        function validate($scope, errors, messageStrategy) {
            if (!$scope.readonlyMode) {
                var patientReq =
                    messageStrategy === "new" || // All required on New Release (as previous).
                    (messageStrategy === "intake" &&
                        !$scope.interoperabilitySettings.nonPatientExport &&
                        ($scope.interoperabilitySettings.exportOption === "xds-sd_cda" ||
                            $scope.interoperabilitySettings.exportDirectoryType === "single_directory" ||
                            $scope.interoperabilitySettings.exportDirectoryType === "patient_folder"));

                // Patient Info validations
                if (patientReq) {
                    if (_.isEmpty($scope.patient)) {
                        errors.push('"Patient Details" information is required.');
                    } else {
                        if (_.isEmpty($scope.patient.firstName)) {
                            errors.push('"Patient First Name" is required.');
                        }
                        if (_.isEmpty($scope.patient.lastName)) {
                            errors.push('"Patient Last Name" is required.');
                        }
                        if (!$scope.patient.birthDate && messageStrategy !== "intake") {
                            errors.push('"Patient Birth Date" is required.');
                        }
                        if (_.isEmpty($scope.patient.gender) && messageStrategy !== "intake") {
                            errors.push('"Patient Gender" is required.');
                        }
                    }
                }
                // If patient birth date is specified and not readonly, must be less than today.
                if ($scope.patient.birthDate && $scope.patient.birthDate > moment().toDate()) {
                    errors.push('"Patient Birth Date" must be equal or less than today.');
                }

                if ($scope.patient.birthDate && !moment($scope.patient.birthDate).isValid()) {
                    errors.push('"Patient Birth Date" is invalid.');
                }

                // Validate Visit Date
                if ($scope.message.origin === "Surescripts" && $scope.pageState.patient.visitDate.enabled) {
                    if ($scope.patient.visitDate && $scope.patient.visitDate > moment().toDate()) {
                        errors.push('"Patient Visit Date" must be equal or less than today.');
                    } else {
                        if (typeof $scope.patient.visitDate == "undefined") errors.push('"Patient Visit Date" is invalid.');
                    }
                }
            }

            // Attachments validations
            _.each($scope.attachments, function (_attachment, key) {
                var isSelected = _attachment.isSelected || _attachment.isSelected == undefined; // If not defined it must be selected.
                if (isSelected) {
                    var nonPatientExport = $scope.interoperabilitySettings && $scope.interoperabilitySettings.nonPatientExport;
                    if (!_attachment.attachmentMeta || !_attachment.attachmentMeta.documentType) {
                        if (($scope.message.attachments2CDA || $scope.message.conversion === "Cda" || messageStrategy === "intake") && !nonPatientExport) {
                            errors.push('"Document Type" is required for file "' + _attachment.fileName + '"');
                        }
                    }
                    if (!_attachment.attachmentMeta || !_attachment.attachmentMeta.documentDate) {
                        errors.push('"Document Date" is required for file "' + _attachment.fileName + '"');
                    } else {
                        if (_attachment.attachmentMeta.documentDate && _attachment.attachmentMeta.documentDate > moment().toDate()) {
                            errors.push('"Document Date" must be equal or less than today for file "' + _attachment.fileName + '"');
                        }
                    }
                    // Confidentiality flag validation.
                    if (
                        (messageStrategy == "new" || messageStrategy == "forward") &&
                        (!_attachment.attachmentMeta || typeof _attachment.attachmentMeta.confidentiality == "undefined")
                    ) {
                        errors.push('"Confidentiality is required for file "' + _attachment.fileName + '"');
                    }
                }
            });
        }
    }
]);
