import { AdminBatchImportsService } from "../admin.batchimport.service";

let ctrl;
class AdminBatchImportsStatusController {
    public static $inject: Array<string> = ["AdminBatchImportsService"];

    public selectedTab = "status";
    public batchObjects: Array<any>;

    public data: any;

    constructor(private adminBatchImportsService: AdminBatchImportsService) {}

    public $onInit(): void {
        ctrl = this;
        this.batchObjects = [];
        this.getBatches();
    }

    public getBatches(): void {
        ctrl.adminBatchImportsService.getBatchStatus().then((response) => {
            let items = [],
                itemList = response.data.items;

            for (let bItem = 0; bItem < itemList.length; bItem++) {
                items.push({
                    itemId: itemList[bItem].id,
                    itemType: itemList[bItem].batchType,
                    itemStatus: itemList[bItem].itemStatus,
                    itemError: itemList[bItem].itemError,
                    itemKey: itemList[bItem].key,
                    file: itemList[bItem].fileName,
                    parentStatus: itemList[bItem].batchStatus,
                    pcStatus: itemList[bItem].primaryContactStatus,
                    pcsError: itemList[bItem].pcsErrors
                });
            }

            ctrl.batchObjects = items;
        });
    }
}

class AdminBatchImportsStatusComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.batchimport-status.component.html");
        this.controller = AdminBatchImportsStatusController;
    }
}

export const adminBatchImportsStatusComponent = new AdminBatchImportsStatusComponent();
