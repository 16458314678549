ServiceAccountService.$inject = ["$http"];

export function ServiceAccountService($http) {
    return {
        getServiceAccount: getServiceAccount,
        addOrUpdateServiceAccount: addOrUpdateServiceAccount,
        updateIpAddresses: updateIpAddresses,
        toggleStatus: toggleStatus,
        deleteServiceAccount: deleteServiceAccount,
        getServiceAccount: getServiceAccount
    };

    function getServiceAccount(groupId) {
        return $http.get(`/api/networks/${groupId}/serviceaccounts`).then(function (response) {
            return response.data;
        });
    }

    function addOrUpdateServiceAccount(groupId) {
        return $http.put(`/api/networks/${groupId}/serviceaccounts`).then(function (response) {
            return response.data;
        });
    }

    function updateIpAddresses(groupId, addresses) {
        return $http.put(`/api/networks/${groupId}/serviceaccounts/ipaddresses`, addresses).then(function (response) {
            return response.data;
        });
    }

    function toggleStatus(groupId, state) {
        return $http.put(`/api/networks/${groupId}/serviceaccounts/${state}`).then(function (response) {
            return response.data;
        });
    }

    function deleteServiceAccount(groupId) {
        return $http.delete(`/api/networks/${groupId}/serviceaccounts`);
    }
}
