angular.module("kno2.filters").filter("ssn", function () {
    var ssnRegex = /^\d{3}-\d{2}-\d{4}$/;

    var formatSSN = function (ssn) {
        var segOne = ssn.slice(0, 3);
        var segTwo = ssn.slice(3, 5);
        var segThree = ssn.slice(5, 9);
        return segOne + "-" + segTwo + "-" + segThree;
    };

    return function (value) {
        var ssn = $.trim(value);
        return ssnRegex.test(ssn) ? formatSSN(ssn) : "";
    };
});
