import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

@Injectable({
    providedIn: "root"
})
export class ApplicationInsightsService {
    private readonly router = inject(Router);
    private appInsights: ApplicationInsights;

    public load(instrumentationKey: string): void {
        if (!instrumentationKey) {
            console.log(`ApplicationInsightsService: No instrumentation key provided. ApplicationInsights will not be initialized.`);
            return;
        }
        const angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router, errorServices: [new ErrorHandler()] }
                }
            }
        });
        this.appInsights.loadAppInsights();
    }

    public setContextUserId(userId: string): void {
        if (!this.appInsights) return;
        this.appInsights.context.user.id = userId;
    }

    public setAppInsightsCustomFields(customFields: Record<string, any>): void {
        if (!this.appInsights) return;
        const telemetryInitializer = (envelope) => {
            for (const key in customFields) {
                envelope.data[key] = customFields[key];
            }
        };
        this.appInsights?.addTelemetryInitializer(telemetryInitializer);
    }
}
