UtilsService.$inject = ["$http"];

export function UtilsService($http) {
    return {
        get,
        getFromWorkflow,
        mailMessageObjectIdsSearch,
        decodeObjectIds
    };

    function get(messageId) {
        return $http.get(`/api/utilities?id=${messageId}`).then(function (response) {
            return response.data;
        });
    }

    function mailMessageObjectIdsSearch(objectIds) {
        return $http.post(`/api/utilities/mailmessages/objectids`, objectIds).then(function (response) {
            return response.data;
        });
    }

    function decodeObjectIds(objectIds) {
        return $http.post(`/api/utilities/objectids`, objectIds).then(function (response) {
            return response.data;
        });
    }

    function getFromWorkflow(workflowId) {
        return $http.get(`/api/utilities?id=${workflowId}`).then(function (response) {
            return response.data;
        });
    }
}

