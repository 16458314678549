import adminResellersEditModalTemplate from "./admin.resellers.edit-modal.html";
import adminResellersDeleteModalTemplate from "./admin.resellers.delete-modal.html";

AdminResellersCtrl.$inject = ["$scope", "$timeout", "$uibModal", "AdminResellersService", "PagingFactory", "CommonData"];

export function AdminResellersCtrl($scope, $timeout, $uibModal, AdminResellersService, PagingFactory, CommonData) {
    // Sort
    $scope.sort = function () {
        $scope.isSortDesc = !$scope.isSortDesc;
        $scope.sortIconClass = $scope.isSortDesc ? "fa fa-chevron-down" : "fa fa-chevron-up";
        getResellerDtos($scope.currentPage, $scope.pagedItemRange.value);
    };

    // Paging
    $scope.gotoPage = function (page) {
        if (page < 1) page = 1;
        else if (page > $scope.maxPage) page = $scope.maxPage;

        $scope.currentPage = page;
        getResellerDtos($scope.currentPage, $scope.pagedItemRange.value);
    };

    // Update Paging Count
    $scope.updatePagedItemCount = function () {
        $scope.pagedItemCount = AdminResellersService.getPagedItemsSelectValue();
        getResellerDtos(1, $scope.pagedItemRange.value);
    };

    // Edit
    $scope.openModal = function (resellerId) {
        var modalInstance = $uibModal.open({
            windowClass: "resellers-edit-modal",
            templateUrl: adminResellersEditModalTemplate,
            controller: "ResellersEditCtrl",
            resolve: {
                reseller: [
                    "AdminResellersService",
                    function (AdminResellersService) {
                        if (resellerId) return AdminResellersService.getResellerDto(resellerId);

                        return AdminResellersService.getUrlCode().then(function (data) {
                            return {
                                type: $scope.affiliateResellerType.value,
                                urlCode: data.urlCode,
                                fullUrl: data.fullUrl
                            };
                        });
                    }
                ],
                welcomeMessages: [
                    "AdminWelcomeMessagesService",
                    function (AdminWelcomeMessagesService) {
                        return AdminWelcomeMessagesService.getWelcomeMessages();
                    }
                ]
            }
        });

        modalInstance.result.then(
            function () {
                // After a successful save, update the $scope.reseller
                getResellerDtos($scope.currentPage, $scope.pagedItemRange.value);
            },
            function () {
                // do something with dissResult
            }
        );
    };

    // Delete
    $scope.deleteReseller = function (resellerId) {
        var modalInstance = $uibModal.open({
            templateUrl: adminResellersDeleteModalTemplate,
            controller: "ResellersDeleteCtrl",
            resolve: {
                resellerId: function () {
                    return resellerId;
                }
            }
        });

        modalInstance.result.then(
            function () {
                // After a successful save, update the $scope.reseller
                //$scope.reseller = reseller;
                getResellerDtos($scope.currentPage, $scope.pagedItemRange.value);
            },
            function () {
                // do something with dissResult
            }
        );
    };

    $scope.showPage = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.totalResellersCount).visible[page - 1];
    };

    $scope.isFirstPageRange = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.totalResellersCount).isFirst;
    };

    $scope.isLastPageRange = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.totalResellersCount).isLast;
    };

    // Search functionality
    // Instantiate these variables outside the watch
    var searchTimeout;
    $scope.$watch("search.name", function (searchTerm) {
        if (searchTerm == undefined) return; // we want to allow the empty string but not undefined
        if (searchTimeout) $timeout.cancel(searchTimeout);

        searchTimeout = $timeout(function () {
            getResellerDtos(1, $scope.pagedItemRange.value);
        }, 300);
    });

    // Helper method to get resellers data. This function will determine if there is a
    // search term to include or not.
    function getResellerDtos(page, pageSize) {
        $scope.currentPage = page;
        var searchTerm = $scope.search != undefined ? $scope.search.name : undefined;
        AdminResellersService.getResellerDtos($scope.isSortDesc ? "desc" : "asc", page, pageSize, searchTerm).then(function (data) {
            $scope.resellers = data.resellers;
            $scope.maxPage = Math.ceil(data.totalResellersCount / $scope.pagedItemRange.value);
            $scope.totalResellersCount = data.totalResellersCount;
            $scope.pages = _.range(1, $scope.maxPage + 1);
        });
    }

    // Set up the controller initialization, dependencies are in order.
    (function () {
        // On init, set these default values
        //$scope.selectedTab = 1;
        $scope.sortBy = "name";
        $scope.isSortDesc = false;
        $scope.sortIconClass = "fa fa-chevron-up";
        $scope.currentPage = 1;
        $scope.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        $scope.pagedItemRange = $scope.pagedItemRanges[0];

        $scope.licensingResellerType = CommonData.resellerTypes.findByValue("Licensing");
        $scope.affiliateResellerType = CommonData.resellerTypes.findByValue("Affiliate");

        // Update the page with resellers data
        getResellerDtos(1, $scope.pagedItemRange.value);
    })();
}
