import templateUrl from "./admin-organizations-carequality-edit.modal.component.html";

export class AdminOrganizationsCarequalityEditModalController {
    constructor(AdminOrganizationsService, NotificationModalService) {
        this.adminOrganizationsService = AdminOrganizationsService;
        this.notificationModalService = NotificationModalService;
    }

    $onInit() {
        this.oid = { ...this.resolve };
    }

    cancel() {
        this.dismiss();
    }

    save(oid) {
        oid.id ? this.update(oid) : this.create(oid);
    }

    update(oid) {
        const ctrl = this;
        this.adminOrganizationsService
            .updateOrganizationAlternateOids(oid)
            .then(() => {
                ctrl.close();
            })
            .catch((response) => {
                ctrl.notificationModalService.error(response.data.modelState, "There was an error updating this OID.");
            });
    }

    create(oid) {
        const ctrl = this;
        this.adminOrganizationsService
            .createOrganizationAlternateOids(oid)
            .then(() => {
                ctrl.close();
            })
            .catch((response) => {
                ctrl.notificationModalService.error(response.data.modelState, "There was an error creating this OID.");
            });
    }
}

AdminOrganizationsCarequalityEditModalController.$inject = ["AdminOrganizationsService", "NotificationModalService"];

export const adminOrganizationsCarequalityEditModalComponent = {
    templateUrl: templateUrl,
    controller: AdminOrganizationsCarequalityEditModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
