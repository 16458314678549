UnsupportedBrowserService.$inject = ["$state"];
export function UnsupportedBrowserService($state) {
    this.checkBrowser = () => {
        const msie = navigator.userAgent.indexOf("MSIE ");
        let isSupported = true;
        if (msie > 0) {
            // IE 10.0 and up
            isSupported = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10) < 10;
        }

        if (!isSupported) {
            $state.go("unsupportedBrowser");
        }
    };
}
