ResellersEditCtrl.$inject = [
    "$scope",
    "$uibModalInstance",
    "$timeout",
    "reseller",
    "welcomeMessages",
    "AdminResellersService",
    "AdminUsersService",
    "NotificationService",
    "CommonData",
    "fileReader"
];

export function ResellersEditCtrl(
    $scope,
    $uibModalInstance,
    $timeout,
    reseller,
    welcomeMessages,
    AdminResellersService,
    AdminUsersService,
    NotificationService,
    CommonData,
    fileReader
) {
    $scope.reseller = reseller;
    $scope.welcomeMessages = welcomeMessages;
    $scope.resellerUrlPattern = "/register/";
    $scope.showLogo = false;
    $scope.logoFile = "";
    $scope.resellerId = reseller.id;
    $scope.colorCodes = CommonData.colorCodes.asArray();
    $scope.resellerTypes = CommonData.resellerTypes.asArray();
    $scope.licensingResellerType = CommonData.resellerTypes.findByValue("Licensing");
    $scope.affiliateResellerType = CommonData.resellerTypes.findByValue("Affiliate");

    // This provides the necessary call information for the k2-unique directive.
    $scope.resellerUrlExists = function () {
        return {
            serviceFunction: AdminResellersService.isResellerUrlExists,
            errorMessage: "The Reseller Url Code already exists, please enter a different Url Code."
        };
    };

    $scope.onFileSelect = function (files) {
        $scope.reseller.logoBackgroundColor = $scope.colorCodes[0].hexCode; // Select first color by default
        $scope.logoFile = files[0];
        $scope.showLogo = false;
        $scope.reseller.logoUrl = "";
        NotificationService.hideErrors();

        fileReader.readAsDataURL($scope.logoFile, $scope).then(function (result) {
            $scope.logoPreview = result;
        });
    };

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.removeLogo = function () {
        $scope.logoFile = "";
        $scope.showLogo = false;
        $scope.reseller.logoUrl = null;
        $scope.reseller.logoFileName = null;
        $scope.reseller.logoBackgroundColor = null;
    };

    $scope.toggleLogo = function () {
        if ($scope.showLogo) {
            $scope.showLogo = false;
        } else {
            $scope.showLogo = true;
        }
    };

    $scope.saveChanges = function () {
        if ($scope.form.$valid) {
            var functionName = !!$scope.reseller.id ? "putReseller" : "postReseller";

            AdminResellersService[functionName]($scope.reseller, $scope.logoFile).then(
                function (data) {
                    $scope.reseller = data.data;
                    $uibModalInstance.close($scope.reseller);
                    NotificationService.success("The reseller was saved successfully.");
                },
                function (response) {
                    NotificationService.serverError(response.data.modelState, "There was an error saving this reseller.");
                }
            );
        }
    };

    $scope.closeModal = function () {
        $uibModalInstance.dismiss("cancel");
    };
}
