import { Component, input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "kno2-form-radio-input",
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: "./form-radio-input.component.html"
})
export class FormRadioInputComponent {
    public control = input.required<FormControl>();
    public options = input<[string, any][]>([]);
    public hasError = input<boolean>(false);
}
