<ngx-file-drop
    dropZoneClassName="file-dropzone"
    contentClassName="file-dropzone-content"
    [accept]="accept"
    [multiple]="multiple"
    [disabled]="disabled || isLoading"
    [directory]="false"
    (onFileDrop)="onFileDrop($event)">
    <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
        <div
            class="file-dropzone-content w-100 flex-column p-2"
            (click)="openFileSelector()">
            @if (isLoading) {
                <kno2-loading-spinner></kno2-loading-spinner>
            } @else {
                <p class="h5">{{ label }}</p>
                <small class="text-muted">*File sizes large than 20MB will not be accepted.</small>
                <div class="file-ext-row m-2">
                    @for (ext of extensions; track ext) {
                        <span class="badge badge-pill badge-secondary file-ext-badge mx-2 pt-1 pb-2 px-3">
                            {{ ext }}
                        </span>
                    }
                </div>
            }
        </div>
    </ng-template>
</ngx-file-drop>
