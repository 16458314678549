angular.module("kno2.services").service("AlertsService", [
    "$http",
    "SessionService",
    "CacheService",
    "moment",
    function ($http, sessionService, CacheService, moment) {
        this.getAlerts = function () {
            var now = new Date().toJSON().replace("Z", ""); // Keep UTC time.
            var promise = $http.get("/api/alerts?" + "currentDate=" + now + "&s=true").then(function (response) {
                angular.forEach(response.data.alerts, function (value, key) {
                    value.createdDate = new Date(value.createdDate + "Z");
                    value.modifiedDate = new Date(value.modifiedDate + "Z");
                });
                return response.data;
            });
            return promise;
        };

        this.getAlert = function (id) {
            var promise = $http.get("/api/admin/alert/" + id).then(function (response) {
                response.data.startDateTime = new Date(response.data.startDateTime + "Z");
                response.data.endDateTime = new Date(response.data.endDateTime + "Z");
                if (response.data.emailSentDateTime) {
                    response.data.emailSentDateTime = new Date(response.data.emailSentDateTime + "Z");
                }
                return response.data;
            });
            return promise;
        };

        this.getMessageEvents = function () {
            var startDate = moment().utc().subtract(1, "year").startOf("day").toDate();
            var req = {
                pageStart: 1,
                pageSize: 20,
                orderDir: "asc",
                orderBy: "updatedDate",
                startDate: startDate,
                rules: []
            };

            return $http({
                url: "/api/messages/",
                method: "GET",
                params: req,
                cache: CacheService.cacheL1()
            });
        };
    }
]);
