export function identityProviderValueFilter() {
    return function (value) {
        value = value || "";
        var str = "";
        if (value instanceof Array) {
            for (var idx = 0; idx < value.length; idx++) {
                var obj = value[idx];
                Object.keys(obj).forEach((key) => {
                    if (obj.hasOwnProperty(key) && key !== "$$hashKey") {
                        str += obj[key] + " ";
                    }
                });
            }
        } else {
            str = value;
        }
        return str;
    };
}
