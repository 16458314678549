import { AdminDocuSignService } from "./admin.docusign.service";

let ctrl;
class AdminDocuSignLoginController {
    public static $inject: Array<string> = ["AdminDocuSignService", "NotificationService", "$timeout", "$interval"];

    public resolve;
    public close;
    public dismiss;
    public data: any;
    public saving: boolean;

    private signInWindow: any;
    private loginUrl: any;

    constructor(
        private adminDocuSignService: AdminDocuSignService,
        private notificationService,
        private $timeout,
        private $interval
    ) {}

    public $onInit(): void {
        this.getDocuSignLoginUrl();
    }

    private getDocuSignLoginUrl(): void {
        this.adminDocuSignService.getExternalLogin().then((x) => {
            this.loginUrl = x.data.url;
        });
    }

    private checkSignInWindowClosed(): void {
        const interval = this.$interval(
            () => {
                if (this.signInWindow && this.signInWindow.closed) {
                    this.$interval.cancel(interval);
                    this.signInWindow = null;
                }
            },
            1000,
            120
        );
    }

    public signIn(): void {
        this.signInWindow = window.open(this.loginUrl);
        this.checkSignInWindowClosed();
        this.signInWindow.focus();
        this.close();
    }
}

export class AdminDocuSignLoginComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.docusign.login-modal.component.html");
        this.controller = AdminDocuSignLoginController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}
