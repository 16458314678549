type SsoFeature = "PointClickCareIdentityProvider";

export class SingleSignOnService {
    public static $inject: Array<string> = ["FeatureService"];

    constructor(private FeatureService) {}

    public isOrgSSOEnabled(): boolean {
        const ssoFeatures: Array<SsoFeature> = ["PointClickCareIdentityProvider"];
        return this.FeatureService.isAnyEnabled(ssoFeatures);
    }
}
