angular.module("kno2.settings").controller("SignOutModalCtrl", [
    "$scope",
    "$rootScope",
    "$uibModalInstance",
    function ($scope, $rootScope, $uibModalInstance) {
        $scope.signOut = function () {
            $rootScope.logoff();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
    }
]);
