<div class="content-full support-center">
    <div class="row ng-scope">
        <div class="col-sm-9 col-no-gutters">
            <div class="box-content">
                <div class="container main">
                    <div class="row intake-header">
                        <div class="col-sm-12">
                            <div class="intake-header-title no-info pull-left">
                                <h2>Support Center</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row intake-row support-center-row auto-fill">
                        <div class="container px-0">
                            <kno2-support-submit-a-ticket
                                (toggleCollapse)="onToggleCollapse('contact')"
                                class="inline-box" />
                            <kno2-support-downloads
                                (toggleCollapse)="onToggleCollapse('downloads')"
                                class="inline-box" />
                            <kno2-support-knowledge-base
                                (toggleCollapse)="onToggleCollapse('knowledge')"
                                class="inline-box" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-3 sidebar-right auto-fill col-no-gutters">
            <div class="box-content">
                <div class="container">
                    <div class="">
                        <div class="row rules-header-first">
                            <div class="col-sm-12">
                                <div class="rules-header-title">
                                    <h2>About Support Center</h2>
                                </div>
                                <div
                                    class="header-ctrls"
                                    style="margin-right: -15px">
                                    <div class="header-ctrl">
                                        <i class="fa fa-lightbulb-o"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="rules-content in"
                            style="font-size: 13px">
                            <p>The Support Center is available for product and technical support assistance.</p>
                            <p>
                                Various options are available within the Support Center including submitting a ticket to our technical team, a downloads center
                                for advanced functionality of {{ brandDisplayName }}, and a link to access our knowledge base.
                            </p>
                        </div>

                        <div class="row rules-header">
                            <div class="col-sm-12">
                                <div class="rules-header-title">
                                    <h2>About</h2>
                                </div>
                                <div
                                    class="header-ctrls"
                                    style="margin-right: -15px; cursor: auto">
                                    <div class="header-ctrl">
                                        <i class="fa fa-book"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="rules-content in"
                            style="font-size: 13px">
                            <!-- IKW-5994 - Conversation resulted in leaving this as is -->
                            <p>Kno2 Version 4</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
