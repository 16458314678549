export class WorkflowsProcessingService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    getById(orgId: string, documentId: string): Promise<any> {
        return this.$http.get(`/api/workflows/processing/${orgId}/${documentId}`);
    }

    create(orgId: string, data: any): Promise<any> {
        return this.$http.post(`/api/workflows/processing/${orgId}`, data);
    }

    update(orgId: string, documentId: string, data: any): Promise<any> {
        return this.$http.put(`/api/workflows/processing/${orgId}/${documentId}`, data);
    }

    delete(orgId: string, documentId: string): Promise<any> {
        return this.$http.delete(`/api/workflows/processing/${orgId}/${documentId}`);
    }
}
