class AdminBatchImportsController {
    public static $inject: Array<string> = ["$state", "AdminBatchImportsService"];
    public selectedTab = "status";

    public data: any;

    constructor() {}
}

class AdminBatchImportsComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.batchimport.component.html");
        this.controller = AdminBatchImportsController;
        this.bindings = {};
    }
}

export const adminBatchImportsComponent = new AdminBatchImportsComponent();
