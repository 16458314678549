angular.module("kno2.services").service("EFaxService", [
    "$http",
    "CacheService",
    function ($http) {
        this.postAccount = function (account) {
            return $http.put("/api/efax/account/kno2", account);
        };

        this.deleteEFaxAccount = function (organizationId) {
            return $http.delete("/api/efax/account/kno2/" + organizationId);
        };
    }
]);
