import templateUrl from "./admin-organizations-edit.component.html";

export const adminOrganizationsEditComponent = {
    templateUrl,
    controller: AdminOrganizationsEditController,
    bindings: {
        organization: "<"
    }
};

AdminOrganizationsEditController.$inject = [
    "$state",
    "$timeout",
    "$q",
    "$stateParams",
    "appConfig",
    "CommonData",
    "AdminOrganizationsService",
    "AdminUsersService",
    "NotificationService",
    "AdminReservedWordsService",
    "AdminResellersService",
    "SubscriptionPlansService",
    "OpenTextService",
    "OrganizationFeaturesService",
    "QueryService",
    "BrandService",
    "AdminNetworksService",
    "$uibModal",
    "EFaxService",
    "WestFaxService",
    "$confirm"
];

export function AdminOrganizationsEditController(
    $state,
    $timeout,
    $q,
    $stateParams,
    appConfig,
    CommonData,
    AdminOrganizationsService,
    AdminUsersService,
    NotificationService,
    AdminReservedWordsService,
    AdminResellersService,
    SubscriptionPlansService,
    OpenTextService,
    OrganizationFeaturesService,
    QueryService,
    BrandService,
    AdminNetworksService,
    $uibModal,
    EFaxService,
    WestFaxService,
    $confirm
) {
    const ctrl = this;

    ctrl.$onInit = onInit;

    ctrl.faxSendEnabled = false;
    ctrl.faxDocumentSourcesEnabled = false;

    const getOrganizationTypes = function () {
        return AdminOrganizationsService.getOrganizationTypes().then(function (data) {
            ctrl.organizationTypeList = data;
        });
    };

    const getOrganizationSpecialties = function () {
        return AdminOrganizationsService.getOrganizationSpecialties().then(function (data) {
            ctrl.organizationSpecialtyList = data;
        });
    };

    const getOrganizationAdministrators = function (id) {
        return AdminOrganizationsService.getOrganizationAdministrators(id).then(function (data) {
            ctrl.admins = data;
        });
    };

    const getOrganizationLicenses = function (id) {
        return AdminOrganizationsService.getOrganizationLicenses(id).then(function (data) {
            ctrl.licenses = data;
        });
    };

    const getOrganizationDevices = function (id) {
        return AdminOrganizationsService.getOrganizationDevices(id).then(function (data) {
            ctrl.devices = data;
        });
    };

    const getOrganizationIntegrations = function (id) {
        if (id) {
            return AdminOrganizationsService.getOrganizationIntegrations(id).then(function (data) {
                ctrl.integrations = data;
            });
        }
    };

    const getServiceAccounts = function (id) {
        if (id) {
            return AdminOrganizationsService.getServiceAccounts(id).then(function (data) {
                ctrl.serviceAccounts = data;
            });
        }
    };

    ctrl.updateServiceAccountLimit = function () {
        AdminOrganizationsService.updateServiceAccountLimit(ctrl.organization.id, ctrl.serviceAccountLimit).then(
            function (data) {
                NotificationService.success("The service account limit was saved successfully.");
            },
            function (response) {
                NotificationService.errorToaster(response);
            }
        );
    };

    const getServiceAccountLimit = function (id) {
        if (id) {
            return AdminOrganizationsService.getServiceAccountLimit(id).then(function (data) {
                ctrl.serviceAccountLimit = data.data;
            });
        }
    };

    const getNetwork = (id) => {
        return AdminNetworksService.getNetwork(id).then(({ data }) => {
            const { identityProvider } = data;
            if (identityProvider.connectionName) {
                ctrl.singleSignOnUrl = `${appConfig.appUrl}/account/login/${identityProvider.connectionName}`;
            }
        });
    };

    const getBrands = function () {
        return BrandService.getBrands().then(function (data) {
            ctrl.brands = data.items;
        });
    };

    ctrl.updateAddressCount = function (serviceAccount) {
        if (serviceAccount.clientIpAddresses.length > serviceAccount.allowedAddressCount) {
            NotificationService.errorToaster(`The number of Allowed Addresses cannot be lower than ${serviceAccount.clientIpAddresses.length}`);
            return;
        }
        return AdminOrganizationsService.updateServiceAccountAddressCount(ctrl.organizationId, serviceAccount.id, serviceAccount.allowedAddressCount).then(
            function (data) {
                NotificationService.success("Address count updated");
            },
            function (error) {
                NotificationService.error("Error updating address count");
            }
        );
    };

    const getResellers = function () {
        return AdminResellersService.getResellers().then(function (data) {
            ctrl.licensedResellers = _.filter(data, function (reseller) {
                return reseller.type === ctrl.licensingResellerType.value;
            });
            ctrl.affiliateResellers = _.filter(data, function (reseller) {
                return reseller.type === ctrl.affiliateResellerType.value;
            });
        });
    };

    const getPlans = function () {
        return SubscriptionPlansService.getAll().then(function (data) {
            ctrl.plans = data.items;
        });
    };

    const getDirectProviders = function () {
        return AdminOrganizationsService.getDirectProviders().then(function (response) {
            ctrl.directMessagingProviders = response.data;
        });
    };

    const filterAllowedPlans = function (userCount) {
        // Filter plans down to only plans that match fit their user count
        if (userCount != null) {
            ctrl.plans = _.filter(ctrl.plans, function (plan) {
                return plan.allowedUsers >= userCount || plan.allowedUsers === -1;
            });
        }
    };

    const setResellerAndPlan = function (org) {
        if (org.plan != null) {
            ctrl.licensedPlan = _.find(ctrl.plans, { id: org.plan.id });
        }
        // Only set the licensed reseller drop down for the org, if they have a licensing reseller
        if (org.reseller != null && org.reseller.type === ctrl.licensingResellerType.value) {
            ctrl.licensedReseller = _.find(ctrl.licensedResellers, { id: org.reseller.id });
            ctrl.updateLicenserAndPlan(ctrl.licensedReseller, ctrl.licensedPlan);
        } else if (org.reseller != null && org.reseller.type === ctrl.affiliateResellerType.value) {
            ctrl.affiliateReseller = _.find(ctrl.affiliateResellers, { id: org.reseller.id });
        }
    };

    const canResellerBeSet = function (org) {
        ctrl.canSetReseller = true;
        if (org.reseller != null && org.reseller.type !== ctrl.licensingResellerType.value && org.plan != null) {
            ctrl.canSetReseller = false;
        } else if (org.reseller == null && org.plan != null) {
            ctrl.canSetReseller = false;
        }
    };

    ctrl.updatePlan = function () {
        if (ctrl.licensedPlan) ctrl.organization.planId = ctrl.licensedPlan.id;
        if (!ctrl.licensedPlan) ctrl.organization.planId = -1;
    };

    ctrl.organizationSubdomainValid = function () {
        return {
            serviceFunction: AdminOrganizationsService.isSubdomainValid,
            errorMessage: "This subdomain already exists, contains invalid characters or is a reserved word, please enter a different one."
        };
    };

    ctrl.saveChanges = function () {
        // SubDomain is using k2-unique as a directive to check if the SubDomain exists and/or is valid as the user types.
        // The problem is, when pressing the enter key to submit the form, the submit action runs before the promise is
        // returned and therefore we cannot invalidate the page. This request will prevent that timing issue.
        AdminOrganizationsService.isSubdomainValid(ctrl.organizationId, ctrl.detailsForm.SubDomain.$viewValue).then(function (response) {
            ctrl.detailsForm.SubDomain.$setValidity("unique", response.data === false);
            saveOrgDetails();
        });
    };

    ctrl.updateAffiliate = function (reseller) {
        ctrl.organization.reseller = reseller;
        if (reseller != null) {
            ctrl.organization.resellerId = reseller.id;
        } else {
            ctrl.organization.resellerId = null;
        }
        if (!ctrl.canSetReseller)
            NotificationService.warning("The reseller has changed. You will need to ensure the subscription in FastSpring is updated to reflect this change.");
    };

    ctrl.updateLicenserAndPlan = function (reseller, plan) {
        if (reseller != null) {
            ctrl.organization.reseller = reseller;
            ctrl.organization.resellerId = reseller.id;
            if (plan != null) {
                ctrl.organization.plan = plan;
                ctrl.organization.planId = plan.id;
            } else {
                ctrl.licensedPlan = ctrl.plans[0];
                ctrl.organization.plan = ctrl.licensedPlan;
                ctrl.organization.planId = ctrl.licensedPlan.id;
            }
        } else {
            if (ctrl.organization.numberOfUsers > 3) {
                NotificationService.error("Can't remove Licenser because this organization exceeds the user limit for the free plan.");
                ctrl.licensedReseller = _.find(ctrl.licensedResellers, { id: ctrl.organization.reseller.id });
                ctrl.licensedPlan = _.find(ctrl.plans, { id: ctrl.organization.plan.id });
            } else {
                NotificationService.warning("Removing a Licensing Reseller will reset the organization to a Free Plan.");
                ctrl.organization.resellerId = null;
                ctrl.organization.reseller = null;
                ctrl.organization.planId = null;
                ctrl.organization.plan = null;
            }
        }
    };

    ctrl.searchNpiByNumber = function () {
        const modal = $uibModal.open({
            component: "k2AdminOrganizationsNpiModal",
            resolve: {
                npiNumber: () => ctrl.detailsForm.OrgNpi.$viewValue
            }
        });

        modal.result.then(
            (result) => {
                ctrl.organization.organizationNpi = result;
                angular.element("#OrgNpi").removeClass("has-error");
                NotificationService.notice(`Click 'Save Changes' to apply the new NPI`);
            },
            () => {}
        );
    };

    const saveOrgDetails = function () {
        if (!ctrl.orgForm.$valid) {
            NotificationService.error("Please fill out all required fields.");
            return;
        }

        if (ctrl.organization.name !== ctrl.detailsForm.Name.$viewValue) {
            ctrl.organization.name = ctrl.detailsForm.Name.$viewValue;
        }

        if (ctrl.organization.id) {
            // Edit = PUT
            AdminOrganizationsService.putOrganization(ctrl.organization).then(
                function (data) {
                    $state.go("organizations");
                    NotificationService.success("The organization was saved successfully.");
                },
                function (response) {
                    NotificationService.serverError(response.data.modelState, "There was an error saving this organization");
                }
            );
        } else {
            // Create = POST
            AdminOrganizationsService.postOrganization(ctrl.organization).then(
                function (data) {
                    $state.go("organizations");
                    NotificationService.success("The organization was saved successfully.");
                },
                function (response) {
                    NotificationService.serverError(response.data.modelState, "There was an error saving this organization.");
                }
            );
        }
    };

    ctrl.regionSelectionChanged = function () {
        ctrl.selectedCommunity = "";
    };

    // OpenText Accounts
    ctrl.canTypes = ["Kno2Group", "OpenText"];

    ctrl.eFaxAccountTypes = ["EFaxAccount", "Kno2EFaxAccount"];

    ctrl.westFaxAccountTypes = ["WestFaxAccount"];

    ctrl.getPreferredEFaxGroup = function () {
        return ctrl.organization.preferredGroupName;
    };

    ctrl.showClipboardCapture = function () {
        NotificationService.success("Group Name copied to your clipboard");
    };

    ctrl.ladaLoad = function () {
        ctrl.loginLoading = true;
    };

    ctrl.organizationHasFeature = function (feature) {
        if (!feature) return false;
        if (!ctrl.organization) return false;
        if (!ctrl.organization.enabledFeatures) return false;
        var orgHasFeature = false;
        _.each(ctrl.organization.enabledFeatures, function (orgFeature) {
            if (orgFeature === feature.id) orgHasFeature = true;
        });
        return orgHasFeature;
    };

    ctrl.selectFeature = function (featureId) {
        const index = ctrl.organization.enabledFeatures.indexOf(featureId);
        if (index === -1) ctrl.organization.enabledFeatures.push(featureId);
        else ctrl.organization.enabledFeatures.splice(index, 1);
    };

    ctrl.toggleAllFeatures = (toggle) => {
        ctrl.organization.enabledFeatures = ctrl.organization.enabledFeatures || {};
        if (toggle) ctrl.organization.enabledFeatures = ctrl.features.map((x) => x.id);
        else ctrl.organization.enabledFeatures.length = 0;
    };

    ctrl.creatingKno2Account = false;
    ctrl.generateKno2Account = function () {
        ctrl.creatingKno2Account = true;

        const account = {
            organizationId: ctrl.organizationId
        };

        const success = function (response) {
            ctrl.organization.openTextAccount.canType = response.data.canType;
            ctrl.organization.openTextAccount.username = response.data.username;
            ctrl.organization.openTextAccount.serviceUrl = response.data.serviceUrl;
            ctrl.creatingKno2Account = false;
        };

        const error = function (response) {
            NotificationService.error("There was an error creating an OpenText account for " + ctrl.organization.name);
            ctrl.creatingKno2Account = false;
        };

        OpenTextService.postAccount(account).then(success, error);
    };

    ctrl.creatingEFaxAccount = false;
    ctrl.saveEFaxAccount = function () {
        ctrl.creatingEFaxAccount = true;
        const account = {
            organizationId: ctrl.organizationId,
            groupName: ctrl.organization.eFaxAccount.groupName,
            accountType: ctrl.organization.eFaxAccount.accountType
        };

        const success = function (response) {
            ctrl.creatingEFaxAccount = false;
        };

        const error = function (response) {
            NotificationService.error("There was an error creating a Kno2 eFax account for " + ctrl.organization.name);
            ctrl.creatingKno2Account = false;
        };

        EFaxService.postAccount(account).then(success, error);
    };

    ctrl.creatingWestFaxAccount = false;
    ctrl.generateWestFaxAccount = function () {
        ctrl.creatingWestFaxAccount = true;

        const account = {
            organizationId: ctrl.organizationId
        };

        const success = function (response) {
            const successType = ctrl.organization.westFaxAccount.username ? "updated" : "created";
            const successResponse = `Successfully ${successType} WestFax account for ${ctrl.organization.name}!`;
            ctrl.organization.westFaxAccount.username = response.data.username;
            ctrl.creatingWestFaxAccount = false;

            NotificationService.success(successResponse);
        };

        const error = function (response) {
            const errorMessage = response.data.message ? response.data.message : response.data;
            NotificationService.error(errorMessage);
            ctrl.creatingWestFaxAccount = false;
        };

        WestFaxService.postAccount(account).then(success, error);
    };

    ctrl.syncWestFaxAccount = function () {
        const success = function (response) {
            const successResponse = `Successfully synced WestFax account for ${ctrl.organization.name}!`;
            NotificationService.success(successResponse);
        };

        const error = function (response) {
            const errorMessage = response.data.message ? response.data.message : response.data;
            NotificationService.error(errorMessage);
            ctrl.creatingWestFaxAccount = false;
        };

        WestFaxService.syncAccount(ctrl.organizationId).then(success, error);
    };

    const confirmDeleteWestfax = () => {
        return $confirm.open({
            bodyText: `Are you sure you want to delete the "${ctrl.organization.name}" WestFax Account?`,
            okText: "Delete"
        });
    };

    ctrl.deleteWestFaxAccount = function () {
        const success = function (response) {
            ctrl.organization.westFaxAccount = {};
        };

        const error = function (response) {
            const errorMessage = response.data.message ? response.data.message : response.data;
            NotificationService.error(errorMessage);
        };

        confirmDeleteWestfax().result.then(() => {
            WestFaxService.deleteWestFaxAccount(ctrl.organizationId).then(success, error);
        });
    };

    const confirmDeleteEfax = () => {
        return $confirm.open({
            bodyText: "Are you sure you want to revoke token access?",
            okText: "Revoke"
        });
    };

    ctrl.deleteEFaxAccount = function () {
        const success = function (response) {
            ctrl.organization.eFaxAccount = {};
        };

        const error = function (response) {
            NotificationService.errorToaster(response);
        };

        confirmDeleteEfax().result.then(() => {
            EFaxService.deleteEFaxAccount(ctrl.organizationId).then(success, error);
        });
    };

    ctrl.togglePurposeOfUse = (p) => {
        const index = ctrl.organization.allowedPurposeOfUseCodes.indexOf(p);
        if (index > -1) ctrl.organization.allowedPurposeOfUseCodes.splice(index, 1);
        else ctrl.organization.allowedPurposeOfUseCodes.push(p);
    };

    // Init
    function onInit() {
        if ($stateParams.id) ctrl.organizationId = $stateParams.id;
        ctrl.states = CommonData.states.hash;

        OrganizationFeaturesService.getFeatures().then(function (response) {
            const features = response;
            ctrl.features = features.filter((x) => x.enabled && x.isOrganizationFeature);
        });

        ctrl.faxSendEnabled = false;
        ctrl.faxDocumentSourcesEnabled = false;

        ctrl.licensingResellerType = CommonData.resellerTypes.findByValue("Licensing");
        ctrl.affiliateResellerType = CommonData.resellerTypes.findByValue("Affiliate");
        ctrl.canSetReseller = true;

        let promises = [
            getPlans(),
            getDirectProviders(),
            getResellers(),
            getOrganizationTypes(),
            getOrganizationSpecialties(),
            getBrands(),
            QueryService.getPurposesOfUse().then((x) => (ctrl.purposesOfUse = x))
        ];

        if (ctrl.organizationId) {
            promises = [
                ...promises,
                getOrganizationIntegrations(ctrl.organizationId),
                getServiceAccounts(ctrl.organizationId),
                getOrganizationAdministrators(ctrl.organizationId),
                getOrganizationDevices(ctrl.organizationId),
                getOrganizationLicenses(ctrl.organizationId),
                getServiceAccountLimit(ctrl.organizationId)
            ];
        }

        if (ctrl.organization.networkDbId) {
            getNetwork(ctrl.organization.networkDbId);
        }

        $q.all(promises).then(function () {
            if (ctrl.organization && ctrl.organization.id) {
                setResellerAndPlan(ctrl.organization);
                canResellerBeSet(ctrl.organization);
                filterAllowedPlans(ctrl.organization.numberOfUsers);
            } else {
                ctrl.organization = {};
            }
        });
    }
}
