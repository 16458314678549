import { inject, Injector, runInInjectionContext } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, UrlTree } from "@angular/router";

export const runSeriallyGuard = (guards: CanActivateFn[] | CanActivateChildFn[]): CanActivateFn | CanActivateChildFn => {
    return async (route, state): Promise<boolean | UrlTree> => {
        const injectionContext = inject(Injector);

        for (const guard of guards) {
            const guardResult = await runInInjectionContext(injectionContext, () => guard(route, state));
            if (guardResult !== true) {
                return guardResult as boolean | UrlTree;
            }
        }

        return true;
    };
};
