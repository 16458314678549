import { IPromise, IHttpService, IHttpResponse } from "angular";

export interface NetworkUserGroupListItemResponse {
    id: number;
    name: string;
    role: string;
    lastSyncTime: Date;
    organizations: NetworkUserGroupAssignedOrganizationListItemResponse[];
    numberOfUsers: number;
}

export interface NetworkUserGroupAssignedOrganizationListItemResponse {
    organizationId: number;
    organizationName: string;
    assignmentCreatedAt: string;
}

export interface UpdateNetworkUserGroupRequest {
    role: string;
    organizationIds: number[];
}

export interface NetworkUserGroupDetailResponse {
    id: number;
    name: string;
    role: string;
    createDate: Date;
    lastSync: Date;
    organizations: NetworkUserGroupDetailOrganization[];
    users: NetworkUserGroupDetailUser[];
}

export interface NetworkUserGroupDetailOrganization {
    organizationId: number;
    organizationName: string;
    assignmentCreateDate: Date;
}

export interface NetworkUserGroupDetailUser {
    userId: string;
    userName: string;
    assignmentCreateDate: string;
}

import moment from "moment";

export class NetworkUserGroupsService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http: IHttpService) {}

    public getUserGroups(): IPromise<NetworkUserGroupListItemResponse[]> {
        return this.$http
            .get<NetworkUserGroupListItemResponse[]>("/api/networks/usergroups")
            .then((res) => res.data)
            .catch((_) => {
                throw new Error("Failed to get network user groups.");
            });
    }

    public getUserGroup(userGroupId: number): IPromise<NetworkUserGroupDetailResponse> {
        return this.$http
            .get<NetworkUserGroupDetailResponse>(`/api/networks/usergroups/${userGroupId}`)
            .then((res) => res.data)
            .then((data) => {
                data.users = data.users.map((m) => ({ ...m, assignmentCreateDate: moment.utc(m.assignmentCreateDate).local().format("LLL") }));
                return data;
            })
            .catch((_) => {
                throw new Error("Failed to get network user group.");
            });
    }

    public updateUserGroup(groupId: number, request: UpdateNetworkUserGroupRequest): IPromise<NetworkUserGroupListItemResponse> {
        return this.$http
            .put<NetworkUserGroupListItemResponse>(`/api/networks/usergroups/${groupId}`, request)
            .then((res) => res.data)
            .catch((_) => {
                throw new Error("Failed to update network user group.");
            });
    }
}

