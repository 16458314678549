(function ($) {
    "use strict";

    angular.module("kno2.directives").directive("k2ChosenInvalid", k2ChosenInvalid);

    k2ChosenInvalid.$inject = ["$parse"];

    function k2ChosenInvalid($parse) {
        return {
            restrict: "A",
            require: "?ngModel",
            link: link
        };

        function link(scope, element, attr, ngModel) {
            var model,
                invalidItems,
                invalidClass = attr.k2ChosenInvalidClass || "chosen-invalid",
                useToolTip = attr.k2ChosenInvalidTooltip === "true",
                tooltipClass = ".k2-chosen-tooltip",
                getText = $parse(attr.k2ChosenInvalidText);

            scope.$watch(
                function () {
                    return ngModel.$modelValue;
                },
                function (newValue) {
                    model = newValue;
                    updateInvalid(model, invalidItems);
                }
            );

            scope.$watch(attr.k2ChosenInvalid, function (newValue) {
                invalidItems = newValue;
                updateInvalid(model, invalidItems);
            });

            function updateInvalid(model, invalidItems) {
                _(model).each(function (value, index) {
                    element.one("chosen:updated", function () {
                        var invalid = _(invalidItems).includes(value),
                            option = element.siblings(".chosen-container").find(".search-choice").eq(index);

                        if (invalid) {
                            var text = getText(scope, { $item: value });
                            option.addClass(invalidClass);

                            if (useToolTip) {
                                if (!option.find(tooltipClass).length)
                                    $('<i class="fa fa-info-circle k2-chosen-tooltip">').insertAfter(option.find("span")).popover({
                                        placement: "top",
                                        content: text,
                                        trigger: "hover",
                                        container: "body",
                                        html: true
                                    });
                            } else option.prop("title", text);
                        } else {
                            option.removeClass(invalidClass);

                            if (useToolTip) option.remove(tooltipClass);
                            else option.removeProp("title");
                        }
                    });
                });

                if (!invalidItems || !invalidItems.length) element.trigger("chosen:updated");
            }
        }
    }
})(window.jQuery);
