angular.module("kno2.filters").filter("queryString", function () {
    return function (options) {
        var params = [];
        _.each(options, function (value, key) {
            if (_.isArray(value)) {
                _.each(value, function (item) {
                    params.push(key + "=" + item);
                });
            } else if (value !== "" && value !== undefined) {
                params.push(key + "=" + value);
            }
        });
        return params.length ? "?" + params.join("&") : "";
    };
});
