IdentityWelcomeKno2Modal.$inject = ["$scope", "$uibModalInstance", "SessionService", "Auth0Service", "appConfig"];
export function IdentityWelcomeKno2Modal($scope, $uibModalInstance, sessionService, Auth0Service, appConfig) {
    $scope.title = `Welcome to ${appConfig.brandDisplayName}`;
    $scope.brandDisplayName = appConfig.brandDisplayName;
    $scope.userProfile = sessionService.getProfile();
    $scope.subdomain = JSON.parse(atob(Auth0Service.accessToken.split(".")[1]))["http://kno2/subdomain"];

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.submitNext = function () {
        $uibModalInstance.close();
    };
}
