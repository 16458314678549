import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { UpgradeModule } from "@angular/upgrade/static";
import { AuthService } from "@auth0/auth0-angular";
import { ApplicationInsightsService } from "./core/services";
import { SessionService } from "./core/session";
import { SocketService } from "./data-access/websockets";

@Injectable({
    providedIn: "root"
})
export class StartupService {
    private readonly authService = inject(AuthService);
    private readonly socketService = inject(SocketService);
    private readonly sessionService = inject(SessionService);
    private readonly applicationInsightsService = inject(ApplicationInsightsService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly featureService = this.upgrade.$injector.get("FeatureService");

    private isAuthenticated = toSignal(this.authService.isAuthenticated$);
    private profileData = this.sessionService.profileData;

    public isReady = computed(() => this.isAuthenticated() && !!this.profileData());

    public start(): void {
        this.socketService.init();
        this.featureService.load();
        const { userId, organizationId, organizationName } = this.profileData();
        this.applicationInsightsService.setContextUserId(userId);
        this.applicationInsightsService.setAppInsightsCustomFields({
            organizationId,
            organizationName
        });
    }

    public stop(): void {
        this.socketService.disconnect();
    }
}
