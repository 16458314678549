import { Directive, ElementRef, inject } from "@angular/core";

declare const $: JQueryStatic;
@Directive({
    selector: "[kno2Popover]",
    standalone: true
})
export class PopoverDirective {
    private el = inject(ElementRef);

    ngAfterViewInit() {
        const element = $(this.el.nativeElement);
        if (!element.data("bs.popover")) {
            element.popover({
                html: true,
                trigger: "hover",
                title: "Tip",
                delay: {
                    show: 200,
                    hide: 200
                }
            });
        }
    }

    ngOnDestroy() {
        const element = $(this.el.nativeElement);
        if (element.data("bs.popover")) {
            element.popover("dispose");
        }
    }
}
