import templateUrl from "./integration-list-view.component.html";

export const integrationListViewComponent = {
    templateUrl: templateUrl,
    controller: IntegrationListViewController
};

IntegrationListViewController.$inject = ["AdminIntegrationsService", "$stateParams", "NotificationService"];

export function IntegrationListViewController(AdminIntegrationsService, $stateParams, NotificationService) {
    var ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.type = $stateParams.type;
    ctrl.name = $stateParams.type.split(".").pop();

    function onInit() {
        getOrgsForIntegration();
    }

    function getOrgsForIntegration() {
        return AdminIntegrationsService.getOrgsForIntegration(ctrl.type).then(
            function (data) {
                ctrl.organizations = data;
            },
            function (error) {
                NotificationService.error(error.data.message);
            }
        );
    }
}
