(function () {
    angular.module("kno2.services").factory("SupportedFileExtensionsService", SupportedFileExtensionsService);

    let extensions = [];

    SupportedFileExtensionsService.$inject = ["AttachmentService", "_"];

    function SupportedFileExtensionsService(AttachmentService, _) {
        return {
            load: load,
            getExtensions: getExtensions
        };

        function load() {
            if (extensions && extensions.length) return extensions;
            return AttachmentService.getExtensions().then(function (exts) {
                _.each(exts, function (e) {
                    extensions.push(e.substring(1));
                });
                return extensions;
            });
        }

        function getExtensions() {
            return extensions;
        }
    }
})();
