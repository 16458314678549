import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { NgxFileDropEntry, NgxFileDropModule } from "ngx-file-drop";

@Component({
    selector: "kno2-file-dropzone",
    imports: [NgxFileDropModule, LoadingSpinnerComponent],
    templateUrl: "./file-dropzone.component.html",
    styles: `
        ::ng-deep .file-dropzone {
            border: 2px dashed #DDD;
            text-align: center;
            cursor: pointer;
        }

        ::ng-deep .file-dropzone-content {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100px;
        }

        ::ng-deep .file-ext-badge {
            font-size: 1.25rem;
        }
    `,
    standalone: true
})
export class FileDropZoneComponent implements OnInit {
    @Input({ required: true }) public accept: string;
    @Input() public multiple: boolean = true;
    @Input() public disabled: boolean = false;
    @Input() public isLoading: boolean = false;
    @Input() public label: string = "Drop valid file(s) here or click to upload.";
    @Output() public onFilesAdded = new EventEmitter<File[]>();
    protected extensions: string[];

    public ngOnInit(): void {
        this.extensions = this.accept.replace(/\s/g, "").split(",");
    }

    public onFileDrop = (entries: NgxFileDropEntry[]) => {
        const files: File[] = [];

        entries.forEach(({ fileEntry: entry }) =>
            (entry as FileSystemFileEntry).file((file: File) => {
                files.push(file);
                if (files.length === entries.length) {
                    this.onFilesAdded.emit(files);
                }
            })
        );
    };
}
