<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="cancel()">
        &times;
    </button>
    <h2 class="modal-title">Welcome!</h2>
</div>
<div class="modal-body">
    <p>
        Your organization's activation is currently in progress, and should be completed within 2-3 business days. You'll receive an email notification as soon
        as the activation is complete.
    </p>
    <p>If you have any questions in the meantime, please don't hesitate to reach out.</p>
    <p>We're excited to get started!</p>
    <div class="form-check">
        <input
            class="form-check-input mr-2"
            type="checkbox"
            (change)="hidePrompt = !hidePrompt"
            style="position: absolute !important; top: -1px" />
        <label class="form-check-label">Don't show this message again</label>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-primary"
        (click)="ok()">
        Ok
    </button>
</div>
