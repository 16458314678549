class AttachmentPreviewModalController {
    public static $inject: Array<string> = ["$state"];

    public resolve;
    public close;
    public dismiss;

    public message;
    public attachment;

    public viewerOptions = {
        rotate: "hidden",
        remove: "hidden",
        print: "hidden",
        download: "hidden",
        zoom: "auto",
        // old options
        height: -1,
        centerDocument: true
    };

    $onInit() {
        this.message = this.resolve.message;
        this.attachment = this.resolve.attachment;
    }
}

class AttachmentPreviewModalComponent {
    public templateUrl: string;
    public bindings: any;
    public controller: any;

    constructor() {
        this.templateUrl = require("./intake.attachment-preview-modal.component.html");
        this.controller = AttachmentPreviewModalController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const attachmentPreviewModalComponent = new AttachmentPreviewModalComponent();
