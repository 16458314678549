import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "truncateText",
    standalone: true
})
export class TruncateTextPipe implements PipeTransform {
    transform(value: string, maxLength: number): unknown {
        return value.substring(0, maxLength);
    }
}
