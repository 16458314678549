import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telephone',
  standalone: true
})
export class TelephonePipe implements PipeTransform {
  /**
   *
   * @param value - The telephone number to be transformed.
   * @returns The transformed value as a string in the format (111) 222-3333 or empty string if no value or not 10 digits.
   * @example <span>{{ 1112223333 }}</span> <!-- (111) 222-3333 -->
   */
  transform(value: string): string {
    let numberRegex = /^[0-9]{10}$/;
    value = value ? value.replace(/\D/g, '') : '';
    if (!numberRegex.test(value))
      return '';

    return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
  }

}
