angular.module("kno2.settings").controller("SettingsLicensedPlanCtrl", [
    "$scope",
    "SessionService",
    "SubscriptionsFactory",
    "appConfig",
    function ($scope, SessionService, SubscriptionsFactory, appConfig) {
        $scope.userProfile = SessionService.getProfile();
        $scope.brandDisplayName = appConfig.brandDisplayName;

        var refreshSubscription = function () {
            SubscriptionsFactory.get().then(function (res) {
                $scope.plan = res.data;
                if ($scope.plan.planId == null) {
                    $scope.plan.planId = -1;
                }
                $scope.plan.upgradePlanId = $scope.plan.planId;
                $scope.plan.originalPlanId = $scope.plan.planId;
            });
        };

        (function () {
            refreshSubscription();
        })();
    }
]);
