import { Routes } from "@angular/router";

export const routes: Routes = [
    {
        path: "callback",
        pathMatch: "full",
        loadComponent: () => import("./callback.component").then((c) => c.CallbackComponent)
    },
    {
        path: "auth-error",
        loadComponent: () => import("./auth-error.component").then((c) => c.AuthErrorComponent)
    },
    {
        path: "account/login/:loginType",
        loadComponent: () => import("./login.component").then((c) => c.LoginComponent)
    },
    {
        path: "account/login",
        pathMatch: "full",
        loadComponent: () => import("./login.component").then((c) => c.LoginComponent)
    },

    {
        path: "account/logout",
        loadComponent: () => import("./logout.component").then((c) => c.LogoutComponent)
    }
];
