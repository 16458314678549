angular.module("kno2.factories").factory("FeatureGuardFactory", [
    "$state",
    "FeatureService",
    function ($state, FeatureService) {
        return {
            canActivate: function () {
                const { features } = $state.current.data || [];
                const canActivate = features?.every((f) => FeatureService.isEnabled(f));
                if (!canActivate && features?.length) {
                    $state.go("accountDashboard");
                }
            }
        };
    }
]);
