import {
    NetworkUserGroupDetailResponse,
    NetworkUserGroupListItemResponse,
    NetworkUserGroupsService
} from "App/src/common/services/network-user-groups.service";
import { NetworkOrganizationListItemResponse, NetworksService } from "App/src/common/services/networks.service";
import { IComponentOptions } from "angular";

let ctrl;

class NetworkIdentityProviderUserGroupDetailModalController {
    public static $inject: Array<string> = ["NetworkUserGroupsService"];

    public userGroup: NetworkUserGroupDetailResponse;
    public userSearch: string;

    public close: any;

    public $onInit(): void {
        ctrl = this;
        this.networkUserGroupsService.getUserGroup(ctrl.resolve.userGroupId).then((data) => (this.userGroup = data));
    }

    constructor(private networkUserGroupsService: NetworkUserGroupsService) {}
}

export const networkIdentityProviderUserGroupDetailModalComponent: IComponentOptions = {
    templateUrl: require("./network-identity-provider-user-group-detail-modal.html"),
    controller: NetworkIdentityProviderUserGroupDetailModalController,
    bindings: {
        userGroupId: "<",
        resolve: "<",
        close: "&"
    }
};
