OrganizationTypesService.$inject = ["$http"];

export function OrganizationTypesService($http) {
    return {
        get: get,
        create: create,
        update: update,
        getOrganizationType: getOrganizationType,
        delete: deleteOrganizationType
    };

    function get(pageStart, pageSize, search) {
        const args = {
            pageStart: search ? 1 : pageStart,
            pageSize: pageSize,
            search: search
        };
        return $http({
            method: "GET",
            params: args,
            url: "/api/admin/organizationTypes"
        }).then(function (response) {
            return response.data;
        });
    }

    function create(organizationType) {
        return $http.post("/api/admin/organizationTypes", organizationType).then(function (response) {
            return response.data;
        });
    }

    function update(organizationType) {
        return $http.put("/api/admin/organizationTypes", organizationType).then(function (response) {
            return response.data;
        });
    }

    function getOrganizationType(id) {
        return $http.get(`/api/admin/organizationTypes/${id}`).then(function (response) {
            return response.data;
        });
    }

    function deleteOrganizationType(id) {
        return $http.delete(`/api/admin/organizationTypes/${id}`).then(function (response) {
            return response.data;
        });
    }
}
