SendModalCtrl.$inject = ["$scope", "$uibModalInstance", "SessionService", "appConfig"];
export function SendModalCtrl($scope, $uibModalInstance, SessionService, appConfig) {
    const isKno2Brand = appConfig.brand === "Kno2";
    $scope.showKno2Send = isKno2Brand;
    $scope.kno2FaxButtonText = isKno2Brand ? "Kno2fax" : "Multi-Function Printers (MFP)";
    $scope.brandDisplayName = appConfig.brandDisplayName;
    $scope.onFreePlan = SessionService.hasUIResourceAction("releaseSendButton", "planUpgradePrompt");

    $scope.dismissSendModal = function () {
        $uibModalInstance.dismiss("cancel");
    };

    $scope.oneAtATime = true;
    $scope.kno2Send = function () {
        return $scope.onFreePlan ? "Kno2Send - Upgrade" : "Kno2Send";
    };
    $scope.kno2Fax = function () {
        return $scope.onFreePlan ? "Fax - Upgrade" : "Fax";
    };
    $scope.smartReleases = function () {
        return $scope.onFreePlan ? "Smart releases - Upgrade" : "Smart releases";
    };

    $scope.status = {
        isFirstOpen: true,
        isFirstDisabled: false
    };

    var init = function () {
        $scope.userProfile = SessionService.getProfile();
    };

    init();
}
