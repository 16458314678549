import { AdminEmergenciesService } from "./admin.emergencies.service";

let ctrl;
class AdminEmergenciesEditController {
    public static $inject: Array<string> = ["AdminEmergenciesService", "AdminOrganizationsService", "NotificationService", "$timeout"];

    public resolve;
    public close;
    public dismiss;

    public data: any;
    public saving: boolean;
    public emergency: any;

    constructor(
        private adminEmergenciesService: AdminEmergenciesService,
        private adminOrganizationsService,
        private notificationService,
        private $timeout
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.data = this.resolve.data;
        this.saving = false;
        if (this.data && this.data.id) {
            this.adminEmergenciesService
                .getById(this.data.id)
                .then((response) => (this.emergency = response.data))
                .catch(() => this.notificationService.error("Error retrieving emergency"));
        } else {
            this.emergency = {
                organizations: []
            };
        }
    }

    public search(searchString: string): Promise<any> {
        if (searchString) {
            const promise = this.adminOrganizationsService.getOrgs(searchString).then((response) => {
                return response;
            });
            return promise;
        }
    }

    public save(): void {
        this.saving = true;
        this.adminEmergenciesService
            .upsert(this.emergency)
            .then((response) => {
                this.notificationService.success("Emergency saved.");
                this.$timeout(ctrl.close(), 2000, false);
                this.saving = false;
            })
            .catch((error) => {
                this.notificationService.error(error.data.message);
                this.saving = false;
            });
    }

    public add(organization): void {
        this.emergency.organizations.push(organization);
    }

    public remove(organization): void {
        const index = this.emergency.organizations.indexOf(organization);
        if (index !== -1) this.emergency.organizations.splice(index, 1);
    }
}

export class AdminEmergenciesEditComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.emergencies.edit-modal.component.html");
        this.controller = AdminEmergenciesEditController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}
