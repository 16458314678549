<li
    id="settings-menu"
    class="dropdown"
    data-toggle="tooltip"
    title="Settings">
    <a
        class="btn dropdown-toggle"
        data-toggle="dropdown">
        <i class="fa fa-gear"></i>
    </a>
    <ul class="dropdown-menu dropdown-menu-right">
        <li
            role="presentation"
            class="dropdown-header">
            Settings
        </li>

        @if (isLicensedOrg && !isPrivateLabel) {
            <li ng-if="isLicensedOrg()">
                <a routerLink="/settings/licensedplan">
                    <i class="fa fa-credit-card"></i>
                    Licensed Plan
                </a>
            </li>
        }
        <li>
            <a routerLink="/settings/addons">
                <i class="fa fa-print"></i>
                Add-Ons
            </a>
        </li>
        @if (!currentUser().isNetworkAdmin) {
            <li>
                <a routerLink="/settings/users">
                    <i class="fa fa-users"></i>
                    Users
                </a>
            </li>
        }

        <li>
            <a routerLink="/settings/organization">
                <i class="fa fa-building-o"></i>
                Organization
            </a>
        </li>
        <li *kno2FeatureToggle="['ContactsManagement']">
            <a routerLink="/settings/contacts">
                <i class="fa fa-address-book-o"></i>
                Contacts
            </a>
        </li>
        <li *kno2FeatureToggle="['EnableExternalOpenTextAccount']">
            <a routerLink="/settings/faxaccount">
                <i class="fa fa-fax"></i>
                OpenText Account
            </a>
        </li>
        <li *kno2FeatureToggle="['EnableExternalEFaxAccount']">
            <a routerLink="/settings/efaxaccount">
                <i class="fa fa-fax"></i>
                eFax Account
            </a>
        </li>
        <li>
            <a routerLink="/settings/api">
                <i class="fa fa-exchange"></i>
                API
            </a>
        </li>
        <li *kno2FeatureToggle="['WhitelistIpAddressesUI']">
            <a routerLink="/settings/ipaddresses">
                <i class="fa fa-map-marker"></i>
                IP Addresses
            </a>
        </li>
        <li *kno2FeatureToggle="['PointClickCareIdentityProvider']">
            <a routerLink="/settings/identityproviders">
                <i class="fa fa-id-badge"></i>
                Identity Providers
            </a>
        </li>
        <li
            role="presentation"
            class="dropdown-header">
            Document Sources
        </li>

        <li>
            <a routerLink="/settings/directaddresses">
                <i class="fa fa-at"></i>
                Addresses
            </a>
        </li>

        <li *kno2FeatureToggle="['FaxDocumentSource']">
            <a routerLink="/settings/fax">
                <i class="fa fa-fax"></i>
                Fax Numbers
            </a>
        </li>

        @if (currentUser().isNetworkAdmin) {
            <li
                role="presentation"
                class="dropdown-header">
                Network
            </li>
            <li>
                <a routerLink="/settings/users">
                    <i class="fa fa-users"></i>
                    Network Users
                </a>
            </li>
            <li *kno2NetworkFeatureToggle="['SSO']">
                <a routerLink="/settings/network/identityprovider">
                    <i class="fa fa-id-badge"></i>
                    Identity Provider
                </a>
            </li>
        }

        <li
            role="presentation"
            class="dropdown-header">
            Interoperability
        </li>

        <li>
            <a routerLink="/settings/documenttypes">
                <i class="fa fa-file-text"></i>
                <span class="text">Document Types</span>
            </a>
        </li>
        <li *kno2FeatureToggle="['CdaDocumentTypes']">
            <a routerLink="/settings/cdadocumenttypes">
                <i class="fa fa-list"></i>
                <span class="text">CDA Document Types</span>
            </a>
        </li>
        <li>
            <a routerLink="/settings/rules">
                <i class="fa fa-sliders"></i>
                <span class="text">Intake Rules</span>
            </a>
        </li>
        <li *kno2FeatureToggle="['MessageDownloadClient']">
            <a routerLink="/settings/downloadprofiles">
                <i class="fa fa-cloud-download"></i>
                <span class="text">Download Profiles</span>
            </a>
        </li>
        <li>
            <a routerLink="/settings/exports">
                <i class="fa fa-download"></i>
                <span class="text">Intake Download</span>
            </a>
        </li>
        <li>
            <a routerLink="/settings/releasetypes">
                <i class="fa fa-upload"></i>
                <span class="text">Configure Releases</span>
            </a>
        </li>
    </ul>
</li>
