import { Pipe, PipeTransform } from "@angular/core";

export type SplitOnType = "number" | "uppercase" | "custom";

@Pipe({
    name: "splitOn",
    standalone: true
})
export class SplitOnPipe implements PipeTransform {
    private splitOnRegex: Record<Exclude<SplitOnType, "custom">, string> = {
        number: `(\\d+)`,
        uppercase: `([A-Z])`
    };
    /**
     * @param value - The string to be transformed.
     * @param splitOnType - The type of split to perform.
     * @param regex - The regular expression to split on, ignored if type is not 'custom'.
     * @returns The transformed value as a string, splitting on numeric values.
     * @example <span>{{ 12ac3Bbb }}</span> <!-- 12ac 3Bbb -->
     */
    transform(value: string, splitOnType: SplitOnType, re?: RegExp): string {
        if (!re && splitOnType === "custom") {
            throw new Error("Custom regex must be provided for custom split on type.");
        }
        if (!value) {
            return "";
        }
        if (splitOnType !== "custom") re = new RegExp(this.splitOnRegex[splitOnType], "g");

        return value ? value.replace(re, " $1").trim() : "";
    }
}
