ReviewerLookupModalCtrl.$inject = [
    "$uibModalInstance",
    "$timeout",
    "_",
    "IntegrationsService",
    "reviewerId",
    "reviewer",
    "reviewerGroupUuid",
    "reviewerGroup",
    "groupLookupAvailable",
    "reviewerNote"
];

export function ReviewerLookupModalCtrl(
    $uibModalInstance,
    $timeout,
    _,
    IntegrationsService,
    reviewerId,
    reviewer,
    reviewerGroupUuid,
    reviewerGroup,
    groupLookupAvailable,
    reviewerNote
) {
    var self = this;

    self.reviewer = reviewer ? _.pick(reviewer, "firstName", "lastName", "username") : null;
    self.selectedReviewerGroup = reviewerGroup ? _.pick(reviewerGroup) : null;
    self.reviewerNote = null;
    self.previousSelected = !!reviewerId || !!reviewerGroupUuid;
    self.search = search;
    self.selectReviewer = selectReviewer;
    self.selectReviewerGroup = selectReviewerGroup;
    self.remove = remove;
    self.confirm = confirm;
    self.dismiss = dismiss;
    self.listReviewerGroups = listReviewerGroups;
    self.aprimaV2Available = groupLookupAvailable;
    self.reviewerNote = reviewerNote;

    activate();

    function activate() {
        self.selectedTab = reviewerGroup || reviewerGroupUuid ? 2 : 1;

        if (reviewer) {
            self.reviewers = [reviewer];
            self.selectedReviewer = reviewer;
        } else if (reviewerId) {
            self.reviewersLoading = true;
            IntegrationsService.getUser(reviewerId).then(function (u) {
                if (u) {
                    self.reviewers = [u];
                    self.selectedReviewer = u;
                }
                self.reviewersLoading = false;
            });
        } else if (reviewerGroup) {
            self.selectedReviewerGroup = reviewerGroup;
            self.reviewerGroups = [reviewerGroup];
        } else if (reviewerGroupUuid) {
            self.reviewerGroupsLoading = true;
            IntegrationsService.getReviewerGroups().then(function (reviewerGroups) {
                var group = _.find(reviewerGroups, function (rg) {
                    return rg.groupUid === reviewerGroupUuid;
                });
                self.reviewerGroups = [group];
                self.selectedReviewerGroup = group;
                self.reviewerGroupsLoading = false;
            });
        }
    }

    function search() {
        $timeout(function () {
            var hasValues = self.reviewer && _.chain(self.reviewer).values().compact().value().length;
            if (self.reviewerLookupForm.$valid && hasValues) {
                self.reviewersLoading = true;
                IntegrationsService.getReviewers(self.reviewer).then(function (reviewers) {
                    self.reviewers = reviewers;
                    self.reviewersLoading = false;
                });
            }
        });
    }

    function listReviewerGroups() {
        if (self.reviewerGroups) return;
        $timeout(function () {
            self.reviewerGroupsLoading = true;
            IntegrationsService.getReviewerGroups().then(function (reviewerGroups) {
                self.reviewerGroups = reviewerGroups;
                self.reviewerGroupsLoading = false;
            });
        });
    }

    function selectReviewer(reviewer) {
        self.selectedReviewerGroup = null;
        if (self.selectedReviewer === reviewer) reviewer = null;
        self.selectedReviewer = reviewer;
    }

    function selectReviewerGroup(group) {
        self.selectedReviewer = null;
        if (self.selectedReviewerGroup === group) group = null;
        self.selectedReviewerGroup = group;
    }

    function remove() {
        $uibModalInstance.close();
    }

    function confirm() {
        $uibModalInstance.close({
            reviewer: self.selectedReviewer,
            group: self.selectedReviewerGroup,
            reviewerNote: self.reviewerNote
        });
    }

    function dismiss() {
        $uibModalInstance.dismiss("cancel");
    }
}
