<div
    (click)="toggleCollapse.emit()"
    class="inline-box-header rules-header">
    <div class="inline-box-title rules-header-title">
        <h2>Submit a Ticket</h2>
    </div>
    <span
        class="pull-left"
        kno2Popover
        [attr.data-content]="sectionTooltip">
        <i class="fa fa-info-circle box-info"></i>
    </span>
    <div class="header-ctrls">
        <div class="header-ctrl box-icon">
            <a>
                <i
                    class="fa"
                    [class.fa-chevron-down]="!expanded"
                    [class.fa-chevron-up]="expanded"></i>
            </a>
        </div>
    </div>
</div>
<div
    class="inline-box-content"
    [class.in]="expanded"
    [class.collapse]="!expanded">
    <form
        [formGroup]="form"
        id="ticketForm"
        class="form-horizontal"
        role="form"
        novalidate
        (ngSubmit)="onSubmit()">
        <div class="form-group row">
            <div class="input-group">
                <kno2-form-select
                    class="col-sm-7"
                    name="FeatureArea"
                    [control]="form.controls.area"
                    [hasError]="form.controls.area.invalid && hasSubmitted"
                    [options]="areaOptions"
                    placeholder="Select Feature Area" />
                <span
                    class="pull-left"
                    kno2Popover
                    [attr.data-content]="featureAreaTooltip"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-info-circle box-info"></i>
                </span>
            </div>

            <span
                class="pull-left"
                html-tip="SupportCenterAreas"></span>
        </div>

        <div class="form-group row">
            <div class="input-group">
                <kno2-form-select
                    class="col-sm-7"
                    name="TicketType"
                    [control]="form.controls.severity"
                    [hasError]="form.controls.severity.invalid && hasSubmitted"
                    [options]="severityOptions"
                    placeholder="Select Ticket Type" />
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-7">
                <label
                    for="TicketDescription"
                    class="control-label">
                    Describe your current error/issue.
                </label>
                <div class="input-group">
                    <textarea
                        class="w-100"
                        style="resize: none"
                        name="TicketDescription"
                        maxlength="5000"
                        rows="8"
                        cols="80"
                        [formControl]="form.controls.issueText"
                        [class.has-error]="form.controls.issueText.invalid && hasSubmitted"
                        [placeholder]="issueTextPlaceholder"></textarea>
                </div>
            </div>
        </div>

        <button
            id="submitTicket"
            type="submit"
            class="btn btn-primary ladda-button"
            style="width: 95px"
            title="Submit a Ticket to customer support."
            data-toggle="tooltip"
            [disabled]="isSubmitting">
            <span class="ladda-label">Submit</span>
        </button>
    </form>
</div>
