export class BrandService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    getBrands() {
        return this.$http.get("/api/admin/brands", { cache: true }).then((x) => x.data);
    }
}

BrandService.$inject = ["$http"];
