timeConfig.$inject = ["$window", "$timeout"];

export function timeConfig($window, $timeout) {
    if (!$window.__karma__ && $window.location.href.indexOf("kno2fy.com") === -1) {
        var appStartTime = Date.now();
        console.info("Load time:", appStartTime - $window.performance.timing.fetchStart + "ms");

        $timeout(function () {
            console.info("First digest:", Date.now() - appStartTime + "ms");
        });
    }
}
