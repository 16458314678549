@for (opt of options(); track opt[0]) {
    <label class="radio-inline">
        <input
            type="radio"
            [formControl]="control()"
            [value]="opt[1]"
            [ngClass]="hasError() ? errorClass() : ''" />
        {{ opt[0] }}
    </label>
}
