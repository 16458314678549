import { Component } from "@angular/core";

@Component({
    selector: "kno2-unauthorized",
    standalone: true,
    template: `
        <div class="row settings-general">
            <div class="col-sm-12">
                <div class="box-content">
                    <div class="well-lg">
                        <h1>Not Authorized!!</h1>
                        <p>You are not authorized to view this page</p>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class UnauthorizedComponent {}
