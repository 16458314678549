(function (undefined) {
    "use strict";

    angular.module("kno2.filters").filter("yesNo", yesNoFilter);

    yesNoFilter.$inject = [];

    function yesNoFilter() {
        return function (value) {
            if (value === true) return "Yes";
            if (value === false) return "No";
            return value;
        };
    }
})();
