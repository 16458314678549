import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { NetworkOrganizationListItemResponse } from "@kno2/ng1/common/services/networks.service";
import { Observable, firstValueFrom, shareReplay } from "rxjs";

export interface NetworkFeatureStatus {
    id: number;
    name: string;
    isEnabled: boolean;
    childNetworkFeatures: NetworkFeatureStatus[];
}

@Injectable({
    providedIn: "root"
})
export class NetworksService {
    private cache: Record<string, Observable<NetworkFeatureStatus[]>> = {};
    private httpClient = inject(HttpClient);

    public getNetwork = (networkId: string): Promise<any> => firstValueFrom(this.getNetwork$(networkId));
    public getNetworkFeatureStatuses = (networkId: string): Promise<NetworkFeatureStatus[]> => firstValueFrom(this.getNetworkFeatureStatuses$(networkId));
    public getNetworkOrganizations = (networkId: string): Promise<NetworkOrganizationListItemResponse[]> =>
        firstValueFrom(this.getNetworkOrganizations$(networkId));

    private getNetwork$(networkId: string) {
        return this.httpClient.get(`/api/networks/${networkId}`);
    }

    private getNetworkFeatureStatuses$(networkId: string): Observable<NetworkFeatureStatus[]> {
        const url = `/api/networks/${networkId}/features`;
        if (!this.cache[url]) {
            this.cache[url] = this.httpClient.get<NetworkFeatureStatus[]>(url).pipe(shareReplay(1));
        }
        return this.cache[url];
    }

    private getNetworkOrganizations$(networkId: string): Observable<NetworkOrganizationListItemResponse[]> {
        return this.httpClient.get<NetworkOrganizationListItemResponse[]>(`/api/admin/networks/${networkId}/organizations`);
    }
}
