export class AdminExternalServiceAccountsService {
    public static $inject: Array<string> = ["$http"];
    public routePrefix: string = "/api/admin/externalserviceaccounts";

    constructor(private $http) {}

    public createClient(request: any) {
        return this.$http.post(`${this.routePrefix}/clients`, request).then((response) => response.data);
    }

    public getClients(entityType: string, entityId: string) {
        return this.$http.get(`${this.routePrefix}/${entityType}/${entityId}/clients`).then((response) => response.data);
    }

    public getClientGrantType(clientId) {
        return this.$http.get(`${this.routePrefix}/granttypes/${clientId}`).then((response) => response.data);
    }

    public createGrantTypes(request: any) {
        return this.$http.post(`${this.routePrefix}/granttypes`, request).then((response) => response.data);
    }

    public updateGrantTypes(cgr: string, clientId: string, request: any) {
        return this.$http.post(`${this.routePrefix}/granttypes/${cgr}/${clientId}`, request).then((response) => response.data);
    }

    public getResourceServers() {
        return this.$http.get(`${this.routePrefix}/resourceservers`).then((response) => response.data);
    }

    public getResourceServer(id: string) {
        return this.$http.get(`${this.routePrefix}/resourceservers/${id}`).then((response) => response.data);
    }
}
