import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { SupportDownloadsComponent } from "./downloads/support.downloads.component";
import { SupportKnowledgeBaseComponent } from "./knowledge-base/support.knowledge-base.component";
import { SubmitATicketComponent } from "./submit-a-ticket/support.submit-a-ticket.component";

@Component({
    selector: "kno2-support",
    standalone: true,
    imports: [SubmitATicketComponent, SupportKnowledgeBaseComponent, SupportDownloadsComponent, NgbPopover],
    templateUrl: "./support.component.html"
})
export class SupportComponent implements OnInit {
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private readonly upgrade = inject(UpgradeModule);
    private readonly appConfig = this.upgrade.$injector.get("appConfig");

    protected brandDisplayName: string;
    protected knowledgeBaseUrl: string;

    public ngOnInit(): void {
        this.brandDisplayName = this.appConfig.brandDisplayName;
        this.knowledgeBaseUrl = this.appConfig.knowledgeBaseUrl;
    }

    public onToggleCollapse(path: string): void {
        const currentPath = this.route.snapshot.routeConfig.path;
        const targetPath = currentPath.endsWith(path) ? "/supportcenter" : `/supportcenter/${path}`;
        this.router.navigate([targetPath]);
    }
}
