(function (undefined) {
    "use strict";

    angular.module("kno2.directives").directive("importantAlertsBadge", importantAlertsBadge);

    importantAlertsBadge.$inject = [];

    function importantAlertsBadge() {
        var directive = {
            restrict: "EA",
            template: '<span ng-show="alerts.priorityHighCount > 0" class="badge badge-danger">{{alerts.priorityHighCount}}</span>'
        };
        return directive;
    }
})();
