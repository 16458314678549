import { Pipe, PipeTransform } from "@angular/core";

/**
 * A pipe that transforms a number based on a specified limit.
 * If the value exceeds the limit, it returns the limit followed by a plus sign.
 * Otherwise, it returns the value itself.
 */
@Pipe({
    name: "counter",
    standalone: true
})
export class CounterPipe implements PipeTransform {
    /**
     *
     * @param value - The input number to be transformed.
     * @param limit - The limit to compare the value against.
     * @returns The transformed value as a string if it exceeds the limit, otherwise the original value.
     * @example <span>{{ 15 | counter: 10 }}</span> <!-- 10+ -->
     */
    transform(value: number, limit: number): number | string {
        const result = value > limit ? `${limit}+` : value;
        return result;
    }
}
