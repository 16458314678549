(function () {
    "use strict";

    angular.module("kno2.services").factory("MessageHubService", MessageHubService);

    MessageHubService.$inject = ["hubProxyFactory", "NotificationService", "SessionService"];

    function MessageHubService(hubProxyFactory, NotificationService, SessionService) {
        var hubProxy = hubProxyFactory("messageHub"),
            currentMessageId = null,
            users = {};

        hubProxy.on("messageOpened", function (userId, userFullName) {
            messageAlreadyOpened();
            users[userId] = userFullName;
            NotificationService.notice(userFullName + " has opened this message.");
        });

        hubProxy.on("messageAlreadyOpened", function (userId, userFullName) {
            if (users[userId]) return;
            users[userId] = userFullName;
            NotificationService.notice(userFullName + " currently has this message open.");
        });

        hubProxy.on("messageClosed", function (userId) {
            delete users[userId];
            var userIds = Object.keys(users);
            if (userIds.length) NotificationService.notice(users[userIds[0]] + " currently has this message open.");
            else NotificationService.hideErrors();
        });

        return {
            openMessage: openMessage,
            closeMessage: closeMessage
        };

        function openMessage(messageId) {
            const profile = SessionService.getProfile();

            currentMessageId = messageId;
            hubProxy.qs = {
                messageId: messageId
            };
            return hubProxy.invoke("openMessage", messageId, profile.userId, profile.fullName).catch(function (e) {
                console.error(e);
            });
        }

        function closeMessage(messageId) {
            const profile = SessionService.getProfile();

            messageId = messageId || currentMessageId;
            currentMessageId = null;
            hubProxy.qs = {
                messageId: null
            };
            users = {};
            return hubProxy.invoke("closeMessage", messageId, profile.userId, profile.fullName).catch(function (e) {
                console.error(e);
            });
        }

        function messageAlreadyOpened(messageId) {
            const profile = SessionService.getProfile();

            messageId = messageId || currentMessageId;
            return hubProxy.invoke("messageAlreadyOpen", messageId, profile.userId, profile.fullName).catch(function (e) {
                console.error(e);
            });
        }
    }
})();
