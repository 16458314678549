import { AdminCertificatesService } from "./admin.certificates.service";

declare const angular;
declare const _;

let ctrl;
class AdminCertificatesController {
    public static $inject: Array<string> = ["AdminCertificatesService", "$uibModal", "NotificationService"];

    public certificates: Array<any>;

    constructor(
        private adminCertificatesService: AdminCertificatesService,
        private $uibModal,
        private notificationService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.certificates = [];
        this.loadCertificates();
    }

    public loadCertificates(): void {
        ctrl.adminCertificatesService.getCertificates().then((response) => {
            ctrl.certificates = response.data;
        });
    }

    public addOrEdit(cert: string): void {
        this.$uibModal
            .open({
                component: "k2AdminCertificatesEditComponent",
                resolve: { data: () => cert }
            })
            .result.then(this.loadCertificates, angular.noop);
    }

    public toggleEnabled(cert: any): void {
        ctrl.adminCertificatesService
            .enableCertificate(cert)
            .then((response) => {
                cert.enabled = !cert.enabled;
            })
            .catch((err) => {
                this.notificationService.errorToaster(`Could not ${cert.enabled ? "disable" : "enable"} certificate in KeyVault.`);
            });
    }
}

export class AdminCertificatesComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.certificates.component.html");
        this.controller = AdminCertificatesController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}
