import { BrandService } from "../../common/services/brand.service";
declare const angular;

class AdminBrandsController {
    public static $inject: Array<string> = ["BrandService", "$uibModal"];
    public brands: Array<any>;

    constructor(
        private brandService: BrandService,
        private $uibModal: any
    ) {}

    public $onInit(): void {
        this.brands = [];
        this.loadBrands().then(() => this.getHispNames());
    }

    public edit(brand: any): void {
        this.$uibModal
            .open({
                component: "k2AdminBrandsEdit",
                resolve: { data: () => brand }
            })
            .result.then(this.loadBrands, angular.noop);
    }

    private loadBrands(): Promise<void> {
        return this.brandService.getBrands().then((response) => (this.brands = response.items));
    }

    private getHispNames(): void {
        this.brands.forEach((x) => {
            x.documentSourceProviderNames = x.hispDetails.map((y) => y.documentSourceProviderName);
        });
    }
}

class AdminBrandsComponent {
    public templateUrl: any;
    public controller: any;

    constructor() {
        this.templateUrl = require("./admin-brands.component.html");
        this.controller = AdminBrandsController;
    }
}

export const adminBrandsComponent = new AdminBrandsComponent();
