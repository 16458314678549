import templateUrl from "./sift-metrics.component.html";

import { MongoSortDirections } from "../../common/mongo/mongo-sort-directions";

export const siftMetricsComponent = {
    templateUrl: templateUrl,
    controller: SiftMetricsController
};

SiftMetricsController.$inject = ["SiftMetricsService", "_"];

export function SiftMetricsController(SiftMetricsService, _) {
    let ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.refreshData = refreshData;
    ctrl.dateChange = dateChange;
    ctrl.sort = sort;

    function onInit() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        ctrl.sortDir = MongoSortDirections.Desc;
        ctrl.query = { start: today, end: today, col: "Count", sortDir: MongoSortDirections[ctrl.sortDir] };
        ctrl.fadir = "fa-chevron-down";

        updateMinMax();
        refreshData();
    }

    function refreshData() {
        ctrl.query.sortDir = MongoSortDirections[ctrl.sortDir];
        SiftMetricsService.get(ctrl.query).then((data) => {
            ctrl.data = data.concat(aggregateTotal(data));
        });
    }

    function sort(col) {
        ctrl.query.col = col;
        ctrl.sortDir = -ctrl.sortDir;
        ctrl.fadir = ctrl.sortDir === MongoSortDirections.Desc ? "fa-chevron-down" : "fa-chevron-up";
        refreshData();
    }

    function aggregateTotal(data) {
        if (!data.length) return [];
        const ignoredProps = ["id"];
        const total = data.reduce((a, b) => {
            for (let k in b) {
                if (b.hasOwnProperty(k) && ignoredProps.indexOf(k) === -1) {
                    a[k] = (a[k] || 0) + b[k];
                }
            }
            return a;
        }, {});
        total.id = "Total";
        total.elapsed = Math.floor(total.elapsed / data.length);
        return total;
    }

    function dateChange() {
        updateMinMax();
        refreshData();
    }

    function updateMinMax() {
        ctrl.startOptions = {
            maxDate: ctrl.query.end
        };

        ctrl.endOptions = {
            minDate: ctrl.query.start
        };
    }
}
