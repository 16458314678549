angular.module("kno2.factories").factory("PagingFactory", () => {
    const maxNavPages = 5; // Maximum of pages to display.

    return {
        paging: paging,
        getPageRange: getPageRange,
        maxNavPages: maxNavPages
    };

    function paging(settings) {
        var promise = {};
        promise.sortBy = settings.sortBy;
        promise.isSortDesc = typeof settings == "undefined" ? false : settings.isSortDesc;
        promise.sortIconClass = promise.isSortDesc ? "fa fa-chevron-down" : "fa fa-chevron-up";
        promise.currentPage = 1;
        promise.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        promise.pagedItemRange = promise.pagedItemRanges[0];
        return promise;
    }

    function getPageRange(page, currentPage, pageSize, totalItems) {
        const totalPages = Math.ceil(totalItems / pageSize);
        const lastPageRangeNumber = Math.ceil(currentPage / maxNavPages) * maxNavPages;
        const firstPageRangeNumber = lastPageRangeNumber - maxNavPages + 1;

        return {
            visible: new Array(lastPageRangeNumber).fill(true, firstPageRangeNumber - 1),
            isFirst: lastPageRangeNumber - maxNavPages == page,
            isLast: lastPageRangeNumber == page && page < totalPages
        };
    }
});
