<div class="col-sm-12">
    <h2>You have granted access to the following applications:</h2>
    <ul class="list-group">
        @for (app of authorizedApps(); track app.authorizationId) {
            <li class="list-group-item">
                <span>
                    <img
                        [src]="app.logoUrl"
                        style="overflow: hidden; width: 32px; height: 32px" />
                </span>
                <strong>{{ app.name }}</strong>
                <button
                    type="button"
                    class="btn btn-danger pull-right"
                    (click)="removeApplication(app.authorizationId)">
                    <i class="fa fa-trash"></i>
                </button>
            </li>
        }
    </ul>
</div>
