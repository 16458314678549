import templateUrl from "./admin-organizations-carequality-delete.modal.component.html";

export class AdminOrganizationsCarequalityDeleteModalController {
    constructor(AdminOrganizationsService, NotificationModalService) {
        this.adminOrganizationsService = AdminOrganizationsService;
        this.notificationModalService = NotificationModalService;
    }

    $onInit() {
        this.oid = { ...this.resolve };
    }

    cancel() {
        this.dismiss();
    }

    ok(oid) {
        const ctrl = this;
        this.adminOrganizationsService
            .deleteOrganizationAlternateOid(oid)
            .then(() => {
                ctrl.close();
            })
            .catch((response) => {
                ctrl.notificationModalService.error(response.data.modelState, "There was an error deleting this OID.");
            });
    }
}

AdminOrganizationsCarequalityDeleteModalController.$inject = ["AdminOrganizationsService", "NotificationService"];

export const adminOrganizationsCarequalityDeleteModalComponent = {
    templateUrl,
    controller: AdminOrganizationsCarequalityDeleteModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
