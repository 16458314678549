import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { saveAs } from "file-saver";
import { Observable, catchError, debounceTime, firstValueFrom, map, of } from "rxjs";
import { ContactUploadLatest } from "./contacts.models";

@Injectable({
    providedIn: "root"
})
export class ContactsService {
    public latestMetadata = () => toSignal(this.getUploadLatestMetadata$());
    public downloadContacts = () => firstValueFrom(this.downloadContacts$());
    public downloadErrorFile = (contactUploadId: number) => firstValueFrom(this.downloadErrorFile$(contactUploadId));
    public uploadContacts = (file: File) => firstValueFrom(this.uploadContacts$(file));

    constructor(private httpClient: HttpClient) {}

    private getUploadLatestMetadata$(): Observable<ContactUploadLatest> {
        return this.httpClient.get<ContactUploadLatest>("/api/contacts/uploads/latest");
    }

    private downloadContacts$(): Observable<Blob> {
        return this.httpClient.get("/api/contacts/download", { responseType: "blob" }).pipe(
            debounceTime(300),
            map((blob) => saveAs(blob, "contacts.csv"))
        );
    }

    private downloadErrorFile$(contactUploadId: number): Observable<Blob> {
        return this.httpClient
            .get(`/api/contacts/uploads/${contactUploadId}/errors`, { responseType: "blob" })
            .pipe(map((blob) => saveAs(blob, "Contacts-Upload-Errors.csv")));
    }

    private uploadContacts$(file: File): Observable<void> {
        const formData = new FormData();
        formData.append("file", file);

        return this.httpClient.post<void>("/api/contacts", formData).pipe(catchError((_) => of(null)));
    }
}
