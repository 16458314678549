import templateUrl from "./cda-document-types.component.html";
import angular from "angular";

export const cdaDocumentTypesComponent = {
    templateUrl: templateUrl,
    controller: CdaDocumentTypesController
};

CdaDocumentTypesController.$inject = ["CdaDocumentTypesService", "IntegrationsService", "DocumentTypesService", "NotificationService", "_"];

export function CdaDocumentTypesController(CdaDocumentTypesService, IntegrationsService, DocumentTypesService, NotificationService, _) {
    let ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.save = save;
    ctrl.reset = reset;
    ctrl.cdaDocumentTypes = {};

    function reset(key) {
        delete ctrl.cdaDocumentTypes[key];
    }

    function save() {
        let cdaDocumentTypes = [];
        Object.keys(ctrl.cdaDocumentTypes).forEach((key) => {
            const type = ctrl.cdaDocumentTypes[key];
            cdaDocumentTypes.push({
                templateId: key,
                documentType: type.name,
                documentTypeId: type.id,
                isDefault: key === "default"
            });
        });

        CdaDocumentTypesService.upsert(cdaDocumentTypes)
            .then((x) => NotificationService.success("CDA Document Types were saved successfully"))
            .catch((e) => NotificationService.error("There was an error saving CDA Document Types"));
    }

    function onInit() {
        CdaDocumentTypesService.get().then((res) => {
            res.forEach((x) => (ctrl.cdaDocumentTypes[x.templateId] = { name: x.documentType, id: x.documentTypeId }));
        });

        CdaDocumentTypesService.getTemplates().then((x) => (ctrl.templates = x));

        IntegrationsService.getCapabilities().then((res) => {
            const documentTypeLookup = _.find(res.capabilities, {
                display: IntegrationsService.capabilities.DocumentTypeLookup
            });
            const useIntegrationDocumentTypes = !!documentTypeLookup && documentTypeLookup.reference.indexOf("integrations") > -1;

            if (useIntegrationDocumentTypes) {
                IntegrationsService.getDocumentTypes().then((types) => {
                    ctrl.documentTypes = _.map(types, (x) => ({
                        id: x.id || x.name,
                        name: x.name
                    }));
                });
            } else {
                DocumentTypesService.getDocumentTypes().then((types) => {
                    ctrl.documentTypes = _.map(types.documentTypes, (x) => ({
                        id: x.name,
                        name: x.name
                    }));
                });
            }
        });
    }
}
