import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { WINDOW_TOKEN } from "@kno2/core/browser";
import { RedirectService } from "@kno2/core/routing";
import { SessionService } from "@kno2/core/session";
import { filter, firstValueFrom } from "rxjs";

@Component({
    selector: "kno2-callback",
    standalone: true,
    template: ""
})
export class CallbackComponent implements OnInit {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly router = inject(Router);
    private readonly redirectService = inject(RedirectService);
    private readonly sessionService = inject(SessionService);

    public async ngOnInit(): Promise<void> {
        this.router.navigate([], { fragment: null });
        await firstValueFrom(this.sessionService.profileData$.pipe(filter((profile) => !!profile)));
        this.complete();
    }

    public async complete(): Promise<void> {
        if (this.sessionService.isSysAdmin()) {
            this.window.location.href = "/admin/messaging/dashboard";
            return;
        }
        const redirectUrl = this.redirectService.getRedirectUrl() || this.sessionService.getDefaultRouteForActiveRole();

        this.router.navigateByUrl(redirectUrl);
    }
}
