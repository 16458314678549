declare const angular;
declare const _;

let ctrl;
class JSignFormController {
    public static $inject: Array<string> = [
        "DocumentSourcesService",
        "IntegrationsService",
        "SessionService",
        "WorkflowsProcessingService",
        "$interval",
        "_",
        "FeatureService"
    ];

    public documentSourceOptions: Array<any>;
    public connectedIntegrationTypes: Array<any>;
    public integration: any;
    public saveChanges: () => any;
    public sendEnvelopeProcessingWorkflow: any;
    public forwardViaDirectProcessingWorkflow: any;
    private signInWindow: any;
    private loginUrl: any;
    public allowedSenders: any;
    public envelopeTabs: Array<any>;
    public allowCarePlans: boolean;

    constructor(
        private DocumentSourcesService,
        private IntegrationsService,
        private SessionService,
        private WorkflowsProcessingService,
        private $interval,
        private _,
        private FeatureService
    ) {}

    public $onInit(): void {
        this.getDocumentSources();
        this.getConnectedIntegrationTypes();
        this.getSendEnvelopeProcessingWorkflow();
        this.getForwardViaDirectProcessingWorkflow();
        if (!this.integration.id) {
            this.integration.isDisabled = true;
        }
        if (this.integration.values.AllowedSenders) {
            this.allowedSenders = this.integration.values.AllowedSenders.split(";").map((x) => {
                return { text: x };
            });
        }
        this.allowCarePlans = this.FeatureService.isEnabled("AutomatedCarePlans");
    }

    private getDocumentSources(): void {
        this.DocumentSourcesService.getDocumentSources("asc", 1, 100).then((data) => {
            this.documentSourceOptions = data.documentSources.map((x) => x.address);
        });
    }

    private getConnectedIntegrationTypes(): void {
        this.IntegrationsService.listConnectedTypes().then((data) => {
            this.connectedIntegrationTypes = data.map((x) => {
                return { name: x.name, value: x.type };
            });
        });
    }

    private getSendEnvelopeProcessingWorkflow(): void {
        const profile = this.SessionService.getProfile();

        if (!!this.integration.values.SendEnvelopeProcessingWorkflowId) {
            this.WorkflowsProcessingService.getById(profile.organizationId, this.integration.values.SendEnvelopeProcessingWorkflowId).then((data) => {
                this.sendEnvelopeProcessingWorkflow = data.data;
            });
        }
    }

    private getForwardViaDirectProcessingWorkflow(): void {
        const profile = this.SessionService.getProfile();

        if (!!this.integration.values.ForwardViaDirectProcessingWorkflowId) {
            this.WorkflowsProcessingService.getById(profile.organizationId, this.integration.values.ForwardViaDirectProcessingWorkflowId).then((data) => {
                this.forwardViaDirectProcessingWorkflow = data.data;
            });
        }
    }

    public showCustomFieldsConfig(): boolean {
        return this.integration.values.IncludePatientDemographicsAsCustomFields || this.integration.values.IncludePatientDemographicsAsTabs;
    }

    public isSignedIn(): boolean {
        return !!this.integration.values.UserInfo && !!this.integration.values.UserInfo.email;
    }

    public clearAccount(): void {
        this.integration.values.UserIsAdmin = false;
        this.integration.values.AutoCheckConnectFailures = false;
        this.integration.values.UserInfo = {};
    }

    public connectedIntegrationIsForwardViaDirect(): boolean {
        return this.integration.values.ConnectedIntegration === "Inofile.Kno2.Integration.Integrations.DocusignConnect.ForwardViaDirectIntegration";
    }

    public signIn(): void {
        this.saveChanges().then(() => {
            this.signInWindow = window.open(this.loginUrl);
            this.checkSignInWindowClosed();
            this.signInWindow.focus();
        });
    }

    private checkSignInWindowClosed(): void {
        const interval = this.$interval(
            () => {
                if (this.signInWindow && this.signInWindow.closed) {
                    this.$interval.cancel(interval);
                    this.signInWindow = null;
                }
            },
            1000,
            120
        );
    }

    public saveJSignIntegration(): void {
        if (!!this.allowedSenders) {
            this.integration.values.AllowedSenders = this.allowedSenders.map((x) => x.text).join(";");
        }

        this.saveChanges();
    }
}

export class JSignFormComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./jSign-form.component.html");
        this.controller = JSignFormController;
        this.bindings = {
            dismissModal: "&",
            saveChanges: "&",
            selectedIntegration: "=",
            savingIntegration: "=",
            integration: "="
        };
    }
}
