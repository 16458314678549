(function () {
    "use strict";

    angular.module("kno2.release").filter("systemReleaseTypes", systemReleaseTypesFilter);

    systemReleaseTypesFilter.$inject = ["_"];

    function systemReleaseTypesFilter(_) {
        return _.debounce(function (releaseTypes) {
            if (!releaseTypes || !_.isArray(releaseTypes)) return releaseTypes;

            return _.filter(releaseTypes, function (r) {
                return !r.organizationId;
            });
        });
    }
})();
