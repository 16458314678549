angular.module("kno2.settings").directive("validateAddressMaxLength", [
    "SessionService",
    "ValidateFactory",
    function (SessionService, ValidateFactory) {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                element.on("keyup", function () {
                    scope.$apply(function () {
                        var fullAddress = element.val() + "@" + SessionService.getProfile().domain;
                        var maxSize = 254;
                        var message = stringFormat("Direct Address length should be less or equal than {0}.", maxSize);
                        var valid = fullAddress.length <= maxSize;
                        ValidateFactory.setValidity(element, attrs, ctrl, "addressmaxlength", valid, message);
                    });
                });
            }
        };
    }
]);
