export class AdminBatchImportsService {
    public static $inject = ["$http"];

    constructor(private $http) {}

    public getOrgs(name) {
        return this.$http.get("/api/organizations/", { params: { search: name } }).then(function (response) {
            return response.data.items;
        });
    }

    public getOrganizationTypes() {
        return this.$http.get("/api/organizations/types", { cache: true }).then((x) => x.data);
    }

    public getOrganizationSpecialties() {
        return this.$http.get("/api/organizations/specialties", { cache: true }).then((x) => x.data);
    }

    public getDirectProviders() {
        return this.$http.get("/api/admin/documentsources/documentsourceproviders?pageNumber=1&pageSize=20&type=DirectMessaging");
    }

    public getBatchStatus() {
        return this.$http.get("/api/admin/import");
    }

    public importOrganizations(file, data) {
        const formDataObj = new FormData();
        formDataObj.append("file", file);
        formDataObj.append("metadata", data);

        return this.$http({
            method: "POST",
            url: "/api/admin/import/organization",
            data: formDataObj,
            headers: {
                "Content-Type": undefined
            }
        });
    }
}
