class AdminBrandCreateController {
    public brand: any;

    $onInit() {}
}

class AdminBrandCreateComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./admin-brand.create.component.html");
        this.controller = AdminBrandCreateController;
        this.bindings = {};
    }
}

export const adminBrandCreateComponent = new AdminBrandCreateComponent();
