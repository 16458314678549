import { CommonModule } from "../common";
import { inviteRoutes } from "./invite.routes";
import { InviteService } from "./invite.service";
import { InviteCtrl } from "./invite.ctrl";

export const InviteModule = angular
    .module("kno2.invite", [CommonModule])
    .config(inviteRoutes)
    .service("InviteService", InviteService)
    .controller("InviteCtrl", InviteCtrl).name;
