OrderLookupModalCtrl.$inject = ["$uibModalInstance", "$q", "_", "IntegrationsService", "visit", "state", "allowMultipleOrders"];

export function OrderLookupModalCtrl($uibModalInstance, $q, _, IntegrationsService, visit, state, allowMultipleOrders) {
    var self = this;

    self.visit = visit;
    self.previousSelected = [];
    state.orderIds.forEach(function (x) {
        self.previousSelected.push(x);
    });
    self.remove = remove;
    self.confirm = confirm;
    self.dismiss = dismiss;
    self.aprimaV2Available = allowMultipleOrders;
    self.toggleSelection = toggleSelection;
    self.isSelected = isSelected;
    self.orders = [];

    self.orderIds = [];

    activate();

    self.thing = false;

    function toggleSelection(order) {
        var idx = self.orderIds.indexOf(order.id);
        if (idx > -1) {
            self.orderIds.splice(idx, 1);
        } else {
            if (!self.aprimaV2Available) {
                self.orderIds = [];
            }
            self.orderIds.push(order.id);
        }
    }

    function isSelected(o) {
        return self.orderIds.indexOf(o.id) > -1;
    }

    function activate() {
        IntegrationsService.getOrders(state.patientId, state.visitId).then((x) => {
            self.orders = x;
            self.orderIds = state.orderIds || [];
        });
    }

    function remove() {
        $uibModalInstance.close({
            visit: self.visit,
            orderIds: []
        });
    }

    function confirm() {
        $uibModalInstance.close({
            visit: self.visit,
            orderIds: self.orderIds
        });
    }

    function dismiss() {
        $uibModalInstance.close({
            visit: self.visit,
            orderIds: self.previousSelected
        });
    }
}
