let ctrl;

class SignatureRequestConfirmSendController {
    public static $inject: Array<string> = ["NotificationModalService", "NotificationService", "ReleaseService", "SessionService"];

    public resolve: any;
    public close: any;
    public dismiss: any;

    public message: any;
    public patient: any;
    public draftId: string;
    public templates: Array<any>;
    public sendInProgress: boolean;
    public toAddresses: Array<any>;

    public profile: any;

    constructor(
        private NotificationModalService,
        private NotificationService,
        private ReleaseService,
        private SessionService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.message = this.resolve.message;
        this.patient = this.resolve.patient;
        this.draftId = this.resolve.draftId;
        this.templates = this.resolve.templates;

        this.profile = this.SessionService.getProfile();

        this.setToAddresses();
    }

    public sendMessage(): void {
        this.sendInProgress = true;

        let message = {
            id: this.message.id,
            fromAddress: this.message.fromAddress,
            patient: this.patient,
            patientName: this.patient.fullName,
            origin: this.message.origin || "Kno2",
            subject: this.message.subject ? this.message.subject : this.message.releaseType,
            toAddress: `${this.profile.signaturesProfileSettings.toAddressPrefix}:Patient.Signers:SR`,
            signers: this.message.signers.map((x) => {
                return {
                    id: x.id,
                    role: x.role,
                    email: x.email,
                    name: x.name,
                    hostEmail: x.hostEmail,
                    hostName: x.hostName,
                    signerType: x.signerType,
                    additionalNotifications: x.additionalNotifications
                };
            })
        };

        this.ReleaseService.sendMessage(this.draftId, message)
            .then((res) => {
                this.NotificationService.success("The message was sent successfully.");
                this.close({ $value: "success" });
            })
            .catch((err) => {
                this.NotificationModalService.error("Failed to send message");
            });
        this.sendInProgress = false;
    }

    private setToAddresses(): void {
        this.toAddresses = this.message.signers.map((x) => (x.email ? x.email : x.hostEmail));
    }
}

class SignatureRequestConfirmSendComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./signatures.confirm-send-modal.component.html");
        this.controller = SignatureRequestConfirmSendController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const signatureRequestConfirmSendComponent = new SignatureRequestConfirmSendComponent();
