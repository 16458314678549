angular.module("kno2.controllers").controller("ManageCachedAddressesModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "AddressesCacheFactory",
    function ($scope, $uibModalInstance, AddressesCacheFactory) {
        $scope.title = "Saved Direct Addresses";
        $scope.pageSize = 5;
        $scope.currentPage = 1;
        $scope.hasChanged = false;
        $scope.isSortDesc = false;
        $scope.sortIconClass = "fa fa-chevron-up";
        $scope.manageCachedAddresses = AddressesCacheFactory.get() || [];

        $scope.sort = function () {
            $scope.isSortDesc = !$scope.isSortDesc;
            $scope.sortIconClass = $scope.isSortDesc ? "fa fa-chevron-down" : "fa fa-chevron-up";
            $scope.isSortDesc ? $scope.manageCachedAddresses.reverse() : $scope.manageCachedAddresses.sort();
            spliceResults();
        };

        var spliceResults = function () {
            var pageStart = ($scope.currentPage - 1) * $scope.pageSize;
            var pageEnd = $scope.currentPage * $scope.pageSize;

            // check to see if pageEnd is greater than total count
            if ($scope.manageCachedAddresses.length < pageEnd) {
                pageEnd = $scope.manageCachedAddresses.length;
            }
            $scope.dataPage = $scope.manageCachedAddresses.slice(pageStart, pageEnd);
        };

        $scope.$watch("currentPage", function () {
            spliceResults();
        });
        $scope.$watch("currentPage", function () {
            spliceResults();
        });

        $scope.closeModal = function () {
            $uibModalInstance.dismiss("cancel");
        };
        $scope.clearAll = function () {
            $scope.hasChanged = true;
            $scope.manageCachedAddresses = [];
        };
        $scope.saveChanges = function () {
            AddressesCacheFactory.save($scope.manageCachedAddresses);
            $uibModalInstance.close("save");
        };
        $scope.deleteAddress = function (deletedAddress) {
            $scope.hasChanged = true;
            $scope.manageCachedAddresses = _.without($scope.manageCachedAddresses, deletedAddress);
            spliceResults();
        };
        spliceResults();
    }
]);
