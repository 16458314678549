import templateUrl from "./service-account-list.component.html";
import angular from "angular";

export const serviceAccountListComponent = {
    templateUrl: templateUrl,
    controller: ServiceAccountListController
};

ServiceAccountListController.$inject = ["AdminServiceAccountService", "$confirm", "NotificationService"];

export function ServiceAccountListController(AdminServiceAccountService, $confirm, NotificationService) {
    var ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.remove = remove;

    function onInit() {
        getServiceAccounts();
    }

    function remove(clientId) {
        $confirm
            .open({
                bodyText: "Are you sure you want to delete " + clientId + "?"
            })
            .result.then(function () {
                AdminServiceAccountService.deleteServiceAccount(clientId)
                    .then(function () {
                        NotificationService.success(clientId + " successfully deleted");
                        getServiceAccounts();
                    })
                    .catch(function () {
                        NotificationService.error("Failed to delete " + clientId);
                    });
            })
            .catch(angular.noop);
    }

    function getServiceAccounts() {
        return AdminServiceAccountService.getServiceAccounts().then(function (data) {
            ctrl.serviceAccounts = data;
        });
    }
}
