import { MenuItem } from "./menu-item";

const MESSAGING_HEALTH_MENU_ITEM: MenuItem = {
    label: "Messaging Health",
    icon: "fa fa-bar-chart",
    route: "/dashboard/messaging"
};

const REPORTS_MENU_ITEM: MenuItem = {
    label: "Reports",
    icon: "fa fa-file-text-o",
    route: "/reports"
};

const SIFT_METRICS_MENU_ITEM: MenuItem = {
    label: "Sift Metrics",
    icon: "fa fa-file-pdf-o",
    route: "/siftmetrics"
};

export const USER_MENU_ITEMS: MenuItem[] = [
    {
        id: "dashboard",
        label: "Dashboard",
        icon: "fa fa-dashboard",
        route: "/account/dashboard",
        data: {
            roles: ["Administrator"]
        }
    },
    {
        id: "records",
        label: "Request Records",
        icon: "fa fa-file-code-o",
        route: "/records",
        data: {
            roles: ["Administrator", "User"]
        }
    },
    {
        id: "my-intake",
        label: "My Intake",
        icon: "fa fa-download",
        route: "/intake",
        data: {
            roles: ["Administrator", "User"]
        }
    },
    {
        id: "release",
        label: "Release",
        icon: "fa fa-upload",
        route: "/release",
        data: {
            roles: ["Administrator", "User"]
        }
    },
    {
        id: "find-provider",
        label: "Find Provider",
        icon: "fa fa-search",
        data: {
            roles: ["Administrator", "User"]
        }
    },
    {
        id: "get-signatures",
        label: "Get Signatures",
        icon: "fa fa-pencil",
        route: "/signatures",
        data: {
            roles: ["Administrator", "User"]
        }
    }
];

export const SYSADMIN_REPORTING_MENU_ITEMS: MenuItem[] = [MESSAGING_HEALTH_MENU_ITEM, REPORTS_MENU_ITEM, SIFT_METRICS_MENU_ITEM];

export const SYSADMIN_MENU_ITEMS: MenuItem[] = [
    {
        id: "admin-activity-diagnostics",
        label: "Activity / Diagnostics",
        icon: "fa fa-stethoscope",
        items: [
            MESSAGING_HEALTH_MENU_ITEM,
            {
                label: "Messages (old)",
                icon: "fa fa-envelope",
                route: "/messages"
            },
            {
                label: "Workflows",
                icon: "fa fa-envelope-o",
                route: "/dashboard/messaging/workflows"
            },
            {
                label: "Message Utils",
                icon: "fa fa-wrench",
                route: "/utils"
            },
            {
                label: "Bulk Workflow Retry",
                icon: "fa fa-recycle",
                route: "/workflows"
            },
            {
                label: "Secure Logs",
                icon: "fa fa-lock",
                route: "/securelogs"
            },
            REPORTS_MENU_ITEM,
            SIFT_METRICS_MENU_ITEM,
            {
                label: "Api Capture",
                icon: "fa fa-camera",
                route: "/apicapture"
            }
        ]
    },
    {
        id: "admin-system",
        label: "System",
        icon: "fa fa-cogs",
        items: [
            {
                label: "Alerts",
                icon: "fa fa-exclamation-triangle",
                route: "/alerts"
            },
            {
                label: "Emergencies",
                icon: "fa fa-exclamation",
                route: "/emergencies"
            },
            {
                label: "TOS",
                icon: "fa fa-gavel",
                route: "/eulas"
            },
            {
                label: "Subscription Plans",
                icon: "fa fa-dollar",
                route: "/subscriptionplans"
            },
            {
                label: "Reserved Words",
                icon: "fa fa-flag",
                route: "/reservedwords"
            },
            {
                label: "Health Service Areas",
                icon: "fa fa-users",
                route: "/healthserviceareas"
            },
            {
                label: "Health Referral Regions",
                icon: "fa fa-building-o",
                route: "/healthreferralregions"
            },
            {
                label: "New Features and Updates",
                icon: "fa fa-gift",
                route: "/features"
            },
            {
                label: "Feature Toggle",
                icon: "fa fa-unlock",
                route: "/featureToggle"
            },
            {
                label: "Downloads",
                icon: "fa fa-download",
                route: "/downloads"
            },
            {
                label: "Service Accounts",
                icon: "fa fa-user",
                route: "/serviceaccounts"
            },
            {
                label: "Client Applications",
                icon: "fa fa-share-alt",
                route: "/clientapplications"
            },
            {
                label: "Audit Logs",
                icon: "fa fa-calculator",
                route: "/audits"
            },
            {
                label: "Certificates",
                icon: "fa fa-certificate",
                route: "/certificates"
            },
            {
                label: "Batch Imports",
                icon: "fa fa-object-group",
                route: "/batchimports"
            }
        ]
    },
    {
        id: "admin-defaults",
        label: "Defaults",
        icon: "fa fa-compass",
        items: [
            {
                label: "Document Types",
                icon: "fa fa-file-text",
                route: "/documenttypes"
            },
            {
                label: "Release Types",
                icon: "fa fa-tag",
                route: "/releasetypes"
            },
            {
                label: "Welcome Messages",
                icon: "fa fa-file-text-o",
                route: "/welcomemessages"
            }
        ]
    },
    {
        id: "admin-directory",
        label: "Directory",
        icon: "fa fa-folder-open",
        items: [
            {
                label: "Directory Downloads",
                icon: "fa fa-cloud-download",
                route: "/directorydownloads"
            },
            {
                label: "Message Routing Rules",
                icon: "fa fa-exchange",
                route: "/messageroutingrules"
            },
            {
                label: "Directory",
                icon: "fa fa-folder",
                route: "/directory"
            },
            {
                label: "Records Request Facilities",
                icon: "fa fa-hospital-o",
                route: "/facilities"
            }
        ]
    },
    {
        id: "admin-document-sources",
        label: "Document Sources",
        icon: "fa fa-book",
        items: [
            {
                label: "Direct Addresses",
                icon: "fa fa-at",
                route: "/documentsources"
            },
            {
                label: "Fax Numbers",
                icon: "fa fa-fax",
                route: "/fax"
            }
        ]
    },
    {
        id: "admin-connectors",
        label: "Connectors",
        icon: "fa fa-plug",
        items: [
            {
                label: "Connector Types",
                icon: "fa fa-usb",
                route: "/connectors"
            },
            {
                label: "License Types",
                icon: "fa fa-certificate",
                route: "/licensetypes"
            },
            {
                label: "License Keys",
                icon: "fa fa-key",
                route: "/licensekeys"
            },
            {
                label: "Integrations",
                icon: "fa fa-plug",
                route: "/integrations"
            },
            {
                label: "Integration Whitelist",
                icon: "fa fa-list",
                route: "/integrations/whitelist"
            },
            {
                label: "Code Grants",
                icon: "fa fa-id-card-o",
                route: "/integrations/codegrants"
            },
            {
                label: "DocuSign",
                icon: "fa fa-pencil",
                route: "/docusign"
            }
        ]
    },
    {
        id: "admin-partners",
        label: "Partners",
        icon: "fa fa-handshake-o",
        items: [
            {
                label: "Resellers",
                icon: "fa fa-male",
                route: "/resellers"
            },
            {
                label: "Partners",
                icon: "fa fa-star",
                route: "/partners"
            }
        ]
    },
    {
        id: "admin-customers",
        label: "Customers",
        icon: "fa fa-users",
        items: [
            {
                label: "Brands",
                icon: "fa fa-tags",
                route: "/brands"
            },
            {
                label: "Networks",
                icon: "fa fa-sitemap",
                route: "/networks"
            },
            {
                label: "Organizations",
                icon: "fa fa-globe",
                route: "/organizations"
            },
            {
                label: "Users",
                icon: "fa fa-user",
                route: "/users"
            },
            {
                label: "Organization Types",
                icon: "fa fa-building-o",
                route: "/organizationTypes"
            },
            {
                label: "Organization Specialties",
                icon: "fa fa-building",
                route: "/organizationSpecialties"
            }
        ]
    }
];
