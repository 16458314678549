<div class="box-content">
    <div class="container">
        <div class="row">
            <div class="box">
                <div
                    class="box-header"
                    style="overflow: visible"
                    data-original-title>
                    <h2>
                        <i class="fa fa-address-book-o"></i>
                        <span class="break"></span>
                        Contacts
                    </h2>
                    <div class="box-icon">
                        <a
                            type="button"
                            k2-tooltip
                            title="Download Contacts File"
                            (click)="downloadContacts()">
                            <i class="fa fa-download"></i>
                        </a>
                    </div>
                </div>
                <div class="box-content d-flex align-items-center flex-column">
                    <kno2-file-dropzone
                        class="w-100"
                        accept=".csv"
                        [isLoading]="isUploading"
                        [multiple]="false"
                        (onFilesAdded)="uploadFile($event)"></kno2-file-dropzone>
                </div>
            </div>
        </div>
    </div>
</div>
