import templateUrl from "./integration-whitelist-list.component.html";

export class IntegrationWhitelistListController {
    constructor($uibModal, $confirm, AdminIntegrationsService, NotificationService) {
        this.$uibModal = $uibModal;
        this.$confirm = $confirm;
        this.AdminIntegrationsService = AdminIntegrationsService;
        this.NotificationService = NotificationService;
    }

    $onInit() {
        this.pageSize = 10;
        this.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        this.pagedItemRange = this.pagedItemRanges[0];
        this.currentPage = 1;
        this.getWhitelist();
    }

    getWhitelist() {
        return this.AdminIntegrationsService.getIntegrationWhitelist({
            pageNumber: this.currentPage,
            pageSize: this.pageSize,
            search: this.search
        }).then((x) => {
            this.whitelist = x.items;
            this.totalItems = x.totalCount;
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        });
    }

    add() {
        return this.$uibModal
            .open({
                component: "k2IntegrationWhitelistAddEditModal",
                resolve: {
                    whitelist: () => null
                }
            })
            .result.then(
                () => this.getWhitelist(),
                () => {
                    /* handle dismiss */
                }
            );
    }

    edit(id) {
        return this.$uibModal
            .open({
                component: "k2IntegrationWhitelistAddEditModal",
                resolve: {
                    whitelist: () => this.AdminIntegrationsService.getIntegrationWhitelistEntry(id)
                }
            })
            .result.then(
                () => this.getWhitelist(),
                () => {
                    /* handle dismiss */
                }
            );
    }

    remove(item) {
        return this.$confirm
            .open({
                bodyText: `Are you sure you want to remove <strong>${item.value}</strong> from the whitelist?`,
                okText: "Remove"
            })
            .result.then(
                () => {
                    this.AdminIntegrationsService.removeIntegrationWhitelistEntry(item.id).then(() => {
                        this.NotificationService.success("Whitelist value removed.");
                        this.getWhitelist();
                    });
                },
                () => {
                    /* handle dismiss */
                }
            );
    }
}

IntegrationWhitelistListController.$inject = ["$uibModal", "$confirm", "AdminIntegrationsService", "NotificationService"];

export const integrationWhitelistListComponent = {
    templateUrl,
    controller: IntegrationWhitelistListController
};
