class DynamicFormController {
    public static $inject: Array<string> = ["IntegrationsService", "NotificationModalService"];

    public integration: any;

    constructor(
        private integrationService,
        private notificationModalService
    ) {}

    public downloadCertificate(): void {
        this.integrationService
            .downloadCertificate(this.integration)
            .then(() => this.notificationModalService.success("Certificate successfully downloaded."))
            .catch((err) => {
                if (err.message) this.notificationModalService.error(err.message);
                else this.notificationModalService.serverError(err.modelState, "There was an error downloading the certificate.");
            });
    }
}

export class DynamicFormComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./dynamic-form.component.html");
        this.controller = DynamicFormController;
        this.bindings = {
            dismissModal: "&",
            saveChanges: "&",
            toggleCapability: "&",
            isAdvancedOption: "&",
            displayPropertyFormElement: "&",
            getInputType: "&",
            toggleCodeGrantCapability: "&",
            hasAdvancedOptions: "&",
            isRequiredInput: "&",
            displayCheckConnection: "&",
            checkConnection: "&",
            selectedIntegration: "=",
            integration: "=",
            setProperties: "=",
            readonlyProperties: "=",
            facilities: "=",
            codeGrants: "=",
            showAdvancedOptions: "=",
            checkingConnection: "=",
            connected: "=",
            savingIntegration: "=",
            getAdvancedOptionName: "&",
            getAdvancedOption: "&"
        };
    }
}
