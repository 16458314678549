import templateUrl from "./identityproviders.upsert.modal.component.html";
import { find, cloneDeep } from "lodash";

class IdentityProvidersUpsertModalController {
    constructor(IdentityProvidersService, NotificationModalService) {
        this.identityProvidersService = IdentityProvidersService;
        this.notificationModalService = NotificationModalService;
    }

    $onInit() {
        this.availableTypes = this.resolve.availableTypes;
        this.allowSsoBypass = [
            { label: "No", value: false },
            { label: "Yes", value: true }
        ];
        this.identityProvider = this.resolve.identityProvider;
        if (this.availableTypes.length === 1 && !this.resolve.identityProvider.name) {
            this.resolve.identityProvider.type = this.availableTypes[0].type;
        }

        this.identityProviderSelected();
    }

    identityProviderSelected() {
        this.selectedIdentityProvider = find(this.availableTypes, (x) => x.type === this.identityProvider.type);
    }

    saveChanges() {
        const idp = cloneDeep(this.identityProvider);
        Object.keys(idp.values).forEach((key) => {
            if (idp.values.hasOwnProperty(key) && idp.values[key] instanceof Array) {
                idp.values[key] = idp.values[key].map((x) => x.text).join(",");
            }
        });
        this.saving = true;
        this.identityProvidersService
            .upsert(idp)
            .then((res) => this.close({ $value: this.identityProvider }))
            .catch((err) => this.notificationModalService.error(err.data.message));
    }
}

IdentityProvidersUpsertModalController.$inject = ["IdentityProvidersService", "NotificationModalService"];

export const identityProvidersUpsertModalComponent = {
    templateUrl: templateUrl,
    controller: IdentityProvidersUpsertModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
