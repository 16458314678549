import jQuery from "jquery";
import DOMPurify from "dompurify";
import moment from "moment";
import { uniqueId } from "lodash";
import templateUrl from "./release.add-attachment-modal.component.html";

const $ = jQuery;

export const addAttachmentModalComponent = {
    templateUrl,
    controller: AddAttachmentModalCtrl,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AddAttachmentModalCtrl.$inject = [
    "$rootScope",
    "$q",
    "NotificationModalService",
    "CommonData",
    "Upload",
    "MessageFactory",
    "AttachmentService",
    "$filter",
    "FileService"
];

export function AddAttachmentModalCtrl($rootScope, $q, NotificationModalService, CommonData, Upload, MessageFactory, AttachmentService, $filter, FileService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.cancel = cancel;
    ctrl.isStructuredDocument = isStructuredDocument;
    ctrl.documentDateTypeChanged = documentDateTypeChanged;
    ctrl.getConfidentialityFlagName = getConfidentialityFlagName;
    ctrl.addAttachment = addAttachment;
    ctrl.onFileSelect = onFileSelect;
    ctrl.start = start;

    function $onInit() {
        ctrl.documentDateType = "native";
        ctrl.upload = [];
        ctrl.selectedFiles = [];
        ctrl.attachmentMeta = {
            documentType: null,
            confidentiality: null,
            documentDate: new Date(),
            documentDescription: ""
        };

        ctrl.dateOptions = { maxDate: new Date() };

        ctrl.documentTypes = ctrl.resolve.documentTypes;
        ctrl.confidentialityFlags = CommonData.confidentialityFlags.asArray();
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function setDocumentDate() {
        if ($("#nativeDateType").val() === "native") {
            const file = document.getElementById("selectedFile").files[0];
            if (file && file.lastModified) {
                const documentDate = moment(file.lastModified).toDate();
                ctrl.attachmentMeta.documentDate = new Date(documentDate.getFullYear(), documentDate.getMonth(), documentDate.getDate());
            }
        }
    }

    function setDocumentConfidentiality() {
        const file = document.getElementById("selectedFile").files[0];

        let confidentialityCode = "Normal";
        if (file && ctrl.isStructuredDocument()) {
            FileService.readFileAsText(file).then(
                function (data) {
                    data = DOMPurify.sanitize(data, { SAFE_FOR_JQUERY: true });
                    const jq = jQuery(data);
                    if (jq) {
                        const confidentiality = jq.find("confidentialitycode");
                        if (confidentiality) {
                            const code = confidentiality.attr("code");
                            switch (code) {
                                case "R":
                                    ctrl.confidentiality = "Restricted";
                                    confidentialityCode = "Restricted";
                                    break;
                                case "VR":
                                    ctrl.confidentiality = "Very Restricted";
                                    confidentialityCode = "VeryRestricted";
                                    break;
                                default:
                                    ctrl.confidentiality = "Normal";
                                    confidentialityCode = "Normal";
                            }
                        }
                    }
                },
                (error) => NotificationModalService.error(error)
            );
        }
        ctrl.attachmentMeta.confidentiality = confidentialityCode;
    }

    function isStructuredDocument() {
        if (ctrl.selectedFiles.length == 0) {
            // If file not selected yet, the Document Date will be shown by default
            return false;
        } else {
            const attachment = { fileName: ctrl.selectedFiles[0].name };
            const isStructuredDocument = MessageFactory.isStructuredDocument(attachment);
            if (isStructuredDocument) {
                $("#nativeDateType").val("native");
            }
            return isStructuredDocument;
        }
    }

    function documentDateTypeChanged(documentDateType) {
        if (documentDateType === "today") {
            var now = moment().utc().toDate();
            ctrl.attachmentMeta.documentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        }
        setDocumentDate();
        setDocumentConfidentiality();
    }

    function getConfidentialityFlagName(flagId) {
        return CommonData.confidentialityFlags.getById(flagId);
    }

    function addAttachment() {
        ctrl.uploading = true;
        validate()
            .then(function () {
                // Save changes if form valid
                ctrl.start(0); // We currently support only one file, just use index 0.
            })
            .catch(function (message) {
                ctrl.uploading = false;
                if (message) NotificationModalService.error(message);
            });
    }

    function validate() {
        return $q(function (resolve, reject) {
            if (!ctrl.form.$valid) return reject();
            if (!ctrl.selectedFiles.length) return reject("Please select a file.");
            if (ctrl.selectedFiles[0].size === 0) return reject("File is empty. Please upload something more substantial.");
            if (ctrl.selectedFiles[0].size > 20971520) return reject("Your attachment exceeds the maximum (20MB) allowed size.");
            if (!ctrl.supported) return reject();
            return resolve();
        });
    }

    function onFileSelect($files) {
        if (!$files || !$files.length) return;
        ctrl.selectedFiles = $files;
        NotificationModalService.hideErrors();

        const supported = _.some($files, function (f) {
            return $filter("isSupportedExtension")(f.name);
        });
        ctrl.supported = supported;

        if (!supported) {
            const f = $files[0];
            if (f.name && f.name.indexOf(".") !== -1) {
                const ext = f.name.substring(f.name.lastIndexOf(".") + 1);
                NotificationModalService.error(`Your attachment file type ${ext} is not supported`);
            } else {
                NotificationModalService.error("Your attachment file type is not supported");
            }
        } else {
            setDocumentDate();
            ctrl.attachmentMeta.confidentiality = 0;
            setDocumentConfidentiality();
        }
    }

    function start(index) {
        const uploadProcessId = uniqueId("upload_attachment_process_");
        const documentType = _.find(ctrl.documentTypes, (x) => x.id === ctrl.attachmentMeta.documentType);

        ctrl.attachmentMeta.documentType = documentType ? documentType.name : null;

        setDocumentDate();

        ctrl.upload[uploadProcessId] = AttachmentService.uploadAttachment(ctrl.resolve.messageId, ctrl.selectedFiles[index], ctrl.attachmentMeta).then(
            function (response) {
                //success
                $rootScope.$emit("onAddAttachmentSuccess", {
                    uploadProcessId: uploadProcessId,
                    attachment: response.data
                });
            },
            function () {
                //error
                $rootScope.$emit("onAddAttachmentError", {
                    uploadProcessId: uploadProcessId,
                    fileName: ctrl.selectedFiles[index].name
                });
            }
        );

        $rootScope.$emit("onAddAttachmentProcessStarted", {
            uploadProcessId: uploadProcessId,
            fileName: ctrl.selectedFiles[index].name
        });
        ctrl.close();
    }
}
