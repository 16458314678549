import { IController } from "angular";
import { AppConfigService } from "../../app-config";

class IdProofingModalHeaderCtrl implements IController {
    static $inject = ["AppConfigService"];
    public logo: string;

    constructor(private appConfigService: AppConfigService) {}

    public $onInit(): void {
        this.logo = this.appConfigService.getImageByName("logo-dark").url;
    }
}

export const idProofingModalHeaderComponent = {
    templateUrl: require("./account.id-proofing-modal-header.component.html"),
    controller: IdProofingModalHeaderCtrl,
    bindings: {
        headerTitle: "<"
    }
};
