import { signaturesRoutes } from "./signatures.routes";
import { signaturesComponent } from "./signatures.component";
import { signaturesNewDraftComponent } from "./message/signatures.new-draft.component";
import { patientTemplatesComponent } from "./message/patient-templates/patient-templates.component";
import { patientTemplatesAddComponent } from "./message/patient-templates/add-template-modal/patient-templates.add.component";
import { signatureRequestConfirmSendComponent } from "./message/confirm-send-modal/signatures.confirm-send-modal.component";
import { patientSearchModalComponent } from "./message/patient-templates/patient-search-modal/patient-search.modal.component";

export const SignaturesModule = angular
    .module("kno2.getSignatures", [])
    .config(signaturesRoutes)
    .component("signaturesComponent", signaturesComponent)
    .component("signaturesNewDraftComponent", signaturesNewDraftComponent)
    .component("patientTemplatesComponent", patientTemplatesComponent)
    .component("patientTemplatesAddComponent", patientTemplatesAddComponent)
    .component("signatureRequestConfirmSendComponent", signatureRequestConfirmSendComponent)
    .component("patientSearchModalComponent", patientSearchModalComponent).name;
