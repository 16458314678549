import templateUrl from "./admin-organizations-carequality-directory-active-toggle.modal.component.html";

export class AdminOrganizationsCarequalityDirectoryActiveToggleModalController {
    constructor(AdminOrganizationsService, NotificationModalService) {
        this.adminOrganizationsService = AdminOrganizationsService;
        this.notificationModalService = NotificationModalService;
    }

    $onInit() {
        this.entry = this.resolve.entry;
        this.orgId = this.resolve.orgId;
    }

    cancel() {
        this.dismiss();
    }

    ok(entry) {
        const ctrl = this;
        entry.isActive = !entry.isActive;
        this.adminOrganizationsService
            .upsertOrganizationDirectoryEntry(ctrl.orgId, entry)
            .then(() => {
                ctrl.close();
            })
            .catch((response) => {
                ctrl.notificationModalService.error(response.data.modelState, `There was an error ${entry.isActive ? "activating" : "deactivating"} this OID.`);
            });
    }
}

AdminOrganizationsCarequalityDirectoryActiveToggleModalController.$inject = ["AdminOrganizationsService", "NotificationService"];

export const adminOrganizationsCarequalityDirectoryActiveToggleModalComponent = {
    templateUrl,
    controller: AdminOrganizationsCarequalityDirectoryActiveToggleModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
