ApiCaptureService.$inject = ["$http"];

export function ApiCaptureService($http) {
    return {
        get
    };

    function get(query) {
        const params = { params: query };
        return $http.get("/api/diagnostics/apicapture", params).then((res) => res.data);
    }
}
