import templateUrl from "./webhooks-payload-preview.modal.component.html";

export const webhooksPayloadPreviewModalComponent = {
    templateUrl: templateUrl,
    controller: WebhooksPayloadPreviewModalController,
    bindings: {
        resolve: "<",
        modalInstance: "<"
    }
};

WebhooksPayloadPreviewModalController.$inject = [];

export function WebhooksPayloadPreviewModalController() {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.dismissModal = dismissModal;

    function $onInit() {
        ctrl.model = ctrl.resolve.model;
    }

    function dismissModal() {
        ctrl.modalInstance.dismiss();
    }
}
