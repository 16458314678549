angular.module("kno2.factories").factory("AuthInterceptorFactory", [
    "$rootScope",
    "$location",
    "$q",
    "NotificationService",
    "Auth0Service",
    function ($rootScope, $location, $q, NotificationService, Auth0Service) {
        return {
            request: async function (config) {
                if (config.skipInterceptors) return config;

                if (Auth0Service.isExpired()) {
                    await Auth0Service.renewTokens();
                }
                const token = Auth0Service.accessToken;

                if (token) {
                    config.headers = config.headers || {};
                    config.headers.Authorization = "Bearer " + token;
                }
                return config;
            },
            responseError: function (rejection) {
                if (rejection.status === 401 || rejection.status === 403) {
                    if (rejection.data && rejection.data.reason === "Token revoked or expired.") {
                        $rootScope.logoff();
                    } else {
                        NotificationService.errorToaster("You are not authorized to view this resource.");
                        $location.path("unauthorized").replace();
                    }
                }
                return $q.reject(rejection);
            }
        };
    }
]);
