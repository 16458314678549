angular.module("kno2.services").service("WestFaxService", [
    "$http",
    "CacheService",
    function ($http) {
        this.postAccount = function (resource) {
            return $http.put("/api/westfax/account", resource);
        };

        this.syncAccount = function (organizationId) {
            return $http.post(`/api/westfax/account/${organizationId}/sync`);
        };

        this.deleteWestFaxAccount = function (organizationId) {
            return $http.delete(`/api/westfax/account/${organizationId}`);
        };

        this.addWestFaxNumber = function (organizationId, resource) {
            return $http.post(`/api/westfax/account/${organizationId}`, resource);
        };

        this.deleteWestFaxNumber = function (organizationId, documentSourceId) {
            return $http.delete(`/api/westfax/account/${organizationId}/${documentSourceId}`);
        };
    }
]);
