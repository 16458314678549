import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FileSizePipe } from "@kno2/common/pipes";
import { ContactUploadLatestData } from "@kno2/data-access/contacts";

@Component({
    selector: "kno2-contacts-upload-success-card",
    imports: [DatePipe, FileSizePipe],
    templateUrl: "./contacts-upload-success-card.component.html",
    standalone: true
})
export class ContactsUploadSuccessCardComponent {
    @Input() public uploadData: ContactUploadLatestData;
}
