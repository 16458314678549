import templateUrl from "./admin-organization-specialties-edit.modal.component.html";

export const adminOrganizationSpecialtiesEditModalComponent = {
    templateUrl,
    controller: AdminOrganizationSpecialtiesEditModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AdminOrganizationSpecialtiesEditModalController.$inject = ["NotificationService", "OrganizationSpecialtiesService"];

export function AdminOrganizationSpecialtiesEditModalController(NotificationService, OrganizationSpecialtiesService) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.save = save;
    ctrl.cancel = cancel;

    function onInit() {
        ctrl.organizationSpecialtyId = ctrl.resolve.organizationSpecialtyId;

        if (ctrl.organizationSpecialtyId) {
            OrganizationSpecialtiesService.getSpecialty(ctrl.organizationSpecialtyId).then(function (response) {
                ctrl.organizationSpecialty = response;
            });
        }
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function save() {
        const promise = ctrl.organizationSpecialty.code
            ? OrganizationSpecialtiesService.update(ctrl.organizationSpecialty)
            : OrganizationSpecialtiesService.create(ctrl.organizationSpecialty);

        promise.then(
            function (response) {
                ctrl.close();
            },
            function (error) {
                NotificationService.error(error.message);
            }
        );
    }
}
