import { inject, Injectable } from "@angular/core";
import { Noty } from "./noty.token";

type NotificationOptions = {
    text: string;
    type: string;
};

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    private readonly noty = inject(Noty);

    public success(text: string): void {
        this.notify({ text, type: "success" });
    }

    public error(text: string): void {
        this.notify({ text, type: "error" });
    }

    private notify(options: NotificationOptions): void {
        this.noty({
            layout: "topCenter",
            timeout: 5000,
            maxVisible: 1,
            killer: true,
            closeWith: ["click"],
            animation: {
                open: { height: "toggle" },
                close: { height: "toggle" },
                easing: "swing",
                speed: 200
            },
            ...options
        });
    }
}
