import { fromEvent } from "rxjs";
import { messageOfType } from "../lib/observables";

export class CdaPreviewFrameController {
    constructor($element, $window, Auth0Service, appConfig) {
        this.$element = $element;
        this.$window = $window;
        this.Auth0Service = Auth0Service;
        this.token = this.Auth0Service.accessToken;
        this.iframe = this.$element.children("iframe").first();
        this.subscriptions = [
            fromEvent(this.$window, "message")
                .pipe(messageOfType("ready.cdapreview"))
                .subscribe(() => this.queuePreview())
        ];
        this.queuePreview = _.debounce(() => this.preview(), 300);
        this.appConfig = appConfig;
    }

    $onDestroy() {
        this.subscriptions.forEach((x) => x.unsubscribe && x.unsubscribe());
    }

    $onChanges(changes) {
        if (changes.attachment && changes.attachment.currentValue) this.attachment = changes.attachment.currentValue;
        if (changes.cacheId && changes.cacheId.currentValue) this.cacheId = changes.cacheId.currentValue;
        if (this.cacheId || this.attachment) this.queuePreview();
    }

    preview() {
        let value = {
            token: this.token
        };

        if (this.attachment && this.attachment.fileName.endsWith(".xml")) {
            value.messageUrl = `${this.appConfig.baseApiUrl}/api/messages/${this.attachment.messageId}/attachments/${this.attachment.id}`;
        } else if (this.cacheId) {
            value.messageUrl = `${this.appConfig.baseApiUrl}/api/Preview/Document/${this.cacheId}`;
        }

        this.iframe[0].contentWindow.postMessage({ type: "cdapreview", value }, this.$window.location.origin);
    }

    onError(error) {
        console.error(error);
    }
}

CdaPreviewFrameController.$inject = ["$element", "$window", "Auth0Service", "appConfig"];

export const cdaPreviewFrameComponent = {
    template: `<iframe class="cda-viewer-frame" src="${CDA_PREVIEW_PATH}" tabindex="-1"></iframe>`,
    controller: CdaPreviewFrameController,
    bindings: {
        cacheId: "<",
        attachment: "<"
    }
};
