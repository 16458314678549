<div class="box-content">
    <div class="container">
        <div class="row">
            <div class="box">
                <div
                    class="box-header"
                    style="overflow: visible"
                    data-original-title>
                    <h2>
                        <i class="fa fa-exclamation-circle text-danger"></i>
                        <span class="break"></span>
                        Error
                    </h2>
                </div>
                <div class="box-content d-flex align-items-center flex-column">
                    <div class="w-100 alert alert-danger">
                        <div class="text-center">
                            {{ uploadData.errorMessage }}
                        </div>
                    </div>
                    @if (uploadData.hasAdditionalDetailsFile) {
                        <button
                            type="button"
                            class="btn btn-primary mb-3"
                            [disabled]="isLoading"
                            (click)="downloadErrorFile()">
                            Download error file
                        </button>
                    }
                    <div>
                        <div>
                            <label class="font-weight-bold mr-2">Upload Date:</label>
                            <span>{{ uploadData.createDate + "Z" | date: "M/d/yyyy 'at' hh:mm a" }}</span>
                        </div>
                        <div>
                            <label class="font-weight-bold mr-2">Uploaded By:</label>
                            <span>{{ uploadData.createdByUserName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
