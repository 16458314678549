import templateUrl from "./integration-codegrants-orgs.modal.component.html";

export class IntegrationCodeGrantsOrgsModalController {
    constructor(AdminIntegrationCodeGrantsService, NotificationModalService, IntegrationsService, AdminUsersService) {
        this.AdminIntegrationCodeGrantsService = AdminIntegrationCodeGrantsService;
        this.NotificationModalService = NotificationModalService;
        this.IntegrationsService = IntegrationsService;
        this.AdminUsersService = AdminUsersService;
    }

    $onInit() {
        this.codeGrant = this.resolve.codeGrant;
    }

    getOrgs(val) {
        return this.AdminUsersService.getOrgs(val);
    }

    getCodeGrant() {
        this.AdminIntegrationCodeGrantsService.getCodeGrant(this.codeGrant.id).then((result) => (this.codeGrant = result));
    }

    addOrg() {
        if (!this.form.$valid) return;

        this.AdminIntegrationCodeGrantsService.addOrganization(this.codeGrant.id, this.selectedOrg.id)
            .then(() => {
                this.selectedOrg = null;

                this.getCodeGrant();
                this.NotificationModalService.success("The code grant organization was saved successfully.");
            })
            .catch((response) => {
                if (response.data.message) {
                    this.NotificationModalService.error(response.data.message);
                } else {
                    this.NotificationModalService.error("There was an error assigning this organization");
                }
            });
    }

    removeOrg(orgId) {
        this.AdminIntegrationCodeGrantsService.removeOrganization(this.codeGrant.id, orgId)
            .then(() => {
                this.getCodeGrant();
                this.NotificationModalService.success("The code grant organization was removed successfully.");
            })
            .catch((response) => {
                if (response.data.message) {
                    this.NotificationModalService.error(response.data.message);
                } else {
                    this.NotificationModalService.error("There was an error removing this organization");
                }
            });
    }
}

IntegrationCodeGrantsOrgsModalController.$inject = [
    "AdminIntegrationCodeGrantsService",
    "NotificationModalService",
    "IntegrationsService",
    "AdminUsersService"
];

export const integrationCodeGrantsOrgsModalComponent = {
    templateUrl,
    controller: IntegrationCodeGrantsOrgsModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
