intercomConfig.$inject = ["$transitions", "$window", "SessionService", "IntercomService"];

const blackListedHosts = [
    // 'kno2fy-dev.us'
];

const testAccounts = ["kno2+orgadmin@kno2.com", "kno2user@kno2.com"];

export function intercomConfig($transitions, $window, SessionService, IntercomService) {
    const profile = SessionService.getProfile();

    if (!$window.Intercom) return;
    if (blackListedHosts.indexOf(profile.host) > -1) return;
    if (testAccounts.some((x) => x === profile.userName)) return;

    $transitions.onStart({}, function () {
        // Send intercom update event
        $window.Intercom("update");
    });

    if (profile && profile.userId) {
        IntercomService.boot();
    }
}
