ShowAlertModalCtrl.$inject = ["$scope", "$uibModalInstance", "data", "alertIconClass"];
export function ShowAlertModalCtrl($scope, $uibModalInstance, data, alertIconClass) {
    $scope.title = "Notification";
    $scope.alert = data;
    $scope.alert.alertText = data.alertText.replace(/\n/g, "<br />").replace(/\r/g, "");
    $scope.alertIconClass = function () {
        return alertIconClass;
    };
    $scope.setForm = function (form) {
        $scope.form = form;
    };
    $scope.closeModal = function () {
        $uibModalInstance.dismiss("cancel");
    };
}
