validateUrlCodeDirective.$inject = ["ValidateFactory"];

export function validateUrlCodeDirective(ValidateFactory) {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ctrl) {
            element.on("keyup", function (evt) {
                var message = "You must provide a valid Url Code. Valid characters are: 0-9 a-z _ -";
                var val = element.val().toLowerCase();
                var pattern = /^[a-zA-Z0-9\-_]+$/;
                var regEx = new RegExp(pattern);
                var valid = regEx.test(val);
                var urlCode = scope.reseller.fullUrl.split(scope.resellerUrlPattern);
                var newFullUrl = urlCode.length == 2 ? urlCode[0] + scope.resellerUrlPattern + val : "";

                if (newFullUrl != "") {
                    // Reference: http://regexlib.com/REDetails.aspx?regexp_id=2841
                    pattern =
                        /^((http(s){0,1}\:\/\/){0,1}([a-z|A-Z|0-9|\.|\-|_]){4,255}(\:\d{1,5}){0,1}){0,1}((\/([a-z|A-Z|0-9|\.|\-|_]|\%[A-F|a-f|0-9]{2}){1,255}){1,255}\/{0,1}){0,1}(|\/{0,1}\?[a-z|A-Z|0-9|\.|\-|_]{1,255}\=([a-z|A-Z|0-9|\.|\-|_|\+|\:]|\%[A-F|a-f|0-9]{2}|\&[a-z|A-Z]{2,12}\;){0,255}){0,1}((\&[a-z|A-Z|0-9|\.|\-|_]{1,255}\=([a-z|A-Z|0-9|\.|\-|_|\+|\:]|\%[A-F|a-f|0-9]{2}|\&[a-z|A-Z]{2,12}\;){0,255}){0,255})(\/{0,1}|\#([a-z|A-Z|0-9|\.|\-|_|\+|\:]|\%[A-F|a-f|0-9]{2}|\&[a-z|A-Z]{2,12}\;){0,255})$/;
                    regEx = new RegExp(pattern);
                    valid = regEx.test(newFullUrl);
                    if (!valid) {
                        message = "Url is not valid.";
                    }

                    var _msg = "Url can't use consecutive character '{0}'.";
                    if (valid) {
                        valid = newFullUrl.indexOf("--") == -1;
                        if (!valid) {
                            message = stringFormat(_msg, "-");
                        }
                    }

                    if (valid) {
                        valid = newFullUrl.indexOf("__") == -1;
                        if (!valid) {
                            message = stringFormat(_msg, "_");
                        }
                    }
                }

                // Build fullUrl
                scope.reseller.fullUrl = urlCode.length == 2 ? urlCode[0] + scope.resellerUrlPattern + (valid ? val : "") : "";
                scope.reseller.urlCode = val;
                scope.$apply();

                ValidateFactory.setValidity(element, attrs, ctrl, "validurlcode", valid, message);
            });
        }
    };
}

validateUrlCodeReservedWordsDirective.$inject = ["ValidateFactory", "AdminReservedWordsService", "$timeout"];

export function validateUrlCodeReservedWordsDirective(ValidateFactory, AdminReservedWordsService, $timeout) {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ctrl) {
            element.bind("change", function (evt) {
                scope.$apply(function () {
                    var val = element.val().toLowerCase();
                    if (val != "") {
                        AdminReservedWordsService.isReservedWord(val).then(function (result) {
                            var message = "The Url Code contains a reserved word. Please enter a valid Url Code.";
                            var valid = result.data == false;
                            ValidateFactory.setValidity(element, attrs, ctrl, "validurlcodereservedwords", valid, message);
                            $timeout(function () {
                                element.blur();
                            }); // Force element blur to hide the error immedialy
                        });
                    }
                });
            });
        }
    };
}

export function randomString(length) {
    const characters = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i) result += characters[Math.floor(Math.random() * characters.length)];
    return result;
}
