(function () {
    angular.module("kno2.services").service("EventService", eventService);

    eventService.$inject = ["$http", "$location", "CacheService", "SessionService", "CsvService", "_"];

    function eventService($http, $location, CacheService, SessionService, CsvService, _) {
        this.messageDocument = undefined;

        this.redirectToReleaseView = function (message) {
            this.messageDocument = message;
            $location.path("/release/" + message.id);
        };

        this.getRecentEvents = function (pageSize) {
            return $http
                .get("/api/messages/events", {
                    params: {
                        pageSize: pageSize || 30,
                        eventTypes: ["Delivered,Exported,Forwarded,Replied,Deleted,Bounced,Failed,Processed,Received"],
                        start: moment().utc().subtract(14, "days").startOf("day").toDate()
                    },
                    data: "",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    cache: CacheService.cacheL1()
                })
                .then(function (response) {
                    return response.data;
                });
        };

        this.getMyRecentEvents = function (pageSize, page, mailMessageId, filter) {
            const userId = SessionService.getProfile().userId;
            pageSize = pageSize || 30;
            page = page || 1;
            const params = { pageSize: pageSize, pageStart: page, mailMessageId: mailMessageId };

            if (filter) _.assign(params, filter);

            return $http.get("/api/users/{id}/events".replace("{id}", userId), {
                params: params,
                throbber: true
            });
        };

        this.getDocusignRecentEvents = function (pageSize, page, filter) {
            const userId = SessionService.getProfile().userId;
            pageSize = pageSize || 30;
            page = page || 1;
            const params = { pageSize: pageSize, pageStart: page };

            if (filter) _.assign(params, filter);

            return $http.get("/api/users/{id}/events/signatures".replace("{id}", userId), {
                params: params,
                throbber: true
            });
        };

        this.getMyRecentRequestEvents = function (top, page) {
            const userId = SessionService.getProfile().userId;
            return $http.get("/api/users/{id}/requests".replace("{id}", userId), {
                params: { pageSize: top || 10, pageStart: page || 1 }
            });
        };

        this.getMyRecentEventsByType = function (type, top) {
            const userId = SessionService.getProfile().userId;
            return $http
                .get("/api/users/{id}/events/type".replace("{id}", userId), {
                    params: {
                        eventType: type,
                        pageSize: top || 30
                    },
                    cache: CacheService.cacheL1()
                })
                .then(function (response) {
                    return response.data;
                });
        };

        this.getMessageEventsReport = function (options) {
            const defaults = {
                pageSize: 30,
                pageStart: 1,
                eventType: "",
                timezoneOffset: new Date().getTimezoneOffset()
            };
            const parameters = _.extend({}, defaults, options);
            return $http({
                url: "/api/events",
                method: "GET",
                params: parameters
            }).then(function (response) {
                return response.data;
            });
        };

        this.raiseReadEvent = function (messageId) {
            return $http.put("/api/messages/{id}/read".replace("{id}", messageId)).then(function (response) {
                return response.data;
            });
        };

        this.raiseAttachmentReadEvent = function (messageId, attachmentId) {
            const url = "/api/messages/{messageId}/attachments/{attachmentId}/read".replace("{messageId}", messageId).replace("{attachmentId}", attachmentId);
            return $http.put(url).then(function (response) {
                return response.data;
            });
        };

        this.getRecentEventsReport = function (params, callback) {
            // workaround due to angular bug https://github.com/angular/angular.js/commit/9dce42b3c26eb02621723172a68725980369b849
            const p = `?start=${params.start.toISOString()}&end=${params.end.toISOString()}`;
            const url = `/api/messages/eventsreport${p}`;
            const request = {
                pageSize: 10000,
                eventTypes: ["Delivered,Exported,Forwarded,Replied,Deleted,Bounced,Failed,Processed,Received"]
            };

            return CsvService.getCsvFile(url, "Activity", request, callback);
        };

        this.acknowledgeFailedMessage = function (id) {
            return $http.put("/api/failedmessages/" + id + "/acknowledge").then(function (res) {
                return res.data;
            });
        };

        this.acknowledgeAllFailedMessages = function () {
            return $http.post("/api/failedmessages/acknowledgeall").then(function (res) {
                return res.data;
            });
        };
    }
})();
