angular.module("kno2.settings").controller("EditUserModalCtrl", [
    "$uibModalInstance",
    "userId",
    "usersFactory",
    "NotificationService",
    "SessionService",
    "ValidationService",
    "DocumentSourceService",
    "RulesService",
    "_",
    "SingleSignOnService",
    function (
        $uibModalInstance,
        userId,
        usersFactory,
        NotificationService,
        sessionService,
        ValidationService,
        DocumentSourceService,
        RulesService,
        _,
        singleSignOnService
    ) {
        const ctrl = this;
        ctrl.title = "Update User";
        ctrl.currentUser = sessionService.getProfile();
        ctrl.userId = userId;
        ctrl.isCurrentUser = userId.toUpperCase() === sessionService.getProfile().userId.toUpperCase();
        ctrl.validateUsername = validateUsername;
        ctrl.saveChanges = saveChanges;
        ctrl.setForm = setForm;
        ctrl.closeModal = closeModal;
        ctrl.selected = {};
        ctrl.addAllDocumentSources = addAllDocumentSources;
        ctrl.addAllIntakeRules = addAllIntakeRules;
        ctrl.isSingleSignOnOrg = singleSignOnService.isOrgSSOEnabled();

        ctrl.$onInit = activate;

        function activate() {
            usersFactory.userfororg(ctrl.userId).then(
                function (res) {
                    // success
                    ctrl.user = res.data;
                    var username = ctrl.user.userName;
                    var split = username.split("|");
                    if (split.length == 3) {
                        ctrl.displayedUserName = split[2];
                        ctrl.identityProvider = "PointClickCare";
                    } else {
                        ctrl.displayedUserName = ctrl.user.userName;
                        ctrl.identityProvider = "Kno2";
                    }
                },
                function (status) {
                    // error
                }
            );

            const promises = [];

            promises.push(
                DocumentSourceService.getOrgDocumentSources()
                    .then(function (data) {
                        ctrl.globalDocumentSources = data.filter((d) => d.isAssociatedWithAllActiveOrganizationUsers);
                        ctrl.additionalDocumentSources = data.filter((d) => !d.isAssociatedWithAllActiveOrganizationUsers);
                    })
                    .then(function () {
                        return DocumentSourceService.getDocumentSourcesByUser(userId).then(function (data) {
                            ctrl.selected.documentSources = filterBy(ctrl.additionalDocumentSources, data, "id");
                        });
                    })
            );

            promises.push(
                RulesService.getAllRules()
                    .then((data) => {
                        ctrl.globalIntakeRules = data.filter((d) => d.isAssociatedWithAllActiveOrganizationUsers);
                        ctrl.additionalIntakeRules = data.filter((d) => !d.isAssociatedWithAllActiveOrganizationUsers);
                    })
                    .then(() => {
                        return RulesService.getRules(userId).then((data) => {
                            ctrl.selected.intakeRules = filterBy(ctrl.additionalIntakeRules, data, "id");
                        });
                    })
            );

            Promise.all(promises).then(function () {});
        }

        function addAllDocumentSources() {
            if (!ctrl.selected.documentSources) {
                ctrl.selected.documentSources = [];
            }
            _.each(ctrl.additionalDocumentSources, function (documentSource) {
                ctrl.selected.documentSources.push(documentSource);
            });
        }

        function addAllIntakeRules() {
            if (!ctrl.selected.intakeRules) {
                ctrl.selected.intakeRules = [];
            }
            _.each(ctrl.additionalIntakeRules, (intakeRule) => {
                ctrl.selected.intakeRules.push(intakeRule);
            });
        }

        function filterBy(collection1, collection2, prop1, prop2) {
            if (prop2 === undefined) prop2 = prop1;
            var props = _.map(collection2, prop2);
            return _.filter(collection1, function (value) {
                return _.includes(props, value[prop1]);
            });
        }

        function setForm(form) {
            ctrl.form = form;
        }

        function closeModal() {
            $uibModalInstance.dismiss("cancel");
        }

        function validateUsername(username, callback) {
            ValidationService.validateUsername(username, callback);
        }

        function saveChanges() {
            if (ctrl.form && ctrl.form.$valid) {
                const documentSources = _(ctrl.selected.documentSources).map(function (d) {
                    return {
                        documentSourceId: d.id,
                        organizationId: ctrl.currentUser.organizationId
                    };
                });
                const intakeRules = _(ctrl.selected.intakeRules).map((x) => {
                    return {
                        organizationId: ctrl.currentUser.organizationId,
                        intakeRuleId: x.id
                    };
                });

                const promises = [
                    usersFactory.save(ctrl.user),
                    DocumentSourceService.setDocumentSourcesForUser(userId, documentSources),
                    RulesService.setIntakeRulesForUser(userId, intakeRules)
                ];

                Promise.all(promises).then(
                    () => {
                        NotificationService.success("User has been saved successfully.");
                        sessionService.refreshProfile();
                        $uibModalInstance.close(ctrl.user);
                    },
                    (res) => {
                        NotificationService.serverError(res.data.modelState, "There was an error saving this user.");
                    }
                );
            }
        }
    }
]);
