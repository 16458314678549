ReceiveModalCtrl.$inject = ["$scope", "$uibModalInstance", "SubscriptionService", "SessionService", "appConfig"];
export function ReceiveModalCtrl($scope, $uibModalInstance, SubscriptionService, SessionService, appConfig) {
    $scope.brandDisplayName = appConfig.brandDisplayName;
    $scope.showIntegrateScannerCard = appConfig.brand === "Kno2";

    $scope.dismissReceiveModal = function () {
        $uibModalInstance.dismiss("cancel");
    };

    $scope.oneAtATime = true;

    $scope.status = {
        isFirstOpen: true,
        isFirstDisabled: false
    };

    $scope.triageRulesClick = function (event) {
        if (SubscriptionService.checkAndShowPrompt("triageRoute")) event.preventDefault();
    };

    var init = function () {
        $scope.userProfile = SessionService.getProfile();
    };

    init();
}
