import { DOCUMENT } from "@angular/common";
import { effect, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";

declare const $: JQueryStatic;
@Injectable({
    providedIn: "root"
})
export class TooltipsService {
    private readonly document = inject(DOCUMENT);
    private readonly router = inject(Router);
    private readonly routerEvent = toSignal(this.router.events);

    public constructor() {
        effect(() => {
            if (this.routerEvent() instanceof NavigationEnd) {
                const tooltips = this.document.querySelectorAll(".tooltip");
                tooltips.forEach((tooltip) => {
                    tooltip.remove();
                });
            }
        });
    }

    public init(): void {
        $("body").tooltip({ selector: "[data-toggle=tooltip]" });

        $(this.document).on("click", () => $('[data-toggle="tooltip"]').tooltip("hide"));
        $(this.document).on("mouseenter", '.dropdown[data-toggle="tooltip"] > .dropdown-menu', function () {
            $(this).closest('.dropdown[data-toggle="tooltip"]').tooltip("hide");
        });

        $(this.document).on("mouseleave", '.dropdown[data-toggle="tooltip"] > .dropdown-menu', function () {
            const parentLi = $(this).closest('.dropdown[data-toggle="tooltip"]');
            if (parentLi.is(":hover")) {
                parentLi.tooltip("show");
            }
        });
    }
}
