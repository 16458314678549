export class AdminIntegrationCodeGrantsService {
    constructor($http) {
        this.$http = $http;
    }

    getCodeGrants(criteria) {
        const options = { params: criteria };
        return this.$http.get("/api/admin/codegrants?pageSize=1000", options).then(function (response) {
            return response.data;
        });
    }

    getCodeGrant(id) {
        return this.$http.get(`/api/admin/codegrants/${id}`).then(function (response) {
            return response.data;
        });
    }

    addCodeGrant(entry) {
        return this.$http.post("/api/admin/codegrants", entry).then(function (response) {
            return response.data;
        });
    }

    updateCodeGrant(id, entry) {
        return this.$http.put(`/api/admin/codegrants/${id}`, entry).then(function (response) {
            return response.data;
        });
    }

    addOrganization(id, orgId) {
        return this.$http.put(`/api/admin/codegrants/${id}/organizations/${orgId}`).then(function (response) {
            return response.data;
        });
    }

    removeOrganization(id, orgId) {
        return this.$http.delete(`/api/admin/codegrants/${id}/organizations/${orgId}`).then(function (response) {
            return response.data;
        });
    }

    toggledIsDisabled(id) {
        return this.$http.delete(`/api/admin/codegrants/${id}/enable`).then(function (response) {
            return response.data;
        });
    }
}

AdminIntegrationCodeGrantsService.$inject = ["$http"];
