import eventPillStatusTemplate from "./release.event-pill-status.html";

angular.module("kno2.directives").directive("eventPillStatus", function () {
    return {
        restrict: "A",
        templateUrl: eventPillStatusTemplate,
        scope: {
            status: "@"
        },
        link: function (scope) {
            scope.status = _.isEmpty(scope.status) ? "Unknown" : scope.status;
        }
    };
});
