import { fromEvent } from "rxjs";
import { messageOfType } from "../lib/observables";

export const NOT_SELECTED = "/content/documents/no-document-selected.pdf";
export const FAILED = "/content/documents/preview-not-available.pdf";
export const PENDING = "/content/documents/document-being-converted.pdf";

export const pdfViewerFrameComponent = {
    template: `<iframe class="doc-viewer" src="${VIEWER_PATH}" tabindex="-1"></iframe>`,
    controller: PdfViewerFrameController,
    bindings: {
        attachment: "<",
        options: "<",
        disableAudit: "<",
        urlFn: "<",
        change: "&"
    }
};

PdfViewerFrameController.$inject = ["$element", "$window", "$scope", "_", "EventService", "SpinnerService", "Auth0Service", "MessageFactory", "appConfig"];

function PdfViewerFrameController($element, $window, $scope, _, EventService, SpinnerService, Auth0Service, MessageFactory, appConfig) {
    const ctrl = this;

    ctrl.subscriptions = [];
    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.$onDestroy = $onDestroy;

    function $onInit() {
        if (!isFunction(ctrl.urlFn)) {
            ctrl.urlFn = (x) => `${appConfig.baseApiUrl}/api/messages/${x.messageId}/attachments/${x.id}`;
        }

        ctrl.iframe = $element.children("iframe").first();

        ctrl.iframe.on("load", () => {
            const messages$ = fromEvent($window, "message");

            ctrl.subscriptions.forEach((x) => x.unsubscribe && x.unsubscribe());

            ctrl.subscriptions = [
                messages$.pipe(messageOfType("loaded.kno2PdfViewer")).subscribe(() => {
                    SpinnerService.stop();
                }),

                messages$.pipe(messageOfType("rotatePage.kno2PdfViewer")).subscribe((data) => {
                    $scope.$apply(function () {
                        const transforms = getTransforms();
                        if (!transforms) return;
                        const rotations = (transforms.rotations = transforms.rotations || {});
                        rotations[data.pageNumber - 1] = ((rotations[data.pageNumber - 1] || 0) + data.rotation) % 360;
                        ctrl.change({ $attachment: ctrl.attachment });
                    });
                }),

                messages$.pipe(messageOfType("removePage.kno2PdfViewer")).subscribe((data) => {
                    $scope.$apply(function () {
                        const transforms = getTransforms();
                        if (!transforms) return;
                        const deletions = (transforms.deletions = transforms.deletions || []);
                        deletions.push(data.pageNumber - 1);
                        ctrl.change({ $attachment: ctrl.attachment });
                    });
                }),

                messages$.pipe(messageOfType("reset.kno2PdfViewer")).subscribe((data) => {
                    $scope.$apply(function () {
                        const transforms = getTransforms();
                        if (!transforms) return;
                        delete transforms.rotations;
                        delete transforms.deletions;
                        ctrl.change({ $attachment: ctrl.attachment });
                    });
                })
            ];

            if (ctrl.options) setOptions(ctrl.options);
            load(ctrl.attachment);
        });
    }

    function $onChanges(changes) {
        if (!ctrl.iframe || !ctrl.iframe[0].contentWindow) return;
        if (changes.attachment) {
            const a = changes.attachment.currentValue;

            // Audit reads on attachments
            if (!ctrl.disableAudit && a && a.messageId) EventService.raiseAttachmentReadEvent(a.messageId, a.id);

            // Don't show rotate or remove buttons for xml documents
            if (a && a.fileName && MessageFactory.isStructuredDocument(a))
                setOptions({
                    ...ctrl.options,
                    rotate: "hidden",
                    remove: "hidden"
                });
            else setOptions(ctrl.options);

            load(a);
        }
        if (changes.options && changes.options.currentValue) {
            setOptions(changes.options.currentValue);
        }
    }

    function $onDestroy() {
        ctrl.subscriptions.forEach((x) => x.unsubscribe && x.unsubscribe());
        ctrl.subscriptions = [];
    }

    function getTransforms() {
        const a = ctrl.attachment;
        if (!a) return null;
        return (a.$$transforms = a.$$transforms || (a.transforms && _.clone(a.transforms)) || {});
    }

    function load(attachment) {
        const contentWindow = ctrl.iframe[0].contentWindow;
        contentWindow.postMessage(
            {
                type: "load.kno2PdfViewer",
                value: getSrc(attachment)
            },
            $window.location.origin
        );
        SpinnerService.start();
    }

    function setOptions(options) {
        const contentWindow = ctrl.iframe[0].contentWindow;
        contentWindow.postMessage(
            {
                type: "setOptions.kno2PdfViewer",
                value: options
            },
            $window.location.origin
        );
    }

    function getSrc(attachment) {
        if (attachment) {
            switch (attachment.previewAvailable) {
                case "Available": {
                    const cached = attachment.$$restored === true ? false : undefined;
                    delete attachment.$$restored;

                    return {
                        url: ctrl.urlFn(attachment),
                        httpHeaders: {
                            Accept: "application/pdf",
                            Authorization: `Bearer ${Auth0Service.accessToken}`
                        },
                        cached
                    };
                }

                case "Pending":
                    return PENDING;

                case "Failed":
                    return FAILED;
            }
        }

        return NOT_SELECTED;
    }
}

export function isFunction(func) {
    return func && {}.toString.call(func) === "[object Function]";
}
