import { FORMATS, VIEW_FORMAT } from "../utils/date";

uibDatepickerPopupDirective.$inject = [];
export function uibDatepickerPopupDirective() {
    return {
        restrict: "EA",
        priority: 2,
        require: "ngModel",
        link: function (originalScope, element, attrs, ngModel) {
            if (ngModel.$validators) {
                var maxlength = -1;
                attrs.$observe("maxlength", function (value) {
                    var intVal = parseInt(value, 10);
                    maxlength = isNaN(intVal) ? -1 : intVal;
                    ngModel.$validate();
                });

                ngModel.$validators.maxlength = function (modelValue, viewValue) {
                    return (
                        maxlength < 0 ||
                        ngModel.$isEmpty(viewValue) ||
                        (angular.isString(viewValue) && viewValue.length <= maxlength) ||
                        angular.isDate(viewValue)
                    );
                };
            }
        }
    };
}

openOnFocusDirective.$inject = ["$parse", "$timeout"];
export function openOnFocusDirective($parse, $timeout) {
    return {
        restrict: "A",
        link: function (scope, element) {
            var isolatedScope = element.isolateScope();
            element.on("focus", function (e) {
                // if the last item clicked on was a button...
                var originalTarget = e.originalEvent.explicitOriginalTarget || e.originalEvent.relatedTarget;
                var $originalTarget = angular.element(originalTarget);
                if (!$originalTarget.is("button") && !$originalTarget.is("div.uib-daypicker"))
                    $timeout(function () {
                        $parse("isOpen").assign(isolatedScope, "true");
                    });
            });
        }
    };
}

/* Format the input for the readonly datepicker */
datepickerPopupReadonlyDirective.$inject = ["moment"];
export function datepickerPopupReadonlyDirective(moment) {
    return {
        restrict: "A",
        link: function (scope, element, attribute) {
            scope.$watch(attribute.ngModel, function (v) {
                var dateString = "";
                var date = moment.utc(v, ["MM/DD/YYYY", "MM/DD/YY", "YYYY-MM-DD"]);
                var format = attribute.datepickerPopupReadonly || VIEW_FORMAT;

                if (date.isValid()) dateString = date.format(format);

                element.val(dateString);
            });
        }
    };
}

// Used for datePicker to allow numeric key only and /.
datePickerInputDirective.$inject = ["$timeout"];
export function datePickerInputDirective($timeout) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.bind("keypress", function (event) {
                var keyCode = event.keyCode || event.which;
                var keyChar = String.fromCharCode(keyCode);
                var validKey =
                    keyChar != " " &&
                    ((keyChar >= 0 && keyChar <= 9) ||
                        keyChar == "/" ||
                        keyCode == 8 || // Backspace
                        keyCode == 35 || // End
                        keyCode == 36 || // Home
                        keyCode == 37 || // Left
                        keyCode == 39 || // Right
                        keyCode == 46); // Delete

                if (!validKey) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            });
            element.on("keydown", function (event) {
                var keyCode = event.keyCode || event.which;
                if (keyCode == 9 || keyCode == 13) {
                    if (keyCode == 13) {
                        event.preventDefault();
                    }
                    $timeout(function () {
                        element.parent().click();
                    });
                }
            });
        }
    };
}

// Updates entered dates to the correct format (mm/dd/yyyy)
datePickerAutoFormatDirective.$inject = ["moment"];
export function datePickerAutoFormatDirective(moment) {
    return {
        restrict: "A",
        require: "?ngModel", // get a hold of NgModelController
        priority: 3,
        link: link
    };

    function link(scope, element, attrs, ngModel) {
        if (!ngModel) return; // do nothing if no ng-model

        // Attach parser before all other ngModel parsers,
        // including ui-bootstrap's datepickerPopup directive.
        ngModel.$parsers.unshift(function yearAuto(viewValue) {
            if (!viewValue) return viewValue;

            var date;

            // Remove blur binding if viewValue has changed before blur.
            element.off("blur.year-auto");

            // If the string matches the dateformat then parse with
            // moment and and set year prefix, otherwise do nothing.
            if (angular.isString(viewValue)) {
                date = moment(viewValue, FORMATS, true);
                if (date.isValid()) {
                    ngModel.$setValidity("date", true);

                    viewValue = date.toDate();
                    element.one("blur.year-auto", function () {
                        updateView(viewValue, element);
                    });
                }
            }

            return viewValue;
        });

        // Update the value that the input displays
        function updateView(date, element) {
            var m = moment(date);
            element.val(m.format(VIEW_FORMAT));
        }
    }
}

datePickerConfig.$inject = ["$window", "uibDateParser"];
export function datePickerConfig($window, uibDateParser) {
    function parseTwoDigitYear(input) {
        var year = Math.floor(+input),
            thisYear = +moment().format("YY");
        return year + (year > thisYear ? 1900 : 2000);
    }

    $window.moment.parseTwoDigitYear = parseTwoDigitYear;

    uibDateParser.overrideParser("yy", function (value) {
        this.year = parseTwoDigitYear(value);
    });
}
