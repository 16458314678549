<div class="box-content">
    <div class="container">
        <div class="row">
            <div class="box">
                <div
                    class="box-header"
                    style="overflow: visible"
                    data-original-title>
                    <h2>
                        <i class="fa fa-check-circle text-success"></i>
                        <span class="break"></span>
                        Last Successful Upload
                    </h2>
                </div>
                <div class="box-content d-flex align-items-center flex-column">
                    <div>
                        <div>
                            <label class="font-weight-bold mr-2">File Name:</label>
                            <span>{{ uploadData.fileName }}</span>
                        </div>
                        <div>
                            <label class="font-weight-bold mr-2">File Size:</label>
                            <span>{{ uploadData.fileSizeBytes | fileSize }}</span>
                        </div>
                        <div>
                            <label class="font-weight-bold mr-2">No. of Contacts:</label>
                            <span>{{ uploadData.numberOfContacts }}</span>
                        </div>
                        <div>
                            <label class="font-weight-bold mr-2">Upload Date:</label>
                            <span>{{ uploadData.createDate + "Z" | date: "M/d/yyyy 'at' hh:mm a" }}</span>
                        </div>
                        <div>
                            <label class="font-weight-bold mr-2">Uploaded By:</label>
                            <span>{{ uploadData.createdByUserName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
