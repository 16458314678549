IdentityVerificationModal.$inject = ["$scope", "$uibModalInstance", "SessionService", "Auth0Service", "appConfig"];
export function IdentityVerificationModal($scope, $uibModalInstance, sessionService, Auth0Service, appConfig) {
    $scope.title = "Identity Verification";
    $scope.brandDisplayName = appConfig.brandDisplayName;
    $scope.userProfile = sessionService.getProfile();
    $scope.subdomain = JSON.parse(atob(Auth0Service.accessToken.split(".")[1]))["http://kno2/subdomain"];
    $scope.org = _.find($scope.userProfile.organizations, function (org) {
        return org.id == $scope.userProfile.organizationId;
    });

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.submitNext = function () {
        $uibModalInstance.close();
    };
}
