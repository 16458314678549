export class ResellerService {
    constructor($http) {
        this.$http = $http;
    }

    acceptEula(resellerId) {
        return this.$http.post(`/api/resellers/${resellerId}/eula/accept`).then((response) => response.data);
    }
}

ResellerService.$inject = ["$http"];
