import manageCachedAddressesModalTemplate from "../../common/cached-addresses/manage-cached-addresses-modal.html";
import requestFacilitySearchTemplate from "./request.facility-search.html";

angular.module("kno2.records").controller("RequestCtrl", RequestCtrl);

RequestCtrl.$inject = [
    "$scope",
    "$rootScope",
    "$q",
    "$uibModal",
    "$location",
    "$timeout",
    "CommonData",
    "SessionService",
    "NotificationService",
    "RequestService",
    "DocumentSourceService",
    "AddressesCacheFactory",
    "FacilitiesService",
    "FeatureService",
    "DirectoryService",
    "PartnersService"
];

function RequestCtrl(
    $scope,
    $rootScope,
    $q,
    $uibModal,
    $location,
    $timeout,
    commonData,
    sessionService,
    notificationService,
    requestService,
    documentSourceService,
    addressCacheFactory,
    facilitiesService,
    featureService,
    directoryService,
    PartnerService
) {
    $(window).trigger("resize");
    $scope.manageCachedAddresses = manageCachedAddresses;
    $scope.searchDirectory = searchDirectory;
    $scope.autoCompleteAddresses = autoCompleteAddresses;
    $scope.autoCompleteFacilities = autoCompleteFacilities;
    $scope.resetFacilities = resetFacilities;
    $scope.onFileSelect = onFileSelect;
    $scope.onFacilitySelect = onFacilitySelect;
    $scope.onLocationSelect = onLocationSelect;
    $scope.removeConsent = removeConsent;
    $scope.submit = submit;
    $scope.sendRequest = sendRequest;
    $scope.searchFacilities = searchFacilities;

    activate();

    function activate() {
        $scope.profile = sessionService.getProfile();
        $scope.states = commonData.states.asArray();
        $scope.purposes = commonData.disclosurePurposes;
        $scope.additionalRequestTypes = commonData.additionalRequestTypes;
        $scope.dateDisclosures = commonData.dateDisclosures.asArray();
        $scope.dateOptions = { maxDate: new Date() };
        $scope.consentForm = null;
        $scope.badFileType = false;
        $scope.message = {
            requestorName: $scope.profile.fullName,
            patient: {
                gender: "M"
            },
            request: {
                infoAmount: 0,
                urgency: 0
            }
        };
        featureService.load().then(function () {
            $scope.destinationAddressPlaceholder = featureService.isEnabled("FaxDocumentSource") ? "Add an address or fax number" : "Add an address";
        });

        facilitiesService.getMyFacilities().then(function (data) {
            $scope.availableFacilities = data;
        });

        documentSourceService.getMyDocumentSources().then(function (data) {
            $scope.availableFromAddresses = _.map(data, "fullAddress");
        });
    }

    function onFacilitySelect(facility) {
        resetFacilities(true, true);
        $scope.partner = "";
        $scope.message.facilityId = facility.id;
        if (facility) {
            if (facility.allowReleaseOfInformation && facility.directAddress && (!facility.subFacilities || !facility.subFacilities.length)) {
                setToAddress(facility.directAddress);
                setPartnerLogo(facility.selectedFacilityPartner);
            } else if ((!facility.allowReleaseOfInformation || !facility.directAddress) && (!facility.subFacilities || !facility.subFacilities.length)) {
                showFacilityError();
            } else {
                setPartnerLogo(facility.selectedFacilityPartner);
            }
        }
    }

    function onLocationSelect(location) {
        resetFacilities(false, true);

        if (location != null) {
            if (location.allowReleaseOfInformation && location.directAddress) {
                $scope.message.subFacilityId = location.id;
                setToAddress(location.directAddress);
                $scope.location = location;
            } else {
                showFacilityError();
            }
        }
    }

    function showFacilityError(toggle) {
        if (toggle === undefined) toggle = true;

        if (toggle)
            notificationService.error(
                "The facility you have selected is currently not enabled to accept electronic record requests over Direct messaging. Please utilize normal request methods for requesting records and contact the facility for further questions."
            );
        else notificationService.hideErrors();
    }

    function setToAddress(address) {
        if (address) {
            $scope.disableToAddress = true;
            $scope.message.toAddresses = $scope.message.toAddresses || [];

            if ($scope.message.toAddresses.indexOf(address) === -1) {
                $scope.message.toAddresses.push({ text: address });
            }
        }
    }

    function setPartnerLogo(partnerId) {
        if (partnerId != undefined) {
            PartnerService.getPartnerDto(partnerId).then(function (data) {
                if (data.id != 0) {
                    $scope.partner = data;
                }
            });
        }
    }

    function clearToAddress() {
        $scope.message.toAddresses = [];
        $scope.disableToAddress = false;
    }

    function resetFacilities(clearFacility, clearSubFacility, resetModels) {
        showFacilityError(false);
        clearToAddress();

        if (clearFacility === true) {
            $scope.message.facilityId && delete $scope.message.facilityId;
            delete $scope.location;
            $scope.partner = "";
        }

        if (clearSubFacility === true) $scope.message.subFacilityId && delete $scope.message.subFacilityId;

        if (resetModels === true) {
            delete $scope.facility;
            delete $scope.location;
        }
    }

    function manageCachedAddresses() {
        var modalInstance = $uibModal.open({
            templateUrl: manageCachedAddressesModalTemplate,
            controller: "ManageCachedAddressesModalCtrl"
        });
        modalInstance.result.then(
            function (action) {
                if (action == "save") {
                    notificationService.success("Direct Address Cache has been saved successfully.");
                }
            },
            () => {}
        );
    }

    function autoCompleteFacilities(name) {
        return facilitiesService.getFacilitiesByName(name);
    }

    function searchDirectory() {
        directoryService.searchDirectory(true, $scope.message.toAddresses, function (addresses) {
            $scope.message.toAddresses = addresses;
        });
    }

    function autoCompleteAddresses(query) {
        query = query.toLowerCase();
        var addresses = [];

        // Get Addresses from storage cache
        var storageAddresses = addressCacheFactory.get();
        if (storageAddresses) {
            addresses = storageAddresses;
        }

        // Get Addresses from Document Sources
        _.each($scope.availableFromAddresses, function (item) {
            if (!_.includes(addresses, item)) {
                addresses.push(item);
            }
        });

        // Filter list with query value
        var filteredAddresses = [];
        var tempArr = [];
        addresses = addresses.sort();
        _.each(addresses, function (item) {
            item = item.toLowerCase();
            if (item.indexOf(query) > -1) {
                var addr = addressCacheFactory.formatAddress(item);
                if (!_.includes(tempArr, addr)) {
                    tempArr.push(addr);
                    filteredAddresses.push({ text: addressCacheFactory.formatAddress(addr) });
                }
            }
        });

        var deferred = $q.defer();
        deferred.resolve(filteredAddresses);
        return deferred.promise;
    }

    function onFileSelect(files) {
        files = files || [];
        if (files[0] && validType(files[0])) $scope.consentForm = files[0];
    }

    function validType(file) {
        var allowedFileTypes = ["application/pdf", "image/tiff"];
        if (allowedFileTypes.indexOf(file.type) !== -1) {
            $scope.badFileType = false;
            return true;
        } else {
            $scope.badFileType = true;
            removeConsent();
            return false;
        }
    }

    function removeConsent() {
        $scope.consentForm = null;
    }

    function validateForm() {
        const errors = [];
        $scope.validationInProgress = true;

        if ($scope.facility != null && $scope.facility.subFacilities.length && $scope.location == null) errors.push(`"Location" is required`);

        if (!$scope.message.fromAddress || !$scope.message.fromAddress.length) errors.push(`"From Address" is required`);

        if (!$scope.message.toAddresses || !$scope.message.toAddresses.length) errors.push(`"To Address" is required`);

        if (!$scope.message.patient.firstName) errors.push(`"First Name" is required`);

        if (!$scope.message.patient.lastName) errors.push(`"Last Name" is required`);

        if (!$scope.message.patient.dob) errors.push(`"Date of Birth" is required`);

        if (!$scope.message.request.purpose) errors.push(`"Purpose of Disclosure" is required`);

        if (!$scope.message.request.dateDisclosure) errors.push(`"Dates Requested" is required`);

        if (errors.length) {
            notificationService.errors(errors, "Please fix the following errors before sending.");
            $scope.validationInProgress = false;
            return true;
        }

        return false;
    }

    function submit() {
        if (validateForm()) return;

        $scope.formSubmitted = true;

        if ($scope.form.$invalid) {
            // Band-aid fix for dob validation, specifically for chrome, the form says there's an invalid value,
            // the control says it's valid, but the actual value is invalid. So if we see that the form has a
            // date-disabled error, strip out the dob. KSI-73 - Doug Ludlow
            var errors = _($scope.form.$error).toPairs().filter(_.last).map(_.first);
            if (errors.length === 1 && $scope.form.$error["date-disabled"]) delete $scope.message.patient.dob;
            else $scope.validationInProgress = false;
            return;
        }

        var addresses = _.map($scope.message.toAddresses, "text");
        sendRequest(addresses);
    }

    function sendRequest(toAddresses) {
        $scope.message.toAddress = toAddresses.join(";");
        $scope.message.request.specificDates = _.map($scope.message.request.specificDates, "text").join(";");

        requestService.sendRequest($scope.message, $scope.consentForm || "").then(
            function () {
                notificationService.success("Record Request has been sent successfully!");
                addressCacheFactory.add(toAddresses);
                $location.path("/request");
            },
            function (response) {
                $scope.validationInProgress = false;
                if (response.data.message != undefined) {
                    notificationService.serverError(response.data.modelState, response.data.message);
                } else {
                    notificationService.serverError(response.data.modelState, "There was an error sending record request.");
                }
            }
        );
    }

    function searchFacilities() {
        var modal = $uibModal.open({
            windowClass: "facility-search",
            templateUrl: requestFacilitySearchTemplate,
            controller: "FacilitySearch"
        });

        modal.result.then(
            function (facility) {
                var existing = _.find($scope.availableFacilities, function (f) {
                    return f.id === facility.id;
                });

                if (!existing) $scope.availableFacilities.push(facility);
                else facility = existing;

                $scope.facility = facility;
                onFacilitySelect($scope.facility);
            },
            () => {}
        );
    }
}
