import templateUrl from "./workflows.component.html";

export const workflowsComponent = {
    templateUrl: templateUrl,
    controller: workflowsController
};

workflowsController.$inject = ["WorkflowsService", "NotificationService"];

function workflowsController(WorkflowsService, NotificationService) {
    const ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.submit = submit;

    function onInit() {
        //nothing to do
    }

    function submit() {
        if (!ctrl.type) {
            NotificationService.errorToaster("Type is missing.");
            return;
        }
        if (!ctrl.stepType) {
            NotificationService.errorToaster("Step Type is missing.");
            return;
        }
        if (!ctrl.workflowIds) {
            NotificationService.errorToaster("Workflow Ids are missing.");
            return;
        }
        const workflowIds = ctrl.workflowIds.split(",");
        const request = {
            type: ctrl.type,
            retryStep: ctrl.stepType,
            workflowIds
        };
        WorkflowsService.retry(request).then(
            function (response) {
                NotificationService.success("Submitted for retry");
            },
            function (error) {
                NotificationService.errorToaster(`Error: ${error.data.message}`);
            }
        );
    }
}
