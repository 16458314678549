import templateUrl from "./integration-list.component.html";

export const integrationListComponent = {
    templateUrl: templateUrl,
    controller: IntegrationListController
};

IntegrationListController.$inject = ["AdminIntegrationsService", "NotificationService"];

export function IntegrationListController(AdminIntegrationsService, NotificationService) {
    var ctrl = this;
    ctrl.$onInit = onInit;

    function onInit() {
        getIntegrations();
    }

    function getIntegrations() {
        return AdminIntegrationsService.getIntegrations().then(
            function (data) {
                ctrl.integrations = data;
            },
            function (error) {
                NotificationService.error(error.data.message);
            }
        );
    }
}
