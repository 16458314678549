export function StopPropagationAndPreventDefault() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function (event: Event, ...args: any[]) {
            event.stopPropagation();
            event.preventDefault();
            return originalMethod.apply(this, [event, ...args]);
        };

        return descriptor;
    };
}
