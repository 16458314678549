import templateUrl from "./webhooks-upsert.modal.component.html";

export const webhooksUpsertModalComponent = {
    templateUrl: templateUrl,
    controller: WebhooksUpsertModalController,
    bindings: {
        resolve: "<",
        modalInstance: "<"
    }
};

WebhooksUpsertModalController.$inject = ["WebHooksService", "NotificationModalService"];

export function WebhooksUpsertModalController(WebHooksService, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.saveChanges = saveChanges;
    ctrl.dismissModal = dismissModal;
    ctrl.isBasicAuth = isBasicAuth;
    ctrl.resetAuth = resetAuth;
    ctrl.addHeader = addHeader;
    ctrl.removeHeader = removeHeader;

    function $onInit() {
        ctrl.model = ctrl.resolve.model;
        ctrl.model.webhook.hookEvent = ctrl.model.selectableEvents[0];

        ctrl.customHeadersArray = [];
        const customHeadersKeys = Object.keys(ctrl.model.webhook.customHeaders);
        if (customHeadersKeys.length) {
            ctrl.advancedSettings = true;
            customHeadersKeys.forEach((key) => {
                ctrl.customHeadersArray.push({
                    key: key,
                    value: ctrl.model.webhook.customHeaders[key]
                });
            });
            ctrl.model.webhook.customHeaders = {};
        } else {
            ctrl.customHeadersArray.push({});
        }
    }

    function saveChanges() {
        if (ctrl.form.$valid) {
            if (ctrl.customHeadersArray.length) {
                ctrl.model.webhook.customHeaders = {};
                ctrl.customHeadersArray.forEach((x) => {
                    if (x.key && x.value) {
                        ctrl.model.webhook.customHeaders[x.key] = x.value;
                    }
                });
            }
            WebHooksService.upsert(ctrl.model.webhook).then(
                function (d) {
                    ctrl.modalInstance.close();
                },
                function (error) {
                    var msg = error.data.message.split("Reference");
                    NotificationModalService.error(msg[0]);
                }
            );
        }
    }

    function dismissModal() {
        ctrl.modalInstance.dismiss();
    }

    function isBasicAuth() {
        return ctrl.model.webhook.authenticationType === "Basic";
    }

    function resetAuth() {
        ctrl.model.webhook.username = null;
        ctrl.model.webhook.password = null;
    }

    function addHeader() {
        ctrl.customHeadersArray.push({});
    }

    function removeHeader(idx) {
        ctrl.customHeadersArray.splice(idx, 1);
    }
}
