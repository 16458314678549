export class AdminIpAddressesService {
    constructor($http) {
        this.$http = $http;
    }

    getPagedIpAddressesByOrgId(orgId, pageStart, pageSize, search, isWhitelisted, isBlacklisted, isDisabled) {
        return this.$http.get(`/api/admin/ipaddresses`, {
            params: { orgId, pageStart, pageSize, search, isWhitelisted, isBlacklisted, isDisabled }
        });
    }
}

AdminIpAddressesService.$inject = ["$http"];
