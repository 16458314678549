import { Component, inject } from "@angular/core";
import { AuthService } from "@kno2/core/auth";
import { WINDOW_TOKEN } from "@kno2/core/browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "kno2-session-timeout",
    standalone: true,
    templateUrl: "./session-timeout.component.html"
})
export class SessionTimeoutComponent {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly authService = inject(AuthService);
    private readonly modalRef = inject(NgbActiveModal);

    private interval: ReturnType<typeof setInterval>;

    protected countdown = 60;

    public ngOnInit(): void {
        const isLocal = this.window.location.host.includes(".local");
        this.interval = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) clearInterval(this.interval);
            if (this.countdown <= 0 && !isLocal) {
                this.authService.logout();
                this.modalRef.close();
            }
        }, 1000);
    }

    public close(): void {
        clearInterval(this.interval);
        this.modalRef.close();
    }
}
