angular
    .module("kno2.directives")

    .directive("k2FormValidate", [
        function () {
            return {
                restrict: "A",
                link: function (scope, element, attributes) {
                    // Use this directive on a form element only. Or else!
                    // (╯°□°)╯︵ ┻━┻
                    // You can't tell me what to do!
                    if (element.is("form")) {
                        element.bind("submit", function (e) {
                            angular.element("[ng-focus]").not("[ignore-k2validate]").blur();
                            scope.$apply();
                        });
                    }
                }
            };
        }
    ])

    .directive("k2Tooltip", function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                const placement = attrs["placement"] || "bottom";
                const title = attrs["k2Title"] || attrs["title"] || ""; // interpolated values must use 'k2-title' attribute

                element.tooltip({
                    trigger: "hover",
                    boundary: "window",
                    placement: placement,
                    delay: { show: 400 },
                    title: title
                });
                element.on("click", () => element.tooltip("hide"));
            }
        };
    })

    .directive("k2Integer", function () {
        return {
            require: "ngModel",
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(input) {
                    let transformedInput = Math.round(Number(input.toString().replace(/(?!^-|.)[^0-9.]*/g, "")));

                    if (transformedInput !== input) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    })

    .directive("k2SelectFile", [
        function () {
            return {
                restrict: "A",
                link: function (scope, element) {
                    element.bind("click", function (e) {
                        angular.element(e.target).siblings("#selectedFile").trigger("click");
                    });
                }
            };
        }
    ])

    .directive("k2Dropdown", [
        function () {
            return {
                restrict: "A",
                link: function (scope, element) {
                    element.bind("click", function (event) {
                        const dropdownMenu = $(this).find("ul.dropdown-menu");

                        event.stopPropagation();

                        // Allows the dropdown to be closed
                        if (!dropdownMenu.hasClass("show")) $("ul.dropdown-menu.show").removeClass("show");

                        dropdownMenu.toggleClass("show");
                    });
                }
            };
        }
    ])

    // The tags-input element creates a child input element in the dom. The directive extends ngTagsInput to prevent tabbing into the tags-input wrapper
    .directive("tagsInput", function () {
        return {
            priority: 2,
            link: function (scope, element, attrs) {
                scope.$on("loading-complete", function (e) {
                    attrs.$set("tabindex", -1);
                });
            }
        };
    })

    .directive("loadingIndicator", [
        "loadingIndicatorService",
        function (loadingIndicatorService) {
            return {
                restrict: "A",
                template: "<div class='kno2-loading'><img src='/content/images/ajax-loader.gif'/></div>",
                link: function (scope, element, attrs) {
                    loadingIndicatorService.isLoading$.subscribe((isLoading) => {
                        const visibility = isLoading ? "visible" : "hidden";
                        element.css({ visibility });
                    });
                    scope.$on("loading-started", function (e) {
                        element.css({ visibility: "visible" });
                    });

                    scope.$on("loading-complete", function (e) {
                        element.css({ visibility: "hidden" });
                    });
                }
            };
        }
    ])

    .directive("messageSummary", [
        "$timeout",
        "$uibModal",
        ($timeout, $uibModal) => {
            return {
                template: "<div ng-transclude></div>",
                transclude: true,
                restrict: "A",
                link: (scope, element) => {
                    const k2Popover = element.find(".k2-popover");

                    element
                        .on("mouseenter", () => k2Popover.fadeIn(200))
                        .on("mouseleave", () => {
                            $timeout(() => {
                                if (!$(".k2-popover:hover").length) {
                                    k2Popover.fadeOut(200);
                                }
                            }, 300);
                        })
                        .on("$destroy", () => {
                            const popover = element.data("bs.popover");
                            if (popover && popover.getTipElement()) {
                                popover.getTipElement().remove();
                            }
                            element.popover("dispose");
                        });
                }
            };
        }
    ])

    .directive("notificationAlert", [
        "$templateCache",
        function () {
            return {
                restrict: "E",
                template: `
            <div class="clearfix"></div>
            <div class="alert alert-{{level}} notification-alert" ng-controller="NotificationCtrl" ng-show="titleMessage">
                <button type="button" ng-show="showClose" class="close" ng-click="close()">×</button>
                <strong ng-show="titleCaption">{{titleCaption}}</strong> {{messageTitle}}

                <div class="messages" ng-show="messages.length > 0">
                    <span class="message" ng-repeat="message in messages track by $index">{{message}}</span>
                </div>
            </div>`
            };
        }
    ])

    .directive("notificationModalAlert", [
        "$templateCache",
        function () {
            return {
                restrict: "E",
                template: `
            <div class="clearfix"></div>
            <div class="alert alert-{{level}} notification-modal-alert" ng-controller="NotificationModalCtrl" ng-show="titleMessage">
                <button type="button" ng-show="showClose" class="close" ng-click="close()">×</button>
                <strong ng-show="titleCaption">{{titleCaption}}</strong> {{messageTitle}}

                <div class="messages" ng-show="messages.length > 0">
                    <span class="message" ng-repeat="message in messages track by $index">{{message}}</span>
                </div>
            </div>`
            };
        }
    ])

    .directive("ladda", [
        "$timeout",
        function ($timeout) {
            return {
                require: "^form",
                restrict: "A",
                priority: -1,
                link: function (scope, element, attr, formCtrl) {
                    if (Ladda && formCtrl) {
                        $timeout(function () {
                            var parentForm = element.parents("form");

                            if (!parentForm) return;

                            var l = Ladda.create(element[0]);

                            parentForm.on("submit", function ladda() {
                                if (formCtrl.$valid) l.start();

                                scope.$on("loading-complete", function (e) {
                                    l.stop();
                                });
                            });
                        });
                    }
                }
            };
        }
    ])

    .directive("laddaButton", [
        "$timeout",
        "$parse",
        function ($timeout, $parse) {
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    $timeout(function () {
                        var l = Ladda.create(element[0]);
                        scope.$watch(attrs.laddaButton, function (value) {
                            var ngDisabled;
                            if (attrs.ngDisabled) {
                                ngDisabled = scope.$eval(attrs.ngDisabled);
                            }

                            if (value === true) {
                                l.start();
                            } else {
                                l.stop();

                                if (ngDisabled) attrs.$set("disabled", ngDisabled);
                            }
                        });
                    });
                }
            };
        }
    ])

    .directive("k2Unique", [
        function () {
            var validityKey = "unique";

            return {
                require: "ngModel",
                link: function (scope, element, attrs, ctrl) {
                    ctrl.$viewChangeListeners.push(validate);

                    function validate() {
                        ctrl.$setValidity(validityKey, true);
                        var val = ctrl.$viewValue;
                        if (val) {
                            var items = scope.$eval(attrs["k2Unique"]);
                            var model = items.model;
                            var serviceFunction = items && _.isFunction(items.callback) ? items.callback : null;
                            var args = items && items.args ? items.args : [];

                            // Perform basic validations (this shouldn't occurs if k2-unique well defined)
                            if (serviceFunction == null) {
                                console.warn("Missing callback property to k2-unique directive!");
                            } else if (_.isUndefined(model) || _.isNull(model)) {
                                console.warn("Missing model property to k2-unique directive!");
                            } else if (_.isUndefined(model.id) || _.isNull(model.id)) {
                                console.warn("Missing model.id property to k2-unique directive!");
                            } else {
                                var callResults = serviceFunction();
                                if (callResults && _.isFunction(callResults.serviceFunction)) {
                                    callResults.serviceFunction(model.id, val, ...args).then(function (response) {
                                        var messages = element.data("k2ErrorAddonMessages");
                                        messages[validityKey] = callResults.errorMessage || "This value already exists.";
                                        element.data("k2ErrorAddonMessages", messages);

                                        const data = response.data || response;
                                        ctrl.$setValidity(validityKey, data == false);
                                    });
                                }
                            }
                        }
                    }
                }
            };
        }
    ])

    // toggleContent allows an element to specify that when clicked, it will hide
    // another element by its content ID. It will also style another element by
    // its header ID.
    .directive("toggleContent", [
        "$location",
        "$timeout",
        function ($location, $timeout) {
            return {
                restrict: "A",
                scope: {
                    toggleContentToHide: "=toggleContentId",
                    toggleHeaderToStyle: "=toggleHeaderId",
                    toggleContent: "="
                },
                link: function (scope, element, attrs) {
                    element.on("click", function (evt) {
                        var selector = "#" + scope.toggleContentToHide;
                        var headerSelector = "#" + scope.toggleHeaderToStyle;
                        var isContentHidden = angular.element(selector).is(":hidden");
                        var iconClass = isContentHidden ? "fa fa-chevron-up" : "fa fa-chevron-down";

                        // The $event triggering this could be A or I. We want to change the class
                        // for the icon only, so if A triggered the event, locate its icon.

                        var chevron = evt.target;
                        if (attrs.toggleChevron) {
                            chevron = angular.element("#" + attrs.toggleChevron).find("i")[0];
                        }

                        if (chevron.tagName == "A") {
                            chevron.firstChild.className = iconClass;
                        } else {
                            chevron.className = iconClass;
                        }

                        if (angular.element(selector).is(":visible")) {
                            angular.element(selector).slideUp(200, function () {
                                if (scope.toggleHeaderToStyle) {
                                    if (isContentHidden) angular.element(headerSelector).removeClass("bottom-border-none");
                                    else angular.element(headerSelector).addClass("bottom-border-none");
                                }
                            });
                        } else {
                            if (scope.toggleHeaderToStyle) {
                                if (isContentHidden) angular.element(headerSelector).removeClass("bottom-border-none");
                                else angular.element(headerSelector).addClass("bottom-border-none");
                            }

                            angular.element(selector).slideDown(200);
                        }
                    });

                    if (scope.toggleContent)
                        $timeout(function () {
                            element.trigger("click");
                        });
                }
            };
        }
    ])

    .directive("inputTagValidate", [
        "$timeout",
        "_",
        function ($timeout, _) {
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    $timeout(function () {
                        var tagInput = angular.element(element);

                        scope.$watchCollection(attrs.invalidItems, function (value) {
                            tagInput.find("li.tag-item span").each(function () {
                                var parent = $(this).parent().removeAttr("title").removeClass("invalid");
                                if (_.includes(value, $(this).text())) {
                                    parent.attr("title", attrs.invalidMessage).addClass("invalid");
                                }
                            });
                        });
                    });
                }
            };
        }
    ])

    .directive("no800", function () {
        var validatePhone = function (phone) {
            var numList = ["800", "888", "877", "866", "855", "844"];
            for (var s = 0, len = numList.length; s < len; s++) {
                if (
                    phone.substr(0, 5) == "1 " + numList[s] ||
                    phone.substr(0, 5) == "1-" + numList[s] ||
                    phone.substr(0, 5) == "(" + numList[s] + ")" ||
                    phone.substr(0, 4) == "1" + numList[s] ||
                    phone.substr(0, 3) == numList[s]
                ) {
                    return false;
                }
            }
            return true;
        };
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$parsers.unshift(function (viewValue) {
                    if (!validatePhone(viewValue)) {
                        // it is not valid
                        ctrl.$setValidity("no800", false);
                        return viewValue;
                    } else {
                        // it isvalid
                        ctrl.$setValidity("no800", true);
                        return viewValue;
                    }
                });
            }
        };
    })

    .directive("validIp", function () {
        var validateIpAddress = function (ipAddress) {
            if (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm.test(ipAddress)) {
                return true;
            }
            return false;
        };
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$parsers.unshift(function (viewValue) {
                    if (!validateIpAddress(viewValue)) {
                        // it is not valid
                        ctrl.$setValidity("validIp", false);
                        return viewValue;
                    } else {
                        // it isvalid
                        ctrl.$setValidity("validIp", true);
                        return viewValue;
                    }
                });
            }
        };
    })

    .directive("plainTextToHtml", [
        "$sce",
        function ($sce) {
            // matches:
            // - 1 -> the full email address
            // - 2 -> the username part of the email address
            // - 3 -> the domain part of the email address
            var emailRegex =
                /(?:\<?)(([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@((?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?))(?:\>?)/gi;
            var regex = /\r\n|\r|\n/g;
            return {
                restrict: "A",
                template: "<div ng-bind-html='html' class='{{containerClass}}'></div>",
                scope: {
                    plainText: "=ngModel"
                },
                link: function (scope, element, attrs) {
                    scope.$watch("plainText", function (value) {
                        if (_.isEmpty(value)) {
                            return;
                        }
                        // RH: Commenting out this piece as it no longer seems to be used and is breaking RR message body
                        //value = value.replace(emailRegex, '$2');
                        var html = value.replace(regex, "<br />");
                        scope.html = $sce.trustAsHtml(html);
                        scope.containerClass = attrs.containerClass;
                    });
                }
            };
        }
    ])

    .directive("htmlTip", [
        "tooltips",
        function (tooltips) {
            return {
                restrict: "A",
                template: `<a class='no-highlight' href='javascript:;' tabindex='-1' style='text-decoration:none'> <i class='fa fa-info-circle box-info' style='{{htmlTipStyles}}'></i></a>`,
                scope: {
                    // One of the following values: top, right, left, bottom, auto (default)
                    htmlTipPlacement: "@",
                    htmlTipTitle: "@",
                    htmlTipContainer: "@",
                    htmlTipStyles: "@"
                },
                link: function (scope, element, attrs) {
                    var tipPlacement = scope.htmlTipPlacement || "auto";
                    var tipTitle = scope.htmlTipTitle || "Tip";
                    var tipContainer = scope.htmlTipContainer || false;

                    element.popover({
                        trigger: "hover",
                        content: tooltips[attrs.htmlTip],
                        placement: tipPlacement,
                        title: tipTitle,
                        html: true,
                        delay: 400,
                        container: tipContainer
                    });
                }
            };
        }
    ])

    .directive("htmlImageTip", [
        "tooltips",
        function (tooltips) {
            return {
                restrict: "A",
                template:
                    "<a class='no-highlight' href='javascript:;' tabindex='-1' style='text-decoration:none'> <i class='fa fa-info-circle box-info'></i></a>",
                scope: {
                    // One of the following values: top, right, left, bottom, auto (default)
                    htmlImageTipPlacement: "@",
                    htmlImageTipTitle: "@",
                    htmlImageTipContainer: "@",
                    htmlImageTipClass: "@",
                    htmlImageTipContent: "="
                },
                link: function (scope, element, attrs) {
                    var tipPlacement = scope.htmlImageTipPlacement || "auto";
                    var tipTitle = scope.htmlImageTipTitle || "Tip";
                    var tipContainer = scope.htmlImageTipContainer || false;

                    var template = `<div class="popover ${scope.htmlImageTipClass}">
                            <div class="arrow"></div>
                                <h3 class="popover-header">${scope.htmlImageTipTitle}</h3>
                            <div class="popover-body"></div></div>`;

                    element.popover({
                        template: template,
                        trigger: "hover",
                        content: scope.htmlImageTipContent || tooltips[attrs.htmlImageTip],
                        placement: tipPlacement,
                        title: tipTitle,
                        html: true,
                        delay: 400,
                        container: tipContainer
                    });
                }
            };
        }
    ])

    .directive("htmlTipInline", [
        function () {
            return {
                restrict: "A",
                template:
                    "<a class='no-highlight' href='javascript:;' tabindex='-1' style='text-decoration:none'> <i class='fa fa-info-circle box-info no-padding'></i></a>",
                scope: {
                    // One of the following values: top, right, left, bottom, auto (default)
                    htmlTipPlacement: "@",
                    htmlTipTitle: "@",
                    htmlTipContainer: "@"
                },
                link: function (scope, element, attrs) {
                    var tipPlacement = scope.htmlTipPlacement || "auto";
                    var tipTitle = scope.htmlTipTitle || "Tip";
                    var tipContainer = scope.htmlTipContainer || false;

                    element.popover({
                        trigger: "hover",
                        content: attrs.htmlTipInline,
                        placement: tipPlacement,
                        title: tipTitle,
                        html: true,
                        delay: 400,
                        container: tipContainer
                    });
                }
            };
        }
    ])

    .directive("htmlTipNoIcon", [
        function () {
            return {
                restrict: "A",
                template: "",
                scope: {
                    // One of the following values: top, right, left, bottom, auto (default)
                    htmlTipPlacement: "@",
                    htmlTipTitle: "@",
                    htmlTipContainer: "@"
                },
                link: function (scope, element, attrs) {
                    var tipPlacement = scope.htmlTipPlacement || "auto";
                    var tipTitle = scope.htmlTipTitle || "";
                    var tipContainer = scope.htmlTipContainer || false;

                    element.popover({
                        trigger: "hover",
                        content: attrs.htmlTipNoIcon,
                        placement: tipPlacement,
                        title: tipTitle,
                        html: true,
                        delay: 400,
                        container: tipContainer
                    });
                }
            };
        }
    ])

    // Validates a user is unique
    .directive("uniqueUser", [
        "$timeout",
        "usersFactory",
        function ($timeout, usersFactory) {
            var FOCUS_CLASS = "ng-focused";
            var INVALID_CLASS = "ng-invalid";
            var DIRTY_CLASS = "ng-dirty";
            var PRISTINE_CLASS = "ng-pristine";
            var INPUT_GROUP = "input-group";
            var INPUT_GROUP_ADDON = "input-group-addon";
            var INVALID_INPUT_ADDON = "invalid-input-addon";
            var DEFAULT_MESSAGE = "Please enter a valid value for this field";

            return {
                restrict: "A",
                require: "ngModel",
                scope: {
                    validate: "&uniqueUser"
                },
                link: function (scope, element, attrs, ctrl) {
                    var keyUp;
                    var originalElementValueSet = false;
                    var originalElementValue;

                    var getOriginalValue = function () {
                        $timeout(function () {
                            if (element.val() != "" || originalElementValueSet) {
                                originalElementValue = element.val();
                                originalElementValueSet = true;
                            } else {
                                getOriginalValue();
                            }
                        }, 100);
                    };
                    getOriginalValue();

                    var buildValidation = function (message) {
                        element.removeClass(FOCUS_CLASS);
                        // To trigger validation classes on blur,
                        // we're manually removing "ng-pristine" and adding "ng-dirty"
                        element.removeClass(PRISTINE_CLASS);
                        element.addClass(DIRTY_CLASS);
                        // Build addon notifier
                        var parent = element.parent();
                        if (element.hasClass(INVALID_CLASS)) {
                            if (parent.find("i").length == 0) {
                                parent.addClass(INPUT_GROUP);
                                parent.append(
                                    '<span class="' +
                                        INPUT_GROUP_ADDON +
                                        " " +
                                        INVALID_INPUT_ADDON +
                                        '" title="' +
                                        message +
                                        '"><i class="fa fa-exclamation-circle"></i></span>'
                                );
                            } else {
                                // Update the title text
                                parent.find("span").attr("title", message);
                            }
                        } else {
                            if (parent.find("span").length > 0) {
                                parent.removeClass(INPUT_GROUP);
                                parent.find('span[class="' + INPUT_GROUP_ADDON + " " + INVALID_INPUT_ADDON + '"]').remove();
                            }
                        }
                    };

                    var setMessageCallback = function (isValid, msg, updatedElementValueAfterRegex) {
                        if (updatedElementValueAfterRegex && updatedElementValueAfterRegex == originalElementValue) {
                            // This case will happen if the original value is the same as the
                            // post-processed value after running the regex against it.
                            // Example: original value = "admin@kno2fy.com"
                            //          post processed = ""User Name" <admin@kno2fy.com>
                            //          final result   = "admin@kno2fy.com
                            // The final result will show an error because that user already exists,
                            // as it is the same value after regex noise stripping as the original was.
                            ctrl.$setValidity("unique", true);
                            if (element.attr("type") == "email") {
                                ctrl.$setValidity("email", true);
                            }
                            ctrl.$setPristine();
                            resetValidation();
                        } else if (!isValid && originalElementValue != ctrl.$viewValue) {
                            ctrl.$setValidity("unique", false);
                            buildValidation(msg);
                        } else {
                            ctrl.$setValidity("unique", true);
                            resetValidation(msg);
                        }
                        if (updatedElementValueAfterRegex && updatedElementValueAfterRegex != element.val()) {
                            ctrl.$viewValue = updatedElementValueAfterRegex;
                            element.val(updatedElementValueAfterRegex);
                        }
                    };

                    var resetValidation = function (msg) {
                        var parent = element.parent();
                        element.attr("title", msg);
                        if (parent.find("span").length > 0) {
                            parent.removeClass(INPUT_GROUP);
                            parent.find('span[class="' + INPUT_GROUP_ADDON + " " + INVALID_INPUT_ADDON + '"]').remove();
                        }
                    };

                    var tryValidate = function () {
                        if (ctrl.$dirty) {
                            if (scope.validate != null) {
                                scope.validate({ username: element.val(), callback: setMessageCallback });
                            } else {
                            }
                        }
                    };

                    element.bind("blur", function () {
                        if (keyUp) $timeout.cancel(keyUp);
                        tryValidate();
                    });

                    element.bind("focus", function () {
                        originalElementValueSet = true;
                    });

                    element.bind("keyup", function () {
                        // cancel existing timer to ensure the timer starts over with each key stroke
                        if (keyUp) $timeout.cancel(keyUp);

                        keyUp = $timeout(function () {
                            $timeout.cancel(keyUp);
                            tryValidate();
                        }, 1000);
                    });
                }
            };
        }
    ])

    .directive("validNumber", function () {
        return {
            require: "?ngModel",
            link: function (scope, element, attrs, ngModelCtrl) {
                if (!ngModelCtrl) {
                    return;
                }

                ngModelCtrl.$parsers.push(function (val) {
                    if (typeof val === "undefined") return val; // Prevent console error when val is undefined.
                    if (attrs.allowDecimal === "true") {
                        var clean = val.replace(/[^0-9.]+/g, "");
                        clean = clean.replace("..", ".");
                    } else {
                        var clean = val.replace(/[^0-9]+/g, "");
                    }
                    if (val !== clean) {
                        ngModelCtrl.$setViewValue(clean);
                        ngModelCtrl.$render();
                    }
                    return clean;
                });

                element.bind("keypress", function (event) {
                    if (event.keyCode === 32) {
                        event.preventDefault();
                    }
                });
            }
        };
    })

    .directive("equals", function () {
        return {
            restrict: "A", // only activate on element attribute
            require: "?ngModel", // get a hold of NgModelController
            link: function (scope, elem, attrs, ngModel) {
                if (!ngModel) return; // do nothing if no ng-model

                // watch own value and re-validate on change
                scope.$watch(attrs.ngModel, function () {
                    validate();
                });

                // observe the other value and re-validate on change
                attrs.$observe("equals", function (val) {
                    validate();
                });

                var validate = function () {
                    // values
                    var val1 = ngModel.$viewValue;
                    var val2 = attrs.equals;

                    // set validity
                    if (val1 && val2) {
                        ngModel.$setValidity("equals", val1 === val2);
                    }
                };
            }
        };
    })

    .directive("iframeSetHeightOnload", [
        function () {
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    element.on("load", function () {
                        element.height(window.innerHeight - 200 + "px");
                        element.width("100%");
                    });
                }
            };
        }
    ])

    .directive("setHeight", function ($window) {
        return {
            link: function (scope, element, attrs) {
                element.css("height", $window.innerHeight - 200 + "px");
            }
        };
    })

    .directive("k2BindHtml", ($sce, $parse, $compile) => {
        return {
            restrict: "A",
            compile: function ngBindHtmlCompile(el, attrs) {
                var ngBindHtmlGetter = $parse(attrs.k2BindHtml);
                var ngBindHtmlWatch = $parse(attrs.k2BindHtml, function sceValueOf(val) {
                    // Unwrap the value to compare the actual inner safe value, not the wrapper object.
                    return $sce.valueOf(val);
                });
                $compile.$$addBindingClass(el);

                return function ngBindHtmlLink(scope, element, attr) {
                    $compile.$$addBindingInfo(element, attr.k2BindHtml);

                    scope.$watch(ngBindHtmlWatch, function ngBindHtmlWatchAction() {
                        // The watched value is the unwrapped value. To avoid re-escaping, use the direct getter.

                        var value = attrs.stripTags ? stripTags(ngBindHtmlGetter(scope), attrs.stripTags.split(",")) : ngBindHtmlGetter(scope);

                        element.html($sce.getTrustedHtml(value) || "");
                    });
                };

                function stripTags(html, tags) {
                    for (let i = 0; i < tags.length; i++) {
                        if (!html) break;

                        const start = html.indexOf(`<${tags[i]}>`);
                        const end = html.indexOf(`</${tags[i]}>`) + `</${tags[i]}>`.length;

                        html = html.substring(0, start) + html.substring(end);
                    }
                    return html;
                }
            }
        };
    })

    .directive("truncateHtmlTags", [
        function () {
            var stripHTML = function (text) {
                if (text) {
                    var regex = /(<([^>]+)>)/gi;
                    return text.replace(regex, "");
                } else {
                    return text;
                }
            };
            return {
                restrict: "A",
                scope: {
                    ngModel: "="
                },
                link: function (scope, element) {
                    element.bind("blur", function (e) {
                        if (scope && scope.ngModel) {
                            scope.$apply(function () {
                                scope.ngModel = stripHTML(element.val());
                            });
                        }
                    });
                }
            };
        }
    ])

    .directive("disableNgAnimate", [
        "$animate",
        function ($animate) {
            return {
                restrict: "A",
                link: function (scope, element) {
                    $animate.enabled(false, element);
                }
            };
        }
    ])

    .directive("k2Chars", [
        "_",
        function (_) {
            // This directive is used to ensure a field doesn't have invalid characters.
            return {
                require: "ngModel",
                restrict: "A",
                link: function (scope, elem, attrs, ctrl) {
                    var originalTitle = elem.attr("title") || "";

                    // if ngModel is not defined, we don't need to do anything
                    if (!ctrl) return;

                    ctrl.$validators.validCharacters = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;
                        if (!value) return true;

                        var pattern = /[^\w !"#$%&'()*+,-./:;<=>?@\|\[\]^_`{}~]/gi;
                        var result = value.match(pattern);
                        var invalidCharacters = result ? _.unique(result).join(", ") : null;
                        var isValid = !result;

                        updateErrorMessage(isValid, invalidCharacters);

                        return isValid;
                    };

                    function updateErrorMessage(isValid, invalidCharacters) {
                        if (!isValid) {
                            elem.attr("title", "Invalid characters in field: " + invalidCharacters);
                        } else {
                            elem.attr("title", originalTitle);
                        }
                    }
                }
            };
        }
    ])

    .directive("k2SpaceRequired", [
        function () {
            // This directive is used to ensure an org name is valid
            return {
                require: "ngModel",
                restrict: "A",
                link: function (scope, elem, attrs, ctrl) {
                    var originalTitle = elem.attr("title") || "";

                    // if ngModel is not defined, we don't need to do anything
                    if (!ctrl) return;

                    ctrl.$validators.spaceRequired = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;
                        var isValid = value && value.indexOf(" ") > -1;
                        updateErrorMessage(isValid);
                        return isValid;
                    };

                    function updateErrorMessage(isValid) {
                        if (!isValid) {
                            elem.attr("title", "A space is required.");
                        } else {
                            elem.attr("title", originalTitle);
                        }
                    }
                }
            };
        }
    ]);
