import templateUrl from "./admin-documentsources-list.component.html";

export const adminDocumentSourcesListComponent = {
    templateUrl: templateUrl,
    controller: DocumentSourcesListController
};

DocumentSourcesListController.$inject = ["AdminDocumentSourcesService", "NotificationService", "$timeout", "PagingFactory", "$uibModal"];

export function DocumentSourcesListController(AdminDocumentSourcesService, NotificationService, $timeout, PagingFactory, $uibModal) {
    var ctrl = this;
    ctrl.$onInit = onInit;

    function onInit() {
        ctrl.sortBy = "name";
        ctrl.isSortDesc = false;
        ctrl.sortIconClass = "fa fa-chevron-up";
        ctrl.currentPage = 1;
        ctrl.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        ctrl.pagedItemRange = ctrl.pagedItemRanges[0];

        getDocumentSources(ctrl.pageNumber, ctrl.pageSize, ctrl.searchTerm);
    }

    // Sort
    ctrl.sort = function () {
        ctrl.isSortDesc = !ctrl.isSortDesc;
        ctrl.sortIconClass = ctrl.isSortDesc ? "fa fa-chevron-down" : "fa fa-chevron-up";
        getDocumentSources();
    };

    // Paging
    ctrl.gotoPage = function (page) {
        if (page < 1) page = 1;
        else if (page > ctrl.maxPage) page = ctrl.maxPage;

        ctrl.currentPage = page;
        getDocumentSources();
    };

    ctrl.showPage = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalCount).visible[page - 1];
    };

    ctrl.isFirstPageRange = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalCount).isFirst;
    };

    ctrl.isLastPageRange = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalCount).isLast;
    };

    // Search functionality
    // Instantiate these variables outside the watch
    var searchTimeout;
    ctrl.searchDocumentSources = function () {
        if (ctrl.searchTerm == undefined) return; // we want to allow the empty string but not undefined
        if (searchTimeout) $timeout.cancel(searchTimeout);

        searchTimeout = $timeout(function () {
            ctrl.currentPage = 1;
            getDocumentSources();
        }, 400);
    };

    // Update Paging Count
    ctrl.updatePagedItemCount = function () {
        ctrl.pageSize = AdminDocumentSourcesService.getPagedItemsSelectValue();
        getDocumentSources();
    };

    ctrl.editStatus = function (documentSource) {
        if (!documentSource) {
            return;
        }
        const modalInstance = $uibModal.open({
            component: "k2DocumentSourceStatusModal",
            resolve: {
                documentSource: function () {
                    return documentSource;
                }
            }
        });

        modalInstance.result.then(
            function () {
                getDocumentSources();
            },
            function () {}
        );
    };

    function getDocumentSources() {
        AdminDocumentSourcesService.getDocumentSources(ctrl.isSortDesc ? "desc" : "asc", ctrl.currentPage, ctrl.pagedItemRange.value, ctrl.searchTerm).then(
            function (response) {
                ctrl.documentSources = response.documentSources;
                ctrl.totalCount = response.documentSourcesCount;
                ctrl.maxPage = Math.ceil(ctrl.totalCount / ctrl.pagedItemRange.value);
                ctrl.pages = _.range(1, ctrl.maxPage + 1);
            }
        );
    }
}
