(function () {
    "use strict";

    angular.module("kno2.directives").directive("trimModel", trimModel);

    trimModel.$inject = [];

    /**
     * A directive that will intercept any model values that are '' and format
     * them as null so angular doesn't insert an empty <option value="?"> for unknowns.
     * See IKW-3814, KPI-28869.
     */
    function trimModel() {
        return {
            restrict: "A",
            require: "?ngModel",
            link: function (scope, element, attrs, ngModelController) {
                if (!ngModelController) return;

                ngModelController.$parsers.unshift(function trim(value) {
                    if (value) value = value.trim();
                    return value;
                });
            }
        };
    }
})();
