import triageTemplate from "./triage.html";

triageRoutes.$inject = ["$stateProvider"];

export function triageRoutes($stateProvider) {
    $stateProvider.state("triage", {
        url: "/triage/:id",
        parent: "home",
        templateUrl: triageTemplate,
        controller: "TriageCtrl",
        controllerAs: "vm",
        data: {
            roles: ["Administrator", "User"],
            resource: "triageRoute"
        }
    });
}
