import { Directive, TemplateRef, ViewContainerRef, inject, input } from "@angular/core";
import { SessionService } from "@kno2/core/session";

@Directive({
    selector: "[kno2ShowForRoleIn]",
    standalone: true
})
export class ShowForRoleInDirective {
    private readonly sessionService = inject(SessionService);
    private templateRef = inject(TemplateRef);
    private viewContainer = inject(ViewContainerRef);
    protected roles = input.required<string[]>({ alias: "kno2ShowForRoleIn" });

    ngOnInit() {
        const userInRole = this.roles().some((role) => this.sessionService.userInRole(role));

        if (userInRole) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
