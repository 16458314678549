import { fromEvent } from "rxjs";
import { filter, take } from "rxjs/operators";
import { messageOfType } from "../lib/observables";

export class PrintService {
    constructor($window, Auth0Service, NotificationService) {
        this.$window = $window;
        this.Auth0Service = Auth0Service;
        this.NotificationService = NotificationService;

        this.messages$ = fromEvent(this.$window, "message");
        this.printError$ = this.messages$.pipe(messageOfType("printerror"));
        this.printReady$ = this.messages$.pipe(messageOfType("printready"));
    }

    print(messageId, attachmentId) {
        const jobId = this.createJobId();
        let window;

        this.printReady$
            .pipe(
                filter((x) => x === jobId),
                take(1)
            )
            .subscribe(() => {
                this.sendPrintRequest(window, messageId, attachmentId);
            });

        this.printError$
            .pipe(
                filter((x) => x.jobId === jobId),
                take(1)
            )
            .subscribe((x) => {
                console.error("Print Error", x.error);
                this.NotificationService.error("An error occurred while attempting to print. Please try again.");
            });

        window = this.$window.open(`/dist/print.html?job=${jobId}`);
    }

    sendPrintRequest(window, messageId, attachmentId) {
        const token = this.Auth0Service.accessToken;
        const message = {
            type: "print",
            value: {
                token,
                messageId,
                attachmentId
            }
        };
        window.postMessage(message, this.$window.location.origin);
    }

    createJobId() {
        return Math.random().toString(36).substring(2);
    }
}

PrintService.$inject = ["$window", "Auth0Service", "NotificationService"];
