import { Routes } from "@angular/router";

export const routes: Routes = [
    {
        path: "supportcenter",
        loadComponent: () => import("./support.component").then((m) => m.SupportComponent)
    },
    {
        path: "supportcenter/contact",
        loadComponent: () => import("./support.component").then((m) => m.SupportComponent)
    },
    {
        path: "supportcenter/downloads",
        loadComponent: () => import("./support.component").then((m) => m.SupportComponent)
    },
    {
        path: "supportcenter/knowledge",
        loadComponent: () => import("./support.component").then((m) => m.SupportComponent)
    }
];
