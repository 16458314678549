import angular from "angular";
import { AccountModule } from "./account";
import { appConfigModule } from "./app-config";
import { CommonModule } from "./common";
import { DirectorySearchModalModule } from "./directory";
import { IntakeModule } from "./intake";
import { InviteModule } from "./invite";
import { RecordsModule } from "./records";
import { ReleaseModule } from "./release";
import { SettingsModule } from "./settings";
import { SignaturesModule } from "./signatures";
import { TriageModule } from "./triage";

// Client Module -- Inject all sub-modules here when adding new modules
angular
    .module("kno2.client", [
        appConfigModule.name,
        CommonModule,
        AccountModule,
        IntakeModule,
        TriageModule,
        ReleaseModule,
        RecordsModule,
        SettingsModule,
        InviteModule,
        DirectorySearchModalModule,
        SignaturesModule
    ])
    .run([
        "UnsupportedBrowserService",
        async (UnsupportedBrowserService) => {
            UnsupportedBrowserService.checkBrowser();
        }
    ]);
