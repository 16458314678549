import { fromEvent } from "rxjs";
import { messageOfType } from "../../../../common/lib/observables";
export class QueryPreviewModalController {
    public static $inject: Array<string> = ["QueryService", "NotificationModalService", "$timeout"];

    private modalInstance;
    private resolve;

    public subscriptions: Array<any>;
    public hasError: boolean;
    public cacheId: string;

    constructor(
        private queryService,
        private notificationModalService,
        private $timeout
    ) {
        this.subscriptions = [
            fromEvent(window, "message")
                .pipe(messageOfType("error.cdapreview"))
                .subscribe((errorMessage: string) => {
                    this.$timeout(() => (this.hasError = true), 0);
                    this.notificationModalService.error(errorMessage);
                })
        ];
    }

    public $onInit(): void {
        this.hasError = false;
        this.cacheDocument(this.resolve.query);
    }

    public $onDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe && x.unsubscribe());
    }

    public cacheDocument(query): void {
        this.queryService
            .cacheDocument(query)
            .then((cacheId) => (this.cacheId = cacheId))
            .catch((err) => {
                this.hasError = true;
                this.notificationModalService.error(err);
            });
    }

    public close(): void {
        this.modalInstance.dismiss();
    }
}

class QueryPreviewModalComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: { [boundProperty: string]: string };

    public constructor() {
        (this.templateUrl = require("./query.preview.modal.component.html")),
            (this.controller = QueryPreviewModalController),
            (this.bindings = {
                modalInstance: "<",
                resolve: "<"
            });
    }
}

export const queryPreviewModalComponent = new QueryPreviewModalComponent();
