import templateUrl from "./admin-organizations-carequality-directory-edit.modal.component.html";

export class AdminOrganizationsCarequalityDirectoryEditModalController {
    constructor(AdminOrganizationsService, NotificationModalService, CommonData, QueryService) {
        this.AdminOrganizationsService = AdminOrganizationsService;
        this.NotificationModalService = NotificationModalService;
        this.CommonData = CommonData;
        this.QueryService = QueryService;
    }

    $onInit() {
        this.states = this.CommonData.states.hash;
        this.organization = this.resolve.organization;
        this.orgId = this.resolve.orgId;
        const parentOrgId = this.resolve.parentOrg ? this.resolve.parentOrg.organizationId : "";
        this.directoryEntry = this.resolve.entry || {
            organizationId: parentOrgId,
            parentOrgId: parentOrgId
        };

        if (!this.directoryEntry.id) {
            const address = this.organization.address;
            this.directoryEntry.address = address.street;
            this.directoryEntry.city = address.city;
            this.directoryEntry.state = address.state;
            this.directoryEntry.zipCode = address.zip;
            this.directoryEntry.country = address.country || "US";
            this.directoryEntry.purposeOfUseCodes = this.organization.allowedPurposeOfUseCodes;

            this.directoryEntry.displayName = this.organization.name;
            this.directoryEntry.homeCommunityId = this.organization.homeCommunityId;
            this.directoryEntry.organizationId = this.organization.homeCommunityId;
            this.directoryEntry.isActive = true;
        }
    }

    save() {
        const ctrl = this;
        ctrl.saveInProgress = true;
        const otherNames = ctrl.directoryEntry.otherNames || [];
        ctrl.directoryEntry.otherNames = otherNames.map((x) => x.text);
        ctrl.AdminOrganizationsService.upsertOrganizationDirectoryEntry(ctrl.orgId, ctrl.directoryEntry)
            .then(() => ctrl.close())
            .catch((res) => {
                ctrl.NotificationModalService.error(res.data.message, "There was an error upserting this entry");
                ctrl.saveInProgress = false;
            });
    }

    cancel() {
        this.dismiss();
    }
}

AdminOrganizationsCarequalityDirectoryEditModalController.$inject = ["AdminOrganizationsService", "NotificationModalService", "CommonData", "QueryService"];

export const adminOrganizationsCarequalityDirectoryEditModalComponent = {
    templateUrl,
    controller: AdminOrganizationsCarequalityDirectoryEditModalController,
    bindings: {
        resolve: "<",
        dismiss: "&",
        close: "&"
    }
};
