routeSubscriptionConfig.$inject = ["$rootScope", "$transitions", "$state", "SessionService", "SubscriptionService"];

/**
 *  Shows the subscription upgrade prompt if the user navigates to an area that requires subscription.
 */
export function routeSubscriptionConfig($rootScope, $transitions, $state, SessionService, SubscriptionService) {
    // Expose subscription methods globally
    $rootScope.promptUpgradeModal = SubscriptionService.checkAndShowPrompt;

    $transitions.onBefore({}, function (transition) {
        var to = transition.$to();
        var resource = (to.data && to.data.resource) || null;
        if (resource) {
            if (SubscriptionService.hasPromptAction(resource)) {
                SubscriptionService.showPrompt(resource)
                    .catch(() => {})
                    .then(() => {});

                const from = transition.$from();

                if (!from.name) {
                    const redirectUrl = SessionService.getDefaultRouteForActiveRole();
                    const targetState = $state.get().find((state) => state.url === redirectUrl);

                    return transition.router.stateService.target(targetState);
                }

                return false;
            }

            if (SubscriptionService.hasCancelAction(to.data.resource)) return false;
        }
    });
}
