import templateUrl from "./message-send-actions.component.html";

MessageSendActionsController.$inject = [];

export function MessageSendActionsController() {
    var ctrl = this;

    ctrl.send = function () {
        ctrl.onSend();
    };
}

angular.module("kno2.components").component("messageSendActions", {
    transclude: true,
    templateUrl,
    controller: MessageSendActionsController,
    bindings: {
        message: "=",
        processing: "<",
        sending: "<",
        readonly: "<",
        onSend: "&"
    }
});
