@if (error) {
    <kno2-notification-alert
        class="row col-sm"
        type="danger"
        [title]="error"
        [messages]="errorMessages"
        (dismiss)="error = null"></kno2-notification-alert>
}
<div class="form-group row">
    <label
        for="firstName"
        class="col-sm-3 control-label col-form-label">
        First Name
    </label>
    <div class="col-sm-8">
        <kno2-form-input
            name="firstName"
            [control]="parentForm().get('firstName')"
            [hasError]="parentForm().get('firstName').invalid && parentForm().get('firstName').dirty"
            errorClass="is-invalid"
            placeholder="First Name" />
    </div>
</div>
<div class="form-group row">
    <label
        for="lastName"
        class="col-sm-3 control-label col-form-label">
        Last Name
    </label>
    <div class="col-sm-8">
        <kno2-form-input
            name="lastName"
            [control]="parentForm().get('lastName')"
            [hasError]="parentForm().get('lastName').invalid && parentForm().get('lastName').dirty"
            errorClass="is-invalid"
            placeholder="Last Name" />
    </div>
</div>
<div class="form-group row">
    <label
        for="phoneNumber"
        class="col-sm-3 control-label col-form-label">
        Phone Number
    </label>
    <div class="col-sm-8">
        <kno2-form-input
            name="phoneNumber"
            [control]="parentForm().get('phoneNumber')"
            [hasError]="parentForm().get('phoneNumber').invalid && parentForm().get('phoneNumber').dirty"
            errorClass="is-invalid"
            mask="(000)000-0000" />
    </div>
</div>
<div class="form-group row">
    <label
        for="email"
        class="col-sm-3 control-label col-form-label">
        Email Address
    </label>
    <div class="col-sm-8">
        <kno2-form-input
            name="email"
            [control]="parentForm().get('email')"
            [hasError]="parentForm().get('email').invalid && parentForm().get('email').dirty"
            errorClass="is-invalid"
            placeholder="Email Address" />
    </div>
</div>
<div class="form-group row mt-3">
    <div class="col-sm-8 col-sm-offset-3">
        <div class="form-check">
            <input
                name="enabled2FA"
                [formControl]="parentForm().get('twoFactorEnabled')"
                [class.is-invalid]="parentForm().get('twoFactorEnabled').invalid && parentForm().get('twoFactorEnabled').dirty"
                class="form-check-input mr-3"
                type="checkbox" />
            <label class="form-check-label">
                Enable 2-Factor Authentication
                <span
                    class="ml-3"
                    kno2Popover
                    [attr.data-content]="mfaRequired() ? mfaEnforcedTooltip : mfaTooltip">
                    <i class="fa fa-info-circle box-info"></i>
                </span>
            </label>
        </div>
    </div>
</div>
<form
    [formGroup]="passwordForm"
    (ngSubmit)="changePassword()">
    @if (showChangePassword) {
        <div class="form-group row">
            <label
                for="inputOldPassword"
                class="col-sm-3 control-label col-form-label">
                Old Password
            </label>
            <div class="col-sm-8">
                <kno2-form-input
                    name="inputOldPassword"
                    type="password"
                    [control]="passwordForm.get('oldPassword')"
                    [hasError]="passwordForm.get('oldPassword').invalid && passwordFormSubmitted"
                    errorClass="is-invalid"
                    placeholder="Old Password" />
            </div>
        </div>
        <div class="form-group row">
            <label
                for="inputNewPassword"
                class="col-sm-3 control-label col-form-label">
                New Password
            </label>
            <div class="col-sm-8">
                <kno2-form-input
                    name="inputNewPassword"
                    type="password"
                    [control]="passwordForm.get('password')"
                    [hasError]="passwordForm.get('password').invalid && passwordFormSubmitted"
                    errorClass="is-invalid"
                    placeholder="New Password" />
            </div>
        </div>
        <div class="form-group row">
            <label
                for="inputConfirmPassword"
                class="col-sm-3 control-label col-form-label">
                Confirm Password
            </label>
            <div class="col-sm-8">
                <kno2-form-input
                    name="inputConfirmPassword"
                    type="password"
                    [control]="passwordForm.get('confirmPassword')"
                    [hasError]="passwordForm.get('confirmPassword').invalid && passwordFormSubmitted"
                    errorClass="is-invalid"
                    placeholder="Confirm Password" />
            </div>
        </div>
    }
    <div class="form-group row">
        <div class="col-sm-8 col-sm-offset-3">
            <div class="pull-right-btn-group">
                <button
                    type="button"
                    class="btn btn-default"
                    (click)="showChangePassword = !showChangePassword">
                    {{ showChangePassword ? "Cancel" : "Change Password" }}
                </button>
                @if (showChangePassword) {
                    <button
                        type="submit"
                        class="btn btn-primary">
                        Update Password
                    </button>
                }
            </div>
        </div>
    </div>
</form>
