export function formatContactDisplayFilter($filter): (value: string) => string {
    return (value: string): string => {
        if (!value.includes("|")) return value;

        const contacts = value.split(";");

        const results = contacts.map((contact) => {
            const [address, name, company] = contact.split("|");
            const formattedAddress = $filter("addressFormatting")(address);
            const contactDisplay = [name, company, formattedAddress].filter(Boolean).join(" | ");

            return contactDisplay;
        });

        return results.join(";");
    };
}
