import { Component, Input, inject } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UpgradeModule } from "@angular/upgrade/static";
import { AddIntegrationPatient, CapabilityProperty, IntegrationPatient, IntegrationsService } from "@kno2/data-access/integrations";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";
import { IntegrationPatientDynamicFormComponent } from "../integration-patient-dynamic-form/integration-patient-dynamic-form.component";
import { IntegrationPatientField } from "../integration-patient-dynamic-form/integration-patient-field";
import { GENDER_OPTIONS } from "./patient-add-form";

export type IntegrationDisplayName = "AlayaCare" | "Aprima V2" | "EMA" | "Eyefinity" | "RevolutionEHR";
@Component({
    selector: "kno2-patient-add-modal",
    standalone: true,
    imports: [ReactiveFormsModule, IntegrationPatientDynamicFormComponent],
    templateUrl: "./patient-add-modal.component.html"
})
export class PatientAddModalComponent {
    private readonly upgrade = inject(UpgradeModule);
    private readonly notificationService = this.upgrade.$injector.get("NotificationService");

    @Input({ required: true }) public integrationName: IntegrationDisplayName;
    @Input() public patient: AddIntegrationPatient;
    @Input() public capabilityProperties: CapabilityProperty[];

    public form: FormGroup = new FormGroup({}, { updateOn: "change" });
    public patientProperties: IntegrationPatientField[] = [];
    public hasSubmitted = false;
    public isSubmitting = false;

    constructor(
        private activeModal: NgbActiveModal,
        private integrationsService: IntegrationsService
    ) {}

    public ngOnInit(): void {
        this.patient = this.toAddPatient(this.patient);
    }

    public cancel(): void {
        this.activeModal.close();
    }

    public async submitForm(): Promise<void> {
        this.hasSubmitted = true;
        if (!this.form.valid) return;

        this.isSubmitting = true;
        const patient = this.form.getRawValue();
        const bd = patient.birthdate;

        if (bd) {
            patient.birthdate = DateTime.fromObject({ year: bd.year, month: bd.month, day: bd.day }).toFormat("yyyy-MM-dd");
        }

        try {
            await this.integrationsService.addIntegrationPatient(patient);
            this.activeModal.close(this.toSearchPatient(patient));
        } catch (err) {
            console.error(err);
            this.notificationService.errorToaster("An error has occurred while attempting to add a patient.");
        } finally {
            this.isSubmitting = false;
        }
    }

    private toAddPatient(patient: IntegrationPatient): AddIntegrationPatient {
        const [, gender] = GENDER_OPTIONS.find(([key]) => key.toLowerCase() === patient.gender?.toLowerCase()) || [];
        return {
            ...patient,
            gender
        };
    }

    private toSearchPatient(patient: AddIntegrationPatient): IntegrationPatient {
        const [patientSearchGender] = GENDER_OPTIONS.find(([, value]) => value === patient.gender);
        return {
            ...patient,
            gender: patientSearchGender.toLowerCase() || ""
        };
    }
}
