EFaxAccountService.$inject = ["$http", "appConfig"];

export function EFaxAccountService($http, appConfig) {
    this.save = function (account) {
        return $http.post("/api/efaxorganizationaccounts", account).then(function (response) {
            return response.data;
        });
    };

    this.get = function () {
        return $http.get("/api/efaxorganizationaccounts").then(function (response) {
            return response.data;
        });
    };

    this.delete = function (account) {
        return $http.delete("/api/efaxorganizationaccounts").then(function (response) {
            return response.data;
        });
    };

    this.verifyAccount = function (req) {
        return $http.post(`${appConfig.baseApiUrl}/incoming/efax/verifyaccount`, req).then(function (response) {
            return response.data;
        });
    };
}
