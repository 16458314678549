import templateUrl from "./admin-organizations-carequality-directory-sync.modal.component.html";

export class AdminOrganizationsCarequalityDirectorySyncModalController {
    constructor(AdminOrganizationsService, NotificationModalService) {
        this.AdminOrganizationsService = AdminOrganizationsService;
        this.NotificationModalService = NotificationModalService;
    }

    $onInit() {
        this.orgId = this.resolve.orgId;
    }

    save() {
        const ctrl = this;
        const oids = ctrl.oids || [];
        ctrl.AdminOrganizationsService.syncOrganizationDirectoryEntries(ctrl.orgId, { oids: oids.map((x) => x.text) })
            .then(() => ctrl.close())
            .catch((res) => {
                ctrl.NotificationModalService.error(res.data.message, "There was an error syncing");
            });
    }

    cancel() {
        this.dismiss();
    }
}

AdminOrganizationsCarequalityDirectorySyncModalController.$inject = ["AdminOrganizationsService", "NotificationModalService"];

export const adminOrganizationsCarequalityDirectorySyncModalComponent = {
    templateUrl,
    controller: AdminOrganizationsCarequalityDirectorySyncModalController,
    bindings: {
        resolve: "<",
        dismiss: "&",
        close: "&"
    }
};
