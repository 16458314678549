SiftMetricsService.$inject = ["$http"];

export function SiftMetricsService($http) {
    return {
        get: get
    };

    function get(query) {
        const params = { params: query };
        return $http.get("/api/diagnostics/siftmetrics", params).then(function (response) {
            return response.data;
        });
    }
}
