import { AdminExternalServiceAccountsService } from "../../../common/services/admin.external-service-accounts.service";

let ctrl;
class BrandServiceAccountController {
    public static $inject: Array<string> = ["AdminExternalServiceAccountsService", "$uibModal"];
    public selectedTab = "externalServiceAccount";
    public brandClientAccounts: Array<any>;
    public clients: any;
    public data: any;

    public brandId: string;
    public clientId: string;

    constructor(
        private AdminExternalServiceAccountsService: AdminExternalServiceAccountsService,
        private $uibModal
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.brandClientAccounts = [];
        this.loadBrandClients(this.brandId);
    }

    public loadBrandClients(brandId: string): void {
        let resultSet = [];
        const getSettingTypesPromise = this.AdminExternalServiceAccountsService.getClients("brand", brandId);
        getSettingTypesPromise.then((result) => {
            let reg = /([\[\]\"])+/g;
            for (let x = 0; x < result.length; x++) {
                let resultItems = result[x].scopes.replace(reg, "");
                resultSet.push({
                    clientId: result[x].clientId,
                    name: result[x].name,
                    description: result[x].description,
                    scopes: resultItems.split(",")
                });
            }

            this.clients = resultSet;
        });
    }

    public addClient(brandId: string): void {
        const modalInstance = this.$uibModal.open({
            component: "addBrandServiceAccountModalComponent",
            resolve: {
                brandId: function () {
                    return brandId;
                }
            }
        });
        modalInstance.result.then(() => {
            this.loadBrandClients(this.brandId);
        });
    }

    public editClient(clientId: string, scopes: Array<string>): void {
        const modalInstance = this.$uibModal.open({
            component: "editBrandServiceAccountModalComponent",
            resolve: {
                clientId: function () {
                    return clientId;
                },
                scopes: function () {
                    return scopes;
                }
            }
        });
        modalInstance.result.then(() => {
            this.loadBrandClients(this.brandId);
        });
    }

    public displayScopes(scopes: Array<string>): string {
        return scopes.join(", ");
    }
}

class BrandServiceAccountComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./brand.service-account.component.html");
        this.controller = BrandServiceAccountController;
        this.bindings = {
            modalInstance: "<",
            brandId: "<",
            clientId: "<"
        };
    }
}

export const brandServiceAccountComponent = new BrandServiceAccountComponent();
