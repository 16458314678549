import { AdminExternalServiceAccountsService } from "../../../../common/services/admin.external-service-accounts.service";

declare const ENVIRONMENT;
declare const $;

let ctrl;

class EditBrandServiceAccountModalController {
    public static $inject: Array<string> = ["NotificationService", "AdminExternalServiceAccountsService"];

    public serviceAccountScopes: Array<string>;
    public onboardingScopes: Array<string>;
    public managementScopes: Array<string>;
    public clientId: string;
    public cgr: string;

    public resolve: any;
    public close: any;
    public dismiss: any;

    constructor(
        private NotificationService,
        private AdminExternalServiceAccountsService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.clientId = ctrl.resolve.clientId;
        this.cgr = ctrl.resolve.cgr;
        this.getAvailableScopes();
        this.getClientGrants();
        this.serviceAccountScopes = ctrl.resolve.scopes;
        ctrl.onboardingScopes = [];
        ctrl.managementScopes = [];
    }

    public submitForm(): any {
        const scopes = !this.serviceAccountScopes ? [] : this.serviceAccountScopes;

        const request = {
            scope: scopes
        };

        try {
            this.AdminExternalServiceAccountsService.updateGrantTypes(this.cgr, this.clientId, request).then((data) => {
                this.NotificationService.success("The grant types were succesfully updated.");
                this.close();
            });
        } catch (error) {
            this.NotificationService.failure(error);
        }
    }

    public getAvailableScopes() {
        this.AdminExternalServiceAccountsService.getResourceServer("9DEB3C9A6E61492FB9C65CA3CC66F6C4").then((scopes) => {
            ctrl.onboardingScopes = scopes.data.scopes.filter((x) => x.description.includes("Onboarding -")).map((x) => x.value);
            ctrl.managementScopes = scopes.data.scopes.filter((x) => x.description.includes("Management -")).map((x) => x.value);

            ctrl.availableScopes = scopes.data.scopes.map((x) => x.value);
        });
    }

    public getClientGrants(): void {
        this.AdminExternalServiceAccountsService.getClientGrantType(this.clientId).then((grants) => {
            this.cgr = grants.data.id;
        });
    }

    public addAllOptions() {
        this.serviceAccountScopes = ctrl.availableScopes;
    }

    public removeAllOptions() {
        this.serviceAccountScopes = [];
    }

    public addAllOnboardingOptions() {
        this.serviceAccountScopes = ctrl.onboardingScopes;
    }

    public addAllManagementOptions() {
        this.serviceAccountScopes = ctrl.managementScopes;
    }
}

class EditBrandServiceAccountModalComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./edit-brand-service-account-modal.component.html");
        this.controller = EditBrandServiceAccountModalController;
        this.bindings = {
            createClient: "&",
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const editBrandServiceAccountModalComponent = new EditBrandServiceAccountModalComponent();
