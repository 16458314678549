export function friendlyKeyName() {
    return function (value: string): string {
        const chars = value.split("");
        const result = [];
        chars.forEach((x, idx) => {
            if (chars[idx - 1] && chars[idx - 1] === chars[idx - 1].toLowerCase() && x === x.toUpperCase()) result.push(" ");
            result.push(x);
        });
        return result.join("");
    };
}
