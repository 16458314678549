import templateUrl from "./query.documents.component.html";

angular.module("kno2.records").component("k2QueryDocuments", {
    controller: QueryDocumentsController,
    templateUrl,
    require: {
        wizard: "^^k2QueryWizard"
    }
});

QueryDocumentsController.$inject = ["$uibModal", "QueryService", "NotificationService"];

function QueryDocumentsController($uibModal, QueryService, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.getDocuments = getDocuments;
    ctrl.previewDocument = previewDocument;
    ctrl.sendToIntake = sendToIntake;
    ctrl.showPatient = showPatient;
    ctrl.getConfidenceValue = getConfidenceValue;
    ctrl.isMinimumOrNullDate = isMinimumOrNullDate;

    function $onInit() {
        ctrl.form = ctrl.wizard.form;
        ctrl.patients = ctrl.wizard.patients;
        ctrl.wizard.submit = null;
    }

    function showPatient(patient, organization) {
        $uibModal
            .open({
                component: "k2PatientDisplayModal",
                resolve: {
                    patient: function () {
                        return patient;
                    },
                    organization: function () {
                        return organization;
                    }
                }
            })
            .result.then(angular.noop, angular.noop);
    }

    function getDocuments(org, patient) {
        patient.loading = true;
        patient.hasRequestedDocuments = false;

        var requester = ctrl.wizard.query.requester;
        var identifier = patient.patientIdentifier[0];
        var query = {
            organizationId: org.orgId,
            homeCommunityId: identifier.patientHomeCommunityId,
            patientIdentifier: identifier.patientId,
            suppressRawMeta: true,
            requester: {
                roleCode: requester.role.code,
                purposeOfUseCode: requester.purpose.code
            }
        };

        QueryService.queryDocuments(query)
            .then(function (data) {
                patient.documents = data;
            })
            .catch(function (err) {
                let message = "There was an error loading documents for this patient. Please try again.";
                if (err.data && err.data.message) message = err.data.message;
                NotificationService.error(message);
            })
            .then(function () {
                patient.loading = false;
                patient.hasRequestedDocuments = true;
            });
    }

    function getConfidenceValue(confidenceValue) {
        return confidenceValue == -1 ? "--" : `${confidenceValue}%`;
    }

    function previewDocument(org, document) {
        var requester = ctrl.wizard.query.requester;
        var query = {
            organizationId: org.orgId,
            homeCommunityId: document.homeCommunityId,
            repositoryUniqueId: document.repositoryUniqueId,
            documentUniqueId: document.documentUniqueId,
            requester: {
                roleCode: requester.role.code,
                purposeOfUseCode: requester.purpose.code
            }
        };

        $uibModal
            .open({
                component: "k2QueryPreviewModal",
                windowClass: "preview-modal",
                resolve: {
                    query: function () {
                        return query;
                    }
                }
            })
            .result.then(angular.noop, angular.noop);
    }

    function sendToIntake(org, document) {
        var requester = ctrl.wizard.query.requester;
        var query = {
            organizationId: org.orgId,
            homeCommunityId: document.homeCommunityId,
            repositoryUniqueId: document.repositoryUniqueId,
            documentUniqueId: document.documentUniqueId,
            mimeType: document.mimeType,
            fileName: document.documentName.split(" ").join("_"),
            requester: {
                roleCode: requester.role.code,
                purposeOfUseCode: requester.purpose.code
            }
        };

        $uibModal
            .open({
                component: "k2QueryDocumentDestinationModal",
                windowClass: "modal-500",
                resolve: {
                    query: function () {
                        return query;
                    }
                }
            })
            .result.then(function () {
                document.downloaded = true;
            })
            .catch(angular.noop);
    }

    function isMinimumOrNullDate(date) {
        let result = true;
        if (date) {
            result = moment.utc(date).isSame("0001-01-01", "day");
        }
        return result;
    }
}
