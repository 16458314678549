import multiAddressSnippetTemplate from "./multi-address-snippet.html";

angular.module("kno2.directives").directive("multiAddressSnippet", [
    "_",
    function (_) {
        var emailRegex = /^\S+@\S+$/;
        return {
            restrict: "A",
            templateUrl: multiAddressSnippetTemplate,
            scope: {
                directiveAddresses: "@multiAddressSnippet"
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function (scope) {
                    scope.$watchCollection(
                        "directiveAddresses",
                        function () {
                            if (!_.isString(scope.directiveAddresses)) {
                                return undefined;
                            }

                            // this how many addresses we see before showing the [+ n more] span.
                            var limit = 3;

                            var addresses = $.trim(scope.directiveAddresses);
                            if (addresses) {
                                if (addresses.substring(addresses.length - 1) === ";") {
                                    addresses = addresses.substring(0, addresses.length - 1);
                                }
                                addresses = addresses.split(";");
                                var newAddresses = [];
                                var popoverAddresses = [];
                                scope.moreCount = 0;

                                _.each(addresses, function (address, idx, list) {
                                    if (idx < limit) {
                                        var add = {};
                                        add.full = address;
                                        if (list.length === 1) {
                                            var result = emailRegex.exec(address);
                                            if (result) {
                                                add.short = result.toString();
                                            } else {
                                                add.short = address;
                                            }
                                        } else {
                                            var i = address.indexOf("@");
                                            add.short = i !== -1 ? address.substring(0, address.indexOf("@")) : address;
                                        }
                                        newAddresses.push(add);
                                    } else {
                                        scope.moreCount++;
                                    }
                                    popoverAddresses.push(address);
                                });
                                scope.directiveAddresses = newAddresses;
                                scope.directiveAddressesLength = scope.directiveAddresses.length;
                                scope.popoverAddresses = popoverAddresses;
                            }
                        },
                        false
                    );
                }
            ]
        };
    }
]);
