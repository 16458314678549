export class AdminIntegrationsService {
    constructor($http) {
        this.$http = $http;
    }

    getIntegrations() {
        return this.$http.get("/api/admin/integrations").then(function (response) {
            return response.data;
        });
    }

    getOrgsForIntegration(integrationType) {
        return this.$http.get(`/api/admin/integrations/${integrationType}/organizations`).then(function (response) {
            return response.data;
        });
    }

    getIntegrationWhitelist(criteria) {
        const options = { params: criteria };
        return this.$http.get("/api/admin/integrations/whitelist", options).then(function (response) {
            return response.data;
        });
    }

    getIntegrationWhitelistEntry(id) {
        return this.$http.get(`/api/admin/integrations/whitelist/${id}`).then(function (response) {
            return response.data;
        });
    }

    addIntegrationWhitelistEntry(entry) {
        return this.$http.post("/api/admin/integrations/whitelist", entry).then(function (response) {
            return response.data;
        });
    }

    updateIntegrationWhitelistEntry(id, entry) {
        return this.$http.put(`/api/admin/integrations/whitelist/${id}`, entry).then(function (response) {
            return response.data;
        });
    }

    removeIntegrationWhitelistEntry(id) {
        return this.$http.delete(`/api/admin/integrations/whitelist/${id}`).then(function (response) {
            return response.data;
        });
    }
}

AdminIntegrationsService.$inject = ["$http"];
