ServiceAccountServiceFactory.$inject = ["$http", "$injector"];

export function ServiceAccountServiceFactory($http, $injector) {
    return {
        ofType: ofType
    };

    function ofType(type) {
        if (type === "admin") return $injector.get("AdminServiceAccountService");
        if (type === "network") return $injector.get("ServiceAccountService");
        else if (type !== "organization") throw new Error("Invalid Service Account type: " + type);

        return {
            getServiceAccounts: getServiceAccounts,
            addOrUpdateServiceAccount: addOrUpdateServiceAccount,
            updateIpAddresses: updateIpAddresses,
            toggleStatus: toggleStatus,
            deleteServiceAccount: deleteServiceAccount,
            getServiceAccountLimit: getServiceAccountLimit
        };

        function getServiceAccounts(groupId) {
            return $http.get("/api/organizations/" + groupId + "/serviceaccounts").then(function (response) {
                return response.data;
            });
        }

        function addOrUpdateServiceAccount(groupId, accountId = null) {
            var endpoint = "/api/organizations/" + groupId + "/serviceaccounts";
            if (accountId) {
                endpoint += "/" + accountId;
            }

            return $http.put(endpoint).then(function (response) {
                return response.data;
            });
        }

        function updateIpAddresses(groupId, accountId, addresses) {
            return $http.put("/api/organizations/" + groupId + "/serviceaccounts/" + accountId + "/ipaddresses", addresses).then(function (response) {
                return response.data;
            });
        }

        function toggleStatus(groupId, accountId, state) {
            return $http.put("/api/organizations/" + groupId + "/serviceaccounts/" + accountId + "/" + state).then(function (response) {
                return response.data;
            });
        }

        function deleteServiceAccount(groupId, accountId) {
            return $http.delete("/api/organizations/" + groupId + "/serviceaccounts/" + accountId);
        }

        function getServiceAccountLimit(organizationId) {
            return $http.get(`/api/organizations/${organizationId}/serviceaccount/limit`);
        }
    }
}
