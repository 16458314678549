ResellersDeleteCtrl.$inject = ["$scope", "$uibModalInstance", "resellerId", "AdminResellersService", "NotificationService"];

export function ResellersDeleteCtrl($scope, $uibModalInstance, resellerId, AdminResellersService, NotificationService) {
    $scope.resellerId = resellerId;

    var getResellerDto = function (id) {
        AdminResellersService.getResellerDto(id).then(function (data) {
            $scope.reseller = data;
        });
    };

    $scope.ok = function () {
        AdminResellersService.deleteReseller($scope.reseller.id).then(function (result) {
            $scope.reseller = result.data;
            $uibModalInstance.close($scope.reseller);
            NotificationService.success("The reseller was deleted successfully.");
        });
    };

    $scope.closeModal = function () {
        $uibModalInstance.dismiss("cancel");
    };

    var init = function () {
        getResellerDto($scope.resellerId);
    };
    init();
}
