import { CommonModule } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { MessagesService } from "@kno2/data-access/messages";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "kno2-delivery-summary-send-to-intake",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./delivery-summary-send-to-intake.component.html"
})
export class DeliverySummarySendToIntakeComponent implements OnInit {
    @Input({ required: true }) public messageId: string;

    public isSubmitting: boolean = false;

    private readonly upgrade = inject(UpgradeModule);
    private readonly notificationService = this.upgrade.$injector.get("NotificationService");

    constructor(
        private activeModal: NgbActiveModal,
        private messagesService: MessagesService
    ) {}

    public ngOnInit(): void {}

    public async onConfirm(): Promise<void> {
        this.isSubmitting = true;

        try {
            await this.messagesService.deliverySummarySendToIntake(this.messageId);
            this.notificationService.success(`Your Delivery Summary is being generated. To view the document, go to My Intake.`);
            this.activeModal.close();
        } catch (err) {
            this.notificationService.errorToaster(`The system encountered an error while generating the delivery summary.`);
        } finally {
            this.isSubmitting = false;
        }
    }

    public onCancel(): void {
        this.activeModal.close();
    }
}
