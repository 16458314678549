angular.module("kno2.services").service("TaskGearService", [
    "$rootScope",
    "localStorageService",
    "SessionService",
    function ($rootScope, localStorageService, SessionService) {
        var MAX_TASKS = 10;
        var TASK_STORAGE_SUFFIX = ".gearTasks-";
        var SHOW_NOTIFICATION_SUFFIX = ".gearShowNotifications";

        $rootScope.gearTasks = [];
        $rootScope.gearTasksActiveCount = 0;
        $rootScope.gearTasksShowNotifications = true;

        $rootScope.saveGearTasks = function (saveShowNotificationOnly) {
            const { userName } = SessionService.getProfile() || {};
            const TASK_STORAGE = userName + TASK_STORAGE_SUFFIX;
            const SHOW_NOTIFICATION = userName + SHOW_NOTIFICATION_SUFFIX;

            if (!localStorageService.isSupported) return;

            if (saveShowNotificationOnly) {
                localStorageService.set(SHOW_NOTIFICATION, $rootScope.gearTasksShowNotifications);
                return;
            }

            // Removing all tasks entries
            var i = 1;
            while (localStorageService.get(TASK_STORAGE + i)) {
                localStorageService.remove(TASK_STORAGE + i);
                i++;
            }
            // Add new tasks entries
            for (i = 0; i < MAX_TASKS; i++) {
                var value = i < $rootScope.gearTasks.length ? $rootScope.gearTasks[i] : null;
                if (value) {
                    if (angular.isObject(value) || angular.isArray(value)) {
                        value = angular.toJson(value);
                    }
                    localStorageService.set(TASK_STORAGE + (i + 1), value);
                }
            }
        };

        this.loadGearTasks = function () {
            const { userName } = SessionService.getProfile() || {};
            const TASK_STORAGE = userName + TASK_STORAGE_SUFFIX;
            const SHOW_NOTIFICATION = userName + SHOW_NOTIFICATION_SUFFIX;

            if (!localStorageService.isSupported) return;
            var saveRequired = false;
            for (var i = 0; i < MAX_TASKS; i++) {
                var value = localStorageService.get(TASK_STORAGE + (i + 1));
                if (value) {
                    var task = angular.fromJson(value);
                    // Mark any saved inProgress tasks in error since it have been interupted.
                    if (task.worker.inProgress) {
                        task.worker.inError = true;
                        task.worker.inProgress = false;
                        saveRequired = true; // Resave those changes immediately.
                    }
                    $rootScope.gearTasks.push(task);
                }
            }
            $rootScope.gearTasksShowNotifications = localStorageService.get(SHOW_NOTIFICATION) !== "false";
            if (saveRequired) {
                $rootScope.saveGearTasks();
            }
        };

        $rootScope.gearTasksShowNotificationsClick = function () {
            $rootScope.saveGearTasks(true); // Just save the show notification flag only.
        };

        $rootScope.clearGearTasks = function () {
            if ($rootScope.gearTasks.length > 0) {
                for (var i = $rootScope.gearTasks.length - 1; i >= 0; i--) {
                    if (!$rootScope.gearTasks[i].worker.inProgress) {
                        $rootScope.gearTasks.splice(i, 1);
                    }
                }
            }
            $rootScope.saveGearTasks();
        };

        $rootScope.gearTasksClass = function (item) {
            switch (item.type) {
                case "file":
                    return "fa fa-arrow-circle-o-down";
                case "print":
                    return "fa fa-print";
                default:
                    return "";
            }
        };

        this.updateActiveCount = function () {
            var cnt = 0;
            for (var i = 0; i < $rootScope.gearTasks.length; i++) {
                if ($rootScope.gearTasks[i].worker.inProgress) {
                    cnt++;
                }
            }
            $rootScope.gearTasksActiveCount = cnt;
        };

        this.purgeTaskList = function () {
            if ($rootScope.gearTasks.length <= MAX_TASKS) {
                return;
            }
            var len = $rootScope.gearTasks.length;
            $rootScope.gearTasks.splice(MAX_TASKS, len - MAX_TASKS);
        };

        this.getExportType = function (type) {
            switch (type) {
                case "file":
                    return '"Download"';
                case "print":
                    return '"Print"';
                default:
                    return "";
            }
        };

        this.notify = function (event) {
            // Add new task
            var _task = _.find($rootScope.gearTasks, { id: event.id });
            if (!_task) {
                $rootScope.gearTasks.unshift({
                    id: event.id,
                    type: event.type,
                    content: event.content,
                    date: event.date,
                    href: event.href,
                    worker: event.worker,
                    message: event.message
                });

                // On screen notification
                if ($rootScope.gearTasksShowNotifications) {
                    var opts = {
                        title: "<strong>" + stringFormat("{0} task has been created" + "</strong>", this.getExportType(event.type)),
                        text: event.message.subject + "<br /> From: " + event.message.from + "<br /> To: " + event.message.to,
                        styling: "bootstrap3",
                        icon: true,
                        type: "info",
                        delay: 3000,
                        buttons: {
                            sticker: false,
                            sticker_hover: false
                        }
                    };
                    new PNotify(opts);
                }
                // Update existing task
            } else {
                _task.worker.inProgress = event.worker.inProgress;
                _task.worker.inError = event.worker.inError;
            }
            this.purgeTaskList();
            this.updateActiveCount();
            $rootScope.saveGearTasks();
        };
    }
]);
