import templateUrl from "./admin-ipaddresses-list.component.html";

export class AdminOrganizationsIpAddressesController {
    constructor($stateParams, AdminIpAddressesService) {
        this.$stateParams = $stateParams;
        this.AdminIpAddressesService = AdminIpAddressesService;
    }

    $onInit() {
        this.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        this.pagedItemRange = this.pagedItemRanges[0];
        this.pageStart = 1;

        this.organizationId = this.$stateParams.id;
        this.getPagedIpAddresses(this.organizationId);
    }

    getPagedIpAddresses() {
        if (!this.search) this.search = null;
        return this.AdminIpAddressesService.getPagedIpAddressesByOrgId(
            this.organizationId,
            this.pageStart,
            this.pagedItemRange.value,
            this.search,
            this.isWhitelisted,
            this.isBlacklisted,
            this.includeDisabled
        ).then((res) => {
            this.ipAddresses = res.data.items;
            this.totalCount = res.data.totalCount;
        });
    }
}

AdminOrganizationsIpAddressesController.$inject = ["$stateParams", "AdminIpAddressesService"];

export const adminOrganizationsIpAddressesComponent = {
    templateUrl,
    controller: AdminOrganizationsIpAddressesController,
    bindings: {
        organization: "<"
    }
};
