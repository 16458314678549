import { NgClass } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

@Component({
    selector: "kno2-form-input",
    standalone: true,
    imports: [ReactiveFormsModule, NgxMaskDirective, NgClass],
    templateUrl: "./form-input.component.html",
    providers: [provideNgxMask()]
})
export class FormInputComponent {
    public type = input<string>("text");
    public control = input.required<FormControl>();
    public hasError = input<boolean>(false);
    public errorClass = input<string>("has-error");
    public placeholder = input<string>();
    public mask = input<string>();

    protected readonly onBlurEvent = output<void>();
}
