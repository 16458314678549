IdProofingService.$inject = ["$http"];
export function IdProofingService($http) {
    this.getUserIdentity = function () {
        return $http.get("/api/users/current?s=true");
    };

    this.submitIdentity = function (identity) {
        return $http.post("/api/equifax/identity?s=true", identity);
    };

    this.submitAnswers = function (answers) {
        return $http.post("/api/equifax/quizanswers?s=true", answers);
    };
    this.checkDuplicateAddress = function (address) {
        return $http({
            url: "/api/equifax/validateaddress?address=" + address,
            method: "GET"
        }).then(function (response) {
            return response.data;
        });
    };

    /*2014-08-21 IKW-1049 PR: Keep for future usage.
    this.sendEULAByEmail = function (eulaDto) {
        return $http.post('/api/eula/sendEmail', eulaDto);
    };*/
}
