export const tooltips = {
    Billing: `All billing for your organization that is charged via Credit Card or ACH is displayed below.<br /><br />Please use caution when updating the billing details or changing subscription levels as this may impact the monthly billing transaction for your organization.`,
    DocumentTypes: `Document types are classifications or labels of different documents that comprise the patient record in your organization.<br/><br/>Document types are used for relabeling of documents and attachments received from external organizations into Intake or for labeling of a document or attachment being Released from your organization.<br /><br />A set of common document types comes preconfigured.  These document types may be edited or deleted.<br /><br />When configuring document types, please consider the use of active document types or categories, and also reconciliation to an existing document type list that may already exist in your physical charts or EMR.<br /><br />To create a new document type, click on the plus sign to the right. To edit an existing document type, click on the pencil to the right of that document type.`,
    ReleaseTypes: `A release represents a reason why and to whom sensitive patient information is being released from your organization to an intended recipient.<br/><br/>A set of system releases are automatically included and cannot be edited. System releases can be used either in an ad hoc fashion where you supply the To and From addresses or fax numbers, or certain system releases may have predetermined settings, such as the To/Destination, that cannot be edited.<br/><br/>Additional releases may be created for use by your organization. These releases should represent the most common destinations/providers that you share patient information with. These are referred to as quick releases.<br/><br/>Releases that you have added can be edited or removed at any time by an administrator.<br/><br/>`,
    IntakeDownloadConfiguration: `Intake Download is used to configure the settings for download of the messages and attachments processed within Intake for printing or filing back to your patient chart.<br /><br />Patient chart formats include a paper chart, document management system, or EMR.<br /><br />Three primary download configurations are available - Print Only, Save or Print, or Forward to EMR.<br /><br />The Print Only option allows any message with an attachment(s) to be converted to a print-ready format that can be easily filed into your paper chart.<br /><br />The Save or Print option allows you to identify the type and format of an electronic download to a designated network path. This option also allows for the printing of documents, as indicated.<br /><br />The Forward to EMR option allows you to identify the EMR Direct address to which you wish to forward your processed messages.  This type also allows for printing.<br /><br />Electronic downloads include the ability to save in the native format received in Intake, convert to PDF, or save as an EMR-ready format (XDS-SD/CDA).<br /><br />Choose the desired download type below.`,
    IntakeAdmin: `Below are all the views to filter the messages displaying on the left hand side.<br /><br />The By Rules view will display messages based upon the rules checked under My Rules.<br /><br />The All view will display all messages for your organization.<br /><br />The Unassigned view will display all unassigned messages (no rule applied) for your organization.`,
    IntakeBox: `Intake is a view of all messages that have been assigned to you for processing by a rule. Each message originates from one of the enabled Document Sources or from a batch processed through Triage.<br /><br />Select the checkbox next to a rule or rules to filter the message results in your Intake.  To process a message in your Intake, simply click on the message.`,
    IntakePatientDetails: `Patient Details is the metadata assigned to the active message and all the associated attachments. <br /><br /> The patient details may prepopulate with information from the inbound message. <br /><br /> Please review and update the information to match your internal patient information. <br /><br />Visit details are optional and may be used to assign the message and associated attachments to the correct patient's visit or admission.`,
    IntakeProcessAttachments: `A message may have multiple attachments associated, which are filed as individual documents within your EMR or electronic patient file. <br /><br /> Tag each attachment with the appropriate document type and the document date. <br /><br />A document date may be today's date, the file date, or the actual message date of the document if maintained within the document's metadata. <br /><br /> Optionally, additional detail may be added to the document in the Document Note section.`,
    IntakeProcessSave: `"Save" will save any patient, visit, or document details that have been entered on the active message. <br /><br /> "Download" and "Print" are used for exporting documents from the active message and the associated attachments to a print-ready format or download package, as configured in your instance. Note that the save and download icons will only appear if your instance is configured to use Download. <br /><br /> The message and every attachment are individual documents upon download. <br /><br /> "Reply" will allow you to send a reply from the active message to the Sender. The dropdown list provides options to send a reply, forward the message, mark as processed or delete the message.`,
    NetworkAdminGroupMapping: `Groups mapped to Network Admin will not be available for Organization mapping.`,
    NetworkOrganizationGroupMapping: `Groups mapped to organizations will not be available for Network Admin mapping.`,
    NetworkOrganizationGroupMappingSelectAll: `Selecting this option will add a mapping of this group to all existing organizations under your network. Any new organizations added to your network will need to be mapped separately.`,
    Notifications: `The notifications include active System Alerts and Message Events for your organization. <br /><br />  System Alerts are alerts that are identified at a system level, including outages and scheduled downtime. <br /><br /> Message Events are events related to messages sent and received by your instance from any enabled Document Source. <br /><br /> For example, if a sent Direct Message or fax fails to be delivered to the intended recipient, a Message Event will appear with a critical status.`,
    ReleaseAttachment: `Add an attachment to your message by selecting the Browse button, choosing the desired file and completing the rest of the attachment data. <br /><br /> Select the appropriate document type, document date and any additional comments to append to the attachment.<br /><br />  Perform this process for each attachment.`,
    ReleaseAttachments: `A message should have one or more attachments for the intended recipient. <br /><br /> An attachment may include a CCDA, physician note, progress note, lab, or scanned documentation for a patient. <br /><br /> To add a new attachment click on the plus sign to the right.`,
    ReleaseDirectorySearch: `The Directory Search is providing access to the largest National Provider Directory sponsored by Surescripts®. <br /><br />   Search for the Direct Address of an intended recipient and/or organization by entering key values into the name or organization level details and selecting the Search button.<br /><br />  All matching results will be displayed below.<br /><br />  Select the intended recipient to populate the To address.`,
    ReleaseFrom: `Select the address or fax number from which you would like to send this message on behalf of.`,
    ReleaseReleaseType: `A Release Type represents the reason why a release is being created and sensitive patient information is being sent from your organization to an intended recipient. <br /><br /> Common release types (or reasons) include completing a patient referral, transition of care of a patient record to another provider, or fulfilling a medical record request.<br /><br />Select a release type from the dropdown list to indicate the type of release being sent.`,
    ReleaseNew: `A Release (or Release of Information) is the process used to release patient information from your organization to a recipient, such as for a referral. <br /><br />To initiate a new release, click the Initiate Release button. <br /><br /> Please note:  Only one patient can be assigned to a release.`,
    ReleasePatientDetails: `Patient Details is the patient metadata assigned to the active message and all associated attachments. This is used by the intended recipient to quickly file who the message belongs to.<br /><br />Please accurately input the patient information below.`,
    ReleaseRecentActivity: `Released includes all of the most recent release activity and the status of each release.`,
    ReleaseSendAdditional: `Please include any additional information outlining the purpose and request for the disclosure. <br /><br /> This is used for advanced auditing purposes and describes why and to whom the information is being released. <br /><br /> For example, "Patient's medical records are being requested for further review by Dr. Smith." <br /><br /> This information is not submitted with the message, but is logged with the internal audit for the message.`,
    ReleaseSendComment: `Include a comment to provide further instruction for the recipient of the message. <br /><br /> The comment will be appended to the message body.`,
    ReleaseTo: `Enter or search for one or more addresses or fax numbers to send the message to.  <br /><br /> Select the search icon at the end of the field to perform a directory search by name, organization, or geographic area in the National Address book to find the correct recipient and their associated addresses.`,
    Rules: `A rule is used to filter received messages by specific criteria, such as by the To and/or From address or fax number, or a user or group of users, and route these messages to the assigned user's Intake.<br /><br /> A message that is received that does not meet an existing rule (or filter) will show up as Unassigned and must have a rule assigned before the message and attachments can be processed and filed.`,
    Security: `Configuration and management of system and patient information security is very important in healthcare. <br /><br /> Security settings are configured below. <br /><br />These values control global settings for your instance and all of your users. <br /><br />  Please use caution when updating this information.`,
    FaxOptions: `Fax options allow you to change the pages that are added to a fax message.  <br /><br /> Selecting "Cover Page" will add a cover page to your fax. Selecting "Message Body" will add a page for the message body to your fax. Selecting "Attachment Descriptions" will add a page for each attachment description.  <br /><br /> These values are defaulted to the settings specified for your organization by your organization administrator.`,
    SettingsDirectAddresses: `Direct Addresses are like email addresses, but used only in healthcare, and are used for sending and receiving information to and from your organization to external organizations.<br/><br/>An Address is not only an address within the network but is also a Direct Address.<br/><br/>An Address can be assigned that represents a provider (drjohndoe@organization.xxxxx.com), department (HIM@organization.xxxxx.com), group of individuals (nurses@organization.xxxxx.com), or organization/department (records@organization.xxxxx.com).<br/><br/>It is recommended that if only one person works in a department, the address should be created with the department name and then assign that user with send permissions for the address.<br/><br/>Create the address for your organization and assign to a user account(s).<br/><br/>The user account(s) assigned will have the ability to send a message using the address(es) assigned to them.<br/><br/>To route information received by these addresses, build a Rule with the address in the To field.<br/><br/>To create a new Address, click on the plus sign to the right. To edit an existing address, click on the pencil to the right of that address.`,
    SettingsGearAccount: `Account information is organization level information and settings. <br /><br /> These values control global settings for your instance and all your users. <br /><br /> Please use caution when updating this information.`,
    Users: `Users in this view are individuals that have been granted access to Organizations. <br /><br />User access allows a user to receive and process incoming documents through Intake and perform a Release of patient documents to intended recipients. <br /><br />Organization administrative access includes the ability to view analytics for organization level activity and configure organization level system settings. <br /><br />Network administrative access includes the ability to configure network level system settings.`,
    SupportCenterGuides: `It is our objective to provide an in-depth guide of all available features. As additional features are released, the documentation is updated. <br/><br/> We encourage you to regularly visit the guides for the most recent up to date information.`,
    SupportCenterSubmitTicket: `If you have experienced a technical issue or have a suggestion for a feature enhancement to the system, please complete the following fields to submit a ticket to our support team. <br/><br/> It is very important for us to provide you with the best service possible.  In order to do so, we request that you complete all fields and provide a thorough description of the issue or feature requested.`,
    SupportCenterAreas: `Please select the area in which you encountered the issue or would like to see a feature added.<br/><br/>This will be beneficial to our support team in responding to your request as quickly as possible.`,
    SupportCenterDownloads: `The Downloads are provided to include tools to use during your experience. Examples may include printing or downloading. The team will continue to expand the download functionality to enhance the user experience. Please note that once a download has been completed, that download will be automatically updated to ensure the most recent version is communicating with your instance.`,
    SupportCenterKnowledgeBase: `The Knowledge Base is a central location to find answers to frequently asked questions and detailed descriptions about many features.`,
    DirectAddressModalPageAddressEdit: `Addresses are like email addresses, but are used only in healthcare.<br/><br/>Edit an existing Address for your organization by entering the new value and selecting Save Changes.<br/><br/>A Address can be assigned that represents a provider (drjohndoe@organization.xxxxx.com), department (HIM@organization.xxxxx.com), group of individuals (nurses@organization.xxxxx.com), or organization/department (records@organization.xxxxx.com).`,
    DocumentTypeModalPageAdd: `Document types represent document classifications used by your organization for filing and organizing patient charts.<br /><br />Add a document type by entering the document type value above and selecting Save Changes.<br /><br />The new document type will appear in the list of available document types.<br /><br />It is highly recommend to avoid very general classifications, such as "Miscellaneous", or classifications that are too specific, such as "Dr. Jones' lab records."`,
    ReleaseTypeModalPageAdd: `A release name should represent the context and recipient of the release. In addition, the release name should allow any person within your organization to easily discern the purpose of the release and who the recipient is. For instance, examples of release names could include:<br/><br/>Referral - Dr Smith<br/>Patient Record Request - Dr Smith<br/>Supporting Claim Info - RX Insurance<br/>Order - Hopeful Homecare<br/>Order - Intermountain Radiology<br/>Releases will vary based upon the specialties of the organization.`,
    UserModalPageAdd: `A User is an individual who is part of your organization that is granted user or administrative access to your instance.<br /><br />To be in alignment with federal regulations pertaining to system audits of user activity, each individual must have their own user account. The username for each individual will be their email address. Shared or generic accounts are not allowed.<br /><br />To add a user to your instance, complete the fields below and select Save Changes.  This will send an email invitation for password completion to the newly added user.`,
    UserModalPagePrimaryJobAdd: `Select the Primary Job Function for the user being added.`,
    UserModalPageUserNameKno2: `Enter the email address of the user.<br /><br />The email address can be a corporate email or other type of email, such as Gmail or Hotmail.<br /><br />No personal information is ever delivered to a general email address.`,
    UserModalPageUserNamePointClickCare: `Enter the PointClickCare username of the user.<br /><br />Note: This is only the username, not including the organization prefix. Example: 'testuser' not 'myorg.testuser'`,
    UserModalPagePhoneNumber: `Phone number is managed by the User.`,
    LoginActivity: `View all activity surrounding this user account by selecting the activity below and the date range in which to view the activity that has occurred.  The results will appear on the right hand side.`,
    MessageActivity: `View all activity surrounding this message by selecting the activity below and the date range in which to view the activity that has occurred.  The results will appear on the right hand side.`,
    DashboardDirectMessage: `Direct messages are a secure way to send and receive documents between healthcare providers, similar to fax and email, but specifically used for healthcare.<br /><br />Direct messages are enabled as a document source for your organization.<br /><br />The number in the upper right hand corner represents the total number of Direct messages waiting to be processed in all intake boxes.`,
    DashboardMessageStatistics: `Message Statistics shows sent and received message activity within the defined time criteria for your organization.`,
    DashboardRecentActivities: `Recent Activity includes all of your most recent release activity and the status of each Release.`,
    DashboardTitle: `The Dashboard provides an overview of all the sent and received Messages (from any enabled Document Source) in your organization for a specific period of time.`,
    IntakeAllRules: `Below are all the current rules in place for the organization.<br /><br />To add a new rule, click on the "+" symbol, which will take you to Intake Rules.`,
    TriageRulesAllRules: `All of the current Triage rules in place for the organization.<br /><br />To copy, edit or delete any rules, click the rule name.`,
    IntakeRulesAllRules: `All of the current Message rules in place for the organization.<br /><br />To copy, edit or delete any rules, click the rule name.`,
    IntakeRulesFromAddress: `The "From" is the address, Direct address or fax number from which the incoming message was received.  These addresses or fax numbers are used by external organizations, such as a nearby hospital.  Only one "From" address or fax number can be assigned to a rule.`,
    IntakeRulesResults: `The Results below represent all unprocessed messages across all Intake boxes that meet the above rule criteria.<br /><br />If no messages appear, no unprocessed messages for all Intake boxes meet the rule criteria, however the rule can still be saved.`,
    IntakeRulesToAddress: `The "To" address or fax number is the address or internal fax number to which the incoming message is being sent to.<br /><br />These "To" addresses or fax numbers represent the addresses enabled for your organization or your fax numbers under Settings.  Only one "To" address or fax number can be assigned to a rule.`,
    SessionTimeout: `Session Timeout determines the length of time users can be idle before being automatically logged out of the system.`,
    DirectAddressModalPageAddressType: `Select "Provider" if this Address is for a healthcare provider in your organization.`,
    AccountLockout: `By enabling account lockout this will lock your account for 10 minutes after 3 failed login attempts. This protects from brute force attacks and password guessing.`,
    ReleaseDrafts: `Drafts includes all of your organization's message drafts and allows you to edit and complete them.`,
    IntakeInclude: `By default, only those attachments selected by the user will be included in the package for "download" or "print".  If you would like to include the message details as part of the package, then be sure to select Message Body.`,
    DirectAddressModalPageAddressAdd: `Addresses are like email addresses, but are used only in healthcare.<br/><br/>Add an Address for your organization by entering the first part of the address in the field.<br/><br/>A Address can be assigned that represents a provider (drjohndoe@organization.xxxxx.com), department (HIM@organization.xxxxx.com), group of individuals (nurses@organization.xxxxx.com), or organization/department (records@organization.xxxxx.com).`,
    DocumentTypeModalPageEdit: `Document types represent document classifications used by your organization for filing and organizing patient charts.<br /><br />Edit an existing document type by entering the new value above and selecting Save Changes.<br /><br />The updated document type will appear in the list of available document types.<br /><br />It is highly recommend to avoid very general classifications, such as "Miscellaneous", or classifications that are too specific, such as "Dr. Jones' Lab Records".`,
    IntakeFilters: `Below are the message statuses to filter the messages displayed on the left hand side.`,
    IntakeMyRules: `Below are all of your current rules.  The By Rules view will display messages based upon the rules checked under My Rules.`,
    IntakeSources: `Below are all the sources to filter the messages displaying on the left hand side.`,
    ReleaseTypeModalPageEdit: `A release name should represent the context and recipient of the release. In addition, the release name should allow any person within your organization to easily discern the purpose of the release and who the recipient is. For instance, examples of release names could include:<br/><br/>Referral - Dr Smith<br/>Patient Record Request - Dr Smith<br/>Supporting Claim Info - RX Insurance<br/>Order - Hopeful Homecare<br/>Order - Intermountain Radiology<br/>Releases will vary based upon the specialties of the organization.`,
    UserModalPageEdit: `To edit a user for your instance, make the desired changes in the fields below and select Save Changes.`,
    UserModalPagePrimaryJobEdit: `Select the Primary Job Function for the user being edited.`,
    WelcomeKno2ModalPage: `Like a fax or email address books, the National Provider Directory is a nationwide directory of organizations and providers and their associated addresses made publicly available to other providers for communication across trusted healthcare networks. Your organization and other healthcare organizations can easily search local, regional and national trading partners.`,
    SettingsDeviceManagement: `Add-Ons are devices and connectors that are "Direct-enabled" using technology. Common Add-Ons, such as document scanners, multi-function devices (fax/print/scan/copy units), fax technology, and others are transformed to send Direct Messages instead of a traditional scan or fax. All Add-On licenses and connectivity are managed through the Add-On Management console.`,
    SettingsDevices: `<p>View all Device connections for your organization including the device ID, make, model, serial number and device type. Device types include descriptions such as a MFP or scanner. In addition, the device status indicates the connectivity status to the system for each device.</P> <p>Status types include Unlicensed or Valid. Unlicensed devices will not be able to successfully connect and send a message until they are licensed. To change a status from Unlicensed to Valid, select the linking button and attach to an available license.</P> <p>To remove a license, select the linking button and detach from the current license.</P>`,
    SettingsLicenses: `Add new and view all licenses assigned to your organization. License details include the license ID, the type of license, expiration date and status of the license. Add additional licenses by selecting the Enter Additional Licenses button.</br></br>Please Note: In order to connect more devices, you will need to purchase additional licenses from your vendor. Please contact your local sales representative.`,
    ReleaseTypeModalDestination: `If you specify a destination address or fax number, the system will automatically fulfill the "To Address or Fax Number" to the specified destination when this Release Type is selected.`,
    ResellerLogo: `In order for your logo to appear properly in our system it needs to be a transparent (<strong>.png</strong>) image. We have a preset list of background colors for you to choose from that your logo will be placed on.<br /><br />We also advise you to use a square or vertically aligned logo rather than a wide rectangle or horizontally aligned logo. Your logo's width will be restricted to <strong>325px</strong> within the workbench and <strong>160px</strong> on the registration site.`,
    AffiliateUrlTag: `The Affiliate URL Tag is the URL Tag that is setup for a reseller within FastSpring. This needs to match the exact value within FastSpring in order for the reseller to be compensated for organizations that sign up through their URL.`,
    SendInvitation: `<p>This option is used to send an email invitation to other providers or organizations, notifying them that you are transitioning away from fax and using Direct messaging to exchange patient documentation electronically.</p>To send an email invitation:<ul style='position:relative; margin-bottom:20px;'><li>        Enter the full name of the provider or organization</li><li>        Enter their email address</li><li>        Select a Direct Address for your organization to appear in your invitation signature line</li><li>        Click Send</li></ul><p>The recipient will receive an email inviting them to sign up.</P><p>A sample of the invitation email can be viewed from the information tip below.</P>`,
    HealthReferralRegionCode: `The Health Referral Region code is the unique code assigned to a region by the Dartmouth Atlas of Health Care`,
    HealthReferralRegionDescription: `The Health Referral Region description is a friendly description to help the user determine if they have the correct region selected`,
    HealthReferralRegionName: `The Health Referral Region name is the unique name assigned to a region by the Dartmouth Atlas of Health Care`,
    HealthServiceArea: `To update the health referral region and health service area data, upload the zipcode crosswalk CSV file and select "Save Changes"`,
    RecordsRequestTitle: `A Request (or Records Request) is the process used to request patient records electronically from another organization, for purposes such as care coordination, workman's compensation, legal reasons, etc.<br /><br />To initiate a new request, click the Request Records button.<br /><br />Please note:  Only one patient can be assigned per request.`,
    RecordsRequestSentTitle: `Sent includes all the Records Requests that have been submitted including the status and request detail.`,
    RecordsRequestFromAddress: `Select the address or fax number from which you would like to send this request on behalf of.`,
    RecordsRequestName: `If you are performing the request for another person in your organization, please enter their first and last name.`,
    RecordsRequestFacility: `The facilities shown are a listing of facilities that have implemented automation for Records Requests received from the system in your surrounding geography.  If you do not see the facility that you are looking for in the list or if the facility resides outside your designated geography, select the search glass and look for the name of the facility. Once you locate the facility, select the specific location of the facility.<br /><br /> If you are still unable to find the facility, the facility may not be enabled for automation.  However, you can still submit an electronic records request to any organization's fax number, or Direct Address by manually entering the Direct Address, or fax number in the Address section below.  If you do not know or cannot find the Direct Address using the search option, you can send using their fax number or contact them for their address information. `,
    RecordsRequestLocation: `Once you have selected the facility, the listing of locations enabled for automated Records Request from the system are provided. You must select at least one location. If you are desiring records from all locations or from the primary location, select the Main Location option.`,
    RecordsRequestAddress: `If you are not able to find the facility above, you can still submit an electronic records request to any organization enabled for Direct Messaging by entering their Direct Address.  Manually enter the Direct Address in the Address section.  You may also use a fax number.  If you do not know or cannot find the Direct Address using the search option, you can send using their fax number or contact them for their address information.`,
    RecordsRequestFacilitySearch: `The Facility/Hospital Search allows you to search for a facility or listing of facilities in an area that are enabled to automate patient record requests from the system.  Search for the facility/hospital name or number or for a listing of facility by selecting the city and state or zip code entry.`,
    RecordsRequestPatientDetails: `Patient Details is YOUR patient metadata assigned to the Records Request and the attached consent, including your patient ID, patient Number or other number such as a Case Number that you used for internal tracking purposes.  By providing this information in the request, the recipient may be able to provide you this detail back allowing your records to be quickly assigned to the correct patient record in your patient charts. `,
    RecordsRequestPatientId: `The Patient ID/No should be YOUR patient Number or other number such as a Case Number that you use for internal tracking purposes.  By providing this information in the request, the recipient may be able to provide you this detail back allowing your records to be quickly assigned to the correct patient record in your patient charts.`,
    RecordsRequestDetails: `Request Details includes the information regarding why the request is being made, the portion of the patient records that are desired and the urgency of the request.  The more specific the details are that you provide for the request, the faster the receiving organization will be able to respond to the request.`,
    RecordsRequestAdditional: `Use the Additional Request options to request access to highly protected portions of the patient record including behavioral health records, alcohol abuse, HIV, etc.   You may only request these records with the appropriate authorization from the patient, patient's legal guardian or power of attorney.  The authorization must be attached before you will be able to submit the request.`,
    RecordsRequestConsent: `A patient consent/authorization must be attached in most cases to request records from an external organization.  Attach a PDF or TIF file of the scanned or electronic consent/authorization. `,
    LicenseTypes: `License types enforce compatibility rules for devices and other connectors which makes it possible to ensure a license purchased for one type of device or connector is not applied to a different type of device or connector.`,
    AppName: `The App or Connector Name must be unique.  It is used to look up license types and to enforce compatibility rules when applying licenses to a connected app or device.`,
    Apps: `Similar to devices, apps or connectors are connected clients that are authorized to send and receive over the system.`,
    SettingsApps: `<p>View all Application connections for your organization including the application ID, name, serial number/vendor product key and type of application. Application type indicates how the application connects to the system and which license the application is compatible with. In addition, the license status of a connected application indicates the connectivity status to the system for each application.</P> <p>Status types include Unlicensed or Valid. Unlicensed applications will not be able to successfully connect and send a message until they are licensed. To change a status from Unlicensed to Valid, select the linking button and attach to an available license.</P> <p>To remove a license, select the linking button and detach from the current license.</P>`,
    ReleaseSendAttachmentComment: `Select the file format in which to send your attachment(s). <br /><br /> Faxes will be automatically converted to PDF format, so no selection is needed.`,
    Triage: `Document Triage allows any organization to standardize, centralize and efficiently file all received clinical documentation from any enabled source within the system. The triage process includes separating documents by patients and attachments, and deleting any unwanted pages, whether received via a message, fax or uploaded batch.`,
    TriageCreateMessage: `Messages are received and managed in a user's Intake (inbox) based upon rules. When leveraging Triage, you are creating the message for each new patient in the batch. Assign a message subject and select a rule to either route the message to your Intake or to others in your organization.`,
    TriageDelete: `Once the batch has been processed and is saved, any pages tagged for deletion will be removed and no longer available.`,
    TriageEditMessage: `Use Edit Message to update message fields. Select Save to save all changes.`,
    TriageUnprocessed: `Unprocessed files are the individual pages of an uploaded batch or the attachments associated with a received fax or message. Create a new message for each patient in the unprocessed files. Then, simply create and rename your attachments, and move the pages you want to keep. Delete the pages you don't!`,
    TriageUploadBatch: `Triage is the process used for uploading and quickly sorting batches of documents received through "uncontrolled" methods, such as fax, scan or file imports. Instead of scanning and attaching files individually to each patient chart, use the systems Upload Batch feature to process batches of patient files through a centralized location. Simply drag and drop the desired files and select "Upload & Triage" for immediate processing or "Upload" for later processing. A triaged batch results in one or many messages being submitted to Intake for further processing.`,
    LicenserExplanation: `Licensing Resellers can only be changed for Organizations that are on a Free Plan and aren't tied to an Affiliate Reseller.`,
    InvitationGrid: `<p>Includes all of your previously sent invitations.</p>  <p>Please note, if your invitee did not register from the link contained within the email, the accepted status will not reflect that they have accepted your invitation.</p>`,
    AffiliateExplanation: `Affiliate Resellers can only be changed for Organizations that are on a Free Plan and aren't tied to a Licensing Reseller.`,
    DirectAddressModalNickname: `Please indicate the primary purpose or function for this Address. This will help outside providers know which address to select when they search for your organization in the National Provider Directory.`,
    MarkCdaAsProcessed: `Structured CDA documents received in intake will be automatically flagged as processed.`,
    WebHooks: `The system can respond to different events by calling a URL in your system.  For example, we can notify you when a new message comes in by posting a JSON message to your custom URL.`,
    WebhooksCallback: `The HTTPS URL we will call back with your event data. The system will only post back Ids or references to resources you own. Your system will need to retrieve these resources using your service account.`,
    WebhooksEvent: `Select an event to subscribe to.  You can view an example JSON object the system will post to your system from the menu item 'Preview Payload.'`,
    ReleaseTypeModalOrigin: `If you specify an origin address or fax number, the system will automatically populate the "From" address when this Release Type is selected.`,
    UserModalPageGlobalSendPermissions: `Global Send Permissions allow all active users to send on behalf of the address(es) and/or fax(es) shown here. Global permissions for faxes and direct message accounts can be configured from settings > document sources.`,
    UserModalPageSendPermissions: `Click to grant the user permission to send on behalf of non-global address(es) and/or fax number(s) added here.`,
    UserModalPageGlobalIntakeRules: `Global Intake Rules allow all users to view messages in their intake box that meet the rule criteria.`,
    UserModalPageIntakeRules: `Click to grant the user access to messages that meet the criteria of non-global intake rule(s) added here.`,
    FaxModalNickname: `Please indicate the primary purpose or function for this Fax address. `,
    FaxModalPageAddressAdd: `A Fax number can be assigned that represents a provider.`,
    FaxModalPageUserIdAdd: `This eFax provided user id must be enabled for API access`,
    FaxModalPagePermissions: `Select the user(s) who can send messages on behalf of the Fax.<br /><br />This does not affect incoming Intake messages.`,
    AccountCustomerInvoice: `Please enter the account number from your current monthly invoice from your provider of your telephone or fax service and verify the customer information matches the invoice detail.  Updates to this section WILL NOT update your organization settings.`,
    BillingTelephoneNumber: `Billing number is the primary phone or fax number on your invoice from your current telephone provider.  The number(s) should be on the monthly invoice you receive.`,
    CurrentTelephoneServiceProvider: `Please enter the name and primary billing number of the telephone company through which you currently manage and pay for your fax line(s).`,
    SettingsFaxAddresses: `To allow you to centralize all your document workflows, you can also enable faxing.<br/><br/>To enable faxing, the system provides three different options:<ul style='position:relative; margin-bottom:20px;'><li>        Port your existing fax number</li><li>        Add a new fax number</li><li>        Forward an existing fax number to a new fax number</li></ul>Fax numbers that are ported can take up to 4 weeks to change from your current telephone carrier.  All fax numbers in the system should be primarily used for PHI exchange.  Although the system can send a non-PHI fax (such as an invoice), it is not intended for non-PHI activity.<br/><br/>The user account(s) assigned will have the ability to send a message (fax) using the fax numbers assigned to them.<br/><br/>To route information by these fax numbers, build a Rule with the number in the To field.<br/><br/>To create a new fax number, simply select the plus sign on the right. <br/><br/>Once a fax number has been added and activated, it cannot be edited.`,
    TelephoneServiceProvider: `Provider name is the name of the company through which you currently manage and pay for your fax lines(s).  The name of this company should be on the monthly invoice you receive.`,
    ApiSettings: `Systems that integrate with the API through a service account require client credentials managed here. <br /><br /> Only configure client credentials if the system integrating with the system requires a service account and is able to secure the credentials, and provide sufficient access and audit control for actions taken with the service account.`,
    OrganizationTwoFactorAuth: `Turn this setting on to enforce Two-Factor Authentication on all users associated with your organization.<br /><br />Two-Factor Authentication adds another level of security to your account. This uses two different authentication factors, something you know and your phone. If your password was stolen, your account would not be able to be accessed unless your phone had also been stolen. <br /><br /><strong>To enable:</strong><ol><li>Enter your password</li><li>Next a code will be sent to your phone via phone call or text</li><li>Enter the code into the website</li></ol>Upon entering the correct code, you will be granted access to your account.`,
    OrganizationSuppressDefaultFaxCoverSheet: `Turn this setting on to suppress the default fax cover sheet.<br /><br />Cover sheets provide information to fax recipients about who sent the fax.<br /><br />This will be the default setting for your organization. Users will have the option to override this setting when sending a fax.`,
    OrganizationSuppressFaxMetadataPages: `Turn this setting on to suppress the fax metadata pages.<br /><br />Fax Metadata pages include pages generated from the message body and attachment descriptions.<br /><br />This will be the default setting for your organization. Users will have the option to override this setting when sending a fax.`,
    AutoAcceptAllEReferrals: `Acceptance will be automatically sent when an e-Referral is received.`,
    MarkEReferralProcessed: `e-Referrals received in intake will be automatically flagged as processed.`,
    IntakeFeatureInclude: `Below are all special message types not displayed by default.`,
    DownloadClientQueryBuilder: `Build a download profile from the current intake settings by clicking the button below.`,
    mergeAllEReferralDocuments: `Merge all documents from an e-referral into one attachment.`,
    ReleaseNewMessage: `A Release (or Release of Information) is the process used to release patient information from your organization to a recipient, such as for a referral. <br /><br />Please note:  Only one patient can be assigned to a release.`,
    ReleaseTypeModalClassification: `Message Context is sent to the recipient as a machine readable code, allowing recipients to route or process messages differently depending on the context provided.`,
    PatientLookup: `Enter patient demographic data to search integrated EHR/Systems to utilize patient data for message processing.`,
    DocumentQueryTitle: `A Document Query is the process used to query patient records electronically from organizations participating in the query based network, for purposes such as care coordination, workman's compensation, legal reasons, etc.<br /><br />To initiate a new query, click the Query Documents button.<br /><br />Please note:  Only one patient can be assigned per query.`,
    RegionModalPage: ``,
    OrderLookup: ``,
    IntegrationType: ``,
    Integrations: ``,
    StructuredDocumentType: `Structured document type, no additional attachment details can be added.`,
    IpAddressesWhitelist: `Login is restricted to only whitelisted IP Addresses when "Require Whitelisted IP" Address is enabled`,
    OrganizationIpAddressesWhitelist: `Turn this setting on to require users to login from a whitelisted IP address. <br /><br /> You can add and configure IP addresses by navigating to Settings > IP Addresses.`,
    IgnoredAttachmentExtensions: `Enable to prevent receiving attachments with this extension`,
    AzureADAdminRole: `Your Active Directory group name to be assigned as Application Administrators`,
    AzureADUserRole: `Your Active Directory group name to be assigned as Application Users`,
    PointClickCareAdminSetupUser: `Your PointClickCare username you will be signing into PointClickCare with to complete setup. Please do not include your facility prefix in the username.<br /><br />Example: Do not enter <strong>exampleorg.user1</strong>, only enter <strong>user1</strong>`,
    PointClickCareAdminRole: `Your PointClickCare Position name to be assigned as Application Administrators`,
    PointClickCareUserRole: `Your PointClickCare Position name to be assigned as Application Users`,
    DocuSignAutoProcessDocuments: `Check to automatically process completed DocuSign documents to your Connected Integration`,
    JSignAutoProcessDocuments: `Check to automatically process completed jSign documents to your Connected Integration`,
    DocuSignConnectedIntegration: `Select your integration to send automatically processed documents to`,
    JSignConnectedIntegration: `Select your integration to send automatically processed documents to`,
    DocusignEnvelopeCustomFields: `Use custom fields inside your DocuSign envelope to map to Patient Demographics`,
    UseDocumentTypeCustomFields: `Identifies Document Type by "Tab Status" and ignores Filename Conventions`,
    DocuSignIgnoreSupplementalDocuments: `Ignores "Supplemental" Documents and does not add them to the Kno2 Message`,
    DocuSignMinimumMatchingCriteria: `Minimum criteria for patient matching`,
    QueryMatchConfidencePercent: `The Confidence value is returned by an external system, and should not be used to determine whether to download documents for this patient`,
    DocuSignAllowKno2DocusignUse: `This is opt-in affirmation that authorization of this account will allow Kno2 and connected applications to create envelopes or access data using this identity.`,
    DocuSignUserAccount: `This is the DocuSign account that will be used when calling the DocuSign API both for origination and to download content (vs reliance on Connect for all data)`,
    DocuSignAccountDetailToggle: `Click this label to toggle your DocuSign account details`,
    DocuSignNotifyAdministratorsOnException: `When checked, organization administrators will receive an email upon failure`,
    JSignNotifyAdministratorsOnException: `When checked, organization administrators will receive an email upon failure`,
    DocuSignEnableForDirect: `Check this box to enable the ability to receive a direct message to a specified address to initiate a DocuSign Envelope`,
    JSignEnableForDirect: `Check this box to enable the ability to receive a direct message to a specified address to initiate a jSign Envelope`,
    DocuSignIncludePatientDemographicsAsCustomFields: `Check this box to send patient demographics with the following labels as envelope custom fields`,
    DocuSignIncludePatientDemographicsAsTabs: `Check this box to send patient demographics with the following labels as document custom fields on your envelope`,
    DocuSignNotifyAutoRetryFailedConnectNotifications: `Check this box to automatically retry Failed Connect Notifications. This requires the user to be an Account Admin. If this checkbox is disabled and you wish to use this feature, you will need to update your user's permissions to be an Account Admin, clear your account, and login again.`,
    FaxModalPageCID: `Select the value to send as Caller ID on your outbound fax messages. The default may use a common number as the outbound Caller ID.`,
    DateRangeForDischargedPatients: `This value specifies the range of discharge dates to be included when searching for inactive (discharged) patients.  Select a value long enough to include the time that you might process documents for a discharged patients.<br /><br />Note: Setting a value larger than 6 months could result in slower patient search times.`,
    EnableGetSignaturesInUI: `Check this box to enable Get Signatures in the UI. This will be an additional menu item in the sidebar.`,
    SignatureRequests: `View sent signature requests and manage those saved as drafts.`,
    SignatureRequestsRecentActivity: `Sent includes all of the most recent sent activity and the delivery status of each signature request.`,
    SignatureRequestsDrafts: `Drafts includes all of your organization's signature request drafts and allows you to edit and complete them.`,
    SignatureAddNewTemplate: `Add a new template to manage signers for a signature request.`,
    DocuSignAllowSMSNotifications: `Check this box to enable the ability to send SMS Notifications to DocuSign Envelope recipients. Requires a DocuSign account with SMS Delivery available.  Please contact your DocuSign representative before enabling.`,
    EnableForcuraProcessingIntegrationAgreement: `By enabling the integration, you are granting Forcura access to the following on behalf of your organization: <br /><br /> <ul><li>Manage Mail Messages</li><li>Receive Webhooks</li></ul>`,
    OrganizationAllowLegacyLoginCompatibilityMode: `By enabling this setting, you will be granting your Organization's Users the ability to bypass Two-Factor Authentication checks when logging in through Legacy channels.`,
    UserAllowUserLegacyLoginCompatibility: `By enabling this setting, you are choosing to bypass Two-Factor Authentication checks when logging in through Legacy channels.`,
    CarePlanSettings: ``,
    ProcessingPreferencesOrder: ``,
    DocuSignAllowKno2DocusignUseCarePlan: `This is opt-in affirmation that authorization of this account will allow Kno2 and connected applications to create envelopes or access data using this identity.<br /><br />For sending automated Care Plans, this opt-in is not required.`,
    AllowSsoBypass: `Allow non-admins to bypass SSO and login with a standard username and password`,
    OrganizationDeliverySummaryEnableDownload: `Allow a user to request a Delivery Summary from Release, to be downloaded into a folder location of their choice.`,
    OrganizationDeliverySummaryIncludePatientName: `Displays the patient name on the generated delivery summary.`
};
