<form
    [formGroup]="form"
    (ngSubmit)="submitForm()"
    id="patientAddForm">
    <div class="modal-header">
        <button
            type="button"
            class="close"
            aria-hidden="true"
            (click)="cancel()">
            &times;
        </button>
        <h2 class="modal-title">Add Patient</h2>
    </div>
    <div class="modal-body">
        <div class="alert alert-warning">
            You are about to add a patient to
            <span [class.font-weight-bold]="integrationName">{{ integrationName ?? "the EHR" }}.</span>
        </div>
        <kno2-integration-patient-dynamic-form
            [formGroup]="form"
            [integrationName]="integrationName"
            [capabilityProperties]="capabilityProperties"
            [patient]="patient" />
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default"
            (click)="cancel()">
            Cancel
        </button>
        <button
            type="submit"
            class="btn btn-primary">
            <span class="ladda-label">Add Patient</span>
        </button>
    </div>
</form>
