(function () {
    "use strict";

    angular.module("kno2.settings").filter("twoFactorAuthStatus", twoFactorAuthStatusFilter);

    function twoFactorAuthStatusFilter() {
        return function (user) {
            let result = "No";
            if (user.security.twoFactorEnabled ^ user.phoneNumberConfirmed) result = "Legacy Pending";
            if (user.security.twoFactorEnabled && user.phoneNumberConfirmed) result = "Legacy";
            if (user.security.multiFactorStatus !== 0) result = "Yes";
            return result;
        };
    }
})();
