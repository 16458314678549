(function () {
    "use strict";

    angular.module("kno2.services").service("IntegrationsService", IntegrationsService);

    IntegrationsService.$inject = ["$http", "_", "SaveService"];

    function IntegrationsService($http, _, SaveService) {
        var self = this;

        this.list = function () {
            return $http.get("/api/integrations").then((x) => x.data);
        };

        this.listTypes = function () {
            return $http.get("/api/integrations/types").then((x) => x.data);
        };

        this.listConnectedTypes = function () {
            return $http.get("/api/integrations/connected/types").then((x) => x.data);
        };

        this.listAvailableTypes = function () {
            return $http.get("/api/integrations/types/available").then((x) => x.data);
        };

        this.getById = function (id) {
            return $http.get("/api/integrations/" + id).then((x) => x.data);
        };

        this.delete = function (id) {
            return $http.delete("/api/integrations/" + id).then((x) => x.data);
        };

        this.upsert = function (data) {
            return $http.put("/api/integrations", data).then((x) => x.data);
        };

        this.testConnectionById = function (id) {
            return $http.get("/api/integrations/" + id + "/connectivity").then((x) => x.data);
        };

        this.testConnection = function (integration) {
            return $http.post("/api/integrations/connectivity", integration).then((x) => x.data);
        };

        this.downloadCertificate = function (integration) {
            return $http
                .post(`/api/integrations/${integration.id}/downloadcertificate`, integration)
                .then((response) => SaveService.saveFile(response.data, response.status, response.headers, "certificate.cer"));
        };

        this.getCapabilities = function () {
            return $http.get("/api/integrations/capabilities").then((x) => x.data);
        };

        this.capabilities = {
            PatientLookup: "patientLookup",
            PatientDetailsLookup: "patientDetailsLookup",
            VisitLookup: "visitLookup",
            ReviewerLookup: "reviewerLookup",
            DocumentTypeLookup: "documentTypeLookup",
            DocumentUpload: "documentUpload",
            ReviewerGroupLookup: "reviewerGroupLookup",
            HaveCodeGrantLogin: "haveCodeGrantLogin"
        };

        this.getPatients = function (criteria) {
            return $http.post("/api/integrations/patients", criteria, { throbber: false }).then((x) => x.data);
        };

        this.getPatient = function (criteria, patientResourceId) {
            return self.getPatients(criteria).then((patients) => _.find(patients, { patientResourceId: patientResourceId }));
        };

        this.getPatientById = function (id) {
            return $http.get(`/api/integrations/patients/${id}`, { throbber: false }).then(
                (x) => x.data,
                () => []
            );
        };

        this.getVisits = function (patientId) {
            return $http.post("/api/integrations/visits/", { patientId: patientId }, { throbber: false }).then(
                (x) => x.data,
                () => []
            );
        };

        this.getVisit = function (patientId, visitId) {
            return self.getVisits(patientId).then((visits) => _.find(visits, { id: visitId }));
        };

        this.getOrders = function (patientId, visitId) {
            return $http.post("/api/integrations/orders", { patientId: patientId, visitId: visitId }).then(
                (x) => x.data,
                () => []
            );
        };

        this.getReviewers = function (criteria) {
            return $http.post("/api/integrations/reviewers", criteria, { throbber: false }).then(
                (x) => x.data,
                () => []
            );
        };

        this.getReviewerGroups = function () {
            return $http.get("/api/integrations/reviewergroups").then(
                (x) => x.data,
                () => []
            );
        };

        this.getUser = function (userId) {
            return self.getReviewers().then((users) => _.find(users, { id: userId }));
        };

        this.getDocumentTypes = function () {
            return $http.get("/api/integrations/documenttypes", { throbber: false }).then((x) => x.data);
        };

        this.getFacilities = function (filter) {
            return $http.get(`/api/integrations/facilities?filter=${!!filter}`, { throbber: false }).then((x) => x.data);
        };

        this.externalLogin = function (integrationType) {
            let url = "/api/integrations/externallogin";
            if (!!integrationType) {
                url += `?integrationType=${integrationType}`;
            }
            return $http.get(url, { throbber: false }).then((x) => x.data);
        };

        this.checkState = function (state, integrationType) {
            return $http.post(`/api/integrations/checkstate`, { state: state, integrationType: integrationType }).then((x) => x.data);
        };

        this.revokeToken = function (type) {
            return $http.post("/api/integrations/revoketoken", { type: type }).then((x) => x.data);
        };

        this.revokeTokenOauth = function (type) {
            return $http.post("/api/integrations/revoketoken/oauth").then((x) => x.data);
        };

        this.clearCachedData = function () {
            return $http.post("/api/integrations/clearcache").then((x) => x.data);
        };

        this.getPatientKeys = function (type) {
            return $http.get(`/api/integrations/patientkeys?type=${type}`).then((x) => x.data.data);
        };

        this.getTemplates = function () {
            return $http.get(`/api/integrations/templates`).then((x) => x.data.data);
        };
    }
})();
