(function () {
    "use strict";

    angular.module("kno2.directives").directive("affix", affix);

    affix.$inject = ["$timeout", "$window"];

    /**
     * A directive that wraps the bootstrap affix functionality:
     * https://getbootstrap.com/docs/3.3/javascript/#affix
     */
    function affix($timeout, $window) {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var target = attrs.affix;
                var top = attrs.affixOffsetTop;
                var bottom = attrs.affixOffsetBottom;
                var options = {};

                if (target) options.target = target;
                if (top || bottom) options.offset = {};
                if (top) options.offset.top = top;
                if (bottom) options.offset.bottom = bottom;

                $timeout(init);

                function init() {
                    // add placeholder wrapper
                    if (attrs.affixWrapper === "true") {
                        var wrapper = angular.element('<div class="affix-wrapper"></div>');
                        wrapper.height(element.outerHeight());
                        element.wrap(wrapper);
                    }

                    element.affix(options);

                    // readjust affix each time scope updates
                    scope.$watch(function resetAffix() {
                        $timeout(
                            function () {
                                element.affix("checkPosition");
                            },
                            0,
                            false
                        );
                    });

                    // readjust affix when window is resized
                    angular.element($window).on("resize", function () {
                        element.affix("checkPosition");
                    });
                }
            }
        };
    }
})();
