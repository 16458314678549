(function () {
    "use strict";

    angular.module("kno2.directives").directive("k2ToggleRole", toggleRole);

    toggleRole.$inject = ["$animate", "$compile", "SessionService"];

    /**
     * Shows/hides an element depending on whether a feature flag is enabled. Partially copied from
     * https://github.com/angular/angular.js/blob/3651e42e49ded7d410fd1cbd46f717056000afd4/src/ng/directive/ngIf.js#L81
     */
    function toggleRole($animate, $compile, SessionService) {
        return {
            multiElement: true,
            transclude: "element",
            priority: 599,
            terminal: true,
            restrict: "A",
            $$tlb: true,
            link: function ($scope, $element, $attr, ctrl, $transclude) {
                var hasHideAttribute = "k2ToggleRoleHide" in $attr;
                var block, childScope, previousElements;

                $scope.$watch(k2ToggleRoleWatcher, k2ToggleRoleWatchAction);

                function k2ToggleRoleWatcher() {
                    return SessionService.userInRole($attr.k2ToggleRole);
                }

                function k2ToggleRoleWatchAction(isEnabled) {
                    var showElement = hasHideAttribute ? !isEnabled : isEnabled;

                    if (showElement) {
                        if (!childScope) {
                            $transclude(function (clone, newScope) {
                                childScope = newScope;
                                clone[clone.length++] = $compile.$$createComment("end k2ToggleRole", $attr.k2ToggleRole);
                                // Note: We only need the first/last node of the cloned nodes.
                                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                                // by a directive with templateUrl when its template arrives.
                                block = {
                                    clone: clone
                                };
                                $animate.enter(clone, $element.parent(), $element);
                            });
                        }
                    } else {
                        if (previousElements) {
                            previousElements.remove();
                            previousElements = null;
                        }
                        if (childScope) {
                            childScope.$destroy();
                            childScope = null;
                        }
                        if (block) {
                            previousElements = getBlockNodes(block.clone);
                            $animate.leave(previousElements).done(function (response) {
                                if (response !== false) previousElements = null;
                            });
                            block = null;
                        }
                    }
                }
            }
        };
    }

    /**
     * Return the DOM siblings between the first and last node in the given array.
     * @param {Array} array like object
     * @returns {Array} the inputted object or a jqLite collection containing the nodes
     * https://github.com/angular/angular.js/blob/3651e42e49ded7d410fd1cbd46f717056000afd4/src/Angular.js#L2008
     */
    function getBlockNodes(nodes) {
        // TODO(perf): update `nodes` instead of creating a new object?
        var node = nodes[0];
        var endNode = nodes[nodes.length - 1];
        var blockNodes;

        for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
            if (blockNodes || nodes[i] !== node) {
                if (!blockNodes) {
                    blockNodes = jqLite(slice.call(nodes, 0, i));
                }
                blockNodes.push(node);
            }
        }

        return blockNodes || nodes;
    }
})();
