import templateUrl from "./integration-codegrants-edit.modal.component.html";

export class IntegrationCodeGrantsEditModalController {
    constructor(AdminIntegrationCodeGrantsService, NotificationModalService, IntegrationsService) {
        this.AdminIntegrationCodeGrantsService = AdminIntegrationCodeGrantsService;
        this.NotificationModalService = NotificationModalService;
        this.IntegrationsService = IntegrationsService;
    }

    $onInit() {
        this.codeGrant = this.resolve.codeGrant;
        this.integrations = [];
        this.getIntegrations();
    }

    getIntegrations() {
        const self = this;
        this.IntegrationsService.listTypes().then(
            function (data) {
                self.integrations = data;
            },
            function (error) {
                NotificationService.error(error.data.message);
            }
        );
    }

    save() {
        const promise = this.codeGrant.id
            ? this.AdminIntegrationCodeGrantsService.updateCodeGrant(this.codeGrant.id, this.codeGrant)
            : this.AdminIntegrationCodeGrantsService.addCodeGrant(this.codeGrant);

        return promise
            .then(() => {
                this.NotificationModalService.success("Code grant saved");
                this.close();
            })
            .catch((response) => {
                if (response.data && response.data.modelState)
                    this.NotificationModalService.serverError(response.data.modelState, "An error occurred while saving this code grant.");
                else if (response.status === 409) this.NotificationModalService.error("A code grant with this value already exists.");
                else this.NotificationModalService.error("An unknown error occurred while saving this code grant.");
            });
    }
}

IntegrationCodeGrantsEditModalController.$inject = ["AdminIntegrationCodeGrantsService", "NotificationModalService", "IntegrationsService"];

export const integrationCodeGrantsEditModalComponent = {
    templateUrl,
    controller: IntegrationCodeGrantsEditModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
