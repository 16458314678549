import templateUrl from "./query.wizard.component.html";

angular.module("kno2.records").component("k2QueryWizard", {
    controller: QueryWizardController,
    templateUrl,
    bindings: {
        history: "<"
    }
});

QueryWizardController.$inject = ["$transitions", "$stateParams", "$timeout", "QueryService", "NotificationService"];

function QueryWizardController($transitions, $stateParams, $timeout, QueryService, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;

    function $onInit() {
        ctrl.query = {
            requester: {},
            organization: {},
            patient: {}
        };
        ctrl.organizations = null;
        ctrl.selectedOrganizations = [];
        ctrl.patients = null;

        if ($stateParams.test) setupTestValues();

        if (ctrl.history) setupHistoryValues(ctrl.history);

        const criteria = { to: "records.query.wizard.**" };
        ctrl.cancelHook = $transitions.onBefore(criteria, () => {
            NotificationService.hideErrors();
            ctrl.form.$setPristine();

            QueryService.saveSearch({
                id: ctrl.query.id,
                purposeOfUseCode: ctrl.query.requester.purpose.code,
                subjectRoleCode: ctrl.query.requester.role.code,
                organizationQuery: ctrl.query.organization,
                selectedOrganizations: ctrl.selectedOrganizations,
                patientQuery: ctrl.query.patient
            }).then((x) => {
                if (x.id) ctrl.query.id = x.id;
            });
        });

        if ($stateParams.submit) $timeout(() => ctrl.submit());
    }

    function $onDestroy() {
        ctrl.cancelHook();
    }

    function setupHistoryValues(history) {
        ctrl.query.id = history.id;
        ctrl.query.requester.role = {
            code: history.subjectRoleCode
        };

        ctrl.query.requester.purpose = {
            code: history.purposeOfUseCode
        };

        ctrl.query.organization = {
            name: history.organizationQuery.name,
            address: history.organizationQuery.address,
            city: history.organizationQuery.city,
            state: history.organizationQuery.state,
            zipcode: history.organizationQuery.zipcode,
            isHealthReferralRegionSearch: history.organizationQuery.isHealthReferralRegionSearch
        };

        ctrl.selectedOrganizations = history.selectedOrganizations;

        ctrl.query.patient = {
            firstName: history.patientQuery.firstName,
            middleName: history.patientQuery.middleName,
            lastName: history.patientQuery.lastName,
            gender: history.patientQuery.gender,
            city: history.patientQuery.city,
            street: history.patientQuery.street,
            street2: history.patientQuery.street2,
            state: history.patientQuery.state,
            zipCode: history.patientQuery.zipcode,
            birthDate: history.patientQuery.birthdate,
            ssn: history.patientQuery.ssn,
            phoneNumber: history.patientQuery.phoneNumber
        };
    }

    function setupTestValues() {
        ctrl.query.requester.fullName = "Tony Stark";

        ctrl.query.requester.role = {
            code: 309418004,
            displayName: "Audiologist"
        };

        ctrl.query.requester.purpose = {
            code: "TREATMENT",
            description: "Treatment"
        };

        ctrl.query.organization = {
            name: "Kno2 Test"
        };

        ctrl.selectedOrganizations = [
            {
                displayName: "Kno2 Test",
                orgId: "urn:oid:2.16.840.1.113883.3.3126.2.5.0"
            }
        ];

        ctrl.query.patient = {
            firstName: "CHDRTWO",
            lastName: "CHDRZZZTESTPATIENT",
            gender: "F",
            city: "La Jolla",
            street: "1234 Howard St.",
            state: "CA",
            zipCode: "92038",
            birthDate: "1961-03-03"
        };

        ctrl.patients = [
            {
                patientIdentifier: [
                    {
                        patientHomeCommunityId: "urn:oid:2.16.840.1.113883.3.3126.2.5.10",
                        patientId: "9584785"
                    }
                ],
                otherIdentifiers: null,
                addressInformation: [
                    {
                        street: "1234 Howard St.",
                        unit: null,
                        city: "La Jolla",
                        state: "CA",
                        zip: null,
                        country: "US"
                    }
                ],
                patientName: [
                    {
                        given: ["CHDRTWO"],
                        family: "CHDRZZZTESTPATIENT"
                    }
                ],
                statusCode: "active",
                gender: "F",
                isDeceased: false,
                birthDate: "1961-03-03T00:00:00",
                matchConfidencePercent: 100,
                documents: [
                    {
                        author: "Kno2-StubbedTestData",
                        authorInstitution: "kno2.com",
                        dateCreated: "2017-08-22T14:18:06",
                        documentName: "Telephone Summary",
                        documentUniqueId: "2.16.840.1.113883.3.3126.20.3.7.111111122",
                        homeCommunityId: "urn:oid:2.16.840.1.113883.3.3126.2.5.10",
                        repositoryUniqueId: "urn:oid:2.16.840.1.113883.3.3126.2.5.10.47"
                    },
                    {
                        author: "Jed Sanders",
                        authorInstitution: "kno2.com",
                        dateCreated: "2017-08-22T14:18:06",
                        documentName: "Care Plan",
                        documentUniqueId: "2.16.840.1.113883.3.3126.20.3.7.111111122",
                        homeCommunityId: "urn:oid:2.16.840.1.113883.3.3126.2.5.10",
                        repositoryUniqueId: "urn:oid:2.16.840.1.113883.3.3126.2.5.10.47"
                    }
                ]
            }
        ];
    }
}
