<div class="sidebar-nav nav-collapse collapse navbar-collapse">
    <ul class="nav main-menu flex-column">
        @for (item of menuItems; track $index) {
            @if (item.visible && !item.items) {
                <li [class.active]="activeRoute().startsWith(item.route)">
                    <a
                        [class.disabled]="item.disabled"
                        [routerLink]="item.route || activeRoute()"
                        (click)="item.command && item.command()"
                        [title]="item.label">
                        <i [class]="item.icon"></i>
                        <span class="hidden-sm text">{{ item.label }}</span>
                    </a>
                </li>
            } @else if (item.visible) {
                <li>
                    <a
                        kno2NoOpLink
                        class="dropmenu"
                        (click)="item.expanded = !item.expanded">
                        <i [class]="item.icon"></i>
                        <span class="hidden-sm text">{{ item.label }}</span>
                        <span
                            class="chevron"
                            [class]="item.expanded ? 'opened' : 'closed'"></span>
                    </a>
                    <ul
                        [id]="item.id"
                        class="sub"
                        [style.display]="item.expanded ? 'block' : 'none'">
                        @for (subItem of item.items; track $index) {
                            <li [class.active]="activeRoute().startsWith(subItem.route)">
                                <a
                                    class="submenu"
                                    [routerLink]="subItem.route">
                                    <i [class]="subItem.icon"></i>
                                    <span
                                        class="hidden-sm text"
                                        [class.hidden-sm]="!sidebarCollapsed">
                                        {{ subItem.label }}
                                    </span>
                                </a>
                            </li>
                        }
                    </ul>
                </li>
            }
        }
    </ul>
    <button
        class="btn btn-link pl-0 mt-4 shadow-none hidden-sm"
        (click)="toggleCollapse()">
        <i
            [class]="sidebarCollapsed() ? 'fa fa-angle-double-right' : 'fa fa-angle-double-left'"
            class="fa-lg"></i>
    </button>
</div>
