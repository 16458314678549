import { AfterViewChecked, Component, ElementRef, Renderer2, computed, inject, input, signal, viewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { StopPropagationAndPreventDefault } from "@kno2/common/decorators";
import { AuthService } from "@kno2/core/auth";
import { SessionService } from "@kno2/core/session";
import { AccountService } from "@kno2/data-access/account";

@Component({
    selector: "kno2-org-switcher",
    standalone: true,
    imports: [FormsModule],
    templateUrl: "./org-switcher.component.html"
})
export class OrgSwitcherComponent implements AfterViewChecked {
    private readonly sessionService = inject(SessionService);
    private readonly authService = inject(AuthService);
    private readonly accountService = inject(AccountService);
    private readonly router = inject(Router);
    private readonly renderer = inject(Renderer2);

    private orgToggleDropdown = viewChild<ElementRef>("orgToggleDropdown");
    private orgToggleSearch = viewChild<ElementRef>("orgToggleSearch");

    protected organizationName = input.required<string>();
    protected organizations = input.required<{ id: string; role: string; isActivated: boolean; name: string }[]>();
    protected filteredOrganizations = computed(() => this.organizations().filter((org) => org.name.toLowerCase().includes(this.search().toLowerCase())));

    public search = signal<string>("");

    public ngAfterViewChecked(): void {
        if (this.orgToggleSearch && this.orgToggleSearch().nativeElement) {
            const elementStyle = window.getComputedStyle(this.orgToggleSearch().nativeElement);
            if (elementStyle && elementStyle.display !== "none" && elementStyle.visibility !== "hidden") {
                this.orgToggleSearch().nativeElement.focus();
            }
        }
    }

    @StopPropagationAndPreventDefault()
    public async changeOrganization(event: Event, orgId: string): Promise<void> {
        const token = await this.accountService.changeOrganization(orgId);
        this.renderer.removeClass(this.orgToggleDropdown().nativeElement, "show");
        if (!token) {
            this.sessionService.removeProfile();
            await this.authService.login({ prompt: "none" });
        } else {
            await this.sessionService.refreshProfile();
            const newProfile = await this.sessionService.getProfileAsync();
            this.sessionService.setProfile(newProfile);

            const redirectPath = this.sessionService.getDefaultRouteForActiveRole();
            this.router.navigate([redirectPath]);
        }
    }
}
