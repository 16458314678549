import templateUrl from "./admin-organization-specialties.component.html";

export const adminorganizationSpecialtiesComponent = {
    templateUrl: templateUrl,
    controller: organizationSpecialtiesController
};

organizationSpecialtiesController.$inject = ["OrganizationSpecialtiesService", "NotificationService", "$timeout", "_", "PagingFactory", "$uibModal"];

function organizationSpecialtiesController(OrganizationSpecialtiesService, NotificationService, $timeout, _, PagingFactory, $uibModal) {
    const ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.search = search;
    ctrl.pageSize = 10;
    ctrl.searchTerm = null;
    ctrl.currentPage = 1;
    ctrl.gotoPage = gotoPage;
    ctrl.showPage = showPage;
    ctrl.edit = edit;
    ctrl.add = edit;
    ctrl.delete = deleteOrgSpecialty;
    ctrl.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
    ctrl.pagedItemRange = ctrl.pagedItemRanges[0];

    function onInit() {
        getData();
    }

    function gotoPage(pageNum) {
        if (pageNum === 0 || pageNum > ctrl.maxPage) return;
        ctrl.currentPage = pageNum;
        getData();
    }

    function showPage(page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalCount).visible[page - 1];
    }

    function edit(id) {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationSpecialtiesEditModal",
            resolve: {
                organizationSpecialtyId: function () {
                    return id;
                }
            }
        });

        modalInstance.result.then(
            function () {
                // After a successful save, update the ctrl.organization
                //ctrl.organization = organization;
                getData();
            },
            function () {
                // do something with dissResult
            }
        );
    }

    function deleteOrgSpecialty(id) {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationSpecialtiesDeleteModal",
            resolve: {
                organizationSpecialtyId: function () {
                    return id;
                }
            }
        });
        modalInstance.result.then(
            function () {
                getData();
            },
            function () {}
        );
    }

    function getData() {
        if (!ctrl.searchTerm) ctrl.searchTerm = null;
        OrganizationSpecialtiesService.get(ctrl.currentPage, ctrl.pagedItemRange.value, ctrl.searchTerm).then(
            function (response) {
                ctrl.organizationSpecialties = response.items;
                ctrl.maxPage = Math.ceil(response.totalCount / ctrl.pagedItemRange.value);
                ctrl.pages = _.range(1, ctrl.maxPage + 1);
                ctrl.totalCount = response.totalCount;
            },
            function (error) {
                NotificationService.error("Error retrieving organization specialties.");
            }
        );
    }

    let searchTimeout;
    function search() {
        if (searchTimeout) $timeout.cancel(searchTimeout);

        searchTimeout = $timeout(function () {
            getData();
        }, 300);
    }
}
