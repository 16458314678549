(function (undefined) {
    "use strict";

    angular.module("kno2.directives").directive("ignoreSpecialCharacters", ignoreSpecialCharacters);

    ignoreSpecialCharacters.$inject = ["_"];

    var pattern = /[A-Za-z0-9._\- ]/,
        validCodes = [
            8, // backspace
            13 // enter
        ];

    function ignoreSpecialCharacters(_) {
        // Usage:
        //     <input tyep="text" ignore-special-characters />
        return {
            restrict: "A",
            link: link
        };

        function link(scope, element) {
            element.on("keypress", function (event) {
                var code = event.which;
                if (!code || _.includes(validCodes, code)) return;
                var char = String.fromCharCode(code);
                if (!pattern.test(char)) event.preventDefault();
            });
        }
    }
})();
