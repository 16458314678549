(function () {
    "use strict";

    angular.module("kno2.release").filter("orgReleaseTypes", orgReleaseTypesFilter);

    orgReleaseTypesFilter.$inject = ["_"];

    function orgReleaseTypesFilter(_) {
        return _.debounce(function (releaseTypes) {
            if (!releaseTypes || !_.isArray(releaseTypes)) return releaseTypes;

            return _.filter(releaseTypes, function (r) {
                return !!r.organizationId;
            });
        });
    }
})();
