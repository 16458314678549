import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export const requiredIfAnyValidator = (...controlNames: string[]): ValidatorFn => {
    return (formGroup: FormGroup): ValidationErrors | null => {
        const existingControls: [FormControl, string][] = controlNames
            .map((name) => [formGroup.get(name), name] as [FormControl, string])
            .filter(([control]) => !!control);

        const hasAnyValues = existingControls.some(([control]) => control.value);
        const hasAllValues = existingControls.every(([control]) => control.value);
        const anyRequired = existingControls.some(([control]) => control.hasValidator(Validators.required));

        if ((hasAnyValues || anyRequired) && !hasAllValues) {
            const errors = existingControls.reduce((acc, [control, name]) => {
                if (!control.value) {
                    acc[name] = { requiredIfAny: true };
                }

                if (hasAnyValues) {
                    control.markAsTouched();
                }
                return acc;
            }, {});

            return errors;
        }

        return null;
    };
};
