import inviteHistoryTemplate from "./invite-history.html";

inviteRoutes.$inject = ["$stateProvider"];
export function inviteRoutes($stateProvider) {
    $stateProvider.state("inviteHistory", {
        url: "/invite/history",
        parent: "home",
        templateUrl: inviteHistoryTemplate,
        controller: "InviteCtrl"
    });
}
