WorkflowsService.$inject = ["$http"];

export function WorkflowsService($http) {
    return {
        retry: retry
    };

    function retry(bulkRetry) {
        return $http.post("/api/workflows/retry", bulkRetry).then(function (response) {
            return response;
        });
    }
}
