import { Component, OnDestroy, effect, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TooltipsService } from "@kno2/common/services";
import { WINDOW_TOKEN } from "@kno2/core/browser";
import { SessionTimeoutDirective } from "@kno2/shared/session-timout";
import { StartupService } from "./startup.service";

@Component({
    imports: [RouterOutlet, SessionTimeoutDirective],
    selector: "app-root",
    templateUrl: "./app.component.html",
    standalone: true
})
export class AppComponent implements OnDestroy {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly tooltipsService = inject(TooltipsService);
    private readonly startupService = inject(StartupService);

    public constructor() {
        effect(() => this.startupService.isReady() && this.startupService.start());
    }

    public async ngOnInit(): Promise<void> {
        try {
            this.tooltipsService.init();

            this.window.onbeforeunload = () => this.ngOnDestroy();
        } catch (err) {
            console.error(err);
        }
    }

    public ngOnDestroy(): void {
        this.window.onbeforeunload = null;
        this.startupService.stop();
    }
}
