QueryService.$inject = ["$location", "$http"];

export function QueryService($location, $http) {
    return {
        getHistory: getHistory,
        getSubjectRoleCodes: getSubjectRoleCodes,
        getPurposesOfUse: getPurposesOfUse,
        queryOrganizations: queryOrganizations,
        queryPatients: queryPatients,
        queryDocuments: queryDocuments,
        sendDocumentToIntake: sendDocumentToIntake,
        saveSearch: saveSearch,
        getHistoryEntry: getHistoryEntry,
        removeHistoryEntry: removeHistoryEntry,
        cacheDocument: cacheDocument
    };

    function saveSearch(searchData) {
        return $http.post("/api/queryhistory", searchData).then((x) => x.data);
    }

    function getHistoryEntry(id) {
        return $http.get(`/api/queryhistory/${id}`).then((x) => x.data);
    }

    function removeHistoryEntry(id) {
        return $http.delete(`/api/queryhistory/${id}`).then((x) => x.data);
    }

    function getHistory(pageNumber, pageSize) {
        return $http
            .get("/api/queryhistory", {
                params: {
                    pageNumber,
                    pageSize
                }
            })
            .then((x) => x.data);
    }

    function getSubjectRoleCodes() {
        return $http.get("/api/query/subjectcodes", { cache: true }).then((x) => x.data);
    }

    function getPurposesOfUse() {
        return $http.get("/api/query/purposesofuse").then((x) => x.data);
    }

    function queryOrganizations(query) {
        return $http.post("/api/query/organizations", query).then((x) => x.data);
    }

    function queryPatients(query) {
        return $http.post("/api/query/patient/demographics", query).then(function (response) {
            var data = response.data;

            var facilitiesByCategory = data.results;

            var normalizedErrorFacilities = _.map(facilitiesByCategory.connectionFailureFacilities, function (facility) {
                return _.merge({ results: [], isError: true }, facility);
            });

            const normalizedNoResultsFacilities = _.map(facilitiesByCategory.noResultsFacilities, function (facility) {
                return _.merge({ results: [], isError: false }, facility);
            });

            const normalizedResultsFacilities = _.map(facilitiesByCategory.results, function (facility) {
                return _.merge({ results: [], isError: false }, facility);
            });

            var allFacilities = _.flatten([normalizedResultsFacilities, normalizedNoResultsFacilities, normalizedErrorFacilities]);

            return allFacilities;
        });
    }

    function queryDocuments(query) {
        return $http.post("/api/query/document", query).then((x) => x.data);
    }

    function cacheDocument(query) {
        return $http.post("/api/query/document/cache", query).then((x) => x.data);
    }

    function sendDocumentToIntake(query) {
        return $http.post("/api/query/senddocumenttointake", query).then((x) => x.data);
    }
}
