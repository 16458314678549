/**
 * Filters out properties from the input object that have empty values.
 *
 * An empty value is defined as:
 * - `null`
 * - `undefined`
 * - An empty string (after trimming)
 * - `NaN`
 *
 * The function retains:
 * - Non-empty strings
 * - Numbers (including 0)
 * - Booleans (including false)
 * - Bigints
 * - Symbols
 *
 * @param obj - The input object with properties to be filtered.
 * @returns A new object with only the non-empty properties.
 *
 * @example
 * ```typescript
 * const params = {
 *     pageNumber: 1,
 *     pageSize: 10,
 *     search: '',
 *     sortDir: 'asc',
 *     sortBy: 'name'
 * };
 *
 * const filteredParams = filterNonEmptyProperties(params); // { pageNumber: 1, pageSize: 10, sortDir: 'asc', sortBy: 'name' }
 *
 * ```
 */
export const filterNonEmptyProperties = <T extends Record<string, any>>(obj: T): Partial<T> => {
    return Object.entries(obj)
        .filter(([, value]) => {
            if (typeof value === "string") {
                return value.trim() !== "";
            }
            return value || value === false || value === 0;
        })
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
