import templateUrl from "./clientapp-grid.component.html";

export const clientAppGridComponent = {
    templateUrl: templateUrl,
    controller: ClientAppGridController
};

ClientAppGridController.$inject = ["$confirm", "ClientApplicationService", "NotificationService"];

export function ClientAppGridController($confirm, ClientApplicationService, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.remove = remove;

    function $onInit() {
        ctrl.pageSizes = [10, 25, 50];
        ctrl.pageSize = ctrl.pageSizes[0];

        ClientApplicationService.listApplications().then((applications) => {
            ctrl.applications = applications;
        });
    }

    function remove(application) {
        return $confirm
            .open({
                bodyText: "Are you sure you want to remove " + application.name + "?"
            })
            .result.then(
                () => {
                    return ClientApplicationService.removeApplication(application.id)
                        .then(() => {
                            var index = ctrl.applications.indexOf(application);
                            ctrl.applications.splice(index, 1);
                            NotificationService.success("Application successfully removed");
                        })
                        .catch(function (e) {
                            NotificationService.error("An error occurred while removing the application. " + ((e && e.data && e.data.exceptionMessage) || ""));
                        });
                },
                () => {}
            );
    }
}
