import { Component, input } from "@angular/core";

@Component({
    selector: "kno2-loading-spinner",
    template: `
        <div
            class="d-flex justify-content-center align-items-center h-100"
            [class]="spinnerClass()">
            <div
                class="spinner-border text-primary"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    `,
    standalone: true
})
export class LoadingSpinnerComponent {
    public spinnerClass = input<string>();
}
