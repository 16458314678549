import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { UAParser } from "ua-parser-js";

@Injectable({
    providedIn: "root"
})
export class SupportService {
    private httpClient = inject(HttpClient);

    public submitTicket = (ticket): Promise<void> => firstValueFrom(this.submitTicket$(ticket));
    public sendPlanUpgradeRequest = (): Promise<void> => firstValueFrom(this.sendPlanUpgradeRequest$());

    private submitTicket$(ticket): Observable<void> {
        const { browser, engine, os } = new UAParser().getResult();
        return this.httpClient.post<void>("/api/support/ticket/", {
            ...ticket,
            userAgent: {
                browser,
                engine,
                os
            }
        });
    }

    private sendPlanUpgradeRequest$(): Observable<void> {
        return this.httpClient.post<void>("/api/support/plan/upgrade/request", null);
    }
}
