(function (undefined) {
    "use strict";

    angular.module("kno2.filters").filter("structureddocs", structuredDocFilter);

    structuredDocFilter.$inject = ["_", "MessageFactory"];

    function structuredDocFilter(_, MessageFactory) {
        return function (attachments, bool) {
            if (bool === undefined) bool = true;
            return _.filter(attachments, function (a) {
                return MessageFactory.isStructuredDocument(a) === bool;
            });
        };
    }
})();
