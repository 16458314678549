import templateUrl from "./webhooks.component.html";

export const webhooksComponent = {
    templateUrl: templateUrl,
    controller: WebhooksController
};

WebhooksController.$inject = ["$uibModal", "$timeout", "WebHooksService", "NotificationService", "SessionService"];

export function WebhooksController($uibModal, $timeout, WebHooksService, NotificationService, SessionService) {
    var ctrl = this;
    const profile = SessionService.getProfile();

    ctrl.$onInit = $onInit;
    ctrl.openEdit = openEdit;
    ctrl.openUpsert = openUpsert;
    ctrl.delete = deleteWebhook;
    ctrl.showPreviewPayload = showPreviewPayload;

    ctrl.externalServiceAccountHasManagementAccess = profile.externalServiceAccountSettings.hasManagingExternalServiceAccount;
    ctrl.externalServiceAccountHasWebhookNotifications = profile.externalServiceAccountSettings.hasWebhookAccess;
    ctrl.externalServiceAccountName = profile.externalServiceAccountSettings.externalServiceAccountName;

    function $onInit() {
        ctrl.selectedEvent = "";
        load();
    }

    function load() {
        WebHooksService.listevents().then(function (data) {
            ctrl.events = data;
            WebHooksService.list().then(function (data) {
                ctrl.hooks = _.map(data, function (h) {
                    h.set = false;
                    if (h.id > 0) h.set = true;

                    return h;
                });

                ctrl.hookedEvents = _.map(ctrl.hooks, function (h) {
                    return h.hookEvent;
                });

                ctrl.selectableEvents = _.difference(ctrl.events, ctrl.hookedEvents);
            });
        });

        WebHooksService.authenticationTypes().then(function (data) {
            ctrl.selectableAuthenticationTypes = data;
        });
    }

    function openEdit(webhook) {
        var selectableEventsEdit = ctrl.selectableEvents;
        if (webhook.id > 0) {
            selectableEventsEdit = [webhook.hookEvent];
        }

        var modal = $uibModal.open({
            component: "k2WebhooksUpsertModal",
            resolve: {
                model: function () {
                    return {
                        selectableEvents: selectableEventsEdit,
                        selectableAuthenticationTypes: ctrl.selectableAuthenticationTypes,
                        webhook: webhook,
                        hookEvent: webhook.hookEvent
                    };
                }
            }
        });

        modal.result.then(
            function () {
                load();
            },
            () => {}
        );
    }

    function openUpsert(webhook) {
        if (webhook === null || webhook === undefined || webhook.id === 0) {
            webhook = {
                hookEvent: "",
                callback: "",
                id: 0,
                customHeaders: {}
            };
            openEdit(webhook);
        } else {
            WebHooksService.getById(webhook.id).then(
                function (data) {
                    webhook = data;
                    openEdit(webhook);
                },
                function () {
                    NotificationService.success("Error opening webhook");
                }
            );
        }
    }

    function deleteWebhook(webhook) {
        var modal = $uibModal.open({
            component: "k2WebhooksDeleteModal",
            resolve: {
                model: function () {
                    return webhook;
                }
            }
        });
        modal.result.then(
            function () {
                NotificationService.success("The device was successfully deleted.");
                load();
            },
            () => {}
        );
    }

    function showPreviewPayload(webhook) {
        $uibModal
            .open({
                component: "k2WebhooksPayloadPreviewModal",
                windowClass: "webhooks-preview-modal",
                resolve: {
                    model: function () {
                        return webhook;
                    }
                }
            })
            .result.then(
                () => {},
                () => {}
            );
    }
}
