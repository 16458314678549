import { IComponentOptions, IRootScopeService } from "angular";

class ManageNetworkIdentityProviderController {
    public static $inject: Array<string> = ["$rootScope"];

    public identityProviderExists: boolean = false;

    constructor(private $rootScope: IRootScopeService) {}

    public $onInit(): void {
        this.$rootScope.$on("identityProviderExistenceChanged", (_, identityProviderExists: boolean) => {
            this.identityProviderExists = identityProviderExists;
        });
    }
}

export const manageNetworkIdentityProviderComponent: IComponentOptions = {
    templateUrl: require("./manage-network-identity-provider.component.html"),
    controller: ManageNetworkIdentityProviderController
};
