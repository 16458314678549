import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { AddIntegrationPatient, IntegrationCapabilities } from "./integrations.models";

@Injectable({
    providedIn: "root"
})
export class IntegrationsService {
    public addIntegrationPatient = (patient: AddIntegrationPatient): Promise<void> => firstValueFrom(this.addIntegrationPatient$(patient));
    public getCapabilities = (): Promise<IntegrationCapabilities> => firstValueFrom(this.getCapabilities$());
    public clearCachedData = (): Promise<void> => firstValueFrom(this.clearCachedData$());
    public revokeToken = (integrationType: string): Promise<void> => firstValueFrom(this.revokeToken$(integrationType));
    public revokeTokenOauth = (): Promise<void> => firstValueFrom(this.revokeTokenOauth$());

    constructor(private httpClient: HttpClient) {}

    private addIntegrationPatient$(patient: AddIntegrationPatient): Observable<void> {
        return this.httpClient.post<void>("/api/integrations/patients/add", patient);
    }

    private getCapabilities$(): Observable<IntegrationCapabilities> {
        return this.httpClient.get<IntegrationCapabilities>("/api/integrations/capabilities");
    }

    private clearCachedData$(): Observable<void> {
        return this.httpClient.post<void>("/api/integrations/clearcache", null);
    }

    private revokeToken$(integrationType: string): Observable<void> {
        return this.httpClient.post<void>("/api/integrations/revoketoken", { type: integrationType });
    }

    private revokeTokenOauth$(): Observable<void> {
        return this.httpClient.post<void>("/api/integrations/revoketoken/oauth", null);
    }
}
