import { BrandService } from "../../../common/services/brand.service";
import { AdminBatchImportsService } from "../admin.batchimport.service";

let ctrl;
class AdminBatchImportsOrganizationController {
    public static $inject: Array<string> = [
        "SubscriptionPlansService",
        "AdminBatchImportsService",
        "BrandService",
        "AdminResellersService",
        "NotificationService",
        "SessionService"
    ];
    public selectedTab = "status";

    public resolve;
    public close;
    public dismiss;

    public data: any;
    public importing;
    public reseller;

    constructor(
        private SubscriptionPlansService,
        private AdminBatchImportsService: AdminBatchImportsService,
        private BrandService: BrandService,
        private AdminResellersService,
        private NotificationService,
        private SessionService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.importing = false;
        this.reseller = undefined;
        this.getPlans(), this.getDirectProviders(), this.getBrands(), this.getOrganizationSpecialties(), this.getOrganizationTypes(), this.getResellers();
    }

    public import(form): void {
        let brandId = this.SessionService.getProfile().brandId;
        let data = {
            HispProviderId: form.HISP.$viewValue,
            BrandId: brandId,
            PlanId: form.Plan.$viewValue.id,
            ResellerId: this.reseller,
            Specialty: form.Specialty.$viewValue,
            Type: form.Type.$viewValue
        };

        let finalData = JSON.stringify(data);

        let file = form.FileData.$$element[0].files[0];

        this.AdminBatchImportsService.importOrganizations(file, finalData).then((data) => {
            this.NotificationService.success("The file was imported successfully.\n Please check the status screen.");
        });
    }

    public setReseller(data): void {
        this.reseller = data;
    }

    public getPlans(): void {
        return this.SubscriptionPlansService.getAll().then((plans) => {
            ctrl.plans = plans.items;
            ctrl.plans.push({
                id: null,
                name: "Free",
                allowedUsersDescription: "Free Plan"
            });
        });
    }

    public getDirectProviders(): void {
        return this.AdminBatchImportsService.getDirectProviders().then((providers) => {
            ctrl.directMessagingProviders = providers.data;
        });
    }

    public getBrands(): void {
        return this.BrandService.getBrands().then((brands) => {
            ctrl.brands = brands.items;
        });
    }

    public getOrganizationSpecialties(): void {
        return this.AdminBatchImportsService.getOrganizationSpecialties().then((data) => {
            ctrl.organizationSpecialtyList = data;
        });
    }

    public getOrganizationTypes(): void {
        return this.AdminBatchImportsService.getOrganizationTypes().then((data) => {
            ctrl.organizationTypeList = data;
        });
    }

    public getResellers(): void {
        return this.AdminResellersService.getResellers().then((data) => {
            let licensedResellers = [],
                affiliateResellers = [];
            for (let x = 0; x < data.length; x++) {
                data[x].type != "Affiliate" ? licensedResellers.push(data[x]) : affiliateResellers.push(data[x]);
            }

            ctrl.licensedResellers = licensedResellers;
            ctrl.affiliateResellers = affiliateResellers;
        });
    }
}

class AdminBatchImportsOrganizationComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin.batchimport-organizations.component.html");
        this.controller = AdminBatchImportsOrganizationController;
    }
}

export const adminBatchImportsOrganizationComponent = new AdminBatchImportsOrganizationComponent();
