import { PatientAddModalComponent } from "../../../../AppNgx/app/features/intake/message/patient-add-modal/patient-add-modal.component";

PatientLookupModalCtrl.$inject = [
    "_",
    "$uibModalInstance",
    "ngbModalService",
    "$timeout",
    "IntegrationsService",
    "patient",
    "patientLookupTemplate",
    "visitEnabled",
    "supportsGenderOnSearch",
    "NotificationService",
    "hasFacilityLookup",
    "facilities",
    "integrationDisplayName"
];

var tempCachedPatients = {};

export function PatientLookupModalCtrl(
    _,
    $uibModalInstance,
    ngbModalService,
    $timeout,
    IntegrationsService,
    patient,
    patientLookupTemplate,
    visitEnabled,
    supportsGenderOnSearch,
    NotificationService,
    hasFacilityLookup,
    facilities,
    integrationDisplayName
) {
    var self = this;

    self.patientLookupTemplate = patientLookupTemplate;
    self.integrationDisplayName = integrationDisplayName;
    self.patient = mapPatient(patient);
    self.visitEnabled = visitEnabled;
    self.search = search;
    self.selectPatient = selectPatient;
    self.addPatient = addPatient;
    self.showVisits = showVisits;
    self.hideVisits = hideVisits;
    self.selectVisit = selectVisit;
    self.hideBack = !!patient.patientResourceId;
    self.confirm = confirm;
    self.dismiss = dismiss;
    self.capabilities = null;
    self.canAddPatient = false;
    self.showInactivePatientsControl = false;
    self.clearSearch = clearSearch;
    self.supportsGenderOnSearch = supportsGenderOnSearch;
    self.searchableFields = new Set();
    self.facilities = facilities;
    self.hasFacilityLookup = hasFacilityLookup;
    self.facilityRequired = hasFacilityLookup && facilities.length > 10;

    activate();

    function activate() {
        IntegrationsService.getCapabilities().then(function (data) {
            self.capabilities = data.capabilities;
            self.canAddPatient = self.capabilities.some((c) => c.display === "patientAdd");
            self.showInactivePatientsControl = _.some(self.capabilities, (x) => _.some(x.properties, (y) => y.name === "includeInactive"));
            self.searchableFields = new Set(self.capabilities.find((c) => c.display === "patientLookup")?.properties.map((p) => p.name));
        });
        if (patient.integrationMeta && patient.integrationMeta.patientId) {
            var p = tempCachedPatients[patient.integrationMeta.patientId];
            if (!p) {
                p = {
                    patientResourceId: patient.integrationMeta.patientId
                };

                self.patientsLoading = true;
                IntegrationsService.getPatient(patient, patient.integrationMeta.patientId).then(function (current) {
                    tempCachedPatients[patient.integrationMeta.patientId] = self.selectedPatient = current;
                    self.patientsLoading = false;
                });
            }

            showVisits(p);
        }
    }

    function search() {
        if (!self.visitsVisible && isValidSearch()) {
            self.patientsLoading = true;
            IntegrationsService.getPatients(self.patient).then(
                function (patients) {
                    self.patients = patients;

                    if (patients.length === 1) selectPatient(patients[0]);

                    self.patientsLoading = false;
                },
                (err) => {
                    self.patientsLoading = false;
                    if (err.data && err.data.message) {
                        NotificationService.error(`Patient Search - ${err.data.message}`);
                    }
                }
            );
        }
    }

    function selectPatient(patient) {
        tempCachedPatients[patient.patientResourceId] = patient;
        if (self.selectedPatient === patient) patient = null;
        self.selectedPatient = patient;
    }

    function addPatient() {
        const modal = angular.element(document.querySelector(".patient-lookup-modal"));
        modal.css("display", "none");

        const { componentInstance, result } = ngbModalService.open(PatientAddModalComponent, {
            backdrop: false
        });
        componentInstance.patient = self.patient;
        componentInstance.integrationName = self.integrationDisplayName;
        componentInstance.capabilityProperties = self.capabilities.find((capability) => capability.display === "patientAdd").properties;

        result.then((patient) => {
            modal.css("display", "block");
            if (patient) {
                self.patient = patient;
                $timeout(() => self.search(), 100);
            }
        });
    }

    function showVisits(patient) {
        tempCachedPatients[patient.patientResourceId] = patient;
        self.selectedPatient = patient;
        self.visitsLoading = true;
        self.visitsVisible = true;
        IntegrationsService.getVisits(patient.patientResourceId).then(function (visits) {
            self.visits = visits;
            self.visitsLoading = false;
        });
    }

    function hideVisits() {
        self.visits = null;
        self.selectedVisit = null;
        self.visitsVisible = false;
    }

    function selectVisit(visit) {
        if (self.selectedVisit === visit) visit = null;
        self.selectedVisit = visit;
    }

    function confirm() {
        $uibModalInstance.close({
            patient: self.selectedPatient,
            visit: self.selectedVisit
        });
    }

    function dismiss() {
        $uibModalInstance.dismiss("cancel");
    }

    function clearSearch() {
        self.patient = mapPatient({});
        self.patients = null;
    }

    function mapPatient(p) {
        if (p) {
            const { patientId, firstName, lastName, birthDate, includeInactive, medicalRecordNumber } = p;
            const birthdate = birthDate ? moment(birthDate)?.format("YYYY-MM-DD") : null;
            let name = undefined;
            if (self.patientLookupTemplate === "App/src/intake/message/intake.message.hcs-patient-lookup-modal.html") {
                if (p.firstName || p.lastName) {
                    name = `${p.lastName || ""}, ${p.firstName || ""}`;
                }
            }

            const shouldUsePatientIdAsMrn = usePatientIdAsMrn();
            return {
                patientId: shouldUsePatientIdAsMrn ? null : patientId,
                firstName,
                lastName,
                birthdate,
                name,
                includeInactive,
                medicalRecordNumber: shouldUsePatientIdAsMrn ? patientId : medicalRecordNumber
            };
        }
        return {};
    }

    function usePatientIdAsMrn() {
        return ["PointClickCare", "Axxess"].includes(self.integrationDisplayName);
    }

    function isValidSearch() {
        const { gender, ...patient } = self.patient;
        return Object.values(patient).some((value) => value) && self.patientLookupForm.$valid;
    }
}
