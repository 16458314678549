DashboardRecentActivityModalCtrl.$inject = ["$scope", "$uibModalInstance", "moment", "EventService"];
export function DashboardRecentActivityModalCtrl($scope, $uibModalInstance, moment, EventService) {
    var now = new Date();

    $scope.cancel = cancel;
    $scope.submit = submit;
    $scope.dateOptions = { max: now };
    $scope.selection = {
        start: moment().days(-30).toDate(),
        end: now
    };

    function submit() {
        EventService.getRecentEventsReport($scope.selection, $uibModalInstance.close);
    }

    function cancel() {
        $uibModalInstance.dismiss("cancel");
    }
}
