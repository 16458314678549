class AdminBrandsEditController {
    public static $inject: Array<string> = ["$stateParams", "$state"];
    public brand: any;
    public brandId: any;

    constructor(
        private $stateParams,
        private $state
    ) {}

    $onInit() {
        this.brand = this.$stateParams.brand;
        this.brandId = this.$stateParams.id;
    }
}

class AdminBrandsEditComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: any;

    constructor() {
        this.templateUrl = require("./admin-brands.edit.component.html");
        this.controller = AdminBrandsEditController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}

export const adminBrandsEditComponent = new AdminBrandsEditComponent();
