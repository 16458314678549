declare const $;
export class HomeLayoutCtrl {
    public static $inject = ["$window", "sidebarService"];
    public sidebarCollapsed: () => boolean;

    constructor(
        private $window,
        private sidebarService
    ) {}

    $onInit() {
        this.sidebarCollapsed = this.sidebarService.collapsed;
        const h = $(this.$window).height();
        if (h > 500) $("#content").css("min-height", h);
    }
}

export const homeLayoutComponent = {
    templateUrl: require("./home.layout.component.html"),
    controller: HomeLayoutCtrl
};
