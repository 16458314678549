export const LANGUAGES: [string, string][] = [
    ["Abkhaz - Аҧсуа", "Abkhaz"],
    ["Afar - Afaraf", "Afar"],
    ["Afrikaans - Afrikaans", "Afrikaans"],
    ["Akan - Akan", "Akan"],
    ["Albanian - Shqip", "Albanian"],
    ["Amharic - አማርኛ", "Amharic"],
    ["Arabic - العربية", "Arabic"],
    ["Aragonese - Aragonés", "Aragonese"],
    ["Armenian - Հայերեն", "Armenian"],
    ["Assamese - অসমীয়া", "Assamese"],
    ["Avaric - Авар", "Avaric"],
    ["Avestan - avesta", "Avestan"],
    ["Aymara - Aymar", "Aymara"],
    ["Azerbaijani - Azərbaycanca", "Azerbaijani"],
    ["Bambara - Bamanankan", "Bambara"],
    ["Bashkir - Башҡортса", "Bashkir"],
    ["Basque - Euskara", "Basque"],
    ["Belarusian - Беларуская", "Belarusian"],
    ["Bengali - বাংলা", "Bengali"],
    ["Bihari - भोजपुरी", "Bihari"],
    ["Bislama - Bislama", "Bislama"],
    ["Bosnian - Bosanski", "Bosnian"],
    ["Breton - Brezhoneg", "Breton"],
    ["Bulgarian - Български", "Bulgarian"],
    ["Burmese - မြန်မာဘာသာ", "Burmese"],
    ["Catalan - Català", "Catalan"],
    ["Chamorro - Chamoru", "Chamorro"],
    ["Chechen - Нохчийн", "Chechen"],
    ["Chichewa - Chichewa", "Chichewa"],
    ["Chinese - 中文", "Chinese"],
    ["Chuvash - Чӑвашла", "Chuvash"],
    ["Cornish - Kernewek", "Cornish"],
    ["Corsican - Corsu", "Corsican"],
    ["Cree - ᓀᐦᐃᔭᐍᐏᐣ", "Cree"],
    ["Croatian - Hrvatski", "Croatian"],
    ["Czech - Čeština", "Czech"],
    ["Danish - Dansk", "Danish"],
    ["Divehi - Divehi", "Divehi"],
    ["Dutch - Nederlands", "Dutch"],
    ["Dzongkha - རྫོང་ཁ", "Dzongkha"],
    ["English - English", "English"],
    ["Esperanto - Esperanto", "Esperanto"],
    ["Estonian - Eesti", "Estonian"],
    ["Ewe - Eʋegbe", "Ewe"],
    ["Faroese - Føroyskt", "Faroese"],
    ["Fijian - Na Vosa Vaka-Viti", "Fijian"],
    ["Finnish - Suomi", "Finnish"],
    ["French - Français", "French"],
    ["Fula - Fulfulde", "Fula"],
    ["Galician - Galego", "Galician"],
    ["Georgian - ქართული", "Georgian"],
    ["German - Deutsch", "German"],
    ["Greek - Ελληνικά", "Greek"],
    ["Guaraní - Avañe'ẽ", "Guaraní"],
    ["Gujarati - ગુજરાતી", "Gujarati"],
    ["Haitian - Kreyòl Ayisyen", "Haitian"],
    ["Hausa - هَوُسَ", "Hausa"],
    ["Hebrew - עברית", "Hebrew"],
    ["Herero - Otjiherero", "Herero"],
    ["Hindi - हिन्दी", "Hindi"],
    ["Hiri Motu - Hiri Motu", "Hiri Motu"],
    ["Hungarian - Magyar", "Hungarian"],
    ["Interlingua - Interlingua", "Interlingua"],
    ["Indonesian - Bahasa Indonesia", "Indonesian"],
    ["Interlingue - Interlingue", "Interlingue"],
    ["Irish - Gaeilge", "Irish"],
    ["Igbo - Igbo", "Igbo"],
    ["Inupiaq - Iñupiak", "Inupiaq"],
    ["Ido - Ido", "Ido"],
    ["Icelandic - Íslenska", "Icelandic"],
    ["Italian - Italiano", "Italian"],
    ["Inuktitut - ᐃᓄᒃᑎᑐᑦ", "Inuktitut"],
    ["Japanese - 日本語", "Japanese"],
    ["Javanese - Basa Jawa", "Javanese"],
    ["Kalaallisut - Kalaallisut", "Kalaallisut"],
    ["Kannada - ಕನ್ನಡ", "Kannada"],
    ["Kanuri - Kanuri", "Kanuri"],
    ["Kashmiri - كشميري", "Kashmiri"],
    ["Kazakh - Қазақша", "Kazakh"],
    ["Khmer - ភាសាខ្មែរ", "Khmer"],
    ["Kikuyu - Gĩkũyũ", "Kikuyu"],
    ["Kinyarwanda - Kinyarwanda", "Kinyarwanda"],
    ["Kyrgyz - Кыргызча", "Kyrgyz"],
    ["Komi - Коми", "Komi"],
    ["Kongo - Kongo", "Kongo"],
    ["Korean - 한국어", "Korean"],
    ["Kurdish - Kurdî", "Kurdish"],
    ["Kwanyama - Kuanyama", "Kwanyama"],
    ["Latin - Latina", "Latin"],
    ["Luxembourgish - Lëtzebuergesch", "Luxembourgish"],
    ["Ganda - Luganda", "Ganda"],
    ["Limburgish - Limburgs", "Limburgish"],
    ["Lingala - Lingála", "Lingala"],
    ["Lao - ພາສາລາວ", "Lao"],
    ["Lithuanian - Lietuvių", "Lithuanian"],
    ["Luba-Katanga - Tshiluba", "Luba-Katanga"],
    ["Latvian - Latviešu", "Latvian"],
    ["Manx - Gaelg", "Manx"],
    ["Macedonian - Македонски", "Macedonian"],
    ["Malagasy - Malagasy", "Malagasy"],
    ["Malay - Bahasa Melayu", "Malay"],
    ["Malayalam - മലയാളം", "Malayalam"],
    ["Maltese - Malti", "Maltese"],
    ["Māori - Māori", "Māori"],
    ["Marathi - मराठी", "Marathi"],
    ["Marshallese - Kajin M̧ajeļ", "Marshallese"],
    ["Mongolian - Монгол", "Mongolian"],
    ["Nauru - Dorerin Naoero", "Nauru"],
    ["Navajo - Diné Bizaad", "Navajo"],
    ["Northern Ndebele - isiNdebele", "Northern Ndebele"],
    ["Nepali - नेपाली", "Nepali"],
    ["Ndonga - Owambo", "Ndonga"],
    ["Norwegian Bokmål - Norsk (Bokmål)", "Norwegian Bokmål"],
    ["Norwegian Nynorsk - Norsk (Nynorsk)", "Norwegian Nynorsk"],
    ["Norwegian - Norsk", "Norwegian"],
    ["Nuosu - ꆈꌠ꒿ Nuosuhxop", "Nuosu"],
    ["Southern Ndebele - isiNdebele", "Southern Ndebele"],
    ["Occitan - Occitan", "Occitan"],
    ["Ojibwe - ᐊᓂᔑᓈᐯᒧᐎᓐ", "Ojibwe"],
    ["Old Church Slavonic - Словѣ́ньскъ", "Old Church Slavonic"],
    ["Oromo - Afaan Oromoo", "Oromo"],
    ["Oriya - ଓଡି଼ଆ", "Oriya"],
    ["Ossetian - Ирон æвзаг", "Ossetian"],
    ["Panjabi - ਪੰਜਾਬੀ", "Panjabi"],
    ["Pāli - पाऴि", "Pāli"],
    ["Persian - فارسی", "Persian"],
    ["Polish - Polski", "Polish"],
    ["Pashto - پښتو", "Pashto"],
    ["Portuguese - Português", "Portuguese"],
    ["Quechua - Runa Simi", "Quechua"],
    ["Romansh - Rumantsch", "Romansh"],
    ["Kirundi - Kirundi", "Kirundi"],
    ["Romanian - Română", "Romanian"],
    ["Russian - Русский", "Russian"],
    ["Sanskrit - संस्कृतम्", "Sanskrit"],
    ["Sardinian - Sardu", "Sardinian"],
    ["Sindhi - سنڌي‎", "Sindhi"],
    ["Northern Sami - Sámegiella", "Northern Sami"],
    ["Samoan - Gagana Sāmoa", "Samoan"],
    ["Sango - Sängö", "Sango"],
    ["Serbian - Српски", "Serbian"],
    ["Gaelic - Gàidhlig", "Gaelic"],
    ["Shona - ChiShona", "Shona"],
    ["Sinhala - සිංහල", "Sinhala"],
    ["Slovak - Slovenčina", "Slovak"],
    ["Slovene - Slovenščina", "Slovene"],
    ["Somali - Soomaaliga", "Somali"],
    ["Southern Sotho - Sesotho", "Southern Sotho"],
    ["Spanish - Español", "Spanish"],
    ["Sundanese - Basa Sunda", "Sundanese"],
    ["Swahili - Kiswahili", "Swahili"],
    ["Swati - SiSwati", "Swati"],
    ["Swedish - Svenska", "Swedish"],
    ["Tamil - தமிழ்", "Tamil"],
    ["Telugu - తెలుగు", "Telugu"],
    ["Tajik - Тоҷикӣ", "Tajik"],
    ["Thai - ภาษาไทย", "Thai"],
    ["Tigrinya - ትግርኛ", "Tigrinya"],
    ["Tibetan Standard - བོད་ཡིག", "Tibetan Standard"],
    ["Turkmen - Türkmençe", "Turkmen"],
    ["Tagalog - Tagalog", "Tagalog"],
    ["Tswana - Setswana", "Tswana"],
    ["Tonga - faka Tonga", "Tonga"],
    ["Turkish - Türkçe", "Turkish"],
    ["Tsonga - Xitsonga", "Tsonga"],
    ["Tatar - Татарча", "Tatar"],
    ["Twi - Twi", "Twi"],
    ["Tahitian - Reo Mā’ohi", "Tahitian"],
    ["Uyghur - ئۇيغۇرچه", "Uyghur"],
    ["Ukrainian - Українська", "Ukrainian"],
    ["Urdu - اردو", "Urdu"],
    ["Uzbek - O‘zbek", "Uzbek"],
    ["Venda - Tshivenḓa", "Venda"],
    ["Vietnamese - Tiếng Việt", "Vietnamese"],
    ["Volapük - Volapük", "Volapük"],
    ["Walloon - Walon", "Walloon"],
    ["Welsh - Cymraeg", "Welsh"],
    ["Wolof - Wolof", "Wolof"],
    ["Western Frisian - Frysk", "Western Frisian"],
    ["Xhosa - isiXhosa", "Xhosa"],
    ["Yiddish - ייִדיש", "Yiddish"],
    ["Yoruba - Yorùbá", "Yoruba"],
    ["Zhuang - Cuengh", "Zhuang"],
    ["Zulu - isiZulu", "Zulu"]
];
