import { Directive, inject, input, output } from "@angular/core";
import { NgModel } from "@angular/forms";
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";

@Directive({
    selector: "[kno2DebounceChange]",
    standalone: true
})
export class DebounceChangeDirective {
    private readonly ngModel = inject(NgModel);
    private subscription: Subscription;

    public readonly kno2DebounceChange = output<any>();
    public kno2DebounceChangeDelay = input<number>(500);

    public ngOnInit(): void {
        this.subscription = this.ngModel.control.valueChanges
            .pipe(debounceTime(this.kno2DebounceChangeDelay()), distinctUntilChanged())
            .subscribe((value) => this.kno2DebounceChange.emit(value));
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
