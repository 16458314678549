autoExtensionDirective.$inject = [];

export function autoExtensionDirective() {
    var directive = {
        restrict: "A",
        require: "ngModel",
        link: link
    };

    return directive;

    function link(scope, element, attrs, ngModelCtrl) {
        element
            .find("*")
            .addBack()
            .on("blur", update)
            .on("keydown", function (e) {
                if (e.keyCode === 13) update();
            });

        var extension = attrs.autoExtension;

        function update() {
            var old = ngModelCtrl.$viewValue;
            var value = addExtension(old);
            if (old != value) updateModel(value);
        }

        function addExtension(value) {
            if (value) {
                var e = extension;
                var tail = value.substr(value.length - e.length);
                if (tail !== e) {
                    return value + e;
                }
            }
            return value;
        }

        function updateModel(value) {
            scope.$apply(function () {
                ngModelCtrl.$setViewValue(value);
                ngModelCtrl.$render();
            });
        }
    }
}
