ClientApplicationService.$inject = ["$http"];

export function ClientApplicationService($http) {
    const svc = this;

    svc.getApplication = getApplication;
    svc.addApplication = addApplication;
    svc.listApplications = listApplications;
    svc.removeApplication = removeApplication;
    svc.updateApplication = updateApplication;
    svc.generateCredentials = generateCredentials;

    function getApplication(id) {
        return $http.get(`/api/admin/clientapplications/${id}`).then((response) => response.data);
    }

    function addApplication(application) {
        return $http.post("/api/admin/clientapplications", application).then((response) => response.data);
    }

    function updateApplication(application) {
        return $http.put(`/api/admin/clientapplications/${application.id}`, application).then((response) => response.data);
    }

    function listApplications() {
        return $http.get("/api/admin/clientapplications").then((response) => response.data);
    }

    function generateCredentials(id) {
        return $http.post(`/api/admin/clientapplications/${id}/credentials`).then((response) => response.data);
    }

    function removeApplication(id) {
        return $http.delete(`/api/admin/clientapplications/${id}`).then((response) => response.data);
    }
}
