<div class="input-group">
    <div class="input-group-prepend">
        <span class="input-group-text">
            <i class="fa fa-calendar"></i>
        </span>
    </div>
    <input
        #datepicker="ngbDatepicker"
        #inputField
        ngbDatepicker
        kno2DateFormat
        dateFormatDelimiter="/"
        [formControl]="control()"
        class="form-control"
        [class.has-error]="hasError()"
        [placeholder]="placeholder()"
        (focus)="datepicker.open(); preventPickerFocus()"
        [restoreFocus]="false"
        (paste)="formatOnPaste($event)" />
</div>
