angular.module("kno2.services").service("SaveService", [
    "appConfig",
    function (appConfig) {
        // Common code method for downloading csv file or attachments.
        var saveFileInternal = function (data, status, headers, reportName) {
            var octetStreamMime = "application/octet-stream";

            // Get the headers
            headers = headers();
            // Get the filename from the x-filename header or default to "download.bin"
            // var filename = headers["x-filename"] || "download.bin";
            var filename = buildFilename(reportName);

            // Determine the content type from the header or default to "application/octet-stream"
            var contentType = headers["content-type"] || octetStreamMime;

            if (navigator.msSaveBlob) {
                // Save blob is supported, so get the blob as it's contentType and call save.
                var blob = new Blob([data], { type: contentType });
                navigator.msSaveBlob(blob, filename);
            } else {
                // Get the blob url creator
                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                if (urlCreator) {
                    // Try to use a download link
                    var link = document.createElement("a");
                    if ("download" in link) {
                        // Prepare a blob URL
                        var blob = new Blob([data], { type: contentType });
                        var url = urlCreator.createObjectURL(blob);
                        link.setAttribute("href", url);

                        // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                        link.setAttribute("download", filename);

                        // Simulate clicking the download link
                        var event = document.createEvent("MouseEvents");
                        event.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                        link.dispatchEvent(event);
                    } else {
                        // Prepare a blob URL
                        // Use application/octet-stream when using window.location to force download
                        var blob = new Blob([data], { type: octetStreamMime });
                        var url = urlCreator.createObjectURL(blob);
                        window.location = url;
                    }
                } else {
                    alert("Unable to download the file.");
                }
            }
        };

        var buildFilename = function (reportName) {
            let fileName = "";
            switch (reportName) {
                case "DirectAddresses":
                case "Users":
                case "Organizations":
                case "Messages":
                case "Devices":
                    fileName += `${appConfig.brand}-${reportName}_${moment().utc().format("YYYY-MM-DD_HH-mm-ss")}.csv`;
                    break;
                case "Activity":
                    fileName = `${moment().utc().format("YYYY-MM-DD_HH-mm-ss")}.csv`;
                    break;
                default:
                    fileName = reportName;
                    break;
            }
            return fileName;
        };

        return {
            saveFile: saveFileInternal
        };
    }
]);
