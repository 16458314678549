import { SettingsService } from "../../../common/services/settings.service";

class AdminBrandSettingsController {
    public static $inject: Array<string> = ["SettingsService", "$stateParams", "$q"];
    public brand: any;
    public brandId: any;
    public settingTypes: Array<any>;

    constructor(
        private settingsService: SettingsService,
        private $stateParams,
        private $q
    ) {}

    public $onInit(): void {
        this.brandId = this.$stateParams.id;
        this.settingTypes = [];

        this.getSettings();
    }

    public getSettings(): void {
        const getSettingTypesPromise = this.settingsService.getSettingTypes("Brand");
        const getAllSettingsPromise = this.settingsService.getAll(this.brandId, "Brand");

        this.$q.all([getSettingTypesPromise, getAllSettingsPromise]).then(([settingTypes, settings]) => {
            if (settingTypes.data.isSuccess) {
                this.settingTypes = settingTypes.data.data;
                this.settingTypes.forEach((set) => {
                    const name = set.name.charAt(0).toLowerCase() + set.name.slice(1);
                    if (set.dataType === "Boolean") {
                        set.value = settings.data.data[name] === "true" || false;
                    }
                });
            }
        });
    }

    public async updateSetting(setting: any): Promise<void> {
        const body = {
            entityId: this.brandId,
            entityType: "Brand",
            settings: {}
        };
        body.settings[setting.name] = setting.value;
        await this.settingsService.upsert(body);
    }
}

class AdminBrandSettingsComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./admin-brand.settings.component.html");
        this.controller = AdminBrandSettingsController;
        this.bindings = {
            brand: "="
        };
    }
}

export const adminBrandSettingsComponent = new AdminBrandSettingsComponent();
