import { FORMATS, MODEL_FORMAT } from "../utils/date";

datepickerLocalDateDirective.$inject = ["uibDatepickerPopupConfig", "moment"];
export function datepickerLocalDateDirective(uibDatepickerPopupConfig, moment) {
    var directive = {
        restrict: "A",
        require: "?ngModel", // get ahold of NgModelController
        link: link,
        priority: 1
    };
    return directive;

    function link(scope, element, attrs, ngModel) {
        if (!ngModel) return; // do nothing if no ng-model

        ngModel.$validators.date = function (modelValue, viewValue) {
            var value = modelValue || viewValue;

            if (!attrs.ngRequired && !value) return true;
            if (angular.isNumber(value)) value = new Date(value);
            if (!value) return true;
            if (angular.isDate(value) && !isNaN(value)) return true;
            if (angular.isString(value)) return moment(value, MODEL_FORMAT, true).isValid();

            return false;
        };

        ngModel.$formatters.push(function timezoneOffsetFormatter(value) {
            if (!value) return value;

            var date;

            if (angular.isString(value)) {
                var d = moment(value, FORMATS, true);
                if (d.isValid()) date = d.toDate();
            }

            if (!date) date = new Date(value);

            var offset = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

            return offset;
        });

        ngModel.$parsers.unshift(function timezoneOffSetParser(value) {
            if (!value) return value;

            if (!angular.isDate(value)) {
                var date = moment(value, FORMATS, true);
                if (!date.isValid()) return undefined;
                value = date.toDate();
            }

            return new Date(value.getTime() - value.getTimezoneOffset() * 60000);
        });

        ngModel.$parsers.push(function toDateStringParser(value) {
            if (!value && !angular.isDate(value)) return value;

            return value.toISOString().substring(0, 10);
        });
    }
}
