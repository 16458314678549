import { CommonModule } from "../common";

import { beautifyFilter } from "../common/filters/beautify.filter";
import { integrationTypeFilter } from "../common/filters/integration-type.filter";
import { percentageFilter } from "../common/filters/percentage.filter.js";
import { stripUrlFilter } from "../common/filters/strip-url.filter";
import { apiCaptureComponent } from "./apicapture/api-capture.component.js";
import { ApiCaptureService } from "./apicapture/api-capture.service.js";
import { adminAuditsComponent } from "./audits/admin-audits.component.js";
import { AdminAuditsService } from "./audits/admin-audits.service.js";
import { adminBatchImportsComponent } from "./batchimports/admin.batchimport.component";
import { AdminBatchImportsService } from "./batchimports/admin.batchimport.service";
import { adminBatchImportsOrganizationComponent } from "./batchimports/organizations/admin.batchimport-organizations.component";
import { adminBatchImportsStatusComponent } from "./batchimports/status/admin.batchimport-status.component";
import { AdminCertificatesComponent } from "./certificates/admin.certificates.component";
import { AdminCertificatesEditComponent } from "./certificates/admin.certificates.edit-modal.component";
import { AdminCertificatesService } from "./certificates/admin.certificates.service";
import { clientAppGridComponent } from "./clientapplications/clientapp-grid.component";
import { clientApplicationComponent } from "./clientapplications/clientapp.component";
import { ClientApplicationService } from "./clientapplications/clientapplication.service";
import { messagingHealthDashboardComponent } from "./diagnostics/messaging-health-dashboard.component";
import { messagingHealthWorkflowsComponent } from "./diagnostics/messaging-health-workflows.component";
import { MessagingHealthService } from "./diagnostics/messaging-health.service";
import { AdminDocumentSourcesStatusModal } from "./documentsources/admin-documentsource-status.modal.component";
import { adminDocumentSourcesListComponent } from "./documentsources/admin-documentsources-list.component";
import { AdminDocumentSourcesService } from "./documentsources/admin-documentsources.service";
import { AdminEmergenciesComponent } from "./emergencies/admin.emergencies.component";
import { AdminEmergenciesListComponent } from "./emergencies/emergencies/admin.emergencies-list.component";
import { AdminEmergenciesEditComponent } from "./emergencies/emergencies/admin.emergencies.edit-modal.component";
import { AdminEmergenciesService } from "./emergencies/emergencies/admin.emergencies.service";
import { AdminEmergenciesOrganizationsComponent } from "./emergencies/organizations/admin.emergencies-organizations.component";
import { AdminEmergenciesOrganizationsService } from "./emergencies/organizations/admin.emergencies-organizations.service";
import { AdminEmergenciesOrganizationsEditComponent } from "./emergencies/organizations/admin.emergencies.edit-organizations-modal.component";
import { integrationCodeGrantsEditModalComponent } from "./integration-codegrants/integration-codegrants-edit.modal.component";
import { integrationCodeGrantsListComponent } from "./integration-codegrants/integration-codegrants-list.component";
import { integrationCodeGrantsOrgsModalComponent } from "./integration-codegrants/integration-codegrants-orgs.modal.component";
import { AdminIntegrationCodeGrantsService } from "./integration-codegrants/integration-codegrants.service";
import { integrationWhitelistEditModalComponent } from "./integration-whitelist/integration-whitelist-edit.modal.component";
import { integrationWhitelistListComponent } from "./integration-whitelist/integration-whitelist-list.component";
import { AdminIntegrationsService } from "./integrations/admin-integrations.service";
import { integrationListViewComponent } from "./integrations/integration-list-view.component";
import { integrationListComponent } from "./integrations/integration-list.component";
import { adminOrganizationSpecialtiesDeleteModalComponent } from "./organization-specialties/admin-organization-specialties-delete.modal.component";
import { adminOrganizationSpecialtiesEditModalComponent } from "./organization-specialties/admin-organization-specialties-edit.modal.component";
import { adminorganizationSpecialtiesComponent } from "./organization-specialties/admin-organization-specialties.component";
import { OrganizationSpecialtiesService } from "./organization-specialties/admin-organization-specialties.service";
import { adminOrganizationTypesDeleteModalComponent } from "./organization-types/admin-organization-types-delete.modal.component";
import { adminOrganizationTypesEditModalComponent } from "./organization-types/admin-organization-types-edit.modal.component";
import { adminOrganizationTypesComponent } from "./organization-types/admin-organization-types.component";
import { OrganizationTypesService } from "./organization-types/admin-organization-types.service";
import { adminOrganizationsActivateModalComponent } from "./organizations/admin-organizations-activate.modal.component";
import { adminOrganizationsClearCancelModalComponent } from "./organizations/admin-organizations-clear-cancel.modal.component";
import { adminOrganizationsDeleteModalComponent } from "./organizations/admin-organizations-delete.modal.component";
import { adminOrganizationsEditComponent } from "./organizations/admin-organizations-edit.component";
import { adminOrganizationsNpiDetailsModalComponent } from "./organizations/admin-organizations-npi.modal.component";
import { adminOrganizationsComponent } from "./organizations/admin-organizations.component";
import { AdminOrganizationsService } from "./organizations/admin-organizations.service";
import { adminOrganizationsCarequalityAlternateoidsComponent } from "./organizations/carequality/admin-organizations-carequality-alternateoids.component.js";
import { adminOrganizationsCarequalityDeleteModalComponent } from "./organizations/carequality/admin-organizations-carequality-delete.modal.component";
import { adminOrganizationsCarequalityDirectoryActiveToggleModalComponent } from "./organizations/carequality/admin-organizations-carequality-directory-active-toggle.modal.component";
import { adminOrganizationsCarequalityDirectoryEditModalComponent } from "./organizations/carequality/admin-organizations-carequality-directory-edit.modal.component.js";
import { adminOrganizationsCarequalityDirectorySyncModalComponent } from "./organizations/carequality/admin-organizations-carequality-directory-sync.modal.component";
import { adminOrganizationsCarequalityDirectoryComponent } from "./organizations/carequality/admin-organizations-carequality-directory.component.js";
import { adminOrganizationsCarequalityEditModalComponent } from "./organizations/carequality/admin-organizations-carequality-edit.modal.component";
import { adminOrganizationsIpAddressesComponent } from "./organizations/ipaddresses/admin-ipaddresses-list.component";
import { AdminIpAddressesService } from "./organizations/ipaddresses/admin-ipaddresses.service";
import { AdminResellersCtrl } from "./resellers/admin.resellers.ctrl";
import { ResellersDeleteCtrl } from "./resellers/admin.resellers.delete-modal.ctrl";
import { validateUrlCodeDirective, validateUrlCodeReservedWordsDirective } from "./resellers/admin.resellers.directives";
import { ResellersEditCtrl } from "./resellers/admin.resellers.edit-modal.ctrl";
import { AdminServiceAccountService } from "./serviceaccounts/admin-service-account.service";
import { serviceAccountEditComponent } from "./serviceaccounts/service-account-edit.component";
import { serviceAccountListComponent } from "./serviceaccounts/service-account-list.component";
import { siftMetricsComponent } from "./siftmetrics/sift-metrics.component.js";
import { SiftMetricsService } from "./siftmetrics/sift-metrics.service.js";
import { messageUtilsComponent } from "./utils/message-utils.component.js";
import { UtilsService } from "./utils/utils.service.js";
import { workflowsComponent } from "./workflows/workflows.component.js";
import { WorkflowsService } from "./workflows/workflows.service.js";

import { adminBrandsComponent } from "./brands/admin-brands.component";
import { adminBrandsEditComponent } from "./brands/admin-brands.edit.component";
import { adminBrandCreateComponent } from "./brands/create/admin-brand.create.component";
import { addBrandServiceAccountModalComponent } from "./brands/service-account/add-service-account-modal/add-brand-service-account-modal.component";
import { brandServiceAccountComponent } from "./brands/service-account/brand.service-account.component";
import { editBrandServiceAccountModalComponent } from "./brands/service-account/edit-brand-service-account-modal/edit-brand-service-account-modal.component";
import { adminBrandSettingsComponent } from "./brands/settings/admin-brand.settings.component";

import { AdminDocuSignComponent } from "./docusign/admin.docusign.component";
import { AdminDocuSignDeleteModalComponent } from "./docusign/admin.docusign.delete-modal.component";
import { AdminDocuSignLoginComponent } from "./docusign/admin.docusign.login-modal.component";
import { AdminDocuSignService } from "./docusign/admin.docusign.service";

import angularChartJs from "angular-chart.js";
import "chart.js/dist/Chart.js";
import "chart.piecelabel.js";
import { appConfigModule } from "../app-config";

export const AdminModule = angular
    .module("kno2.admin", [appConfigModule.name, CommonModule, "kendo.directives", "angular-flot", angularChartJs, "ui.toggle"])
    .factory("AdminServiceAccountService", AdminServiceAccountService)
    .service("AdminIntegrationsService", AdminIntegrationsService)
    .service("AdminIntegrationCodeGrantsService", AdminIntegrationCodeGrantsService)
    .service("AdminOrganizationsService", AdminOrganizationsService)
    .service("ClientApplicationService", ClientApplicationService)
    .component("k2AdminOrganizationsIpAddresses", adminOrganizationsIpAddressesComponent)
    .component("k2ClientApplicationsGrid", clientAppGridComponent)
    .component("k2ClientApplicationEdit", clientApplicationComponent)
    .component("k2ServiceAccountList", serviceAccountListComponent)
    .component("k2ServiceAccountEdit", serviceAccountEditComponent)
    .component("k2IntegrationList", integrationListComponent)
    .component("k2DocumentSourcesList", adminDocumentSourcesListComponent)
    .component("k2IntegrationListView", integrationListViewComponent)
    .component("k2IntegrationWhitelistList", integrationWhitelistListComponent)
    .component("k2IntegrationWhitelistAddEditModal", integrationWhitelistEditModalComponent)
    .component("k2IntegrationCodeGrantsList", integrationCodeGrantsListComponent)
    .component("k2IntegrationCodeGrantsAddEditModal", integrationCodeGrantsEditModalComponent)
    .component("k2IntegrationCodeGrantsOrgsModal", integrationCodeGrantsOrgsModalComponent)
    .component("k2AdminOrganizations", adminOrganizationsComponent)
    .component("k2AdminOrganizationsEdit", adminOrganizationsEditComponent)
    .component("k2AdminOrganizationsCarequalityEditModalComponent", adminOrganizationsCarequalityEditModalComponent)
    .component("k2AdminOrganizationsCarequalityDeleteModalComponent", adminOrganizationsCarequalityDeleteModalComponent)
    .component("k2AdminOrganizationsActivateModal", adminOrganizationsActivateModalComponent)
    .component("k2AdminOrganizationsDeleteModal", adminOrganizationsDeleteModalComponent)
    .component("k2AdminOrganizationsClearCancelModal", adminOrganizationsClearCancelModalComponent)
    .component("k2AdminOrganizationsNpiModal", adminOrganizationsNpiDetailsModalComponent)
    .controller("AdminResellersCtrl", AdminResellersCtrl)
    .controller("ResellersEditCtrl", ResellersEditCtrl)
    .controller("ResellersDeleteCtrl", ResellersDeleteCtrl)
    .directive("validateUrlCode", validateUrlCodeDirective)
    .directive("validateUrlCodeReservedWords", validateUrlCodeReservedWordsDirective)
    .service("MessagingHealthService", MessagingHealthService)
    .service("AdminDocumentSourcesService", AdminDocumentSourcesService)
    .component("k2DocumentSourceStatusModal", AdminDocumentSourcesStatusModal)
    .component("k2MessagingHealthDashboard", messagingHealthDashboardComponent)
    .component("k2MessagingHealthWorkflowsComponent", messagingHealthWorkflowsComponent)
    .filter("percentage", percentageFilter)
    .service("SiftMetricsService", SiftMetricsService)
    .component("k2SiftMetrics", siftMetricsComponent)
    .filter("beautify", beautifyFilter)
    .filter("stripurl", stripUrlFilter)
    .filter("integrationType", integrationTypeFilter)
    .service("ApiCaptureService", ApiCaptureService)
    .component("k2ApiCapture", apiCaptureComponent)
    .component("k2MessageUtils", messageUtilsComponent)
    .component("k2Workflows", workflowsComponent)
    .service("UtilsService", UtilsService)
    .service("WorkflowsService", WorkflowsService)
    .component("k2OrganizationTypes", adminOrganizationTypesComponent)
    .component("k2AdminOrganizationTypesEditModal", adminOrganizationTypesEditModalComponent)
    .component("k2AdminOrganizationTypesDeleteModal", adminOrganizationTypesDeleteModalComponent)
    .component("k2OrganizationSpecialties", adminorganizationSpecialtiesComponent)
    .component("k2AdminOrganizationSpecialtiesEditModal", adminOrganizationSpecialtiesEditModalComponent)
    .component("k2AdminOrganizationSpecialtiesDeleteModal", adminOrganizationSpecialtiesDeleteModalComponent)
    .service("OrganizationTypesService", OrganizationTypesService)
    .service("OrganizationSpecialtiesService", OrganizationSpecialtiesService)
    .component("k2AdminOrganizationsCarequalityAlternateoids", adminOrganizationsCarequalityAlternateoidsComponent)
    .component("k2AdminOrganizationsCarequalityDirectory", adminOrganizationsCarequalityDirectoryComponent)
    .component("k2AdminOrganizationsCarequalityDirectoryEditModalComponent", adminOrganizationsCarequalityDirectoryEditModalComponent)
    .component("k2AdminOrganizationsCarequalityDirectoryActiveToggleModalComponent", adminOrganizationsCarequalityDirectoryActiveToggleModalComponent)
    .component("k2AdminOrganizationsCarequalityDirectorySyncModalComponent", adminOrganizationsCarequalityDirectorySyncModalComponent)
    .service("AdminAuditsService", AdminAuditsService)
    .service("AdminIpAddressesService", AdminIpAddressesService)
    .component("k2AdminAudits", adminAuditsComponent)
    .component("k2AdminEmergenciesComponent", new AdminEmergenciesComponent())
    .component("k2AdminEmergenciesList", new AdminEmergenciesListComponent())
    .component("k2AdminEmergenciesEditComponent", new AdminEmergenciesEditComponent())
    .service("AdminEmergenciesService", AdminEmergenciesService)
    .component("k2AdminEmergenciesOrganizations", new AdminEmergenciesOrganizationsComponent())
    .component("k2AdminEmergenciesOrganizationsEditComponent", new AdminEmergenciesOrganizationsEditComponent())
    .service("AdminEmergenciesOrganizationsService", AdminEmergenciesOrganizationsService)
    .service("AdminCertificatesService", AdminCertificatesService)
    .component("k2Certificates", new AdminCertificatesComponent())
    .component("k2AdminCertificatesEditComponent", new AdminCertificatesEditComponent())
    .service("AdminBatchImportsService", AdminBatchImportsService)
    .component("adminBatchImportsComponent", adminBatchImportsComponent)
    .component("adminBatchImportsStatusComponent", adminBatchImportsStatusComponent)
    .component("adminBatchImportsOrganizationComponent", adminBatchImportsOrganizationComponent)
    .component("k2AdminBrands", adminBrandsComponent)
    .component("k2AdminBrandsEdit", adminBrandsEditComponent)
    .component("k2AdminBrandCreate", adminBrandCreateComponent)
    .component("k2AdminBrandSettings", adminBrandSettingsComponent)
    .component("brandServiceAccountComponent", brandServiceAccountComponent)
    .component("addBrandServiceAccountModalComponent", addBrandServiceAccountModalComponent)
    .component("editBrandServiceAccountModalComponent", editBrandServiceAccountModalComponent)
    .component("k2AdminDocuSignComponent", new AdminDocuSignComponent())
    .component("k2AdminDocuSignLoginComponent", new AdminDocuSignLoginComponent())
    .component("k2AdminDocuSignDeleteModalComponent", new AdminDocuSignDeleteModalComponent())
    .service("AdminDocuSignService", AdminDocuSignService);
