import templateUrl from "./filter.component.html";

angular.module("kno2.components").component("k2Filter", {
    transclude: true,
    controller: FilterController,
    templateUrl,
    require: {
        group: "^^k2FilterGroup"
    },
    bindings: {
        prop: "<",
        exact: "@",
        date: "@",
        values: "<"
    }
});

FilterController.$inject = ["$window", "$element", "$timeout", "_"];

export function FilterController($window, $element, $timeout, _) {
    var ctrl = this;

    ctrl.id = _.uniqueId();
    ctrl.descending = null;
    ctrl.search = null;
    ctrl.filterVisible = false;

    ctrl.$postLink = $postLink;
    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;
    ctrl.toggleFilter = toggleFilter;
    ctrl.onSort = onSort;
    ctrl.onFilter = onFilter;
    ctrl.onReset = onReset;

    function $postLink() {
        angular.element($window).on("mouseup.k2Filter" + ctrl.id, function (e) {
            if (!ctrl.filterVisible) return;
            var blacklist = $element.find(".k2-filter-menu, .k2-filter-toggle, .uib-datepicker-popup");
            if (!blacklist.is(e.target) && blacklist.has(e.target).length === 0) {
                $timeout(function () {
                    hideFilter();
                });
            }
        });
    }

    function $onInit() {
        ctrl.type = getType();
        ctrl.isExact = ctrl.exact !== undefined;

        ctrl.prev = mapFilter();
        ctrl.group.addFilter(ctrl);
    }

    function $onDestroy() {
        angular.element($window).off("mouseup.k2Filter" + ctrl.id);
    }

    function toggleFilter() {
        ctrl.filterVisible = !ctrl.filterVisible;
    }

    function onSort() {
        if (ctrl.descending === null) ctrl.descending = true;
        else if (ctrl.descending) ctrl.descending = false;
        else ctrl.descending = null;

        onFilterChanged();
        hideFilter();
    }

    function onFilter() {
        onFilterChanged();
        hideFilter();
    }

    function onReset(options) {
        ctrl.descending = null;
        ctrl.search = null;

        if (options && options.emit) onFilterChanged();
        else ctrl.prev = mapFilter();

        hideFilter();
    }

    function onFilterChanged() {
        var next = mapFilter();

        if (!isEqual(ctrl.prev, next)) ctrl.group.filterChanged(next);

        ctrl.prev = next;
    }

    function isEqual(prev, next) {
        return prev.prop === next.prop && prev.descending === next.descending && prev.search === next.search;
    }

    function mapFilter() {
        let search;
        if (ctrl.prop && ctrl.prop === "date" && ctrl.search) {
            var offset = moment().utcOffset();
            search = moment(ctrl.search).utcOffset(offset).format();
        }
        return {
            prop: ctrl.prop,
            descending: ctrl.descending,
            search: search || ctrl.search
        };
    }

    function hideFilter() {
        ctrl.filterVisible = false;
    }

    function getType() {
        if (ctrl.date !== undefined) return "date";
        else if (ctrl.values && ctrl.values.length) return "select";
        else return "text";
    }
}
