<li id="org-nav">
    <div class="dropdown">
        <button
            class="btn btn-default dropdown-toggle"
            type="button"
            id="current-org-btn"
            data-toggle="dropdown">
            <span class="mr-1 fa fa-building"></span>
            <span id="menu-org-name">
                {{ organizationName() }}
            </span>
            <span class="caret more-dropdown"></span>
        </button>
        <ul
            #orgToggleDropdown
            class="dropdown-menu">
            <li
                class="form-group"
                style="margin-bottom: 0">
                <input
                    #orgToggleSearch
                    class="form-control"
                    type="text"
                    placeholder="Search..."
                    [(ngModel)]="search" />
            </li>
            @for (org of filteredOrganizations(); track org) {
                <li [class.disabled]="!org.isActivated && org.role == 'User'">
                    <a
                        href
                        (click)="changeOrganization($event, org.id)">
                        {{ org.name }}
                    </a>
                </li>
            }
        </ul>
    </div>
</li>
