import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "a[kno2NoOpLink]",
    standalone: true,
    host: {
        "[attr.href]": "''"
    }
})
export class NoOpLink {
    constructor() {}

    @HostListener("click", ["$event"])
    public onClick(event: MouseEvent): void {
        event.preventDefault();
    }
}
