(function () {
    "use strict";

    angular.module("kno2.records").filter("stripUrnOid", stripUrnOidFilter);

    stripUrnOidFilter.$inject = [];

    function stripUrnOidFilter() {
        return function (value) {
            if (!value || !angular.isString(value)) return value;
            return value.replace("urn:oid:", "");
        };
    }
})();
