import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";

export interface OrganizationFeature {
    id: string;
    name: string;
    enabled: boolean;
}

@Injectable({
    providedIn: "root"
})
export class OrganizationFeaturesService {
    private readonly httpClient = inject(HttpClient);
    private readonly baseUrl = "/api/features";

    public getFeatures = (): Promise<OrganizationFeature[]> => firstValueFrom(this.getFeatures$());
    public getFeatureDto = (featureId: string): Promise<OrganizationFeature> => firstValueFrom(this.getFeatureDto$(featureId));
    public saveFeature = (feature: OrganizationFeature): Promise<void> => firstValueFrom(this.saveFeature$(feature));
    public deleteFeature = (id: string): Promise<void> => firstValueFrom(this.deleteFeature$(id));

    private getFeatures$(): Observable<OrganizationFeature[]> {
        return this.httpClient.get<OrganizationFeature[]>(`${this.baseUrl}`);
    }

    private getFeatureDto$(featureId: string): Observable<OrganizationFeature> {
        return this.httpClient.get<OrganizationFeature>(`${this.baseUrl}/${featureId}`);
    }

    private saveFeature$(feature: OrganizationFeature): Observable<void> {
        return this.httpClient.put<void>(`${this.baseUrl}`, feature);
    }

    private deleteFeature$(id: string): Observable<void> {
        return this.httpClient.put<void>(`${this.baseUrl}/${id}/delete`, {});
    }
}
