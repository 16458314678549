import templateUrl from "./service-account-edit.component.html";

export const serviceAccountEditComponent = {
    templateUrl: templateUrl,
    controller: ServiceAccountEditController
};

ServiceAccountEditController.$inject = ["$stateParams"];

function ServiceAccountEditController($stateParams) {
    var ctrl = this;
    ctrl.$onInit = onInit;

    function onInit() {
        ctrl.group = {};
        if ($stateParams.id) {
            ctrl.group.id = $stateParams.id;
        }
    }
}
