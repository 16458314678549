import { Injectable, Signal, signal } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class SidebarService {
    private collapsedState = signal(false);
    private _collapsed = signal(this.collapsedState());
    public get collapsed(): Signal<boolean> {
        return this._collapsed;
    }

    public toggleSidebar(): void {
        this.collapsedState.update((value) => !value);
        this._collapsed.update(() => this.collapsedState());
    }

    public collapseSidebar(): void {
        this._collapsed.update(() => true);
    }

    public restoreState(): void {
        this._collapsed.update(() => this.collapsedState());
    }
}
