export function docStatusDirective() {
    var PROCESSED_CLASS = "intake-doc-status-success";
    var URGENT_CLASS = "intake-doc-status-important";
    var DEFAULT_CLASS = "intake-doc-status-default";

    return {
        restrict: "A",
        scope: {
            document: "=document"
        },
        link: function (scope, element, attrs, ctrl) {
            var urgent = scope.document.isUrgent;
            var processed = scope.document.isProcessed;
            var css = urgent ? URGENT_CLASS : DEFAULT_CLASS;
            var text = urgent ? "Urgent" : "Default";
            if (processed) {
                css = PROCESSED_CLASS;
                text = "Processed";
            }
            element.addClass(css);
            element.text(text);
        }
    };
}

export function k2DropdownDirective() {
    return {
        restrict: "E",
        require: "ngModel",
        scope: {
            id: "@",
            model: "=ngModel"
        },

        // Model example
        //$scope.k2Dropdown = {};
        //$scope.k2Dropdown.displayText = "Include:";
        //$scope.k2Dropdown.options = [
        //{ text: "Message Body", checked: false },
        //{ text: "Selected Attachments", checked: true }
        //];

        template:
            "<div>" +
            '<div class="k2-dropdown" ng-mouseleave="mouseleave()">' +
            '<div class="k2-select">' +
            '<span class="selected">{{model.displayText}}</span>' +
            '<span class="btn" ng-click="k2DropdownClick()" ng-show="model.options"><i class="fa fa-caret-down"></i></span>' +
            "</div>" +
            '<div class="k2-options" >' +
            '<label for="option-{{$index}}" ng-repeat="option in model.options">' +
            '<input ng-show="option.checked != undefined" type="checkbox" id="option-{{$index}}" ' +
            '                           name="option-{{$index}}" value="{{option.text}}" ng-model="option.checked" ng-change="option.onChange(option)">' +
            '<span ng-show="option.checked != undefined" class="check-text">{{option.text}}</span>' +
            '<span ng-show="option.checked == undefined">{{option.text}}</span>' +
            "</label>" +
            "</div>" +
            "</div>" +
            "</div>",
        link: function (scope, element, attrs, ctrl) {
            // save the jquery location string
            var selector = "#" + scope.id + " .k2-dropdown .k2-options";

            scope.k2DropdownClick = function () {
                // if k2-options is not visible, show it
                angular.element(selector).toggle();
            };

            scope.mouseleave = function () {
                // if there are checkboxes with these options, set them all to false.
                var hasChecked = _.find(scope.model.options, function (o) {
                    return _.has(o, "checked");
                });
                if (hasChecked) {
                    _.each(scope.model.options, function (o) {
                        o.checked = false;
                    });

                    // figure out which options are selected (checked)?
                    if (angular.element(selector + " input:checked").length > 0) {
                        // determine which ones are selected
                        var len = angular.element(selector + " input:checked").length;

                        for (var i = 0; i < len; i++) {
                            _.find(scope.model.options, function (o) {
                                return o.text == angular.element(".k2-dropdown .k2-options input:checked")[i].value;
                            }).checked = true;
                        }
                    }
                }

                // If visible, hide it. Toggle does not work because
                // the options div may or may not be visible.
                if (angular.element(selector).is(":visible")) {
                    angular.element(selector).hide();
                }
            };
        }
    };
}
