import templateUrl from "./api-capture.component.html";
const _ = require("lodash");

import { MongoSortDirections } from "../../common/mongo/mongo-sort-directions";

export const apiCaptureComponent = {
    templateUrl: templateUrl,
    controller: ApiCaptureController
};

ApiCaptureController.$inject = ["ApiCaptureService", "_"];

export function ApiCaptureController(ApiCaptureService, _) {
    let ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.refreshData = refreshData;
    ctrl.dateChange = dateChange;
    ctrl.filterChange = filterChange;
    ctrl.previousPage = previousPage;
    ctrl.nextPage = nextPage;
    ctrl.toggleSort = toggleSort;
    ctrl.filterHeaders = filterHeaders;

    function onInit() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let tomorrow = new Date();
        tomorrow.setHours(23, 59, 59, 0);
        ctrl.search = {
            tenantId: "",
            networkId: "",
            statusCode: null,
            method: ""
        };
        ctrl.query = { start: today, end: tomorrow, search: "", pageNumber: 1, pageSize: 20, sortDir: "1" };

        refreshData();
    }

    function filterHeaders(headers) {
        let knownHeaders = [
            "host",
            "accept",
            "accept-encoding",
            "accept-language",
            "authorization",
            "origin",
            "referrer",
            "connection",
            "user-agent",
            "content-length",
            "content-type"
        ];
        var result = _.pickBy(headers, function (value, key) {
            return _.includes(knownHeaders, key.toLowerCase());
        });

        return result;
    }

    function refreshData() {
        ctrl.query.search = JSON.stringify(ctrl.search);
        ApiCaptureService.get(ctrl.query).then((data) => {
            _.each(data, (x) => {
                if (x.value.response && x.value.response.body) {
                    try {
                        x.value.response.body = JSON.parse(x.value.response.body);
                    } catch (e) {
                        /* catch a parse error and don't modify the body */
                    }
                }
                if (x.value.request && x.value.request.body) {
                    try {
                        x.value.request.body = JSON.parse(x.value.request.body);
                    } catch (e) {
                        /* catch a parse error and don't modify the body */
                    }
                }
            });
            ctrl.data = data;
            if (ctrl.query.pageNumber > 1 && data.length === 0) {
                ctrl.lastPage = ctrl.query.pageNumber - 1;
                previousPage();
            }
        });
    }

    function filterChange() {
        ctrl.lastPage = undefined;
        ctrl.query.pageNumber = 1;
        refreshData();
    }

    function dateChange() {
        updateMinMax();
        filterChange();
    }

    function updateMinMax() {
        ctrl.startOptions = {
            maxDate: ctrl.query.end,
            timeZone: "+0"
        };

        ctrl.endOptions = {
            minDate: ctrl.query.start,
            timeZone: "+0"
        };
    }

    function previousPage() {
        if (ctrl.query.pageNumber > 1) {
            ctrl.query.pageNumber--;
            refreshData();
        }
    }

    function nextPage() {
        ctrl.query.pageNumber++;
        refreshData();
    }

    function toggleSort() {
        ctrl.query.sortDir = ctrl.query.sortDir === "1" ? "0" : "1";
        refreshData();
    }
}
