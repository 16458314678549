import templateUrl from "./admin-organizations-delete.modal.component.html";

export const adminOrganizationsDeleteModalComponent = {
    templateUrl,
    controller: AdminOrganizationsDeleteModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AdminOrganizationsDeleteModalController.$inject = ["AdminOrganizationsService", "NotificationService"];

export function AdminOrganizationsDeleteModalController(AdminOrganizationsService, NotificationService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.ok = ok;
    ctrl.cancel = cancel;

    function $onInit() {
        ctrl.organizationId = ctrl.resolve.orgId;

        getOrganizationDto(ctrl.organizationId);
    }

    function ok() {
        AdminOrganizationsService.deleteOrganization(ctrl.organization.id)
            .then(function (result) {
                ctrl.organization = result.data;
                ctrl.close(ctrl.organization);
                NotificationService.success("The organization was deleted successfully.");
            })
            .catch(function (response) {
                NotificationService.serverError(response.data.modelState, "There was an error deleting this organization.");
            });
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function getOrganizationDto(id) {
        AdminOrganizationsService.getOrganizationDto(id).then((x) => (ctrl.organization = x));
    }
}
