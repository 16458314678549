<div class="modal-header px-4">
    <button
        (click)="activeModal.close()"
        type="button"
        class="close"
        aria-hidden="true">
        &times;
    </button>
    <div class="modal-title">
        <h2>Configure Delegates for {{ directoryEntry().name | titlecase }}</h2>
        <h5>Kno2 Organization ID: {{ directoryEntry().kno2OrganizationId || "N/A" }}</h5>
    </div>
</div>
<div class="modal-body px-4">
    <div class="p-fluid d-flex">
        <p-autoComplete
            class="flex-grow-1 mr-2"
            [(ngModel)]="doaSelectedResult"
            [suggestions]="doaFilteredSearchResults()"
            (completeMethod)="searchForDelegates($event.query)"
            forceSelection="true"
            placeholder="Search for a delegate..."
            optionLabel="id"
            minLength="3">
            <ng-template
                let-delegate
                pTemplate="item">
                <div class="d-flex flex-column">
                    <div>
                        <strong>ID:</strong>
                        {{ delegate.id }}
                    </div>
                    <div style="font-size: 0.85rem">
                        <strong style="font-size: inherit">Name:</strong>
                        {{ delegate.name }},
                        <strong style="font-size: inherit">QHIN:</strong>
                        {{ getQhinName(delegate.rootId) }}
                    </div>
                </div>
            </ng-template>
        </p-autoComplete>
        <button
            class="btn btn-primary"
            [disabled]="!doaSelectedResult"
            (click)="addDelegate(doaSelectedResult)">
            <span>Add</span>
        </button>
    </div>
    <p-table
        class="p-fluid"
        [value]="delegates()"
        tableStyleClass="table table-striped table-bordered bootstrap-datatable datatable actions-table">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Directory OID</th>
                <th>Entity Type</th>
                <th>Parent Directory OID</th>
                <th>Status</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-delegate>
            <tr>
                <td>{{ delegate.name }}</td>
                <td>{{ delegate.id }}</td>
                <td>{{ delegate.directoryEntryType | titlecase }}</td>
                <td>{{ delegate.parentId }}</td>
                <td class="text-center">
                    <i
                        [style.color]="delegate.isActive ? 'green' : 'red'"
                        class="fa fa-circle"></i>
                </td>
                <td>
                    <a
                        kno2NoOpLink
                        (click)="removeDelegate(delegate)">
                        <i class="fa fa-trash"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div class="modal-footer px-4">
    <button
        (click)="activeModal.close()"
        type="button"
        class="btn btn-default">
        Close
    </button>
</div>
