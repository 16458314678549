(function () {
    "use strict";

    angular.module("kno2.factories").factory("ApiInterceptorFactory", ["appConfig", apiInterceptorFactory]);

    function apiInterceptorFactory(appConfig) {
        return {
            request: (config) => {
                if (config.skipInterceptors) return config;
                if (_.startsWith(config.url, "/api") || _.includes(config.url, "/odata")) {
                    config.url = `${appConfig.baseApiUrl}${config.url}`;
                }
                return config;
            }
        };
    }
})();
