import templateUrl from "./query.patient.component.html";

angular.module("kno2.records").component("k2QueryPatient", {
    controller: QueryPatientController,
    templateUrl,
    require: {
        wizard: "^^k2QueryWizard"
    }
});

QueryPatientController.$inject = ["$state", "QueryService", "CommonData", "NotificationService", "_", "moment"];

function QueryPatientController($state, QueryService, CommonData, NotificationService, _, moment) {
    var ctrl = this;

    ctrl.$onInit = $onInit;

    function $onInit() {
        ctrl.patient = ctrl.wizard.query.patient;
        ctrl.selectedOrganizations = ctrl.wizard.selectedOrganizations;
        ctrl.backRef = ctrl.wizard.organizations ? "records.query.wizard.organizations" : "records.query.wizard";
        ctrl.search = search;
        ctrl.gatherErrors = gatherErrors;
        ctrl.wizard.submit = search;
        ctrl.states = CommonData.states.asArray();
        ctrl.datepickerOptions = { max: new Date() };
    }

    function gatherErrors() {
        let errorList = [];

        if (!ctrl.patient.firstName) errorList.push("First name is required.");
        if (!ctrl.patient.lastName) errorList.push("Last name is required.");
        if (!ctrl.patient.birthDate) errorList.push("Date of Birth is required.");
        if (!ctrl.patient.gender) errorList.push("Gender is required.");

        return errorList;
    }

    function search() {
        if (ctrl.patientDetailsForm.$invalid) {
            NotificationService.errors(gatherErrors(), "There was an error querying patients. Please include the following information.");
            return;
        }
        ctrl.searching = true;

        const requester = ctrl.wizard.query.requester;
        const query = angular.extend(
            {
                organizationIds: _.map(ctrl.selectedOrganizations, (x) => x.orgId),
                requester: {
                    roleCode: requester.role.code,
                    purposeOfUseCode: requester.purpose.code
                }
            },
            ctrl.patient
        );

        if (query.birthDate) query.birthDate = moment(query.birthDate).format("MMDDYYYY");

        QueryService.queryPatients(query)
            .then((patientsByFacility) => (ctrl.wizard.patients = patientsByFacility))
            .then(() => $state.go("records.query.wizard.documents"))
            .catch(() => NotificationService.error("There was an error querying patients. Please try again."))
            .then(() => (ctrl.searching = false));
    }
}
