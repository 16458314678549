import accountDashboardTemplate from "./dashboard/account.dashboard.html";
import accountNotificationsTemplate from "./notifications/account.notifications.html";

accountRoutes.$inject = ["$stateProvider"];
export function accountRoutes($stateProvider) {
    $stateProvider
        .state("accountDashboard", {
            url: "/account/dashboard",
            parent: "home",
            templateUrl: accountDashboardTemplate,
            controller: "DashboardCtrl",
            data: { roles: ["Administrator"] }
        })
        .state("accountNotifications", {
            url: "/account/notifications?selectedTab",
            parent: "home",
            templateUrl: accountNotificationsTemplate,
            controller: "NotificationsCtrl",
            data: {
                roles: ["Administrator", "User"]
            }
        });
}
