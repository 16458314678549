const templateUrl = require("./jump-pager.component.html");
import * as _ from "lodash";

class JumpPagerController {
    public static $inject: Array<string> = ["$timeout"];

    private $timeout;
    public pagerCurrentPage;
    public pagerTotalItems;
    public pagerItemsPerPage;

    public pagerChange;

    constructor($timeout) {
        this.$timeout = $timeout;
    }

    $onInit() {
        this.pagerCurrentPage = Number(this.pagerCurrentPage);
    }

    goToPage() {
        const max = Math.ceil(this.pagerTotalItems / this.pagerItemsPerPage);

        if (this.pagerCurrentPage <= 0 || isNaN(this.pagerCurrentPage)) this.pagerCurrentPage = 1;
        else if (this.pagerCurrentPage > max) this.pagerCurrentPage = max;
        else if (!this.pagerCurrentPage) return;

        this.$timeout(() => this.pagerChange(), 0);
    }
}

JumpPagerController.$inject = ["$timeout"];
export const jumpPagerComponent = {
    templateUrl,
    controller: JumpPagerController,
    bindings: {
        pagerPlaceholder: "@?",
        pagerCurrentPage: "=",
        pagerItemsPerPage: "=",
        pagerTotalItems: "=",
        pagerChange: "&"
    }
};
