(function (undefined) {
    "use strict";

    angular.module("kno2.filters").filter("fax", yesNoFilter);

    yesNoFilter.$inject = [];

    var pattern = /^[0-9]{10}$/;

    function yesNoFilter() {
        return function (value) {
            var fax = $.trim(value).replace(/[^\d]*/g, "");
            return pattern.test(fax) ? formatFax(fax) : "";
        };
    }

    function formatFax(telephone) {
        var areaCode = telephone.slice(0, 3);
        var number = telephone.slice(3, 6) + "-" + telephone.slice(6);
        return areaCode + "-" + number;
    }
})();
