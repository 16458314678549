angular
    .module("kno2.release")
    .controller("DeleteDraftCtrl", [
        "$uibModalInstance",
        "draftId",
        "ReleaseService",
        "NotificationService",
        function ($uibModalInstance, draftId, ReleaseService, NotificationService) {
            const ctrl = this;
            ctrl.ok = function () {
                var promise = ReleaseService.deleteDraft(draftId);
                promise.then(function () {
                    $uibModalInstance.close();
                    NotificationService.success("The draft was deleted successfully.");
                });
            };

            ctrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };
        }
    ])
    .controller("DeleteReleaseModalCtrl", [
        "$uibModalInstance",
        "eventMessage",
        "MessageService",
        "NotificationService",
        function ($uibModalInstance, eventMessage, MessageService, NotificationService) {
            const ctrl = this;
            ctrl.message = eventMessage;

            ctrl.closeModal = function () {
                $uibModalInstance.close(ctrl.eventMessage);
            };

            ctrl.deleteMessage = function () {
                MessageService.delete(ctrl.message.id).then(
                    function (data) {
                        NotificationService.success("Your message is being deleted");
                        $uibModalInstance.close(ctrl.message);
                    },
                    function (res) {
                        NotificationService.error({
                            messages: [res.data.message]
                        });
                    }
                );
            };
        }
    ]);
