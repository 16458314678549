angular.module("kno2.settings").controller("SettingsLicensesAttachCtrl", SettingsLicensesAttachCtrl);

SettingsLicensesAttachCtrl.$inject = ["$uibModalInstance", "NotificationService", "LicensesService", "connector", "licenses"];
function SettingsLicensesAttachCtrl($uibModalInstance, NotificationService, LicensesService, connector, licenses) {
    this.connector = connector;
    this.license = {};
    this.licenses = _.filter(
        licenses,
        function (lic) {
            var appNames = _.map(lic.licenseType.apps, function (app) {
                return app.name;
            });
            return _.includes(appNames, this.connector.applicationName);
        }.bind(this)
    ).map(
        function (lic) {
            var key = lic.licenseKey;
            var typeName = lic.licenseType.name;
            key = "..." + key.substring(key.length - 4);
            var displayName = key + " - " + typeName;
            return _.extend(lic, { displayName: displayName });
        }.bind(this)
    );

    this.saveChanges = function () {
        LicensesService.attachLicense(this.connector, this.license.licenseKey).then(function () {
            $uibModalInstance.close();
        });
    };
    this.dismissModal = $uibModalInstance.dismiss;
}
