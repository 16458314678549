import templateUrl from "./clientapp.component.html";

export const clientApplicationComponent = {
    templateUrl: templateUrl,
    controller: ClientApplicationController,
    require: {
        grid: "^k2ClientApplicationsGrid"
    },
    bindings: {
        application: "<"
    }
};

ClientApplicationController.$inject = ["ClientApplicationService", "NotificationService", "$state", "$confirm"];

export function ClientApplicationController(ClientApplicationService, NotificationService, $state, $confirm) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.save = save;
    ctrl.cancel = cancel;
    ctrl.getCredentials = getCredentials;
    ctrl.clientSecretClipboard = false;
    ctrl.showClipboardCapture = showClipboardCapture;
    ctrl.generateCredentials = generateCredentials;

    function $onInit() {
        ctrl.editing = !!ctrl.application;
        ctrl.application = ctrl.application || { clientId: "", secret: "" };
    }

    function save() {
        let method = "addApplication";
        if (ctrl.editing) method = "updateApplication";
        return ClientApplicationService[method](ctrl.application)
            .then(function (data) {
                ctrl.application = data;
                ctrl.clientSecretClipboard = true;
                let verb = "add";
                if (ctrl.editing) verb = "updat";
                NotificationService.success(`Application ${verb}ed successfully, please copy client credentials now`);
            })
            .then(() => {
                ClientApplicationService.listApplications().then((applications) => {
                    ctrl.grid.applications = applications;
                });
            })
            .catch(function (e) {
                let verb = "add";
                if (ctrl.editing) verb = "updat";
                NotificationService.error("An error occured while " + verb + "ing the application. " + ((e && e.data && e.data.exceptionMessage) || ""));
            });
    }

    function cancel() {
        return $state.go("clientapplications");
    }

    function generateCredentials() {
        return $confirm
            .open({
                bodyText: "Are you sure you want to generate new credentials for " + ctrl.application.name + "? This will invalidate current usages."
            })
            .result.then(
                () => {
                    ClientApplicationService.generateCredentials(ctrl.application.id)
                        .then((response) => {
                            ctrl.application.clientId = response.clientId;
                            ctrl.application.secret = response.secret;
                            NotificationService.success("Updated client credentials, please copy them now");
                        })
                        .catch((e) => {
                            NotificationService.error(
                                "An error occurred while updating client credentials. " + ((e && e.data && e.data.exceptionMessage) || "")
                            );
                        });
                },
                () => {}
            );
    }

    function getCredentials() {
        return "Key: " + ctrl.application.clientId + "\r\nSecret: " + ctrl.application.secret + "\r\n";
    }

    function showClipboardCapture() {
        NotificationService.success("Client key and secret copied to your clipboard");
    }
}
