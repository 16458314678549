export class SettingsService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    public upsert(setting: object): Promise<any> {
        return this.$http.post("/api/settings", setting);
    }

    // refactor to use params in order
    public getAll(entityId: string, entityType: string): Promise<any> {
        return this.$http.get(`/api/settings/${entityType}/${entityId}`);
    }

    public getSettingTypes(entityType: string): Promise<any> {
        return this.$http.get(`/api/settings/${entityType}`);
    }
}
