import { AdminEmergenciesService } from "./admin.emergencies.service";

declare const angular;
declare const _;

let ctrl;
class AdminEmergenciesListController {
    public static $inject: Array<string> = ["AdminEmergenciesService", "NotificationService", "$uibModal"];

    public emergencies: Array<any>;

    constructor(
        private adminEmergenciesService: AdminEmergenciesService,
        private notificationService,
        private $uibModal
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.emergencies = [];

        this.loadEmergencies();
    }

    public $onChanges(changes): void {
        if (changes.emergencyAdded && changes.emergencyAdded.currentValue) {
            this.loadEmergencies();
        }
    }

    public mapWithTrim(organizations: Array<{ name: string }>): string {
        const length = 30;
        const val = organizations
            .map((o) => o.name)
            .toString()
            .replace(/,/g, ", ");
        return val.length > length ? val.substring(0, length) + "..." : val;
    }

    public map(organizations: Array<{ name: string }>): string {
        return organizations
            .map((o) => o.name)
            .toString()
            .replace(/,/g, "\n");
    }

    public disable(emergencyId: string): void {
        this.adminEmergenciesService.disable(emergencyId).then((response) => {
            this.loadEmergencies();
        }, this.notificationService.error("Error disabling emergency"));
    }

    public enable(emergencyId: string): void {
        this.adminEmergenciesService
            .enable(emergencyId)
            .then((response) => this.loadEmergencies())
            .catch(() => this.notificationService.error("Error enabling emergency"));
    }

    public edit(emergency: any): void {
        this.$uibModal
            .open({
                component: "k2AdminEmergenciesEditComponent",
                resolve: { data: () => emergency }
            })
            .result.then(this.loadEmergencies, angular.noop);
    }

    public loadEmergencies(): void {
        ctrl.adminEmergenciesService
            .get()
            .then((response) => {
                ctrl.emergencies.splice(0, ctrl.emergencies.length);
                const currentDate = new Date().getDate();

                _.forEach(response.data, (emergency) => {
                    emergency.isDisabled = false;
                    if (emergency.disabledDate) {
                        const scheduledDisableDate = new Date(emergency.disabledDate).getDate();
                        emergency.isDisabled = scheduledDisableDate <= currentDate;
                    }

                    ctrl.emergencies.push(emergency);
                });
                ctrl.resetItems();
            })
            .catch(() => {
                ctrl.notificationService.error("Error loading emergencies.");
                ctrl.resetItems();
            });
    }
}

export class AdminEmergenciesListComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./admin.emergencies-list.component.html");
        this.controller = AdminEmergenciesListController;
        this.bindings = {
            emergencyAdded: "<",
            resetItems: "&"
        };
    }
}
