// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/content/images/ajax-loader.gif");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"modal-header\"> <button type=\"button\" class=\"close\" ng-click=\"vm.dismiss()\"> &times; </button> <h2 class=\"modal-title\"> Orders <span style=\"margin-left:0\" ng-show=\"!vm.visitLoading\"> for visit with <i>{{vm.visit.provider}}</i> on <i>{{vm.visit.date | date}}</i> </span> <span style=\"margin-left:10px;font-size:.85em\" html-tip=\"OrderLookup\"></span> </h2> </div> <div class=\"modal-body lookup-modal\"> <div class=\"box visits\"> <div class=\"box-header\"> <h2>Select order(s)...</h2> </div> <div class=\"box-content\"> <div ng-if=\"vm.visitLoading\" class=\"pad-20 text-center\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> <div ng-if=\"!vm.orders.length && !vm.visitLoading\" class=\"pad-20 text-center\"> No orders available for this visit. </div> <div ng-if=\"vm.orders.length && !vm.visitLoading\"> <div class=\"lookup-header flex-row\"> <div class=\"col-sm-4\"> <h3>Order Date</h3> </div> <div class=\"col-sm-8\"> <h3>Description</h3> </div> </div> <div class=\"lookup-results\"> <div class=\"lookup-result flex-row\" ng-repeat=\"o in vm.orders\" ng-click=\"vm.toggleSelection(o)\" ng-class=\"{selected: vm.isSelected(o) }\"> <div class=\"col-sm-4\">{{o.date | date}}</div> <div class=\"col-sm-7\">{{o.description}}</div> <div class=\"col-sm-1\"> <i class=\"fa\" ng-class=\"vm.isSelected(o) ? 'fa-check-square-o': 'fa-square-o'\"></i> </div> </div> </div> </div> </div> </div> </div> <div class=\"modal-footer\"> <button type=\"button\" class=\"btn btn-default\" ng-click=\"vm.dismiss()\"> Cancel </button> <button type=\"button\" class=\"btn btn-danger\" ng-if=\"vm.previousSelected.length\" ng-click=\"vm.remove()\"> Remove </button> <button id=\"btnSelect\" class=\"btn btn-primary ladda-button\" ng-disabled=\"!vm.orderIds.length\" ng-click=\"vm.confirm()\"> <span class=\"ladda-label\">Select</span> <i class=\"fa fa-check\"></i> </button> </div> ";
// Exports
var _module_exports = code;;
var path = 'App/src/intake/message/intake.message.order-lookup-modal.html';
window.angular.module('kno2.templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;