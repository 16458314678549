import templateUrl from "./admin-audits.component.html";

export class AdminAuditsController {
    constructor(AdminAuditsService, IAuditService, _) {
        this.AdminAuditsService = AdminAuditsService;
        this.AuditService = IAuditService;
        this._ = _;
    }

    $onInit() {
        this.currentPage = 1;
        this.pageSize = 25;
        let today = new Date();
        today.setHours(0, 0, 0, 0, 0);
        this.filter = {
            start: today,
            end: today
        };
        this.updateMinMax();

        this.getEntryTypes();
        this.getAuditEntries();
    }

    getAuditEntries() {
        this.AdminAuditsService.get({
            startDate: this.filter.start,
            endDate: this.filter.end,
            username: this.filter.user
        }).then((res) => {
            this.entries = res;
            this.sliceResults();
        });
    }

    downloadAuditEntries() {
        if (!this.entries) getAuditEntries();

        if (this.entries.length === 0) return;

        const items = this.entries;
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(items[0]);
        const csv = [header.join(","), ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","))].join("\r\n");
        const from = moment(this.filter.start).format("MMDDYYYY");
        const to = moment(this.filter.end).format("MMDDYYYY");
        const fileName = `AuditEntries_${from}-${to}.csv`;
        const link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv));
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getEntryTypes() {
        this.AuditService.getEntryTypes().then((res) => {
            this.eventTypes = _.sortBy(res, "name");
        });
    }

    $doCheck() {
        if (this.entries && this.entries.length) {
            if (this.currentPage !== this.previousPage) {
                this.sliceResults();
                this.previousPage = this.currentPage;
            }

            if (this.sortDir !== this.previousSortDir) {
                this.sortChanged = true;
                this.sliceResults();
                this.previousSortDir = this.sortDir;
            }
        }
    }

    sliceResults() {
        this.filtered = this.entries;

        if (this.filter.eventType) this.filtered = this.entries.filter((x) => x.eventType === this.filter.eventType);
        if (this.filter.data) this.filtered = this.entries.filter((x) => x.eventData.toLowerCase().contains(this.filter.data.toLowerCase()));

        const pageStart = (this.currentPage - 1) * this.pageSize;
        let pageEnd = this.currentPage * this.pageSize;
        if (this.filtered && this.filtered.length < pageEnd) pageEnd = this.filtered.length;

        if (this.sortChanged) {
            this.filtered.sort(() => -1);
            this.sortChanged = false;
        }

        this.data = this.filtered.slice(pageStart, pageEnd);
    }

    toggleSort() {
        this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
    }

    dateChange() {
        this.updateMinMax();
        this.getAuditEntries();
    }

    updateMinMax() {
        this.startOptions = { maxDate: this.filter.end };
        this.endOptions = { minDate: this.filter.start };
    }
}

AdminAuditsController.$inject = ["AdminAuditsService", "IAuditService", "_"];

export const adminAuditsComponent = {
    templateUrl: templateUrl,
    controller: AdminAuditsController
};
