import templateUrl from "./integration-codegrants-list.component.html";

export class IntegrationCodeGrantsListController {
    constructor($uibModal, $confirm, AdminIntegrationCodeGrantsService, NotificationService) {
        this.$uibModal = $uibModal;
        this.$confirm = $confirm;
        this.AdminIntegrationCodeGrantsService = AdminIntegrationCodeGrantsService;
        this.NotificationService = NotificationService;
    }

    $onInit() {
        this.pageSize = 10;
        this.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        this.pagedItemRange = this.pagedItemRanges[0];
        this.currentPage = 1;
        this.getCodeGrants();
    }

    getCodeGrants() {
        return this.AdminIntegrationCodeGrantsService.getCodeGrants({
            pageNumber: this.currentPage,
            pageSize: this.pageSize,
            search: this.search || null
        }).then((x) => {
            this.codeGrants = x.items;
            this.totalItems = x.totalCount;
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        });
    }

    add() {
        return this.$uibModal
            .open({
                component: "k2IntegrationCodeGrantsAddEditModal",
                resolve: {
                    codeGrant: () => null
                }
            })
            .result.then(() => this.getCodeGrants());
    }

    edit(id) {
        return this.$uibModal
            .open({
                component: "k2IntegrationCodeGrantsAddEditModal",
                resolve: {
                    codeGrant: () => this.AdminIntegrationCodeGrantsService.getCodeGrant(id)
                }
            })
            .result.then(() => this.getCodeGrants());
    }

    editOrgs(id) {
        return this.$uibModal
            .open({
                component: "k2IntegrationCodeGrantsOrgsModal",
                resolve: {
                    codeGrant: () => this.AdminIntegrationCodeGrantsService.getCodeGrant(id)
                }
            })
            .result.then(
                () => this.getCodeGrants(),
                () => this.getCodeGrants()
            );
    }

    toggledIsDisabled(item) {
        const action = item.isDisabled ? "Enable" : "Disable";
        return this.$confirm
            .open({
                bodyText: `Are you sure you want to ${action.toLowerCase()} <strong>${item.name}</strong>?`,
                okText: action
            })
            .result.then(() => {
                this.AdminIntegrationCodeGrantsService.toggledIsDisabled(item.id).then(() => {
                    this.NotificationService.success(`Code grant ${action.toLowerCase()}d.`);
                    this.getCodeGrants();
                });
            });
    }
}

IntegrationCodeGrantsListController.$inject = ["$uibModal", "$confirm", "AdminIntegrationCodeGrantsService", "NotificationService"];

export const integrationCodeGrantsListComponent = {
    templateUrl,
    controller: IntegrationCodeGrantsListController
};
