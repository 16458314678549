ReactivateOrgModalCtrl.$inject = ["$scope", "$rootScope", "SessionService", "SupportService", "NotificationService"];
export function ReactivateOrgModalCtrl($scope, $rootScope, sessionService, supportService, notificationService) {
    $scope.hasSubmitted = false;
    $scope.sendRequest = function () {
        $scope.hasSubmitted = true;
        const { fullName, userName: emailAddress, organizationName } = sessionService.getProfile();
        supportService
            .submitTicket({
                area: "Other",
                emailAddress,
                fullName,
                issueText: `Organization reactivation request for ${organizationName}`
            })
            .then(() => {
                notificationService.success("Your organization reactivation request has been submitted.");
            })
            .catch(() => ($scope.hasSubmitted = false));
    };

    $scope.cancel = function () {
        const { orgIsCancelled, orgIsInGracePeriod } = sessionService.getProfile();
        if (!orgIsCancelled && orgIsInGracePeriod) {
            window.history.back();
        } else {
            $rootScope.logoff();
        }
    };
}
