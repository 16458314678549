<div class="modal-header">
    <h2 class="modal-title">Warning</h2>
</div>
<div class="modal-body">
    <div
        class="progress"
        style="height: 25px">
        <div
            class="progress-bar progress-bar-striped progress-bar-animated progress-bar-info"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemax="100"
            style="width: 100%">
            You'll be logged out in {{ countdown }} second(s).
        </div>
    </div>

    <div class="pull-right">
        <button
            class="btn btn-primary"
            (click)="close()"
            style="margin-top: 10px; margin-bottom: 30px">
            Continue Session
        </button>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
</div>
