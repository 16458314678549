import { IPromise, IHttpService } from "angular";

export interface NetworkIdentityProviderType {
    name: string;
    displayName: string;
}

export interface NetworkIdentityProviderConfiguration {
    providedConfigurationValues: NetworkIdentityProviderConfigurationValue[];
    requiredConfigurationValues: NetworkIdentityProviderExpectedConfigurationValue[];
}

export interface NetworkIdentityProviderConfigurationValue {
    displayName: string;
    value: string;
}

export interface NetworkIdentityProviderProvisioningConfiguration {
    propertyName: string;
    displayName: string;
}

export interface NetworkIdentityProviderExpectedConfigurationValue {
    propertyName: string;
    displayName: string;
}

export interface SaveNetworkIdentityProviderRequest {
    identityProviderTypeName: string;
    configuration: Record<string, string>;
    allowNonAdminsUsernamePasswordLogin: boolean;
}

export interface NetworkIdentityProvider {
    identityProviderTypeName: string;
    configuration: Record<string, string>;
    allowNonAdminsUsernamePasswordLogin: boolean;
    connectionName: string;
}

export interface NetworkIdentityProviderProvisioning {
    identityProviderTypeName: string;
    endpoint: string;
    token: string;
    lastUpdate: string;
    numberOfUsers: number;
    numberOfGroups: number;
}

export interface NetworkIdentityProviderProvisioningToken {
    token: string;
}

export class NetworkIdentityProviderService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http: IHttpService) {}

    public getSupportedTypes(): IPromise<NetworkIdentityProviderType[]> {
        return this.$http
            .get("/api/networks/identityprovider/supportedtypes")
            .then((response) => {
                return response.data as NetworkIdentityProviderType[];
            })
            .catch((_) => {
                throw new Error("Failed to get supported identity provider types.");
            });
    }

    public getConfigurationForIdentityProviderType(identityProviderTypeName: string): IPromise<NetworkIdentityProviderConfiguration> {
        if (!identityProviderTypeName) throw new Error("identityProviderTypeName is required");
        return this.$http
            .get(`/api/networks/identityprovider/supportedtypes/${identityProviderTypeName}/configuration`)
            .then((response) => {
                return response.data as NetworkIdentityProviderConfiguration;
            })
            .catch((_) => {
                throw new Error("Failed to get requested identity provider configuration.");
            });
    }

    public getProvisioningConfigurationForIdentityProviderType(identityProviderTypeName: string): IPromise<NetworkIdentityProviderProvisioningConfiguration[]> {
        if (!identityProviderTypeName) throw new Error("identityProviderTypeName is required");
        return this.$http
            .get(`/api/networks/identityprovider/supportedtypes/${identityProviderTypeName}/provisioning/configuration`)
            .then((response) => {
                return response.data as NetworkIdentityProviderProvisioningConfiguration[];
            })
            .catch((_) => {
                throw new Error("Failed to get requested identity provider configuration.");
            });
    }

    public getNetworkIdentityProvider(): IPromise<NetworkIdentityProvider> {
        return this.$http
            .get("/api/networks/identityprovider")
            .then((response) => {
                return !!response.data ? (response.data as NetworkIdentityProvider) : null;
            })
            .catch((_) => {
                throw new Error("Failed to get network identity provider configuration.");
            });
    }

    public saveNetworkIdentityProvider(identityProvider: SaveNetworkIdentityProviderRequest): IPromise<NetworkIdentityProvider> {
        if (!identityProvider) throw new Error("identityProvider is required");
        return this.$http
            .post("/api/networks/identityprovider", identityProvider)
            .then((response) => {
                return response.data as NetworkIdentityProvider;
            })
            .catch((error) => {
                if (error.status === 500) {
                    throw new Error("Something went wrong while saving the network identity provider configuration. Please try again or contact support.");
                }
                throw error;
            });
    }

    public deleteNetworkIdentityProvider(): IPromise<void> {
        return this.$http
            .delete("/api/networks/identityprovider")
            .then(() => {
                return;
            })
            .catch((error) => {
                if (error.status === 500) {
                    throw new Error("Something went wrong while saving the network identity provider configuration. Please try again or contact support.");
                }
                throw error;
            });
    }

    public getProvisioningInfo(): IPromise<NetworkIdentityProviderProvisioning> {
        return this.$http
            .get("/api/networks/identityprovider/provisioning")
            .then((response) => {
                return !!response.data ? (response.data as NetworkIdentityProviderProvisioning) : null;
            })
            .catch((_) => {
                throw new Error("Failed to get network identity provider provisioning.");
            });
    }

    public generateProvisioningToken(): IPromise<NetworkIdentityProviderProvisioningToken> {
        return this.$http
            .post("/api/networks/identityprovider/provisioning/token", {})
            .then((response) => {
                return response.data as NetworkIdentityProviderProvisioningToken;
            })
            .catch((error) => {
                if (error.status === 500) {
                    throw new Error("Something went wrong while generating the provisioning token. Please try again or contact support.");
                }
                throw error;
            });
    }

    public deleteProvisioningToken(): IPromise<void> {
        return this.$http
            .delete("/api/networks/identityprovider/provisioning/token")
            .then(() => {
                return;
            })
            .catch((error) => {
                if (error.status === 500) {
                    throw new Error("Something went wrong while revoking the provisioning token. Please try again or contact support.");
                }
                throw error;
            });
    }
}
