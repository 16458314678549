import templateUrl from "./admin-organizations.component.html";

export const adminOrganizationsComponent = {
    templateUrl,
    controller: AdminOrgnizationsController,
    bindings: {
        apiCaptureEnabled: "<"
    }
};

AdminOrgnizationsController.$inject = ["$state", "$timeout", "$uibModal", "$transitions", "AdminOrganizationsService", "PagingFactory", "NotificationService"];

export function AdminOrgnizationsController($state, $timeout, $uibModal, $transitions, AdminOrganizationsService, PagingFactory, NotificationService) {
    const ctrl = this;
    const removeOnReturnHook = $transitions.onSuccess({ to: "organizations" }, () => {
        getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
    });

    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;

    // Sort
    ctrl.sort = function () {
        ctrl.isSortDesc = !ctrl.isSortDesc;
        ctrl.sortIconClass = ctrl.isSortDesc ? "fa fa-chevron-down" : "fa fa-chevron-up";
        getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
    };

    // Paging
    ctrl.gotoPage = function (page) {
        if (page < 1) page = 1;
        else if (page > ctrl.maxPage) page = ctrl.maxPage;

        ctrl.currentPage = page;
        getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
    };

    // Update Paging Count
    ctrl.updatePagedItemCount = function () {
        ctrl.pagedItemCount = AdminOrganizationsService.getPagedItemsSelectValue();
        getOrganizationDtos(1, ctrl.pagedItemRange.value);
    };

    // Delete
    ctrl.deleteOrganization = function (organizationId) {
        var modalInstance = $uibModal.open({
            component: "k2AdminOrganizationsDeleteModal",
            resolve: {
                orgId: function () {
                    return organizationId;
                }
            }
        });

        modalInstance.result.then(
            function () {
                // After a successful save, update the ctrl.organization
                //ctrl.organization = organization;
                getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
            },
            function () {
                // do something with dissResult
            }
        );
    };

    // Activation
    ctrl.activateOrg = function (organizationId) {
        var modalInstance = $uibModal.open({
            component: "k2AdminOrganizationsActivateModal",
            resolve: {
                orgId: function () {
                    return organizationId;
                }
            }
        });

        modalInstance.result.then(
            function () {
                // After a successful save, update the ctrl.organization
                //ctrl.organization = organization;
                getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
            },
            function () {
                // do something with dissResult
            }
        );
    };

    ctrl.clearCancel = function (organizationId) {
        let modalInstance = $uibModal.open({
            component: "k2AdminOrganizationsClearCancelModal",
            resolve: {
                orgId: function () {
                    return organizationId;
                }
            }
        });

        modalInstance.result.then(
            function () {
                getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
            },
            function () {}
        );
    };

    // Enable or Disable Organization Login
    ctrl.enableDisableOrg = function (organizationId) {
        AdminOrganizationsService.enableDisableOrg(organizationId)
            .then(function () {
                NotificationService.success("Organization Login Ability has been updated.");
                getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
            })
            .catch(function () {
                NotificationService.error("There was an error updating the organization login ability.");
            });
    };

    ctrl.showPage = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalOrganizationsCount).visible[page - 1];
    };

    ctrl.isFirstPageRange = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalOrganizationsCount).isFirst;
    };

    ctrl.isLastPageRange = function (page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalOrganizationsCount).isLast;
    };

    ctrl.exportDirectAddresses = function () {
        AdminOrganizationsService.exportDirectAddresses();
    };

    ctrl.showCancellingSoon = function () {
        ctrl.cancelling = !ctrl.cancelling;
        getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value, ctrl.cancelling);
    };

    // Enable or Disable Organization API Capture
    ctrl.toggleApiCapture = function (organizationId) {
        AdminOrganizationsService.toggleApiCapture(organizationId)
            .then(function () {
                NotificationService.success("Organization API settings updated.");
                getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
            })
            .catch(function () {
                NotificationService.error("There was an error updating the organization API setting.");
            });
    };

    ctrl.syncOrganization = function (organizationId) {
        AdminOrganizationsService.syncOrganization(organizationId)
            .then(function () {
                NotificationService.success("Organization Intercom Sync has been initialized.");
                getOrganizationDtos(ctrl.currentPage, ctrl.pagedItemRange.value);
            })
            .catch(function () {
                NotificationService.error("There was an error trying to sync organization with Intercom.");
            });
    };

    // Search functionality
    // Instantiate these variables outside the watch
    var searchTimeout;
    ctrl.searchOrgs = function () {
        if (ctrl.search.name == undefined) return; // we want to allow the empty string but not undefined
        if (searchTimeout) $timeout.cancel(searchTimeout);

        searchTimeout = $timeout(function () {
            getOrganizationDtos(1, ctrl.pagedItemRange.value, ctrl.cancelling);
        }, 400);
    };

    // Helper method to get organizations data. This function will determine if there is a
    // search term to include or not.
    function getOrganizationDtos(page, pageSize, cancelling) {
        ctrl.currentPage = page;
        const searchTerm = ctrl.search != undefined ? ctrl.search.name : undefined;
        AdminOrganizationsService.getOrganizationDtos(ctrl.isSortDesc ? "desc" : "asc", page, pageSize, searchTerm, cancelling).then(function (data) {
            ctrl.organizations = data.items;
            ctrl.maxPage = Math.ceil(data.totalCount / ctrl.pagedItemRange.value);
            ctrl.totalOrganizationsCount = data.totalCount;
            ctrl.pages = _.range(1, ctrl.maxPage + 1);
        });
    }

    // Set up the controller initialization, dependencies are in order.
    function $onInit() {
        // On init, set these default values
        //ctrl.selectedTab = 1;
        ctrl.cancelling = false;
        ctrl.sortBy = "name";
        ctrl.isSortDesc = false;
        ctrl.sortIconClass = "fa fa-chevron-up";
        ctrl.currentPage = 1;
        ctrl.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
        ctrl.pagedItemRange = ctrl.pagedItemRanges[0];

        // Update the page with organizations data
        getOrganizationDtos(1, ctrl.pagedItemRange.value);
    }

    function $onDestroy() {
        removeOnReturnHook();
    }
}
