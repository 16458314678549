(function () {
    "use strict";

    angular.module("kno2.factories").factory("hubProxyFactory", hubProxyFactory);

    hubProxyFactory.$inject = ["$q", "_", "appConfig"];

    var services = {};

    function hubProxyFactory($q, _, appConfig) {
        const noop = function () {};
        const noopAsync = function () {
            return $q.resolve();
        };
        const noopService = {
            start: noopAsync,
            stop: noopAsync,
            invoke: noopAsync,
            on: noop,
            get qs() {
                return {};
            },
            set qs(value) {},
            get connection() {
                return {};
            },
            get proxy() {
                return {};
            }
        };

        return function (hubName) {
            if (!appConfig.signalRUrl) return noopService;

            var service = services[hubName];
            if (!service) {
                var connection = $.hubConnection(appConfig.signalRUrl, { useDefaultPath: false });
                var proxy = connection.createHubProxy(hubName);

                services[hubName] = service = {
                    connection: connection,
                    proxy: proxy
                };
            }

            return {
                start: start,
                stop: stop,
                invoke: invoke,
                on: on,
                get qs() {
                    return service.connection.qs;
                },
                set qs(value) {
                    _.extend(service.connection.qs, value);
                },
                get connection() {
                    return service.connection;
                },
                get proxy() {
                    return service.proxy;
                }
            };

            function start() {
                return $q(function (resolve, reject) {
                    service.connection.start().done(resolve).fail(reject);
                });
            }

            function stop() {
                return $q(function (resolve, reject) {
                    service.connection.stop().done(resolve).fail(reject);
                });
            }

            function invoke() {
                var args = arguments;
                return start().then(function () {
                    return $q(function (resolve, reject) {
                        service.proxy.invoke.apply(service.proxy, args).done(resolve).fail(reject);
                    });
                });
            }

            function on(event, func) {
                service.proxy.on(event, func);
            }
        };
    }
})();
