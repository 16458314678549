<div class="row">
    <div class="col-sm-12">
        <kno2-contacts-upload-card
            [isUploading]="isUploading"
            (onUploadFile)="uploadFile($event)"
            (onDownloadClick)="downloadContacts()"></kno2-contacts-upload-card>

        @if (currentUpload()) {
            <kno2-contacts-upload-success-card [uploadData]="currentUpload()"></kno2-contacts-upload-success-card>
        }

        @if (lastUpload()?.status === "Failure") {
            <kno2-contacts-upload-failure-card
                [uploadData]="lastUpload()"
                [isLoading]="isDownloadingErrorFile"
                (onDownloadClick)="downloadErrorFile()"></kno2-contacts-upload-failure-card>
        }
    </div>
</div>
