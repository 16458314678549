import templateUrl from "./admin-organization-types.component.html";

export const adminOrganizationTypesComponent = {
    templateUrl: templateUrl,
    controller: organizationTypesController
};

organizationTypesController.$inject = ["OrganizationTypesService", "NotificationService", "$timeout", "_", "PagingFactory", "$uibModal"];

function organizationTypesController(OrganizationTypesService, NotificationService, $timeout, _, PagingFactory, $uibModal) {
    const ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.search = search;
    ctrl.pageSize = 10;
    ctrl.searchTerm = null;
    ctrl.currentPage = 1;
    ctrl.gotoPage = gotoPage;
    ctrl.showPage = showPage;
    ctrl.edit = edit;
    ctrl.add = edit;
    ctrl.delete = deleteOrgType;
    ctrl.pagedItemRanges = [{ value: "10" }, { value: "25" }, { value: "50" }, { value: "100" }];
    ctrl.pagedItemRange = ctrl.pagedItemRanges[0];

    function onInit() {
        getData();
    }

    function gotoPage(pageNum) {
        if (pageNum === 0 || pageNum > ctrl.maxPage) return;
        ctrl.currentPage = pageNum;
        getData();
    }

    function showPage(page, currentPage) {
        return PagingFactory.getPageRange(page, currentPage, ctrl.pagedItemRange.value, ctrl.totalCount).visible[page - 1];
    }

    function edit(id) {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationTypesEditModal",
            resolve: {
                organizationTypeId: function () {
                    return id;
                }
            }
        });

        modalInstance.result.then(
            function () {
                // After a successful save, update the ctrl.organization
                //ctrl.organization = organization;
                getData();
            },
            function () {
                // do something with dissResult
            }
        );
    }

    function deleteOrgType(id) {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationTypesDeleteModal",
            resolve: {
                organizationTypeId: function () {
                    return id;
                }
            }
        });
        modalInstance.result.then(
            function () {
                getData();
            },
            function () {}
        );
    }

    function getData() {
        if (!ctrl.searchTerm) ctrl.searchTerm = null;
        OrganizationTypesService.get(ctrl.currentPage, ctrl.pagedItemRange.value, ctrl.searchTerm).then(
            function (response) {
                ctrl.organizationTypes = response.organizationTypes;
                ctrl.maxPage = Math.ceil(response.totalCount / ctrl.pagedItemRange.value);
                ctrl.pages = _.range(1, ctrl.maxPage + 1);
                ctrl.totalCount = response.totalCount;
            },
            function (error) {
                NotificationService.error("Error retrieving organization types.");
            }
        );
    }

    let searchTimeout;
    function search() {
        if (searchTimeout) $timeout.cancel(searchTimeout);

        searchTimeout = $timeout(function () {
            getData();
        }, 300);
    }
}
