import { AdminEmergenciesOrganizationsService } from "./admin.emergencies-organizations.service";

let ctrl;
class AdminEmergenciesOrganizationsEditController {
    public static $inject: Array<string> = ["AdminEmergenciesOrganizationsService", "NotificationService", "NotificationModalService", "$timeout"];

    public resolve;
    public close;
    public dismiss;

    public saving: boolean;
    public organization: any = {};
    public modalTitle: string;

    constructor(
        private adminEmergenciesOrganizationsService: AdminEmergenciesOrganizationsService,
        private notificationService,
        private notificationModalService,
        private $timeout
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.organization = this.resolve.data;
        this.modalTitle = this.organization ? "Update Organization" : "Add Organization";
    }

    public searchOrganizations(): void {
        this.organization.name = "";
        this.adminEmergenciesOrganizationsService
            .getOrganizationForAdmin(this.organization.oid)
            .then((res) => {
                if (res.data.value && res.data.value.displayName) {
                    this.organization.name = res.data.value.displayName;
                    this.notificationModalService.hideErrors();
                } else {
                    this.notificationModalService.warning("No organizations found");
                }
            })
            .catch((err) => this.notificationModalService.error("Error searching for organization"));
    }

    public save(): void {
        this.saving = true;
        this.adminEmergenciesOrganizationsService
            .upsertEmergencyOrganizations(this.organization)
            .then((res) => {
                this.notificationService.success("Organization saved.");
                this.$timeout(() => ctrl.close(), 0);
                this.saving = false;
            })
            .catch((error) => {
                this.notificationModalService.error(error.data.message);
                this.saving = false;
            });
    }
}

export class AdminEmergenciesOrganizationsEditComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./admin.emergencies.edit-organizations-modal.component.html");
        this.controller = AdminEmergenciesOrganizationsEditController;
        this.bindings = {
            resolve: "<",
            close: "&",
            dismiss: "&"
        };
    }
}
