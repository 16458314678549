<div
    *kno2FeatureToggle="['UserPreferencesAfterProcessMessage']"
    class="form-group row"
    style="margin-bottom: 0">
    <label class="col-sm-4 control-label col-form-label">After Process Message</label>
    <select
        [formControl]="preferencesForm().get('afterProcessMessage')"
        name="afterProcessMessage"
        class="form-control col-sm-6">
        <option value="DoNothing">Do Nothing</option>
        <option value="ReturnToIntake">Return to Intake</option>
    </select>
    <span
        kno2Popover
        class="ml-3"
        [attr.data-content]="afterProcessMessageTooltip">
        <i class="fa fa-info-circle box-info"></i>
    </span>
</div>
