import { IStateProvider } from "angular-ui-router";

identityProviderRoutes.$inject = ["$stateProvider"];

export default function identityProviderRoutes($stateProvider: IStateProvider) {
    $stateProvider
        .state("manageNetworkIdentityProvider", {
            abstract: true,
            url: "/settings/network/identityprovider",
            parent: "home",
            component: "manageNetworkIdentityProvider",
            data: { roles: ["NetworkAdmin"] }
        })
        .state("manageNetworkIdentityProvider.networkIdentityProvider", {
            url: "",
            component: "networkIdentityProvider",
            data: { roles: ["NetworkAdmin"] }
        })
        .state("manageNetworkIdentityProvider.networkIdentityProviderUserProvisioning", {
            url: "",
            component: "networkIdentityProviderUserProvisioning",
            data: { roles: ["NetworkAdmin"] }
        })
        .state("manageNetworkIdentityProvider.networkIdentityProviderUserGroups", {
            url: "",
            component: "networkIdentityProviderUserGroups",
            data: { roles: ["NetworkAdmin"] }
        });
}
