import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ShowForRoleInDirective } from "@kno2/common/directives";
import { AppConfigToken } from "@kno2/interop";

declare const HOST_NAME, BUILD_NUMBER;

@Component({
    selector: "kno2-footer",
    standalone: true,
    imports: [RouterLink, ShowForRoleInDirective],
    templateUrl: "./footer.component.html",
    styles: `
    footer .links {
        white-space: nowrap;
    }

    footer .links .sys-admin-link {
        padding: 0;
    }

    @media (max-width: 930px) {
        footer .light {
            display: none;
        }
    }`
})
export class FooterComponent {
    private readonly appConfig = inject(AppConfigToken);

    public hostName = HOST_NAME;
    public buildNumber = BUILD_NUMBER;
    public year = new Date().getFullYear();
    public aboutUsUrl: string;

    public ngOnInit(): void {
        this.aboutUsUrl = this.appConfig.aboutUsUrl;
    }
}
