IdentityDecisionModal.$inject = ["$scope", "_", "$uibModalInstance", "Upload", "NotificationService", "SessionService", "completion", "appConfig"];
export function IdentityDecisionModal($scope, _, $uibModalInstance, Upload, notificationService, sessionService, completion, appConfig) {
    $scope.title = "Identity Proofing Complete";
    $scope.brandDisplayName = appConfig.brandDisplayName;
    $scope.userProfile = sessionService.getProfile();
    $scope.completion = completion;
    $scope.passed = $scope.completion.decision == "Y";
    $scope.proofingUnderReview = !$scope.userProfile.orgIsIdProofed && $scope.userProfile.idProofedFileName;
    $scope.submitIdentityInProgress = false;
    $scope.completionResult = {
        passed: $scope.completion.decision == "Y"
    };

    if ($scope.passed) {
        $scope.title = `Congratulations! Start Using ${appConfig.brandDisplayName}`;
    } else {
        $scope.title = "Identity Verification Required: Step 2 of 2";
    }

    $scope.uploading = false;

    $scope.onFileSelect = function (files) {
        $scope.selectedFile = files[0];
    };

    $scope.closeModal = function () {
        $uibModalInstance.dismiss("cancel");
    };

    $scope.uploadId = function () {
        $scope.submitIdentityInProgress = true;
        $scope.form.$valid = true;
        if ($scope.form.$error.required) {
            _.each($scope.form.$error.required, function () {
                $scope.form.$valid = false;
            });
        }

        if ($scope.form.$valid) {
            $scope.uploading = true;
            $scope.upload = Upload.upload({
                url: "/api/equifax/manual",
                data: { file: $scope.selectedFile }
            })
                .then(function () {
                    $scope.uploading = false;
                    notificationService.success("Identification has been sent");
                    $uibModalInstance.close($scope.completion);
                })
                .catch(function (response) {
                    $scope.uploading = false;
                    notificationService.error(response.data.modelState.errors.join("<br/>"));
                })
                .finally(function () {
                    $scope.submitIdentityInProgress = false;
                });
        } else {
            $scope.submitIdentityInProgress = false;
        }
    };

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.finish = function () {
        $uibModalInstance.close($scope.completion);
    };
}
