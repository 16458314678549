import settingsOrganizationSignOutModalTemplate from "./settings.organization.sign-out-modal.html";

angular.module("kno2.settings").controller("OrganizationCtrl", [
    "_",
    "$http",
    "$uibModal",
    "SessionService",
    "OrganizationService",
    "NotificationService",
    "InteroperabilityService",
    "organization",
    "plan",
    "organizationSettings",
    "FeatureService",
    function (
        _,
        $http,
        $uibModal,
        SessionService,
        OrganizationService,
        NotificationService,
        InteroperabilityService,
        organization,
        plan,
        organizationSettings,
        FeatureService
    ) {
        const ctrl = this;
        const profile = SessionService.getProfile();

        ctrl.isMfaEnforced = FeatureService.isEnabled("MFARequired");
        ctrl.hasManagingExternalServiceAccount = profile.externalServiceAccountSettings.hasManagingExternalServiceAccount;
        ctrl.externalServiceAccountEntityName = profile.externalServiceAccountSettings.externalServiceAccountName;
        ctrl.$onInit = onInit;

        ctrl.minutesList = [1, 5, 10, 15, 20];
        ctrl.timeoutMinutes = profile.sessionTimeoutMinutes;
        // ML - Track if the user has changed Session Timeout.  There is no reason ''
        // to prompt the user to log out if Session timeout did not change
        ctrl.originalTimeoutMinutes = ctrl.timeoutMinutes;

        var getInteroperabilitySettings = function () {
            InteroperabilityService.get().then(function (data) {
                ctrl.interoperabilitySettings = data.data;
            });
        };

        var getOrganizationSettings = function () {
            OrganizationService.getOrganizationSettings()
                .then((settings) => {
                    ctrl.organization.requireWhitelistedIp = _.includes(settings.enabledSettings, organizationSettings.RequireWhitelistedIp);
                    ctrl.organization.ignoredAttachmentExtensions = {
                        xsl: _.includes(settings.ignoredAttachmentExtensions, "xsl")
                    };
                })
                .catch((err) => {
                    let message = "An error occurred when retrieving the organization settings.";
                    if (err.data && err.data.message) message = err.data.message;

                    NotificationService.errorToaster(message);
                });
        };

        var toggleRequireWhitelistedIp = function () {
            return OrganizationService.updateRequireWhitelistedIp(ctrl.organization.requireWhitelistedIp)
                .then((res) => (ctrl.organization.requireWhitelistedIp = res.data.requireWhitelistedIp))
                .catch((err) => {
                    NotificationService.errorToaster(err.data.message);
                });
        };

        var updateIgnoredAttachmentExtensions = function () {
            const ignoredAttachmentExtensions = Object.keys(ctrl.organization.ignoredAttachmentExtensions).filter(
                (key) => ctrl.organization.ignoredAttachmentExtensions[key]
            );
            return OrganizationService.updateIgnoredAttachmentExtensions(ignoredAttachmentExtensions)
                .then((res) => console.log(res))
                .catch((err) => {
                    NotificationService.errorToaster(err.data.message);
                });
        };

        var updateOrganizationSettings = () => {
            return OrganizationService.allowLegacyCompatibilityMode(ctrl.organization.settings.allowOrganizationLegacyLoginCompatibility)
                .then((res) => {
                    SessionService.refreshProfile();
                })
                .catch((err) => {
                    NotificationService.errorToaster(err.data.message);
                });
        };

        ctrl.organization = organization;
        ctrl.plan = plan;

        ctrl.save = function () {
            if (!ctrl.timeoutMinutes || ctrl.timeoutMinutes < 1 || ctrl.timeoutMinutes > 20) {
                //This will never hit with the dropdown, but keeping it for now.
                alert("Timeout must be between 1 and 20 minutes.");
                return false;
            } else {
                let data = { sessionTimeoutMinutes: ctrl.timeoutMinutes };

                // Mark as Processed
                InteroperabilityService.put(ctrl.interoperabilitySettings).then(
                    function () {},
                    function () {
                        NotificationService.errorToaster("An error occurred when saving Settings.");
                    }
                );

                if (!ctrl.isMfaEnforced) {
                    $http
                        .put("/api/settings/requiretwofactorauth", {
                            requireTwoFactorAuth: ctrl.organization.requireTwoFactorAuth
                        })
                        .then(
                            function (response) {
                                //success
                            },
                            function (error) {
                                NotificationService.errorToaster("An error occurred when saving Settings.");
                            }
                        );
                }

                toggleRequireWhitelistedIp();
                updateIgnoredAttachmentExtensions();
                updateOrganizationSettings();

                let promise = $http.put("/api/settings/sessiontimeout", data).then(function (response) {
                    //update profile data:
                    if (response.data.sessionTimeoutMinutes) {
                        NotificationService.success("Settings saved.");

                        // ML - If the user's session timeout has changed, prompt them to log out
                        // for the session settings to take
                        if (ctrl.originalTimeoutMinutes !== ctrl.timeoutMinutes) {
                            let success = function (result) {
                                if (result.status !== "200") {
                                    NotificationService.errorToaster("An error occurred when saving Settings.");
                                }
                            };

                            let error = function () {
                                ctrl.timeoutMinutes = profile.sessionTimeoutMinutes;
                            };

                            $uibModal
                                .open({
                                    templateUrl: settingsOrganizationSignOutModalTemplate,
                                    controller: "SignOutModalCtrl",
                                    resolve: {}
                                })
                                .result.then(success, error);
                        }
                    } else {
                        NotificationService.errorToaster("An error occurred when saving Settings.");
                    }

                    return response.data;
                });

                return promise;
            }
        };

        function onInit() {
            getInteroperabilitySettings();
            getOrganizationSettings();
        }
    }
]);
