import { TitleCasePipe } from "@angular/common";
import { Component, OnInit, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NoOpLink } from "@kno2/common/directives";
import {
    Address,
    AddressType,
    ContactType,
    DirectoryClient,
    DirectoryEntryQueryResult,
    DirectoryEntrySummaryResult,
    Governance,
    QhinMetadata,
    UpdateDirectoryEntryRequest
} from "@kno2/data-access/directory";
import { ConfirmationDialogService } from "@kno2/shared/confirmation-dialog";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";
import { AutoCompleteModule } from "primeng/autocomplete";
import { TableModule } from "primeng/table";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "admin-organizations-qhin-doa-modal",
    styles: "",
    standalone: true,
    imports: [TitleCasePipe, TableModule, FormsModule, AutoCompleteModule, NoOpLink],
    templateUrl: "./admin-organizations-qhin-doa-modal.component.html"
})
export default class AdminOrganizationsQhinDoaModalComponent implements OnInit {
    protected activeModal = inject(NgbActiveModal);

    private directoryClient = inject(DirectoryClient);
    private confirmationService = inject(ConfirmationDialogService);

    protected delegates = signal<DirectoryEntrySummaryResult[]>([]);
    private delegateIds = computed(() => this.delegates().map((m) => m.id));

    public directoryEntry = signal<DirectoryEntrySummaryResult>(null);
    public qhinDirectoryEntries = signal<QhinMetadata[]>([]);

    protected doaSelectedResult: DirectoryEntryQueryResult;
    private doaSearchResults = signal<DirectoryEntryQueryResult[]>([]);
    protected doaFilteredSearchResults = computed(() =>
        this.doaSearchResults().filter(
            (f) => !this.delegateIds().includes(f.id) && this.directoryEntry().id !== f.id && !this.qhinDirectoryEntries().find((q) => q.id === f.id)
        )
    );

    async ngOnInit() {
        await this.refreshDelegates();
    }

    public async searchForDelegates(query: string) {
        const isIdSearch = query.startsWith("rce:urn:oid");

        if (isIdSearch) {
            try {
                this.doaSearchResults.set([await firstValueFrom(this.directoryClient.directoryEntriesGET(query))]);
            } catch (e) {}
        } else {
            this.doaSearchResults.set(await firstValueFrom(this.directoryClient.search(true, { governance: Governance.Tefca, name: query })));
        }
    }

    public getQhinName(id: string) {
        return this.qhinDirectoryEntries().find((f) => f.id === id)?.name || "Unknown";
    }

    public async updateDelegates(delegates: string[]) {
        const directoryEntryDetails = await firstValueFrom(this.directoryClient.directoryEntriesGET(this.directoryEntry().id));
        const directoryEntryOrganization = directoryEntryDetails.contacts?.find((c) => c.contactType === ContactType.Organization) || {};
        const directoryEntryOrganizationAddress =
            directoryEntryOrganization.addresses?.find((c) => c.addressType === AddressType.OrganizationLocation) || ({} as Address);

        const updateRequest: UpdateDirectoryEntryRequest = {
            city: directoryEntryOrganizationAddress.city,
            country: directoryEntryOrganizationAddress.country,
            governance: directoryEntryDetails.governance,
            homeCommunityId: directoryEntryDetails.homeCommunityId,
            responderFilter: directoryEntryDetails.responderFilter,
            id: directoryEntryDetails.id,
            inboundMessageAddress: directoryEntryDetails.inboundMessageAddress,
            initiatorOnlyType: directoryEntryDetails.initiatorOnlyType,
            name: directoryEntryDetails.name,
            parentId: directoryEntryDetails.parentId,
            purposesOfUse: directoryEntryDetails.purposeOfUseCodes,
            source: directoryEntryDetails.source,
            state: directoryEntryOrganizationAddress.state,
            street1: directoryEntryOrganizationAddress.street1,
            street2: directoryEntryOrganizationAddress.street2,
            type: directoryEntryDetails.directoryEntryType,
            zipCode: directoryEntryOrganizationAddress.zipCode,
            delegates: delegates,
            isActive: directoryEntryDetails.isActive
        };

        await firstValueFrom(this.directoryClient.directoryEntriesPUT(directoryEntryDetails.id, updateRequest));
    }

    public async addDelegate(delegate: DirectoryEntryQueryResult) {
        const newDelegateSet = [...this.directoryEntry().delegates, delegate.id];

        const dialog = this.confirmationService.open({
            headerText: "Confirmation",
            bodyText: `Are you sure you want to add ${delegate.name} (${delegate.id}) as a delegate to ${this.directoryEntry().name} (${this.directoryEntry().id})?`,
            okText: "Continue",
            cancelText: "Cancel",
            confirmFn: () => this.updateDelegates(newDelegateSet)
        });

        await dialog.result.catch(noop);
        await this.refreshDelegates();
    }

    public async removeDelegate(delegate: DirectoryEntryQueryResult) {
        const newDelegateSet = this.directoryEntry().delegates.filter((f) => f !== delegate.id);

        const dialog = this.confirmationService.open({
            headerText: "Confirmation",
            bodyText: `Are you sure you want to remove ${delegate.name} (${delegate.id}) as a delegate to ${this.directoryEntry().name} (${this.directoryEntry().id})?`,
            okText: "Continue",
            cancelText: "Cancel",
            confirmFn: () => this.updateDelegates(newDelegateSet)
        });

        await dialog.result.catch(noop);
        await this.refreshDelegates();
    }

    private async refreshDelegates() {
        this.delegates.set(await firstValueFrom(this.directoryClient.delegates(this.directoryEntry().id, false)));
        this.directoryEntry.update((entry) => ({ ...entry, delegates: this.delegateIds() }));
        this.doaSelectedResult = null;
    }
}
