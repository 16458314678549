<div
    class="select-container"
    [class.has-error]="hasError()">
    <select
        class="form-control"
        [formControl]="control()">
        @if (includeDefaultOption()) {
            <option
                [ngValue]="null"
                [disabled]="!isDefaultOptionEnabled()"
                selected>
                {{ placeholder() }}
            </option>
        }

        @for (opt of options(); track opt[0]) {
            <option [value]="opt[1]">{{ opt[0] }}</option>
        }
    </select>
    <span class="caret"></span>
</div>
