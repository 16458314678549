angular.module("kno2.filters").filter("localDate", function () {
    return function (dateStr, format, notLocal) {
        if (!dateStr) return dateStr;
        const match = /\d\d:\d\d$/.test(dateStr);
        if (!dateStr.endsWith("Z") && !match) dateStr = dateStr + "Z";
        if (!format) format = "MMMM Do YYYY, h:mm:ss a";
        if (notLocal) return moment.utc(dateStr).format(format);
        return moment.utc(dateStr).local().format(format);
    };
});
