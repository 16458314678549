import triageBatchTemplate from "./triage.batch.html";
import triageMoveMenuTemplate from "./triage.move-menu.html";

triageBatchDirective.$inject = [];

export function triageBatchDirective() {
    return {
        restrict: "E",
        controller: "TriageBatchCtrl",
        controllerAs: "vm",
        templateUrl: triageBatchTemplate,
        scope: {
            collection: "="
        }
    };
}

triageMoveMenuDirective.$inject = [];

export function triageMoveMenuDirective() {
    return {
        restrict: "E",
        replace: true,
        templateUrl: triageMoveMenuTemplate,
        scope: {
            fromCollection: "=",
            moveToCollection: "&",
            moveToAttachment: "&",
            moveToMessageTitle: "="
        },
        link: function (scope, element, attrs) {
            scope.moveToAttachmentSet = !!attrs.moveToAttachment;
        },
        controller: [
            "$scope",
            "TriageServiceFactory",
            function ($scope, TriageServiceFactory) {
                var triageService = TriageServiceFactory();

                $scope.vm = {
                    fromCollection: $scope.fromCollection,
                    trash: triageService.trash,
                    unprocessed: triageService.unprocessed,
                    messages: triageService.messages,
                    moveToCollection: moveToCollection,
                    moveToAttachment: moveToAttachment,
                    createMessage: createMessage,
                    createAttachment: triageService.createAttachment,
                    moveToMessageTitle: $scope.moveToMessageTitle,
                    getRuleName: triageService.callbacks.getRuleName
                };

                function moveToCollection(collection) {
                    $scope.moveToCollection({
                        $collection: collection
                    });
                }

                function moveToAttachment(attachment) {
                    $scope.moveToAttachment({
                        $attachment: attachment
                    });
                }

                function createMessage() {
                    triageService.createMessage().then(function (message) {
                        moveToCollection(message.attachments);
                    });
                }
            }
        ]
    };
}
