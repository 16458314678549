export class AdminDocuSignService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    public getExternalLogin(): Promise<any> {
        return this.$http.get(`/api/connectors/externallogin`);
    }

    public getOrganizationAccounts(): Promise<any> {
        return this.$http.get(`/api/connectors/organization-accounts`);
    }

    public deleteOrganizationAccount(id): Promise<any> {
        return this.$http.delete(`/api/connectors/organization-accounts/${id}`);
    }
}
