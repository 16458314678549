import { IAppConfig } from "App/src/app-config/app.config";

export class AdminEmergenciesOrganizationsService {
    public static $inject: Array<string> = ["$http", "appConfig"];

    constructor(
        private $http,
        private appConfig: IAppConfig
    ) {}

    public getOrganizationForAdmin(oid: string): Promise<any> {
        return this.$http.post(`${this.appConfig.carequalityApi}/api/organization/admin/_search`, { orgId: oid });
    }

    public getEmergencyOrganizations(): Promise<any> {
        return this.$http.get(`${this.appConfig.carequalityApi}/api/emergencies/organizations`);
    }

    public getEmergencyOrganization(oid: string): Promise<any> {
        return this.$http.get(`${this.appConfig.carequalityApi}/api/emergencies/organizations/${oid}/`);
    }

    public upsertEmergencyOrganizations(org: any): Promise<void> {
        return this.$http.put(`${this.appConfig.carequalityApi}/api/emergencies/organizations`, org);
    }

    public deleteEmergencyOrganization(oid: string): Promise<void> {
        return this.$http.delete(`${this.appConfig.carequalityApi}/api/emergencies/organizations/${oid}/`);
    }
}
