import templateUrl from "./admin-organizations-npi.modal.component.html";

class AdminOrganizationsNpiDetailsModalComponentController {
    constructor($timeout, AdminOrganizationsService, NotificationModalService) {
        this.$timeout = $timeout;
        this.adminOrganizationsService = AdminOrganizationsService;
        this.notificationModalService = NotificationModalService;
    }

    $onInit() {
        this.isLoading = false;
        this.npi = null;
        this.addresses = null;
        this.npiNumber = this.resolve.npiNumber;

        this.$timeout(() => this.searchByNpiNumber(), 0);
    }

    searchByNpiNumber() {
        if (this.npiForm.$invalid || !this.npiNumber) return;

        this.notificationModalService.hideErrors();
        this.isLoading = true;
        this.npi = null;
        this.addresses = null;

        this.adminOrganizationsService
            .searchNpiByNumber(this.npiNumber)
            .then((response) => {
                this.isLoading = false;

                if (!response.data) return;

                if (response.data.number && response.data.number !== Number(this.npiNumber)) {
                    this.notificationModalService.warning(`Result does not match: Searched for ${this.npiNumber}. Result was ${response.data.number}`);
                }

                this.npi = response.data.basic;
                this.addresses = response.data.addresses;
                this.npi.fullName = [this.npi.authorizedOfficialFirstName, this.npi.authorizedOfficialMiddleName, this.npi.authorizedOfficialLastName]
                    .join(" ")
                    .trim()
                    .replace("  ", " ");
            })
            .catch((err) => {
                this.isLoading = false;
                this.notificationModalService.error(err.data.message);
            });
    }

    useNpiNumber() {
        this.close({ $value: this.npiNumber });
    }
}

AdminOrganizationsNpiDetailsModalComponentController.$inject = ["$timeout", "AdminOrganizationsService", "NotificationModalService"];
export const adminOrganizationsNpiDetailsModalComponent = {
    templateUrl: templateUrl,
    controller: AdminOrganizationsNpiDetailsModalComponentController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};
