(function () {
    var controller = function ($uibModalInstance, service, addon, title, id) {
        this.title = title;
        this.addon = addon || {};

        this.dismissModal = function () {
            $uibModalInstance.dismiss("cancel");
        };

        this.delete = function () {
            var promise = service.delete(id);
            promise.then(function () {
                $uibModalInstance.close();
            });
        };
    };

    controller.$inject = ["$uibModalInstance", "service", "addon", "title", "id"];
    angular.module("kno2.settings").controller("SettingsDevicesDeleteCtrl", controller);
})();
