import { CarePlanProcessingOptions } from "./care-plan.processing.options";

let ctrl;
class CarePlanController {
    public static $inject: Array<string> = ["OrganizationService", "NotificationService"];
    public conversationTypes: Array<string> = ["Order"];
    public carePlanProcessingOptions: CarePlanProcessingOptions;
    public existingSettings: Array<any> = [];
    public saving: boolean;

    constructor(
        private organizationService,
        private notificationService
    ) {}

    $onInit() {
        this.carePlanProcessingOptions = {
            None: "None",
            AwaitingEMRExport: "Processed and Awaiting Emr Export"
        };

        this.organizationService.getOrganizationSettings().then((data) => {
            this.existingSettings = data.automatedCarePlans || [];

            this.conversationTypes.forEach((type) => {
                if (!this.existingSettings || !this.existingSettings.find((x) => x.type === type)) {
                    this.existingSettings.push({
                        type: type,
                        action: null
                    });
                }
            });
        });
    }

    public save(): void {
        this.saving = true;
        this.organizationService
            .updateCarePlanActions(this.existingSettings)
            .then(() => {
                this.notificationService.success("Organization updated successfully");
                this.saving = false;
            })
            .catch((error) => {
                this.notificationService.error("An error occurred while updating Processing Preferences.", error.data.message);
                this.saving = false;
            });
    }
}

export class CarePlanComponent {
    public templateUrl: any;
    public controller: any;

    constructor() {
        this.templateUrl = require("./care-plan.component.html");
        this.controller = CarePlanController;
    }
}
