FaxAccountService.$inject = ["$http"];

export function FaxAccountService($http) {
    this.save = function (account) {
        return $http.post("/api/organizationaccounts", account).then(function (response) {
            return response.data;
        });
    };

    this.get = function () {
        return $http.get("/api/organizationaccounts").then(function (response) {
            return response.data;
        });
    };

    this.delete = function (account) {
        return $http.delete("/api/organizationaccounts").then(function (response) {
            return response.data;
        });
    };
}
