import { pickBy, keys } from "lodash";
import templateUrl from "./integrations.component.html";

export const integrationsComponent = {
    templateUrl: templateUrl,
    controller: IntegrationsController
};

IntegrationsController.$inject = ["$uibModal", "$confirm", "_", "IntegrationsService", "NotificationService", "$interval", "ExternalLoginService", "tooltips"];

export function IntegrationsController($uibModal, $confirm, _, IntegrationsService, NotificationService, $interval, ExternalLoginService, tooltips) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.addOrEditIntegration = addOrEditIntegration;
    ctrl.checkConnection = checkConnection;
    ctrl.deleteIntegration = deleteIntegration;
    ctrl.checkRequirementsToToggleEnabled = checkRequirementsToToggleEnabled;
    ctrl.displayCheckConnection = displayCheckConnection;

    function $onInit() {
        getIntegrations();
    }

    function getIntegrations() {
        IntegrationsService.listTypes().then((types) => {
            ctrl.types = types;
            IntegrationsService.list().then((integrations) => {
                ctrl.integrations = integrations.map((x) => ({
                    ...x,
                    capabilities: keys(pickBy(x.capabilities, (y) => y === true))
                }));
                var used = _.map(integrations, "type");
                ctrl.availableTypes = _.filter(types, function (t) {
                    return !_.includes(used, t.type) || t.allowMultiple;
                });
            });
        });
    }

    function addOrEditIntegration(id) {
        const modal = $uibModal.open({
            component: "k2IntegrationsUpsertModal",
            windowClass: "modal-800",
            resolve: {
                availableTypes: () => ctrl.availableTypes,
                types: () => ctrl.types,
                integration: () => {
                    if (!id) return null;
                    return IntegrationsService.getById(id).catch(() => {
                        NotificationService.errorToaster("Error opening integration");
                    });
                }
            }
        });

        modal.result.then(
            () => {
                getIntegrations();
            },
            () => {}
        );
    }

    function displayCheckConnection(x) {
        var type = _.find(ctrl.types, function (t) {
            return t.type === x.type;
        });
        return type && type.allowCheckForConnectivity;
    }

    function checkConnection(integration) {
        integration.checkingConnection = true;
        integration.connected = undefined;
        IntegrationsService.testConnectionById(integration.id)
            .then((connected) => (integration.connected = connected.isSuccess))
            .catch((err) => {
                integration.connected = false;
                NotificationService.serverError(err.data.modelState, err.data.exceptionMessage);
            })
            .finally(() => (integration.checkingConnection = false));
    }

    function toggleIntegrationEnabled(integration, isDisabled) {
        integration.isDisabled = !isDisabled;
        let capabilities = integration.capabilities;
        if (integration.capabilities instanceof Array)
            integration.capabilities = integration.capabilities.reduce((result, x) => {
                result[x] = true;
                return result;
            }, {});
        IntegrationsService.upsert(integration).then(
            function (res) {
                integration.capabilities = capabilities;
                const verbed = integration.isDisabled ? "disabled" : "enabled";
                NotificationService.success(`Successfully ${verbed} the ${integration.name} integration`);
            },
            function (err) {
                integration.isDisabled = !integration.isDisabled;
                NotificationService.error(err.data.message);
            }
        );
    }

    function loginRequired(integration) {
        return integration.requireExternalLoginToEnable || integration.values.AuthenticationType === "OAuth2AuthorizationCode";
    }

    function confirmationRequired(integration) {
        return integration.requiredConfirmationToEnable;
    }

    function checkRequirementsToToggleEnabled(integration, isDisabled) {
        if (isDisabled && loginRequired(integration)) {
            ExternalLoginService.externalLogin(integration, toggle(integration, isDisabled));
        } else if (isDisabled && confirmationRequired(integration)) {
            confirmEnableIntegration(integration, isDisabled);
        } else {
            toggleIntegrationEnabled(integration, isDisabled);
        }
    }

    function toggle(integration, isDisabled) {
        return function () {
            toggleIntegrationEnabled(integration, isDisabled);
        };
    }

    function confirmEnableIntegration(integration, isDisabled) {
        var modal = $confirm.open({
            title: "Enable Integration?",
            bodyText: tooltips[integration.requiredConfirmationToEnable]
        });

        modal.result.then(
            function (ok) {
                if (ok) {
                    toggleIntegrationEnabled(integration, isDisabled);
                }
            },
            function () {
                //cancelled
            }
        );
    }

    function deleteIntegration(integration) {
        var modal = $confirm.open({
            title: "Delete Integration",
            bodyText: "Are you sure you want to delete the integration?"
        });

        modal.result.then(function (ok) {
            if (ok) {
                IntegrationsService.delete(integration.id).then(function () {
                    getIntegrations();
                });
            }
        });
    }
}
