import templateUrl from "./admin-organizations-carequality-alternateoids.component.html";

export const adminOrganizationsCarequalityAlternateoidsComponent = {
    templateUrl,
    controller: AdminOrganizationsCarequalityAlternateoidsController
};

AdminOrganizationsCarequalityAlternateoidsController.$inject = ["$uibModal", "NotificationService", "AdminOrganizationsService", "$stateParams"];

export function AdminOrganizationsCarequalityAlternateoidsController($uibModal, NotificationService, AdminOrganizationsService, $stateParams) {
    const ctrl = this;

    ctrl.$onInit = onInit;

    ctrl.editAlternateOrganizationOid = editAlternateOrganizationOid;
    ctrl.createAlternateOrganizationOid = createAlternateOrganizationOid;
    ctrl.deleteAlternateOrganizationOid = deleteAlternateOrganizationOid;
    ctrl.toggleDefaultOrganizationOid = toggleDefaultOrganizationOid;

    function editAlternateOrganizationOid(oid) {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationsCarequalityEditModalComponent",
            resolve: {
                id: () => oid.id,
                organizationId: () => oid.organizationId,
                value: () => oid.value,
                isDefault: () => oid.isDefault
            }
        });

        modalInstance.result.then(
            () => {
                getOrganizationAlternateOids(ctrl.organizationId);
                NotificationService.success("The OID was updated successfully.");
            },
            () => {}
        );
    }

    function createAlternateOrganizationOid() {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationsCarequalityEditModalComponent",
            resolve: { organizationId: () => ctrl.organizationId }
        });

        modalInstance.result.then(
            () => {
                getOrganizationAlternateOids(ctrl.organizationId);
                NotificationService.success("The OID was added successfully.");
            },
            () => {}
        );
    }

    function deleteAlternateOrganizationOid(oid) {
        const modalInstance = $uibModal.open({
            component: "k2AdminOrganizationsCarequalityDeleteModalComponent",
            resolve: {
                id: () => oid.id,
                organizationId: () => oid.organizationId,
                value: () => oid.value
            }
        });

        modalInstance.result.then(
            () => {
                getOrganizationAlternateOids(ctrl.organizationId);
            },
            () => {}
        );
    }

    function toggleDefaultOrganizationOid(oid) {
        oid.isDefault = !oid.isDefault;
        AdminOrganizationsService.updateOrganizationAlternateOids(oid)
            .then(() => {
                getOrganizationAlternateOids(ctrl.organizationId);
                NotificationService.success("The OID was updated successfully.");
            })
            .catch((response) => {
                oid.isDefault = !oid.isDefault; // reset if failed
                NotificationService.serverError(response.data.modelState, "There was an error updating this OID.");
            });
    }

    function getOrganizationAlternateOids(orgId) {
        if (orgId) {
            return AdminOrganizationsService.getOrganizationAlternateOids(orgId).then((data) => {
                ctrl.alternateOids = data;
            });
        }
    }

    function onInit() {
        ctrl.organizationId = $stateParams.id;
        getOrganizationAlternateOids(ctrl.organizationId);
    }
}
