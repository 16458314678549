import { inject, Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@Injectable({
    providedIn: "root"
})
export class ConfirmationDialogService {
    private modalService = inject(NgbModal);

    public open(options: {
        headerText?: string;
        bodyText?: string;
        okText?: string;
        cancelText?: string;
        successNotificationText?: string;
        errorNotificationText?: string;
        windowClass?: string;
        confirmFn?: () => void;
        errFn?: (error: string) => void;
    }): NgbModalRef {
        const modal = this.modalService.open(ConfirmationDialogComponent, {
            windowClass: options.windowClass || "confirm-modal"
        });

        if (options.headerText) modal.componentInstance.headerText = options.headerText;
        if (options.bodyText) modal.componentInstance.bodyText = options.bodyText;
        if (options.okText) modal.componentInstance.okText = options.okText;
        if (options.cancelText) modal.componentInstance.cancelText = options.cancelText;
        if (options.successNotificationText) modal.componentInstance.successNotificationText = options.successNotificationText;
        if (options.errorNotificationText) modal.componentInstance.errorNotificationText = options.errorNotificationText;
        if (options.confirmFn) modal.componentInstance.confirmFn = options.confirmFn;

        return modal;
    }
}
