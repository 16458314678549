AdminServiceAccountService.$inject = ["$http"];

export function AdminServiceAccountService($http) {
    return {
        getServiceAccount: getServiceAccount,
        addOrUpdateServiceAccount: addOrUpdateServiceAccount,
        updateIpAddresses: updateIpAddresses,
        toggleStatus: toggleStatus,
        deleteServiceAccount: deleteServiceAccount,
        getServiceAccounts: getServiceAccounts
    };

    function getServiceAccount(clientId) {
        return $http.get("/api/admin/serviceaccounts/" + clientId).then(function (response) {
            return response.data;
        });
    }

    function addOrUpdateServiceAccount(clientId) {
        return $http.put("/api/admin/serviceaccounts/" + clientId).then(function (response) {
            return response.data;
        });
    }

    function updateIpAddresses(clientId, addresses) {
        return $http.put("/api/admin/serviceaccounts/" + clientId + "/ipaddresses", addresses).then(function (response) {
            return response.data;
        });
    }

    function toggleStatus(clientId, state) {
        return $http.put("/api/admin/serviceaccounts/" + clientId + "/" + state).then(function (response) {
            return response.data;
        });
    }

    function deleteServiceAccount(clientId) {
        return $http.delete("/api/admin/serviceaccounts/" + clientId);
    }

    function getServiceAccounts() {
        return $http.get("/api/admin/serviceaccounts").then(function (response) {
            return response.data;
        });
    }
}
