(function () {
    "use strict";

    angular.module("kno2.directives").directive("autoSubmit", autoSubmit);

    autoSubmit.$inject = ["$timeout"];

    function autoSubmit($timeout) {
        return {
            restrict: "A",
            replace: true,
            require: ["^form"],
            link: function (scope, element, attr, ctrl) {
                $timeout(function () {
                    element.triggerHandler("submit");
                });
            }
        };
    }
})();
