import { BrandSettingsService } from "../../common/services/brand-settings.service";

class BrandSettingsToggleDirectiveController {
    public static $inject: Array<string> = ["BrandSettingsService"];

    constructor(public BrandSettingsService: BrandSettingsService) {}
}

class BrandSettingsToggleDirective {
    public restrict: string;
    public controller: any;
    public ctr: any;

    constructor() {
        this.restrict = "A";
        this.controller = BrandSettingsToggleDirectiveController;
    }

    public async link(scope: any, element: any, attrs: any, ctr: any) {
        this.ctr = ctr;
        const enabled = await this.isEnabled(attrs.k2BrandSettingsToggle);
        if (!enabled) {
            element.remove();
        }
    }

    public async isEnabled(setting: string): Promise<Boolean> {
        return await this.ctr.BrandSettingsService.isEnabled(setting);
    }

    static directiveFactory() {
        return new BrandSettingsToggleDirective();
    }
}

export const brandSettingsToggleDirective = BrandSettingsToggleDirective.directiveFactory;
