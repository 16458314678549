import templateUrl from "./admin-organizations-activate.modal.component.html";

export const adminOrganizationsActivateModalComponent = {
    templateUrl,
    controller: AdminOrganiztionsActivateModalController,
    bindings: {
        resolve: "<",
        close: "&",
        dismiss: "&"
    }
};

AdminOrganiztionsActivateModalController.$inject = ["AdminOrganizationsService", "NotificationService", "CommonData"];

export function AdminOrganiztionsActivateModalController(AdminOrganizationsService, NotificationService, commonData) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.saveChanges = saveChanges;
    ctrl.cancel = cancel;

    function $onInit() {
        ctrl.organizationId = ctrl.resolve.orgId;
        ctrl.statuses = commonData.orgActivationStatuses.asArray();
        getOrganizationDto(ctrl.organizationId);
    }

    function saveChanges() {
        if (ctrl.form.$valid) {
            const activationRequest = {
                id: ctrl.organization.id,
                activated: ctrl.organization.isActivated,
                sendEmail: ctrl.organization.sendEmail,
                seedRecords: ctrl.organization.seedRecords
            };

            AdminOrganizationsService.activateOrg(activationRequest)
                .then(function () {
                    NotificationService.success("Organization Activation Status has been updated.");
                    ctrl.close();
                })
                .catch(function () {
                    NotificationService.error("There was an error updating the organization activation status.");
                });
        }
    }

    function cancel() {
        ctrl.dismiss({ $value: "cancel" });
    }

    function getOrganizationDto(id) {
        AdminOrganizationsService.getOrganizationDto(id).then((x) => {
            ctrl.organization = x;
            ctrl.organization.seedRecords = true;
        });
    }
}
