angular.module("kno2.settings").controller("AddUserModalCtrl", [
    "$uibModalInstance",
    "usersFactory",
    "NotificationService",
    "ValidationService",
    "SessionService",
    "DocumentSourceService",
    "RulesService",
    "SingleSignOnService",
    function (
        $uibModalInstance,
        usersFactory,
        NotificationService,
        ValidationService,
        SessionService,
        DocumentSourceService,
        RulesService,
        SingleSignOnService
    ) {
        const ctrl = this;

        ctrl.title = "Create User";
        ctrl.user = {};
        ctrl.selected = {};
        ctrl.organizationId = SessionService.getProfile().organizationId;
        ctrl.validateUsername = validateUsername;
        ctrl.saveChanges = saveChanges;
        ctrl.closeModal = closeModal;
        ctrl.setForm = setForm;
        ctrl.addAllDocumentSources = addAllDocumentSources;
        ctrl.addAllIntakeRules = addAllIntakeRules;
        ctrl.isSingleSignOnOrg = SingleSignOnService.isOrgSSOEnabled();

        ctrl.$onInit = onInit;

        ctrl.identityProvider = "Kno2";

        function onInit() {
            usersFactory.getJobFunctions().then(function (res) {
                ctrl.user.jobFunctions = res.data;
            });

            DocumentSourceService.getOrgDocumentSources().then(function (data) {
                ctrl.globalDocumentSources = data.filter((d) => d.isAssociatedWithAllActiveOrganizationUsers);
                ctrl.additionalDocumentSources = data.filter((d) => !d.isAssociatedWithAllActiveOrganizationUsers);
            });

            RulesService.getAllRules().then((data) => {
                ctrl.globalIntakeRules = data.filter((d) => d.isAssociatedWithAllActiveOrganizationUsers);
                ctrl.additionalIntakeRules = data.filter((d) => !d.isAssociatedWithAllActiveOrganizationUsers);
            });
        }

        function validateUsername(username, callback) {
            if (ctrl.identityProvider !== "PointClickCare") {
                ValidationService.validateUsername(username, callback);
            }
        }

        function addAllDocumentSources() {
            if (!ctrl.selected.documentSources) {
                ctrl.selected.documentSources = [];
            }
            _.each(ctrl.additionalDocumentSources, function (documentSource) {
                ctrl.selected.documentSources.push(documentSource);
            });
        }

        function addAllIntakeRules() {
            if (!ctrl.selected.intakeRules) {
                ctrl.selected.intakeRules = [];
            }
            _.each(ctrl.additionalIntakeRules, (intakeRule) => {
                ctrl.selected.intakeRules.push(intakeRule);
            });
        }

        function saveChanges() {
            if (ctrl.form && ctrl.form.$valid) {
                ctrl.user.isPointClickCareUser = ctrl.identityProvider === "PointClickCare";
                usersFactory
                    .create(ctrl.user)
                    .then(
                        function (res) {
                            // success
                            NotificationService.success("New user has been added successfully.");
                            $uibModalInstance.close(ctrl.user);
                            return res.data.id;
                        },
                        function (res) {
                            NotificationService.serverError(res.data.modelState, "There was an error creating this user.");
                        }
                    )
                    .then((userId) => {
                        const documentSources = _(ctrl.selected.documentSources).map(function (d) {
                            return {
                                documentSourceId: d.id,
                                organizationId: ctrl.organizationId
                            };
                        });
                        const intakeRules = _(ctrl.selected.intakeRules).map((x) => {
                            return {
                                organizationId: ctrl.organizationId,
                                intakeRuleId: x.id
                            };
                        });

                        const promises = [
                            DocumentSourceService.setDocumentSourcesForUser(userId, documentSources),
                            RulesService.setIntakeRulesForUser(userId, intakeRules)
                        ];
                        Promise.all(promises).then(() => {});
                    });
            }
        }

        function closeModal() {
            $uibModalInstance.dismiss("cancel");
        }

        function setForm(form) {
            ctrl.form = form;
        }
    }
]);
