var drake; // only one instance (doesn't appear to care for multiple when trying to drag between);

dragulaDirective.$inject = ["$window"];

export function dragulaDirective($window) {
    var directive = {
        restrict: "A",
        link: link,
        scope: {
            collection: "=dragula",
            collections: "=collections",
            drag: "&",
            drop: "&"
        }
    };
    return directive;

    function link(scope, element) {
        var from,
            to,
            item,
            raw = element[0];

        if (!drake) {
            drake = $window.dragula([], {
                direction: "vertical",
                moves: function (el) {
                    return !angular.element(el).hasClass("gu-stake");
                },
                invalid: function (el) {
                    return el.classList.contains("gu-disable") || el.tagName === "A" || el.tagName === "BUTTON";
                },
                delay: 200
            });

            $window.autoScroll([document.querySelector(".triage-grid")], {
                margin: 40,
                maxSpeed: 10,
                scrollWhenOutside: true,
                autoScroll: function () {
                    return this.down && drake.dragging;
                }
            });

            drake.on("drag", function (el, container) {
                from = angular.element(el).index();
                item = container.$$dragulaCollection[from];

                // drag callback
                scope.drag({
                    $item: item,
                    $source: container.$$dragulaCollection
                });
            });

            drake.on("drop", function (el, container, source) {
                to = angular.element(el).index();

                if (container === source) reorder(container.$$dragulaCollection, from, to);
                else {
                    move(source.$$dragulaCollection, from, container.$$dragulaCollection, to);

                    // after dragging to another container, ngRepeat will intermittenly not render all of the items
                    ngRepeatFix(scope, container.$$dragulaCollection, container);
                }

                scope.$apply();

                // drop callback
                scope.drop({
                    $item: item,
                    $source: source.$$dragulaCollection,
                    $dest: container.$$dragulaCollection
                });
            });
        }

        if (scope.collection) {
            scope.collection.$$dragulaContainer = raw;
            raw.$$dragulaCollection = scope.collection;
        }

        element.addClass("gu-container");

        scope.$watchCollection("collections", function (collections) {
            _.forEach(collections, function (c) {
                if (c && c.$$dragulaContainer) {
                    drake.containers.remove(c.$$dragulaContainer);
                    drake.containers.push(c.$$dragulaContainer);
                }
            });
        });
    }

    function reorder(array, fromIndex, toIndex) {
        if (fromIndex !== toIndex) return move(array, fromIndex, array, toIndex);
    }

    function move(fromArray, fromIndex, toArray, toIndex) {
        toArray.splice(toIndex, 0, fromArray.splice(fromIndex, 1)[0]);
    }

    function ngRepeatFix(scope, collection, container) {
        container.classList.toggle("gu-dropped");

        scope.$apply();

        var temp = collection.slice(0);
        collection.length = 0;

        scope.$apply();

        _.forEach(temp, function (item) {
            collection.push(item);
        });

        container.classList.toggle("gu-dropped");
    }
}
