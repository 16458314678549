angular.module("kno2.services").service("AlertsNotificationService", [
    "AlertsService",
    "$timeout",
    "$rootScope",
    "SessionService",
    function (alertsService, $timeout, $rootScope, sessionService) {
        var refresh = function () {
            if (sessionService.userInRole("Administrator") || sessionService.userInRole("User")) {
                alertsService.getAlerts().then(function (data) {
                    // Calculate counts
                    var highPriority = 0,
                        mediumPriority = 0,
                        lowPriority = 0;

                    angular.forEach(data.alerts, function (value) {
                        switch (value.priority) {
                            case 1:
                                highPriority++;
                                break;
                            case 2:
                                mediumPriority++;
                                break;
                            case 3:
                                lowPriority++;
                                break;
                        }
                    });

                    $rootScope.alerts = data;
                    $rootScope.alerts.priorityHighCount = highPriority;
                    $rootScope.alerts.priorityMediumCount = mediumPriority;
                    $rootScope.alerts.priorityLowCount = lowPriority;
                });
            }
        };

        return {
            refresh: refresh
        };
    }
]);
