(function () {
    "use strict";
    var factory = function ($q, ConfirmationDialogService, SessionService, SupportService, planUpgradePrompts) {
        let isModalVisible = false;
        return function (resource) {
            const deferred = $q.defer();

            const isAdmin = SessionService.userInRole("Administrator");
            const messageKeySuffix = isAdmin ? "Admin" : "User";
            const messageKey = `${resource}${messageKeySuffix}`;

            if (!isModalVisible) {
                isModalVisible = true;

                ConfirmationDialogService.open({
                    windowClass: "modal-800",
                    headerText: "Upgrade Subscription Plan",
                    bodyText: planUpgradePrompts[messageKey],
                    okText: "Send Request",
                    cancelText: "Not Now",
                    confirmFn: () => {
                        return $q.when(SupportService.sendPlanUpgradeRequest());
                    },
                    successNotificationText: "Your plan upgrade request has been sent."
                })
                    .result.then((result) => deferred.resolve(result))
                    .catch(() => {})
                    .finally(() => {
                        isModalVisible = false;
                    });
            }

            return deferred.promise;
        };
    };

    factory.$inject = ["$q", "ConfirmationDialogService", "SessionService", "SupportService", "planUpgradePrompts"];
    angular.module("kno2.factories").factory("PromptUpgradeModal", factory);
})();
