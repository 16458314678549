angular.module("kno2.directives").directive("k2SecureUrlValidator", ["$location", secureUrlValidator]);

function secureUrlValidator($location) {
    let kno2fyLocalHost = "kno2fy.local";
    let URL_REGEXP =
        /^(?:https:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;

    if ($location.host().indexOf(kno2fyLocalHost) > -1) {
        URL_REGEXP =
            /^(?:https?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;
    }

    function link(scope, element, attrs, ngModel) {
        if (ngModel && attrs.type === "url") {
            ngModel.$validators.url = function (value) {
                return URL_REGEXP.test(value);
            };
        }
    }

    return {
        require: "?ngModel",
        link: link
    };
}
